import React, { Component } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Redirect } from "react-router-dom";
import { Form, Col, Row, Alert, Button, Table, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Constants from "../../../Config";
import ItemAdd from "./ItemAdd";

import AsyncSelect from "react-select/async";
const apiUrl = Constants.API_URL;
// const b2bApiUrl = Constants.B2B_APIUAT_URL;
const b2bApiUrl = Constants.B2B_API_URL;

export class CreateLead extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    show: false,
    details: "",
    lead_source: "",
    is_redirect: false,
    buyingForArray: [],
    buying_for: "",
    priorityArray: [],
    priority: "",
    verifyStatusArray: ["Verified B2B", "Verified B2C"],
    selectedFile: "",
    existing_disable: false,

    verified_status: "",
    campaign: "",
    agentList: [],
    leadItemDisplay: [],
    is_loading: false,
    pincode: "",
    wrong_pincode: false,
    wrong_phone: false,
    message: "",
    agent_id: "0",
    location: "",
    location_id: "",
    businessGroup: [],
    // business_group: ,
    checkedBusinessGroup: 0,
    checkedBusinessType: [],
    businessType: [],
    business_type: [],
    business_type_other: "",
    businessType_required: false,
    showBusinesstype: false,
    is_multiselect_search: false,
    others: false,
    customer_id: "",
    locationList: [],
    locationArray: [],
    customer_mobile: "",
    itemsRemoved: false,
    validated: false,
    all_lead_source: [],
    typeArray: [],
    customer_type: "",
    supportRemarks: "",
    mediumArray: [],
    utmSourceArray: [],
    utmsource: "",
    medium: "",
    // isutmdisable: false,
    uploadedFiles: [],
    fileNames: [],
  };
  componentDidMount() {
    // this.getMyAccount();
    this.handleSupportAgent();
    this.handleBuyingFor();
    this.handlePriority();
    this.handleCustomertype();
    this.getBusinessGroup();
    this.getAllSourceTypes();
    this.getMedium();
    this.getSource();
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      })
  };
  getSource = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "28",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let utmSourceArray = data.data.data;
        this.setState({
          utmSourceArray,
        });
      })
      .catch((err) => console.log(err));
  };
  getMedium = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "29",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let mediumArray = data.data.data;
        this.setState({
          mediumArray,
        });
      })
      .catch((err) => console.log(err));
  };
  getAllSourceTypes = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "21",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let apiData = data.data.data;

        // let leadSources = [
        //   "Pro Pricing Request",
        //   "FB Lead",
        //   "Phone",
        //   "Email",
        //   "RT-Email",
        //   "RT-Whatsapp",
        //   "Bot",
        //   "Google",
        //   "IndiaMart",
        //   'facebook_leads',
        //   "Social",
        //   "Whatsapp"
        // ];

        let leadSources = [
          "ECOM_CLP_BOQ",
          "ECOM_CLP",
          "Project_Purchase_List",
          "Boq",
          "boq",
          "TECH_QUERY",
          "LEAD_OOS",
          "LEAD_BULK",
        ];

        // let allowedLeadSource = leadSources.filter((item)=> apiData.includes(item.meta_value));
        let allowedLeadSource = apiData.filter((item) =>
          !leadSources.includes(item.meta_value)
        );
        this.setState({
          all_lead_source: allowedLeadSource,
        });
      })
      .catch((error) => console.log(error));
  };

  getPhoneValue = (e) => {
    e.persist();
    if (e.target.value.length === 10) {
      this.setState({
        phone2: e.target.value,
        wrong_phone: false,
      });
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: e.target.value },
      })
        .then((data) => {
          let requestQuoteData = data.data.data[0];
          if (data.data.data[0].customerName) {
            this.setState({
              existing_name: true,
            });
          }
          if (data.data.data[0].email) {
            this.setState({
              existing_email: true,
            });
          }
          if (data.data.data[0]) {
            this.setState(
              {
                customer_id: data.data.data[0].customer_id,
                customer_name: data.data.data[0].customerName,
                customer_email: data.data.data[0].email,
                // existing_disable: true,
                wrong_phone: false,
                business_group: requestQuoteData.business_group,
                // business_type: arr2,
                // business_type_other: requestQuoteData.business_type_other,
              },
              () => {
                if (this.state.business_group) {
                  this.setState({
                    showBusinesstype: true,
                    checkedBusinessType: this.state.business_type,
                  });
                }
                if (this.state.business_group == 100) {
                  this.setState({
                    others: true,
                  });
                }
              }
            );
          } else {
            this.setState({
              customer_mobile: e.target.value,
            });
          }
        })

        .catch((error) => {
          console.log("error", error);
          this.setState({
            customer_id: 0,
            existing_disable: false,
            existing_name: false,
            existing_email: false,
            customer_name: "",
            customer_email: "",
          });
        });
    } else {
      this.setState(
        {
          wrong_phone: true,
          customer_name: "",
          customer_email: "",
          existing_disable: false,
          existing_name: false,
          existing_email: false,
        }
        // () => {
        //   setTimeout(() => {
        //     this.setState({
        //       wrong_phone: false,
        //     });
        //   }, 5000);
        // }
      );
    }
  };

  handlePinCode = (e) => {
    this.setState(
      {
        pincode: e.target.value,
      },
      () => {
        if ([6, 0].includes(this.state.pincode.length)) {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails?.token}`,
            },
            data: {
              pincode: this.state.pincode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                location: `${data.data.data[0].city},${data.data.data[0].state}`,
                location_id: data.data.data[0].city_id,
                wrong_pincode: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                wrong_pincode: false,
              });
              // this.setState(
              //   {
              //     wrong_pincode: true,

              //   },
              //   () => {
              //     setTimeout(() => {
              //       this.setState({
              //         wrong_pincode: false,
              //       });
              //     }, 5000);
              //   }
              // );
            });
        } else {
          this.setState(
            {
              wrong_pincode: true,
              location: "",
            }
            // },
            // () => {
            //   setTimeout(() => {
            //     this.setState({
            //       wrong_pincode: false,
            //     });
            //   }, 2000);
            // }
          );
        }
      }
    );
  };

  handleBuyingFor = (e) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    axios
      .post(
        apiUrl + "/meta-value",
        {
          meta_id: 19,
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        this.setState({
          buyingForArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  handlePriority = (e) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    axios
      .post(
        apiUrl + "/meta-value",
        {
          meta_id: 20,
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        this.setState({
          priorityArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  handlePriorityValue = (e) => {
    let selectedValue = e.target.id;
    let index = this.state.priorityArray.filter((item) => {
      return item.id == selectedValue;
    })[0];
    this.setState({
      priority: index.meta_value,
    });
  };
  handleCustomertype = (e) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    axios
      .post(
        apiUrl + "/meta-value",
        {
          meta_id: 4,
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        this.setState({
          typeArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  handleVerifyStatus = (e) => {
    let selectedValue = e.target.id;
    let index = this.state.verifyStatusArray.filter((item) => {
      return item == selectedValue;
    })[0];

    this.setState({
      verified_status: index.meta_value,
    });
  };
  handleBuyingForValue = (e) => {
    let selectedValue = e.target.id;
    let index = this.state.buyingForArray.filter((item) => {
      return item.id == selectedValue;
    })[0];
    this.setState({
      buying_for: index.meta_value,
    });
  };
  handleSupportAgent = (e) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        agent_list: "",
        id: [54],
        showAll: "true",
        slug: "createlead",
        listType: "add",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentList: data.data.data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };
  handleLocation = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return await axios(apiUrl + "/get-suggested-cities", config)
      .then((data) => {
        this.setState(
          {
            locationList: data.data.data,

            // locationArray: data.data.data.map((item) => {
            //   return {
            //     name: `${item.name},${item.state_name}`,
            //     value: `${item.name},${item.state_name}`,
            //   };
            // }),
          },
          () => {
            if (this.state.locationList.length == 0) {
              this.setState({
                location_id: 0,
              });
            }
          }
        );
        callback(
          data.data.data.map((i) => ({
            label: `${i.name},${i.state_name}`,
            value: `${i.name},${i.state_name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };
  getCities = (e) => {
    let city = this.state.locationList.filter(
      (el) => `${el.name},${el.state_name}` == e.value
    )[0];

    this.setState({
      location: `${city.name},${city.state_name}`,
      location_id: city.city_id,
    });
  };
  handleNoSelect = (e) => {
    this.setState({
      location: e.target.value,
    });
  };
  onFileChange = async (event) => {
    // Update the state
    const file = event.target.files[0];
    var allowedimage = this.validateFile(file);
    const fileType = this.getFileType(file.name);
    // if(allowedimage){
    // const base64 = await this.convertBase64(file)
    //    console.log("base64",file)
    //    console.log("filename",file.name)

    //    this.setState({
    //     //    selectedFile: base64
    //     selectedFile: file
    //     });

    // 'Content-Type': 'multipart/form-data'
    const { selectedFile } = this.state;
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    // console.log("formdata",formData)
    const totalFilesCount = this.state.uploadedFiles.length;
    if (totalFilesCount == 3) {
      alert("You can upload a maximum of three images");
      return;
    }
    axios
      .post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },
      })
      .then((res) => {
        this.setState({
          uploadedFiles:
            [...this.state.uploadedFiles,
            {
              url: res.data.data.image_url,
              type: fileType
            }
            ],
          fileNames: [...this.state.fileNames, file.name]
        })
        this.setState({
          selectedFile: res.data.data.image_url,
        });
      });
  };
  validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    // console.log("file",typeof (file))
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };
  getFileType = (filename) => {
    const fileExtension = filename.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return 'pdf'
      case 'jpeg':
      case 'jpg':
      case 'png':
        return 'image'
      case 'doc':
      case 'docx':
        return 'doc'
      default:
        return 'unknown'
    }
  }
  showleadDisplayArray = (e) => {
    this.setState(
      {
        leadItemDisplay: this.state.leadItemDisplay.concat(e),
      },
      () => {
        let allItems = null;
        allItems = this.state.leadItemDisplay;
        const getLastElement = (name, allItems) => {
          const objUniq = allItems.reduce(
            (res, item) => ({ ...res, [item[name]]: item }),
            {}
          );
          return Object.keys(objUniq).map((item) => objUniq[item]);
        };
        const uniqAddress = getLastElement("name", allItems);
        const finalArray = uniqAddress.filter((item) => item.name != "");
        this.setState({
          leadItemDisplay: finalArray,
        });
      }
    );
  };
  removeItemFromMainList = (e) => {
    let id = e;
    let products = this.state.leadItemDisplay;
    products.splice(id, 1);
    this.setState({
      leadItemDisplay: products,
      itemsRemoved: true,
    });
  };

  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          // data:{id:this.props.businessData.id}
          data: { id: 2105 },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState(
              {
                businessGroup: data.data.data,
                isLoading: false,
              },
              () => {
                let selectedArray = this.state.businessGroup.filter((item) => {
                  return item.selected === true;
                });
                if (selectedArray.length > 0) {
                  if (selectedArray[0].business_type.length > 0) {
                    let selectedBusinessType =
                      selectedArray[0].business_type.filter((item) => {
                        return item.selected === true;
                      });
                    let selectedBusinessTypeID = selectedBusinessType.map(
                      (item) => item.id
                    );
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      businessType: selectedArray[0].business_type,
                      checkedBusinessType: selectedBusinessTypeID,
                    });
                    // this.props.business_group2(selectedArray[0].id);
                    // this.props.business_type2(selectedArray[0].business_type)
                  } else {
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      others: true,
                      business_type_other: selectedArray[0].value,
                    });
                    // this.props.business_group2(selectedArray[0].id);
                    // this.props.business_type_other2(selectedArray[0].value);
                  }
                } else {
                  this.setState({
                    is_multiselect_search: false,
                  });
                }
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  handleBusinessGroup = (e) => {
    this.setState({
      showBusinesstype: true,
      checkedBusinessGroup: e.target.id,
      business_group: e.target.id,
      businessType_required: true,
    });
    // this.props.business_group2(e.target.id);
    let id = e.target.id;
    if (id == 100) {
      this.setState({
        others: true,
        checkedBusinessGroup: id,
        checkedBusinessType: [],
        business_group: id,
        business_type: [],
        is_multiselect_search: false,
        // required_state: false,
        agentid: "0",
      });
      // this.props.agent2(this.state.agentid));
      // this.props.business_group2(id)
    } else {
      if (id != 2) {
        this.setState({
          is_multiselect_search: false,
        });
      } else {
        this.setState({
          is_multiselect_search: true,
        });
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              // this.props.business_group2(selectedBusinessGroupID)
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
              // this.props.business_type2(selectedBusinessType)
            }
          );
        }
      );
    }
  };
  handleBusinessType = (e) => {
    this.setState({
      businessType_required: false,
    });
    let selectedArray = this.state.checkedBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedBusinessType: selectedArray,
      });
      // this.props.business_type2(selectedArray);
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      if (filteredArray.length == 0) {
        this.setState({ businessType_required: true });
      }

      this.setState({
        checkedBusinessType: filteredArray,
      });
      // this.props.business_type2(filteredArray);
    }
  };
  handletypeOther = (e) => {
    this.setState({
      business_type_other: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      is_loading: true,
      validated: true,
    });

    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;

    axios
      .post(
        apiUrl + "/create-lead",
        {
          customer_id: this.state.customer_id,
          customer_name: this.state.customer_name,
          email: this.state.customer_email,
          mobile: this.state.customer_mobile,
          details: this.state.details,
          // rfq_type: this.state.rfq_type,
          lead_type: this.state.lead_source,
          agent_id: this.state.agent_id,
          // business_type: [7],
          // business_type_other: "after 5 minutes",
          // products: this.state.leadItemDisplay,
          products: this.state.leadItemDisplay,
          source: "CRM",
          // buying_for: this.state.buying_for,
          file_path: this.state.selectedFile,
          attachments: JSON.stringify(this.state.uploadedFiles),
          // created_by: JSON.parse(localStorage.getItem("userData")).user_id,
          priority: this.state.priority,
          campaign: this.state.campaign,
          pincode: this.state.pincode,
          location: this.state.location,
          location_id: Number(this.state.location_id),
          // business_type: this.state.checkedBusinessType,
          // business_group: Number(this.state.checkedBusinessGroup),
          // business_type_other: this.state.business_type_other,
          role: this.state.customer_type,
          supportRemarks: this.state.supportRemarks,
          utmSource: this.state.utmsource,
          utmMedium: this.state.medium,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            message: "Lead Generated Successfully!",
            is_error: false,
            is_loading: false,
            customer_mobile: "",
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });
  };
  // handleUTMSource = (e) => {
  //   // this.state.lead_source == "IndiaMart" ? this.setState({ utmSource : 'indiamart' }) :
  //   this.setState({
  //     utmsource: e.target.value, 
  //   })
  // };
  // handleMedium = (e) => {
  //   this.setState({
  //     medium : e.target.value
  //   })
  // }
  render() {
    if (this.state.is_redirect) {
      return <Redirect to="/all-leads" />;
    }
    return (
      <>
          <div>
            {this.state.message.length > 0 ? (
              <Alert
                className="displayMessageArea"
                variant={this.state.is_error ? "danger" : "success"}
              >
                {this.state.message}
              </Alert>
            ) : null}
            {this.state.wrong_pincode ? (
              <Alert
                className="displayMessageArea"
                variant={this.state.wrong_pincode ? "danger" : "success"}
              >
                Please Enter Correct Pincode
              </Alert>
            ) : null}
            {this.state.wrong_phone ? (
              <Alert
                className="displayMessageArea"
                variant={this.state.wrong_phone ? "danger" : "success"}
              >
                Please Enter Correct Phone no.
              </Alert>
            ) : null}
            <Form
              noValidate
              validated={this.state.validated}
              encType="multipart/form-data"
            >
              <div className="card">
                <div className="card-header">
                  <h3 className="billingAdressHeader">
                    {" "}
                    <b>ADD LEAD FORM</b>
                  </h3>
                </div>
                <div className="card-body">
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>
                          <b>Lead Source *</b>
                        </Form.Label>

                        <Form.Control
                          as="select"
                          custom
                          name="lead_source"
                          placeholder="Add Lead"
                          onChange={this.handleChange}
                          value={this.state.lead_source}
                          required
                        >
                          {/* {" "}
                          <option value="0">Select Lead Source</option>
                          <option value="Pro Pricing Request">
                            Pro Pricing Request
                          </option>
                          <option value="FB Lead">FB Lead</option>
                          <option value="LEAD_BULK">LEAD_BULK</option>
                          <option value="LEAD_OOS">LEAD_OOS</option>
                          <option value="LEAD_TECH_QUERY">
                            LEAD_TECH_QUERY
                          </option>
                          <option value="Boq">Boq</option>
                          <option value="Phone">Phone</option>
                          <option value="Email">Email</option>
                          <option value="Bot">Bot</option>
                          <option value="Rt-Email">Rt-Email</option>
                          <option value="RT-Whatsapp">RT-Whatsapp</option>
                          <option value="Project_Product_List">
                            Project_Product_List
                          </option> */}
                          <option value="">Please select Lead Source</option>
                          {this.state.all_lead_source &&
                            this.state.all_lead_source.map((item, index) => {
                              return (
                                <option
                                  key={item.id}
                                  // id={item.id}
                                  value={item.meta_value}
                                >
                                  {item.meta_title}
                                </option>
                              );
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Select the Lead type first.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        type="hidden"
                        onChange={this.handleChange}
                        value={this.state.customer_id}
                      >
                        <Form.Label>
                          <b>Customer Mobile *</b>
                        </Form.Label>

                        <Form.Control
                          className={this.state.wrong_phone ? "error" : ""}
                          name="customer_mobile"
                          type="number"
                          min={0}
                          autofocus
                          placeholder="Customer Mobile"
                          onChange={this.getPhoneValue}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the Mobile Number first.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>
                          <b>Customer Name *</b>
                        </Form.Label>
                        <Form.Control
                          name="customer_name"
                          type="text"
                          autofocus
                          placeholder="Customer Name"
                          onChange={this.handleChange}
                          value={this.state.customer_name}
                          required
                          disabled={this.state.existing_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the Name first.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>
                          <b>Customer Email</b>
                        </Form.Label>
                        <Form.Control
                          name="customer_email"
                          autofocus
                          type="email"
                          placeholder="Customer Email"
                          onChange={this.handleChange}
                          value={this.state.customer_email}
                          // required
                          disabled={this.state.existing_email}

                        // disabled={this.state.existing_disable}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the Email first.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b>PinCode</b>
                        </Form.Label>
                        <Form.Control
                          className={this.state.wrong_pincode ? "error" : ""}
                          type="number"
                          name="pincode"
                          placeholder="PinCode"
                          onChange={this.handlePinCode}
                          value={this.state.pincode}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          required={
                            [
                              "Social",
                              "RT-Whatsapp",
                              "Whatsapp"
                            ].includes(this.state.all_lead_source)
                              ? false
                              : true
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter Valid Pincode.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b> Location </b>
                        </Form.Label>
                        {this.state.location.length == 0 ? (
                          //   <SelectSearch
                          //   options={this.state.locationArray}
                          //   getOptions={(query) => this.handleLocation(query)}
                          //   search
                          //   placeholder={this.state.location}
                          //   onChange={this.getCities}
                          //   // onInput={this.getCities}

                          //   value={this.state.location}
                          //   required
                          // />

                          <AsyncSelect
                            // value={this.state.product_name}
                            value={this.state.location}
                            onChange={this.getCities}
                            onBlur={(e) => this.handleNoSelect(e)}
                            loadOptions={this.handleLocation}
                            placeholder="Enter Location"
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            name="location"
                            placeholder="Location"
                            onChange={this.handleChange}
                            value={this.state.location}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}

                        {/* <input
                        type="text"
                        list="array"
                        onChange={this.handleLocation}
                        className="w-100 p-2 rounded"
                        placeholder="Enter Your Source Location"
                      />
                      <datalist id="array">
                        <option value="">Enter Sourcre</option>
                        {this.state.locationArray.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </datalist> */}
                        <Form.Control.Feedback type="invalid">
                          Please enter Location.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          {" "}
                          <b>Choose File</b>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="attachment"
                          id="image"
                          multiple
                          onChange={this.onFileChange}
                        />
                      </Form.Group>
                      <Col style={{ display: "flex", flexDirection: "row" }}>
                        {this.state.fileNames.map((fileName, index) => (
                          <p key={index} style={{ marginRight: "0.8rem" }}>{fileName}</p>
                        ))}
                      </Col>
                    </Col>

                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b>Campaign Details</b>
                        </Form.Label>
                        <Form.Control
                          name="campaign"
                          placeholder="Enter Campaign Details"
                          onChange={this.handleChange}
                          value={this.state.campaign}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b>Support Agent</b>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.handleChange}
                          name="agent_id"
                        >
                          <option value="">Select to Assign</option>
                          {this.state.agentList.map((item, index) => {
                            return item.id ? (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ) : null;
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b>You Are a ?...</b>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.handleChange}
                          name="customer_type"
                        >
                          <option value="">Select to Choose</option>
                          {this.state.typeArray.map((item, index) => {
                            return (
                              <option key={index} value={item.meta_value}>
                                {item.meta_title}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b>UTM Source</b>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.handleChange}
                          name="utmsource"
                          value={this.state.utmsource}
                          required
                        >
                          <option value="">Select Source</option>
                          {this.state.utmSourceArray.map((item, index) => {
                            return (
                              <option key={index} value={item.meta_value}>
                                {item.meta_title}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          <b>UTM Medium</b>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.handleChange}
                          name="medium"
                          value={this.state.medium}
                          required
                        >
                          <option value="">Select Medium</option>
                          {this.state.mediumArray.map((item, index) => {
                            return (
                              <option key={index} value={item.meta_value}>
                                {item.meta_title}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* 
                <Form.Row className="d-flex justify-content-between">
                  <Col>
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "spaceBetween",
                      }}
                    >
                      <Form.Group controlId="formGridBuyingFor">
                        <Form.Label style={{ marginRight: "63px" }}>
                          <b> Buying For </b>
                        </Form.Label>
                        {this.state.buyingForArray &&
                          this.state.buyingForArray.map((item, index) => {
                            return (
                              <Form.Check
                                type="radio"
                                inline
                                key={item.id + index}
                                id={item.id}
                                name="buying_for"
                                label={item.meta_title}
                                onChange={this.handleBuyingForValue}
                              />
                            );
                          })}
                      </Form.Group>
                    </div>
                  </Col>
                </Form.Row> */}
                  {/* <Row>
                    <Col>
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "spaceBetween",
                        }}
                      >
                        <Form.Group controlId="formGridVerifyStatus">
                          <Form.Label style={{ marginRight: "63px" }}>
                            <b> Verify Status </b>
                          </Form.Label>
                          {this.state.verifyStatusArray &&
                            this.state.verifyStatusArray.map((item, index) => {
                              return (
                                <Form.Check
                                  type="radio"
                                  inline
                                  key={index + index}
                                  // checked={
                                  //   item.id == this.state.checkedBusinessGroup
                                  // }
                                  id={item}
                                  name="priority"
                                  label={item}
                                  onChange={this.handleVerifyStatus}
                                  required
                                />
                              );
                            })}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row> */}
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Form.Label style={{ marginRight: "63px" }}>
                          <b> Priority </b>
                        </Form.Label>
                        {this.state.priorityArray &&
                          this.state.priorityArray.map((item, index) => {
                            return (
                              <Form.Check
                                type="radio"
                                inline
                                key={index + index}
                                id={item.id}
                                name="priority"
                                label={item.meta_title}
                                onChange={this.handlePriorityValue}
                              // required
                              />
                            );
                          })}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  {/* <Form.Row>
                  <Col>
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "spaceBetween",
                      }}
                    >
                      <Form.Group controlId="formGridCustomerName">
                        <Form.Label style={{ marginRight: "63px" }}>
                          <b> Business Group </b>
                        </Form.Label>
                        {this.state.businessGroup &&
                          this.state.businessGroup.map((item, index) => {
                            return (
                              <Form.Check
                                type="radio"
                                inline
                                key={item.id + index}
                                // checked={
                                //   item.id == this.state.checkedBusinessGroup
                                // }
                                id={item.id}
                                name="business_group"
                                label={item.name}
                                onChange={this.handleBusinessGroup}
                                // required
                              />
                            );
                          })}
                      </Form.Group>
                    </div>
                  </Col>
                </Form.Row>
                {this.state.showBusinesstype ? (
                  <Form.Row>
                    <Col>
                      <div
                        className="card-body"
                        style={{
                          alignItems: "center",
                          justifyContent: "spaceBetween",
                        }}
                      >
                        <Form.Group controlId="formGridCustomerName">
                          <Form.Label style={{ marginRight: "75px" }}>
                            <b> Business Type </b>
                          </Form.Label>
                          {this.state.others == false ? (
                            this.state.businessType.map((item, index) => {
                              let checked =
                                this.state.checkedBusinessType.indexOf(
                                  item.id
                                ) != -1;
                              return (
                                <Form.Check
                                  type="checkbox"
                                  inline
                                  onChange={this.handleBusinessType}
                                  key={item.id + index}
                                  // defaultChecked={true}
                                  // defaultValue={7}
                                  // checked={checked}
                                  id={item.id}
                                  name="business_type"
                                  label={item.name}
                                  required={this.state.businessType_required}
                                
                              />
                              );
                            })
                          ) : (
                            <Form.Control
                              placeholder="Others"
                              name="business_type_other"
                              defaultValue={this.state.business_type_other}
                              onChange={this.handletypeOther}
                              required
                            />
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                  </Form.Row>
                ) : null} */}
                  <Row>
                    <Col md="12">
                      <Form.Group
                        className="mb-3"
                      >
                        <Form.Label>
                          <b>Message from customer</b>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="details"
                          rows={3}
                          placeholder="Message from customer"
                          onChange={this.handleChange}
                          value={this.state.details}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Form.Group
                        className="mb-3"
                      >
                        <Form.Label>
                          <b>Customer support Remark</b>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="supportRemarks"
                          rows={2}
                          placeholder="Customer Support Remark"
                          onChange={this.handleChange}
                          value={this.state.supportRemarks}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>


                </div>
              </div>
              <Row>
                {this.state.leadItemDisplay.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Item Type</th>
                        <th>Item Detail</th>
                        <th>Quantity</th>
                        <th>Price per Item</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.leadItemDisplay.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.type ? item.type : "Product"}</td>
                            <td>
                              {item.name ? item.name : item.category_name}
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>

                            <td>
                              <FontAwesomeIcon
                                icon="times-circle"
                                className="editIcon"
                                onClick={() =>
                                  this.removeItemFromMainList(index)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : null}

                <ItemAdd
                  showleadDisplayArray={this.showleadDisplayArray}
                  leadItemDisplay={this.state.leadItemDisplay}
                  customer_id={this.state.customer_id}
                  customer_mobile={this.state.customer_mobile}
                  itemsRemoved={this.state.itemsRemoved}
                />
              </Row>

              <Button
                variant="primary"
                type="submit"
                className="mr-3 float-right"
                disabled={this.state.is_loading ? true : false}
                onClick={this.handleSubmit}
              >
                {this.state.is_loading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Create Lead"
                )}
              </Button>
            </Form>
          </div>
      </>
    );
  }
}

export default CreateLead;
