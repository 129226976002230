import React from "react";
import classes from "./CreateSource.module.css";
import { Button, Col, Spinner } from "react-bootstrap";

const Footer = (props) => {
  const {
    access,
    status,
    showButtonStatus,
    showReOpen,
    UpdateSourcingRequest,
    submitLoading,
    handleShowFooter,
    handleCloseFooter,
    handleCreateQuote,
    accessSales,
    srRfqStatus,
    showModal,
  } = props;

  const SourceRequestId = JSON.parse(localStorage.getItem("srState"));
  const RfqStatus = JSON.parse(localStorage.getItem("SR_Details"));
  return (
    <>
      {access && ["pending", "new", "reopen"].includes(status) ? (
        <>
          <div className={classes.footer}>
            <div className="row">
              {["Direct Order", "Closed - Lost", "Duplicate"].includes(
                SourceRequestId?.rfq_status
              ) || (["cancelled"].includes(status) 
                && ["Direct Order", "Closed - Lost", "Duplicate"].includes(RfqStatus?.rfqStatus)) ? null : (
                <Col lg={12}>
                  <div className="d-flex align-items-center h-100 justify-content-end">
                    {!submitLoading ? (
                      <Button
                        className={classes.save_response}
                        type="submit"
                        id="save"
                        onClick={UpdateSourcingRequest}
                        disabled={submitLoading}
                      >
                        Save Response
                      </Button>
                    ) : (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )}
                    {!submitLoading ? (
                      <Button
                        className={classes.submit}
                        type="submit"
                        id="complete"
                        onClick={UpdateSourcingRequest}
                        disabled={submitLoading}
                      >
                        Mark Complete
                      </Button>
                    ) : (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )}
                  </div>
                </Col>
              )}
            </div>
          </div>
        </>
      ) : null}
      {status === "completed" &&
      access &&
      !["Closed Won", "Closed Lost"].includes(srRfqStatus) ? (
        <div className={classes.footer}>
          {["Direct Order", "Closed - Lost", "Duplicate"].includes(
                SourceRequestId?.rfq_status
          ) || (["cancelled","completed"].includes(status) 
          && ["Direct Order", "Closed - Lost", "Duplicate"].includes(RfqStatus?.rfqStatus)) 
          ? null : (
          <div className="row">
            <Col lg={12}>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  className={classes.btn_blue}
                  style={{ marginLeft: "16px" }}
                  onClick={showModal}
                >
                  {submitLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Re-Open By Sourcing"
                  )}
                </Button>
              </div>
            </Col>
          </div>
          )}
        </div>
      ) : null}
      {accessSales &&
      status === "completed" &&
      !showReOpen &&
      !["Closed Won", "Closed Lost"].includes(srRfqStatus) ? (
        <div className={classes.footer}>
          {["Direct Order", "Closed - Lost", "Duplicate"].includes(
            SourceRequestId?.rfq_status
          ) || (["cancelled", "completed"].includes(status)
          && ["Direct Order", "Closed - Lost", "Duplicate"].includes(RfqStatus?.rfqStatus))
          ? null : (
            <div className="row">
              <Col lg={12}>
                <div className={classes.footer_btn_wrapper}>
                  <Button
                    className={classes.btn_grey}
                    type="submit"
                    id="reopen"
                    onClick={handleShowFooter}
                  >
                    {submitLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Reopen"
                    )}
                  </Button>

                  {!showButtonStatus ? (
                    <Button
                      className={classes.btn_blue}
                      style={{ marginLeft: "16px" }}
                      type="createquote"
                      id="save"
                      onClick={handleCreateQuote}
                    >
                      {submitLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Create Quote"
                      )}
                    </Button>
                  ) : null}
                  {access &&
                  accessSales &&
                  status === "completed" &&
                  !["Closed Won", "Closed Lost"].includes(srRfqStatus) ? (
                    <Button
                      className={classes.btn_blue}
                      style={{ marginLeft: "16px" }}
                      onClick={showModal}
                    >
                      {submitLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Re-Open By Sourcing"
                      )}
                    </Button>
                  ) : null}
                </div>
              </Col>
            </div>
          )}
        </div>
      ) : null}
      {showReOpen ? (
        <>
          <div className={classes.footer}>
            <div className="row">
              <Col lg={12}>
                <div className={classes.footer_btn_wrapper}>
                  <Button
                    className={classes.btn_grey}
                    onClick={handleCloseFooter}
                  >
                    {submitLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Cancel"
                    )}
                  </Button>
                  <Button
                    className={classes.btn_blue}
                    style={{ marginLeft: "16px" }}
                    id="reopen"
                    type="submit"
                    onClick={UpdateSourcingRequest}
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Col>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Footer;
