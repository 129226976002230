import React, { Component } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../../Config";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import SelectSearch from "react-select-search";
// import AsyncSelect from 'react-select/async';
import ApproveQuote from "./ApproveQuote";

const apiUrl = Constants.API_URL;

export class RfqEditProducts extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    product_name: "",
    product_id: "",
    product_quantity: 0,

    selectedValue: "",
    category_name: "",
    category_id: "",
    category_quantity: "",
    type: "",
    new_add: false,
    finalAdd: false,

    categoryList: [],
    categoryArray: [],
    productList: [],
    productArray: [],
    finalArray: [],

    other_name: "",
    other_quantity: "",

    nameChange: false,
    categnameChange: false,
  };
  componentDidMount() {
    this.showDetails();
  }
  //functions start from here on
  showDetails() {
    this.setState({
      finalArray: this.props.itemArray,
      selectedValue: this.props.data.type,
    });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // this.props.removeExisting()
  };
  handleChangeOtherName = (i, e) => {
    // this.setState({
    //   [e.target.name]: e.target.value,
    // });
    if (this.props.new_add) {
      this.props.addFinalArrayItem(i,{
        name: e.target.value,
        product_id: 0,
        quantity: 1,
        type: "other",
        category_id: 0,
        // price: Number(index.price).toFixed(2),
      });
    } else {
      this.props.handleEditData(i, {
        name: e.target.value,
        product_id: 0,
        quantity: 1,
        type: "other",
        category_id: 0,
      });
    }
  };

  getValue = (e) => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { keyword: e },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };
  getProductName = (i, e) => {
    let index = this.state.productList.filter((el) => el.name == e)[0];
    this.setState(
      {
        product_name: index.name,
        product_id: index.product_id,
        price: Number(index.price).toFixed(2),
        nameChange: true,
        // availabe_stock: index.quantity,
      },
      () => {
        if (this.props.new_add) {
          this.props.addFinalArrayItem(i,{
            name: index.name,
            product_id: index.product_id,
            quantity: 1,
            type: "product",
            category_id: 0,
            price: Number(index.price).toFixed(2),
          });
        } else {
          this.props.handleEditData(i, {
            name: index.name,
            product_id: index.product_id,
            quantity: 1,
            type: "product",
            category_id: 0,
            price: Number(index.price).toFixed(2),
          });
        }
      }
    );
    // this.props.finalArray.splice(ind,1)
  };

  getCategory = (e) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: {
        keyword: e,
      },
    })
      .then((data) => {
        this.setState({
          categoryList: data.data.data,
          categoryArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };

  getCategoryName = (i, e) => {
    let categ = this.state.categoryList.filter((el) => el.name == e)[0];
    categ["type"] = "category";
    this.setState(
      {
        category_name: categ.name,
        category_id: categ.category_id,
        type: categ.type,
        categnameChange: true,
        //   categSelect: true,
      },
      () => {
        if (this.props.new_add) {
          this.props.addFinalArrayItem(i,{
            name: e,
            category_id: categ.category_id,
            quantity: this.state.category_quantity,
            type: "category",
            product_id: 0,
          });
        } else {
          this.props.handleEditData(i, {
            name: categ.name,
            category_id: categ.category_id,
            quantity: 1,
            type: "category",
            product_id: 0,
            // price: Number(categ.price).toFixed(2),
          });
        }
      }
    );
  };

  //   rename = (e) => {
  //     e.map((obj) => {
  //       obj["name"] = obj["productName"]; // Assign new key
  //       delete obj["productName"]; // Delete old key
  //       obj["quantity"] = obj["productQuantity"]; // Assign new key
  //       delete obj["productQuantity"]; // Delete old key
  //       delete obj["model"];
  //       delete obj["productPrice"];
  //     });
  //     console.log(e);
  //   };
  handleSelect = (e) => {
    this.setState({
      selectedValue: e.target.value,
      type: e.target.value,
    });
  };
  getValue = (e) => {
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { keyword: e },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <Row key={this.props.data.name}>
        <Col sm={2}>
          <Form.Group
            controlId="formBasicTypeName"
            // key={item.index}
          >
            <Form.Label>Choose Type</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={this.handleSelect}
              value={this.state.selectedValue}
              //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
            >
              <option value="product">Product</option>
              <option value="category">Category</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {this.state.selectedValue == "product" ? (
          <>
            <Col sm={6} className="customeAutoComplete">
              <Form.Group controlId="formBasicProduct">
                <Form.Label> {this.props.ind == 0 ? "Item Name" : ""}</Form.Label>
                {/* <Form.Control
                                        placeholder="Product"
                                        name="product_name"
                                        value={this.state.product_name}
                                        onChange={this.handleChange}
                                        // disabled={this.state.isDisable}
                                      /> */}
                <SelectSearch
                  options={this.state.productArray}
                  getOptions={(query) => this.getValue(query)}
                  search
                  name="product_name"
                  placeholder={
                    this.props.data.type == "product"
                      ? this.props.data.name
                      : "Enter product"
                  }
                  onChange={(e) => this.getProductName(this.props.ind, e)}
                  // onBlur={()=> {
                  //   this.props.removeExisting(this.props.ind)
                  // }}
                  value={this.state.product_name}
                />
                {/* <AsyncSelect
                // value={this.state.product_name}
                onChange={this.getProductName}
                loadOptions={this.loadOptions}
                defaultValue={this.props.data.type == "product"
                ? {label: this.props.data.name, value: this.props.data.name}
                : "Enter product"}
                
                /> */}
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formBasicQuantity">
                <Form.Label> {this.props.ind == 0 ? "Quantity" : ""}</Form.Label>
                <Form.Control
                  placeholder="Quantity"
                  name="product_quantity"
                  min={1}
                  defaultValue={this.props.data.quantity}
                  // value={this.state.product_quantity}
                  onChange={this.handleChange}
                  required
                  onBlur={() => {
                    // this.props.removeExisting(this.props.ind);
                    this.props.addFinalArrayItem(this.props.ind,{
                      name: this.state.nameChange
                        ? this.state.product_name
                        : this.props.data.name,
                      product_id: this.state.nameChange
                        ? this.state.product_id
                        : this.props.data.product_id,
                      category_id: 0,
                      quantity: this.state.product_quantity,
                      type: "product",
                    });
                  }}

                  // disabled={this.state.isDisable}
                />
              </Form.Group>
            </Col>
          </>
        ) : this.state.selectedValue == "category" ? (
          <>
            <Col sm={6} className="customeAutoComplete">
              <Form.Group controlId="formBasicCategory">
                <Form.Label> {this.props.ind == 0 ? "Item Name" : ""}</Form.Label>
                {/* <Form.Control
                                    placeholder="Product"
                                    name="product_name"
                                    value={this.state.product_name}
                                    onChange={this.handleChange}
                                    // disabled={this.state.isDisable}
                                  /> */}
                <SelectSearch
                  options={this.state.categoryArray}
                  getOptions={(query) => this.getCategory(query)}
                  search
                  placeholder={
                    this.props.data.type == "category"
                      ? this.props.data.name
                      : "Enter Category"
                  }
                  onChange={(e) => this.getCategoryName(this.props.ind, e)}
                  value={this.state.category_name}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formBasicQuantity">
                <Form.Label> {this.props.ind == 0 ? "Quantity" : ""}</Form.Label>
                <Form.Control
                  placeholder="Quantity"
                  name="category_quantity"
                  min={1}
                  defaultValue={this.props.data.quantity}
                  // value={this.state.category_quantity ? this.state.category_quantity : this.props.data.quantity }
                  onChange={this.handleChange}
                  required
                  onBlur={() => {
                    // this.props.removeExisting(this.props.ind);
                    this.props.addFinalArrayItem(this.props.ind,{
                      name: this.state.categnameChange
                        ? this.state.category_name
                        : this.props.data.name,
                      category_id: this.state.categnameChange
                        ? this.state.category_id
                        : this.props.data.category_id,
                      product_id: 0,
                      type: "category",
                      quantity: this.state.category_quantity,
                    });
                    // this.props.removeExisting(this.props.ind)
                  }}
                  //   onChange={this.handleChange}
                  // disabled
                />
              </Form.Group>
            </Col>
          </>
        ) : (
          <>
            <Col sm={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{this.props.ind == 0 ? "Item Name" : ""}</Form.Label>
                <Form.Control
                  placeholder="Other"
                  name="other_name"
                  // defaultValue={data.name}
                  value={this.state.other_name}
                  onChange={(e) => {
                    (!this.props.new_add
                      ? this.handleChangeOtherName(this.props.ind, e)
                      : this.handleChange(e))
                  }}
                  disabled={this.state.isDisable}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formBasicQuantity">
                <Form.Label>{this.props.ind == 0 ? "Quantity" : ""}</Form.Label>
                <Form.Control
                  placeholder="Quantity"
                  name="other_quantity"
                  min={1}
                  
                  // value={this.state.other_quantity}
                  onChange={this.handleChange}
                  onBlur={() => {
                    // this.props.removeExisting(this.props.ind);
                    this.props.addFinalArrayItem(this.props.ind,{
                      name: this.state.other_name,
                      product_id: 0,
                      category_id: 0,
                      type: "other",
                      quantity: this.state.other_quantity,
                    });
                    // this.props.removeExisting(this.props.ind)
                  }}
                  required
                  // disabled
                />
              </Form.Group>
            </Col>
          </>
        )}

        {/* <Col sm={2}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Price</Form.Label>
            <Form.Control
              placeholder="Price"
              name="price"
                value={this.state.price}
              onChange={this.handleChange}
              disabled
            />
          </Form.Group>
        </Col> */}
        {/* <Col sm={2}>
          <Form.Group controlId="formBasicAdd">
            <Button
              className="mt-4"
              onClick={() => {
                this.props.addNew({
                  name:
                    this.state.selectedValue == "product"
                      ? this.state.product_name
                      : this.state.category_name,
                  product_id: this.state.product_id,
                  quantity: this.state.product_quantity,
                  type: this.state.selectedValue,
                });
              }}
            >
              Add this
            </Button>
          </Form.Group>
        </Col> */}

        {/* <Col sm={2}>
          <Button
          onclick={this.props.addItem({
            name: this.state.product_name,
          product_id: this.state.product_id,
          quantity: this.state.product_quantity,
          type: "product"
        })}>
            Add
          </Button>
        </Col> */}
        <Col lg={2}>
          {this.props.itemArray.length >= 0 ? (
            <>
              <FontAwesomeIcon
                icon="minus"
                id={this.props.ind}
                onClick={() => this.props.removeItem(this.props.ind)}
                className="removeIcon"
              />

              <FontAwesomeIcon
                icon="plus"
                className="addIcon"
                onClick={() =>
                  this.props.addItem({
                    name:
                      this.state.selectedValue == "product"
                        ? this.state.product_name
                        : this.state.category_name,

                    product_id:
                      this.state.selectedValue == "product"
                        ? this.state.product_id
                        : this.state.category_id,
                    // product_id: this.state.product_id,
                    quantity: this.state.product_quantity,
                    type: this.state.selectedValue,
                    // name: this.state.product_name
                  })
                }
              />
            </>
          ) : (
            <FontAwesomeIcon
              icon="plus"
              onClick={() =>
                this.props.addItem({
                  name:
                    this.state.selectedValue == "product"
                      ? this.state.product_name
                      : this.state.category_name,
                  product_id:
                    this.state.selectedValue == "product"
                      ? this.state.product_id
                      : this.state.category_id,
                  quantity: this.state.product_quantity,
                  type: this.state.selectedValue,
                  // name: this.state.product_name
                })
              }
              className="addIcon"
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default RfqEditProducts;
