import React from "react";
import user_icon from "../../../asset/Images/user_icon.svg";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import searchIcon from "../../../asset/Images/searchIcon.svg";
import infoIconGrey from "../../../asset/Images/infoIconGrey.svg";
import crossBlue from "../../../asset/Images/crossBlue.svg";
import { Form } from "react-bootstrap";

const CustomerInfo = (props) => {
  const {
    showDropdown,
    isOpen,
    showDropDowns,
    closeDropDown,
    toggleDropdown,
    searchAccount,
    wrong_phone,
    customer_id,
    customer_name,
    existingName,
    customer_email,
    existingEmail,
    customer_type,
    customerTypeArray,
    accountCompanyAgentName,
    getPhoneValue,
    handleSelectCustomerRole,
    handleChange,
    handleSelectAccount,
    handleAccountSearch,
    showCustomerDropdown,
    setShowCustomerDropdown,
    suggestions,
    customer_mobile,
    accountPlansData,
    handleSelectLocation,
    handleInputChange,
    locationList,
    location,
    setIsOpen,
    agentData,
    agent_type,
    handleSelectAgent,
    isOpenAgent,
    toggleAgentDropdown,
    setOpenAgent,
    openCreditDropdown,
    closeCreditDropdown,
    showCreditFinance,
    customer_does_not_email,
    handleBarCustomerEmailCheckbox,
  } = props;

  return (
    <>
      <section className="customerInfoMainCnt">
        <div className="container-40">
          <div className="customerInfoEditable">
            <h1 className="pageSubHeading">Customer Info</h1>
            <div className="customerInfoCnt">
              <div className="customerInfoItem">
                <div className="customeSelectCnt">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Customer Account</label>
                    <input
                      type="text"
                      name="serachAccount"
                      value={searchAccount}
                      onChange={handleAccountSearch}
                      placeholder=""
                      style={{paddingRight:"40px"}}
                      onFocus={() =>
                        setShowCustomerDropdown(true)
                      }
                      onBlur={() => setShowCustomerDropdown(false)}
                      autoComplete="off"
                      required
                    />
                    <div className="traInputIconWrapper">
                      <img src={user_icon} width="24px" height="24px" />
                    </div>
                  </div>
                  {/* Dropdown Suggestions */}
                  {showCustomerDropdown && suggestions.length > 0 && searchAccount.length > 0 && (
                    <div className="customDropDownCnt show">
                      <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                        {suggestions.map((account) => (
                          <li
                            key={account.account_id}
                            onMouseDown={() => handleSelectAccount(account)}
                            className="dropdownItem"
                          >
                            <strong>{account.company_name}</strong>,{" "}
                            {account.city_name}, {account.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Mobile
                  <span class="mandatory">*</span>
                  </label>
                  <input
                    type="number"
                    name="customer_mobile"
                    value={customer_mobile}
                    onChange={getPhoneValue}
                    disabled={searchAccount}
                    placeholder=""
                    required
                  />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Name
                  <span class="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="customer_name"
                    value={customer_name}
                    onChange={handleChange}
                    disabled={existingName || searchAccount}
                    required
                    placeholder=""
                  />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Email</label>
                  <input
                    type="email"
                    name="customer_email"
                    onChange={handleChange}
                    value={customer_email}
                    disabled={existingEmail || searchAccount}
                    placeholder=""
                    required={
                      !customer_does_not_email ? false : true
                    }
                  />
                </div>
                <div className="CustomLabelCnt">
                  <input 
                    type="checkbox" 
                    className="mb-0"
                    onChange={handleBarCustomerEmailCheckbox}
                    />
                  <label htmlFor="name">Customer doesn't have an email</label>
                </div>
              </div>
              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                    onBlur={() => setIsOpen(false)}
                    name="customer_type"
                  >
                    <label htmlFor="name">Customer Role
                    <span class="mandatory">*</span>
                    </label>
                    <span className="selectedValue">{customer_type ? customer_type : "Select"}</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  <div className={`customDropDownCnt ${isOpen ? "show" : ""}`}>
                    <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                      {customerTypeArray.map((item) => {
                        return (
                          <li
                            className="dropdownItem"
                            key={item.id}
                            value={item.meta_value}
                            onClick={() =>
                              handleSelectCustomerRole(item.meta_value)
                            }
                          >
                            {item.meta_title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="customeSelectCnt">
              <div className="traInputWrapper">
                <label htmlFor="location">Delivery Location</label>
                <input
                  type="text"
                  id="location"
                  placeholder=""
                  value={location}
                  onChange={handleInputChange}
                  autoComplete="off"
                  style={{paddingRight:"44px"}}
                  required
                />
                <div className="traInputIconWrapper searchIcon">
                  <img src={searchIcon} width="20px" height="20px" />
                </div>
              </div>
              {showDropdown && location.length > 0 && (
                <div className="customDropDownCnt show">
                <ul className="dropdownWrapper">
                  {locationList.map((location, index) => (
                    <li className="dropdownItem" key={index} onClick={() => handleSelectLocation(location)}>
                      {location}
                    </li>
                  ))}
                </ul>
                </div>
              )}
              </div>
            </div>
              {/* <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Agent</label>
                  <input
                    type="text"
                    name="agent"
                    value={accountCompanyAgentName}
                    onChange={handleChange}
                    placeholder=""
                  />
                </div>
              </div> */}
              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCnt">
                  <button
                    className={`${agent_type && agent_type !== "Select" && agentData.filter(({name})=> name === agent_type).length === 0 ? "customSelectBtn disabled": "customSelectBtn"}`}
                    onClick={toggleAgentDropdown}
                    onBlur={() => setOpenAgent(false)}
                    name="agent_type"
                    disabled={ agent_type && agent_type !== "Select" && agentData.filter(({name})=> name === agent_type).length === 0}
                  >
                    <label htmlFor="name">Agent
                    <span class="mandatory">*</span>
                    </label>
                    <span className="selectedValue">{agent_type ? agent_type : "Select"}</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  
                  <div className={`customDropDownCnt ${isOpenAgent ? "show" : ""}`}>
                    <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                      {agentData.map((item) => {
                        return (
                          <li
                            className="dropdownItem"
                            key={item.id}
                            // value={item.name}
                            onClick={() =>
                              handleSelectAgent(item.id, item.name)
                            }
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
  
                <div className="customerInfoItem infoIconCnt">
                <div className="customeSelectCnt">
                  <button
                    className="customSelectBtn disabled"
                    style={{ cursor: "default" }}
                  >
                    <label htmlFor="name">Credit Finance</label>
                    <span className="selectedValue">
                      {Array.isArray(accountPlansData) && accountPlansData.length > 0 
                        ? `${accountPlansData.length}` 
                        : "No"} Options Available
                    </span>
                    <div
                      className="customSelectBtnIcon downArrowIcon"
                      onClick={openCreditDropdown}
                      style={{ cursor: "pointer" }}
                    >
                      {Array.isArray(accountPlansData) && accountPlansData.length > 0 ?
                      <img src={infoIconGrey} width="20px" height="20px" /> : null}
                    </div>
                  </button>
                  {accountPlansData && accountPlansData.length ? (
                  <div
                    className={`creditFinaceDtl ${
                      showCreditFinance ? "" : "d-none"
                    }`}
                  >
                    <div className="closeDropDown" onClick={closeCreditDropdown}>
                      <img
                        src={crossBlue}
                        width="16px"
                        height="16px"
                        alt="cross icon"
                      />
                    </div>
                    <ul className="creditFinaceItemWrapper">
                      <li>Name</li>
                      <li>Rate</li>
                      <li>Amount</li>
                    </ul>
                    <hr className="hr-1 my-2" />
                    {accountPlansData.map((accountPlan) => (
                    <ul key={accountPlan.plan_id} className="creditFinaceItemWrapper creditFinaceValueWrapper">
                      <li>{accountPlan.partner_name} ({accountPlan.plan_days} Days)</li>
                      <li>{accountPlan.plan_interest} %</li>
                      <li>₹{Number(accountPlan.credit_limit).toLocaleString('en-IN')}</li>
                    </ul>
                    ))}
                  </div>
                  ):null}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <hr className="hr-16" />
    </>
  );
};

export default CustomerInfo;
