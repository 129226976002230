import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import { Modal, Row, Button, Spinner, Form, Col, Alert } from "react-bootstrap";
import Constants from "../../../Config";

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;
const reload = () => window.location.reload();
class ClosedLostReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      remarks: "",
      value: [],
      is_loading: false,
      is_error: false,
      message: "",
      name: [],
      ticket_type: [],
      lost_reason: [],
      metaVlaue: "",
      label_name: "",
    };
  }

  componentDidMount() {
    this.getLostReasons();
  }

  closeModal = () => {
    // this.props.closeModal2();
    this.props.closeModal3();
  };

  handleChecbox = (e) => {
    const { ticket_type } = this.state;
    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });
    }
  };

  handlChange = (e) => {
    this.setState({
      metaVlaue: e.target.value,
    });
  };

  getLostReasons = () => {
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
    };

    return axios(apiUrl + "/get-quote-lost-reasons", params)
      .then((data) => {
        this.setState(
          {
            lost_reason: data.data.data,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });
  };

  handleSubmit = (e) => {
    const remarks = e.target.remarks.value;
    e.preventDefault();
    this.setState({
      is_loading: true,
    });
    const { requestQuoteID, status } = this.props;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: {
        quote_id: requestQuoteID,
        status: status,
        remarks: remarks,
        lost_reason: this.state.metaVlaue,
        label_name: this.state.label_name,
      },
    };

    return (
      axios(apiUrl + "/update-quotes-status", params)
        .then((res) => {
          this.setState(
            {
              message: "Update Status Successfully!",
              is_error: false,
              is_loading: false,
              is_redirect: true,
            },
            () => {
              setTimeout(() => {
                // this.props.message2("Update Status Successfully!")
                this.setState({
                  message: "",
                  is_error: false,
                  is_redirect: true,
                });
                this.closeModal();
                this.props.getAllQuote();
              }, 1000);
            }
          );
        })
        // .then((res) => {
        //   this.props.message2("Update Status Successfully")
        //   {console.log("res.data",res.data)}
        //   this.setState({
        //     data: res.data
        //   })
        //   this.setState({
        //     message:'Update Status Successfully!',
        //     is_error:false,
        //     is_loading : false,
        //     showModal:false,
        // },()=>{
        //     setTimeout(() => {
        //     }, 5000);
        // })
        // })
        .catch((error) => {
          this.setState(
            {
              message: error.response.data.message,
              is_error: true,
              is_loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  message: "",
                  is_error: false,
                });
              }, 5000);
            }
          );
        })
    );
  };

  render() {
    return (
      <Row>
        <Col md={12}>
          <Modal
            show={this.state.showModal}
            onHide={this.closeModal}
            onExit={reload}
            size="md"
            modal-dialog-centered
          >
            <Form onSubmit={this.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Body>
                  {/* <Form.Row>
                <Form.Label>
                  <input
                     id="price_issue"
                     value="price_issue"
                     name="platform"
                     type="radio"
                     onChange={this.handleChecbox}
                  />
                  Price issue
                </Form.Label>
                <Form.Label style={{paddingLeft:"30px"}}>
                  <input
                    id="lead_issue"
                    value="lead_issue"
                    name="platform"
                    type="radio"
                    onChange={this.handleChecbox}
                  />
                  Lead time issue
                </Form.Label>
                <Form.Label style={{paddingLeft:"30px"}}>
                  <input
                    id="un_responsive"
                    value="un_responsive"
                    name="platform"
                    type="radio"
                    onChange={this.handleChecbox}
                  />
                  Un Responsive
                </Form.Label>
              </Form.Row> */}

                  <Form.Row>
                    <Form.Group as={Col} controlId="exampleForm.SelectCustom">
                      <Form.Label>Reasons</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name=""
                        required
                        onChange={this.handlChange}
                        value={this.state.metaVlaue}
                      >
                        <option value="">Please select reasons</option>
                        {this.state.lost_reason.map((item, index) => {
                          return (
                            <option key={item.meta_key} value={item.meta_value}>
                              {item.meta_value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Label>Remarks</Form.Label>
                  </Form.Row>
                  <Form.Row>
                    <textarea className="form-control" name="remarks" />
                  </Form.Row>
                </Modal.Body>
              </Modal.Header>
              <Modal.Footer>
                {/* <Button variant="primary" type="submit" disabled={this.state.is_loading ? true:false}>
                                    {
                                        this.state.is_loading ? 
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </Spinner> : 'Create Ticket'
                                    }</Button> */}

                <Button variant="primary" type="submit">
                  {this.state.is_loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Submit"
                  )}
                  {/* Submit */}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </Col>
      </Row>
    );
  }
}
export default ClosedLostReason;
