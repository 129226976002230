import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Redirect, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  Spinner,
  Form,
  Col,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Constants from "../../../Config";
import "./QuoteRequest.css";
import SelectSearch from "react-select-search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallAttemptedHistory from "../Quotes/CallAttemptedHistory";
import DatePicker from "react-datepicker";
import moment from "moment";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import CreateQuote from "./CreateQuote";
import RfqEditProducts from "./RfqEditProducts";
import user_icon from '../../../asset/Images/user_icon.svg'
import downArrowGrey from '../../../asset/Images/downArrowGrey.svg'
import searchIcon from '../../../asset/Images/searchIcon.svg'
import infoIconGrey from '../../../asset/Images/infoIconGrey.svg'
import deleteIcon from '../../../asset/Images/delete_new_icon.svg'
import crossBlue from '../../../asset/Images/crossBlue.svg'
import downloadIcon from '../../../asset/Images/downloadIconNew.svg'
import alertMsg from "../../../asset/Images/danger_alert.svg"
import viewBtn from "../../../asset/Images/viewBtn.svg"
import callIcon from "../../../asset/Images/callIcon.svg"
import backArrowNew from "../../../asset/Images/backArrowNew.svg"
import backArrowInactive from "../../../asset/Images/backArrowInactive.svg"
import edit from "../../../asset/Images/edit.svg"

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;
const b2bApiUrl = Constants.B2B_API_URL;
const now = moment().toDate();

// const CallCount = 10;
const business_call_attempt = Constants.BUSINESS_CALL_ATTEMPT;

class Quote extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    startDate: "",
    startDateShow: "",
    is_calling: false,
    isLoading: false,
    isLoadingReason: false,
    isShowSuccessMsg: false,
    username: "",
    customer_name: "",
    accountName:"", // add new key
    customer_email: "",
    customer_mobile: "",
    assigned_to:"",
    buying_for: "",
    quote_type: "",
    useremail: "",
    product_name: "",
    product_quantity: "",
    productArray: [],
    productList: [],
    reasons: [],
    error: [],
    call_attempt_history: "",
    product_id: "",
    call_status: "",
    current_call_status: "",
    isSaveLoading: false,
    isShowRight: false,
    agent_id: "",
    call_attempt_count: 0,
    call_id: "",
    message: "",
    rfq_message: "",
    status: "",
    rfq_status: "",
    call_response: "",
    call_status_array: [],
    all_quote_types: [],
    call_reason: "",
    remarks: "",
    assigned_to: "",
    product_data: [],
    attachment: [],
    is_redirect: false,
    rfq_id: " ",
    phone: "",
    agent_mandatory: false,
    call_title: "",
    priority: "",
    prevlocation: "",
    location: "",
    locationArray: [],
    locationList: [],
    location_id: "",
    campaign: "",
    isDisable: true,
    rfqStatus: "",
    selectedValue: "",
    category_name: "",
    category_id: "",
    type: "product",
    new_add: false,

    categoryList: [],
    categoryArray: [],
    itemArray: [],
    finalArray: [],
    id: 0,
    itemAdded: false,
    finalAdd: false,
    // Business type states
    businessGroup: [],
    checkedBusinessGroup: 0,
    checkedBusinessType: [],
    businessType: [],
    business_type_other: "",
    businessType_required: false,
    showBusinesstype: false,
    is_multiselect_search: false,
    typeArray: [],
    customer_type: "",
    proAgentName: "",
    proBusinessName: "",
    business_group: 0,
    business_type: [],
    others: false,
    validated: false,
    role: "",
    supportRemark: "",
    quoteDetails: [],
    callBackMandate: false,
    dateDisable: false,
    minTime: this.calculateMinTime(new Date()),
    call_type: "",
    called: false,
    wrong_phone_alert: "",
    logined_user_id: [],
    utmSource: '',
    utmMedium: '',
    pincode:"",
    showConfirmationMessage: false,
    SRId:"",
    sourcingStatus:"",
    isConfirmed: false,
    showDropdown: false,
    isOpen: false,
    callStatusValue:"",
    isOpenCallStatus: false,
  };
  componentDidMount() {
    this.getQuoteDetail();
    this.getAllQuoteUsers();
    // this.getBusinessGroup();
  }

  getCallStatusValue = (value) => {
    // this.setState({
    //   isOpenCallStatus: false,
    //   callStatusValue: value
    // })
    this.setState(prevState => ({ 
      isOpenCallStatus: !prevState.showDropdown, 
      callStatusValue: value 
    }));
  }

  showDropDown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
};

closeDropDown = () => {
    this.setState({ showDropdown: false });
};

toggleDropdown = () => {
    this.setState(prevState => ({ callStatusValue: !prevState.callStatusValue }));
};
  closeModal = () => {
    this.props.closeModal();
  };
  closeConfirmationModal = () => {
    this.setState({
      showConfirmationMessage:false
    })
  }
  openConfirmationModal = () => {
    this.setState({
      showConfirmationMessage:true
    })
  }
  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
        checkedBusinessGroup: this.state.business_group,
        checkedBusinessType: this.state.business_type,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          // data:{id:this.props.businessData.id}
          data: { id: 2105 },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState(
              {
                businessGroup: data.data.data,
                isLoading: false,
              },
              () => {
                if (this.state.business_group) {
                  let selectedBusinessGroupID =
                    this.state.businessGroup.findIndex((item) => {
                      return item.id == this.state.business_group;
                    });
                  // this.props.business_group2(selectedBusinessGroupID)
                  let selectedBusinessType =
                    this.state.businessGroup[selectedBusinessGroupID]
                      .business_type;
                  this.setState({
                    businessType: selectedBusinessType,
                  });
                  // this.props.business_type2(selectedBusinessType)
                }
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  handleBusinessGroup = (e) => {
    this.setState({
      showBusinesstype: true,
      checkedBusinessGroup: e.target.id,
      business_group: e.target.id,
    });
    // this.props.business_group2(e.target.id);
    let id = e.target.id;
    if (id == 100) {
      this.setState({
        others: true,
        checkedBusinessGroup: id,
        checkedBusinessType: [],
        is_multiselect_search: false,
        business_group: id,
        business_type: [],
        // required_state: false,
        agentid: "0",
      });
      // this.props.agent2(this.state.agentid));
      // this.props.business_group2(id)
    } else {
      if (id != 2) {
        this.setState({
          is_multiselect_search: false,
        });
      } else {
        this.setState({
          is_multiselect_search: true,
        });
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              // this.props.business_group2(selectedBusinessGroupID)
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
              // this.props.business_type2(selectedBusinessType)
            }
          );
        }
      );
    }
  };
  handleBusinessType = (e) => {
    this.setState({
      businessType_required: false,
    });
    let selectedArray = this.state.checkedBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedBusinessType: selectedArray,
        business_type: selectedArray,
      });
      // this.props.business_type2(selectedArray);
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      this.setState({
        checkedBusinessType: filteredArray,
        business_type: filteredArray,
      });
      // this.props.business_type2(filteredArray);
    }
  };
  handletypeOther = (e) => {
    this.setState({
      business_type_other: e.target.value,
    });
  };
  getCallStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        quote_type: this.state.quote_type,
        call_attempt_number: this.state.call_attempt_count,
        rfqId: this.props.requestQuoteID,
      },
    };
    return axios(apiUrl + "/get-call-status", options)
      .then((data) => {
        this.setState({
          call_status_array: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getAllQuoteUsers = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        agent_list: "",
        id: [56],
        showAll: "true",
        slug: "quote-requests",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          all_quote_users: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleProductData = (e) => {
    // let newArray = this.state.finalArray.map(product => {
    //   return Object.assign({}, product, {
    //     name: e
    //   })
    // })
    let initialArray = this.state.finalArray;
    initialArray.map((item, ind) => {
      item.name = e.target.value;
    });
    this.setState({
      finalArray: initialArray,
      // product_name: e
    });
    initialArray.map((item) => {
      this.setState({
        product_name: item.name,
      });
    });
  };

  handleChangeForCallStatus = (e) => {
    // if(e.target.value == this.state.call_status_array.filter((item) => {
    //   return [278,282].includes(item.id)
    // })){
    //   this.setState({agent_mandatory: true})
    // }
    if (e.target.value) {
      let call_status_array = this.state.call_status_array.filter((item) => {
        return item.id == e.target.value;
      });
      let rfqStatus = call_status_array[0].rfq_status;
      if (["Call Back Scheduled"].includes(call_status_array[0].status)) {
        this.setState({
          callBackMandate: true,
        });
      } else {
        this.setState({
          callBackMandate: false,
          startDate: "",
        });
      }
      let call_title = call_status_array[0].status;
      this.setState({
        current_call_status: e.target.value,
        call_status: e.target.value,
        rfq_status: rfqStatus,
        call_title: call_title,
        // is_redirect: true
      });
      // if (
      //   ["Converted to Quote", "Converted to MQ"].includes(this.state.rfqStatus)
      // ) {
      //   this.setState({
      //     current_call_status: e.target.value,
      //     call_status: e.target.value,
      //     rfq_status: this.state.rfqStatus,
      //     call_title: call_title,
      //     is_redirect: false
      //   });
      // } else {
      //   this.setState({
      //     current_call_status: e.target.value,
      //     call_status: e.target.value,
      //     rfq_status: rfqStatus,
      //     call_title: call_title,
      //     // is_redirect: true
      //   });
      // }
    } else {
      this.setState({
        current_call_status: e.target.value,
        call_status: e.target.value,
        rfq_status: "",
        call_title: "",
        // is_redirect: true
      });
    }
  };
  getQuoteDetail = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },
          data: {
            request_quote_id: this.props.requestQuoteID,
            logined_user_id: this.props.agent_id
              ? [this.props.agent_id]
              : [this.props.getUserDetails.user_id],
            quote_type: this.props.quote_type,
            selectedStatus: this.state.selectedStatus,
            selectedType: this.state.selectedType,
            total_item: this.state.total_item,
            current_page: this.state.current_page,
            // buying_for: this.state.selectedBuyingFor,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
          },
        };
        return axios(apiUrl + "/get-quote-request", options)
          .then((data) => {
            var options1 = { year: "numeric", month: "long", day: "numeric" };
            let requestQuoteData = data.data.data[0];
            const srID = requestQuoteData.sourcing_id;
            const sourcingStatus = requestQuoteData.sourcing_status;
            let supportRemark = data.data.data[0].lastCallAttempt.agent_note
              ? data.data.data[0].lastCallAttempt.agent_note
              : requestQuoteData.remarks;
            let quoteDetails = data.data.data[0].quoteDetails;
            requestQuoteData.product = requestQuoteData.product.map((item) => {
              return { ...item, type: item.type ? item.type : this.state.type };
            });
            this.rename(requestQuoteData.product);

            let callback_schedule = new Date(
              requestQuoteData.callback_schedule
            );
            let callBackDate =
              callback_schedule.toLocaleString("en", options1) +
              " " +
              callback_schedule.toLocaleTimeString();
            let showCallBackDate = requestQuoteData.callback_schedule
              ? callBackDate
              : "";

            // Code to convert busines type in array from string
            let arr = requestQuoteData.business_type.split(",");

            let arr2 = arr.map((item) => Number(item));
            this.setState(
              {
                isLoading: false,
                accountName: requestQuoteData.accountName ? requestQuoteData.accountName : "",
                SRId:srID,
                sourcingStatus: sourcingStatus,
                customer_name: requestQuoteData.customer_name,
                customer_mobile: requestQuoteData.customer_mobile,
                assigned_to: requestQuoteData.assigned_to,
                customer_email: requestQuoteData.customer_email,
                buying_for: requestQuoteData.buying_for,
                agent_id:
                  requestQuoteData.agent_id == null
                    ? ""
                    : requestQuoteData.agent_id,
                rfq_message: requestQuoteData.message,
                quote_type: requestQuoteData.quote_type,
                assigned_to: requestQuoteData.assigned_to,
                startDateShow: showCallBackDate,
                product_data: requestQuoteData.product,
                // attachment: requestQuoteData.filePath,
                attachment: requestQuoteData.attachments,
                priority: requestQuoteData.priority,
                location: requestQuoteData.location,
                location_id: requestQuoteData.location_id.toString(),
                pincode: requestQuoteData.pincode, // add pincode 
                campaign: requestQuoteData.campaign,
                rfqStatus: requestQuoteData.status,
                business_group: requestQuoteData.business_group,
                business_type: arr2[0] == 0 ? [] : arr2,
                role: requestQuoteData.role,
                supportRemark: supportRemark,
                quoteDetails: quoteDetails,
                utmSource: requestQuoteData.utm_source,
                utmMedium: requestQuoteData.utm_medium,

                // business_other: requestQuoteData.business_type_other,
              },
              () => {
                this.getCallAttemptHistory();
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  getCallAttemptCount = () => {
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },
        data: {
          requestQuoteID: this.props.requestQuoteID,
          quote_type: this.state.quote_type,
        },
      };
      return axios(apiUrl + "/call-count", options)
        .then((data) => {
          this.setState(
            {
              call_attempt_count: data.data.data.count,
              call_type:
                this.state.call_type == "Knowlarity" ? "Knowlarity" : "Manual",
              isShowRight: true,
              called: true,
            },
            () => {
              this.getCallStatus();
              resolve(this.state.call_attempt_count);
            }
          );
        })
        .catch((error) => reject(error));
    });
  };
  getCall = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    if (this.state.customer_mobile.length == 10) {
      this.setState(
        {
          is_calling: true,

          wrong_phone_alert: "",
        },
        () => {
          this.getCallAttemptCount()
            .then((data) => {
              // if (data <= CallCount) {

              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.props.getUserDetails?.token}`,
                },
                data: {
                  phone: this.state.customer_mobile,
                  agentID: loginedUserDetails.user_id,
                  type:"rfq"
                },
              };
              return axios(apiUrl + "/make-call", options)
                .then((data) => {
                  let callData = data.data.data;
                  setTimeout(() => {
                    this.setState({
                      is_calling: false,
                      call_id: callData.success.call_id,
                      message: callData.success.message,
                      status: callData.success.status,
                      call_response: JSON.stringify(callData),
                    });
                  }, 1500);
                })
                .catch((error) => console.log(error));

              // } else {
              //   this.setState({
              //     is_calling: false,
              //     error: "You have crossed your limit to call!",
              //   });
              // }
            })

            .catch((error) => console.log(error));
        }
      );
    } else {
      this.setState(
        {
          wrong_phone_alert: "Please Enter Correct phone Number",
        },
        () => {
          setTimeout(() => {
            this.setState({
              wrong_phone_alert: "",
            });
          }, 5000);
        }
      );
    }
  };

  getCallAttemptHistory = () => {
    if (this.state.business_group) {
      this.setState({
        showBusinesstype: true,
        checkedBusinessType: this.state.business_type,
      });
    }
    if (this.state.business_group == 100) {
      this.setState({
        others: true,
      });
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: {
        requestQuoteID: this.props.requestQuoteID,
        quote_type: this.state.quote_type,
      },
    };
    return axios(apiUrl + "/call-history", options)
      .then((data) => {
        if (data.data.data.length > 0) {
          this.setState({
            call_attempt_history: data.data.data,
            isShowRight: true,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  changeRFQ = (val) => {
    this.submitForm(val);
  };

  // getItems=(e) => {
  //  let itemArray = this.state.product_data
  //  this.setState({
  //   itemArray: itemArray
  //  })
  // }
  rename = (e) => {
    let itemArray = e.map((obj) => {
      obj["name"] = obj["productName"]; // Assign new key
      delete obj["productName"]; // Delete old key
      obj["quantity"] = obj["productQuantity"]; // Assign new key
      delete obj["productQuantity"]; // Delete old key
      obj["price"] = obj["productPrice"]; // Assign new key
      delete obj["productPrice"];
      delete obj["model"];
    });

    this.setState({
      finalArray: e, // initiating value of product data to itemarray
    });
  };
  handleSelect = (e) => {
    this.setState({
      selectedValue: e.target.value,
      type: e.target.value,
    });
  };
  addItem = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;

    this.setState(
      {
        itemArray: this.state.itemArray.concat(e),
        // finalArray: this.state.finalArray.concat(e),
        id: newId,
        new_add: true,
        finalAdd: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            finalAdd: false,
          });
        }, 2000);
      }
    );
    // this.state.product_data.push(e);
  };
  addNew = (e) => {
    this.setState(
      {
        finalArray: this.state.finalArray.concat(e),
        itemAdded: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            itemAdded: false,
          });
        }, 2000);
      }
    );
  };
  removeItem = (e) => {
    let id = e;
    let itemArray = this.state.itemArray;
    itemArray.splice(id, 1);
    let finalArray = this.state.finalArray;
    if (finalArray.length == this.state.product_data) {
      finalArray.splice(e, 1);
    } else {
      finalArray.splice(e + 1, 1);
    }
    // let displayProductArray = this.state.displayProductArray;
    // displayProductArray.splice(id, 1);
    this.setState({
      itemArray,
      finalArray,
      // displayProductArray,
    });
  };
  addFinalArrayItem = (e) => {
    // this.setState({
    //   finalArray: this.state.finalArray.concat
    // })
    this.setState({
      finalArray: this.state.finalArray.concat(e),
      finalAdd: false,
    });
  };
  // removeProduct = (e) => {
  //   let productData = this.state.itemArray;
  //   productData.splice(e, 1);
  //   this.setState({
  //     itemArray: productData,
  //   });
  // };

  submitForm = (e) => {
    localStorage.removeItem("dataKey2");
    const filteredArray = this.state.finalArray
    .map(({ brand, brand_id, quantity_type, ...rest }) => {
    // Add quantity to the rest of the object, ensuring it defaults to 0 if undefined
    return {
      ...rest,
      quantity: rest.quantity ? rest.quantity : 0,
    };
  });
    // Ensure unique items by name
  const names = filteredArray.map((o) => o.name);
  const uniqueFilteredArray = filteredArray.filter(
    ({ name }, index) => !names.includes(name, index + 1)
  );
    let buttonClickType = e.target.id;
    if (buttonClickType === "submit") {
      this.setState(
        {
          isSaveLoading: true,
          validated: true,
        },
        () => {
          if (this.state.called) {
            let loginedUserDetails = JSON.parse(
              localStorage.getItem("userData")
            );
            let params = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.getUserDetails?.token}`,
              },

              data: {
                requested_id: this.props.requestQuoteID,
                call_id: this.state.call_id,
                message: this.state.message,
                rfq_details: this.state.rfq_message,
                status: this.state.status,
                call_response: this.state.call_response,
                call_status: this.state.call_status,
                call_title: this.state.call_title,
                remarks: this.state.remarks,
                // agent_id : loginedUserDetails.user_id,
                call_attempt_count: this.state.call_attempt_count,
                rfq_status: this.state.rfq_status,
                quote_type: this.state.quote_type,
                assigned_to: this.state.assigned_to,
                callback_schedule: this.state.startDate
                  ? moment(this.state.startDate)
                      .add({ hours: "5.5" })
                      .toISOString()
                  : "",
                // buying_for: this.state.buying_for,
                agent_id:
                  this.state.agent_id == 0
                    ? ""
                    : this.state.agent_id.toString(),
                location: this.state.location,
                campaign: this.state.campaign,
                priority: this.state.priority,
                location_id: this.state.location_id,
                // pincode: this.state.pincode, // add new pincode
                products: uniqueFilteredArray,
                calling_mode: this.state.call_type,
                // business_type: this.state.business_type,
                // business_group: Number(this.state.business_group),
                // business_type_other: this.state.business_type_other,
                // callback_schedule : new Date().toLocaleString(),
              },
            };
            return axios(apiUrl + "/quote-request-submit", params)
              .then((data) => {
                this.setState({
                  isSaveLoading: false,
                  isShowSuccessMsg: true,
                  error: [],
                  is_redirect: true,
                  rfq_id: this.props.requestQuoteID,
                });

                setTimeout(() => {
                  this.setState({
                    isShowSuccessMsg: false,
                  });
                  this.closeModal();
                  this.props.getAllQuote();
                }, 3000);
              })
              .catch((error) => {
                this.setState({
                  isSaveLoading: false,
                  error: error.response.data.message,
                });
              });
          } else {
            this.setState({
              error: "Please have a call first!",
              isSaveLoading: false,
            });
          }
        }
      );
      setTimeout(() => {
        this.setState({
          error: [],
          isShowSuccessMsg: false,
        });
      }, 3000);
    }
  };


  handleSubmitForm = () => {
    if (this.state.SRId != "" && 
      ["new", "pending", "reopen"].includes(this.state.sourcingStatus) && 
      ["Closed - Lost", "Direct Order", "Duplicate"].includes(this.state.rfq_status) && !this.state.showConfirmationMessage) 
    {
      this.setState({ showConfirmationMessage: true });
    }
    else {
      this.submitForm({ target: { id: "submit" } });
      this.setState({ showConfirmationMessage: false });
    }
  };
  handleDateChange = (date) => {
    //  console.log( moment(date).tz("Asia/Kolkata"), "date")
    if (date) {
      this.setState({
        minTime: this.calculateMinTime(date),
      });
    }
    this.setState(
      {
        startDate: date,
      },
      () => {
        if (this.state.startDate == null) {
          this.setState({
            startDate: "",
            dateDisable: false,
          });
        } else {
          this.setState({
            startDate: date,
          });
        }
      }
    );
  };
  handleLocation = (e) => {
    this.setState({ location: e });
  };
  getLocation = (e) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return axios(apiUrl + "/get-suggested-cities", config)
      .then((data) => {
        this.setState({
          locationList: data.data.data,
          locationArray: data.data.data.map((item) => {
            return {
              name: `${item.name},${item.state_name}`,
              value: `${item.name},${item.state_name}`,
            };
          }),
        });
      })
      .catch((err) => console.log(err.response));
  };
  // getCities = (e) => {
  //   // let input = document.getElementsByClassName("select-search__input");

  //   let location = this.state.locationList.filter(
  //     (el) => `${el.name},${el.state_name}` == e
  //   )[0];
  //   console.log(location, "acte::::::::");
  //   this.setState({
  //     location: e,
  //     location_id: location.city_id.toString(),
  //   });
  // };

  handleDisable = (e) => {
    this.state.isDisable
      ? this.setState({ isDisable: false })
      : this.setState({ isDisable: true });
    // this.setState({
    //   product_name: this.state.finalArray.name,
    //   product_quantity: this.state.finalArray.quantity,
    // });
    this.state.finalArray.map((item, ind) => {
      this.setState({
        product_name: item.name,
        product_quantity: item.quantity,
      });
    });
  };

  setItem = (QUOTEID) => {
    var data = {
      quoteID: QUOTEID,
      // rfqID: QUOTEID,
      // productsArr: productsArr,
      // customer_id: customerid,
    };
    localStorage.removeItem("dataQuote2");
    localStorage.removeItem("dataKey2");
    localStorage.setItem("dataQuote", JSON.stringify(data));
  };

  calculateMinTime(date) {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).add({ minutes: 1 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate();
  }
  handleCallType = (e) => {
    this.setState(
      {
        call_type: "Knowlarity",
      },
      () => {
        this.getCall();
      }
    );
  };

  handleAttachmentClick(url) {
    window.open(url, '_blank'); // Open link in a new tab
  }

  render() {

    if (
      this.state.is_redirect === true &&
      this.state.rfq_status == "Converted to Quote" &&
      !["Converted to Quote", "Converted to MQ"].includes(this.state.rfqStatus)
    ) {
      //  let data ={phone: this.state.customer_mobile,rfqdata: this.props.requestQuoteID}
      return (
        <Redirect
          to={{
            pathname: "/createQuote",
            state: {
              quoteID: 0,
              rfqID: this.props.requestQuoteID,
              phone: this.state.customer_mobile,
              rfq_status: this.state.rfq_status,
              call_response: this.state.call_response,
              call_id: this.state.call_id,
              message: this.state.message,
              status: this.state.status,
              call_status: this.state.call_status,
              remarks: this.state.remarks,
              agent_id: this.state.agent_id,
              call_attempt_count: this.state.call_attempt_count,
              rfq_quote_type: this.state.quote_type,
            },
            // state:{data}
            // state: {phone:this.state.customer_mobile }
          }}
        />
      );
    }

    return (
      <Modal
         className="business_process_wrapper body-scroll"
        centered
        // show={this.props.show}
        show={false}
        onHide={this.closeModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-column">
          <h5 className="on_call_title">On Call Information..</h5>
            {this.state.quoteDetails &&
              this.state.quoteDetails.map((item) => {
                if (item.quote_id) {
                  return (
                    <>
                      {" "}
                      <b>
                        Quote ID: {item.quote_id} &nbsp;&nbsp; Quote Type:{" "}
                        {item.quote_type == "1" ? "Normal" : "Magic"}{" "}
                        &nbsp;&nbsp; Quote Status: {item.quote_status}{" "}
                        &nbsp;&nbsp; Quote Validity: {item.valid_upto}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                          href="/createQuote"
                          onClick={() => this.setItem(item.quote_id)}
                          target="_blank"
                        >
                          Quote Detail
                        </a>
                      </b>{" "}
                    </>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </Modal.Header>
        {this.state.isLoading ? (
          <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <Modal.Body className="business_approve_process">
            <Form
              noValidate
              validated={this.state.validated}
              encType="multipart/form-data"
            >
              {this.state.wrong_phone_alert ? (
                <div className="alert alert-danger">
                  {this.state.wrong_phone_alert}
                </div>
              ) : null}
              {this.state.error.length > 0 ? (
                <div
                  className={
                    this.state.error
                      ? "alert alert-danger"
                      : "alert alert-success"
                  }
                >
                  {this.state.error}
                </div>
              ) : null}
              {this.state.isShowSuccessMsg ? (
                <div className="alert alert-success">
                  You have successfully updated the status
                </div>
              ) : null}
              {this.state.itemAdded ? (
                <div
                  className={
                    this.state.itemAdded
                      ? "alert alert-success"
                      : "alert alert-false"
                  }
                  variant={this.state.itemAdded ? "success" : "danger"}
                >
                  Item Added successfully
                </div>
              ) : null}
              <Form.Row>
                <Col
                  sm={this.state.isShowRight ? "8" : "12"}
                  className={
                    this.state.isShowRight ? "quoteStatus8" : "quoteStatus12"
                  }
                >
                 
                  <Form.Row>
                    <Col sm={5}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Mobile</Form.Label>
                        <br />
                        <Form.Control
                          className="mobileNumber"
                          placeholder="Mobile"
                          disabled
                          name="customer_mobile"
                          value={this.state.customer_mobile}
                          onChange={this.handleChange}
                        />
                        
                        {/* <Button
                            onClick={this.handleCallType}
                            // value="Knowlarity"
                              >
                                Call
                              </Button> */}
                           
                          <ButtonGroup variant="success">
                            <Button
                              className="callButton"
                              onClick={this.handleCallType}
                              disabled={
                                this.state.is_calling || this.props.rfqDisable
                              }
                              value="Knowlarity"
                            >
                              Call
                            </Button>
                            <DropdownButton
                              as={ButtonGroup}
                              id="bg-nested-dropdown"
                            >
                              <Dropdown.Item eventKey="Manual">
                                <Button
                                  className="callButton"
                                  onClick={this.getCallAttemptCount}
                                  value="Manual"
                                  disabled={
                                    this.state.is_calling ||
                                    this.props.rfqDisable
                                  }
                                >
                                  Call Manually
                                </Button>
                              </Dropdown.Item>
                            </DropdownButton>
                          </ButtonGroup>
                      
                        
                      </Form.Group>
                    </Col>
                    {/* Add Account Name Start here */}
                    <Col sm={2}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Control
                          // placeholder="AccountName"
                          disabled
                          name="accountName"
                          value={this.state.accountName}
                          onChange={this.handleChange}
                          required
                        />{" "}
                        <Form.Control.Feedback type="invalid">
                          Please enter billing first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Add Account Name Ends here */}
                    <Col sm={2}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          placeholder="Name"
                          disabled
                          name="customer_name"
                          value={this.state.customer_name}
                          onChange={this.handleChange}
                          required
                        />{" "}
                        <Form.Control.Feedback type="invalid">
                          Please enter billing first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          placeholder="Email"
                          disabled
                          name="customer_email"
                          value={this.state.customer_email}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    {/* condition to check for edit */}
                    {/* {[
                      "Closed - Lost",
                      "Closed - Won",
                      "Converted to Quote",
                    ].includes(this.state.rfqStatus_for_editCheck) ? null : (
                      <Col sm={12}>
                        <Button
                          variant="light"
                          type="button"
                          // onClick={this.handleDisable}
                        >
                          <FontAwesomeIcon icon="edit" />
                          {this.state.isDisable ? "Edit" : "Reset"}
                        </Button>
                      </Col>
                    )} */}
                    {/* <Col sm={12}>
                        <Button
                          variant="light"
                          type="button"
                          // onClick={this.handleDisable}
                        >
                          <FontAwesomeIcon icon="edit" />
                          {this.state.isDisable ? "Edit" : "Reset"}
                        </Button>
                      </Col> */}

                    {!this.state.isDisable ? (
                      <>
                        {this.state.finalArray.length == 0 ? (
                          <Col sm={12}>
                            <FontAwesomeIcon
                              icon="plus"
                              onClick={() =>
                                this.addItem({
                                  name: this.state.product_name,
                                  product_id: this.state.product_id,
                                  quantity: this.state.product_quantity,

                                  // name: this.state.product_name
                                })
                              }
                              className="addIcon"
                            />
                            <div>Add Item</div>
                          </Col>
                        ) : null}
                        {this.state.product_data.map((data, ind) => (
                          <>
                            {this.state.selectedValue == "" ? (
                              <>
                                <Col sm={2}>
                                  <Form.Group
                                    controlId="formBasicTypeName"
                                    // key={item.index}
                                  >
                                    <Form.Label>Choose Type</Form.Label>
                                    <Form.Control
                                      as="select"
                                      custom
                                      onChange={this.handleSelect}
                                      value={this.state.selectedValue}
                                      //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                                    >
                                      <option value="product">Product</option>
                                      <option value="category">Category</option>
                                      <option value="other">Other</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Product {ind + 1}</Form.Label>
                                    <Form.Control
                                      placeholder="Product"
                                      name="product_name"
                                      // defaultValue={data.name}
                                      value={this.state.product_name}
                                      onChange={this.handleChange}
                                      onBlur={() => {
                                        this.handleProductData(
                                          this.state.product_name
                                        );
                                      }}
                                      disabled={this.state.isDisable}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control
                                      placeholder="Quantity"
                                      name="product_quantity"
                                      value={this.state.product_quantity}
                                      // value={this.state.product_quantity}
                                      onChange={this.handleChange}
                                      disabled={this.state.isDisable}
                                    />
                                  </Form.Group>
                                </Col>

                                <Col sm={2}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                      placeholder="Price"
                                      name="price"
                                      value={data.price}
                                      onChange={this.handleChange}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <FontAwesomeIcon
                                    icon="minus"
                                    id={ind}
                                    onClick={() => this.removeItem(ind)}
                                    className="removeIcon"
                                  />
                                </Col>
                                <Col sm={1}>
                                  <FontAwesomeIcon
                                    icon="plus"
                                    id={ind}
                                    onClick={() =>
                                      this.addItem({
                                        name: this.state.product_name,
                                        product_id: this.state.product_id,
                                        quantity: this.state.product_quantity,
                                        type: data.type,

                                        // name: this.state.product_name
                                      })
                                    }
                                    className="removeIcon"
                                  />
                                </Col>
                              </>
                            ) : (
                              <RfqEditProducts
                                ind={ind}
                                itemArray={this.state.itemArray}
                                finalArray={this.state.finalArray}
                                addItem={this.addItem}
                                addNew={this.addNew}
                                removeItem={this.removeItem}
                                addFinalArrayItem={this.addFinalArrayItem}
                                finalAdd={this.state.finalAdd}
                              />
                            )}
                          </>
                        ))}
                        {this.state.new_add ? (
                          <>
                            {this.state.itemArray.map(
                              (
                                item,
                                ind // product_data is to be channged to productDetailAraray
                              ) => (
                                <RfqEditProducts
                                  ind={ind}
                                  itemArray={this.state.itemArray}
                                  finalArray={this.state.finalArray}
                                  addItem={this.addItem}
                                  addNew={this.addNew}
                                  removeItem={this.removeItem}
                                  addFinalArrayItem={this.addFinalArrayItem}
                                  finalAdd={this.state.finalAdd}
                                />
                              )
                            )}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {this.state.product_data.map((data, index) => (
                          <>
                          {data.type != "attachments" ? (<>
                            <Col sm={6} key={index}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p style={{ marginBottom: 0 }}>
                                    Product
                                  </p>
                                  <a
                                    style={{
                                      marginLeft: this.state.isShowRight
                                        ? "320px"
                                        : "530px",
                                      cursor: "pointer",
                                    }}
                                    href={data.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <FontAwesomeIcon
                                      // className="editIcon"
                                      icon="external-link-alt"
                                      // href={data.product_id ? data.productLink : data.category_id ? data.categoryLink : ""}
                                    />
                                  </a>
                                </Form.Label>
                                <Form.Control
                                  placeholder="Product"
                                  name="product_name"
                                  value={data.name}
                                  onChange={this.handleChange}
                                  disabled={this.state.isDisable}
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={3}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Quantity</Form.Label>
                                <Form.Control
                                  placeholder="Quantity"
                                  name="product_quantity"
                                  value={data.quantity}
                                  onChange={this.handleChange}
                                  disabled={this.state.isDisable}
                                />
                              </Form.Group>
                            </Col>

                            <Col sm={3}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                  placeholder="Price"
                                  name="price"
                                  value={data.price}
                                  onChange={this.handleChange}
                                  disabled={this.state.isDisable}
                                />
                              </Form.Group>
                            </Col>
                            </>):null}
                          </>
                        ))}
                      </>
                    )}

                   <>
                   <Col sm={12}>
                   <ul className="row m-0" style={{ listStyleType:"none" }}>
                    {this.state.product_data.map((attachments,index) => (
                      <>
                      {attachments.type === "attachments" ? (
                      <li className="p-1" key={index}>
                        <a 
                          style={{ textDecoration:"underline", cursor:"pointer" }}
                          href={attachments.name}
                          // target="_blank"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAttachmentClick(attachments.name);
                          }}
                        >
                          {`Attachment`}
                        </a>
                      </li>
                       ): null}
                      </>
                    ))}
                    </ul>
                  </Col>
                  </>
                  
                    {/* {this.state.attachment ? (
                      <Col sm={12}>
                        <Form.Label>Attachment</Form.Label>{" "}
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() =>
                            window.open(this.state.attachment, "_blank")
                          }
                        />
                      </Col>
                    ) : (
                      ""
                    )} */}
                    <Col sm={3}>
                      <Form.Group controlId="formGridBuyingFor">
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                          as="select"
                          name="priority"
                          onChange={this.handleChange}
                          value={this.state.priority}
                          disabled={this.state.isDisable}
                        >
                          <option value="0">Select Priority</option>
                          <option value="Highest">Highest</option>
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="formPinCode">
                        <Form.Label>
                          PinCode
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="pincode"
                          placeholder="PinCode"
                          onChange={this.handleChange}
                          value={this.state.pincode}
                          disabled
                        />
                      </Form.Group>
                  </Col>
                    {/* location form dropdown in below column */}
                    <Col sm={3} className="customeAutoComplete">
                      <Form.Group controlId="formGridCity">
                        <Form.Label>Location</Form.Label>
                        {/* <SelectSearch
                            options={this.state.locationArray}
                            getOptions={(query) => this.getLocation(query)}
                            search
                            placeholder="City name"
                            onChange={this.getCities}
                            disabled={this.state.isDisable}
                            // onInput={this.getCities}

                            value={this.state.location}
                            defaultValue={this.state.location}
                            required
                          /> */}
                        {/* <input
                        id="locationInput"
                          type="text"
                          // list="array"
                          onChange={this.handleLocation}
                          value={this.state.location}
                          disabled={this.state.isDisable}
                          className="w-100 p-2 rounded"
                          placeholder="Enter Your Destination"
                        /> */}
                        {/* <datalist id="array">
                        
                          {this.state.locationArray.map((item) => {
                            return <option value={item.value}>{item.name}</option>;
                          })}
                        </datalist> */}
                        <Form.Control
                          name="location"
                          placeholder="Enter Location"
                          onChange={this.handleChange}
                          value={this.state.location}
                          disabled={this.state.isDisable}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                    <Form.Group controlId="formEditcampaign">
                        <Form.Label>Role</Form.Label>
                    <Form.Control
                          name="Role"
                          placeholder="Enter Role"
                          onChange={this.handleChange}
                          value={this.state.role}
                          disabled={this.state.isDisable}
                        />
                      </Form.Group>

                    </Col>

                    <Col sm={6}>
                      <Form.Group controlId="formEditcampaign">
                        <Form.Label>Campaign Details</Form.Label>
                        <Form.Control
                          name="campaign"
                          placeholder="Enter Campaign Details"
                          onChange={this.handleChange}
                          value={this.state.campaign}
                          disabled={this.state.isDisable}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                    <Form.Group controlId="formUtmSource">
                          <Form.Label>UTM Source</Form.Label>
                          <Form.Control
                            name="utmsource"
                            value={this.state.utmSource}
                            disabled
                            // disabled={this.state.isDisable}
                          />
                        </Form.Group>
                    </Col>
                    <Col sm={3}>
                    <Form.Group controlId="formUtmMedium">
                          <Form.Label>UTM Medium</Form.Label>
                          <Form.Control
                            name="utmMedium"
                            value={this.state.utmMedium}
                            disabled
                            // disabled={this.state.isDisable}
                          />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={12}>
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "spaceBetween",
                        }}
                      >
                        <Form.Group controlId="formGridCustomerName">
                          <Form.Label style={{ marginRight: "63px" }}>
                            <b> Business Group </b>
                          </Form.Label>
                          {this.state.businessGroup &&
                            this.state.businessGroup.map((item, index) => {
                              return (
                                <Form.Check
                                  type="radio"
                                  inline
                                  key={item.id + index}
                                  checked={item.id == this.state.business_group}
                                  id={item.id}
                                  name="business_group"
                                  label={item.name}
                                  onChange={this.handleBusinessGroup}
                                  // required
                                />
                              );
                            })}
                        </Form.Group>
                      </div>
                    </Col>

                    {this.state.showBusinesstype ? (
                      <Col md={12}>
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "spaceBetween",
                          }}
                        >
                          <Form.Group controlId="formGridCustomerName">
                            <Form.Label style={{ marginRight: "75px" }}>
                              <b> Business Type </b>
                            </Form.Label>
                            {this.state.others == false ? (
                              this.state.businessType.map((item, index) => {
                                let checked =
                                  this.state.checkedBusinessType.indexOf(
                                    item.id
                                  ) != -1;
                                return (
                                  <Form.Check
                                    type="checkbox"
                                    inline
                                    onChange={this.handleBusinessType}
                                    key={item.id + index}
                                    checked={checked}
                                    id={item.id}
                                    name="business_type"
                                    label={item.name}
                                    // required={this.state.businessType_required}
                                    required
                                  />
                                );
                              })
                            ) : (
                              <Form.Control
                                placeholder="Others"
                                name="business_type_other"
                                defaultValue={this.state.business_type_other}
                                onChange={this.handletypeOther}
                              />
                            )}
                          </Form.Group>
                        </div>
                      </Col>
                    ) : null} */}

                    <Col sm={12}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          placeholder="Message"
                          name="rfq_message"
                          value={this.state.rfq_message}
                          as="textarea"
                          rows={2}
                          onChange={this.handleChange}
                          disabled={this.state.isDisable}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Form.Group controlId="formBasicRemark">
                        <Form.Label>Support Remark</Form.Label>
                        <Form.Control
                          placeholder="support remark"
                          name="supportRemark"
                          value={this.state.supportRemark}
                          onChange={this.handleChange}
                          disabled
                          // as="textarea"
                          // rows={2}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>

                {this.state.isShowRight ? (
                  <Col sm={this.state.call_attempt_count > 0 ? "4" : ""}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        Callback
                        {/* {
                                        this.state.startDateShow
                                        ?
                                        <span> ({this.state.startDateShow})</span>
                                        :
                                        null
                                        } */}
                      </Form.Label>
                      <DatePicker
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm:ss aa"
                        selected={this.state.startDate}
                        onChange={this.handleDateChange}
                        className="form-control"
                        locale="es"
                        minDate={new Date()}
                        minTime={this.state.minTime}
                        maxTime={moment().endOf("day").toDate()}
                        value={this.state.startDate}
                        required={this.state.callBackMandate}
                      />
                    </Form.Group>
                    {this.state.call_attempt_history.length > 0 ? (
                      <CallAttemptedHistory
                        call_attempt_history={this.state.call_attempt_history}
                      />
                    ) : null}
                    {this.state.call_attempt_count > 0 ? (
                      <div>
                        <div
                          className={`Call_Attempt_${this.state.call_attempt_count}`}
                        >
                          <Form.Group controlId="call_attempt_count">
                            <Form.Label>
                              Call Status {this.state.call_attempt_count}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              name="call_status"
                              onChange={this.handleChangeForCallStatus}
                              required
                            >
                              <option value="">Select Call Status</option>
                              {this.state.call_status_array.map(
                                (item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.status}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        {this.state.rfq_status ? (
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>RFQ Status..</Form.Label>
                            <Form.Control
                              placeholder="Name"
                              disabled
                              name="rfq_status"
                              value={this.state.rfq_status}
                              required
                            />
                            {/* <Button variant="success" className='callButton' onClick={this.changeRFQ}>{this.state.rfq_status}
                                 </Button> */}
                          </Form.Group>
                        ) : null}

                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            placeholder="Remarks"
                            name="remarks"
                            value={this.state.remarks}
                            onChange={this.handleChange}
                            as="textarea"
                            rows={2}
                            required
                          />
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicEmail">
                          <Form.Label>Buying For</Form.Label>
                          <Form.Control
                            as="select"
                            name="buying_for"
                            onChange={this.handleChange}
                            required
                            value={this.state.buying_for}
                          >
                            <option value="">Select Buying For</option>
                            <option value="Project Use">Project Use</option>
                            <option value="Reselling">Reselling</option>
                            <option value="Own construction">
                              Own construction
                            </option>
                          </Form.Control>
                        </Form.Group> */}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Transfer Account</Form.Label>
                          <Form.Control
                            as="select"
                            name="agent_id"
                            autoFocus
                            required={this.state.agent_mandatory}
                            onChange={this.handleChange}
                            // value="155"
                            defaultValue={this.state.agent_id}
                          >
                            <option value="">Select User</option>
                            {this.state.all_quote_users.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                    ) : null}
                  </Col>
                ) : null}
              </Form.Row>
            </Form>
          </Modal.Body>
        )}

        {["Closed - Lost", "Direct Order", "Duplicate"].includes(
            this.state.rfq_status
          ) ? (
          <Modal  
            show={this.state.showConfirmationMessage} 
            onHide={this.closeConfirmationModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              This will Cancel the Open SR ID {this.state.SRId} Do you want to Submit?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" 
              //  onClick={this.handleSubmitForm} 
              //  id="submit"
               id="submit"
                onClick={this.handleSubmitForm}
               >
              {this.state.isSaveLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ width: "1.3rem", height: "1.3rem" }}
                />
              ) : (
                "Yes"
              )}
              </Button>
              <Button variant="primary" onClick={this.closeConfirmationModal}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        ):null}
        <Modal.Footer>
          {this.state.rfq_status == "Converted to Quote" &&
          !["Converted to Quote", "Converted to MQ"].includes(
            this.state.rfqStatus
          ) ? (
            <Button
              style={{ width: "200px" }}
              variant="primary"
              disabled={this.state.isSaveLoading || this.props.rfqDisable}
              id="submit"
              onClick={
                !this.state.isSaveLoading ? (val) => this.changeRFQ(val) : null
              }
            >
              {this.state.isSaveLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ width: "1.3rem", height: "1.3rem" }}
                />
              ) : (
                "Save & Create Quote"
              )}
            </Button>
          ) : (
            <Button
              style={{ width: "100px" }}
              variant="primary"
              disabled={this.state.isSaveLoading || this.props.rfqDisable}
              id="submit"
              onClick={!this.state.isSaveLoading ? this.handleSubmitForm : null}
            >
              {this.state.isSaveLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ width: "1.3rem", height: "1.3rem" }}
                />
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Modal.Footer>
        {/* second page start */}
          <section className="rfqDltMainCnt">
          <div className="container-40">
            <div className="rfqMainCnt">
              <div className="rfqLeftSection">
                <div className="customerInfoNonEditable">
                  <h1 className="pageSubHeading">Customer Info</h1>
                  <div className="customerInfoCnt mt-2">
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Account</label>
                        <input 
                         type="button" 
                         name="accountName"
                         value={this.state.accountName}
                         onChange={this.handleChange} 
                         disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Email</label>
                        <input
                          type="button"
                          name="customer_email"
                          value={this.state.customer_email}
                          onChange={this.handleChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Delivery Location</label>
                        <input
                          type="button"
                          id="name"
                          value="Lajpat Nagar, Delhi 110007"
                        />
                        <button className="editButton">
                          <img
                            src={edit}
                            alt="edit icon"
                            width="12px"
                            height="12px"
                          />
                          <span>Edit</span>
                        </button>
                      </div>
                      <div className="traInputWrapper d-none">
                        <label htmlFor="name">Delivery Location</label>
                        <input type="text" id="name" placeholder="" />
                        <div className="traInputIconWrapper searchIcon">
                          <img src={searchIcon} width="20px" height="20px" />
                        </div>
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Name</label>
                        <input
                          type="button"
                          disabled
                          name="customer_name"
                          value={this.state.customer_name}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Role</label>
                        <input
                          type="button"
                          name="Role"
                          placeholder="Enter Role"
                          onChange={this.handleChange}
                          value={this.state.role}
                          disabled={this.state.isDisable}
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Mobile</label>
                        <input
                          type="button"
                          name="customer_mobile"
                          value={this.state.customer_mobile}
                          onChange={this.handleChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Agent</label>
                        <input 
                          type="button" 
                          id="assigned_to" 
                          value={this.state.assigned_to}
                          onChange={this.handleChange}
                          disabled 
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem w-100">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Message</label>
                        <input
                          type="button"
                          name="rfq_message"
                          value={this.state.rfq_message}
                          onChange={this.handleChange}
                          disabled={this.state.isDisable}
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Support Remarks</label>
                        <input 
                          type="button"  
                          name="supportRemark"
                          value={this.state.supportRemark}
                          onChange={this.handleChange}
                          disabled
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="requirementDtlCnt2">
                  <h2 className="pageSubHeading">Requirement Details</h2>
                  <div className="requirementDtlTableCnt">
                    <table className="requirementTable List">
                      <thead className="tableHead">
                        <tr>
                          <th style={{ width: "20%" }}>Type</th>
                          <th style={{ width: "33%" }}>Name</th>
                          <th style={{ width: "10%" }}>Brand</th>
                          <th style={{ width: "10%" }}>Quantity</th>
                          <th style={{ width: "10%" }}>Unit</th>
                          <th style={{ width: "10%" }}>Price</th>
                          <th style={{ width: "7%" }}>&nbsp;</th>
                        </tr>
                      </thead>

                      <tbody className="tableBody">
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label htmlFor="name">Catalogue</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>

                              <div class="customDropDownCnt">
                                <ul class="dropdownWrapper">
                                  <li class="dropdownItem">Ozone </li>
                                  <li class="dropdownItem">Mortise Door</li>
                                  <li class="dropdownItem">
                                    Mortise Door Handle
                                  </li>
                                  <li class="dropdownItem">
                                    Somany Duragres
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <textarea
                                rows={2}
                                className="textEditableInput"
                                type="text"
                                placeholder=" Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                                    for Residences Library Offices Hospitals High Security
                                                    Premises, Silver (2 Years Warranty)"
                              ></textarea>
                              <a
                                href="javascript:void(0)"
                                className="view_Btn"
                              >
                                <img
                                  src={viewBtn}
                                  width="16px"
                                  height="16px"
                                />
                                <span className="viewCnt">View</span>
                              </a>
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="Ozone"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className=""
                                placeholder="10"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="pcs"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="â‚¹250"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Catalogue</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <textarea
                                rows={2}
                                className="textEditableInput"
                                type="text"
                                placeholder=" Pedestal fans"
                              ></textarea>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="Godrej"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className=""
                                placeholder="10"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="pcs"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="N/A"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Other</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <textarea
                                rows={2}
                                className="textEditableInput"
                                type="text"
                                placeholder="Blue Paint Bottles"
                              ></textarea>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="Asian Paint"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className=""
                                placeholder="2"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className=""
                                placeholder="litres"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="N/A"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Attachment</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <a
                                href="javascript:void(0)"
                                type="button"
                                className="attachmentBtn"
                              >
                                Attachment.pdf
                              </a>
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Catalogue</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper searchProductWrapper">
                            <div className="searchProduct_list tableSelectOption">
                              <div>
                                <label for="searchProduct"></label>
                                <input
                                  className="searchProducts"
                                  placeholder="Search a product"
                                />
                              </div>
                              <div class="customDropDownCnt">
                                <ul class="dropdownWrapper">
                                  <li class="dropdownItem">
                                    Ozone ODWL-03-NS STD SSS Narrow Style
                                    Smart Door Lock for Residences Library
                                    Offices Hospitals High Security Premises,
                                    Silver (2 Years Warranty)
                                  </li>
                                  <li class="dropdownItem">
                                    Mortise Door Handle Set For Aluminium
                                    Casement Door - Series - McCoy DHM
                                  </li>
                                  <li class="dropdownItem">
                                    Mortise Door Handle Set For Aluminium
                                    Casement Door - Series - McCoy DHM - White
                                    - McCoy 8M
                                  </li>
                                  <li class="dropdownItem">
                                    Somany Duragres Breccia Grande Valor
                                  </li>
                                  <li class="dropdownItem">
                                    Somany Duragres Breccia Grande Valor
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="addItemsBtn">
                      Add item
                    </button>
                  </div>
                </div>
              </div>
              <div className="rfqRightSection">
                <div className="calLogBtnWrapper">
                  <button 
                    type="button" 
                    className="rfqCommonBtn bgBlue"
                    onClick={this.handleCallType}
                    disabled={
                      this.state.is_calling || this.props.rfqDisable
                    }
                    value="Knowlarity"
                    >
                    <img
                      src={callIcon}
                      alt="call icon"
                      width="11px"
                      height="11px"
                    />
                    <span>Call Customer</span>
                  </button>
                  <button 
                    type="button" 
                    className="rfqCommonBtn bgGrey" 
                    onClick={this.handleCallType}
                    disabled={
                      this.state.is_calling || this.props.rfqDisable
                    }
                    value="Knowlarity"
                    >
                    Log a Call
                  </button>
                </div>
                {this.state.call_attempt_count > 0 ? (
                <div className="callRemarkCnt">
                  <h2 className="pageSubHeading">Remarks on Call Attempt</h2>
                  <div className="callReamrkWrapper">
                    <div className="callReamrkItem">
                      <div className="customeSelectCnt callReamrkDropCnt">
                        <button
                          className={`customSelectBtn`}
                          onClick={this.state.toggleDropdown}
                          onBlur={() => this.setState({ isOpenCallStatus: false })}
                        >
                          <label htmlFor="name">Call Status</label>
                          <span className="selectedValue">{this.state.callStatusValue}</span>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div
                          className={`customDropDownCnt show ${this.state.isOpenCallStatus ? "show" : ""}`}
                        >
                          <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                            {this.state.call_status_array.map((item) => (
                              <>
                            <li
                              key={item.id} 
                              className="dropdownItem"
                              // name="call_status"
                              onClick={() => this.getCallStatusValue(item.status)}
                            >
                              {/* {item.status} */}
                            </li>
                            </>))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="traInputWrapper">
                        <label htmlFor="name">Call Back</label>
                        <input type="text" id="name" placeholder="" disabled />
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="customeSelectCnt callReamrkDropCnt">
                        <button
                          className={`customSelectBtn`}
                          // onClick={this.state.toggleDropdown}
                        >
                          <label htmlFor="name">RFQ Status</label>
                          <span className="selectedValue">Pending</span>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div
                          className={`customDropDownCnt ${this.state.isOpen ? "show" : ""
                            }`}
                        >
                          <ul className="dropdownWrapper">
                            <li className="dropdownItem">Pending</li>
                            <li className="dropdownItem">Sucsess</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="traInputWrapper">
                        <label htmlFor="name">Remarks</label>
                        <input type="text" id="name" placeholder="" />
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="customeSelectCnt callReamrkDropCnt">
                        <button
                          className={`customSelectBtn`}
                          // onClick={this.state.toggleDropdown}
                        >
                          <label htmlFor="name">Transfer Account</label>
                          <span className="selectedValue">Rahul</span>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div
                          className={`customDropDownCnt ${this.state.isOpen ? "show" : ""
                            }`}
                        >
                          <ul className="dropdownWrapper">
                            <li className="dropdownItem">Rahul</li>
                            <li className="dropdownItem">Nikhil</li>
                            <li className="dropdownItem">Nasir</li>
                            <li className="dropdownItem">Irshad</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ):null}
                <div className="callHistoryCnt">
                  <h2 className="pageSubHeading">Call History</h2>
                  <div className="callHistoryAccordionCnt">
                    <div className="callHistoryAccordionItem show">
                      <button className="callAccordionBtn">
                        <span>Call 03</span>
                        <img
                          src={backArrowInactive}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="inactiveIcon"
                        />
                        <img
                          src={backArrowNew}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="activeIcon"
                        />
                      </button>
                      <div className="callAccordionBody">
                        <ul className="callHistoryWrapper">
                          <li>
                            <span>Call 01</span>
                            <p>No Remarks</p>
                          </li>
                          <li>
                            <span>Status</span>
                            <p>NA</p>
                          </li>
                          <li>
                            <span>By</span>
                            <p>Dev Kapoor</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>Calling Mode</span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>Follow up on</span>
                            <p>NA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="callHistoryAccordionItem">
                      <button className="callAccordionBtn">
                        <span>Call 03</span>
                        <img
                          src={backArrowInactive}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="inactiveIcon"
                        />
                        <img
                          src={backArrowNew}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="activeIcon"
                        />
                      </button>
                      <div className="callAccordionBody">
                        <ul className="callHistoryWrapper">
                          <li>
                            <span>Call 01</span>
                            <p>No Remarks</p>
                          </li>
                          <li>
                            <span>Status</span>
                            <p>NA</p>
                          </li>
                          <li>
                            <span>By</span>
                            <p>Dev Kapoor</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>Calling Mode</span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>Follow up on</span>
                            <p>NA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="callHistoryAccordionItem">
                      <button className="callAccordionBtn">
                        <span>Call 03</span>
                        <img
                          src={backArrowInactive}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="inactiveIcon"
                        />
                        <img
                          src={backArrowNew}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="activeIcon"
                        />
                      </button>
                      <div className="callAccordionBody">
                        <ul className="callHistoryWrapper">
                          <li>
                            <span>Call 01</span>
                            <p>No Remarks</p>
                          </li>
                          <li>
                            <span>Status</span>
                            <p>NA</p>
                          </li>
                          <li>
                            <span>By</span>
                            <p>Dev Kapoor</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>Calling Mode</span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>Follow up on</span>
                            <p>NA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </section>
        {/* second page start */}
      </Modal>
      
    );
  }
}
export default withRouter(Quote);
