import React, { useState, useEffect } from "react";
import user_icon from "../../../asset/Images/user_icon.svg";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import searchIcon from "../../../asset/Images/searchIcon.svg";
import infoIconGrey from "../../../asset/Images/infoIconGrey.svg";
import deleteIcon from "../../../asset/Images/delete_new_icon.svg";
import crossBlue from "../../../asset/Images/crossBlue.svg";
import downloadIcon from "../../../asset/Images/downloadIconNew.svg";
import alertMsg from "../../../asset/Images/danger_alert.svg";
import viewBtn from "../../../asset/Images/viewBtn.svg";
import callIcon from "../../../asset/Images/callIcon.svg";
import backArrowNew from "../../../asset/Images/backArrowNew.svg";
import backArrowInactive from "../../../asset/Images/backArrowInactive.svg";
import edit from "../../../asset/Images/edit.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Constants from "../../../Config";
import { Spinner } from "react-bootstrap";
import ErrorHandling from "../../ErrorHandling/ShowMessage";
import DatePicker from "react-datepicker";
import moment from "moment";
import CallAttemptedHistory from "../Quotes/CallAttemptedHistory";
import { Modal, Button } from "react-bootstrap";
import { useHistory, Link, Redirect } from "react-router-dom";
import EditRfqMobileView from "./EditRfqMobileView";

const apiUrl = Constants.API_URL;

const calculateMinTime = (date) => {
  let isToday = moment(date).isSame(moment(), "day");
  if (isToday) {
    let nowAddOneHour = moment(new Date()).add({ minutes: 1 }).toDate();
    return nowAddOneHour;
  }
  return moment().startOf("day").toDate();
};

const EditRfq = () => {
  const history = useHistory();
  const location = useLocation();
  const { id, agent_id } = location.state || {};
  // console.log(id,"id here",agent_id);
  const [isLoading, setIsLoading] = useState(false);
  const [editRfqData, setEditRfqData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [callStatusId, setCallStatusId] = useState("");
  const [typeSwitch, setTypeSwitch] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [productList, setProductList] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [isImageLoading, setImageLoading] = useState("");
  const [imageIndex, setImageIndex] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [products, setProducts] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // submit api data state start here
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [called, setCalled] = useState(false);
  const [call_id, setCallId] = useState("");
  const [message, setMessage] = useState("");
  const [rfq_message, setRfqMessage] = useState("");
  const [status, setStatus] = useState("");
  const [call_response, setCallResponse] = useState("");
  const [remarks, setRemarks] = useState("");
  const [call_attempt_count, setCallAttemptCount] = useState("");
  const [rfq_status, setRfqStatus] = useState("");
  const [quote_type, setQuoteType] = useState("");
  const [assigned_to, setAssignedTo] = useState("");
  const [startDate, setStartDate] = useState("");
  // const [agent_id, setAgentId] = useState(0);
  const [locationValue, setLocationValue] = useState("");
  const [campaign, setCampaign] = useState("");
  const [priority, setPriority] = useState("");
  const [location_id, setLocationId] = useState("");
  const [isShowSuccessMsg, setIsShowSuccessMsg] = useState(false);
  const [error, setError] = useState([]);
  const [isRedirect, setIsRedirect] = useState(false);
  const [rfq_id, setRfqId] = useState("");
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [SRId, setSRId] = useState("");
  const [sourcingStatus, setSourcingStatus] = useState("");
  // submit api data ends here
  const [callStatusArray, setCallStatusArray] = useState([]);
  const [callType, setCallType] = useState("");
  const [isShowRight, setIsShowRight] = useState(false);
  const [isOpenCallStatus, setIsOpenCallStatus] = useState(false);
  const [isOpenTransferAccount, setIsOpenTransferAccount] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [isCallingLog, setIsCallingLog] = useState(false);
  const [wrongPhoneAlert, setWrongPhoneAlert] = useState("");
  const [minTime, setMinTime] = useState(() => calculateMinTime(new Date()));
  // const [minTime, setMinTime] = useState();
  const [callBackMandate, setCallBackMandate] = useState(false);
  const [businessGroup, setBusinessGroup] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [callAttemptHistory, setCallAttemptHistory] = useState([]);
  const [showBusinessType, setShowBusinessType] = useState(false);
  const [checkedBusinessType, setCheckedBusinessType] = useState(null);
  const [others, setOthers] = useState(false);
  const [dateDisable, setDateDisable] = useState(false);
  const [allQuoteUsers, setAllQuoteUsers] = useState([]);
  const [transferAccountValue, setTransferAccountValue] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openIndex, setOpenIndex] = useState(null); // Track the open dropdown
  const [remarksData, setRemarksData] = useState({
    remarks: "", // State to store input value
  });
  const [locationList, setLocationList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [is_redirect, setRedirect] = useState(false);
  const [initial, setInitial] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [redirectTo, setRedirectTo] = useState("/quote-requests");
  const [isDisabledEditRfq, setIsDisabledEditRfq] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [editableProduct, setEditableProduct] = useState(false);
  const [editProductData, setEditProductData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    getAllQuote();
  }, []); // Runs once when the component mounts

  useEffect(() => {
    if (editRfqData?.product && initial) {
      let products = editRfqData.product;
      if (isMobileView) {
        products = editRfqData.product.map((each) => {
          return { ...each, showCards: true };
        });
      }
      setProducts(products); // Update products when editRfqData updates
      setInitial(false);
    }
    setLocationValue(editRfqData.location);
    getCallAttemptHistory();
    getAllQuoteUsers();
  }, [editRfqData.product]);

  useEffect(() => {
    const condition =
      editRfqData.sourcing_id !== "" ||
      !["New", "Pending"].includes(editRfqData.status);
    setIsDisabledEditRfq(condition);
  }, [editRfqData.sourcing_id, editRfqData.status]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getAllQuote = async () => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        apiUrl + "/get-quote-request",
        {
          logined_user_id: [loginedUserDetails?.user_id],
          request_quote_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails?.token}`, // Ensure token is passed correctly
          },
        }
      );
      setEditRfqData(data.data[0]);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleBlur = () => {
    setIsOpen(false);
  };

  // Toggle dropdown function
  const toggleDropdown = (index) => {
    setIsOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  
  // Close dropdown function
  const closeDropdown = (index) => {
    setIsOpen((prev) => ({ ...prev, [index]: false }));
  };

  const handleProductChange = (index, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              type: "product",
              productName: "",
              brand: "",
              productQuantity: "",
              quantity_type: "",
              productPrice: "",
              showProducts: false,
              [field]: value, // Set only the selected field
            }
          : product
      )
    );
    closeDropdown(index);
  };

  const handleProductChange1 = (index, field, value) => {
    setEditProductData({
              type: "product",
              productName: "",
              brand: "",
              productQuantity: "",
              quantity_type: "",
              productPrice: "",
              showProducts: false,
              [field]: value, // Set only the selected field
            }
    );
    closeDropdown(index);
    // setIsOpenTypeDropdown(false);
  };

  const handleTextAreaChange = (e, index) => {
    const inputValue = e.target.value;
    // const showProducts = inputValue.length > 0;
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              productName: inputValue,
              showProducts: true,
              link: "",
            }
          : product
      )
    );
    setActiveDropdownIndex(index); // Set active dropdown index when typing
    getValue(inputValue);
  };

  const handleTextAreaChange1 = (e) => {
    const inputValue = e.target.value;
    setEditProductData({
      ...editProductData,
      productName: inputValue,
      showProducts: true,
      link: "",
    }
);
    getValue(inputValue);
  };

  const getValue = (inputValue) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    if (!inputValue) {
      setSuggestions([]); // Hide suggestions if input is empty
      return;
    }

    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      data: { keyword: inputValue, status: "2, 0" },
    })
      .then((data) => {
        setProductList(data.data.data);
        setSuggestions(
          data.data.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      })
      .catch((error) => console.log(error));
      // new 
  };

  const handleTextAreaCategory = (e, index) => {
    const inputValue = e.target.value;
    // const showProducts = inputValue.length > 0;
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              productName: inputValue,
              showProducts: true,
              link: "",
            }
          : product
      )
    );
    setActiveDropdownIndex(index);
    getCategory(inputValue);
  };

  const handleTextAreaCategory1 = (e) => {
    const inputValue = e.target.value;
    setEditProductData({
              ...editProductData,
              productName: inputValue,
              showProducts: true,
              link: "",
            }
    );
    // setActiveDropdownIndex(index);
    getCategory(inputValue);
  };

  const getCategory = (e) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: { keyword: e },
    })
      .then((data) => {
        setCategoryList(data.data.data);
        setCategorySuggestions(
          data.data.data.map((i) => ({ label: i.name, value: i.name }))
        );
      })
      .catch((error) => console.log(error));
  };

  const handleCategoryClick = (index, suggestion) => {
    let selectedProduct = categoryList.find(
      (el) => el.name === suggestion.value
    );
    if (!selectedProduct) return;

    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              productName: selectedProduct.name,
              product_id: selectedProduct.product_id
                ? selectedProduct.product_id
                : 0,
              brand: product.brand,
              brand_id: selectedProduct.brand_id ? selectedProduct.brand_id : 0,
              category_id: selectedProduct.category_id
                ? selectedProduct.category_id
                : 0,
              quantity_type: product.quantity_type,
              productQuantity: product.productQuantity
                ? product.productQuantity
                : 1,
              productPrice: `${
                isNaN(Number(selectedProduct.product_price))
                  ? ""
                  : Number(selectedProduct.product_price).toFixed(2)
              }`,
              link: selectedProduct.url,
              showProducts: false,
            }
          : product
      )
    );

    // setCategorySuggestions([]); // Clear dropdown after selection
    setActiveDropdownIndex(null); // Hide dropdown after selection
  };

  const handleCategoryClick1 = (suggestion) => {
    let selectedProduct = categoryList.find(
      (el) => el.name === suggestion.value
    );
    if (!selectedProduct) return;
    setEditProductData({
              ...editProductData,
              productName: selectedProduct.name,
              product_id: selectedProduct.product_id
                ? selectedProduct.product_id
                : 0,
              brand: editProductData.brand,
              brand_id: selectedProduct.brand_id ? selectedProduct.brand_id : 0,
              category_id: selectedProduct.category_id
                ? selectedProduct.category_id
                : 0,
              quantity_type: editProductData.quantity_type,
              productQuantity: editProductData.productQuantity
                ? editProductData.productQuantity
                : 1,
              productPrice: `${
                isNaN(Number(selectedProduct.product_price))
                  ? ""
                  : Number(selectedProduct.product_price).toFixed(2)
              }`,
              link: selectedProduct.url,
              showProducts: false,
            }
    );
    setActiveDropdownIndex(null); // Hide dropdown after selection
  };

  const handleSuggestionClick = (index, suggestion) => {
    let selectedProduct = productList.find(
      (el) => el.name === suggestion.value
    );
    if (selectedProduct) {
      setProducts((prevProducts) =>
        prevProducts.map((product, i) =>
          i === index
            ? {
                ...product,
                product_id: selectedProduct.product_id,
                category_id: selectedProduct.category_id
                  ? selectedProduct.category_id
                  : 0,
                productName: selectedProduct.name, // Set the selected name
                productPrice: `₹${Number(selectedProduct.price).toFixed(2)}`,
                productQuantity: product.productQuantity
                  ? product.productQuantity
                  : 1,
                quantity_type: selectedProduct.qty_type,
                brand: selectedProduct.brand,
                brand_id: selectedProduct.brand_id,
                link: selectedProduct.url,
                showProducts: false,
              }
            : product
        )
      );
      setActiveDropdownIndex(null); // Hide dropdown after selection
    }
    // Clear suggestions after selection
    setCategorySuggestions([]);
  };

  const handleSuggestionClick1 = (suggestion) => {
    let selectedProduct = productList.find(
      (el) => el.name === suggestion.value
    );
    if (selectedProduct) {
      setEditProductData({
                ...editProductData,
                product_id: selectedProduct.product_id,
                category_id: selectedProduct.category_id
                  ? selectedProduct.category_id
                  : 0,
                productName: selectedProduct.name, // Set the selected name
                productPrice: `₹${Number(selectedProduct.price).toFixed(2)}`,
                productQuantity: editProductData.productQuantity
                  ? editProductData.productQuantity
                  : 1,
                quantity_type: selectedProduct.qty_type,
                brand: selectedProduct.brand,
                brand_id: selectedProduct.brand_id,
                link: selectedProduct.url,
                showProducts: false,
              }
      );
      setActiveDropdownIndex(null); // Hide dropdown after selection
    }
    // Clear suggestions after selection
    setCategorySuggestions([]);
  };

  const handleTextAreaOther = (e, index) => {
    const inputValue = e.target.value;
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              productName: inputValue,
              product_id: 0,
              category_id: 0,
              productPrice: "",
              productQuantity: product.productQuantity
                ? product.productQuantity
                : 1,
              quantity_type: product.quantity_type,
              brand: product.brand,
              brand_id: 0,
              link: "",
            }
          : product
      )
    );
  };

  const handleTextAreaOther1 = (e) => {
    const inputValue = e.target.value;
    setEditProductData( {
              ...editProductData,
              productName: inputValue,
              product_id: 0,
              category_id: 0,
              productPrice: "",
              productQuantity: editProductData.productQuantity
                ? editProductData.productQuantity
                : 1,
              quantity_type: editProductData.quantity_type,
              brand: editProductData.brand,
              brand_id: 0,
              link: "",
            }
    );
  };

  const handleTextChange = (index, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index ? { ...product, [field]: value } : product
      )
    );
  };

  const handleTextChange1 = (field, value) => {
    setEditProductData({ ...editProductData, [field]: value });
  };

  const handleAddRow = () => {
    const newProduct = {
      type: "product",
      productName: "",
      brand: "",
      productQuantity: "",
      quantity_type: "",
      productPrice: "",
      showProducts: false,
      showCards: false,
    };

    setProducts((prevProducts) => [...prevProducts, newProduct]);
  };

  const removeItem = (index) => {
    setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };

  const onFileChange = async (index, event) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const file = event.target.files[0];
    if (!file) return;

    const allowedImage = validateFile(file);
    if (!allowedImage) {
      setImageLoading(false);
      setImageIndex(null);
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    setImageLoading(true);
    setImageIndex(index);
    try {
      const response = await axios.post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginedUserDetails?.token}`,
        },
      });

      const imageUrl = response.data.data.image_url;

      setFileNames((prevFileNames) => [...prevFileNames, file.name]);

      setProducts((prevProducts) =>
        prevProducts.map((product, i) =>
          i === index
            ? {
                ...product,
                productName: imageUrl,
                product_id: 0,
                category_id: 0,
                productPrice: product.price,
                productQuantity: product.productQuantity,
                quantity_type: product.quantity_type,
                brand: product.brand,
                brand_id: 0,
                link: "",
              }
            : product
        )
      );

      setImageLoading(false);
      setImageIndex(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setImageLoading(false);
      setImageIndex(null);
    }
  };

  const onFileChange1 = async (event) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const file = event.target.files[0];
    if (!file) return;

    const allowedImage = validateFile(file);
    if (!allowedImage) {
      setImageLoading(false);
      setImageIndex(null);
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    setImageLoading(true);
    // setImageIndex(index);
    try {
      const response = await axios.post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginedUserDetails?.token}`,
        },
      });

      const imageUrl = response.data.data.image_url;

      setFileNames((prevFileNames) => [...prevFileNames, file.name]);
      setEditProductData({
        ...editProductData,
        productName: imageUrl,
        product_id: 0,
        category_id: 0,
        productPrice: editProductData.price,
        productQuantity: editProductData.productQuantity,
        quantity_type: editProductData.quantity_type,
        brand: editProductData.brand,
        brand_id: 0,
        link: "",
      }
);
      // setProducts((prevProducts) =>
      //   prevProducts.map((product, i) =>
      //     i === index
      //       ? {
      //           ...product,
      //           productName: imageUrl,
      //           product_id: 0,
      //           category_id: 0,
      //           productPrice: product.price,
      //           productQuantity: product.productQuantity,
      //           quantity_type: product.quantity_type,
      //           brand: product.brand,
      //           brand_id: 0,
      //           link: "",
      //         }
      //       : product
      //   )
      // );

      setImageLoading(false);
      setImageIndex(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setImageLoading(false);
      setImageIndex(null);
    }
  };

  const validateProducts = () => {
    let errorMessage = ""; // Initialize error message

    const isValid = products.every((item) => {
      switch (item.type) {
        case "attachments":
          if (!item.productName?.trim()) {
            errorMessage = "Please upload Attachment.";
            return false;
          }
          return true;

        case "product":
          if (!item.productName?.trim()) {
            errorMessage = "Name is required.";
            return false;
          }
          if (!item.productQuantity) {
            errorMessage = "Quantity is required.";
            return false;
          }
          if (item.productQuantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
            return false;
          }
          return true;

        case "category":
        case "other":
          if (!item.productName?.trim()) {
            errorMessage = "Name is required.";
            return false;
          }
          if (!item.brand?.trim()) {
            errorMessage = "Brand is required.";
            return false;
          }
          if (!item.productQuantity) {
            errorMessage = "Quantity is required.";
            return false;
          }
          if (item.productQuantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
            return false;
          }
          if (!item.quantity_type) {
            errorMessage = "Unit type is required.";
            return false;
          }
          return true;

        default:
          errorMessage = "Invalid item type.";
          return false;
      }
    });

    if (!isValid) {
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(""), 3000);
    }

    return isValid;
  };


  // Button Click Handler
  const handleAddItem = () => {
    if (validateProducts()) {
      handleAddRow();
    }
  };

  const submitForm = async (e, redirectVal = "") => {
    try {
      setDisabledButton(true);
      localStorage.removeItem("dataKey2");

      const filteredArray = products.map(
        ({
          productPrice,
          showProducts,
          showCards,
          link,
          id,
          model,
          price,
          url,
          productName: name,
          productQuantity: quantity,
          type,
          ...rest
        }) => ({
          ...rest,
          name,
          link,
          type,
          quantity: type === "attachments" ? "" : quantity,
        })
      );

      // Validation: Check missing fields individually and store messages
      let errorFound = false; // Track errors
      // Validation: Check missing fields individually
      for (const item of filteredArray) {
        switch (item.type) {
          case "attachments":
            if (!item.name) {
              setErrorMessage("Attachment name is required.");
              errorFound = true;
            }
            break;

          case "product":
            if (!item.name) {
              setErrorMessage("Product Name is required.");
              errorFound = true;
            } else if (!item.brand) {
              setErrorMessage("Brand is required.");
              errorFound = true;
            } else if (!item.quantity) {
              setErrorMessage("Quantity is required.");
              errorFound = true;
            } else if (Number(item.quantity) <= 0) {
              setErrorMessage("Quantity must be greater than 0.");
              errorFound = true;
            } else if (!item.quantity_type) {
              setErrorMessage("Quantity Type is required.");
              errorFound = true;
            }
            break;

          case "category":
          case "other":
            if (!item.name) {
              setErrorMessage("Name is required.");
              errorFound = true;
            } else if (!item.brand) {
              setErrorMessage("Brand is required.");
              errorFound = true;
            } else if (!item.quantity) {
              setErrorMessage("Quantity is required.");
              errorFound = true;
            } else if (Number(item.quantity) <= 0) {
              setErrorMessage("Quantity must be greater than 0.");
              errorFound = true;
            } else if (!item.quantity_type) {
              setErrorMessage("Quantity Type is required.");
              errorFound = true;
            }
            break;

          default:
            setErrorMessage("Invalid item type.");
            errorFound = true;
            break;
        }

        if (errorFound) {
          setTimeout(() => setErrorMessage(""), 3000);
          setIsSaveLoading(false);
          setDisabledButton(false);
          return; // Stop function execution
        }
      }

      const nameSet = new Set();
      let duplicateName = null;
      for (const item of filteredArray) {
        if (nameSet.has(item.name)) {
          duplicateName = item.name;
          break;
        }
        nameSet.add(item.name);
      }

      if (duplicateName) {
        setErrorMessage("Duplicate product name not added");
        setTimeout(() => setErrorMessage(""), 3000);
        setIsSaveLoading(false);
        setDisabledButton(false);
        return;
      }

      const combinedProducts = [...filteredArray];
      const uniqueFilteredArray = Array.from(
        new Map(combinedProducts.map((item) => [item.name, item])).values()
      );

      let buttonClickType = e.target.id;
      if (buttonClickType === "submit") {
        setDisabledButton(true);
        setIsSaveLoading(true);
        setValidated(true);

        let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        let params = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails?.token}`,
          },
          data: {
            requested_id: id,
            call_id,
            message,
            rfq_details: editRfqData.rfq_message,
            status,
            call_response,
            call_status: callStatusId.toString(),
            call_title: selectedValue,
            remarks: remarksData.remarks,
            call_attempt_count,
            quote_type: editRfqData?.quote_type,
            rfq_status,
            assigned_to: editRfqData?.assigned_to,
            callback_schedule: startDate
              ? moment(startDate).add({ hours: "5.5" }).toISOString()
              : "",
            agent_id: agent_id === 0 ? "" : agent_id.toString(),
            location: locationValue || editRfqData.location,
            campaign,
            priority: "",
            location_id: location_id || "",
            products: uniqueFilteredArray,
            calling_mode: callType,
          },
        };

        const data = await axios(apiUrl + "/quote-request-submit", params);

        setIsSaveLoading(false);
        setIsShowSuccessMsg(true);
        setSuccessMessage("Rfq Edit Successfully");
        setError([]);
        setRedirectTo("/createQuote");
        setRedirect(true);
        if (data.status === 200 && redirectVal === "") {
          setTimeout(() => history.push(redirectTo), 500);
        }

        setRfqId(id);
        setTimeout(() => {
          setIsShowSuccessMsg(false);
          getAllQuote();
        }, 3000);
      }
    } catch (error) {
      setIsSaveLoading(false);
      setErrorMessage(error?.response?.data?.message || "Something went wrong");
      setDisabledButton(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setError("Please have a call first!");
      setIsSaveLoading(false);

      setTimeout(() => {
        setError([]);
        setIsShowSuccessMsg(false);
      }, 3000);
    }
  };

  const createSourceRequestSubmit = async (e) => {
    if (submitLoading) return;
    setSubmitLoading(true);
    setDisabledButton(true);
    //create POST api call will be executed below on clicktype
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    // Validation: Check missing fields individually and store messages
    let errorFound = false; // Track errors
    // Validation: Check missing fields individually
    for (const item of products) {
      switch (item.type) {
        case "attachments":
          if (!item.productName) {
            setErrorMessage("Attachment name is required.");
            errorFound = true;
          }
          break;

        case "product":
          if (!item.productName) {
            setErrorMessage("Product Name is required.");
            errorFound = true;
          } else if (!item.brand) {
            setErrorMessage("Brand is required.");
            errorFound = true;
          } else if (!item.productQuantity) {
            setErrorMessage("Quantity is required.");
            errorFound = true;
          } else if (Number(item.productQuantity) <= 0) {
            setErrorMessage("Quantity must be greater than 0.");
            errorFound = true;
          } else if (!item.quantity_type) {
            setErrorMessage("Quantity Type is required.");
            errorFound = true;
          }
          break;

        case "category":
        case "other":
          if (!item.productName) {
            setErrorMessage("Name is required.");
            errorFound = true;
          } else if (!item.brand) {
            setErrorMessage("Brand is required.");
            errorFound = true;
          } else if (!item.productQuantity) {
            setErrorMessage("Quantity is required.");
            errorFound = true;
          } else if (Number(item.productQuantity) <= 0) {
            setErrorMessage("Quantity must be greater than 0.");
            errorFound = true;
          } else if (!item.quantity_type) {
            setErrorMessage("Quantity Type is required.");
            errorFound = true;
          }
          break;

        default:
          setErrorMessage("Invalid item type.");
          errorFound = true;
          break;
      }

      if (errorFound) {
        setTimeout(() => setErrorMessage(""), 3000);
        setSubmitLoading(false);
        setDisabledButton(false);
        return; // Stop function execution
      }
    }
   
    const nameSet = new Set();
    let duplicateName = null;
    for (const item of products) {
      if (nameSet.has(item.productName)) {
        duplicateName = item.productName;
        break;
      }
      nameSet.add(item.productName);
    }
   
    if (duplicateName) {
      setErrorMessage("Duplicate product name not added");
      setTimeout(() => setErrorMessage(""), 3000);
      setSubmitLoading(false);
      setDisabledButton(false);
      return;
    }
    let product;
    product = editRfqData.product.map((data) => {
      if (data.type === "product") {
        return {
          name: data.name,
          quantity: data.quantity,
          brand: data.brand ? data.brand : "",
          brand_id: data.brand_id,
          quantity_type: data.quantity_type,
          category_id: data.category_id ? data.category_id : 0,
          product_id: data.product_id,
          type: data.type,
        };
      } else if (data.type === "attachments") {
        return {
          name: data.name,
          brand: "",
          brand_id: data.brand_id ? data.brand_id : 0,
          quantity_type: data.quantity_type ? data.quantity_type : "",
          quantity: data.quantity ? data.quantity : "",
          category_id: data.category_id ? data.category_id : 0,
          product_id: data.product_id ? data.product_id : 0,
          type: data.type,
        };
      }
      return {
        name: data.name,
        quantity: data.quantity,
        brand: data.brand ? data.brand : "",
        brand_id: data.brand_id,
        quantity_type: data.quantity_type,
        category_id: data.category_id ? data.category_id : 0,
        product_id: data.product_id,
        type:
          data.type === null
            ? data.product_id > 0
              ? "product"
              : data.category_id > 0
              ? "category"
              : data.type
            : data.type,
      };
    });
    // Process current products to remove unnecessary properties
    const filteredArray = products.map(
      ({
        price,
        url,
        productPrice,
        showProducts,
        showCards,
        link,
        id,
        model,
        productName: name,
        productQuantity: quantity,
        brand,
        brand_id,
        quantity_type,
        ...rest
      }) => ({
        ...rest,
        name,
        // link,
        quantity: quantity ? quantity : "",
        brand: brand ? brand : "",
        brand_id: brand_id ? brand_id : 0,
        quantity_type: quantity_type ? quantity_type : "",
      })
    );

    // Combine previous and current products
    const combinedProducts = [...filteredArray];
    const uniqueFilteredArray = Array.from(
      new Map(combinedProducts.map((item) => [item.name, item])).values()
    );

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customer_id: editRfqData.customer_id.toString(),
        customer_name: editRfqData.customer_name,
        account_name: editRfqData.accountName,
        mobile: editRfqData.customer_mobile,
        email: editRfqData.customer_email,
        pincode: editRfqData.pincode.toString(),
        location: locationValue ? locationValue : editRfqData.loaction,
        location_id: editRfqData.loaction_id,
        role: editRfqData.role,
        utm_source: editRfqData.utm_source || "",
        utm_medium: editRfqData.utm_medium || "",
        utm_campaign: editRfqData.utm_campaign || "",
        utm_content: editRfqData.utm_content || "",
        rfq_id: editRfqData.id,
        lead_id: editRfqData.lead_id ? editRfqData.lead_id : 0,
        sales_remarks: "",
        products: uniqueFilteredArray,
        // body send to api ends here
      },
    };
    return await axios(apiUrl + "/create-source-request", params)
      .then(() => {
        setSuccessMessage("Source Request Created Successfully");
        setErrorMessage("");
        setSubmitLoading(false);
        setIsRedirect(true);
        setTimeout(() => {
          setDisabledButton(true);
          history.push("/quote-requests");
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        setErrorMessage(err.response.data.message);
        setDisabledButton(false);
        setTimeout(() => {
          setErrorMessage("");
        }, 6000);
      });
  };

  // const handleEditSRAndCreateSource = async (e) => {
  //   await submitForm({ target: { id: "submit" } });
  //   await createSourceRequestSubmit(e);
  // };
  const handleEditSRAndCreateSource = async (e) => {
    await Promise.all([
      submitForm({ target: { id: "submit" } }),
      createSourceRequestSubmit(e)
    ]);
  };  

  const handleSubmitForm = async () => {
    if (
      SRId !== "" &&
      ["new", "pending", "reopen"].includes(sourcingStatus) &&
      ["Closed - Lost", "Direct Order", "Duplicate"].includes(rfq_status) &&
      !showConfirmationMessage
    ) {
      setShowConfirmationMessage(true);
    } else {
      await submitForm({ target: { id: "submit" } });
      setShowConfirmationMessage(false);
    }
  };

  const getCallStatus = async () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    try {
      const response = await axios.post(
        apiUrl + "/get-call-status",
        {
          quote_type: editRfqData?.quote_type,
          call_attempt_number: call_attempt_count,
          rfqId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails?.token}`,
          },
        }
      );
      setCallStatusArray(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCallAttemptCount = async () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        apiUrl + "/call-count",
        {
          requestQuoteID: id,
          quote_type: editRfqData?.quote_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails?.token}`,
          },
        }
      );

      const count = response.data.data.count;

      setCallAttemptCount(count);
      setCallType((prevType) =>
        prevType === "Knowlarity" ? "Knowlarity" : "Manual"
      );
      setIsShowRight(true);
      setCalled(true);
      // setIsCallingLog(true);

      await getCallStatus(); // Call getCallStatus after updating the state
      // setIsCallingLog(false);
      return count;
    } catch (error) {
      console.error(error);
      // setIsCallingLog(false);s
      throw error;
    }
  };

  const getCallAttemptCountLog = async () => {
    setIsCallingLog(true);
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        apiUrl + "/call-count",
        {
          requestQuoteID: id,
          quote_type: editRfqData?.quote_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails?.token}`,
          },
        }
      );

      const count = response.data.data.count;

      setCallAttemptCount(count);
      setCallType((prevType) =>
        prevType === "Knowlarity" ? "Knowlarity" : "Manual"
      );
      setIsShowRight(true);
      setIsCallingLog(true);

      await getCallStatus(); // Call getCallStatus after updating the state
      setIsCallingLog(false);
      return count;
    } catch (error) {
      console.error(error);
      setIsCallingLog(false);
      throw error;
    }
  };

  const getCall = async () => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    if (editRfqData.customer_mobile.length === 10) {
      setIsCalling(true);
      setWrongPhoneAlert("");

      try {
        const data = await getCallAttemptCount();
        // if (data <= CallCount) { // Uncomment if CallCount logic is required

        const response = await axios.post(
          apiUrl + "/make-call",
          {
            phone: editRfqData.customer_mobile,
            agentID: loginedUserDetails.user_id,
            type: "rfq",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginedUserDetails?.token}`,
            },
          }
        );

        const callData = response.data.data;
        setTimeout(() => {
          setIsCalling(false);
          setCallId(callData?.success?.call_id || "");
          setMessage(callData?.success?.message);
          setStatus(callData?.success?.status);
          setCallResponse(JSON.stringify(callData));
        }, 1500);
      } catch (error) {
        console.error(error);
        setIsCalling(false);
      }
    } else {
      setWrongPhoneAlert("Please Enter Correct Phone Number");

      setTimeout(() => {
        setWrongPhoneAlert("");
      }, 5000);
    }
  };

  const toggleTransferAccount = () => {
    setIsOpenTransferAccount(!isOpenTransferAccount);
  };

  const handleSelectTransferAccount = (value) => {
    setTransferAccountValue(value.name);
    setIsOpenTransferAccount(false);
  };

  const toggleDropdownCallStatus = () => {
    setIsOpenCallStatus(!isOpenCallStatus);
  };

  const handleSelect = (status) => {
    setSelectedValue(status.status);
    setCallStatusId(status.id);
    setRfqStatus(status.rfq_status);
    setIsOpenCallStatus(false); // Close dropdown after selection
  };

  const handleCallType = () => {
    // new 
    setCallType("Knowlarity");
    getCall();
    setSelectedValue("");
    setStartDate("");
    setRfqStatus("");
    setRemarksData({ remarks: "" });
    setTransferAccountValue("");
  };

  const handleLogCall = () => {
    setCallType("Manual");
    getCallAttemptCountLog();
    setSelectedValue("");
    setStartDate("");
    setRfqStatus("");
    setRemarksData({ remarks: "" });
    setTransferAccountValue("");
  };

  const handleDateChange = (date) => {
    if (date) {
      setMinTime(calculateMinTime(date));
    }

    setStartDate(date);

    if (!date) {
      setStartDate("");
      setDateDisable(false);
    } else {
      setStartDate(date);
    }
    setOpenDate(false);
  };

  const getCallAttemptHistory = async () => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    if (businessGroup) {
      setShowBusinessType(true);
      setCheckedBusinessType(businessType);
    }

    if (businessGroup === 100) {
      setOthers(true);
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      data: {
        requestQuoteID: id,
        quote_type: editRfqData?.quote_type,
      },
    };

    try {
      const { data } = await axios(apiUrl + "/call-history", options);
      if (data.data.length > 0) {
        setCallAttemptHistory(data.data);
        setIsShowRight(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllQuoteUsers = async () => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      data: {
        agent_list: "",
        id: [56],
        showAll: "true",
        slug: "quote-requests",
      },
    };
    return await axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        setAllQuoteUsers(data.data.data);
      })
      .catch((error) => console.log(error));
  };

  const showCallHistory = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Close if already open
  };

  const handleChangeRemarks = (e) => {
    setRemarksData({ ...remarksData, [e.target.name]: e.target.value });
  };

  const changeRFQ = async (val) => {
    await submitForm(val, "/createQuote");
  };

  // Function to fetch location suggestions
  const getLocation = (e) => {
    const inputValue = e.target.value;
    setLocationValue(inputValue);

    if (inputValue.length < 2) {
      setShowDropdown(false);
      return;
    }

    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios
      .post(
        apiUrl + "/get-suggested-cities",
        { keyword: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails?.token}`,
          },
        }
      )
      .then((response) => {
        setLocationList(response.data.data);
        setShowDropdown(true);
      })
      .catch((err) => console.error(err));
  };

  // Function to handle selection from dropdown
  const handleSelectLocation = (selectedLocation) => {
    setLocationValue(
      `${selectedLocation.name}, ${selectedLocation.state_name}`
    );
    setShowDropdown(false); // Hide dropdown after selection
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const getSRStatus = async (id) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      data: {
        rfq_id: id,
      },
    };

    return await axios(apiUrl + "/sourcing-exist", requestoption)
      .then(() => {
        return true;
      })
      .catch((error) => {
        setErrorMessage("SR is already created for this RFQ");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return false;
      });
  };

  const openCreateSRDetail = async (ID, leadID, srStatus, rfqStatus) => {
    if (srStatus === "") {
      const result = await getSRStatus(ID);
      var data = {
        requestQuoteID: ID,
        timestamp: new Date().getTime(),
        rfqStatus: rfqStatus,
      };
      localStorage.removeItem("SR_Details");
      localStorage.setItem("SR_Details", JSON.stringify(data));
      return result;
    } else {
      var data = {
        requestQuoteID: ID,
        requestLeadID: leadID,
        timestamp: new Date().getTime(),
        rfqStatus: rfqStatus,
      };
      let data1 = {
        srID: Number(leadID),
        timestamp: new Date().getTime(),
      };
      localStorage.removeItem("srState");
      localStorage.removeItem("SR_Details");
      localStorage.removeItem("rfqStatus");
      localStorage.setItem("srState", JSON.stringify(data1));
      localStorage.setItem("SR_Details", JSON.stringify(data));
      return true;
    }
  };

  const getRedirectUrl = (item, redirect) => {
    if (item.sourcing_status) {
      return "/sourcingPersonRequest";
    } else if (redirect) {
      return "/createSalesPersonRequest";
    } else {
      return "/quote-requests";
    }
  };

  const getSourcingRfqStatus = (status) => {
    switch (status) {
      case "new":
        return "SR In Process";
      case "pending":
        return "SR In Process";
      case "completed":
        return "SR completed";
      case "reopen":
        return "SR Reopen";
      case "cancelled":
        return "SR Cancelled";
      default:
        return "";
    }
  };

  if (
    is_redirect &&
    ["Converted to Quote", "Converted to MQ"].includes(rfq_status)
  ) {
    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: {
            quoteID: 0,
            rfqID: id,
            phone: editRfqData?.customer_mobile,
            rfq_status: rfq_status,
            call_response: call_response,
            call_id: call_id,
            message: editRfqData.message,
            status: status,
            call_status: callStatusId.toString(),
            remarks: remarksData.remarks,
            agent_id: editRfqData.agent_id,
            call_attempt_count: call_attempt_count,
            rfq_quote_type: editRfqData.quote_type,
            calling_mode: callType,
          },
        }}
      />
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorHandling message={errorMessage} type={"ErrorMessage"} />
      ) : null}
      {successMessage ? (
        <ErrorHandling message={successMessage} type={"SuccessMessage"} />
      ) : null}
      {!isMobileView ? (
        <section className="rfqDltMainCnt">
          <div className="container-40">
            <div className="rfqMainCnt">
              <div className="rfqLeftSection">
                <div className="customerInfoNonEditable">
                  <h1 className="pageSubHeading">Customer Info</h1>
                  <div className="customerInfoCnt mt-2">
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Account</label>
                        <input
                          type="button"
                          name="accountName"
                          value={editRfqData.accountName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Email</label>
                        <input
                          type="button"
                          name="customer_email"
                          value={editRfqData.customer_email}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Delivery Location</label>
                        {!isEditing ? (
                          <input
                            type="button"
                            name="locationValue"
                            value={locationValue}
                          />
                        ) : null}
                        {isDisabledEditRfq ? null : (
                          <>
                            {[
                              "pending",
                              "completed",
                              "cancelled",
                              "reopen",
                              "new",
                            ].includes(editRfqData.sourcing_status) ? null : (
                              <button
                                className="editButton"
                                onClick={handleEditClick}
                              >
                                <img
                                  src={edit}
                                  alt="edit icon"
                                  width="12px"
                                  height="12px"
                                />
                                <span>Edit</span>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {isEditing ? (
                        <div className="traInputWrapper">
                          <label htmlFor="name">Delivery Location</label>
                          <input
                            type="text"
                            id="name"
                            placeholder=""
                            value={locationValue}
                            style={{ paddingRight: "40px" }}
                            onChange={getLocation}
                            onBlur={() =>
                              setTimeout(() => setShowDropdown(false), 200)
                            } // Delay to allow click selection
                            autoFocus
                            autoComplete="off"
                          />
                          {showDropdown && locationList.length > 0 && (
                            <div className="customDropDownCnt show">
                              <ul className="dropdownWrapper">
                                {locationList.map((loc, index) => (
                                  <li
                                    key={index}
                                    className="dropdownItem"
                                    onMouseDown={() =>
                                      handleSelectLocation(loc)
                                    } // Prevent blur from closing dropdown
                                  >
                                    {loc.name}, {loc.state_name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div className="traInputIconWrapper searchIcon">
                            <img src={searchIcon} width="20px" height="20px" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Name</label>
                        <input
                          type="button"
                          disabled
                          name="customer_name"
                          value={editRfqData.customer_name}
                          // onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Role</label>
                        <input
                          type="button"
                          name="Role"
                          placeholder="Enter Role"
                          value={editRfqData.role}
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Mobile</label>
                        <input
                          type="button"
                          name="customer_mobile"
                          value={editRfqData.customer_mobile}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Agent</label>
                        <input
                          type="button"
                          id="assigned_to"
                          value={editRfqData.assigned_to}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem w-100">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Message</label>
                        <input
                          type="button"
                          name="rfq_message"
                          value={editRfqData.message}
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Support Remarks</label>
                        <input
                          type="button"
                          name="supportRemark"
                          value={editRfqData.remarks}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <section className="requirementDtlCnt2">
                  <div className="">
                    {editRfqData.product ? (
                      <h2 className="pageSubHeading">Requirement Details</h2>
                    ) : null}
                    <div className="requirementDtlTableCnt testing">
                      {editRfqData.product ? (
                        <table className="requirementTable">
                          <thead className="tableHead">
                            <tr>
                              <th style={{ width: "20%" }}>Type</th>
                              <th style={{ width: "33%" }}>Name</th>
                              <th style={{ width: "10%" }}>Brand</th>
                              <th style={{ width: "10%" }}>Quantity</th>
                              <th style={{ width: "10%" }}>Unit</th>
                              <th style={{ width: "10%" }}>Price</th>
                              <th style={{ width: "7%" }}>&nbsp;</th>
                            </tr>
                          </thead>
                          {products?.map((item, index) => (
                            <tbody className="tableBody">
                              <tr key={index}>
                                {isDisabledEditRfq ? (
                                  <td className="disbled_filed_wrapper normal">
                                    <label htmlFor="name">
                                      {item.type === "product"
                                        ? "Catalogue"
                                        : item.type === "category"
                                        ? "Category"
                                        : item.type === "other"
                                        ? "Other"
                                        : item.type === "attachments"
                                        ? "Attachment"
                                        : item.type}
                                    </label>
                                  </td>
                                ) : (
                                  <td className={`enabled_filed_wrapper`}>
                                    <div className="customeSelectCnt tableSelectOption">
                                      <button
                                        className="customSelectBtn"
                                        onClick={() => toggleDropdown(index)}
                                        onBlur={toggleBlur}
                                      >
                                        <label htmlFor="name">
                                          {item.type === "product"
                                            ? "Catalogue"
                                            : item.type === "category"
                                            ? "Category"
                                            : item.type === "other"
                                            ? "Other"
                                            : item.type === "attachments"
                                            ? "Attachment"
                                            : item.type}
                                        </label>
                                        <div className="customSelectBtnIcon downArrowIcon">
                                          <img
                                            src={downArrowGrey}
                                            width="12px"
                                            height="12px"
                                          />
                                        </div>
                                      </button>
                                      {/* Dropdown List using <ul> and <li> */}
                                      {isOpen[index] && ( // Check if the specific index is open
                                        <div className="customDropDownCnt show">
                                          <ul
                                            className="dropdownWrapper"
                                            onMouseDown={(e) =>
                                              e.preventDefault()
                                            }
                                          >
                                            {[
                                              {
                                                label: "Catalogue",
                                                value: "product",
                                              },
                                              {
                                                label: "Category",
                                                value: "category",
                                              },
                                              {
                                                label: "Other",
                                                value: "other",
                                              },
                                              {
                                                label: "Attachment",
                                                value: "attachments",
                                              },
                                            ].map((option, idx) => (
                                              <li
                                                key={option.value}
                                                className="dropdownItem"
                                                onClick={() =>
                                                  handleProductChange(
                                                    index,
                                                    "type",
                                                    option.value
                                                  )
                                                }
                                              >
                                                {option.label}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                )}
                                {isDisabledEditRfq &&
                                item.type === "product" ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.productName}
                                  </td>
                                ) : (
                                  <>
                                    {item.type == "product" ? (
                                      <td
                                        className="enabled_filed_wrapper"
                                        key={index}
                                        style={{ width: "40%" }}
                                      >
                                        <div className="tableSelectOption">
                                          <div className="textEditableInputCnt">
                                            <textarea
                                              rows={1}
                                              className="textEditableInput"
                                              placeholder=""
                                              value={item.productName}
                                              onChange={(e) =>
                                                handleTextAreaChange(e, index)
                                              }
                                              onFocus={() =>
                                                setActiveDropdownIndex(index)
                                              }
                                            />
                                            {item.link ? (
                                              <a
                                                href={item.link}
                                                target="_blank"
                                                className="view_Btn"
                                              >
                                                <img
                                                  src={viewBtn}
                                                  width="16px"
                                                  height="16px"
                                                />
                                              </a>
                                            ) : null}
                                            {suggestions.length > 0 &&
                                              item.showProducts &&
                                              activeDropdownIndex === index && (
                                                <div
                                                  className="customDropDownCnt show"
                                                  key={index}
                                                >
                                                  <ul className="dropdownWrapper">
                                                    {suggestions.map(
                                                      (suggestion, idx) => (
                                                        <li
                                                          key={idx}
                                                          className="dropdownItem"
                                                          onClick={() =>
                                                            handleSuggestionClick(
                                                              index,
                                                              suggestion
                                                            )
                                                          }
                                                        >
                                                          {suggestion.label}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </td>
                                    ) : null}
                                  </>
                                )}
                                {isDisabledEditRfq &&
                                item.type === "category" ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.productName}
                                  </td>
                                ) : (
                                  <>
                                    {item.type == "category" ? (
                                      <td
                                        className="enabled_filed_wrapper"
                                        key={index}
                                        style={{ width: "40%" }}
                                      >
                                        <div className="tableSelectOption">
                                          <div className="textEditableInputCnt">
                                            {/* Textarea to display the selected category */}
                                            <textarea
                                              rows={1}
                                              className="textEditableInput"
                                              placeholder=""
                                              value={item.productName}
                                              onChange={(e) =>
                                                handleTextAreaCategory(e, index)
                                              }
                                              onFocus={() =>
                                                setActiveDropdownIndex(index)
                                              }
                                              // readOnly
                                            />
                                          </div>
                                          {item.link ? (
                                            <a
                                              href={item.link}
                                              target="_blank"
                                              className="view_Btn"
                                            >
                                              <img
                                                src={viewBtn}
                                                width="16px"
                                                height="16px"
                                              />
                                            </a>
                                          ) : null}
                                          {/* Suggestion dropdown */}
                                          {categorySuggestions.length > 0 &&
                                            item.showProducts &&
                                            activeDropdownIndex === index && (
                                              <div className="customDropDownCnt show">
                                                <ul className="dropdownWrapper">
                                                  {categorySuggestions.map(
                                                    (suggestion, idx) => (
                                                      <li
                                                        key={suggestion.value}
                                                        className="dropdownItem"
                                                        onClick={() =>
                                                          handleCategoryClick(
                                                            index,
                                                            suggestion
                                                          )
                                                        }
                                                      >
                                                        {suggestion.label}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            )}
                                        </div>
                                      </td>
                                    ) : null}
                                  </>
                                )}
                                {isDisabledEditRfq && item.type === "other" ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.productName}
                                  </td>
                                ) : (
                                  <>
                                    {item.type == "other" ? (
                                      <>
                                        <td
                                          className="enabled_filed_wrapper"
                                          style={{ width: "40%" }}
                                        >
                                          <div className="tableSelectOption">
                                            <div className="textEditableInputCnt">
                                              <textarea
                                                rows={1}
                                                className="textEditableInput"
                                                type="text"
                                                placeholder=""
                                                value={item.productName}
                                                onChange={(e) =>
                                                  handleTextAreaOther(e, index)
                                                }
                                                // onBlur={() => this.getOtherName(index)}
                                              ></textarea>
                                            </div>
                                          </div>
                                        </td>
                                      </>
                                    ) : null}
                                  </>
                                )}

                                {item.type == "attachments" ? (
                                  <>
                                    {item.productName ? (
                                      <td className="disbled_filed_wrapper normal">
                                        <div className="tableSelectOption">
                                          <a
                                            className="pl-0 attachmenttext"
                                            href={item.productName}
                                            target="_blank"
                                          >
                                            Attachment
                                          </a>
                                        </div>
                                      </td>
                                    ) : (
                                      <>
                                        {isImageLoading &&
                                        imageIndex === index ? (
                                          <Spinner
                                            animation="border"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </Spinner>
                                        ) : (
                                          <td
                                            className="enabled_filed_wrapper"
                                            style={{ width: "40%" }}
                                          >
                                            <div className="tableSelectOption">
                                              <div className="uploadFileCnt">
                                                <label
                                                  for="downloadIcon"
                                                  className="upload_title"
                                                >
                                                  <img
                                                    src={downloadIcon}
                                                    width="16px"
                                                    height="16px"
                                                  />
                                                  <span>Upload a file</span>
                                                </label>
                                                <input
                                                  id="downloadIcon"
                                                  className="downloadFile"
                                                  style={{ display: "none" }}
                                                  type="file"
                                                  name="attachments"
                                                  accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                                  onChange={(e) =>
                                                    onFileChange(index, e)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : null}
                                {isDisabledEditRfq ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.brand}
                                  </td>
                                ) : (
                                  <>
                                    <td
                                      className={`${
                                        item.type === "attachments"
                                          ? "disbled_filed_wrapper"
                                          : "enabled_filed_wrapper"
                                      } ${
                                        item.type === "product"
                                          ? "disbled_filed_wrapper"
                                          : ""
                                      }`}
                                    >
                                      <div className="tableSelectOption">
                                        {item.type === "attachments" ? null : (
                                          <>
                                            <label></label>
                                            <input
                                              type="text"
                                              className={`${
                                                item.type === "product"
                                                  ? "disbled_filed_wrapper"
                                                  : "enabled_filed_wrapper"
                                              }`}
                                              placeholder=""
                                              name="brand"
                                              value={item.brand}
                                              onChange={(e) =>
                                                handleTextChange(
                                                  index,
                                                  "brand",
                                                  e.target.value
                                                )
                                              }
                                              disabled={`${
                                                item.type == "product"
                                                  ? "disbled_filed_wrapper"
                                                  : ""
                                              }`}
                                              autoComplete="off"
                                              // readOnly="readonly"
                                            />
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                                {isDisabledEditRfq ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.productQuantity}
                                  </td>
                                ) : (
                                  <>
                                    <td
                                      className={`${
                                        item.type === "attachments"
                                          ? "disbled_filed_wrapper"
                                          : "enabled_filed_wrapper"
                                      }`}
                                    >
                                      <div className="tableSelectOption">
                                        {item.type === "attachments" ? null : (
                                          <>
                                            <label></label>
                                            <input
                                              type="number"
                                              className=""
                                              placeholder=""
                                              name="productQuantity"
                                              value={item.productQuantity}
                                              onChange={(e) =>
                                                handleTextChange(
                                                  index,
                                                  "productQuantity",
                                                  e.target.value
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                                {isDisabledEditRfq ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.quantity_type}
                                  </td>
                                ) : (
                                  <>
                                    <td
                                      className={`${
                                        item.type === "attachments" ||
                                        item.type == "product"
                                          ? "disbled_filed_wrapper"
                                          : "enabled_filed_wrapper"
                                      }`}
                                    >
                                      <div className="tableSelectOption">
                                        {item.type === "attachments" ? null : (
                                          <>
                                            <label></label>
                                            <input
                                              type="text"
                                              className={`${
                                                item.type === "product"
                                                  ? "disbled_filed_wrapper"
                                                  : "enabled_filed_wrapper"
                                              }`}
                                              placeholder=""
                                              name="quantity_type"
                                              value={item.quantity_type}
                                              onChange={(e) =>
                                                handleTextChange(
                                                  index,
                                                  "quantity_type",
                                                  e.target.value
                                                )
                                              }
                                              disabled={`${
                                                item.type == "product"
                                                  ? "disbled_filed_wrapper"
                                                  : ""
                                              }`}
                                              autoComplete="off"
                                            />
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                                {isDisabledEditRfq ? (
                                  <td className="disbled_filed_wrapper normal">
                                    {item.productPrice}
                                  </td>
                                ) : (
                                  <>
                                    <td
                                      className={`${
                                        item.type === "attachments" ||
                                        item.type == "product"
                                          ? "disbled_filed_wrapper"
                                          : "enabled_filed_wrapper"
                                      } ${
                                        item.type === "product" ||
                                        item.type === "category" ||
                                        item.type === "other"
                                          ? "disbled_filed_wrapper"
                                          : "enabled_filed_wrapper"
                                      }`}
                                    >
                                      <div className="tableSelectOption">
                                        {item.type === "attachments" ? null : (
                                          <>
                                            <label></label>
                                            <input
                                              type="text"
                                              className={`${
                                                item.type === "product" ||
                                                item.type == "category" ||
                                                item.type == "other"
                                                  ? "disbled_filed_wrapper"
                                                  : "enabled_filed_wrapper"
                                              }`}
                                              placeholder=""
                                              name="productPrice"
                                              value={item.productPrice}
                                              onChange={(e) =>
                                                handleTextChange(
                                                  index,
                                                  "productPrice",
                                                  e.target.value
                                                )
                                              }
                                              disabled={`${
                                                item.type == "product" ||
                                                item.type == "category" ||
                                                item.type == "other"
                                                  ? "disbled_filed_wrapper"
                                                  : ""
                                              }`}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                                <td
                                  className={`${
                                    isDisabledEditRfq
                                      ? "disbled_filed_wrapper normal"
                                      : ""
                                  }`}
                                >
                                  {products.length === 1 ||
                                  isDisabledEditRfq ? null : (
                                    <div
                                      className={`tableSelectOption deleteIconBtn`}
                                    >
                                      <img
                                        src={deleteIcon}
                                        width="24px"
                                        height="24px"
                                        alt="deleteIcon"
                                        id={index}
                                        onClick={() => removeItem(index)}
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      ) : null}
                      {isDisabledEditRfq ? null : (
                        <button
                          onClick={handleAddItem}
                          type="button"
                          className="addItemsBtn"
                        >
                          Add item
                        </button>
                      )}
                    </div>
                  </div>
                </section>
                <section className="footerCnt">
                  {["Closed - Lost"].includes(editRfqData.status) ? null : (
                    <div className="container-40">
                      <div className="footerWrapper">
                        {[
                          "new",
                          "pending",
                          "cancelled",
                          "reopen",
                          "completed",
                        ].includes(editRfqData.sourcing_status) ? (
                          <Link
                            className="rfqCommonBtn"
                            to="#"
                            onClick={async (e) => {
                              e.preventDefault();
                              const data = await openCreateSRDetail(
                                editRfqData.id,
                                editRfqData.sourcing_id,
                                editRfqData.sourcing_status,
                                editRfqData.status
                              );
                              const redirectUrl = getRedirectUrl(
                                editRfqData,
                                data
                              );
                              if (redirectUrl !== "/quote-requests") {
                                window.open(redirectUrl, "_blank");
                              } else {
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);
                              }
                            }}
                          >
                            {getSourcingRfqStatus(editRfqData.sourcing_status)}
                          </Link>
                        ) : null}
                        {[""].includes(editRfqData.sourcing_status) ? (
                          <button
                            id="submit"
                            disabled={disabledButton}
                            onClick={handleEditSRAndCreateSource}
                            type="button"
                            className="rfqCommonBtn"
                          >
                            {/* Submit with SR */}
                            {submitLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                style={{ width: "1.3rem", height: "1.3rem" }}
                              />
                            ) : (
                              "Submit with SR"
                            )}
                          </button>
                        ) : null}
                        {rfq_status == "Converted to Quote" &&
                        ![
                          "Converted to Quote",
                          "Converted to MQ",
                        ].includes() ? (
                          <button
                            style={{ width: "200px" }}
                            type="button"
                            className={`rfqCommonBtn bgBlue`}
                            disabled={disabledButton}
                            id="submit"
                            onClick={
                              !isSaveLoading ? (val) => changeRFQ(val) : null
                            }
                          >
                            {isSaveLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                style={{ width: "1.3rem", height: "1.3rem" }}
                              />
                            ) : (
                              "Save & Create Quote"
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className={`rfqCommonBtn bgBlue`}
                            id="submit"
                            disabled={disabledButton}
                            onClick={!isSaveLoading ? handleSubmitForm : null}
                          >
                            {isSaveLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                style={{ width: "1.3rem", height: "1.3rem" }}
                              />
                            ) : (
                              "Submit Changes"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </section>
              </div>
              <div className="rfqRightSection">
                <div className="calLogBtnWrapper">
                  <button
                    type="button"
                    className={`rfqCommonBtn bgBlue`}
                    onClick={handleCallType}
                    // disabled={isCallingLog}
                    value="Knowlarity"
                  >
                    <img
                      src={callIcon}
                      alt="call icon"
                      width="11px"
                      height="11px"
                    />
                    {isCalling ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span>Call Customer</span>
                    )}
                  </button>
                  <button
                    type="button"
                    className={`rfqCommonBtn bgGrey`}
                    onClick={handleLogCall}
                    // disabled={isCallingLog}
                    value="Manual"
                  >
                    {isCallingLog ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span>Log a Call</span>
                    )}
                  </button>
                </div>
                {/* new section start here */}
                {isShowRight && callStatusArray.length > 0 ? (
                  <div className="callRemarkCnt">
                    <h2 className="pageSubHeading">Remarks on Call Attempt</h2>
                    <div className="callReamrkWrapper">
                      <div className="callReamrkItem">
                        <div className="customeSelectCnt callReamrkDropCnt">
                          <button
                            className="customSelectBtn"
                            onClick={toggleDropdownCallStatus}
                          >
                            <label htmlFor="name">Call Status</label>
                            <span className="selectedValue">
                              {selectedValue}
                            </span>
                            <div className="customSelectBtnIcon downArrowIcon">
                              <img
                                src={downArrowGrey}
                                width="12px"
                                height="12px"
                                alt="Dropdown Icon"
                              />
                            </div>
                          </button>

                          {isOpenCallStatus && callStatusArray.length > 0 && (
                            <div className="customDropDownCnt show">
                              <ul className="dropdownWrapperScroll">
                                {callStatusArray.map((item, index) => (
                                  <li
                                    key={index}
                                    className="dropdownItem"
                                    onClick={() => handleSelect(item)}
                                  >
                                    {item.status}
                                    <hr className="dashed-border"/>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      {isShowRight ? (
                        <div className="callReamrkItem">
                          <div className="traInputWrapper">
                            <label htmlFor="name">Call Back</label>
                            <DatePicker
                              showTimeSelect
                              placeholder=""
                              dateFormat="MMMM d, yyyy h:mm:ss aa"
                              selected={startDate}
                              onChange={handleDateChange}
                              className="form-control"
                              locale="es"
                              minDate={new Date()}
                              minTime={minTime}
                              maxTime={moment().endOf("day").toDate()}
                              value={startDate ? startDate : "Select"}
                              openDate={openDate} // Control dropdown visibility
                              onClickOutside={() => setOpenDate(false)} // Close when clicking outside
                              onFocus={() => setOpenDate(true)} // Open when focusing on the input
                              required={callBackMandate}
                              disabled={
                                !["Call Back Scheduled"].includes(selectedValue)
                              }
                            />
                            {callAttemptHistory.length > 0 ? (
                              <CallAttemptedHistory
                                callAttemptHistory={callAttemptHistory}
                              />
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      <div className="callReamrkItem">
                        <div className="customeSelectCnt callReamrkDropCnt">
                          <button
                            className={`customSelectBtn ${
                              selectedValue && "disabled"
                            }`}
                          >
                            <label htmlFor="name">RFQ Status</label>
                            <span className="selectedValue">{rfq_status}</span>
                          </button>
                          <div className={`customDropDownCnt`}></div>
                        </div>
                      </div>
                      <div className="callReamrkItem">
                        <div className="traInputWrapper">
                          <label htmlFor="name">Remarks</label>
                          <input
                            type="text"
                            name="remarks"
                            placeholder=""
                            value={remarksData.remarks}
                            onChange={handleChangeRemarks}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* new section ends here */}
                {callAttemptHistory.length > 0 ? (
                  <div className="callHistoryCnt">
                    <h2 className="pageSubHeading">Call History</h2>
                    <div className="callHistoryAccordionCnt">
                      {callAttemptHistory.map((item, index) => (
                        <div
                          key={index}
                          className={`callHistoryAccordionItem ${
                            openIndex === index ? "show" : ""
                          }`}
                        >
                          <button
                            className="callAccordionBtn"
                            onClick={() => showCallHistory(index)}
                          >
                            <span>Call {item.call_attempt}</span>
                            <img
                              src={backArrowInactive}
                              width="12px"
                              height="12px"
                              alt="arrow icon"
                              className={`inactiveIcon ${
                                openIndex === index ? "hidden" : ""
                              }`}
                            />
                            <img
                              src={backArrowNew}
                              width="12px"
                              height="12px"
                              alt="arrow icon"
                              className={`activeIcon ${
                                openIndex === index ? "show" : "hidden"
                              }`}
                            />
                          </button>
                          {openIndex === index && (
                            <div className="callAccordionBody">
                              <ul className="callHistoryWrapper">
                                <li>
                                  <span>Call {item.call_attempt}</span>
                                  <p>{item.agent_note}</p>
                                </li>
                                <li>
                                  <span>Status</span>
                                  <p>{item.call_status}</p>
                                </li>
                                <li>
                                  <span>By</span>
                                  <p>
                                    {item.firstname} {item.lastname}
                                  </p>
                                </li>
                                <li>
                                  <span>on</span>
                                  <p>
                                    {moment
                                      .utc(item.created_at)
                                      .format("Do MMM, YYYY HH:mm")}
                                  </p>
                                </li>
                                <li>
                                  <span>Calling Mode</span>
                                  <p>{item.calling_mode}</p>
                                </li>
                                <li>
                                  <span>Follow up on</span>
                                  <p>{item.followup_date}</p>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <EditRfqMobileView
          editRfqData={editRfqData}
          locationValue={locationValue}
          isCallingLog={isCallingLog}
          isCalling={isCalling}
          toggleDropdownCallStatus={toggleDropdownCallStatus}
          selectedValue={selectedValue}
          isOpenCallStatus={isOpenCallStatus}
          callStatusArray={callStatusArray}
          isShowRight={isShowRight}
          startDate={startDate}
          minTime={minTime}
          openDate={openDate}
          callAttemptHistory={callAttemptHistory}
          callBackMandate={callBackMandate}
          rfq_status={rfq_status}
          remarks={remarksData.remarks}
          openIndex={openIndex}
          handleChangeRemarks={handleChangeRemarks}
          setOpenDate={setOpenDate}
          handleDateChange={handleDateChange}
          handleSelect={handleSelect}
          handleCallType={handleCallType}
          handleLogCall={handleLogCall}
          showCallHistory={showCallHistory}
          getLocation={getLocation}
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
          locationList={locationList}
          handleSelectLocation={handleSelectLocation}
          // take it
          openCreateSRDetail={openCreateSRDetail}
          getRedirectUrl={getRedirectUrl}
          getSourcingRfqStatus={getSourcingRfqStatus}
          disabledButton={disabledButton}
          handleEditSRAndCreateSource={handleEditSRAndCreateSource}
          submitLoading={submitLoading}
          changeRFQ={changeRFQ}
          isSaveLoading={isSaveLoading}
          // new
          products={products}
          isDisabledEditRfq={isDisabledEditRfq}
          toggleDropdown={toggleDropdown}
          toggleBlur={toggleBlur}
          isOpen={isOpen}
          handleProductChange={handleProductChange}
          handleTextAreaChange={handleTextAreaChange}
          setActiveDropdownIndex={setActiveDropdownIndex}
          suggestions={suggestions}
          handleSuggestionClick={handleSuggestionClick}
          handleTextAreaCategory={handleTextAreaCategory}
          categorySuggestions={categorySuggestions}
          handleCategoryClick={handleCategoryClick}
          handleTextAreaOther={handleTextAreaOther}
          isImageLoading={isImageLoading}
          imageIndex={imageIndex}
          onFileChange={onFileChange}
          handleTextChange={handleTextChange}
          removeItem={removeItem}
          activeDropdownIndex={activeDropdownIndex}
          setProducts={setProducts}
          handleSubmitForm={handleSubmitForm}
          // new 
          editProductData={editProductData}
          editableProduct={editableProduct}
          handleTextAreaChange1={handleTextAreaChange1}
          handleSuggestionClick1={handleSuggestionClick1}
          handleTextChange1={handleTextChange1}
          handleProductChange1={handleProductChange1}
          handleTextAreaCategory1={handleTextAreaCategory1}
          handleCategoryClick1={handleCategoryClick1}
          handleTextAreaOther1={handleTextAreaOther1}
          onFileChange1={onFileChange1}
          setEditProductData={setEditProductData}
          setEditableProduct={setEditableProduct}
        />
      )}
    </>
  );
};

export default EditRfq;
