import React from "react";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";

import Home from "./Index";
import Header from "./Common/Header";
import Footer from "./Common/Footer";

import MyAccounts from "./BusinessAccount/MyAccounts";
import AccountMapping from "./BusinessAccount/AccountMapping";
import BusinessAccount from "./BusinessAccount/AllBusinessAccounts";
import BusinessProfile from "./BusinessAccount/BusinessProfile";

import CreateRfq from "./Quotes/CreateRfq";
import CreateLead from "./Leads/CreateLead";
import AllLeads from "./Leads/AllLeads";

import CreateQuote from "./Quotes/CreateQuote";
import DuplicateQuote from "./Quotes/DuplicateQuote";
import ListQuote from "./Quotes/ListQuote";
import QuoteRequest from "./Quotes/QuoteRequest";

import Profile from "./Profile";
import Getdistance from "../Distance/getdistance";
import RFQDetail from "./BusinessAccount/RFQDetail";

import PurchaseOrderList from "./PurchaseOrder/PurchaseOrderList";
import PurchaseOrderDetail from "./PurchaseOrder/PurchaseOrderDetail";
import EditPO from "./PurchaseOrder/EditPO";

import CreateSalesPersonRequest from "./SourcingRequest/CreateSalesPersonRequest";
import CreateSourcingPersonRequest from "./SourcingRequest/CreateSourcingPersonRequest";
import SourcingRequestList from "./SourcingRequest/SourcingRequestList";
import SourcingUplaodList from "./SourcingUpload/SourcingUploadList";
import "../../asset/css/theme.css";

import Bredcrum from "./Common/Bredcrum";
import Auth from "../Auth";
import Constants from "../../Config";

const apiUrl = Constants.API_URL;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftNav: [],
      routes: [],
      authMessage: "",
      childSlugObject: {},
      isLoading: true,
      groupSlugsArray: [],
    };
  }
  componentDidMount() {
    this.getNavbar();
  }

  getNavbar = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = userData.token;
    const requestoption = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return await axios(apiUrl + "/get-navigation", requestoption)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        const array = data.data.data;
        if (array.length === 0) {
          localStorage.removeItem("userData");
          window.location.reload();
        }
        let results = array
          .map((x) => x.modules)
          .flat()
          .map((y) => y.module_slug);

        let groupSlugsArray = [];
        array.map((item) => {
          groupSlugsArray.push(item.group_slug);
        });
        let modulesNew = array.map((x) => x.modules).flat();
        const childSlugObject = {};
        modulesNew.map((item) => {
          childSlugObject[item.module_slug] = Object.values(
            item.sub_module_name
          );
        });
        this.setState({
          leftNav: data.data.data,
          routes: results,
          isLoading: false,
          authMessage: "You are not Authorized to see this section",
          childSlugObject,
          groupSlugsArray,
        });
      })
      .catch((error) => console.log(error.message));
  };

  render() {
    let pageType = this.props.match.params.page_type;
    let quoteState = this.props.location.state;
    const getUserDetails = JSON.parse(localStorage.getItem("userData"));
    return (
      <div className="wrapper">
        <Header />
        <div className="page-wrap">
          <div className="main-content">
            {this.state.isLoading ? (
              <div style={{ marginLeft: "50%" }}>
                <Spinner animation="border" />
              </div>
            ) : (
              <Container fluid>
                <Bredcrum pageType={pageType} />
                {(() => {
                  switch (pageType) {
                    case "createQuote":
                      if (this.state.routes.includes("createQuote")) {
                        return (
                          <CreateQuote
                            quoteState={quoteState ? quoteState : 0}
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    case "listQuote":
                      if (this.state.routes.includes("listQuote")) {
                        return (
                          <ListQuote
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    case "editQuote":
                      return (
                        <CreateQuote
                          quoteState={quoteState ? quoteState : 0}
                          childSlugProp={this.state.childSlugObject}
                          getUserDetails={getUserDetails}
                        />
                      );

                    case "duplicateQuote":
                      return (
                        <DuplicateQuote
                          quoteState={quoteState ? quoteState : 0}
                          childSlugProp={this.state.childSlugObject}
                        />
                      );

                    case "dashboard":
                      if (this.state.groupSlugsArray.includes("dashboard")) {
                        return <Home />;
                      } else return <Auth />;

                    case "profile":
                      return <Profile />;

                    case "business-accounts":
                      if (this.state.routes.includes("business-accounts")) {
                        return (
                          <BusinessAccount
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    case "my-accounts":
                      if (this.state.routes.includes("my-accounts")) {
                        return (
                          <MyAccounts
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    case "account-mapping":
                      if (this.state.routes.includes("account-mapping")) {
                        return <AccountMapping />;
                      } else return <Auth />;

                    case "all-leads":
                      if (this.state.routes.includes("all-leads")) {
                        return (
                          <AllLeads
                            groupSlugRoutes={this.state.routes}
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    case "createlead":
                      if (
                        this.state.childSlugObject["createlead"].includes(
                          "add"
                        ) &&
                        this.state.routes.includes("createlead")
                      ) {
                        return <CreateLead getUserDetails={getUserDetails}/>;
                      } else return <Auth />;

                    case "quote-requests":
                      if (this.state.routes.includes("quote-requests")) {
                        return (
                          <QuoteRequest
                            quote_type="RFQ_OOS"
                            groupSlugRoutes={this.state.routes}
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    case "createRfq":
                      if (
                        this.state.childSlugObject["createRfq"].includes(
                          "add"
                        ) &&
                        this.state.routes.includes("createRfq")
                      ) {
                        return <CreateRfq getUserDetails={getUserDetails}/>;
                      } else return <Auth />;

                    case "BusinessProfile":
                      if (
                        this.state.routes.includes("BusinessProfile") &&
                        this.state.childSlugObject["BusinessProfile"].includes(
                          "add"
                        )
                      ) {
                        return <BusinessProfile getUserDetails={getUserDetails}/>;
                      } else return <Auth />;

                    case "quote-requests-bulk":
                      if (this.state.routes.includes("quote-requests-bulk")) {
                        return <QuoteRequest quote_type="RFQ_BULK" />;
                      } else return <Auth />;

                    case "quote-requests-tech":
                      if (this.state.routes.includes("quote-requests-tech")) {
                        return <QuoteRequest quote_type="TECH_QUERY" />;
                      } else return <Auth />;

                    case "quote-requests-promo":
                      if (this.state.routes.includes("quote-requests-promo")) {
                        return (
                          <QuoteRequest
                            quote_type="RFQ_BULK_PROMO"
                            childSlugProp={this.state.childSlugObject}
                          />
                        );
                      } else return <Auth />;

                    case "distance":
                      if (this.state.groupSlugsArray.includes("tools")) {
                        return <Getdistance />;
                      } else return <Auth />;

                    case "rfqDetails":
                      return <RFQDetail />;

                    case "po-list":
                      return <PurchaseOrderList getUserDetails={getUserDetails}/>;

                    case "createPO":
                      return <PurchaseOrderDetail />;
                    case "editPO":
                      return <EditPO />;

                    case "createSalesPersonRequest":
                      return (
                        <CreateSalesPersonRequest
                          groupSlugRoutes={this.state.routes}
                          childSlugProp={this.state.childSlugObject}
                        />
                      );

                    case "sourcingPersonRequest":
                      return (
                        <CreateSourcingPersonRequest
                          groupSlugRoutes={this.state.routes}
                          childSlugProp={this.state.childSlugObject}
                          getUserDetails={getUserDetails}
                        />
                      );

                    case "sourcing-request":
                      if (this.state.routes.includes("sourcing-request")) {
                        return (
                          <SourcingRequestList
                            groupSlugRoutes={this.state.routes}
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                      case "add-bulk-products":
                      if (this.state.routes.includes("add-bulk-products")) {
                        return (
                          <SourcingUplaodList
                            groupSlugRoutes={this.state.routes}
                            childSlugProp={this.state.childSlugObject}
                            getUserDetails={getUserDetails}
                          />
                        );
                      } else return <Auth />;

                    default:
                  }
                })()}
              </Container>
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Dashboard;
