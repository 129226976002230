import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Table } from "react-bootstrap";
import Constants from "../../../Config";
import axios from "axios";
import { Modal, Button, Form, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./listQuote.css";
import history from "../../../helper/history";
const apiUrl = Constants.API_URL;

class ProductLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      available_stock: 0,
      currentIndex: "",
      show: false,
      showMarkup: false,
      showTP: false,
      showTotalPopup: false,
      TicketID: "",
      TP: "",
      markup: "",
      selectVendor: "",
      vendorlist: [],
      message: "",
      vendordata: [],
      special_tp: "",
      disabled: false,
      moq: "",
      pack_quantity: "",
      product_id: "",
      tax_in_percentage: "",
      tax_in_amount: "",
      // quantity : 0,
      // markup : "",
      list_price: "",
      netAmount: "",
      transfer_price: "",
      special_tp: "",
      list_price_withGst: "",
      isMarkUpZero: false,
      markupError: "",
      discount_on_mrp: "",
      mrp: "",
      triggerRender: false, 
    };
  }
  openEditQuantityPopup = (
    quantity,
    index,
    available_stock,
    product_id,
    vendor,
    special_tp
  ) => {
    this.setState({
      quantity: quantity,
      available_stock: available_stock,
      currentIndex: index,
      product_id: product_id,
      show: true,
      selectVendor: vendor,
      special_tp: special_tp,
    });
  };
  openEditMarkupPopup = (markup, index, special_tp) => {
    this.setState({
      markup: markup,
      currentIndex: index,
      showMarkup: true,
      special_tp: special_tp,
      // selectVendor: vendor
    });
  };

  openEditTpPopup = (TP, index, vendor) => {
    this.setState({
      vendorlist: vendor,
      TP: TP,
      currentIndex: index,
      showTP: true,
      vendordata: vendor,
    });
  };
  openTotalPopup = (
    totalValue,
    currentIndex,
    tax_in_percentage,
    quantity,
    markup,
    list_price,
    netAmount,
    transfer_price,
    discount_on_mrp,
    mrp,
    special_tp
  ) => {
    this.setState({
      showTotalPopup: true,
      currentIndex,
      total: totalValue,
      tax_in_percentage,
      quantity,
      markup,
      mrp,
      list_price,
      list_price_withGst: Number(
        Number(list_price) +
          (Number(list_price) * Number(tax_in_percentage)) / 100
      ),
      netAmount,
      transfer_price,
      discount_on_mrp,
      mrp,
      special_tp,
    });
  };
  closeTotalPopup = () => {
    this.setState({
      showTotalPopup: false,
    });
  };

  closeTPModal = () => {
    this.setState({
      showTP: false,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });
  };
  closeModalMarkup = () => {
    this.setState({
      showMarkup: false,
    });
  };

  add = () => {
    let quantity =
      Number(this.state.quantity) + Number(this.state.pack_quantity);
    this.setState({ quantity: quantity });
  };
  updateQuantity = (e) => {
    e.preventDefault();
    this.closeModal();
    this.props.updateQuantityWithPrices({
      quantity: this.state.quantity,
      index: this.state.currentIndex,
      vendordata: this.state.vendordata,
      TP: this.state.special_tp,
      TicketID: this.state.TicketID,
      selectVendor: this.state.selectVendor,
    });
  };
  updateMarkup = (e) => {
    e.preventDefault();
    this.closeModalMarkup();
    this.props.updateMarkupWithPrices({
      markup: this.state.markup,
      index: this.state.currentIndex,
      TP: this.state.special_tp,
      TicketID: this.state.TicketID,
      selectVendor: this.state.selectVendor,
    });
  };

  updateTP = (e) => {
    e.preventDefault();
    this.closeTPModal();
    this.props.specialTP({
      TP: this.state.TP,
      index: this.state.currentIndex,
      TicketID: this.state.TicketID,
      selectVendor: this.state.selectVendor,
      vendordata: this.state.vendordata,
    });
    this.setState({
      tpChanged: true,
    });
  };

  updateTotal = (e) => {
    e.preventDefault();
    this.closeTotalPopup();
    this.props.updateTotal({
      totalValue: this.state.total,
      index: this.state.currentIndex,
      listPrice: this.state.list_price,
      markup: this.state.markup,
      netAmount: this.state.netAmount,
      discount_on_mrp: this.state.discount_on_mrp,
      special_tp: this.state.special_tp,
    });
  };
  handleTotalChange = (e) => {
    let list_price_withGst = Number(e.target.value);
    let list_price = Number(e.target.value);
    //   (Number(list_price_withGst) * 100) / (100 + this.state.tax_in_percentage);
    // let total = list_price_withGst * this.state.quantity;
    let total = list_price * this.state.quantity;
    // let netAmount = Number(((100 / 118) * total));
    let netAmount = Number(list_price * this.state.quantity);
    let discount_on_mrp = Number(
      ((Number(this.state.mrp) - Number(list_price)) / Number(this.state.mrp)) *
        100
    );
    let markup = "";
    if (Number(this.state.special_tp) === 0) {
      markup = Number(
        ((Number(list_price) - this.state.transfer_price) * 100) /
          this.state.transfer_price
      );
    } else {
      markup = Number(
        ((Number(list_price) - this.state.special_tp) * 100) /
          this.state.special_tp
      );
    }

    if (markup <= 0) {
      this.setState(
        {
          isMarkUpZero: true,
          markupError: "Mark up is not Allowed to be 0 or Negative",
        },
        () => {
          setTimeout(() => {
            this.setState({
              markupError: "",
            });
          }, 3000);
        }
      );
    } else {
      this.setState({
        isMarkUpZero: false,
        markupError: "",
      });
    }

    this.setState(
      {
        total,
        netAmount,
        list_price: Number(list_price),
        list_price_withGst: Number(
          Number(list_price) +
            (Number(list_price) * Number(this.state.tax_in_percentage)) / 100
        ),
        markup,
        discount_on_mrp,
      },
      () => {
        // , " : netamount")
      }
    );

    // this.props.updateTotal({});
  };

  handleQuantityChange = (e) => {
    /*if(this.state.available_stock >= e.target.value) {
            this.setState({
                quantity:e.target.value
            })
        } */
    if (e.target.value < 0) {
      this.setState({
        quantity: e.target.value * -1,
      });
    } else {
      this.setState({
        quantity: e.target.value,
      });
    }
  };
  handleMarkupChange = (e) => {
    let newValue = e.target.value;
    if (
      newValue === "" ||
      (Number(newValue) >= 0 && !isNaN(Number(newValue)))
    ) {
      this.setState({
        markup: newValue,
      });
    } else {
      alert("Negative values are not allowed.");
    }
  };
  
  handleTicketIDChange = (e) => {
    this.setState({
      TicketID: e.target.value,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: {
        //  rfq_id: this.state.main_rfq_id
        ticket_id: e.target.value,
      },
    };
    return axios(apiUrl + "/ticket-validate", requestOptions)
      .then((data) => {
        this.setState(
          {
            message: "Ticket is exist",
            is_error: false,
            is_loading: false,
            disabled: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 5000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: "Ticket is not exist",
            is_error: true,
            is_loading: false,
            disabled: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });
  };
  handleTPChange = (e) => {
    this.setState({
      TP: e.target.value,
    });
  };

  selectedOption = (e) => {
    this.setState({
      selectVendor: e.target.value,
    });
  };

  showShippingCharges = () => {
    let showShippingCharges = 0;

    if (
      this.props.subTotal < 500 &&
      !["0.0", "", null, undefined, 0].includes(this.props.productTotal)
    ) {
      showShippingCharges = this.props.productTotal;
      return showShippingCharges;
    } else if (
      this.props.subTotal > 500 &&
      !["0.0", "", null, undefined, 0].includes(this.props.productTotal)
    ) {
      showShippingCharges = this.props.productTotal;
      return showShippingCharges;
    } else {
      return 0;
    }
  };

  formatNumberWithCommas = (number) => {
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    let formattedInteger = "";

    const length = integerPart.length;
    if (length > 3) {
      const lastThree = integerPart.slice(-3);
      const otherNumbers = integerPart.slice(0, -3);
      formattedInteger =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
    } else {
      formattedInteger = integerPart;
    }

    return parts.length > 1
      ? `${formattedInteger}.${parts[1]}`
      : formattedInteger;
  };
  render() {
    return (
      <div>
        {this.props.quote_type == 2 ? <h4>Primary products</h4> : null}
        <div className="mobile-table-scroll">
          <Table striped bordered hover marginBottom margin>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Details</th>
                {/* <th>MRP</th> */}
                <th>Make</th>
                <th>System TP</th>
                <th>Sourcing TP</th>
                <th>Markup %</th>
                <th>Quantity</th>
                {/* <th>% Discount on MRP</th> */}
                <th>Unit</th>
                <th>Price (excl GST)/Unit</th>
                {/* <th>List Price(ORG)</th> */}
                <th>Net Amount</th>
                {/* <th>Net Amount(ORG)</th> */}
                {/* <th>Tax (%)</th> */}
                <th>Tax Amount</th>
                <th>Total</th>
                <th style={{ backgroundColor: "#e9ecef" }}>Gross Margin %</th>
                <th style={{ backgroundColor: "#e9ecef" }}>
                  Min Gross Margin %
                </th>
                <th>System Delivery Rate</th>
              </tr>
            </thead>
            <span />
            {this.props.getAllProductsWithDetailForList &&
              this.props.getAllProductsWithDetailForList.map((item, index) => {
                return (
                  <tbody
                    style={{
                      border:
                        Number(item.gross_margin) ===
                        Number(item.minimum_gross_margin)
                          ? "none"
                          : Number(item.gross_margin) >
                            Number(item.minimum_gross_margin)
                          ? "none"
                          : "2px solid red",
                    }}
                  >
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      {/* <td>{Number(item.mrp).toFixed(2)}</td> */}
                      <td>{item.brand}</td>
                      <td>
                        {item.special_tp > 0
                          ? "-"
                          : Number(item.transfer_price).toFixed(2)}
                      </td>
                      <td>
                        {item.special_tp == 0
                          ? "-"
                          : Number(item.special_tp).toFixed(2)}
                        {/* {this.state.tpChanged || !this.props.fromDuplicate ? item.special_tp : 0} */}
                        {/* <a>
                        {" "}
                        <FontAwesomeIcon
                          className="editIcon"
                          icon="edit"
                          onClick={() =>
                            this.openEditTpPopup(
                              item.special_tp == item.special_tp
                                ? item.special_tp
                                : item.transfer_price,
                              index,
                              item.vendor
                            )
                          }
                        />
                      </a> */}
                      </td>
                      <td>
                        <a>
                          {Number(item.markup).toFixed(2)}
                          <FontAwesomeIcon
                            className="editIcon"
                            icon="edit"
                            onClick={() =>
                              this.openEditMarkupPopup(
                                item.markup,
                                index,
                                item.special_tp
                              )
                            }
                          />
                        </a>
                      </td>
                      <td>
                        <a>
                          {item.quantity}{" "}
                          <FontAwesomeIcon
                            className="editIcon"
                            icon="edit"
                            onClick={() =>
                              this.openEditQuantityPopup(
                                item.quantity,
                                index,
                                item.available_stock,
                                item.product_id,
                                // item.vendor,
                                item.special_tp
                              )
                            }
                          />
                        </a>
                      </td>
                      {/* add discount on mrp start here */}
                      {/* <td>{Number(item.discount_on_mrp).toFixed(2)} </td> */}
                      <td>{item.quantity_type}</td>
                      {/* add discount on mrp ends here */}
                      <td>
                        <a>
                          {Number(item.list_price).toFixed(2)}
                          <FontAwesomeIcon
                            className="editIcon"
                            icon="edit"
                            onClick={() =>
                              this.openTotalPopup(
                                item.total,
                                index,
                                item.tax_in_percentage,
                                item.quantity,
                                item.markup,
                                item.list_price,
                                item.net_amount,
                                item.transfer_price,
                                item.discount_on_mrp,
                                item.mrp,
                                item.special_tp
                              )
                            }
                          />
                        </a>
                      </td>
                      {/* <td>{item.list_price_org}</td> */}
                      <td>{Number(item.net_amount).toFixed(2)}</td>
                      {/* <td>{item.net_amount_org}</td> */}
                      <td>
                        {Number(item.tax_in_amount).toFixed(2)}
                        <br />
                        {Number(item.tax_in_percentage).toFixed(0)}%
                      </td>
                      {/* <td>{Number(item.tax_in_amount).toFixed(2)}</td> */}
                      <td>
                        <a>
                          {Number(item.total).toFixed(2)}
                          {/* <FontAwesomeIcon
                          className="editIcon"
                          icon="edit"
                          onClick={() =>
                            this.openTotalPopup(
                              item.total,
                              index,
                              item.tax_in_percentage,
                              item.quantity,
                              item.markup,
                              item.list_price,
                              item.net_amount,
                              item.transfer_price
                            )
                          }
                        /> */}
                        </a>
                      </td>
                      <td style={{ backgroundColor: "#f8f9fa" }}>
                        {Number(item.gross_margin).toFixed(2)}
                      </td>
                      {/* adding new Min Gross Margin % value start here */}
                      <td style={{ backgroundColor: "#f8f9fa" }}>
                        {Number(item.minimum_gross_margin).toFixed(2)}
                      </td>
                      {/* adding new Min Gross Margin % value ends here */}
                      <td style={{ backgroundColor: "#f8f9fa" }}>
                        {Number(item.deliveryCharges).toFixed(2)}%
                        <a>
                          {" "}
                          <FontAwesomeIcon
                            icon="times-circle"
                            className="editIcon"
                            onClick={() =>
                              this.props.removeItemFromMainList(index)
                            }
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}

            <tfoot>
              {/* discount on mrp & sub total component start here */}
              <tr>
                <td colSpan="15">
                  <div className="d-flex align-items-center justify-content-end">
                    {/* <div style={{ display: "flex", alignItems: "baseline" }}>
                      <div>
                        <span className="pr-4">Sub Total..</span>
                        <span>{Number(this.props.subTotal).toFixed(2)}</span>
                      </div>
                    </div> */}
                    <div style={{ width: "230px" }}>
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <span>Total MRP</span>
                        <span>{this.formatNumberWithCommas(Number(this.props.totalMrp).toFixed(2))}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="pr-2 position-relative"><span className="position-absolute" style={{right:"100%"}}>-</span>Total Discount on MRP</span>
                        <span>{this.formatNumberWithCommas(Number(this.props.totalDiscountOnMrp).toFixed(2))}</span>
                      </div> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Total Amount before Tax:</span>
                        <span>
                          {this.formatNumberWithCommas(
                            Math.abs(Number(this.props.totalNetAmount)).toFixed(
                              2
                            )
                          )}
                        </span>
                      </div>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Tax Amount:</span>
                        <span>
                          {this.formatNumberWithCommas(
                            Number(this.props.totalTaxAmount).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              {/* discount or mrp & sub total component ends here  */}
              {/* add new shipping charges compoennt start here */}
              <tr>
                <td colSpan="15">
                  <div className="d-flex align-items-center justify-content-between">
                    <div style={{ textAlignLast: "justify" }}>
                      <p className="mb-0">
                        <b>
                          Quote Gross Margin: Rs{" "}
                          {this.formatNumberWithCommas(
                            Number(this.props.quoteGrossMargin).toFixed(2)
                          )}
                        </b>
                      </p>
                      <p className="mb-0">
                        <b>
                          Minimum Gross Margin: Rs{" "}
                          {this.formatNumberWithCommas(
                            Number(this.props.quoteMinimumGrossMargin).toFixed(
                              2
                            )
                          )}
                        </b>
                      </p>
                      {this.props.quoteGrossMargin -
                        this.props.quoteMinimumGrossMargin <=
                      0 ? (
                        <p className="mb-0 text-danger">
                          Change Required Rs:{" "}
                          {Number(
                            Math.abs(
                              this.props.quoteGrossMargin -
                                this.props.quoteMinimumGrossMargin
                            )
                          ).toFixed(2)}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <p className="mb-0">
                        <b>
                          System Delivery Charges: Rs{" "}
                          {this.formatNumberWithCommas(
                            Number(
                              Math.abs(this.props.totalSystemDeliverRate)
                            ).toFixed(2)
                          )}{" "}
                          (including 18% GST)
                        </b>
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <div>
                        <span className="pr-4 position-relative">
                          <span
                            className="position-absolute"
                            style={{ right: "100%" }}
                          >
                            +
                          </span>
                          Shipping Charges:
                        </span>
                      </div>
                      <div>
                        <div className="d-flex">
                          <Form.Group
                            controlId="updatedQuantity"
                            className="mb-0"
                          >
                            <Form.Control
                              type="number"
                              style={{
                                border:
                                Number(Number(this.props.shippingCharges).toFixed(2) ) < Number(Number(this.props.totalSystemDeliverRate).toFixed(2))
                                    ? "2px solid red"
                                    : "",
                              }}
                              defaultValue={Number(
                                this.props.shippingCharges
                              ).toFixed(2)}
                              // defaultValue={this.props.rfqShippingCharges}
                              name="shippingCharges"
                              className="shippingInput"
                              onChange={this.props.addshippingCharges}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              {/* add new shipping charges component ends here */}
              <tr>
                <td colSpan="15" align="right">
                  <div
                    style={{ width: "230px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <span className="pr-4">
                      <b>Grand Total:</b>
                    </span>
                    {/* <span><b>{this.formatNumberWithCommas(Math.abs(Number(this.props.totalNetAmount)+Number(this.props.totalTaxAmount)+Number(this.props.shippingCharges)).toFixed(2))}</b></span> */}
                    <span>
                      <b>
                        {this.formatNumberWithCommas(
                          Math.abs(Number(this.props.grandTotal)).toFixed(2)
                        )}
                      </b>
                    </span>
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
        {/* below is the secondary products  */}
        {this.props.magicProductsArray &&
        this.props.magicProductsArray.length > 0 ? (
          <>
            <h4>Secondary Products</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Details</th>
                  {/* <th>MRP</th> */}
                  <th>Make</th>
                  <th>System TP</th>
                  <th>Sourcing TP</th>
                  <th>Markup %</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  {/* <th>% Discount on MRP</th> */}
                  <th>Price (excl GST)/Unit</th>
                  {/* <th>List Price(ORG)</th> */}
                  <th>Net Amount</th>
                  {/* <th>Net Amount(ORG)</th> */}
                  {/* <th>Tax (%)</th> */}
                  <th>Tax Amount</th>
                  <th>Total</th>
                  <th style={{ backgroundColor: "#e9ecef" }}>Gross Margin %</th>
                  <th style={{ backgroundColor: "#e9ecef" }}>
                    Min Gross Margin %
                  </th>
                </tr>
              </thead>
              <span />
              {this.props.magicProductsArray &&
                this.props.magicProductsArray.map((item, index) => {
                  return (
                    <tbody
                      style={{
                        border:
                          Number(item.gross_margin) ===
                          Number(item.minimum_gross_margin)
                            ? "none"
                            : Number(item.gross_margin) >
                              Number(item.minimum_gross_margin)
                            ? "none"
                            : "2px solid red",
                      }}
                    >
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        {/* <td>{item.mrp}</td> */}
                        <td>{item.brand}</td>
                        <td>
                          {Number(item.special_tp).toFixed(2) > 0
                            ? "-"
                            : Number(item.transfer_price).toFixed(2)}
                        </td>
                        <td>
                          {Number(item.special_tp).toFixed(2) == 0
                            ? "-"
                            : Number(item.special_tp).toFixed(2)}
                          {/* {this.state.tpChanged || !this.props.fromDuplicate ? item.special_tp : 0} */}
                          {/* <a>
                            {" "}
                            <FontAwesomeIcon
                              className="editIcon"
                              icon="edit"
                              onClick={() =>
                                this.openEditTpPopup(
                                  item.special_tp == item.special_tp
                                    ? item.special_tp
                                    : item.transfer_price,
                                  index,
                                  item.vendor
                                )
                              }
                            />
                          </a> */}
                        </td>
                        <td>
                          {Number(item.markup).toFixed(2)}
                          {/* <a>
                      <FontAwesomeIcon
                        className="editIcon"
                        icon="edit"
                        onClick={() =>
                          this.openEditMarkupPopup(
                            item.markup,
                            index,
                            item.special_tp
                          )
                        }
                      />
                    </a> */}
                        </td>
                        {/* add discount on mrp start here */}
                        {/* <td>{Number(item.discount_on_mrp).toFixed(2)}  </td> */}
                        <td>{item.quantity_type}</td>
                        {/* add discount on mrp ends here */}
                        <td>{Number(item.list_price).toFixed(2)}</td>
                        {/* <td>{item.list_price_org}</td> */}
                        <td>{Number(item.quantity).toFixed(2)} </td>
                        <td>{Number(item.net_amount).toFixed(2)}</td>
                        {/* <td>{item.net_amount_org}</td> */}
                        <td>{Number(item.tax_in_percentage).toFixed(2)}</td>
                        <td>{Number(item.tax_in_amount).toFixed(2)}</td>
                        <td>{Number(item.total).toFixed(2)}</td>
                        <td style={{ backgroundColor: "#f8f9fa" }}>
                          {Number(item.gross_margin).toFixed(2)}
                          {/* <a>
                      {" "}
                      <FontAwesomeIcon
                        icon="times-circle"
                        className="editIcon"
                        onClick={() => this.props.removeItemFromMainList(index)}
                      />
                    </a> */}
                        </td>
                        {/* adding new Min Gross Margin % value start here */}
                        <td style={{ backgroundColor: "#f8f9fa" }}>
                          {Number(item.minimum_gross_margin).toFixed(2)}
                        </td>
                        <td>{Number(item.deliveryCharges).toFixed(2)}%</td>
                        {/* adding new Min Gross Margin % value ends here */}
                      </tr>
                    </tbody>
                  );
                })}
            </Table>
          </>
        ) : null}

        <Modal show={this.state.show} onHide={this.closeModal} className="business_process_wrapper">
          <Modal.Header closeButton>
            <Modal.Title>Update Quantity</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Form>
              <Form.Row>
                <Col lg={2}>
                  <Form.Group controlId="formBasicProductStock">
                    <Form.Control
                      type="text"
                      placeholder="Stock"
                      value={this.state.available_stock}
                      name="available_stock"
                      readOnly="readOnly"
                    />
                  </Form.Group>
                </Col>
                <Col lg={10}>
                  <div className="d-flex">
                    <Form.Group controlId="updatedQuantity">
                      <Form.Control
                        type="number"
                        value={this.state.quantity}
                        name="quantity"
                        onChange={this.handleQuantityChange}
                        placeholder="Quantity"
                        min={1}
                      />
                    </Form.Group>
                    {/* <input
                        style={{
                          width: "35%",
                          minHeight: "35px",
                          border: "1px solid #ced4da",
                          borderRadius: "0.25rem",
                          textAlign: "center",
                        }}
                        placeholder="Quantity"
                        defaultValue={this.state.quantity}
                        // onBlur={()=>this.props.addNewProduct({product_id : this.state.product_id, quantity:this.state.quantity})}
                        onChange={this.handleChangeQuantity2}
                    />  */}
                  </div>
                </Col>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.updateQuantity}>
              Done
            </Button>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showMarkup} onHide={this.closeModalMarkup} className="business_process_wrapper">
          <Modal.Header closeButton>
            <Modal.Title>Update Markup</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="updatedMarkup">
                  <Form.Control
                    // type="text"
                    type="number"
                    value={this.state.markup}
                    name="markup"
                    onChange={this.handleMarkupChange}
                    placeholder="Markup"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.updateMarkup}>
              Done
            </Button>
            <Button variant="secondary" onClick={this.closeModalMarkup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showTP} onHide={this.closeTPModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update TP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.message.length > 0 ? (
              <Alert
                className="displayMessageArea"
                variant={this.state.is_error ? "danger" : "success"}
              >
                {this.state.message}
              </Alert>
            ) : null}
            <Form onSubmit={this.updateTP}>
              <Form.Row style={{ display: "flex" }}>
                <Form.Label style={{ width: "15%" }}>Ticket ID</Form.Label>
                <Form.Group
                  style={{ width: "85%", marginBottom: "10px" }}
                  controlId="updatedTicket"
                >
                  <Form.Control
                    name="Ticket_id"
                    value={this.state.TicketID}
                    onChange={this.handleTicketIDChange}
                    placeholder="Ticket Id"
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ display: "flex" }}>
                <Form.Label style={{ width: "15%" }}>Vendor Name:</Form.Label>
                <Form.Group
                  controlId="updatedvendor"
                  style={{ width: "85%", marginBottom: "19px" }}
                >
                  <Form.Control
                    as="select"
                    name="selectVendor"
                    custom
                    required
                    value={this.state.selectVendor}
                    onChange={this.selectedOption}
                  >
                    <option value="">Please Select Vendor Name</option>
                    {this.state.vendorlist &&
                      this.state.vendorlist.map((item) => {
                        return (
                          <option key={item.vendor_id} value={item.vendor_id}>
                            {item.vendor_name}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select vendor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ display: "flex" }}>
                <Form.Label style={{ width: "15%" }}>TP</Form.Label>
                <Form.Group
                  style={{ width: "85%", marginBottom: "10px" }}
                  controlId="updatedMarkup"
                >
                  <Form.Control
                    type="number"
                    value={Number(this.state.TP).toFixed(2)}
                    name={this.state.TP}
                    onChange={this.handleTPChange}
                    placeholder="TP Price"
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Button
                type="submit"
                variant="primary"
                // disabled={this.state.disabled}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                style={{ marginLeft: "10px" }}
                onClick={this.closeTPModal}
              >
                Cancel
              </Button>
            </Form>
          </Modal.Body>
          {/* <Modal.Footer>                      
                    
                    </Modal.Footer> */}
        </Modal>

        <Modal show={this.state.showTotalPopup} onHide={this.closeTotalPopup} className="business_process_wrapper">
          <Modal.Header closeButton>
            <Modal.Title>Update Selling Price (excl GST)</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Form>
              {this.state.markupError ? (
                <div className="alert alert-danger">
                  {this.state.markupError}
                </div>
              ) : null}
              <Form.Row>
                <Col sm="5">
                  <Form.Label>
                    <b>Selling Price (excl. GST)</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    // value={this.state.list_price_withGst}
                    value={this.state.list_price}
                    name="total"
                    onChange={this.handleTotalChange}
                    // disabled
                    placeholder="List Price"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <Col>
                  <b>Updated Values for Following :</b>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm="2">
                  <Form.Label>
                    <b>MarkUP %</b>
                  </Form.Label>
                </Col>
                <Form.Group as={Col} controlId="updatedTotal">
                  <Form.Control
                    type="number"
                    value={Number(this.state.markup).toFixed(2)}
                    name="total"
                    disabled
                    placeholder="Markup"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Col sm="2">
                  <Form.Label>
                    <b>Discount on MRP%</b>
                  </Form.Label>
                </Col>
                <Form.Group as={Col} controlId="updatedTotal">
                  <Form.Control
                    type="number"
                    value={Number(this.state.discount_on_mrp).toFixed(2)}
                    name="total"
                    disabled
                    placeholder="Discount on MRP"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Form.Group>
              </Form.Row>
              {/* <Form.Row>
                <Col sm="2">
                  <b>List Price..</b>
                </Col>
                <Form.Group as={Col} controlId="updatedTotal">
                  <Form.Control
                    type="number"
                    value={this.state.list_price}
                    name="total"
                    disabled
                    placeholder="List Price"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Form.Group>
              </Form.Row> */}
              {/* <Form.Row>
                <Col sm="2">
                  <b>Net Amount</b>
                </Col>
                <Form.Group as={Col} controlId="updatedTotal">
                  <Form.Control
                    type="number"
                    value={this.state.netAmount}
                    name="total"
                    disabled
                    placeholder="Net Amount"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Form.Group>
              </Form.Row> */}
              <Form.Row style={{ marginBottom: "2rem" }}>
                <Col sm="2">
                  <Form.Label>
                    <b>Quantity</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={Number(this.state.quantity).toFixed(2)}
                    name="quantity"
                    disabled
                    // onChange={this.handleTotalQuantityChange}
                    placeholder="Qty"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Col>
                <Col sm="1">
                  <b>X</b>
                </Col>
                <Col sm="4">
                  <Form.Label>
                    <b>SP (with GST)</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={Number(this.state.list_price_withGst).toFixed(2)}
                    name="total"
                    onChange={this.handleTotalChange}
                    disabled
                    placeholder="List Price"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Col>
                <Col sm="1">
                  <b>=</b>
                </Col>
                <Col sm="4">
                  <Form.Label>
                    <b>Total Value</b>
                  </Form.Label>

                  <Form.Control
                    type="number"
                    value={
                      Number(this.state.quantity).toFixed(2) *
                      Number(this.state.list_price_withGst).toFixed(2)
                    }
                    // value={this.state.total}
                    name="total"
                    disabled
                    placeholder="Total Value"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Col>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.updateTotal}
              disabled={this.state.isMarkUpZero}
            >
              Done
            </Button>
            <Button variant="secondary" onClick={this.closeTotalPopup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ProductLists;
