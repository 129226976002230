import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import crossBlack from "../../../asset/Images/crossBlack.svg";

import SearchResultRow from "./SearchResultRow";
import NoProductFound from "./NoProductFound";
import { Spinner } from "react-bootstrap";
import Pagination from "../SourcingRequest/Pagination";

const AdvanceSearchResultTable = (props) => {
  const {
    showAdvanceSearchResultModal,
    singleSearchFormData,
    submitLoadingSingleSearch,
    multipleSearchFormData,
    submitLoadingMultipleSearch,
    handleCloseAdvanceSearchResultModal,
    handleOpenAdvanceSearchModal,
    handleOpenCancelSRModal,
    handleSearchMultipleProduct,
    setFormData,
    stateData,
    setStateData,
    setMultipleSearchValue,
    searchData,
    setSearchData,
    searchedProducts,
    setSearchedProducts,
    currentIndex,
    setCurrentIndex,
    addSelectedProduct,
    setRowsMultipleSearch,
    rowsMultipleSearch,
    fetchAndAddToSr,
    checkSkipToSrData
  } = props;

  const [currentProduct, setCurrentProduct] = useState({
    product: "",
    category: "",
    brand: "",
    active: false,
    index: 0,
  });

  useEffect(() => {
    let obj = searchData.filter(({ active }) => active);
    if (obj && obj.length) {
      setCurrentProduct(obj[0]);
    }
  }, [searchData]);

  const heading = (
    <>
      Uh-oh! <br /> Looks like we couldn't find any products for
    </>
  );

  const handleBack = (e) => {
    let current = {};
    if (stateData && stateData.length > 1) {
      current = stateData[stateData.length - 2];
    }
    if (
      current &&
      Object.keys(current).length > 0 &&
      current.type === "multiple"
    ) {
      let updatedSearchData = searchData.map((prod, index) => {
        if (index === current.index) {
          return {
            ...prod,
            active: true,
          };
        } else {
          return {
            ...prod,
            active: false,
          };
        }
      });
      let data = stateData.filter((_, index) => index !== stateData.length - 1);
      let updatedIndex = stateData.length - 1;
      if (
        searchedProducts[updatedIndex - 1] &&
        searchedProducts[updatedIndex - 1].length === 0
      ) {
        handleSearchMultipleProduct(e, "fixed", updatedIndex);
      } else {
        setCurrentIndex(updatedIndex - 1);
        setStateData(data);
        setSearchData(updatedSearchData);
      }
    } else {
      current = stateData[0];
      setStateData([]);
      setSearchData([]);
      setRowsMultipleSearch([]);
      setSearchedProducts({})
      if (current && current.type === "single") {
        setMultipleSearchValue("");
      } else if (current && current.type === "multiple") {
        setFormData({ product: "", category: "", brand: "" });
      }
      handleCloseAdvanceSearchResultModal();
      handleOpenAdvanceSearchModal();
    }
  };
  return (
    <>
      <Modal show={showAdvanceSearchResultModal} className="advanceSearchModal">
        <Modal.Header className="advanceSearchHeader multipleSearchHeaderCnt">
          <div className="multipleSearchHeader">
            <Modal.Title>
              <span>Search results</span>
              <span className="multipleProductCount">
                {searchData.length > 1 ?`(${Number(currentIndex)+1}/${Number(rowsMultipleSearch.length)})` : null}
              </span>
            </Modal.Title>
            <button
              className="searchModalCloseBtn"
              onClick={handleOpenCancelSRModal}
            >
              <img
                src={crossBlack}
                alt="cross icon"
                width="16px"
                height="16px"
              />
            </button>
          </div>
          {currentProduct.product ? (
            <p className="multipleSearchSubContent">{currentProduct.product}</p>
          ) : null}
          <div className="searchResultDetailCnt">
            {currentProduct.brand ? (
              <div className="searchResultDetailWrapper">
                <p className="searchResultDetailItem">Brand&nbsp;:&nbsp;</p>
                <p className="searchResultDetailItem">{currentProduct.brand}</p>
              </div>
            ) : null}
            {currentProduct.category ? (
              <div className="searchResultDetailWrapper">
                <p className="searchResultDetailItem">Category&nbsp;:&nbsp;</p>
                <p className="searchResultDetailItem">
                  {currentProduct.category}
                </p>
              </div>
            ) : null}
          </div>
          <hr className="header-seprator" />
        </Modal.Header>
        <Modal.Body>
          {showAdvanceSearchResultModal ? (
            submitLoadingSingleSearch || submitLoadingMultipleSearch ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : singleSearchFormData?.length > 0 ? (
              <div className="multipleResultStatusCnt">
                <table className="searchResulttable">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Product</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <SearchResultRow
                      type={1}
                      singleSearchFormData={singleSearchFormData}
                      searchedProducts={searchedProducts}
                      setSearchedProducts={setSearchedProducts}
                      addSelectedProduct={addSelectedProduct}
                    />
                  </tbody>
                </table>
              </div>
            ) : searchedProducts[currentIndex]?.length > 0 ? (
              <div className="multipleResultStatusCnt">
                <table className="searchResulttable">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Product</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <SearchResultRow
                      type={2}
                      currentIndex={currentIndex}
                      multipleSearchFormData={multipleSearchFormData}
                      searchedProducts={searchedProducts}
                      setSearchedProducts={setSearchedProducts}
                      addSelectedProduct={addSelectedProduct}
                    />
                  </tbody>
                </table>
              </div>
            ) : (
              <NoProductFound
                heading={heading}
                subHeading={
                  currentProduct.product ? currentProduct.product : null
                }
              />
            )
          ) : null}
        </Modal.Body>

        <Modal.Footer className="searchResultFooterModal">
          <div
            className={`searchResultFooterCnt ${
              searchData && searchData.length > 1 ? "" : "justify-content-end"
            }`}
          >
            <div className="paginationSrCnt">
              {searchData && searchData.length > 1 ? (
              <>
                <Pagination
                  searchData={searchData}
                  setSearchData={setSearchData}
                  handleSearchMultipleProduct={handleSearchMultipleProduct}
                />
              </>
              ) : null}
              {searchData && searchData.length > 1 && currentIndex === 0 && 
              <button type="button" onClick={fetchAndAddToSr}>Skip to SR</button>
              }
            </div>
            <div className="searchResultFooterBtnCnt">
              <button
                className="footerbackBtn"
                type="button"
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className="footerSearchbtn"
                type="button"
                onClick={(e) => handleSearchMultipleProduct(e, "next")}
              >
                {submitLoadingMultipleSearch ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdvanceSearchResultTable;
