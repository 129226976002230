import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import { Modal, Button, Spinner, Form, Col, Alert } from "react-bootstrap";
import Constants from "../../../Config";

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;
const reload = () => window.location.reload();
class TicketRaiseListQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      rfq_id: 0,
      ticket_type: [],
      remarks: "",
      value: [],
      is_loading: false,
      is_error: false,
      message: "",
      product_data: [],
      activeState1: true,
      activeState2: true,
      activeState3: true,
    };
  }
  closeModal = () => {
    this.props.closeModal2();
  };

  handleChecbox = (e) => {
    // if(e.target.id==="Price")
    this.setState({
      activeState1: false,
      activeState3: false,
    });
    const { ticket_type } = this.state;

    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });

      if (filteredArray.length === 0) {
        this.setState({
          activeState1: true,
          activeState3: true,
        });
      }
    }
  };

  handleChecbox1 = (e) => {
    this.setState({
      activeState2: false,
      activeState3: false,
    });
    const { ticket_type } = this.state;

    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });

      if (filteredArray.length === 0) {
        this.setState({
          activeState2: true,
          activeState3: true,
        });
      }
    }
  };

  handleChecbox2 = (e) => {
    // if(e.target.id==="Price")
    this.setState({
      activeState1: false,
      activeState2: false,
    });
    const { ticket_type } = this.state;

    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });

      if (filteredArray.length === 0) {
        this.setState({
          activeState2: true,
          activeState1: true,
        });
      }
    }
  };

  handleRemarks = () => {};

  componentDidMount() {
    this.getQuoteDetail();
  }

  getQuoteDetail = () => {
    if (this.props.requestQuoteID > 0) {
      this.setState(
        {
          is_loading: true,
        },
        () => {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getUserDetails?.token}`,
            },

            data: { request_quote_id: this.props.requestQuoteID,
              logined_user_id: getUserDetails.user_id  },
          };
          return axios(apiUrl + "/get-quote-request", options)
            .then((data) => {
              let requestQuoteData = data.data.data[0];

              this.setState({
                rfq_id: requestQuoteData.id,
                is_loading: false,
                product_data: requestQuoteData.product,
              });
            })
            .catch((error) => console.log(error));
        }
      );
    }
  };

  handleSubmit = (e) => {
    const ticket_remarks = e.target.remarks.value;
    e.preventDefault();
    this.setState({
      is_loading: true,
    });
    const { rfq_id, ticket_type } = this.state;
    axios
      .post(
        apiUrl + "/create-ticket",
        {
          login_id: JSON.parse(localStorage.getItem("userData")).user_id,
          rfq_id: rfq_id,
          ticket_type: ticket_type,
          ticket_remarks: ticket_remarks,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            message: "Ticket Created Successfully!",
            is_error: false,
            is_loading: false,
            showModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });
  };

  render() {
    var productData;
    return (
      <>
        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          onExit={reload}
          size="md"
          modal-dialog-centered
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Body>
                {this.state.message.length > 0 ? (
                  <Alert
                    className="displayMessageArea"
                    variant={this.state.is_error ? "danger" : "success"}
                  >
                    {this.state.message}
                  </Alert>
                ) : null}
                <Form.Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="price"
                        value="Price"
                        disabled={!this.state.activeState1}
                        onClick={this.handleChecbox1}
                      />
                      <Form.Label className="ml-2" for="price">
                        Price
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="stock"
                        value="Stock"
                        disabled={!this.state.activeState1}
                        onClick={this.handleChecbox1}
                      />
                      <Form.Label className="ml-2" for="stock">
                        Stock
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="techquery"
                        value="Tech Query"
                        disabled={!this.state.activeState1}
                        onClick={this.handleChecbox1}
                      />
                      <Form.Label className="ml-2" for="techquery">
                        Tech Query
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="UpdateTP"
                        value="Update TP"
                        disabled={!this.state.activeState2}
                        onClick={this.handleChecbox}
                        check=""
                      />
                      <Form.Label className="ml-2" for="price">
                        Update TP
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col></Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="UpdateStock"
                        value="Update Stock"
                        disabled={!this.state.activeState3}
                        onClick={this.handleChecbox2}
                        check=""
                      />
                      <Form.Label className="ml-2" for="price">
                        Update Stock
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Form.Label>Remarks</Form.Label>
                </Form.Row>
                <Form.Row>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="2"
                    name="remarks"
                    defaultValue={this.state.product_data.map((data, ind) => {
                      return (
                        "Name:" +
                        " " +
                        data.productName +
                        " || " +
                        "Model:" +
                        " " +
                        data.model +
                        " || " +
                        "Quantity:" +
                        " " +
                        data.productQuantity
                      );
                    })}
                  ></textarea>
                </Form.Row>
              </Modal.Body>
            </Modal.Header>
            <Modal.Footer>
              {/* <Button variant="primary" type="submit" disabled={this.state.is_loading ? true:false}>
                                    {
                                        this.state.is_loading ? 
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </Spinner> : 'Create Ticket'
                                    }</Button> */}
              <Button
                variant="primary"
                type="submit"
                disabled={this.state.is_loading ? true : false}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
export default TicketRaiseListQuote;
