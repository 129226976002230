import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import ContactDetailsDialog from "./contactDetailsPopup";
import {
  Table,
  Modal,
  Button,
  Alert,
  Spinner,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import Constants from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import Select, { components } from "react-select";
// import paginationFactory from "react-bootstrap-table2-paginator";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "./style.css";
import Pagination from "../Common/Pagination";
import ApprovalProcess from "./ApprovalProcess";
import { CSVLink, CSVDownload } from "react-csv";
import Daterangepicker from "../Common/Daterangepicker";
import ContactsIcon from '@mui/icons-material/Contacts';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DateRangeNew from "../../DashBoardWidgets/NewWidgets.js/DateRangeNew";
const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

// const columns = [
//   {
//     dataField: "id",
//     text: "Customer Id",
//     sort: true,
//   },
//   {
//     dataField: "company_name",
//     text: "Company Name",
//     sort: true,
//   },
//   {
//     dataField: "business_group",
//     text: "Group",
//     sort: true,
//   },
//   {
//     dataField: "business_type",
//     text: "Type",
//     sort: true,
//   },
//   {
//     dataField: "username",
//     text: "Name",
//     sort: true,
//   },
//   {
//     dataField: "wholesale_pricing_status",
//     text: "Wholesale Pricing Status",
//   },
//   {
//     dataField: "created_at",
//     text: "Request Date",
//     sort: true,
//   },
//   // total_order>0 ? showEntries(): null
//   {
//     dataField: "new_entries",
//     text: "Order Summary",
//     // formatter: {total_order}
//     formatter: (cell, row) => (
//       <span>
//         {row.total_order > 0 ? (
//           <>
//             Last Order date:{" "}
//             <b> {row.last_order_date ? row.last_order_date : "NA"}</b> <br />
//             Order count: <b>{row.total_order} </b> <br />
//             Order value: <b>Rs.{row.total_order_value} </b>
//           </>
//         ) : (
//           "-"
//         )}
//       </span>
//     ),
//     // sortFunc : () => {

//     // }
//     onSort: (field, order) => {
//       console.log(field, order);
//       // this.setState(
//       //   {
//       //     sortOnLastDate: "date",
//       //     sort_by: order,
//       //   },
//       //   () => {
//       //     this.getMyAccount();
//       //   }
//       //   );
//     },
//     sort: true,
//     sortValue: (cell, row) => row.last_order_date,
//   },

//   {
//     dataField: "agent_name",
//     text: "Agent Name",
//     sort: true,
//   },
//   {
//     dataField: "action",
//     text: "Action",
//   },
// ];

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  const [open, setOpen] = useState(false);
  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} onChange={() => { }} />{" "}
      {children}
    </components.Option>
  );
};

const headers = [
  { label: "Customer ID", key: "id" },
  { label: "Name", key: "username" },
  { label: "Contacts", key: "contacts" },
  { label: "Email", key: "useremail" },
  { label: "Phone", key: "userphone" },
  { label: "Company Name", key: "company_name" },
  { label: "Gstin", key: "gstin" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Business Group", key: "business_group" },
  { label: "Business Type: ", key: "business_type" },
  // add sub_business_type key
  { label: "Sub Business Type: ", key: "sub_business_type" },
  { label: "Agent Name", key: "agent_name" },
  { label: "Current Status: ", key: "current_status" },
  { label: "pin_code", key: "pin_code" },
  { label: "Slug", key: "slug" },
  { label: "Registration Date", key: "created_at" },
  { label: "Address", key: "address" },
  { label: "Total Order Count", key: "total_order" },
  { label: "Total Order value", key: "total_order_value" },
  { label: "Last Order Date", key: "last_order_date" },
];

const orderOptions = [
  { label: "Transacted", value: "1" },
  { label: "Non Transacted", value: "2" },
  { label: "Lapsed", value: "3" },
];

class MyAccount extends React.Component {
  columns = [
    {
      dataField: "id",
      text: "Customer Id",
      sort: true,
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "business_group",
      text: "Group",
      sort: true,
    },
    {
      dataField: "business_type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "username",
      text: "Name",
      sort: true,
    },
    {
      dataField: "contacts",
      text: "Contacts",
    },
    {
      dataField: "wholesale_pricing_status",
      text: "Wholesale Pricing Status",
    },
    // add new datafields start here
    // { label: "Years in Business", key: "yrs_in_business" },
    // { label:"Primary Contact", key:"is_account_primary" },

    // SHOW IN UI FIELDS ONLY
    {
      dataField: "is_account_primary",
      text: "Primary Contact",
    },
    // add new datafields ends here
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
    },
    // total_order>0 ? showEntries(): null
    {
      dataField: "new_entries",
      text: "Order Summary",
      // formatter: {total_order}
      formatter: (cell, row) => (
        <span>
          {row.total_order > 0 ? (
            <>
              Last Order date:{" "}
              <b> {row.last_order_date ? row.last_order_date : "NA"}</b> <br />
              Order count: <b>{row.total_order} </b> <br />
              Order value: <b>Rs.{row.total_order_value} </b>
            </>
          ) : (
            "-"
          )}
        </span>
      ),
      // sortFunc : () => {

      // }
      onSort: (field, order) => {
        this.changeOrder(order);
        // this.setState(
        //   {
        //     sortOnLastDate: "date",
        //     sort_by: order,
        //   },
        //   () => {
        //     this.getMyAccount();
        //   }
        //   );
      },
      sort: true,
      sortValue: (cell, row) => row.last_order_date,
    },

    {
      dataField: "agent_name",
      text: "Agent Name",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
  }

  state = {
    BusinessAccountsList: [],
    BusinessAccountsListForDownload: [],
    show: false,
    businessData: [],
    isLoading: false,
    total_item: 25,
    current_page: 1,
    total_record: 0,
    isPaginationLoading: 0,
    mobilenumber: "",
    company_name: "",
    status: "",
    contacts: '',
    agentArray: [],
    checkBoxList: [],
    allSelectInit: false,
    selecteTUserID: "",
    is_super: false,
    date_from: "",
    date_to: "",
    transactionStatus: [],
    selectRow: {},
    sortOnLastDate: "",
    sort_by: "",
    selectedOptions: [],
    setSelectedOptions: [],
    checkOnOrderOptionForClass: false,
    allowedSlugs: [],
    agentOptions: [],
    logined_user_id: [],
    setOpen: false
  };
  componentDidMount(props) {
    let is_super = JSON.parse(localStorage.getItem("userData"));
    this.setState({
      is_super: is_super.is_super,
      allowedSlugs: this.props.childSlugProp["my-accounts"],
    });

    this.getMyAccount();
    this.getCount();
    this.getAllQuoteUsers();
    this.getAgents();

    this.setState({
      selectRow: {
        mode: "checkbox", // single row selection
        onSelect: (row, isSelect, rowIndex, e) => {
          if (isSelect) {
            let checkBoxList = this.state.checkBoxList;
            checkBoxList.push(row.assignId);
            this.setState({ checkBoxList });
          } else {
            let checkBoxList = this.state.checkBoxList.filter(
              (id) => row.assignId != id
            );
            this.setState({ checkBoxList });
          }
        },
        onSelectAll: (isSelect, rows, e) => {
          if (isSelect) {
            let checkBoxList = this.state.checkBoxList;
            rows.map((item) => {
              checkBoxList.push(item.assignId);
            });
            this.setState({ checkBoxList });
          } else {
            this.setState({
              checkBoxList: [],
            });
          }
        },
      },
      setOpen: false
    });
  }


  handleOpen = () => {
    this.setState({
      setOpen: true
    })
  };

  handleClose = () => {
    this.setState({
      setOpen: false
    })


  };
  // Popup code start
  openViewDetailPopup = (businessID) => {
    this.getBusinessAccountDetail(businessID);
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  changeOrder = (order) => {
    this.setState(
      {
        sortOnLastDate: "date",
        sort_by: this.state.sort_by == "desc" ? "asc" : "desc",
      },
      () => {
        this.getMyAccount();
      }
    );
  };

  getBusinessAccountDetail = (businessID) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: { business_account_id: `${businessID}` },
    };
    return axios(apiUrl + "/business-account", options)
      .then((data) => {
        let businessData = data.data.data[0];
        this.setState({
          businessData: businessData,
          show: true,
        });
      })
      .catch((error) => console.log(error));
  };

  //popup code end

  getMyAccount = () => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },
          data: {
            // agent_id: this.props.getUserDetails.user_id,
            agent_id: this.state.logined_user_id.length
              ? this.state.logined_user_id
              : [],
            current_page: this.state.current_page,
            total_item: this.state.total_item,
            status: this.state.status,
            phone: this.state.mobilenumber,
            company_name: this.state.company_name,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            transactionStatus: this.state.transactionStatus,
            sort: this.state.sortOnLastDate,
            sort_by: this.state.sort_by,
          },
        };
        return axios(apiUrl + "/my-account", options)
          .then((data) => {
            this.setState(
              {
                BusinessAccountsList: data.data.data,
                isLoading: false,
              },
              () => {
                const dataNew = this.state.BusinessAccountsList.map(
                  (item, index) => {
                    const nameCapitalized =
                      item.business_group?.charAt(0).toUpperCase() +
                      item.business_group?.slice(1);
                    const contacts = <div>
                      <ContactDetailsDialog contactDetails={item.contactDetails} />
                    </div>
                    return {
                      id: item.customer_id,
                      assignId: item.id,
                      company_name: item.company_name,
                      business_group: nameCapitalized,
                      username: item.username,
                      contacts: contacts,
                      address: item.address,
                      wholesale_pricing_status: item.current_status,
                      business_type: item.business_type,
                      created_at: item.created_at,
                      agent_name: item.agent_name,
                      last_order_date: item.last_order_date,
                      total_order_value: Number(item.total_order_value),
                      total_order: item.total_order,
                      transactionStatus: item.transactionStatus,

                      action: (
                        <div style={{ width: "70px" }}>
                          <Button
                            variant="light"
                            type="button"
                            onClick={() => this.openViewDetailPopup(item.id)}
                            style={{
                              fontSize: 12,
                              border: "1px solid #cccccc",
                            }}
                          >
                            <FontAwesomeIcon icon="edit" /> View
                          </Button>
                        </div>
                      ),
                    };
                  }
                );
                this.setState({
                  BusinessAccountsList: dataNew,
                  // total_record:dataNew.length,
                  isPaginationLoading: 0,
                });
              }
            );
            // this.paginationLoading()
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };

  getCount = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },
          data: {
            agent_id: this.state.logined_user_id.length
              ? this.state.logined_user_id
              : [],
            key: "my-accounts",
            status: this.state.status,
            mobile: this.state.mobilenumber,
            company_name: this.state.company_name,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            transactionStatus: this.state.transactionStatus,
          },
        };
        return axios(apiUrl + "/get-accounts-count", options)
          .then((data) => {
            this.setState({
              total_record: data.data.data.count,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };

  getPaginationValue = (currentPage, displayItems) => {
    this.setState(
      {
        current_page: currentPage,
        total_item: displayItems,
      },
      () => {
        this.getMyAccount();
      }
    );
  };
  paginationLoading = () => {
    this.setState({
      isPaginationLoading: 1,
    });
  };
  getAgents = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: {
        agent_list: "true",
        id: [56],
        showAll: "true",
        slug: "my-accounts",
      },

      // data: { logined_user_id: this.props.getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agents: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  // agent list api

  getAllQuoteUsers = () => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: {
        agent_list: "",
        id: [54, 56],
        showAll: "",
        slug: "my-accounts",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState(
          {
            agentArray: data.data.data,
          },
          () => {
            let agentOptions1 = data.data.data;
            let agentOptions = agentOptions1.map((item) => {
              if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
                item["label"] = item["name"];
                item["value"] = item["id"];
                delete item["name"];
                delete item["id"];
                return item;
              }
            });

            this.setState({ agentOptions });
          }
        );
      })
      .catch((error) => console.log(error));
  };

  filterByAgent = (e) => {
    this.setState(
      {
        logined_user_id: e,
        current_page: 1,
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getMyAccount();
      }
    );
  };

  // filter code start
  mobileFilter = (e) => {
    this.setState({
      mobilenumber: e,
      current_page: 1,
    });
    this.getCount();
    this.paginationLoading();
    this.getMyAccount();
  };
  debounce = (cb, delay = 500) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  companyFilter = (e) => {
    this.setState(
      {
        company_name: e,
        current_page: 1,
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getMyAccount();
        // this.businessAccountsListForDownload()
      }
    );
  };
  updateCompany = this.debounce(this.companyFilter);
  getCompany = (e) => {
    this.updateCompany(e.target.value);
  };
  updateMobile = this.debounce(this.mobileFilter);
  getMobile = (e) => {
    this.updateMobile(e.target.value);
  };

  filterByStatus = (e) => {
    this.setState(
      {
        status: e.target.value,
        current_page: 1,
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getMyAccount();
      }
    );
  };
  filterByTransactStatus = (e) => {
    this.setState(
      {
        transactionStatus: e,
        current_page: 1,
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getMyAccount();
      }
    );
  };
  convertQuoteToOther = (e) => {
    const { checkBoxList } = this.state;

    this.setState(
      {
        selecteTUserID: e.target.value,
        current_page: 1,
      },
      () => {
        let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const options = {
          method: "POST",
          data: {
            agent_id: this.state.selecteTUserID,

            businessID: checkBoxList,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },
        };
        return axios(apiUrl + "/assign-business-accounts", options)
          .then((data) => {
            this.paginationLoading();
            this.getMyAccount();
            //this.exportsAllQuote();
            this.setState({
              checkBoxList: [],
              selecteTUserID: "",
            });
          })
          .catch((error) => console.log(error));
      }
    );
  };
  // filter codoe end

  // Download list code
  businessAccountsListForDownload = () => {
    return new Promise((resolve, reject) => {
      // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails?.token}`,
            },
            data: {
              agent_id: this.state.logined_user_id.length
                ? this.state.logined_user_id
                : [],
              status: this.state.status,
              phone: this.state.mobilenumber,
              company_name: this.state.company_name,
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              transactionStatus: this.state.transactionStatus,
            },
          };
          return axios(apiUrl + "/export-my-accounts", requestoption)
            .then((data) => {
              this.setState(
                {
                  BusinessAccountsListForDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.BusinessAccountsListForDownload.map(
                    (item, index) => {
                      const primaryContact = item.contactDetails.find(
                        (contact) => contact.is_primary === 1
                      );

                      const secondaryContacts = item.contactDetails.filter(
                        (contact) => contact.is_primary === 0
                      );

                      // Initialize secondary contact details as an empty string
                      let secondaryContactsStr = "";

                      // Check if there are secondary contacts
                      if (secondaryContacts.length > 0) {
                        // Concatenate secondary contact details with spaces
                        secondaryContactsStr = secondaryContacts
                          .map((secondaryContact) => {
                            const { name, email, phone } = secondaryContact;
                            return `${name || ""} ${email || ""} ${phone || ""}`.trim();
                          })
                          .join(" || ");
                      }
                      return {
                        "Customer ID": item.customer_id,
                        Address: item.address,
                        "Business Group": item.business_group,
                        "Business Type": item.business_type,
                        // add sub_business_type
                        "Sub Business Type": item.sub_business_type,
                        Phone: item.userphone,
                        "Company Name": item.company_name,
                        "Wholesale Pricing Status": item.current_status,
                        "Years in Business": item.yrs_in_business,
                        "Employee Count": item.employee_count,
                        "Annual Revenue (in lacs)": item.turnover,
                        "Credit Limit": item.credit_limit,
                        "Credit Period": item.credit_period,
                        "Projects Done": item.projects_done,
                        "Primary Contact": item.is_account_primary,
                        City: item.city,
                        State: item.state,
                        Gstin: item.gstin,
                        "pin code": item.pin_code,
                        Slug: item.slug,
                        Email: item.useremail,
                        Name: item.username,
                        "Secondary Contacts": secondaryContactsStr,
                        "Registration Date": item.created_at,
                        "Agent Name": item.agent_name,
                        "Customer Order Status": item.transactionStatus,
                        "Total Order Count": item.total_order,
                        "Last Order Date": item.last_order_date,
                        "Total Order value": item.total_order_value,
                      };
                    }
                  );

                  resolve(dataNew);
                  this.setState({
                    BusinessAccountsListForDownload: dataNew,
                  });
                }
              );
            })

            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });

        }
      );
    });
  };
  // end code

  // download report code
  downloadReport = async () => {
    let result;
    result = await this.businessAccountsListForDownload();
    if (result.length >= 0) {
      this.setState({ BusinessAccountsListForDownload: result }, () => {
        setTimeout(() => {
          this.getExcel();
          // this.csvLinkEl.current.click();
        });
      });
    }
  };
  getExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      this.state.BusinessAccountsListForDownload
      // { origin: "A2", skipHeader: true }
    );
    // XLSX.utils.sheet_add_aoa(ws, [heading], { origin: "A1" });
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "myFile2");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(wb, "my-accounts.xls");
  };
  // ONSELECT IS NOT BEONG CALLED AS HTML TABLE IS NOT USED

  // onSelect = (isSelect, row) => {
  //   const { checkBoxList } = this.state;
  //   if (checkBoxList.length > 0) {
  //     let flag = false;
  //     const updateList = [];
  //     checkBoxList.forEach((item) => {
  //       if (item.id === row) {
  //         flag = true;
  //       } else {
  //         updateList.push(item);
  //       }
  //     });
  //     if (flag === false) {
  //       updateList.push({ id: row });
  //     }
  //     this.setState({ checkBoxList: updateList });
  //   } else {
  //     this.getAllQuoteUsers();
  //     this.setState({
  //       checkBoxList: [
  //         {
  //           id: row,
  //         },
  //       ],
  //     });
  //   }
  // };
  allSelect = () => {
    const { allSelectInit } = this.state;
    if (allSelectInit) {
      this.setState({ allSelectInit: false, checkBoxList: [] });
    } else {
      const updateList = [];
      this.state.BusinessAccountsList.map((item, index) => {
        updateList.push({
          id: item.assignId,
        });
      });
      this.setState({ allSelectInit: true, checkBoxList: updateList });
    }
  };
  //

  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
          current_page: 1,
        },
        () => {
          this.getCount();
          this.paginationLoading();
          this.getMyAccount();
        }
      );
    };
    const {
      checkBoxList,
      allSelectInit,
      downloadAttachment,
      setOpen,
      open,
      options,
    } = this.state;
    return (
      <div className="card table-card">
        <div className="headingPadding">
          <h3 className="proAccountHeader mt-3">
            {this.state.allowedSlugs.includes("assignment") &&
              this.state.checkBoxList.length > 0 ? (
              <Form.Control
                as="select"
                custom
                defaultValue={this.state.selecteTUserID}
                onChange={this.convertQuoteToOther}
              >
                <option value="">Assign to ...</option>
                {this.state.agents.map((item, index) => {
                  return item.id ? (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ) : null;
                })}
              </Form.Control>
            ) : (
              <span>My Account</span>
            )}
          </h3>
          <Row className="account_wrapper">
            <Col md="3" className="mt-3 ">
              <div className="customFilterCnt">
                <div className="customFilter">
                  {/* <Daterangepicker setDateRange={setDateRange}></Daterangepicker> */}
                  <DateRangeNew
                    label={"ALL DATA"}
                    // allData={true}
                    setDateRange={setDateRange}
                  ></DateRangeNew>
                </div>
              </div>
            </Col>
            <Col md="3" className="mt-3">
              <Form.Control as="select"  className="listingFontSizeColor" onChange={this.filterByStatus}>
                <option value="">Status</option>
                <option value="Verified PRO">Verified PRO</option>
                <option value="Rejected">Rejected</option>
                <option value="Verified Other B2B">Verified Other B2B</option>
              </Form.Control>
            </Col>
            <Col md="3" className="mt-3">
              <Select
                defaultValue={[]}
                isMulti
                name="orderType"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                options={orderOptions}
                className="basic-multi-select listingFontSizeColor"
                classNamePrefix="select"
                placeholder="Order Status"
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    // this.setState(options?.map((opt) => opt.value));

                    this.setState(
                      { transactionStatus: options?.map((opt) => opt.value) },
                      () => {
                        this.filterByTransactStatus(this.state.transactionStatus);
                      }
                    );
                  } else {
                    this.filterByTransactStatus([]);
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </Col>
            <Col md="3" className="mt-3 ">
              <InputGroup className="mb-0">
                <FormControl
                  className="listingFontSizeColor"
                  placeholder="Mobile"
                  onChange={(data) => this.getMobile(data)}
                />
              </InputGroup>
            </Col>
            {this.state.agentOptions && this.state.agentOptions.length > 1 ? (
              <Col md="3" className="mt-3">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="orderType"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  controlShouldRenderValue={false}
                  options={this.state.agentOptions}
                  className="basic-multi-select listingFontSizeColor"
                  classNamePrefix="select"
                  placeholder="FIlter Agents"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      // this.setState(options?.map((opt) => opt.value));

                      this.setState(
                        { logined_user_id: options?.map((opt) => opt.value) },
                        () => {
                          this.filterByAgent(this.state.logined_user_id);
                        }
                      );
                    } else {
                      this.filterByAgent([]);
                    }
                  }}
                  components={{
                    Option: InputOption,
                  }}
                />
              </Col>
            ) : null}
            <Col md="3" className="mt-3">
              <InputGroup className="mb-0">
                <FormControl
                  className="listingFontSizeColor"
                  placeholder="Company"
                  onChange={this.getCompany}
                />
              </InputGroup>
            </Col>
            {/* <Col>
              <CSVLink data={this.state.BusinessAccountsListForDownload} headers={headers} filename={"business-accounts.csv"} className="btn btn-primary downLoadButton" >
                {
                  this.state.isDownloading
                    ?
                    <Spinner animation="border" />
                    :
                    <span>Export Data</span>
                }
              </CSVLink>
            </Col> */}
            {this.state.allowedSlugs.includes("download") ? (
              <Col md="3" className="mt-3 " >
                <Link
                  headers={headers}
                  className="hidden"
                  filename={"my-accounts.xls "}
                  data={this.state.BusinessAccountsListForDownload}
                  ref={this.csvLinkEl}
                ></Link>

                <span
                  className="listingBtn"
                  onClick={this.downloadReport}
                // disabled={this.state.isDownloading}
                >
                  {this.state.isDownloading ? (
                    <Spinner animation="border" />
                  ) : (
                    "Export Data"
                  )}
                </span>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="card-block">
          {this.state.isLoading ? (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Spinner animation="border" />
            </div>
          ) : (
          <div className="mobile-table-scroll">
            <BootstrapTable
              keyField="id"
              data={this.state.BusinessAccountsList}
              columns={this.columns}
              selectRow={this.state.selectRow}
              striped
              hover
              bordered={false}
              bootstrap4
              noDataIndication="You have not assigned any company"
            />
            </div>
          )}
          {/* Modal will open code */}
          <Modal
            className="business_process_wrapper"
            show={this.state.show}
            onHide={this.closeModal}
            dialogClassName="modal-90w"
            centered
            aria-labelledby="example-custom-modal-styling-title test1"
          >
            <Modal.Header
              closeButton
              style={{ padding: "0px 5px", border: "none" }}
            >
              <h5 className="on_call_title">On Call Information</h5>
            </Modal.Header>
            <Modal.Body className="business_approve_process">
              <ApprovalProcess
                businessData={this.state.businessData}
                closeModal={this.closeModal}
                isFromProPricing={false}
                getUserDetails={this.props.getUserDetails}
              />
            </Modal.Body>
          </Modal>
          {/* code end */}

          <Pagination
            current_page={this.state.current_page}
            total_item={this.state.total_record}
            getPaginationValue={this.getPaginationValue}
            isPaginationLoading={this.state.isPaginationLoading}
          />
        </div>
      </div>
    );
  }
}

export default MyAccount;
