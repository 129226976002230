import React, { Component } from "react";
import { Form, Col, Row, Alert, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from "react-select-search";
import AsyncSelect from "react-select/async";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Constants from "../../../Config";

const apiUrl = Constants.API_URL;

export class ProductAdd extends Component {
  state = {
    productArray: [],
    productList: [],
    categoryArray: [],
    categoryList: [],
    product_name: "",
    product_id: "",
    pack_quantity: "",
    product_quantity: "",
    category_quantity: "",
    count: "",
    category_name: "",
    type: "",
    //
    other: "",
    other_quantity: 1 ,

    selectedValue: "product",
  };
  handleSelect = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeCategQuantity = (i, e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: this.state.category_name,
          quantity: this.state.category_quantity,
          type: "category",
          category_id: this.state.category_id,
          product_id: 0,
        });

        // this.props.addDisplayArray(i, {
        //   name: this.state.category_name,
        //   quantity: this.state.category_quantity,
        //   type: "category",
        //   category_id: this.state.category_id,
        //   product_id: 0,

        //   // price: ,
        // });
      }
    );
  };
  handleChangeProductQuantity = (i, e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: this.state.product_name,
          quantity: this.state.product_quantity,
          type: "product",
          price: this.state.product_price,
          product_id: this.state.product_id,
          category_id: 0,
        });

        // this.props.addDisplayArray(i, {
        //   name: this.state.product_name,
        //   quantity: this.state.product_quantity,
        //   type: this.state.selectedValue,
        //   price: this.state.product_price,
        //   product_id: this.state.product_id,
        //   category_id: 0,
        // });
      }
    );
  };
  getProductName = (i, e) => {
    let index = this.state.productList.filter((el) => el.name == e)[0];
    let productId = index.product_id;

    this.setState(
      {
        product_id: productId,
        product_name: e,
        //   availabe_stock: index.quantity,
        moq: index.moq,
        product_price: Number(index.price).toFixed(2),
        // product_quantity: index.moq,

        count: index.moq,
        product_quantity: index.pack_quantity,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: index.name,
          quantity: this.state.product_quantity.toString(),
          type: "product",
          product_id: productId,
          category_id: 0,
          price: Number(index.price).toFixed(2),
        });
      }
    );
  };

  getValue = (e) => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { keyword: e },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };
  getCategory = (e) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: {
        keyword: e,
      },
    })
      .then((data) => {
        // console.log(data.data.data, "Categories");
        this.setState({
          categoryList: data.data.data,
          categoryArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };

  getCategoryName = (i, e) => {
    let categ = this.state.categoryList.filter((el) => el.name == e)[0];
    categ["type"] = "category";
    // categ.push(type: "category")
    this.setState(
      {
        category_name: e,
        category_id: categ.category_id,
        type: categ.type,
        categSelect: true,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: e,
          quantity: this.state.category_quantity
            ? this.state.category_quantity
            : 1,
          type: "category",
          category_id: categ.category_id,
          product_id: 0,
        });
      }
    );
  };
  getOtherName = (i) => {
    this.props.addDisplayArray(i, {
      name: this.state.other,
      quantity: 1,
      type: "other",
      category_id: 0,
      product_id: 0,
    });
  };

  // addMoq = () => {
  //   let quantity =
  //     Number(this.state.product_quantity) + Number(this.state.pack_quantity);
  //   this.setState({ product_quantity: quantity });
  //   // this.props.addNewProduct({
  //   //   product_id: this.state.product_id,
  //   //   quantity: quantity ? quantity : this.state.product_quantity,
  //   //   name: this.state.product_name,
  //   // });
  // };
   

       

  render() {
    return (
      <div className="d-flex justify-content-between" key={this.props.index}>
        {this.state.selectedValue == "product" ? (
          <div className="d-flex justify-content-between">
            <Col lg={2}>
              <Form.Group controlId="formLeadTypeName">
                <Form.Label>Choose Type</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.handleSelect}
                  value={this.state.selectedValue}
                  //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                >
                  <option value="product">Product</option>
                  <option value="category">Category</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={4} className="customeAutoComplete">
              <Form.Label>Item type</Form.Label>
              <Form.Group
                controlId="formLeadProductName"
                key={this.props.index}
              >
                <SelectSearch
                  options={this.state.productArray}
                  getOptions={(query) => this.getValue(query)}
                  search
                  placeholder="Product name"
                  onChange={(e) => this.getProductName(this.props.index, e)}
                  value={this.state.product_name}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Label>Quantity</Form.Label>
              <Form.Group
                controlId="formLeadProductQuantity"
                key={this.props.index}
                className="d-flex"
                // style={{ display: "flex" }}
              >
                {/* <Button
                  className="h-50 d-inline-block"
                  //   disabled={this.state.quantity_error}
                  onClick={() =>
                    this.removeMOQ(this, this.state.product_quantity)
                  }
                >
                  <FontAwesomeIcon
                    icon="minus"
                    className=""

                    // disabled={this.state.isDisable}
                  />
                </Button> */}
                <Form.Control
                  className={this.state.quantity_error ? "error" : ""}
                  type="number"
                  min={1}
                  placeholder="Quantity"
                  name="product_quantity"
                  value={this.state.product_quantity}
                  onChange={(e) =>
                    this.handleChangeProductQuantity(this.props.index, e)
                  }
                  //  onBlur = {()=>{
                  //   this.props.addDisplayArray(this.props.index, {
                  //     name: this.state.product_name,
                  //     quantity: this.state.product_quantity,
                  //     type: "product",
                  //     price: this.state.product_price,
                  //     product_id: this.state.product_id,
                  //     category_id: 0,
                  //   });
                  //  }}
                />

                {/* <input
                 style={{
                     width: "35%",
                   minHeight: "35px",
                   border: "1px solid #ced4da",
                   borderRadius: "0.25rem",
                   textAlign: "center",
                 }}
                 placeholder="Quantity"
                 defaultValue={this.state.product_quantity}
                 // onBlur={()=>this.props.addNewProduct({product_id : this.state.product_id, quantity:this.state.product_quantity})}
                 onChange={this.handleChangeQuantity2}
               /> */}
                {/* <Button
                  className="h-50 d-inline-block"
                  disabled={this.state.quantity_error}
                  onClick={() => this.addMoq(this, this.state.product_quantity)}
                >
                  <FontAwesomeIcon icon="plus" className="" />
                </Button> */}
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formRfqPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  placeholder="Price"
                  name="price"
                  value={this.state.product_price}
                  onChange={this.handleChange}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              {!this.props.index ? (
                <>
                  <FontAwesomeIcon
                    icon="minus"
                    id={this.props.index}
                    onClick={() => this.props.removeItem(this.props.index)}
                    className="removeIcon"
                  />

                  <FontAwesomeIcon
                    icon="plus"
                    onClick={() =>
                      this.props.addNewItem({
                        product_id: this.state.product_id,
                        quantity: this.state.product_quantity,
                        // name: this.state.product_name
                      })
                    }
                    className="addIcon"
                  />
                </>
              ) : (
                null
              )}
            </Col>
          </div>
        ) : this.state.selectedValue == "category" ? (
          <div className="d-flex justify-content-between">
            <Col lg={2}>
              <Form.Group controlId="formLeadTypeName" key={this.props.index}>
                <Form.Label>choose Type</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.handleSelect}
                  value={this.state.selectedValue}
                  //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                >
                  <option value="product">Product</option>
                  <option value="category">Category</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={4} className="customeAutoComplete">
              <Form.Label>Item Detail</Form.Label>

              <Form.Group controlId="formLeadCategoryName">
                <SelectSearch
                  options={this.state.categoryArray}
                  getOptions={(query) => this.getCategory(query)}
                  search
                  placeholder="Category name"
                  onChange={(e) => this.getCategoryName(this.props.index, e)}
                  value={this.state.category_name}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Label>Quantity</Form.Label>

              <Form.Control
                className={this.state.quantity_error ? "error" : ""}
                type="number"
                min={1}
                placeholder="Quantity"
                name="category_quantity"
                value={this.state.category_quantity}
                onChange={(e) =>
                  this.handleChangeCategQuantity(this.props.index, e)
                }
                //  onBlur={()=>{
                //   this.props.addDisplayArray(this.props.index, {
                //     name: this.state.category_name,
                //     quantity: this.state.category_quantity,
                //     type: "category",
                //     category_id: this.state.category_id,
                //     product_id: 0,
                //   });
                //  }}
                // disabled
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Price</Form.Label>
              <Form.Group controlId="formRfqPrice">
                {/* <Form.Label>Price</Form.Label> */}
                <Form.Control
                  placeholder="Price"
                  name="price"
                  value="0"
                  onChange={this.handleChange}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              {!this.props.index ? (
                <>
                  <FontAwesomeIcon
                    icon="minus"
                    id={this.props.index}
                    onClick={() => this.props.removeItem(this.props.index)}
                    className="removeIcon"
                  />

                  <FontAwesomeIcon
                    icon="plus"
                    onClick={() =>
                      this.props.addNewItem({
                        product_id: this.state.product_id,
                        quantity: this.state.product_quantity,
                        // name: this.state.product_name
                      })
                    }
                    className="addIcon"
                  />
                </>
              ) : (
                null
              )}
            </Col>
            {/* <Col lg={2}>

            </Col> */}
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <Col lg={2}>
              <Form.Group controlId="formLeadTypeName">
                <Form.Label>choose Type</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.handleSelect}
                  value={this.state.selectedValue}
                  //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                >
                  <option value="product">Product</option>
                  <option value="category">Category</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={4} className="customeAutoComplete">
              <Form.Label>Item Detail</Form.Label>

              <Form.Group controlId="formLeadCategoryName">
                <Form.Control
                  type="text"
                  name="other"
                  value={this.state.other}
                  onChange={this.handleChange}
                  // onBlur={() => {
                  //   this.props.addDisplayArray({
                  //     name: this.state.other,
                  //     quantity: this.state.other_quantity,
                  //     type: "other",
                  //     category_id: 0,
                  //     product_id: 0,

                  //     // price: ,
                  //   });
                  // }}
                  onBlur={() => this.getOtherName(this.props.index)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Label>Quantity</Form.Label>

              <Form.Control
                className={this.state.quantity_error ? "error" : ""}
                type="number"
                min={1}
                placeholder="Quantity"
                name="other_quantity"
                defaultValue={1}
                value={this.state.other_quantity}
                onChange={this.handleChange}
                // disabled
                onBlur={() => {
                  this.props.addDisplayArray(this.props.index, {
                    name: this.state.other,
                    quantity: this.state.other_quantity,
                    type: "other",
                    category_id: 0,
                    product_id: 0,

                    // price: ,
                  });
                }}
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Price</Form.Label>
              <Form.Group controlId="formRfqPrice">
                {/* <Form.Label>Price</Form.Label> */}
                <Form.Control
                  placeholder="Price"
                  name="price"
                  value="0"
                  onChange={this.handleChange}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              {!this.props.index ? (
                <>
                  <FontAwesomeIcon
                    icon="minus"
                    id={this.props.index}
                    onClick={() => this.props.removeItem(this.props.index)}
                    className="removeIcon"
                  />

                  <FontAwesomeIcon
                    icon="plus"
                    onClick={() =>
                      this.props.addNewItem({
                        product_id: this.state.product_id,
                        quantity: this.state.product_quantity,
                        // name: this.state.product_name
                      })
                    }
                    className="addIcon"
                  />
                </>
              ) : (
                null
              )}
            </Col>
            {/* <Col lg={2}>

          </Col> */}
          </div>
        )}
      </div>
    );
  }
}

export default ProductAdd;
