import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import SelectSearch from "react-select-search";
import AddProducts from "../Quotes/AddProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../../Config";
import axios from "axios";
import AddItemInRfq from "./AddItemInRfq";
import ProductAdd from "../Leads/ProductAdd";
import ProductAdd2 from "../Leads/ProductAdd2";
// import Test from "./Test";

const apiUrl = Constants.API_URL;

class AddItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_idB2c: "",
      show: false,
      productdetailArray: [
        {
          productName: "",
          productQuantity: 0,
        },
      ],
      itemArray: [
        {
          itemName: "",
          itemQuantity: 0,
        },
      ],
      itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
      id: 0,
      id2: 0,
      displayProductArray: [],
      quantity_error: false,
      category_name: "",
      categoryList: [],
      categoryArray: [],
      category_id: "",
      categSelect: false,

      name: "",
      type: "",
      selectedValue: "product",
      new_add: false,
      newAdd: false,

      onBlurNotClicked: true,
      onlyFirstAdd: false,
    };
  }

  //    getPhoneValueB2C() {
  //         axios({ method: "POST", url: apiUrl + "/get-customer-detail-by-phone",
  //         data: {
  //             phone: this.props.customer_mobile,
  //             customer_name: this.props.customer_name,
  //             customer_email: this.props.customer_email
  //         } })
  //             .then(data => {
  //             console.log(data)
  //             this.setState({
  //                 customer_idB2c: data.data.data[0].customer_id
  //             });
  //             })
  //             .catch(error => console.log(error));
  // }

  // var abc= this.props.custome_id
  componentDidMount() {
    this.setState({
      displayProductArray: this.props.rfq_products,
    });
  }

  checkedCondition = () => {
    this.openModal();
    if (this.props.custome_id > 0) {
    }
    // if (this.props.customer_name && this.props.customer_mobile) {
    //   this.openModal();
    // }
    else {
      // this.getPhoneValueB2C().then(res=>{
      //     this.openModal()
      // })
      // if(
      //     this.props.customer_mobile.length > 0 &&
      //     this.props.customer_name.length > 0 &&
      //     this.props.customer_email.length > 0
      // )
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails?.token}`,
        },
        url: apiUrl + "/get-customer-detail-by-phone",
        data: {
          phone: this.props.customer_mobile,
          customer_name: this.props.customer_name,
          customer_email: this.props.customer_email,
        },
      })
        .then((data) => {
          this.props.customer_idB2(data.data.data[0].customer_id);
          this.props.customerMobile(data.data.data[0].telephone);
          this.openModal();
          this.setState({
            customer_idB2c: data.data.data[0].customer_id,
          });
        })
        .catch((error) => {
          console.log(error);
          // alert("First Add Customer Mobile and Customer Name");
        });
    }
  };

  openModal = () => {
    this.setState({
      show: true,

      itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  addDisplayArray = (i, e) => {
    let itemDisplayArray = this.state.itemDisplayArray;
    itemDisplayArray[i] = e;
    this.setState({ itemDisplayArray });
  };

  addNew = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;

    this.setState({
      productdetailArray: this.state.productdetailArray.concat({
        productName: newId,
        productQuantity: newId,
      }),
      // itemArray: this.state.itemArray.concat({
      //   itemName: newId,
      //   itemQuantity: newId,
      // }),
      id: newId,

      new_add: true,
    });
    this.props.displayProductArray.push(e);
    // this.props.displayProductArray.push(e);
  };

  addNewProduct = (e) => {
    let displayArray = [];

    // displayArray = this.state.displayProductArray.filter((el) => {
    //   return !displayArray.find((element) => {
    //     return element.product_id === el.product_id;
    //   });
    // });
    // if (this.state.new_add) {
    //   displayArray = this.props.displayProductArray;
    // }

    displayArray.push(e);

    // displayArray.map((item,i)=>{
    //   if(item[i].product_id == item[i+1].product_id){
    //     return item[i+1]
    //   }
    // })

    this.setState({
      displayProductArray: this.props.displayProductArray.concat(displayArray),

      // itemArray: this.state.itemArray.concat(e),
    });
  };
  addNewItem = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;

    this.setState({
      itemDisplayArray: [
        ...this.state.itemDisplayArray,
        { name: "", type: "product", quantity: "" },
      ],
      id: newId,
    });
  };

  removeItem = (e) => {
    let id = e;
    let productdetailArray = this.state.productdetailArray;
    productdetailArray.splice(id, 1);
    // let itemArray = this.state.itemArray;
    // itemArray.splice(id, 1);
    let displayProductArray = this.state.displayProductArray;

    // For Add Rfq <productAdd>
    let itemArray = this.state.itemArray;

    itemArray.splice(id, 1);

    let itemDisplayArray = this.state.itemDisplayArray;

    itemDisplayArray.splice(e, 1);

    this.setState({
      itemArray,
      itemDisplayArray,
      productdetailArray,
      displayProductArray,
    });
  };

  // Below is the function to get the name of the category using Search space
  getItemDetails = (e) => {
    let allItems = null;
    allItems = this.state.itemDisplayArray;
    const getLastElement = (name, allItems) => {
      const objUniq = allItems.reduce(
        (res, item) => ({ ...res, [item[name]]: item }),
        {}
      );
      return Object.keys(objUniq).map((item) => objUniq[item]);
    };
    const uniqAddress = getLastElement("name", allItems);
    const finalArray = uniqAddress.filter((item) => item.name != "");
    e.preventDefault();
    this.setState(
      {
        itemArray: [
          {
            itemName: "",
            itemQuantity: 0,
          },
        ],
        itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
        // new_add:true,
        // itemDisplayArray: uniqAddress,
      },
      () => {
        this.props.getRfqArray(finalArray);
      }
    );
    this.closeModal();
  };

  getProductDetails = (e) => {
    // const customer_idB2c = this.state.customer_idB2c
    let allProducts = null;

    if (this.props.rfq_products) {
      allProducts = this.state.displayProductArray;
      console.log(
        allProducts,
        "ALLPRODUCTS",
        this.state.displayProductArray,
        this.props.displayProductArray,
        "Props:::"
      );
    } else {
      allProducts = this.props.displayProductArray.concat(
        this.state.displayProductArray
      );
    }
    e.preventDefault();
    this.setState(
      {
        show: false,
      },
      () => {
        this.props.getProductArray(allProducts);
        // this.props.customer_idB2(customer_idB2c)
      }
    );
    this.setState({
      productdetailArray: [
        {
          productName: "",
          productQuantity: 0,
        },
      ],
      itemArray: [
        {
          itemName: "",
          itemQuantity: 0,
        },
      ],
    });
  };
  quantityDisable = (e) => {
    this.setState({ quantity_error: e });
  };
  handleSelect = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });
    console.log(
      e.target.value,
      "SELECTEd VLAUE ::::",
      this.state.selectedValue
    );
  };

  render() {
    return (
      <div className="card-body">
        {this.props.getAllProductsWithDetailForList.length === 0 ? (
          <Button
            className="AddItemButton"
            variant="secondary"
            size="lg"
            block
            onClick={this.checkedCondition}
          >
            <FontAwesomeIcon className="editIcon" icon="plus" />
          </Button>
        ) : (
          <Button variant="secondary" size="sm" onClick={this.openModal}>
            Add Line item
          </Button>
        )}

        <Modal
          show={this.state.show}
          onHide={this.closeModal}
          backdrop="static"
          size="lg"
          className="choose_products_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Choose Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.showCategory ? (
              this.state.itemDisplayArray.map((item, index) => {
                return (
                  // <div
                  // key={"name" + item.itemName}
                  // className={"name" + item.itemName}>
                  //   {/* <AddItemInRfq
                  //     ind={ind}
                  //     item={item}
                  //     itemArray={this.state.itemArray}
                  //     addNew={this.addNew}
                  //     removeItem={this.removeItem}
                  //     addNewItem={this.addNewItem}
                  //     onBlurNotClicked={this.state.onBlurNotClicked}
                  //   /> */}
                  <ProductAdd2
                    index={index}
                    item={item}
                    itemArray={this.state.itemArray}
                    removeItem={this.removeItem}
                    addNewItem={this.addNewItem}
                    itemDisplayArray={this.state.itemDisplayArray}
                    // onBlurNotClicked={this.state.onBlurNotClicked}
                    addDisplayArray={this.addDisplayArray}
                  />
                );
              })
            ) : (
              <>
                <Row className="mb-2">
                  {/* <Col lg={2}>Choose Type</Col> */}
                  <Col lg={5}>Product Detail</Col>
                  <Col lg={2}>Stock</Col>
                  <Col lg={3}>Quantity</Col>
                </Row>
                {this.state.productdetailArray.map((item, index) => {
                  return (
                    <div
                      key={"name" + item.productName}
                      className={"name" + item.productName}
                    >
                      <div className="d-flex">
                        <Col lg={12}>
                          <AddProducts
                            index={index}
                            productDetailArray={this.state.productdetailArray}
                            addNew={this.addNew}
                            removeItem={this.removeItem}
                            addNewProduct={this.addNewProduct}
                            quantityDisable={this.quantityDisable}
                          />
                        </Col>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {/* diffrence between createquote and creatqrfq */}

            {/* ) : 
            (
              <>
                <Row className="mb-2">
                  <Col>Choose Type</Col>
                </Row>

                <Row>
                  <Col lg={2}>
                    <Form.Group
                      controlId="formBasicTypeName"
                      // key={item.index}
                    >
                      <Form.Control
                        as="select"
                        onChange={this.handleSelect}
                        value={this.state.selectedValue}
                      >
                        <option value="product">Product</option>
                        <option value="category">Category</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4} className="customeAutoComplete">
                    <Form.Group controlId="formBasicCategoryName">
                      <SelectSearch
                        options={this.state.categoryArray}
                        getOptions={(query) => this.getCategory(query)}
                        search
                        placeholder="Category name"
                        onChange={this.getCategoryName}
                        value={this.state.category_name}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )} */}
          </Modal.Body>
          <Modal.Footer>
            {this.props.showCategory ? (
              <>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.getItemDetails}
                  // disabled={this.state.quantity_error}
                >
                  Add Item
                </Button>
                <Button variant="secondary" onClick={this.closeModal}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="primary"
                onClick={this.getProductDetails}
                disabled={this.state.quantity_error}
              >
                Add product
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddItemModal;
