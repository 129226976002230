import React from "react";
import { Spinner } from "react-bootstrap";

const RfqFooter = (props) => {
  const { submitLoading, buttonDisabled, handleSubmit } = props;
  return (
    <>
      <section className="footerCnt">
        <div className="container-40">
          <div className="footerWrapper">
            <button
              // type="button"
              className="rfqCommonBtn"
              type="button"
              id="createRfqSr"
              // disabled={submitLoading ? true : false}
              disabled={buttonDisabled}
              onClick={handleSubmit}
            >
              {submitLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Create with SR"
              )}
            </button>
            <button
              className="rfqCommonBtn bgBlue"
              type="button"
              id="createRfq"
              // disabled={submitLoading ? true : false}
              disabled={buttonDisabled}
              onClick={handleSubmit}
            >
              {submitLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Create RFQ"
              )}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default RfqFooter;
