// copy all business accounts file changes

import React, { useState } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Link } from "react-router-dom";
import {
  Table,
  Modal,
  Button,
  Alert,
  Spinner,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from "xlsx";
import Constants from "../../../Config";
import Pagination from "../Common/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import "./style.css";
import ApprovalProcess from "./ApprovalProcess";
import BusinessProfile from "./BusinessProfile";
import Daterangepicker from "../Common/Daterangepicker";
import Axios from "axios";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select, { components } from "react-select";

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} /> {children}
    </components.Option>
  );
};

const columns = [
  {
    dataField: "id",
    text: "Request Id",
    sort: true,
  },
  {
    dataField: "company_name",
    text: "Company Name",
    sort: true,
  },
  {
    dataField: "business_group",
    text: "Group",
    sort: true,
  },
  // {
  //   dataField: "business_type",
  //   text: "Type",
  //   sort: true,
  // },
  {
    dataField: "username",
    text: "Name",
    sort: true,
  },
  {
    dataField: "pricing_type",
    text: "Request Source",
  },

  {
    dataField: "wholesale_pricing_status",
    text: "Wholesale Pricing Status",
  },
  {
    dataField: "source",
    text: "Source",
  },
  // {
  //   dataField: "lastCaller",
  //   text: "Last Caller",
  // },
  {
    dataField: "support_agent_id",
    text: "Support Agent"
  },
  {
    dataField: "followup_date",
    text: "Followup Date",
  },
  {
    dataField: "created_at",
    text: "Date",
    sort: true,
  },
  {
    dataField: "action",
    text: "Action",
  },
];

const headers = [
  { label: "Customer ID", key: "customer_id" },
  { label: "Name", key: "username" },
  { label: "Email", key: "useremail" },
  { label: "Phone", key: "userphone" },
  { label: "Company Name", key: "company_name" },
  { label: "GST Number", key: "gstin" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Business Group", key: "business_group" },
  { label: "Business Type", key: "business_type" },
  { label: "Sub Business Type", key: "sub_business_type" }, // add sub_business_key
  { label: "Product Interested", key: "product_interested" },
  { label: "Reject reason", key: "reject_reason" },
  { label: "Pro ID", key: "sup_pro_id" },
  { label: "Agent ID", key: "agent_id" },
  { label: "Agent Name", key: "agent_name" },
  { label: "Wholesale Pricing Status", key: "wholesale_pricing_status" },
  { label: "Source", key: "source" },
  // add new label start here
  { label: "Years in Business", key: "yrs_in_business" },
  // add new key
  { label: "Employee Count", key: "employee_count" },
  { label: "Project Done", key: "projects_done" },
  { label: "Credit Period", key: "credit_period" },
  { label: "Credit Limit", key: "credit_limit" },
  // add new key ends here
  { label:"Annual Revenue (in lacs)", key:"turnover" },
  { label:"Is Contact", key:"is_account_primary" },
  // add new label ends here
  { label: "FollowUP Date", key: "followup_date" },
  { label: "Registration date", key: "created_at" },
  { label: "First Call Status", key: "first_call_status" },
  { label: "First Call Note", key: "first_call_note" },
  { label: "First Call UserName", key: "first_call_username" },
  { label: "First Call Date Time", key: "first_call_datetime" },
  { label: "Last Call Status", key: "last_call_status" },
  { label: "Last Call Note", key: "last_call_note" },
  { label: "Last Call UserName", key: "last_callUsename" },
  { label: "Last Call Date Time", key: "last_calldatetime" },
  // adding Primary key Details here
  { label:"Primary Company Name", key: "primaryCompanyName" },
  { label:"Primary Contact Name", key: "primaryContactName" },
  { label:"Primary Contact Mobile No", key: "primaryContactPhone" },
  { label:"Support Agent", key: "supportAgent" },
  // Support Agent supportAgent
  // { label:"Primary Contact Email", key: "primaryContactEmail" }
  // ending Primary key Details here
];
// const heading = [ "Request Id","Company Name", "Business Group","Business Type","Customer ID", "Name","Email","Phone",
// "Pro ID","Agent ID", "Agent Name", "GST Number","City","State","Product Interested","Reject reason","Pro Pricing Status",
// "FollowUP Date","Registration date","First Call Status","First Call Note","First Call UserName","First Call Date Time","Last Call Status",
// "Last Call Note","Last Call UserName","Last Call Date Time"]

class ListBusinessAccount extends React.Component {
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
  }

  state = {
    BusinessAccountsList: [],
    BusinessAccountsListForDownload: [],
    show: false,
    businessData: [],
    isLoading: false,
    total_item: 25,
    current_page: 1,
    total_record: 0,
    isDownloading: false,
    isPaginationLoading: 0,
    mobilenumber: "",
    company_name: "",
    status: "",
    option: "",
    date_from: "",
    date_to: "",
    pro_pricing_type: [],
    pricing_type: "",
    accountStatus: [],
    lastCaller: "",
    support_agent_id:[], // add new state support agent
    apiData: [],
    FollowupData: [],
    showFollowup: true,
    allowedSlugs: [],
    agentOptions: [], // support filter
    salesAgent: [], // add new for support
  };
  componentDidMount() {
    this.getFollowUp();
    this.getAllBusinessAccount();
    this.getCount();
    this.getProPricingType();
    this.getStatus();
    this.getAllAgents();   
    this.setState({
      allowedSlugs: this.props.childSlugProp["business-accounts"],
    });
  }
  getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        agent_list: "",
        id: [54],
        showAll: "true",
        slug: "business-accounts",
        listType: "list",
      },

      // data: { logined_user_id: this.props.getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState(
          {
            agentsArray: data.data.data,
          },
          () => {
            let agentOptions1 = data.data.data;
            let agentOptions = agentOptions1.map((item) => {
              if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
                item["label"] = item["name"];
                item["value"] = item["id"];
                delete item["name"];
                delete item["id"];
                return item;
              }
            });
            // agentOptions.unshift({ label: "UnAssign", value: 0 });
            this.setState({ agentOptions });
          }
        );
      })
      .catch((error) => console.log(error));
  };

  getFollowUp = () => {
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return Axios(apiUrl + "/agents-work-data/pro", requestoption)
      .then((data) => {
        let apiData = data.data.data;
        this.setState({
          apiData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getAllBusinessAccount = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },
          data: {
            current_page: this.state.current_page,
            total_item: this.state.total_item,
            mobile: this.state.mobilenumber,
            company_name: this.state.company_name,
            status: this.state.status.length ? this.state.status : [],
            business_account_id: this.state.business_account_id
              ? this.state.business_account_id
              : "0",
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            pro_pricing_type: this.state.pricing_type.length
              ? this.state.pricing_type
              : [],
              support_agent_id: this.state.salesAgent ? 
                                this.state.salesAgent : []
          },
        };
        return axios(apiUrl + "/business-account", requestoption)
          .then((data) => {
            this.setState(
              {
                BusinessAccountsList: data.data.data,
                isLoading: false,
              },
              () => {
                const dataNew = this.state.BusinessAccountsList.map(
                  (item, index) => {
                    return {
                      sno: index + 1,
                      id: item.id,
                      company_name: item.company_name,
                      business_group: item.business_group,
                      business_group_id: item.business_group_id,
                      // business_type: item.business_type,
                      username: item.username,
                      pricing_type: item.pro_pricing_type,
                      wholesale_pricing_status: this.getStatusButton(
                        item.current_status
                      ),
                      source: item.source,
                      // lastCaller:
                      //   item.call_attempted != 0
                      //     ? `${item.lastCallAttempt.agent_name} (${item.call_attempted})`
                      //     : "",
                      support_agent_id: item.supportAgent + (item.supportAgent!=''?`(${item.call_attempted})`:''), // add new key   
                      followup_date: item.followup_date,
                      created_at: item.created_at,
                      action: this.state.allowedSlugs.includes("edit") ? (
                        ["Enabled", "Disabled"].includes(
                          item.current_status
                        ) ? (
                          <div style={{ width: 170 }}>
                            <Button
                              variant="light"
                              type="button"
                              onClick={() => this.openViewDetailPopup(item.id)}
                              style={{
                                fontSize: 12,
                                border: "1px solid #cccccc",
                              }}
                            >
                              <FontAwesomeIcon icon="edit" /> View
                            </Button>
                          </div>
                        ) : (
                          <div style={{ width: 170 }}>
                            <Button
                              variant="light"
                              type="button"
                              onClick={() => this.openViewDetailPopup(item.id)}
                              style={{
                                fontSize: 12,
                                border: "1px solid #cccccc",
                              }}
                            >
                              <FontAwesomeIcon icon="edit" /> View & Approve{" "}
                            </Button>
                          </div>
                        )
                      ) : null,
                    };
                  }
                );

                this.setState({
                  BusinessAccountsList: dataNew,
                  isPaginationLoading: 0,
                });
              }
            );
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };
  businessAccountsListForDownload = () => {
    return new Promise((resolve, reject) => {
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails?.token}`,
            },
            data: {
              mobile: this.state.mobilenumber,
              company_name: this.state.company_name,
              status: this.state.status,
              key: "business-account",
              agent_id: loginedUserDetails.user_id,
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              pro_pricing_type: this.state.pricing_type.length
                ? this.state.pricing_type
                : [],
                support_agent_id: this.state.salesAgent.length ? 
                                  this.state.salesAgent : []
            },
          };
          return axios(apiUrl + "/export-business-account", requestoption)
            .then((data) => {
              this.setState(
                {
                  BusinessAccountsListForDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew =
                    this.state.BusinessAccountsListForDownload.map(
                      (item, index) => {
                        return {
                          id: item.id,
                          company_name: item.company_name,
                          profile_type: item.profile_type,
                          business_group: item.business_group,
                          business_type: item.business_type,
                          "Sub Business Type": item.sub_business_type,
                          customer_id: item.customer_id,
                          created_by: item.created_by,
                          username: item.username,
                          useremail: item.useremail,
                          userphone: item.userphone,
                          sup_pro_id: item.sup_pro_id,
                          agent_id: item.agent_id,
                          agent_name: item.agent_name,
                          gstin: item.gstin,
                          panNo: item.panNo,
                          city: item.city,
                          state: item.state,
                          product_interested: item.product_interested,
                          reject_reason: item.reject_reason,
                          pricing_type: item.pro_pricing_type,
                          // add new key start here
                          yrs_in_business: item.yrs_in_business,
                          employee_count: item.employee_count,
                          turnover: item.turnover,
                          credit_limit: item.credit_limit, // add credit_limit
                          credit_period: item.credit_period, // add credit_period
                          projects_done: item.projects_done, // add projects_done
                          is_account_primary: item.is_account_primary,
                          // add new key ends here
                          source: item.source,
                          wholesale_pricing_status: item.current_status,
                          callcount: item.call_attempted,
                          followup_date: item.followup_date,
                          created_at: item.created_at,
                          first_call_status: item.firstCallAttempt.call_status,
                          first_call_note: item.firstCallAttempt.agent_note,
                          first_call_username: item.firstCallAttempt.agent_name,
                          first_call_datetime: item.firstCallAttempt.created_at,
                          last_call_status: item.lastCallAttempt.call_status,
                          last_call_note: item.lastCallAttempt.agent_note,
                          last_callUsename: item.lastCallAttempt.agent_name,
                          last_calldatetime: item.lastCallAttempt.created_at,
                          // adding primary key details here
                          primaryCompanyName: item.primaryCompanyName,
                          primaryContactName: item.primaryContactName,
                          primaryContactPhone: item.primaryContactPhone,
                          supportAgent: item.supportAgent,
                          // primaryContactEmail: item.primaryContactEmail,
                          // ending primary key details here 
                          "UTM Content": item.utm_content,
                          "UTM Campaign": item.utm_campaign,
                          "UTM Source": item.utm_source,
                          "UTM Medium": item.utm_medium,
                        };
                      }
                    );
                  this.setState({
                    BusinessAccountsListForDownload: dataNew,
                    // total_record:dataNew.length
                  });
                  resolve(dataNew)
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };

  getCount = () => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          data: {
            agent_id: this.props.getUserDetails.user_id,
            key: "business-account",
            status: this.state.status.length ? this.state.status : [],
            mobile: this.state.mobilenumber,
            company_name: this.state.company_name,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            pro_pricing_type: this.state.pricing_type.length
              ? this.state.pricing_type
              : [],
              support_agent_id: this.state.salesAgent.length ? 
                                this.state.salesAgent : []
          },
        };
        return axios(apiUrl + "/get-accounts-count", options)
          .then((data) => {
            this.setState({
              total_record: data.data.data.count,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };

  // LightGray

  getStatusButton = (status) => {
    if (status == "Enabled") {
      return (
        <Button size="sm" className="button" variant="success">
          {status}
        </Button>
      );
    } else if (status == "Rejected") {
      return (
        <Button size="sm" className="button" variant="danger">
          {status}
        </Button>
      );
    } else if (status == "Followup") {
      return (
        <Button
          size="sm"
          className="button"
          style={{
            backgroundColor: "#FFA500",
            borderColor: "#FFA500",
            color: "#ffffff",
          }}
        >
          {status}
        </Button>
      );
    } else if (status == "Pending-NR") {
      return (
        <Button
          size="sm"
          className="button"
          style={{
            backgroundColor: "#6c757d",
            borderColor: "#6c757d",
            color: "#ffffff",
          }}
        >
          {status}
        </Button>
      );
    } else if (status == "Draft") {
      return (
        <Button
          size="sm"
          className="button"
          style={{
            backgroundColor: "Gray",
            borderColor: "Gray",
            color: "#ffffff",
          }}
        >
          {status}
        </Button>
      );
    } else if (status == "Verified PRO") { // adding new verified-pro status
       return (
        <Button 
          size="sm"
          className="button"
          style={{
            backgroundColor: "green",
            borderColor: "green",
            color: "#ffffff"
          }}
        >
          {status}
        </Button>
       )
    } 
    else if (status == "Verified Other B2B") { // adding new verified-other-b2b status
      return (
       <Button 
         size="sm"
         className="button"
         style={{
           backgroundColor: "#23CF04", // light green background-color
           borderColor: "#23CF04",    // light green border-color
           color: "#ffffff"
         }}
       >
         {status}
       </Button>
      )
   }
   else if (status == "Merged") { // adding new merged status
    return (
     <Button 
       size="sm"
       className="button"
       style={{
         backgroundColor: "#C2B280", // light sand background-color
         borderColor: "#C2B280",    // light sand border-color
         color: "#ffffff"
       }}
     >
       {status}
     </Button>
    )
 } else {
      return (
        <Button
          size="sm"
          className="button"
          variant="warning"
          style={{ color: "#ffffff" }}
        >
          {status}
        </Button>
      );
    }
  };

  openViewDetailPopup = (businessID) => {
    this.getBusinessAccountDetail(businessID);
  };
  closeFollowupModal = () => {
    this.setState({
      showFollowup: false,
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
    localStorage.removeItem("dataQuote");
  };
  getBusinessAccountDetail = (businessID) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: { business_account_id: `${businessID}` },
    };
    return axios(apiUrl + "/business-account", options)
      .then((data) => {
        let businessData = data.data.data[0];
        this.setState({
          businessData: businessData,
          show: true,
        });
      })
      .catch((error) => console.log(error));
  };
  getPaginationValue = (currentPage, displayItems) => {
    this.setState(
      {
        current_page: currentPage,
        total_item: displayItems,
      },
      () => {
        this.getAllBusinessAccount();
      }
    );
  };

  paginationLoading = () => {
    this.setState({
      isPaginationLoading: 1,
    });
  };
  filterByAssignTo = (e) => {
    this.setState(
      {
        // lead_id: 0,

        salesAgent: e,

        current_page: 1,
        // QuoteListDownload: [],
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getAllBusinessAccount();
      }
    );
  };
  proPricingFilter = (e) => {
    this.setState({
      pricing_type: e,
      current_page: 1,
    });
    this.getCount();
    this.paginationLoading();
    this.getAllBusinessAccount();
    // this.businessAccountsListForDownload();
  };
  getProPricingType = (e) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { meta_id: 24 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let requestSource = data.data.data;
        let pro_pricing_type = requestSource.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ pro_pricing_type });
      })
      .catch((error) => console.log(error));
  };
  getStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { meta_id: 26 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let status = data.data.data;
        let accountStatus = status.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ accountStatus });
      })

      .catch((error) => console.log(error));
  };

  debounce = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  mobileFilter = (e) => {
    this.setState({
      mobilenumber: e,
      current_page: 1,
    });
    if (e.length === 10) {
      // this.setState({
      //     mobilenumber: e.target.value,
      // }, () => {
      this.getCount();
      this.paginationLoading();
      this.getAllBusinessAccount();
      // });
    } else {
      this.getCount();
      this.paginationLoading();
      this.getAllBusinessAccount();
    }
  };
  updateMobile = this.debounce(this.mobileFilter);
  getMobile = (e) => {
    this.updateMobile(e.target.value);
  };

  companyFilter = (e) => {
    this.setState(
      {
        company_name: e.target.value,
        current_page: 1,
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getAllBusinessAccount();
        // this.businessAccountsListForDownload();
      }
    );
  };

  filterByStatus = (e) => {
    this.setState(
      {
        status: e,
        current_page: 1,
      },
      () => {
        this.getCount();
        this.paginationLoading();
        this.getAllBusinessAccount();
        // this.businessAccountsListForDownload();
      }
    );
  };
  exportFollowup = () => {
    let dataNew = this.state.apiData.map((item) => {
      return {
        Agent: item.agentName,
        Type: item.type,
        Name: item.name,
        Mobile: item.mobile,
        "Follow Up date": item.followup_date,
      };
    });
    this.setState({ FollowupData: dataNew }, () => {
      setTimeout(() => {
        this.getExcel2();
        // this.csvLinkEl.current.click();
      });
    });
  };
  getExcel2 = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      this.state.FollowupData
      // { origin: "A2", skipHeader: true }
    );

    XLSX.utils.book_append_sheet(wb, ws, "followupListAccount");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(wb, "followup-List-Account.xls");
  };

  downloadReport = async () => {
    let result;
    result = await this.businessAccountsListForDownload();
    if (result.length >= 0) {
      this.setState({ BusinessAccountsListForDownload: result });
    }
    setTimeout(() => {
      this.getExcel();
      
      // this.csvLinkEl.current.click(); //unnecessary
    });
  };

  getExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      this.state.BusinessAccountsListForDownload
      //  { origin: 'A2', skipHeader: true }
    );
    // XLSX.utils.sheet_add_aoa(ws, [heading], { origin: 'A1' });

    ws.A1.v = "Request ID";
    ws.B1.v = "Company Name";
    ws.C1.v = "Profile Type";
    ws.D1.v = "Business Group";
    ws.E1.v = "Business Type";
    // Sub Business Type
    ws.F1.v = "Sub Business Type";
    ws.G1.v = "Customer ID";
    ws.H1.v = "Created By";
    ws.I1.v = "Name";
    ws.J1.v = "Email";
    ws.K1.v = "Phone";
    ws.L1.v = "Pro ID";
    ws.M1.v = "Agent ID";
    ws.N1.v = "Agent Name";
    ws.O1.v = "GST Number";
    ws.P1.v = "Pan No.";
    ws.Q1.v = "City";
    ws.R1.v = "State";
    ws.S1.v = "Product Interested";
    ws.T1.v = "Reject reason";
    ws.U1.v = "Wholesale Pricing Type";
    //add new columns start here
    ws.V1.v = "Years in Business";
    ws.W1.v = "Employee Count"; // add new
    ws.X1.v = "Annual Revenue (in lacs)";
    ws.Y1.v = "Credit Limit"; // add new
    ws.Z1.v = "Credit Period"; // add new
    ws.AA1.v = "Projects Done"; // add new
    ws.AB1.v = "Is Primary";
    ws.AC1.v = "APP Source";
    ws.AD1.v = "Wholesale Pricing Status";
    ws.AE1.v = "Call Count";
    ws.AF1.v = "FollowUP Date";
    // add new columns ends here
    ws.AG1.v = "Registration date";
    ws.AH1.v = "First Call Status";
    ws.AI1.v = "First Call Note";
    ws.AJ1.v = "First Call UserName";
    ws.AK1.v = "First Call Date Time";
    ws.AL1.v = "Last Call Status";
    ws.AM1.v = "Last Call Note";
    ws.AN1.v = "Last Call UserName";
    ws.AO1.v = "Last Call Date Time";
    // add new primary columns here
    ws.AP1.v = "Primary Company Name"
    ws.AQ1.v = "Primary Contact Name"
    ws.AR1.v = "Primary Contact Mobile No"
    ws.AS1.v = "Support Agent"
    // ws.AS1.v = "Primary Contact Email"
    // ends new primary columns here
    // ws.AO1.v = "";
    // ws.AK1.v = "";

    XLSX.utils.book_append_sheet(wb, ws, "myFile1");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(wb, "Pro-Pricing-list.xls");
  };
  setItem = (QUOTEID) => {
    var data = {
      quoteID: QUOTEID,
      rfqID: QUOTEID,
      // productsArr: productsArr,
      // customer_id: customerid,
    };
    localStorage.setItem("dataQuote", JSON.stringify(data));
  };
  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
          current_page: 1,
        },
        () => {
          this.getCount();
          this.paginationLoading();
          this.getAllBusinessAccount();
        }
      );
    };
    return (
      <div className="card table-card allBusinessList">
        {this.state.apiData.length ? (
          <Modal
           center
            show={this.state.showFollowup}
            onHide={this.closeFollowupModal}
            dialogClassName="modal-90w business_process_wrapper mobile-table-scroll"
          >
            <Modal.Header closeButton>
              <div>
                {this.state.allowedSlugs.includes("download") ? (
                  <Button
                    onClick={this.exportFollowup}
                    // variant="outline-success"
                    className="listingBtn"
                  >
                    Download
                  </Button>
                ) : null}
              </div>
            </Modal.Header>
            <div className="mobile-table-scroll"
              // style={{
              //   overflow: "scroll",
              //   maxHeight: "50vh",
              //   backgroundColor: "#FCAE1E",
              // }}
            >
              <table className="table table-fixed">
                <thead style={{ position: "sticky", top: -1 }}>
                  <tr>
                    <th>Agent</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>FollowUp Date</th>
                    {this.state.allowedSlugs.includes("edit") ? (
                      <th>Action</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody className="all-leadsbg-wrapper">
                  {this.state.apiData &&
                    this.state.apiData.map((item) => {
                      return (
                        <tr style={{ lineHeight: "10px" }}>
                          <td>{item.agentName}</td>
                          <td>{item.type}</td>
                          <td>{item.name}</td>
                          <td>{item.mobile}</td>
                          <td>{item.followup_date}</td>
                          {this.state.allowedSlugs.includes("edit") ? (
                            <td>
                              <Button
                                variant="light"
                                type="button"
                                size="sm"
                                onClick={() =>
                                  this.openViewDetailPopup(item.id)
                                }
                                style={{
                                  fontSize: 12,
                                  border: "1px solid #cccccc",
                                }}
                              >
                                <FontAwesomeIcon icon="edit" /> View
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Modal>
        ) : null}
        <div className="headingPadding">
          <Row>
            {/* <Row>
              <Col>
                <h3 className="proAccountHeader">Pro Accounts</h3>
              </Col>
            </Row> */}
            {/* <Row></Row> */}
            <Col md="3" className="mt-3">
            <div className="customFilterCnt">
              <div className="customFilter">
                <Daterangepicker setDateRange={setDateRange}></Daterangepicker>
              </div>
            </div>
            </Col>
            <Col md="3" className="mt-3">
              <Select
                defaultValue={[]}
                isMulti
                name="accountStatus"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                options={this.state.accountStatus}
                className="basic-multi-select listingFontSizeColor"
                classNamePrefix="select"
                placeholder="Filter by Status"
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    this.setState(
                      {
                        status: options?.map((opt) => opt.value),
                      },
                      () => {
                        this.filterByStatus(this.state.status);
                      }
                    );
                  } else {
                    this.filterByStatus([]);
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
              {/* <Form.Control as="select" custom onChange={this.filterByStatus}>
                <option value="">Filter By Status</option>
                {this.state.accountStatus &&
                  this.state.accountStatus.map((item, ind) => {
                    return (
                      <option key={item.id} value={item.meta_value}>
                        {item.meta_title}
                      </option>
                    );
                  })}
              </Form.Control> */}
            </Col>
            <Col md="3" className="mt-3">
              <Select
                defaultValue={[]}
                isMulti
                name="requestSource"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                options={this.state.pro_pricing_type}
                className="basic-multi-select listingFontSizeColor"
                classNamePrefix="select"
                placeholder="Request Source"
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    // this.setState(options?.map((opt) => opt.user_id));

                    this.setState(
                      {
                        pricing_type: options?.map((opt) => opt.value),
                      },
                      () => {
                        this.proPricingFilter(this.state.pricing_type);
                      }
                    );
                  } else {
                    this.proPricingFilter([]);
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />

              {/* <Form.Control
                as="select"
                custom
                name="pricing_type"
                value={this.state.pricing_type}
                onChange={this.proPricingFilter}
              >
                <option value="">Select Request Source</option>
                {this.state.pro_pricing_type &&
                  this.state.pro_pricing_type.map((item, index) => {
                    return (
                      <option key={item.id} value={item.meta_value}>
                        {item.meta_title}
                      </option>
                    );
                  })}
              </Form.Control> */}
            </Col>
            <Col md="3" className="mt-3">
              <InputGroup className="m-0">
                <FormControl
                  className="listingFontSizeColor"
                  placeholder="Filter-Mobile"
                  onChange={(data) => this.getMobile(data)}
                />
              </InputGroup>
            </Col>
            <Col md="3" className="mt-3">
              <InputGroup className="m-0">
                <FormControl
                  className="listingFontSizeColor"
                  placeholder="Filter-Company"
                  onChange={this.companyFilter}
                />
              </InputGroup>
            </Col>
            {/* Support Agent Filter Component Start here */}
            {this.state.agentOptions && this.state.agentOptions.length > 1 ? (
            <Col md="3" className="mt-3">
              <Select
                defaultValue={[]}
                isMulti
                name="orderType"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                options={this.state.agentOptions}
                className="basic-multi-select listingFontSizeColor"
                classNamePrefix="select"
                placeholder="Filter-Support"
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    this.setState(
                      {
                        salesAgent: options?.map(
                          (opt) => opt.value
                        ),
                      },
                      () => {
                        this.filterByAssignTo(
                          this.state.salesAgent
                        );
                      }
                    );
                  } else {
                    this.filterByAssignTo([]);
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </Col>) : null}
            {/* Support Agent Filter Ends here */}
            {this.state.allowedSlugs.includes("add") ? (
            <Col md="3" className="mt-3">
              <Link
                className="listingBtn"
                to={{ pathname: "/BusinessProfile" }}
              >
                Add Business Profile
              </Link>
            </Col>) : null}
              {/* <Col>
                <CSVLink data={ this.state.BusinessAccountsListForDownload } headers={headers} filename={"business-accounts.csv"} className="btn btn-primary downLoadButton" >
                {
                this.state.isDownloading
                ?
                <Spinner animation="border" />
                :
                <span>Export Data</span>
                }
                </CSVLink>
            </Col>  */}
            {this.state.allowedSlugs.includes("download") ? (
              <Col md="3" className="mt-3">
                <Link
                  data={this.state.BusinessAccountsListForDownload}
                  headers={headers}
                  className="hidden"
                  filename={"my-accounts-list.xls"}
                  ref={this.csvLinkEl}
                ></Link>

                <span
                  variant="primary"
                  className="listingBtn"
                  onClick={this.downloadReport}
                  // disabled={this.state.isDownloading}
                >
                  {this.state.isDownloading ? (
                    <Spinner animation="border" />
                  ) : (
                    "Export Data"
                  )}
                </span>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="card-block">
          {this.state.isLoading ? (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="mobile-table-scroll">
            <BootstrapTable
              keyField="sno"
              data={this.state.BusinessAccountsList}
              columns={columns}
              striped
              hover
              bordered={false}
              bootstrap4
              noDataIndication="Data not found"
            />
            </div>
          )}

          <Modal
            className="business_process_wrapper"
            centered
            show={this.state.show}
            onHide={this.closeModal}
            dialogClassName="modal-90w "
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header
              closeButton
              style={{ padding: "0px 5px", border: "none" }}
            >
              {this.state.businessData.quoteID ? (
                <b>
                  QuoteID : {this.state.businessData.quoteID},
                  &nbsp;&nbsp;&nbsp; Quote Status:{" "}
                  {this.state.businessData.quoteStatus}
                  &nbsp;&nbsp;&nbsp; Quote Validity:{" "}
                  {this.state.businessData.quoteValidity}
                  &nbsp;&nbsp;&nbsp; RFQ ID: {this.state.businessData.rfq_id}
                  &nbsp;&nbsp;&nbsp; Role: {this.state.businessData.role}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="/createQuote"
                    onClick={() =>
                      this.setItem(this.state.businessData.quoteID)
                    }
                    target="_blank"
                  >
                    Quote Detail
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    href="/rfqDetails"
                    onClick={() => this.setItem(this.state.businessData.rfq_id)}
                    target="_blank"
                  >
                    RFQ Detail
                  </a>
                </b>
              ) : null}
              {
                <b>
                  {this.state.businessData.utm_source ? `UTM Source: ${this.state.businessData.utm_source}`: ""}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.businessData.utm_medium ? `UTM Medium: ${this.state.businessData.utm_medium}`: ""}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.businessData.utm_content ? `UTM Content: ${this.state.businessData.utm_content}`: ""}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.businessData.utm_campaign ? `UTM Campaign: ${this.state.businessData.utm_campaign}`: ""}
                </b>
              }
            </Modal.Header>
            <Modal.Body className="business_approve_process">
              <ApprovalProcess
                businessData={this.state.businessData}
                closeModal={this.closeModal}
                getAllBusinessAccount={this.getAllBusinessAccount}
                isFromProPricing={true}
                getUserDetails={this.props.getUserDetails}
              />
            </Modal.Body>
          </Modal>
          <Pagination
            current_page={this.state.current_page}
            total_item={this.state.total_record}
            getPaginationValue={this.getPaginationValue}
            isPaginationLoading={this.state.isPaginationLoading}
          />
        </div>
      </div>
    );
  }
}

export default ListBusinessAccount;
