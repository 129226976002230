import React, { useState } from "react";
import "../../asset/css/helvetica.css"
import "./static-page.css"
import user_icon from '../../asset/Images/user_icon.svg'
import downArrowGrey from '../../asset/Images/downArrowGrey.svg'
import searchIcon from '../../asset/Images/searchIcon.svg'
import infoIconGrey from '../../asset/Images/infoIconGrey.svg'
import deleteIcon from '../../asset/Images/delete_new_icon.svg'
import crossBlue from '../../asset/Images/crossBlue.svg'
import downloadIcon from '../../asset/Images/downloadIconNew.svg'
import alertMsg from "../../asset/Images/danger_alert.svg"
import viewBtn from "../../asset/Images/viewBtn.svg"
import callIcon from "../../asset/Images/callIcon.svg"
import backArrowNew from "../../asset/Images/backArrowNew.svg"
import backArrowInactive from "../../asset/Images/backArrowInactive.svg"
import edit from "../../asset/Images/edit.svg"


const StaticPage = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const showDropDown = () => {
        setShowDropdown(!showDropdown)
    }
    const closeDropDown = () => {
        setShowDropdown(false)
    }
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <section className="customerInfoMainCnt">
                <div className="container-40">
                    <div className="customerInfoEditable">
                        <h1 className="pageSubHeading">Customer Info</h1>
                        <div className="customerInfoCnt">
                            <div className="customerInfoItem">
                                <div className="traInputWrapper">
                                    <input type="number" id="name" required />
                                    <label htmlFor="name">Customer Account</label>
                                    <div className="traInputIconWrapper">
                                        <img src={user_icon} width="24px" height="24px" />
                                    </div>
                                </div>
                            </div>
                            <div className="customerInfoItem">
                                <div className="traInputWrapper">
                                    <input type="number" id="name" required />
                                    <label htmlFor="name">Customer Mobile</label>
                                </div>
                            </div>
                            <div className="customerInfoItem">
                                <div className="traInputWrapper">
                                    <input type="text" id="name" required />
                                    <label htmlFor="name">Customer Name</label>
                                </div>
                            </div>
                            <div className="customerInfoItem">
                                <div className="traInputWrapper">
                                    <input type="email" id="name" required />
                                    <label htmlFor="name">Customer Email</label>
                                </div>
                            </div>
                            <div className="customerInfoItem customeSelectCnt">
                                <div className="customeSelectCnt">
                                    <button className={`customSelectBtn`} onClick={toggleDropdown}>
                                        <label htmlFor="name">Customer Role</label>
                                        <span className="selectedValue">CEO</span>
                                        <div className="customSelectBtnIcon downArrowIcon">
                                            <img src={downArrowGrey} width="12px" height="12px" />
                                        </div>
                                    </button>
                                    <div className={`customDropDownCnt ${isOpen ? "show" : ""}`}>
                                        <ul className="dropdownWrapper">
                                            <li className="dropdownItem">COE</li>
                                            <li className="dropdownItem">Designer</li>
                                            <li className="dropdownItem">Developer</li>
                                            <li className="dropdownItem">COE</li>
                                            <li className="dropdownItem">Designer</li>
                                            <li className="dropdownItem">Developer</li>
                                            <li className="dropdownItem">COE</li>
                                            <li className="dropdownItem">Designer</li>
                                            <li className="dropdownItem">Developer</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="customerInfoItem">
                                <div className="traInputWrapper">
                                    <input type="text" id="name" required />
                                    <label htmlFor="name">Delivery Location</label>
                                    <div className="traInputIconWrapper searchIcon">
                                        <img src={searchIcon} width="20px" height="20px" />
                                    </div>
                                </div>
                            </div>
                            <div className="customerInfoItem">
                                <div className="traInputWrapper">
                                    <input type="text" id="name" required />
                                    <label htmlFor="name">Agent</label>
                                </div>
                            </div>
                            <div className="customerInfoItem infoIconCnt">
                                <div className="customeSelectCnt">
                                    <button className="customSelectBtn" style={{ cursor: "default" }}>
                                        <label htmlFor="name">Credit Finance</label>
                                        <span className="selectedValue">2 Options Available</span>
                                        <div className="customSelectBtnIcon downArrowIcon" onClick={showDropDown} style={{ cursor: "pointer" }}>
                                            <img src={infoIconGrey} width="20px" height="20px" />
                                        </div>
                                    </button>
                                    <div className={`creditFinaceDtl ${showDropdown ? "" : "d-none"}`}>
                                        <div className="closeDropDown" onClick={closeDropDown}>
                                            <img src={crossBlue} width="16px" height="16px" alt="cross icon" />
                                        </div>
                                        <ul className="creditFinaceItemWrapper">
                                            <li>Name</li>
                                            <li>Rate</li>
                                            <li>Amount</li>
                                        </ul>
                                        <hr className="hr-1 my-2" />
                                        <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                                            <li>Udaan (15 Days)</li>
                                            <li>2.25%</li>
                                            <li>₹5,00,00,000</li>
                                        </ul>
                                        <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                                            <li>Udaan (15 Days)</li>
                                            <li>2.25%</li>
                                            <li>₹5,00,00,000</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr className="hr-16" />

            {/* requirement detail section start */}
            <section className="requirementDtlCnt">
                <div className="container-40">
                    <h2 className="pageSubHeading">Requirement Details</h2>
                    <div className="requirementDtlTableCnt">
                        <table className="requirementTable">
                            <thead className="tableHead">
                                <tr>
                                    <th style={{ width: "20%" }}>Type</th>
                                    <th style={{ width: "33%" }}>Name</th>
                                    <th style={{ width: "10%" }}>Brand</th>
                                    <th style={{ width: "10%" }}>Quantity</th>
                                    <th style={{ width: "10%" }}>Unit</th>
                                    <th style={{ width: "10%" }}>Price</th>
                                    <th style={{ width: "7%" }}>&nbsp;</th>
                                </tr>
                            </thead>

                            <tbody className="tableBody">
                                <tr>
                                    <td className="enabled_filed_wrapper">
                                        <div className="customeSelectCnt tableSelectOption">
                                            <button className="customSelectBtn">
                                                <label htmlFor="name">Catalogue</label>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                            <div class="customDropDownCnt">
                                                <ul class="dropdownWrapper">
                                                    <li class="dropdownItem">Ozone </li>
                                                    <li class="dropdownItem">Mortise Door</li>
                                                    <li class="dropdownItem">Mortise Door Handle</li>
                                                    <li class="dropdownItem">Somany Duragres</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <textarea rows={2} className="textEditableInput" type="text" placeholder=" Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                            for Residences Library Offices Hospitals High Security
                                            Premises, Silver (2 Years Warranty)">
                                            </textarea>
                                            <a href="javascript:void(0)" className="view_Btn"><img src={viewBtn} width="16px" height="16px" /><span className="viewCnt">View</span></a>
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="Ozone" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="number" className="" placeholder="10" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="pcs" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="₹250" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" className="tableSelectOption deleteIconBtn">
                                            <img src={deleteIcon} width="24px" height="24px" />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="enabled_filed_wrapper">
                                        <div className="customeSelectCnt tableSelectOption">
                                            <button className="customSelectBtn">
                                                <label for="name">Catalogue</label>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <textarea rows={2} className="textEditableInput" type="text" placeholder=" Pedestal fans">
                                            </textarea>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="Godrej" />
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="number" className="" placeholder="10" />
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="pcs" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="N/A" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" className="tableSelectOption deleteIconBtn">
                                            <img src={deleteIcon} width="24px" height="24px" />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="enabled_filed_wrapper">
                                        <div className="customeSelectCnt tableSelectOption">
                                            <button className="customSelectBtn">
                                                <label for="name">Other</label>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <textarea rows={2} className="textEditableInput" type="text" placeholder="Blue Paint Bottles">
                                            </textarea>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="Asian Paint" />
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="number" className="" placeholder="2" />
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="" placeholder="litres" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="N/A" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" className="tableSelectOption deleteIconBtn">
                                            <img src={deleteIcon} width="24px" height="24px" />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="enabled_filed_wrapper">
                                        <div className="customeSelectCnt tableSelectOption">
                                            <button className="customSelectBtn">
                                                <label for="name">Attachment</label>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label for="downloadIcon" className="upload_title">
                                                <img src={downloadIcon} width="16px" height="16px" />
                                                <span>Upload a file</span>
                                            </label>
                                            <input id="downloadIcon" type="file" className="downloadFile" style={{ display: "none" }} />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="NA" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="number" className="disabled" placeholder="NA" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="NA" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper">
                                        <div className="tableSelectOption">
                                            <label></label>
                                            <input type="text" className="disabled" placeholder="NA" readOnly="readonly" />
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" className="tableSelectOption deleteIconBtn">
                                            <img src={deleteIcon} width="24px" height="24px" />
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="enabled_filed_wrapper">
                                        <div className="customeSelectCnt tableSelectOption">
                                            <button className="customSelectBtn">
                                                <label for="name">Catalogue</label>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="enabled_filed_wrapper searchProductWrapper">
                                        <div className="searchProduct_list tableSelectOption">
                                            <div>
                                                <label for="searchProduct"></label>
                                                <input className="searchProducts" placeholder="Search a product" />
                                            </div>
                                            <div class="customDropDownCnt">
                                                <ul class="dropdownWrapper">
                                                    <li class="dropdownItem">
                                                        Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                                        for Residences Library Offices Hospitals High Security
                                                        Premises, Silver (2 Years Warranty)
                                                    </li>
                                                    <li class="dropdownItem">Mortise Door Handle Set For Aluminium Casement Door - Series - McCoy DHM</li>
                                                    <li class="dropdownItem">Mortise Door Handle Set For Aluminium Casement Door - Series - McCoy DHM - White - McCoy 8M
                                                    </li>
                                                    <li class="dropdownItem">Somany Duragres Breccia Grande Valor</li>
                                                    <li class="dropdownItem">Somany Duragres Breccia Grande Valor</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper alert-msg-area">
                                        <div className="alertmsgInner tableSelectOption">
                                            <span>&nbsp;</span>
                                            <img src={alertMsg} width="16px" height="16px" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper alert-msg-area">
                                        <div className="alertmsgInner tableSelectOption">
                                            <span>&nbsp;</span>
                                            <img src={alertMsg} width="16px" height="16px" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper alert-msg-area">
                                        <div className="alertmsgInner tableSelectOption">
                                            <span>&nbsp;</span>
                                            <img src={alertMsg} width="16px" height="16px" />
                                        </div>
                                    </td>
                                    <td className="disbled_filed_wrapper alert-msg-area">
                                        <div className="alertmsgInner tableSelectOption">
                                            <span>&nbsp;</span>
                                            <img src={alertMsg} width="16px" height="16px" />
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" className="tableSelectOption deleteIconBtn">
                                            <img src={deleteIcon} width="24px" height="24px" />
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <button type="button" className="addItemsBtn">Add item</button>
                    </div>
                </div>
            </section>
            {/* requirement detail section  end */}


            {/* second page start */}
            <section className="rfqDltMainCnt">
                <div className="container-40">
                    <div className="rfqMainCnt">
                        <div className="rfqLeftSection">
                            <div className="customerInfoNonEditable">
                                <h1 className="pageSubHeading">Customer Info</h1>
                                <div className="customerInfoCnt mt-2">
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Customer Account</label>
                                            <input type="button" id="name" value="SuperDesigns" />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Customer Email</label>
                                            <input type="button" id="name" value="iamnikhil@mccoymart.com" />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Delivery Location</label>
                                            <input type="button" id="name" value="Lajpat Nagar, Delhi 110007" />
                                            <button className="editButton">
                                                <img src={edit} alt="edit icon" width="12px" height="12px"/>
                                                <span>Edit</span>
                                            </button>
                                        </div>
                                        <div className="traInputWrapper d-none">
                                            <input type="text" id="name" required />
                                            <label htmlFor="name">Delivery Location</label>
                                            <div className="traInputIconWrapper searchIcon">
                                                <img src={searchIcon} width="20px" height="20px" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Customer Name</label>
                                            <input type="button" id="name" value="Nikhil Sharma" />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Customer Role</label>
                                            <input type="button" id="name" value="Other Construction PRO" />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Mobile</label>
                                            <input type="button" id="name" value="+91 9540495531" />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Agent</label>
                                            <input type="button" id="name" value="Chetan" />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem w-100">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Customer Message</label>
                                            <input type="button" id="name" value="Please provide a detailed product description highlighting key features and benefits." />
                                        </div>
                                    </div>
                                    <div className="customerInfoItem">
                                        <div className="nonEditableInputWrapper">
                                            <label htmlFor="name">Support Remarks</label>
                                            <input type="button" id="name" value="NA" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="requirementDtlCnt2">
                                <h2 className="pageSubHeading">Requirement Details</h2>
                                <div className="requirementDtlTableCnt">
                                    <table className="requirementTable List">
                                        <thead className="tableHead">
                                            <tr>
                                                <th style={{ width: "20%" }}>Type</th>
                                                <th style={{ width: "33%" }}>Name</th>
                                                <th style={{ width: "10%" }}>Brand</th>
                                                <th style={{ width: "10%" }}>Quantity</th>
                                                <th style={{ width: "10%" }}>Unit</th>
                                                <th style={{ width: "10%" }}>Price</th>
                                                <th style={{ width: "7%" }}>&nbsp;</th>
                                            </tr>
                                        </thead>

                                        <tbody className="tableBody">
                                            <tr>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="customeSelectCnt tableSelectOption">
                                                        <button className="customSelectBtn">
                                                            <label htmlFor="name">Catalogue</label>
                                                            <div className="customSelectBtnIcon downArrowIcon">
                                                                <img src={downArrowGrey} width="12px" height="12px" />
                                                            </div>
                                                        </button>

                                                        <div class="customDropDownCnt">
                                                            <ul class="dropdownWrapper">
                                                                <li class="dropdownItem">Ozone </li>
                                                                <li class="dropdownItem">Mortise Door</li>
                                                                <li class="dropdownItem">Mortise Door Handle</li>
                                                                <li class="dropdownItem">Somany Duragres</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <textarea rows={2} className="textEditableInput" type="text" placeholder=" Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                                        for Residences Library Offices Hospitals High Security
                                                        Premises, Silver (2 Years Warranty)">
                                                        </textarea>
                                                        <a href="javascript:void(0)" className="view_Btn"><img src={viewBtn} width="16px" height="16px" /><span className="viewCnt">View</span></a>
                                                    </div>
                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="Ozone" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="number" className="" placeholder="10" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="pcs" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="₹250" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td>
                                                    <button type="button" className="tableSelectOption deleteIconBtn">
                                                        <img src={deleteIcon} width="24px" height="24px" />
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="customeSelectCnt tableSelectOption">
                                                        <button className="customSelectBtn">
                                                            <label for="name">Catalogue</label>
                                                            <div className="customSelectBtnIcon downArrowIcon">
                                                                <img src={downArrowGrey} width="12px" height="12px" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <textarea rows={2} className="textEditableInput" type="text" placeholder=" Pedestal fans">
                                                        </textarea>
                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="Godrej" />
                                                    </div>

                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="number" className="" placeholder="10" />

                                                    </div>

                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="pcs" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="N/A" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td>
                                                    <button type="button" className="tableSelectOption deleteIconBtn">
                                                        <img src={deleteIcon} width="24px" height="24px" />
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="customeSelectCnt tableSelectOption">
                                                        <button className="customSelectBtn">
                                                            <label for="name">Other</label>
                                                            <div className="customSelectBtnIcon downArrowIcon">
                                                                <img src={downArrowGrey} width="12px" height="12px" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <textarea rows={2} className="textEditableInput" type="text" placeholder="Blue Paint Bottles">
                                                        </textarea>
                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="Asian Paint" />
                                                    </div>


                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="number" className="" placeholder="2" />
                                                    </div>


                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="" placeholder="litres" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="N/A" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td>
                                                    <button type="button" className="tableSelectOption deleteIconBtn">
                                                        <img src={deleteIcon} width="24px" height="24px" />
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="customeSelectCnt tableSelectOption">
                                                        <button className="customSelectBtn">
                                                            <label for="name">Attachment</label>
                                                            <div className="customSelectBtnIcon downArrowIcon">
                                                                <img src={downArrowGrey} width="12px" height="12px" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <a href="javascript:void(0)" type="button" className="attachmentBtn">Attachment.pdf</a>
                                                    </div>
                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="NA" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="number" className="disabled" placeholder="NA" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="NA" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td className="disbled_filed_wrapper">
                                                    <div className="tableSelectOption">
                                                        <label></label>
                                                        <input type="text" className="disabled" placeholder="NA" readOnly="readonly" />
                                                    </div>


                                                </td>
                                                <td>
                                                    <button type="button" className="tableSelectOption deleteIconBtn">
                                                        <img src={deleteIcon} width="24px" height="24px" />
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="enabled_filed_wrapper">
                                                    <div className="customeSelectCnt tableSelectOption">
                                                        <button className="customSelectBtn">
                                                            <label for="name">Catalogue</label>
                                                            <div className="customSelectBtnIcon downArrowIcon">
                                                                <img src={downArrowGrey} width="12px" height="12px" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="enabled_filed_wrapper searchProductWrapper">
                                                    <div className="searchProduct_list tableSelectOption">
                                                        <div>
                                                            <label for="searchProduct"></label>
                                                            <input className="searchProducts" placeholder="Search a product" />
                                                        </div>
                                                        <div class="customDropDownCnt">
                                                            <ul class="dropdownWrapper">
                                                                <li class="dropdownItem">
                                                                    Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                                                    for Residences Library Offices Hospitals High Security
                                                                    Premises, Silver (2 Years Warranty)
                                                                </li>
                                                                <li class="dropdownItem">Mortise Door Handle Set For Aluminium Casement Door - Series - McCoy DHM</li>
                                                                <li class="dropdownItem">Mortise Door Handle Set For Aluminium Casement Door - Series - McCoy DHM - White - McCoy 8M
                                                                </li>
                                                                <li class="dropdownItem">Somany Duragres Breccia Grande Valor</li>
                                                                <li class="dropdownItem">Somany Duragres Breccia Grande Valor</li>

                                                            </ul>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td className="disbled_filed_wrapper alert-msg-area">
                                                    <div className="alertmsgInner tableSelectOption">
                                                        <span>&nbsp;</span>
                                                        <img src={alertMsg} width="16px" height="16px" />
                                                    </div>

                                                </td>
                                                <td className="disbled_filed_wrapper alert-msg-area">
                                                    <div className="alertmsgInner tableSelectOption">
                                                        <span>&nbsp;</span>
                                                        <img src={alertMsg} width="16px" height="16px" />
                                                    </div>

                                                </td>
                                                <td className="disbled_filed_wrapper alert-msg-area">
                                                    <div className="alertmsgInner tableSelectOption">
                                                        <span>&nbsp;</span>
                                                        <img src={alertMsg} width="16px" height="16px" />
                                                    </div>

                                                </td>
                                                <td className="disbled_filed_wrapper alert-msg-area">
                                                    <div className="alertmsgInner tableSelectOption">
                                                        <span>&nbsp;</span>
                                                        <img src={alertMsg} width="16px" height="16px" />
                                                    </div>

                                                </td>
                                                <td>
                                                    <button type="button" className="tableSelectOption deleteIconBtn">
                                                        <img src={deleteIcon} width="24px" height="24px" />
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <button type="button" className="addItemsBtn">Add item</button>
                                </div>
                            </div>
                        </div>
                        <div className="rfqRightSection">
                            <div className="calLogBtnWrapper">
                                <button type="button" className="rfqCommonBtn bgBlue">
                                    <img src={callIcon} alt="call icon" width="11px" height="11px" />
                                    <span>Call Customer</span>
                                </button>
                                <button type="button" className="rfqCommonBtn bgGrey">Log a Call</button>
                            </div>
                            <div className="callRemarkCnt">
                                <h2 className="pageSubHeading">Remarks on Call Attempt</h2>
                                <div className="callReamrkWrapper">
                                    <div className="callReamrkItem">
                                        <div className="customeSelectCnt callReamrkDropCnt">
                                            <button className={`customSelectBtn`} onClick={toggleDropdown}>
                                                <label htmlFor="name">Call Status</label>
                                                <span className="selectedValue">Call Waiting</span>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                            <div className={`customDropDownCnt ${isOpen ? "show" : ""}`}>
                                                <ul className="dropdownWrapper">
                                                    <li className="dropdownItem">Price Issue</li>
                                                    <li className="dropdownItem">Needed Urgent Delivery</li>
                                                    <li className="dropdownItem">Non Serviceable Product</li>
                                                    <li className="dropdownItem">Non Serviceable Location</li>
                                                    <li className="dropdownItem">Designer</li>
                                                    <li className="dropdownItem">Developer</li>
                                                    <li className="dropdownItem">Lead Time Issue</li>
                                                    <li className="dropdownItem">Fulfilled by Other Quote</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="callReamrkItem">
                                        <div className="traInputWrapper">
                                            <input type="text" id="name" required />
                                            <label htmlFor="name">Call Back</label>
                                        </div>
                                    </div>
                                    <div className="callReamrkItem">
                                        <div className="customeSelectCnt callReamrkDropCnt">
                                            <button className={`customSelectBtn`} onClick={toggleDropdown}>
                                                <label htmlFor="name">RFQ Status</label>
                                                <span className="selectedValue">Pending</span>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                            <div className={`customDropDownCnt ${isOpen ? "show" : ""}`}>
                                                <ul className="dropdownWrapper">
                                                    <li className="dropdownItem">Pending</li>
                                                    <li className="dropdownItem">Sucsess</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="callReamrkItem">
                                        <div className="traInputWrapper">
                                            <input type="text" id="name" required />
                                            <label htmlFor="name">Remarks</label>
                                        </div>
                                    </div>
                                    <div className="callReamrkItem">
                                        <div className="customeSelectCnt callReamrkDropCnt">
                                            <button className={`customSelectBtn`} onClick={toggleDropdown}>
                                                <label htmlFor="name">Transfer Account</label>
                                                <span className="selectedValue">Rahul</span>
                                                <div className="customSelectBtnIcon downArrowIcon">
                                                    <img src={downArrowGrey} width="12px" height="12px" />
                                                </div>
                                            </button>
                                            <div className={`customDropDownCnt ${isOpen ? "show" : ""}`}>
                                                <ul className="dropdownWrapper">
                                                    <li className="dropdownItem">Rahul</li>
                                                    <li className="dropdownItem">Nikhil</li>
                                                    <li className="dropdownItem">Nasir</li>
                                                    <li className="dropdownItem">Irshad</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="callHistoryCnt">
                                <h2 className="pageSubHeading">Call History</h2>
                                <div className="callHistoryAccordionCnt">
                                    <div className="callHistoryAccordionItem show">
                                        <button className="callAccordionBtn">
                                            <span>Call 03</span>
                                            <img src={backArrowInactive} width="12px" height="12px" alt="arrow icon" className="inactiveIcon"/>
                                            <img src={backArrowNew} width="12px" height="12px" alt="arrow icon" className="activeIcon"/>
                                        </button>
                                        <div className="callAccordionBody">
                                            <ul className="callHistoryWrapper">
                                                <li>
                                                    <span>Call 01</span>
                                                    <p>No Remarks</p>
                                                </li>
                                                <li>
                                                    <span>Status</span>
                                                    <p>NA</p>
                                                </li>
                                                <li>
                                                    <span>By</span>
                                                    <p>Dev Kapoor</p>
                                                </li>
                                                <li>
                                                    <span>on</span>
                                                    <p>13th Feb, 2018</p>
                                                </li>
                                                <li>
                                                    <span>on</span>
                                                    <p>13th Feb, 2018</p>
                                                </li>
                                                <li>
                                                    <span>Calling Mode</span>
                                                    <p>Manual</p>
                                                </li>
                                                <li>
                                                    <span>Follow up on</span>
                                                    <p>NA</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="callHistoryAccordionItem">
                                        <button className="callAccordionBtn">
                                            <span>Call 03</span>
                                            <img src={backArrowInactive} width="12px" height="12px" alt="arrow icon" className="inactiveIcon"/>
                                            <img src={backArrowNew} width="12px" height="12px" alt="arrow icon" className="activeIcon"/>
                                        </button>
                                        <div className="callAccordionBody">
                                            <ul className="callHistoryWrapper">
                                                <li>
                                                    <span>Call 01</span>
                                                    <p>No Remarks</p>
                                                </li>
                                                <li>
                                                    <span>Status</span>
                                                    <p>NA</p>
                                                </li>
                                                <li>
                                                    <span>By</span>
                                                    <p>Dev Kapoor</p>
                                                </li>
                                                <li>
                                                    <span>on</span>
                                                    <p>13th Feb, 2018</p>
                                                </li>
                                                <li>
                                                    <span>on</span>
                                                    <p>13th Feb, 2018</p>
                                                </li>
                                                <li>
                                                    <span>Calling Mode</span>
                                                    <p>Manual</p>
                                                </li>
                                                <li>
                                                    <span>Follow up on</span>
                                                    <p>NA</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="callHistoryAccordionItem">
                                        <button className="callAccordionBtn">
                                            <span>Call 03</span>
                                            <img src={backArrowInactive} width="12px" height="12px" alt="arrow icon" className="inactiveIcon"/>
                                            <img src={backArrowNew} width="12px" height="12px" alt="arrow icon" className="activeIcon"/>
                                        </button>
                                        <div className="callAccordionBody">
                                            <ul className="callHistoryWrapper">
                                                <li>
                                                    <span>Call 01</span>
                                                    <p>No Remarks</p>
                                                </li>
                                                <li>
                                                    <span>Status</span>
                                                    <p>NA</p>
                                                </li>
                                                <li>
                                                    <span>By</span>
                                                    <p>Dev Kapoor</p>
                                                </li>
                                                <li>
                                                    <span>on</span>
                                                    <p>13th Feb, 2018</p>
                                                </li>
                                                <li>
                                                    <span>on</span>
                                                    <p>13th Feb, 2018</p>
                                                </li>
                                                <li>
                                                    <span>Calling Mode</span>
                                                    <p>Manual</p>
                                                </li>
                                                <li>
                                                    <span>Follow up on</span>
                                                    <p>NA</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* second page start */}

            {/* footer section star */}
            <section className="footerCnt">
                <div className="container-40">
                    <div className="footerWrapper">
                        <button type="button" className="rfqCommonBtn">Create with SR</button>
                        <button type="button" className="rfqCommonBtn bgBlue">Create RFQ</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StaticPage;