import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Col, Row, Alert, Button, Modal } from "react-bootstrap";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Constants from "../../../Config";
import ProductAdd from "./ProductAdd";
import ProductAdd2 from "./ProductAdd2";
import ProductAdd3 from "./ProductAdd3";

const apiUrl = Constants.API_URL;

export class ItemAdd extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    getAllProductsWithDetailForList: [],
    itemArray: [
      {
        itemName: "",
        itemQuantity: 0,
      },
    ],
    id: 0,
    // selectedValue: "product",
    show: false,
    itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
    new_add: false,
    newArray: [],
    initialArray: [],
  };
  checkedCondition1 = () => {
    if (this.props.customer_id > 0) {
      this.openModal();
    } else {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails?.token}`,
        },
        url: apiUrl + "/get-customer-detail-by-phone",
        data: {
          phone: this.props.customer_mobile,
          customer_name: this.props.customer_name,
          customer_email: this.props.customer_email,
        },
      })
        .then((data) => {
          this.props.customer_idB2(data.data.data[0].customer_id);
          this.props.customerMobile(data.data.data[0].telephone);
          this.openModal();
          this.setState({
            customer_idB2c: data.data.data[0].customer_id,
          });
        })
        .catch((error) => {
          console.log(error);
          alert("first add customer mobile name and email");
        });
    }
  };
  checkedCondition = () => {
    if (this.props.customer_mobile.length == 10) {
      this.openModal();
    } else {
      alert("Enter Correct customer mobile name and email");
    }
  };

  openModal = () => {
    this.setState(
      {
        show: true,
        new_add: true,
        itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
      }
      // },
      // () => {
      //   this.itemRemoveChecking();
      // }
    );
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };
  addNewItem = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;

    // this.setState({
    //   itemArray: this.state.itemArray.concat({
    //     itemName: newId,
    //     itemQuantity: newId,
    //   }),
    //   id: newId,
    //   new_add: true,
    // });
    this.setState({
      itemDisplayArray: [
        ...this.state.itemDisplayArray,
        { name: "", type: "product", quantity: "" },
      ],
    });
  };
  removeItem = (i) => {
    let itemDisplayArray = this.state.itemDisplayArray;
    itemDisplayArray.splice(i, 1);
    this.setState({
      itemDisplayArray,
    });
  };
  // editSameItem = (i, e) => {
  //   if (this.props.leadItemDisplay.length == 0) {
  //     const values = [...this.state.itemDisplayArray];
  //     values[i] = e;
  //     console.log(values, "Vlaues in Edit Same time ");
  //     this.setState({
  //       itemDisplayArray: this.props.leadItemDisplay.concat(values),
  //       new_add: false,
  //       // selectedValue: e.target.value,
  //     });
  //   } else {
  //     const values = [...this.state.itemDisplayArray];
  //     values[i] = e;
  //     this.setState({
  //       // itemDisplayArray: e,
  //       itemDisplayArray: this.props.leadItemDisplay.concat(values[i]),
  //       new_add: false,
  //     });
  //     console.log("Inside else of editsametime");
  //   }
  // };
  
  addDisplayArray = (i, e) => {
    {
      let itemDisplayArray = this.state.itemDisplayArray;
      itemDisplayArray[i] = e;
      this.setState({ itemDisplayArray });
    }
  };
  itemRemoveChecking = () => {
    if (this.props.itemsRemoved) {
      this.setState({
        itemDisplayArray: this.props.leadItemDisplay,
      });
    }
  };

  getItemDetails = (e) => {
    let allItems = null;
    allItems = this.state.itemDisplayArray;
    const getLastElement = (name, allItems) => {
      const objUniq = allItems.reduce(
        (res, item) => ({ ...res, [item[name]]: item }),
        {}
      );
      return Object.keys(objUniq).map((item) => objUniq[item]);
    };
    const uniqAddress = getLastElement("name", allItems);
    const finalArray = uniqAddress.filter(item=>item.name != "") 
    e.preventDefault();
    this.setState(
      {
        itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
        // new_add:true,
        // itemDisplayArray: uniqAddress,
      },
      () => {
        this.props.showleadDisplayArray(finalArray);
      }
    );
    this.closeModal();
  };
  render() {
    return (
      <div className="card-body">
        {this.props.leadItemDisplay.length == 0 ? (
          <>
            <h5>Product Detail</h5>
            <Button
              className="AddItemButton add_plus_btn"
              variant="secondary"
              size="lg"
              block
              onClick={this.checkedCondition}
              // onClick={this.openModal}
            >
              <FontAwesomeIcon className="editIcon" icon="plus" />
            </Button>
          </>
        ) : (
          <Button variant="secondary" size="sm" onClick={this.openModal}>
            Add Line item
          </Button>
        )}

        <Modal
          dialogClassName="custom_dialog"
          show={this.state.show}
          onHide={this.closeModal}
          backdrop='static'
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Choose Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.itemDisplayArray.map((item, index) => {
              return (
                // <div
                //   key={index}
                //   className={"name" + item.name}
                // >
                // <Col lg={12}>
                //     <ProductAdd
                //     item={item}
                //       index={index}
                //       itemArray={this.state.itemArray}
                //       addNewItem={this.addNewItem}
                //       removeItem={this.removeItem}
                //       itemDisplayArray={this.state.itemDisplayArray}
                //       addDisplayArray={this.addDisplayArray}
                //       editSameItem={this.editSameItem}
                //       new_add={this.state.new_add}
                //     />
                //     {/* Add product Component go here */}
                //   </Col>
              
                  <Col lg={12}>
                    <ProductAdd3
                    item={item}
                      index={index}
                      itemArray={this.state.itemArray}
                      addNewItem={this.addNewItem}
                      removeItem={this.removeItem}
                      itemDisplayArray={this.state.itemDisplayArray}
                      addDisplayArray={this.addDisplayArray}
                      
                    />
                    {/* Add product Component go here */}
                  </Col>
                // </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              onClick={this.getItemDetails}
              // disabled={this.state.quantity_error}
            >
              Add Item
            </Button>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ItemAdd;
