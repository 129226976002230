import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import user_icon from "../../../asset/Images/user_icon.svg";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import searchIcon from "../../../asset/Images/search-black.svg";
import infoIconGrey from "../../../asset/Images/infoIconGrey.svg";
import deleteIcon from "../../../asset/Images/delete_new_icon.svg";
import deleteIconWhite from "../../../asset/Images/deleteIconWhite.svg";
import tooltipArrowIcon from "../../../asset/Images/tooltipArrowIcon.svg";
import crossBlue from "../../../asset/Images/crossBlue.svg";
import downloadIcon from "../../../asset/Images/downloadIconNew.svg";
import alertMsg from "../../../asset/Images/danger_alert.svg";
import viewBtn from "../../../asset/Images/viewBtn.svg";
import callIcon from "../../../asset/Images/callIcon.svg";
import callIconBlue from "../../../asset/Images/callIconBlue.svg";
import backArrowNew from "../../../asset/Images/backArrowNew.svg";
import backArrowInactive from "../../../asset/Images/backArrowInactive.svg";
import edit from "../../../asset/Images/edit.svg";
import CloseButton from "../../../asset/Images/CloseButton.svg";
import editDot from "../../../asset/Images/editDot.svg";
import editIconWhite from "../../../asset/Images/editIconWhite.svg";
import plusIcon from "../../../asset/Images/plusIcon.svg";
import RightArrowBlue from  "../../../asset/Images/RightArrowBlue.svg"
import axios from "axios";
import Constants from "../../../Config";
import { Spinner } from "react-bootstrap";
import CallAttemptedHistory from "./CallAttemptedHistory";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useHistory, Link, Redirect } from "react-router-dom";
import ErrorHandling from "../../ErrorHandling/ShowMessage";

const apiUrl = Constants.API_URL;
const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

const EditRfqMobileView = (props) => {
    const { 
        editRfqData, 
        locationValue,
        isCallingLog,
        isCalling,
        handleCallType,
        handleLogCall,
        toggleDropdownCallStatus,
        selectedValue,
        isOpenCallStatus,
        callStatusArray,
        handleSelect, 
        isShowRight,
        startDate,
        minTime,
        openDate,
        setOpenDate,
        callBackMandate,
        handleDateChange,
        callAttemptHistory,
        rfq_status,
        remarks,
        handleChangeRemarks,
        openIndex,
        showCallHistory,
        getLocation,
        setShowDropdown,
        showDropdown,
        locationList,
        handleSelectLocation,
        openCreateSRDetail,
        getRedirectUrl,
        getSourcingRfqStatus,
        disabledButton,
        handleEditSRAndCreateSource,
        submitLoading,
        changeRFQ,
        isSaveLoading,
        products,
        isDisabledEditRfq,
        toggleDropdown,
        toggleBlur,
        isOpen,
        handleProductChange,
        handleTextAreaChange,
        setActiveDropdownIndex,
        suggestions,
        handleSuggestionClick,
        handleTextAreaCategory,
        categorySuggestions,
        handleCategoryClick,
        handleTextAreaOther,
        isImageLoading,
        imageIndex,
        onFileChange,
        handleTextChange,
        removeItem,
        activeDropdownIndex,
        setProducts,
        handleSubmitForm,
        editProductData,
        editableProduct,
        handleTextAreaChange1,
        handleSuggestionClick1,
        handleTextChange1,
        handleProductChange1,
        handleTextAreaCategory1,
        handleCategoryClick1,
        handleTextAreaOther1,
        onFileChange1,
        setEditProductData,
        setEditableProduct
    } = props;

    const [customerInfoModal, setCustomerInfoModal] = useState(false);
    const [accountPlansData, setAccountPlansData] = useState([]);
    const [showCreditFinance, setShowCredditFinance] = useState(false);
    const [callAttemptModal, setCallAttemptModal] = useState(false);
    const [transferDatalist, setTransferDataList] = useState([]);
    const [selectedTransferValue, setSelectedTranferValue] = useState("");
    const [selectedTransferId, setSelectedTranferId] = useState("");
    const [openTransferStatus, setOpenTransferStatus] = useState(false);
    const [deliveryLocationModal, setDeliveryLocationModal] = useState(false);
    const [openAddItemModal, setOpenAddItemModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openModalIndex, setOpenModalIndex] = useState(null);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    // const [editProductData, setEditProductData] = useState(null);
    const [showDropdownList, setShowDropdownList] = useState(false);
    
    useEffect(() => {
        getAllQuoteUsers();
        if(editRfqData?.accountId) {
            fetchAccountPlansData(editRfqData?.accountId);
          }
     },[editRfqData?.accountId]);

    const handleOpenCustomerModal = () => {
        setCustomerInfoModal(true);
    };

    const handleCloseCustomerModal = () => {
        setCustomerInfoModal(false);
    }

    const openCreditDropdown = () => {
        setShowCredditFinance(true);
    }

    const closeCreditDropdown = () => {
        setShowCredditFinance(false);
    }

    const handleOpenCallAttemptModal = () => {
        setCallAttemptModal(true);
    }

    const handleCloseCallAttemptModal = () => {
        setCallAttemptModal(false);
    }

    const checkValidationOnCloseCall = () => {
      if(selectedValue === ""){
        setErrorMessage("Please select call status")
      } else if (selectedValue === "Call Back Scheduled" && startDate === "") {
        setErrorMessage("Please select call back") 
      }
      else if (remarks === ""){
        setErrorMessage("Please enter remarks");
      }else {
        handleCloseCallAttemptModal();
      }
      setTimeout(() => setErrorMessage(""),3000);
    }

    const handleOpenDeliveyLocationModal = () => {
        setDeliveryLocationModal(true);
    }

    const handleCloseDeliveryLocationModal = () => {
        setDeliveryLocationModal(false);
    }

    const fetchAccountPlansData = async (accountID) => {
        try {
          const response = await axios.post(
            apiUrl + "/get-account-plans",
            { account_id: Number(accountID) },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${loginedUserDetails?.token}`,
              },
            }
          );
          setAccountPlansData(response.data.data);
        } catch (error) {
          console.error("Error fetching:", error.response?.data?.message);
        }
      };


  const handleCallModal = () => {
    handleOpenCallAttemptModal();
    handleCallType();
  }

 const handleLogCallModal = () => {
    handleOpenCallAttemptModal();
    handleLogCall();
 }     

const handleOpenAddItem = () => {
 setOpenAddItemModal(true);
 setProducts((prevProducts) => [
  ...prevProducts,
  {
    type: "product",
    name: "",
    quantity: "",
    brand: "",
    quantity_type: "",
    price: "",
    url: "",
    showProducts: false, // Ensure it's hidden until confirmed
    showCards: false
  },
]);
 setOpenModalIndex(products.length);
}

 const handleCloseAddItem = () => {
 setOpenAddItemModal(false);
 }

  const getAllQuoteUsers = async () => {
     const options = {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${loginedUserDetails?.token}`,
       },
       data: {
         agent_list: "",
         id: [56],
         showAll: "true",
         slug: "quote-requests",
       },
     };
     return await axios(apiUrl + "/get-pre-sales-agents", options)
       .then((data) => {
         setTransferDataList(data.data.data);
       })
       .catch((error) => console.log(error));
   };

   const handleSelectTransfer = (item) => {
    setSelectedTranferValue(item.name);
    setSelectedTranferId(item.id);
    setOpenTransferStatus(false);
   }

   const toggleDropdownTransfer = () => {
    setOpenTransferStatus(!openTransferStatus);
   }

  const selectDeliveryLocation = (item) => {
    handleSelectLocation(item);
    handleCloseDeliveryLocationModal();
  }

  const handleHideProducts = (index) => {
    // setOpenProductModal(false);
    handleCloseAddItem();
    setOpenModalIndex(null); // Reset index value
    setEditableProduct(false);
// new
    setProducts((prevProducts) => {
      const product = prevProducts[index];
      if (product.type === "attachments") {
        return prevProducts; // Return all products as is, without any removal
      }
      // Check if any required field is empty
      if (!product?.productName || !product?.brand || !product?.productQuantity || !product?.quantity_type || !product.showCards) {
        return prevProducts.filter((_, i) => i !== index); // Remove item if any field is empty
      }
      
      return prevProducts; // Otherwise, return the same list
    })
  }

  const handleOpenDotIcon = (index) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? { ...product, showProducts: true }
          : product
      )
    );
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const editProducts = (index) => {
    // setOpenProductModal(true);
    setOpenAddItemModal(true);
    setOpenModalIndex(index);
    setEditProductData(products[index]);
    setOpenDropdownIndex(false);
    setEditableProduct(true);
  }

  const validateProducts = () => {
    let errorMessage = ""; // Initialize error message

    const isValid = products.every((item) => {
      switch (item.type) {
        case "attachments":
          if (!item.productName?.trim()) {
            errorMessage = "Please upload Attachment.";
            return false;
          }
          return true;

        case "product":
          if (!item.productName?.trim()) {
            errorMessage = "Name is required.";
            return false;
          }
          if (!item.productQuantity) {
            errorMessage = "Quantity is required.";
            return false;
          }
          if (item.productQuantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
            return false;
          }
          return true;

        case "category":
        case "other":
          if (!item.productName?.trim()) {
            errorMessage = "Name is required.";
            return false;
          }
          if (!item.brand?.trim()) {
            errorMessage = "Brand is required.";
            return false;
          }
          if (!item.productQuantity) {
            errorMessage = "Quantity is required.";
            return false;
          }
          if (item.productQuantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
            return false;
          }
          if (!item.quantity_type) {
            errorMessage = "Unit type is required.";
            return false;
          }
          return true;

        default:
          errorMessage = "Invalid item type.";
          return false;
      }
    });

    if (!isValid) {
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(""), 3000);
    }

    return isValid;
  };

  const validateProduct1 = () => {
    let errorMessage = ""; // Initialize error message
    if (!editProductData || typeof editProductData !== "object") {
      errorMessage = "Invalid product data.";
    } else {
      switch (editProductData.type) {
        case "attachments":
          if (!editProductData.productName?.trim()) {
            errorMessage = "Please upload Attachment.";
          }
          break;
  
        case "product":
          if (!editProductData.productName?.trim()) {
            errorMessage = "Name is required....";
          } else if (!editProductData.productQuantity) {
            errorMessage = "Quantity is required.";
          } else if (editProductData.productQuantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
          }
          break;
  
        case "category":
        case "other":
          if (!editProductData.productName?.trim()) {
            errorMessage = "Name is required.";
          } else if (!editProductData.brand?.trim()) {
            errorMessage = "Brand is required.";
          } else if (!editProductData.productQuantity) {
            errorMessage = "Quantity is required.";
          } else if (editProductData.productQuantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
          } else if (!editProductData.quantity_type) {
            errorMessage = "Unit type is required.";
          }
          break;
  
        default:
          errorMessage = "Invalid product type.";
      }
    }
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(""), 3000);
      return false;
    }
    return true;
  };
  
  const handleAddItemClose = (index) => {
    const enteredValue = products[index]?.productName?.trim();
    const dataValue = products[index]?.type;
    if (dataValue === "product") {
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = suggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()
      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""),3000);
        return;
      }
    }
    if (dataValue === "category") {
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = categorySuggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()

      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""),3000);
        return;
      }
    }
    if (!validateProducts()) {
      return; // Stop execution if validation fails
    }
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? { ...product, showCards: true }
          : product
      )
    );
    setOpenAddItemModal(false); // Close the modal if validation passes
    // setEditableProduct(true);
    setEditableProduct(false);
  };

  const handleAddItemClose1 = (index) => {
    const enteredValue = editProductData?.productName?.trim();
    const dataValue = editProductData?.type;
    if (dataValue === "product" && suggestions && suggestions.length) {
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = suggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()
      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name`);
        setTimeout(() => setErrorMessage(""),3000);
        return;
      }
    }
    if (dataValue === "category" && categorySuggestions && categorySuggestions.length) {
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = categorySuggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()

      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""),3000);
        return;
      }
    }
    if (!validateProduct1()) {
      return; // Stop execution if validation fails
    }
    let prods = { ...editProductData, showCards: true }
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? { 
            ...prods              
            }
          : product
      )
    );
    setOpenAddItemModal(false); // Close the modal if validation passes
    // setEditableProduct(true);
    setEditableProduct(false);
    setEditProductData({});
  };

  return (
    <>
      <section className="editRfqMobileCnt">
        <p className="customer_name">{editRfqData.customer_name}</p>
        <h2 className="pageSubHeading mb-3">{editRfqData.accountName}</h2>
        <div className="customerInfoCnt">
          <div className="customerInfoItem">
            <div className="traInputWrapper">
              <label htmlFor="name">Customer Mobile</label>
              <input
                type="number"
                id="name"
                placeholder=""
                value={editRfqData.customer_mobile}
                disabled
                className="disabled"
              />
              <div className="callLognBtnCntMobile">
                <div className="calLogBtnWrapper">
                  <button
                    type="button"
                    className="item"
                    onClick={handleCallModal}
                    value="Knowlarity"
                >
                    {isCalling ? (
                    <Spinner animation="border" size="sm" />
                    ) : (
                    <span>Call</span>
                    )}
                </button>
                <button
                    type="button"
                    className="item"
                    onClick={handleLogCallModal}
                    value="Manual"
                    >
                    {isCallingLog ? (
                        <Spinner animation="border" size="sm" />
                    ) : (
                        <span>Log a Call</span>
                    )}
                    </button>  
                </div>
              </div>
            </div>
          </div>
          <div className="customerInfoItem">
            <div className="traInputWrapper">
              <label htmlFor="name">Delivery Location</label>
              <input
                type="text"
                id="name"
                placeholder=""
                style={{ paddingRight:"40px" }}
                value={locationValue}
                onClick={handleOpenDeliveyLocationModal}
                autoComplete="off"
                disabled={isDisabledEditRfq}
              />
              <div className="traInputIconWrapper searchIcon">
                <img src={searchIcon} width="20px" height="20px" />
              </div>
            </div>
          </div>
          <button onClick={handleOpenCustomerModal} type="button" className="moreInfoBtn">
            More Info{" "}
            <img
              width="12px"
              height="12px"
              src={RightArrowBlue}
              alt="rightBlu"
            />
          </button>
        </div>
      </section>

      {/* customer info modal start here */}
      <Modal
        className="modal-p-bottom addRfqModal"
        show={customerInfoModal}
        onHide={handleCloseCustomerModal}
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="pageSubHeading">Customer Info</Modal.Title>
          <button className="modalCloseBtn" onClick={handleCloseCustomerModal}>
            <img
              src={CloseButton}
              width="24px"
              height="24px"
              alt="cross icon"
            />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modalAddItemsList">
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Customer Account</label>
                <input
                  type="text"
                  id="name"
                  placeholder=""
                  disabled
                  value={editRfqData.accountName}
                  // className="disabled"
                />
                <div className="traInputIconWrapper">
                  <img src={user_icon} width="20px" height="20px" />
                </div>
              </div>
            </div>
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Customer Name</label>
                <input
                  type="text"
                  id="name"
                  placeholder=""
                  value={editRfqData.customer_name}
                  disabled
                  // className="disabled"
                />
              </div>
            </div>
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Customer Email</label>
                <input
                  type="email"
                  id="name"
                  placeholder=""
                  disabled
                  value={editRfqData.customer_email}
                  // className="disabled"
                />
              </div>
            </div>
            <div className="customerEmailCheck customerInfoItem">
              <input
                className="customerCheckLabelInput"
                id="customerCheck"
                type="checkbox"
              />
              <label className="customerCheckLabel" for="customerCheck">
                Customer doesn't have an email
              </label>
            </div>
            <div className="customerInfoItem infoIconCnt">
              <div className="customeSelectCnt">
                <button
                  className="customSelectBtn disabled"
                  style={{ cursor: "default" }}
                >
                  <label htmlFor="name">Credit Finance</label>
                  <span className="selectedValue">
                    {Array.isArray(accountPlansData) && accountPlansData.length > 0 
                      ? `${accountPlansData.length}` 
                      : "No"} Options Available
                  </span>
                  <div
                    className="customSelectBtnIcon downArrowIcon"
                    onClick={openCreditDropdown}
                    style={{ cursor: "pointer" }}
                  >
                    {Array.isArray(accountPlansData) && accountPlansData.length > 0 ?
                    <img src={infoIconGrey} width="20px" height="20px" /> : null}
                  </div>
                </button>
                {accountPlansData && accountPlansData.length ? (
                <div
                  className={`creditFinaceDtl ${
                    showCreditFinance ? "" : "d-none"
                  }`}
                >
                  <div className="closeDropDown" onClick={closeCreditDropdown}>
                    <img
                      src={crossBlue}
                      width="16px"
                      height="16px"
                      alt="cross icon"
                    />
                  </div>
                  <ul className="creditFinaceItemWrapper">
                    <li>Name</li>
                    <li>Rate</li>
                    <li>Amount</li>
                  </ul>
                  <hr className="hr-1 my-2" />
                  {accountPlansData.map((accountPlan) => (
                  <ul key={accountPlan.partner_id} className="creditFinaceItemWrapper creditFinaceValueWrapper">
                    <li>{accountPlan.partner_name} ({accountPlan.plan_days} Days)</li>
                    <li>{accountPlan.plan_interest} %</li>
                    <li>₹{Number(accountPlan.credit_limit).toLocaleString('en-IN')}</li>
                  </ul>
                  ))}
                </div>
                ):null}
              </div>
            </div>
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Agent</label>
                <input 
                  type="text" 
                  id="name" 
                  placeholder="" 
                  disabled 
                  value={editRfqData.assigned_to}
                  className="disabled" 
              />
              </div>
            </div>
            <div className="customerInfoItem customeSelectCnt">
              <div className="customeSelectCnt">
                <button
                  className={`customSelectBtn disabled`}
                  // onClick={toggleDropdown}
                >
                  <label htmlFor="name">Customer Role</label>
                  <span className="selectedValue">{editRfqData.role}</span>
                  <div className="customSelectBtnIcon downArrowIcon">
                    <img src={downArrowGrey} width="12px" height="12px" />
                  </div>
                </button>
              </div>
            </div>
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Customer Message</label>
                <input 
                  type="text" 
                  id="name" 
                  placeholder="" 
                  disabled 
                  value={editRfqData.message}
                  className="disabled" 
                />
              </div>
            </div>
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Support Remarks</label>
                <input 
                  type="text" 
                  id="name" 
                  placeholder="" 
                  value={editRfqData.remarks} 
                  disabled 
                  className="disabled"
              />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* customer info modal ends here */}

      {/* remarks on call attempt start here */}
          <Modal
          className="modal-p-bottom addRfqModal callReamrkModal"
          show={callAttemptModal}
          onHide={handleCloseCallAttemptModal}
          backdrop="static" 
          keyboard={false}
          >
          <Modal.Header>
              <Modal.Title className="pageSubHeading">Remarks on Call Attempt</Modal.Title>
              <button className="modalCloseBtn" onClick={handleCloseCallAttemptModal}>
              <img
                  src={CloseButton}
                  width="24px"
                  height="24px"
                  alt="cross icon"

              />
              </button>
          </Modal.Header>

          <Modal.Body>
              <div className="modalAddItemsList">
              <div className="callReamrkItem">
                  <div className="customeSelectCnt callReamrkDropCnt">
                  <button
                      className="customSelectBtn"
                      onClick={toggleDropdownCallStatus}
                  >
                      <label htmlFor="name">Call Status</label>
                      <span className="selectedValue">{selectedValue}</span>
                      <div className="customSelectBtnIcon downArrowIcon">
                          <img
                          src={downArrowGrey}
                          width="12px"
                          height="12px"
                          alt="Dropdown Icon"
                          />
                      </div>
                      </button>

                      {isOpenCallStatus && callStatusArray.length > 0 && (
                      <div className="customDropDownCnt show">
                          <ul className="dropdownWrapperScroll">
                          {callStatusArray.map((item, index) => (
                              <li
                              key={index}
                              className="dropdownItem"
                              onClick={() => handleSelect(item)}
                              >
                              {item.status}
                                <hr className="dashed-border"/>
                              </li>
                          ))}
                          </ul>
                      </div>
                      )}
                  </div>
              </div>
              
              {isShowRight ? (
              <div className="callReamrkItem">
                  <div className="traInputWrapper">
                  <label htmlFor="name">Call Back</label>
                  <DatePicker
                      showTimeSelect
                      placeholder=""
                      dateFormat="MMMM d, yyyy h:mm:ss aa"
                      selected={startDate}
                      onChange={handleDateChange}
                      className="form-control"
                      locale="es"
                      minDate={new Date()}
                      minTime={minTime}
                      maxTime={moment().endOf("day").toDate()}
                      value={startDate ? startDate : "Select"}
                      openDate={openDate} // Control dropdown visibility
                      onClickOutside={() => setOpenDate(false)} // Close when clicking outside
                      onFocus={() => setOpenDate(true)} // Open when focusing on the input
                      required={callBackMandate}
                      disabled={
                      !["Call Back Scheduled"].includes(selectedValue)
                      }
                  />
                  {callAttemptHistory.length > 0 ? (
                      <CallAttemptedHistory
                      callAttemptHistory={callAttemptHistory}
                      />
                  ) : null}
                  </div>
              </div>
              ) : null}
              
              <div className="callReamrkItem">
                  <div className="customeSelectCnt callReamrkDropCnt">
                      <button
                      className={`customSelectBtn ${
                          selectedValue && "disabled"
                      }`}
                      >
                      <label htmlFor="name">RFQ Status</label>
                      <span className="selectedValue">{rfq_status}</span>
                      </button>
                      <div className={`customDropDownCnt`}></div>
                  </div>
              </div>

              <div className="callReamrkItem">
                      <div className="traInputWrapper">
                        <label htmlFor="name">Remarks</label>
                        <input
                          type="text"
                          name="remarks"
                          placeholder=""
                          value={remarks}
                          onChange={handleChangeRemarks}
                        />
                      </div>
              </div>

              {/* <div className="callReamrkItem">
                  <div className="customeSelectCnt callReamrkDropCnt">
                  <button
                      className="customSelectBtn"
                      onClick={toggleDropdownTransfer}
                  >
                      <label htmlFor="name">Transfer Account</label>
                      <span className="selectedValue">{selectedTransferValue}</span>
                      <div className="customSelectBtnIcon downArrowIcon">
                          <img
                          src={downArrowGrey}
                          width="12px"
                          height="12px"
                          alt="Dropdown Icon"
                          />
                      </div>
                      </button>

                      {openTransferStatus && transferDatalist.length > 0 && (
                      <div className="customDropDownCnt show">
                          <ul className="dropdownWrapperScroll">
                          {transferDatalist.map((item) => (
                              <li
                              key={item.id}
                              className="dropdownItem"
                              onClick={() => handleSelectTransfer(item)}
                              >
                              {item.name}
                              </li>
                          ))}
                          </ul>
                      </div>
                      )}
                  </div>
              </div> */}

              {callAttemptHistory.length > 0 ? (
                <div className="callHistoryCnt">
                  <h2 className="pageSubHeading">Call History</h2>
                  <div className="callHistoryAccordionCnt">
                    {callAttemptHistory.map((item, index) => (
                      <div
                        key={index}
                        className={`callHistoryAccordionItem ${
                          openIndex === index ? "show" : ""
                        }`}
                      >
                        <button
                          className="callAccordionBtn"
                          onClick={() => showCallHistory(index)}
                        >
                          <span>Call {item.call_attempt}</span>
                          <img
                            src={backArrowInactive}
                            width="12px"
                            height="12px"
                            alt="arrow icon"
                            className={`inactiveIcon ${
                              openIndex === index ? "hidden" : ""
                            }`}
                          />
                          <img
                            src={backArrowNew}
                            width="12px"
                            height="12px"
                            alt="arrow icon"
                            className={`activeIcon ${
                              openIndex === index ? "show" : "hidden"
                            }`}
                          />
                        </button>
                        {openIndex === index && (
                          <div className="callAccordionBody">
                            <ul className="callHistoryWrapper">
                              <li>
                                <span>Call {item.call_attempt}</span>
                                <p>{item.agent_note}</p>
                              </li>
                              <li>
                                <span>Status</span>
                                <p>{item.call_status}</p>
                              </li>
                              <li>
                                <span>By</span>
                                <p>
                                  {item.firstname} {item.lastname}
                                </p>
                              </li>
                              <li>
                                <span>on</span>
                                <p>{moment.utc(item.created_at).format("Do MMM, YYYY HH:mm")}</p>
                              </li>
                              <li>
                                <span>Calling Mode</span>
                                <p>{item.calling_mode}</p>
                              </li>
                              <li>
                                <span>Follow up on</span>
                                <p>{item.followup_date}</p>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              </div>
              <div className="rfqButtonWrapper">
              <button
                  type="button"
                  className="rfqCommonBtn bgBlue"
                  onClick={checkValidationOnCloseCall}
              >
                  Save
              </button>
              </div>
              {errorMessage ? (
                <ErrorHandling message={errorMessage} type={"ErrorMessage"} />
              ) : null}  
          </Modal.Body>
          </Modal>
      {/* remarks on call attempt ends here */}

      {/* delivery location modal start here */}
        <Modal
        className="modal-p-bottom addRfqModal callReamrkModal deliveryLocationModal"
        show={deliveryLocationModal}
        onHide={handleCloseDeliveryLocationModal}
        backdrop="static" 
        keyboard={false}
        >
        <Modal.Header>
            <Modal.Title className="pageSubHeading">Delivery Location</Modal.Title>
            <button className="modalCloseBtn" onClick={handleCloseDeliveryLocationModal}>
            <img
                src={CloseButton}
                width="24px"
                height="24px"
                alt="cross icon"

            />
            </button>
        </Modal.Header>

        <Modal.Body>
        {/* <div className="traInputWrapper">
                <label htmlFor="name">Lajpat Nagar</label>
                <input className="forDeliveryInput" type="text" id="name" placeholder="" />
                <div className="traInputIconWrapper searchIcon">
                <img src={searchIcon} width="20px" height="20px" />
                </div>
            </div>
            <hr className="locationSeprator"/>
            <div className="modalAddItemsList">
            
            <div className="deliveryLocationList">
                <ul>
                    <li>Lajpat Nagar Metro Station</li>
                    <li>Lajpat Nagar 1</li>
                    <li>Lajpat Nagar 2</li>
                    <li>Lajpat Nagar 3</li>
                    <li>Lajpat Nagar 4</li>
                </ul>
            </div>
            </div> */}
            <div className="traInputWrapper">
            <input
            type="text"
            id="name"
            placeholder=""
            className="forDeliveryInput"
            value={locationValue}
            onClick={handleOpenDeliveyLocationModal}
            onChange={getLocation}
            onBlur={() =>
                setTimeout(() => setShowDropdown(false), 200)
            } // Delay to allow click selection
            autoFocus
            autoComplete="off"
            />
                <div className="traInputIconWrapper searchIcon">
                    <img src={searchIcon} width="20px" height="20px" />
                </div>
            </div>
            <hr className="locationSeprator"/>
            {showDropdown && locationList.length > 0 && (
              <div className="modalAddItemsList">   
            <div className="deliveryLocationList">
                <ul>
                {locationList.map((loc, index) => (
                    <li
                    key={index}
                    onMouseDown={() => selectDeliveryLocation(loc)} // Prevent blur from closing dropdown
                    >
                    {loc.name}, {loc.state_name}
                    </li>
                ))}
                </ul>
            </div>
            </div>
            )}
        </Modal.Body>
        </Modal>
      {/* delivery location modal ends here */}

      <section className="requirementDtlMobileCnt">
        <h2 className="pageSubHeading mb-3">Requirement Details</h2>
        {products.map((item,index) => (
          <React.Fragment key={index}>
        {/* show cards after data filling start here */}
          {item.showCards  ?  (
            <>
              {item.type != "attachments" ? (
              <div className="requirementDtlCard">
                <div className="catalogueEditCnt">
                  <p className="catalogueWrapper">
                    {
                      item.type === "product"
                        ? "Catalogue"
                        : item.type === "category"
                        ? "Category"
                        : item.type === "other"
                        ? "Other"
                        : item.type === "attachments"
                        ? "Attachment"
                        : item.type
                    }
                  </p>
                  {isDisabledEditRfq ? null : (
                  <div className="editRemoveBtnCnt">
                    <button className="editRemoveBtn">
                      <img
                        src={editDot}
                        alt="dot icon"
                        width="24px"
                        height="24px"
                        onClick={() => handleOpenDotIcon(index)}
                      />
                    </button>
                    
                    {openDropdownIndex === index && item.showProducts ? (
                    <div className="editRemoveDropdown show">
                      <img
                        src={tooltipArrowIcon}
                        width="16px"
                        height="10px"
                        alt="arrow icon"
                        className="arrowIconTooltip"
                      />
                      <ul className="editRemoveWrapper">
                        <li onClick={() => editProducts(index)}>
                          <img
                            src={editIconWhite}
                            width="16px"
                            height="16ox"
                            alt="edit icon"
                          />
                          <span>Edit</span>
                        </li>
                        {products.length > 1  ? (
                        <li onClick={() => removeItem(index)}>
                          <img
                            src={deleteIconWhite}                 
                            width="16px"
                            height="16ox"
                            alt="edit icon"
                          />
                          <span>Remove</span>
                        </li>
                        ):null}
                      </ul>
                    </div>
                    ):null}
                  </div>
                  )}
                </div>
                <p className="prodDisc">{item.productName}</p>
                {(item.type === "product" || item.type === "category") && item.link ? (
                <a href={item.link} target="_blank" className="view_Btn">
                  <img
                    src={viewBtn}
                    width="16px"
                    height="16px"
                    alt="redirect icon"
                  />
                  <span>View</span>
                </a>
                ):null}
                <div className="customerInfoNonEditable_mobile">
                  <div className="customerInfoItem w-100">
                    <div className="nonEditableInputWrapper">
                      <label htmlFor="name">Brand</label>
                      <input type="button" id="name" value={item.brand} />
                    </div>
                  </div>
                </div>
                <div className="customerInfoNonEditable_mobile">
                  <div className="customerInfoItem">
                    <div className="nonEditableInputWrapper">
                      <label htmlFor="name">Quantity</label>
                      <input type="button" id="name" value={item.productQuantity} />
                    </div>
                  </div>
                  <div className="customerInfoItem">
                    <div className="nonEditableInputWrapper">
                      <label htmlFor="name">Unit</label>
                      <input type="button" id="name" value={item.quantity_type} />
                    </div>
                  </div>
                  <div className="customerInfoItem">
                    <div className="nonEditableInputWrapper">
                      <label htmlFor="name">Price</label>
                      <input type="button" id="name" value={item.productPrice} />
                    </div>
                  </div>
                </div>
              </div>
              ):null}
              {item.type === "attachments" ? (
              <div className="requirementDtlCard">
                <div className="catalogueEditCnt">
                  <p className="catalogueWrapper">Attachment</p>
                  {isDisabledEditRfq ? null : (
                  <div className="editRemoveBtnCnt">
                    <button className="editRemoveBtn">
                      <img
                        src={editDot}
                        alt="dot icon"
                        width="24px"
                        height="24px"
                        onClick={() => handleOpenDotIcon(index)}
                      />
                    </button>
                    {openDropdownIndex === index && item.showProducts  ? (
                    <div className="editRemoveDropdown show">
                      <img
                        src={tooltipArrowIcon}
                        width="16px"
                        height="10px"
                        alt="arrow icon"
                        className="arrowIconTooltip"
                      />
                      <ul className="editRemoveWrapper">
                        <li onClick={() => editProducts(index)}>
                          <img
                            src={editIconWhite}
                            width="16px"
                            height="16ox"
                            alt="edit icon"
                          />
                          <span>Edit</span>
                        </li>
                        {products.length > 1 ? (
                        <li onClick={() => removeItem(index)}>
                          <img
                            src={deleteIconWhite}
                            width="16px"
                            height="16ox"
                            alt="edit icon"
                          />
                          <span>Remove</span>
                        </li>
                        ):null}
                      </ul>
                    </div>
                    ):null}
                  </div>
                  )}
                </div>
                <div>
                  <a href={item.name} className="attachmentBtn">
                    Attachment
                  </a>
                </div>
              </div>
            ):null}
            </>
          ):null}

        {/* modal for products start here */}
            <div>
                  <Modal
                    className="modal-p-bottom addRfqModal"
                    show={openAddItemModal && openModalIndex === index}
                    onHide={handleCloseAddItem}
                    backdrop="static" 
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title className="pageSubHeading">
                      {editableProduct ? "Edit Item" : "Add Item"}
                      </Modal.Title>
                      <button
                        className="modalCloseBtn"
                        // onClick={handleCloseAddItem}
                      >
                        <img
                          src={CloseButton}
                          width="24px"
                          height="24px"
                          alt="cross icon"
                          onClick={()=>handleHideProducts(index)}
                          // onClick={handleCloseAddItem}
                        />
                      </button>
                    </Modal.Header>
                    {editableProduct ? (
                    <Modal.Body>
                      {/* change view start here */}
                    {editProductData.type === "product" ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {editProductData.type === "product"
                                  ? "Catalogue"
                                  : editProductData.type === "category"
                                  ? "Category"
                                  : editProductData.type === "other"
                                  ? "Other"
                                  : editProductData.type === "attachments"
                                  ? "Attachment"
                                  : editProductData.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}
                              style={{position:"static",marginTop:"8px"}}
                              >
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange1(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      <div className="customerInfoItem">
                      <div className="traInputWrapper">
                      <label htmlFor="name">Name</label>
                        <input
                          // className="textEditableInput"
                          placeholder=""
                          value={editProductData.productName}
                          onChange={(e) =>
                            handleTextAreaChange1(e)
                          }
                          onFocus={() => {
                            setActiveDropdownIndex(index);
                            setShowDropdownList(true);
                          }}
                          onBlur={() => setShowDropdownList(false)}
                        />
                        {suggestions.length > 0 &&
                          item.showProducts &&
                          showDropdownList &&
                          activeDropdownIndex === index && (
                            <div
                              className="customDropDownCnt show"
                              style={{position:"static",marginTop:"8px"}}
                              key={index}
                            >
                              <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                                {suggestions.map(
                                  (suggestion, idx) => (
                                    <li
                                      key={idx}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleSuggestionClick1(
                                          suggestion
                                        )
                                      }
                                    >
                                      {suggestion.label}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                      </div>

                      <div className="customerInfoItem">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="text"
                                className={`${
                                  editProductData.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="brand"
                                value={editProductData.brand}
                                onChange={(e) =>
                                  handleTextChange1("brand", e.target.value)
                                }
                                disabled={`${
                                  editProductData.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>
                      </div>

                      <div className="customerInfoItem QuantityUnitPrice">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                placeholder=""
                                type="number"
                                name="productQuantity"
                                value={editProductData.productQuantity}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "productQuantity",
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="text"
                                className={`${
                                  editProductData.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="quantity_type"
                                value={editProductData.quantity_type}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "quantity_type",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  editProductData.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="text"
                                className={`${
                                  editProductData.type === "product" ||
                                  editProductData.type == "category" ||
                                  editProductData.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="productPrice"
                                value={editProductData.productPrice}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "productPrice",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  editProductData.type == "product" ||
                                  editProductData.type == "category" ||
                                  editProductData.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                              />
                            </div>
                      </div>
                      </div>
                      </>
                    ):null}
                      {/* change view ends here */}
                    {editProductData.type === "category" ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {editProductData.type === "product"
                                  ? "Catalogue"
                                  : editProductData.type === "category"
                                  ? "Category"
                                  : editProductData.type === "other"
                                  ? "Other"
                                  : editProductData.type === "attachments"
                                  ? "Attachment"
                                  : editProductData.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}
                              style={{position:"static",marginTop:"8px"}}
                              >
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange1(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      <div className="customerInfoItem">
                      <div className="traInputWrapper">
                      <label htmlFor="name">Name</label>
                        <input
                          // className="textEditableInput"
                          placeholder=""
                          value={editProductData.productName}
                          onChange={(e) =>
                            handleTextAreaCategory1(e, index)
                          }
                          onFocus={() => {
                            setActiveDropdownIndex(index);
                            setShowDropdownList(true);
                          }}
                          onBlur={() => setShowDropdownList(false)}
                        />
                        {categorySuggestions.length > 0 &&
                          item.showProducts &&
                          showDropdownList &&
                          activeDropdownIndex === index && (
                            <div
                              className="customDropDownCnt show"
                              style={{position:"static",marginTop:"8px"}}
                              key={index}
                            >
                              <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                                {categorySuggestions.map(
                                  (suggestion, idx) => (
                                    <li
                                      key={idx}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleCategoryClick1(
                                          suggestion
                                        )
                                      }
                                    >
                                      {suggestion.label}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                      </div>

                      <div className="customerInfoItem">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="text"
                                className={`enabled_filed_wrapper`}
                                placeholder=""
                                name="brand"
                                value={editProductData.brand}
                                onChange={(e) =>
                                  handleTextChange1("brand", e.target.value)
                                }
                                autoComplete="off"
                              />
                            </div>
                      </div>

                      <div className="customerInfoItem QuantityUnitPrice">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                placeholder=""
                                type="number"
                                name="productQuantity"
                                value={editProductData.productQuantity}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "productQuantity",
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="text"
                                className={`enabled_filed_wrapper`}
                                placeholder=""
                                name="quantity_type"
                                value={editProductData.quantity_type}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "quantity_type",
                                    e.target.value
                                  )
                                }
                               
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="text"
                                className={`${
                                  editProductData.type === "product" ||
                                  editProductData.type == "category" ||
                                  editProductData.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="productPrice"
                                value={editProductData.productPrice}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "productPrice",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  editProductData.type == "product" ||
                                  editProductData.type == "category" ||
                                  editProductData.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                              />
                            </div>
                      </div>
                      </div>
                      </>
                    ):null}

                    {editProductData.type === "other" ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {editProductData.type === "product"
                                  ? "Catalogue"
                                  : editProductData.type === "category"
                                  ? "Category"
                                  : editProductData.type === "other"
                                  ? "Other"
                                  : editProductData.type === "attachments"
                                  ? "Attachment"
                                  : editProductData.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}
                              style={{position:"static",marginTop:"8px"}}
                              >
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange1(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      <div className="customerInfoItem">
                      <div className="traInputWrapper">
                      <label htmlFor="name">Name</label>
                        <input
                          // className="textEditableInput"
                          placeholder=""
                          value={editProductData.productName}
                          onChange={(e) =>
                            handleTextAreaOther1(e)
                          }
                          onFocus={() =>
                            setActiveDropdownIndex(index)
                          }
                        />
                      </div>
                      </div>

                      <div className="customerInfoItem">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="text"
                                className={"enabled_filed_wrapper"}
                                placeholder=""
                                name="brand"
                                value={editProductData.brand}
                                onChange={(e) =>
                                  handleTextChange1("brand", e.target.value)
                                }
                                autoComplete="off"
                              />
                            </div>
                      </div>

                      <div className="customerInfoItem QuantityUnitPrice">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                placeholder=""
                                type="number"
                                name="productQuantity"
                                value={editProductData.productQuantity}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "productQuantity",
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="text"
                                className={"enabled_filed_wrapper"}
                                placeholder=""
                                name="quantity_type"
                                value={editProductData.quantity_type}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "quantity_type",
                                    e.target.value
                                  )
                                }
                              
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="text"
                                className={`${
                                  editProductData.type === "product" ||
                                  editProductData.type == "category" ||
                                  editProductData.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="productPrice"
                                value={editProductData.productPrice}
                                onChange={(e) =>
                                  handleTextChange1(
                                    "productPrice",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  editProductData.type == "product" ||
                                  editProductData.type == "category" ||
                                  editProductData.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                              />
                            </div>
                      </div>
                      </div>
                      </>
                    ):null}

                    {editProductData.type === "attachments" ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {editProductData.type === "product"
                                  ? "Catalogue"
                                  : editProductData.type === "category"
                                  ? "Category"
                                  : editProductData.type === "other"
                                  ? "Other"
                                  : editProductData.type === "attachments"
                                  ? "Attachment"
                                  : editProductData.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}
                              style={{position:"static",marginTop:"8px"}}
                              >
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange1(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      {editProductData.productName ? (
                         <div className="uploadFileChoosenWrapper">
                          <div className="uploadFileChoosen">
                            <a
                              className="pl-0 attachmenttext"
                              href={editableProduct.productName}
                              target="_blank"
                            >
                              Attachment
                            </a>
                          </div>
                          </div>
                        ) : (
                          <>
                            {isImageLoading &&
                            imageIndex === index ? (
                              <Spinner
                                animation="border"
                                role="status"
                              >
                                <span className="sr-only">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : (
                              <>
                              <div className="uploadFileChoosen">
                                <label
                                  for="downloadIcon"
                                  className="upload_title"
                                >
                                  <div className="uploadFilebg">
                                    <img
                                      src={downloadIcon}
                                      width="16px"
                                      height="16px"
                                    />
                                    <span className="uploadFileTitle">
                                      Upload a file
                                    </span>
                                  </div>
                                  <p className="noFileChoosen">
                                    No file choosen
                                  </p>
                                </label>
                                <input
                                  id="downloadIcon"
                                  className="downloadFile"
                                  style={{ display: "none" }}
                                  type="file"
                                  name="attachments"
                                  accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                  onChange={(e) => onFileChange1(e)}
                                />
                                </div>
                              </>
                              
                            )}
                          </>
                        )}
                      </div>
                      </>
                    ):null}

                  <div className="rfqButtonWrapper">
                    <button
                      type="button"
                      onClick={() => handleAddItemClose1(index)}
                      className="rfqCommonBtn bgBlue"
                    >
                      {editableProduct ? "Edit Item" : "Add Item"}
                    </button>
                  </div>
                    </Modal.Body>
                    ) : (
                      <Modal.Body>
                     {/* change view start here */}
                    {item.type === "product" && openModalIndex === index ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {item.type === "product"
                                  ? "Catalogue"
                                  : item.type === "category"
                                  ? "Category"
                                  : item.type === "other"
                                  ? "Other"
                                  : item.type === "attachments"
                                  ? "Attachment"
                                  : item.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}>
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      <div className="customerInfoItem">
                      <div className="traInputWrapper">
                      <label htmlFor="name">Name</label>
                        <input
                          // className="textEditableInput"
                          placeholder=""
                          value={item.productName}
                          onChange={(e) =>
                            handleTextAreaChange(e, index)
                          }
                          // onFocus={() =>
                          //   setActiveDropdownIndex(index)
                          // }
                          onFocus={() => {
                            setActiveDropdownIndex(index);
                            setShowDropdownList(true);
                          }}
                          onBlur={() => setShowDropdownList(false)}
                        />
                        {suggestions.length > 0 &&
                          item.showProducts &&
                          showDropdownList &&
                          activeDropdownIndex === index && (
                            <div
                              className="customDropDownCnt show"
                              key={index}
                            >
                              <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                                {suggestions.map(
                                  (suggestion, idx) => (
                                    <li
                                      key={idx}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleSuggestionClick(
                                          index,
                                          suggestion
                                        )
                                      }
                                    >
                                      {suggestion.label}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                      </div>

                      <div className="customerInfoItem">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="brand"
                                value={item.brand}
                                onChange={(e) =>
                                  handleTextChange(index, "brand", e.target.value)
                                }
                                disabled={`${
                                  item.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>
                      </div>

                      <div className="customerInfoItem QuantityUnitPrice">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                placeholder=""
                                type="number"
                                name="productQuantity"
                                value={item.productQuantity}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "productQuantity",
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="quantity_type"
                                value={item.quantity_type}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "quantity_type",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  item.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product" ||
                                  item.type == "category" ||
                                  item.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="productPrice"
                                value={item.productPrice}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "productPrice",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  item.type == "product" ||
                                  item.type == "category" ||
                                  item.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                              />
                            </div>
                      </div>
                      </div>
                      </>
                    ):null}
                      {/* change view ends here */}
                    {item.type === "category" && openModalIndex === index ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {item.type === "product"
                                  ? "Catalogue"
                                  : item.type === "category"
                                  ? "Category"
                                  : item.type === "other"
                                  ? "Other"
                                  : item.type === "attachments"
                                  ? "Attachment"
                                  : item.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}>
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      <div className="customerInfoItem">
                      <div className="traInputWrapper">
                      <label htmlFor="name">Name</label>
                        <input
                          // className="textEditableInput"
                          placeholder=""
                          value={item.productName}
                          onChange={(e) =>
                            handleTextAreaCategory(e, index)
                          }
                          // onFocus={() =>
                          //   setActiveDropdownIndex(index)
                          // }
                          onFocus={() => {
                            setActiveDropdownIndex(index);
                            setShowDropdownList(true);
                          }}
                          onBlur={() => setShowDropdownList(false)}
                        />
                        {categorySuggestions.length > 0 &&
                          item.showProducts &&
                          showDropdownList &&
                          activeDropdownIndex === index && (
                            <div
                              className="customDropDownCnt show"
                              key={index}
                            >
                              <ul className="dropdownWrapper" onMouseDown={(e) => e.preventDefault()}>
                                {categorySuggestions.map(
                                  (suggestion, idx) => (
                                    <li
                                      key={idx}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleCategoryClick(
                                          index,
                                          suggestion
                                        )
                                      }
                                    >
                                      {suggestion.label}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                      </div>

                      <div className="customerInfoItem">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="brand"
                                value={item.brand}
                                onChange={(e) =>
                                  handleTextChange(index, "brand", e.target.value)
                                }
                                disabled={`${
                                  item.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>
                      </div>

                      <div className="customerInfoItem QuantityUnitPrice">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                placeholder=""
                                type="number"
                                name="productQuantity"
                                value={item.productQuantity}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "productQuantity",
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="quantity_type"
                                value={item.quantity_type}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "quantity_type",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  item.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product" ||
                                  item.type == "category" ||
                                  item.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="productPrice"
                                value={item.productPrice}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "productPrice",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  item.type == "product" ||
                                  item.type == "category" ||
                                  item.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                              />
                            </div>
                      </div>
                      </div>
                      </>
                    ):null}

                    {item.type === "other" && openModalIndex === index ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {item.type === "product"
                                  ? "Catalogue"
                                  : item.type === "category"
                                  ? "Category"
                                  : item.type === "other"
                                  ? "Other"
                                  : item.type === "attachments"
                                  ? "Attachment"
                                  : item.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}>
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      <div className="customerInfoItem">
                      <div className="traInputWrapper">
                      <label htmlFor="name">Name</label>
                        <input
                          // className="textEditableInput"
                          placeholder=""
                          value={item.productName}
                          onChange={(e) =>
                            handleTextAreaOther(e, index)
                          }
                          onFocus={() =>
                            setActiveDropdownIndex(index)
                          }
                        />
                        {suggestions.length > 0 &&
                          item.showProducts &&
                          activeDropdownIndex === index && (
                            <div
                              className="customDropDownCnt show"
                              key={index}
                            >
                              <ul className="dropdownWrapper">
                                {suggestions.map(
                                  (suggestion, idx) => (
                                    <li
                                      key={idx}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleSuggestionClick(
                                          index,
                                          suggestion
                                        )
                                      }
                                    >
                                      {suggestion.label}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                      </div>

                      <div className="customerInfoItem">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="brand"
                                value={item.brand}
                                onChange={(e) =>
                                  handleTextChange(index, "brand", e.target.value)
                                }
                                disabled={`${
                                  item.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>
                      </div>

                      <div className="customerInfoItem QuantityUnitPrice">
                            <div className="traInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                placeholder=""
                                type="number"
                                name="productQuantity"
                                value={item.productQuantity}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "productQuantity",
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="quantity_type"
                                value={item.quantity_type}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "quantity_type",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  item.type == "product"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                            </div>

                            <div className="traInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="text"
                                className={`${
                                  item.type === "product" ||
                                  item.type == "category" ||
                                  item.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : "enabled_filed_wrapper"
                                }`}
                                placeholder=""
                                name="productPrice"
                                value={item.productPrice}
                                onChange={(e) =>
                                  handleTextChange(
                                    index,
                                    "productPrice",
                                    e.target.value
                                  )
                                }
                                disabled={`${
                                  item.type == "product" ||
                                  item.type == "category" ||
                                  item.type == "other"
                                    ? "disbled_filed_wrapper"
                                    : ""
                                }`}
                              />
                            </div>
                      </div>
                      </div>
                      </>
                    ):null}

                    {item.type === "attachments" && openModalIndex === index ? (<>
                      <div className="modalAddItemsList">
                        <div className="customerInfoItem">
                          <div className="customeSelectCntRole customeSelectCnt">
                            <button
                              className="customSelectBtn"
                              onClick={() => toggleDropdown(index)}
                              onBlur={toggleBlur}
                            >
                              <label htmlFor="name">Item Type</label>
                              <span className="selectedValue">
                                {item.type === "product"
                                  ? "Catalogue"
                                  : item.type === "category"
                                  ? "Category"
                                  : item.type === "other"
                                  ? "Other"
                                  : item.type === "attachments"
                                  ? "Attachment"
                                  : item.type}
                              </span>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>
                            {/* Dropdown List using <ul> and <li> */}
                            {isOpen[index] && ( // Check if the specific index is open
                              <div className={`customDropDownCnt ${
                                isOpen ? "show" : ""
                              }`}>
                                {isOpen[index] && (
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) =>
                                    e.preventDefault()
                                  }
                                >
                                  {[
                                    {
                                      label: "Catalogue",
                                      value: "product",
                                    },
                                    {
                                      label: "Category",
                                      value: "category",
                                    },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        handleProductChange(
                                          index,
                                          "type",
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      
                      {item.productName ? (
                         <div className="uploadFileChoosenWrapper">
                          <div className="uploadFileChoosen">
                            <a
                              className="pl-0 attachmenttext"
                              href={item.productName}
                              target="_blank"
                            >
                              Attachment
                            </a>
                          </div>
                          </div>
                        ) : (
                          <>
                            {isImageLoading &&
                            imageIndex === index ? (
                              <Spinner
                                animation="border"
                                role="status"
                              >
                                <span className="sr-only">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : (
                              <>
                              <div className="uploadFileChoosen">
                                <label
                                  for="downloadIcon"
                                  className="upload_title"
                                >
                                  <div className="uploadFilebg">
                                    <img
                                      src={downloadIcon}
                                      width="16px"
                                      height="16px"
                                    />
                                    <span className="uploadFileTitle">
                                      Upload a file
                                    </span>
                                  </div>
                                  <p className="noFileChoosen">
                                    No file choosen
                                  </p>
                                </label>
                                <input
                                  id="downloadIcon"
                                  className="downloadFile"
                                  style={{ display: "none" }}
                                  type="file"
                                  name="attachments"
                                  accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                  onChange={(e) => onFileChange(index, e)}
                                />
                                </div>
                              </>
                              
                            )}
                          </>
                        )}
                      </div>
                      </>
                    ):null}

                  <div className="rfqButtonWrapper">
                    <button
                      type="button"
                      onClick={() => handleAddItemClose(index)}
                      className="rfqCommonBtn bgBlue"
                    >
                      Add Item
                    </button>
                  </div>
                    </Modal.Body>
                    )}
                    {errorMessage ? (
                      <ErrorHandling message={errorMessage} type={"ErrorMessage"} />
                    ) : null}
                  </Modal>
            </div>
      {/* modal for products ends here */}
        </React.Fragment>
      ))}
      {isDisabledEditRfq ? null : (
        <div className="addItemBtnCnt">
            <button className="addItemBtn" onClick={handleOpenAddItem}>
              <img src={plusIcon} alt="add icon" width="24px" height="24px" />
              <span>Add Item</span>
            </button>
        </div>
      )}
      </section>
      

      {/* footer section start here */}
        <section className="rfqButtonCnt">
        {["Closed - Lost"].includes(editRfqData.status) ? null : (
            <div className="rfqButtonWrapper">
                {[
                "new",
                "pending",
                "cancelled",
                "reopen",
                "completed",
                ].includes(editRfqData.sourcing_status) ? (
                <Link
                    className="rfqCommonBtn"
                    to="#"
                    onClick={async (e) => {
                    e.preventDefault();
                    const data = await openCreateSRDetail(
                        editRfqData.id,
                        editRfqData.sourcing_id,
                        editRfqData.sourcing_status,
                        editRfqData.status
                    );
                    const redirectUrl = getRedirectUrl(
                        editRfqData,
                        data
                    );
                    if (redirectUrl !== "/quote-requests") {
                        window.open(redirectUrl, "_blank");
                    } else {
                        setTimeout(() => {
                        window.location.reload();
                        }, 3000);
                    }
                    }}
                >
                    {getSourcingRfqStatus(editRfqData.sourcing_status)}
                </Link>
                ) : null}
                {[""].includes(editRfqData.sourcing_status) ? (
                <button
                    id="submit"
                    disabled={disabledButton}
                    onClick={handleEditSRAndCreateSource}
                    type="button"
                    className="rfqCommonBtn"
                >
                    {/* Submit with SR */}
                    {submitLoading ? (
                    <Spinner
                        animation="border"
                        size="sm"
                        style={{ width: "1.3rem", height: "1.3rem" }}
                    />
                    ) : (
                    "Submit with SR"
                    )}
                </button>
                ) : null}
                {rfq_status == "Converted to Quote" &&
                !["Converted to Quote", "Converted to MQ"].includes() ? (
                <button
                    style={{ width: "200px" }}
                    type="button"
                    className={`rfqCommonBtn bgBlue`}
                    disabled={disabledButton}
                    id="submit"
                    onClick={
                    !isSaveLoading ? (val) => changeRFQ(val) : null
                    }
                >
                    {isSaveLoading ? (
                    <Spinner
                        animation="border"
                        size="sm"
                        style={{ width: "1.3rem", height: "1.3rem" }}
                    />
                    ) : (
                    "Save & Create Quote"
                    )}
                </button>
                ) : (
                <button
                    type="button"
                    className={`rfqCommonBtn bgBlue`}
                    id="submit"
                    disabled={disabledButton}
                  onClick={!isSaveLoading ? handleSubmitForm : null}
                >
                    {isSaveLoading ? (
                    <Spinner
                        animation="border"
                        size="sm"
                        style={{ width: "1.3rem", height: "1.3rem" }}
                    />
                    ) : (
                    "Submit Changes"
                    )}
                </button>
                )}
            </div>
        )}
        </section>
      {/* footer section ends here */}
    </>
  );
};

export default EditRfqMobileView;
