import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Constants from "../../../Config";
// new revamp code
import "../../../asset/css/helvetica.css";
import "../../Static-page/static-page.css";
import CustomerInfo from "./CustomerInfo";
import AddItem from "./AddItem";
import RfqFooter from "./RfqFooter";
import ErrorHandling from "../../ErrorHandling/ShowMessage";
import CustomerInfoMobile from "./CustomerInfoMobile";

// b2b Api Url
const apiUrl = Constants.API_URL;
// get token
const getUserDetails = JSON.parse(localStorage.getItem("userData"));

const CreateRfq = (props) => {
  const [showCreditFinance, setShowCredditFinance] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAgent, setOpenAgent] = useState(false);
  // our all fucntions state start here
  const [customer_id, setCustomerId] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [customer_mobile, setCustomerMobile] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [displayProductArray, setDisplayProductArray] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [is_loading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [is_error, setIsError] = useState(false);
  const [is_redirect, setIsRedirect] = useState(false);
  const [getAllProductsWithDetailForList, setGetAllProductsWithDetailForList] =
    useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [phone2, setPhone2] = useState("");
  const [campaign, setCampaign] = useState("");
  const [showCategory, setShowCategory] = useState(true);
  const [categObj, setCategObj] = useState([]);
  const [categSelect, setCategSelect] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [validated, setValidated] = useState(false);
  const [all_rfq_types, setAllRfqTypes] = useState([]);
  const [wrong_phone, setWrongPhone] = useState(false);
  const [mediumArray, setMediumArray] = useState([]);
  const [utmSourceArray, setUtmSourceArray] = useState([]);
  const [customerTypeArray, setCustomerTypeArray] = useState([]);
  const [customer_type, setCustomerType] = useState("");
  const [agent_type, setAgentType] = useState("");
  const [agent_type_id, setAgentTypeId] = useState("");
  const [accountCompany, setAccountCompany] = useState("");
  const [accountCompanyAgentName, setAccountCompanyAgentName] = useState("");
  const [customer_does_not_email, setCustomerDoesNotEmail] = useState(false);
  const [customer_company, setCustomerCompany] = useState("");
  const [pincode, setPincode] = useState("");
  const [location, setLocation] = useState("");
  const [location_id, setLocationId] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchAccount, setSearchAccount] = useState("");
  const [googleData, setGoogleData] = useState([]);
  const [getRfqId, setGetRfqId] = useState("");
  const [rfqIdResponse, setRfqIdResponse] = useState("");
  const [total, setTotal] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [existingName, setExistingName] = useState(false);
  const [existingEmail, setExistingEmail] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [agentID, setAgentID] = useState("");
  const [customerIdB2C, setCustomerIdB2C] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [accountPlansData, setAccountPlansData] = useState([]);
  const [showAccountId, setShowAccountId] = useState("");
  const [agentData, setAgentData] = useState([]);
  const [productArrData, setProductArrData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // our all fucntions state ends here
  // mobile state start here
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  // mobile state ends here

  useEffect(() => {
    getBusinessGroup();
    getRfqTypes();
    handleCustomerType();
    getUtmSource();
    getUtmMedium();
    getAllAgents();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const handleBarCustomerEmailCheckbox = () => {
    setCustomerDoesNotEmail((prev) => !prev);
  };

  const openCreditDropdown = () => {
    setShowCredditFinance(true);
  }

  const closeCreditDropdown = () => {
    setShowCredditFinance(false);
  }

  const showDropDowns = () => {
    setShowDropdown(!showDropdown);
  };
  const closeDropDown = () => {
    setShowDropdown(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleAgentDropdown = () => {
    setOpenAgent(!isOpenAgent)
  }

  // all previous fucntion start here
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "serachAccount") {
      setSearchAccount(value);
    } else if (name === "customer_id") {
      setCustomerId(value);
    } else if (name === "customer_name") {
      setCustomerName(value);
    } else if (name === "customer_email") {
      setCustomerEmail(value);
    } else if (name === "customer_type") {
      setCustomerType(value);
    } else if (name === "location") {
      setLocation(value);
    } else if (name === "agent") {
      setAccountCompanyAgentName(value);
    } else if (name === "customer_mobile") {
      setCustomerMobile(value);
    } else if (name === "agent_type"){
      setAgentType(value);
    }
  };

  const handleSelectCustomerRole = (value) => {
    setIsOpen(false);
    setCustomerType(value);
  }

  const handleSelectAgent = (id, value) => {
    setOpenAgent(false);
    setAgentType(value);
    setAgentTypeId(id);
  }

  const fetchAccountPlansData = async (accountID) => {
    try {
      const response = await axios.post(
        apiUrl + "/get-account-plans",
        { account_id: Number(accountID) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },
        }
      );
      setAccountPlansData(response.data.data);
    } catch (error) {
      console.error("Error fetching:", error.response?.data?.message);
    }
  };

  const categoryObject = (object) => {
    setCategObj(object)
    setCategSelect(true);
  };
  
  const displayAllProducts = (e) => {
    setGetAllProductsWithDetailForList((prev) => {
      const updatedList = [...prev, ...e];
      const objUniq = updatedList.reduce(
        (res, item) => ({ ...res, [item.name]: item }),
        {}
      );
      return Object.values(objUniq).filter((item) => item.name !== "");
    });
  };
  const getBusinessGroup = async () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { id: 2105 },
    };
    try {
      const response = await axios(apiUrl + "/get-business-group", options);
      setBusinessGroup(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUtmSource = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails?.token}`,
      },
      data: { meta_id: 28 },
    };
    try {
      const response = await axios(apiUrl + "/meta-value", options);
      setUtmSourceArray(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUtmMedium = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { meta_id: 29 },
    };
    try {
      const response = await axios(apiUrl + "/meta-value", options);
      setMediumArray(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getRfqTypes = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { meta_id: 23 },
    };
    try {
      await axios(apiUrl + "/meta-value", options);
      setAllRfqTypes([
        "Pro Pricing Request",
        "Sales Inbound Call",
        "Sales Outbound Call",
        "Sales Whatsapp",
        "Sales message",
        "Sales E-mail",
        "Sales Reference",
        "Non transacted Pro",
        "Other",
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllAgents = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [56],
        showAll: "",
        slug: "quote-requests",
        listType: "list"
      },
    };
    return await axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        setAgentData(data.data.data)
      })
      .catch((error) => console.log(error));
  };

  const handleCustomerType = async (e) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails?.token;
    try {
      const response = await axios.post(
        apiUrl + "/meta-value",
        { meta_id: 4 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCustomerTypeArray(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitType = e.target.id;
    if (customer_mobile.length !== 10) {
      setErrorMessage("Mobile number must be 10 digits.");
      setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
      setButtonDisabled(false);
      setSubmitLoading(false);
      return; // Stop further execution
    }

    setButtonDisabled(true);
    setGetRfqId(submitType);
    setSubmitLoading(true);
    setValidated(true);

    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails?.token;

    const nameSet = new Set();
    let duplicateName = null;
  
    for (const item of productArrData) {
      if (nameSet.has(item.name)) {
        duplicateName = item.name;
        break;
      }
      nameSet.add(item.name);
    }
  
    // Show error message if duplicate product name found
    if (duplicateName) {
      setErrorMessage(`Duplicate product not allowed.`);
      setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
      setButtonDisabled(false);
      setSubmitLoading(false);
      return;
    }

    // Extract state values
    const productsData = productArrData.map(({ 
      showProducts, 
      itemQuantity, 
      price, 
      url, 
      categoryUrl, 
      ...filteredData 
      }) => ({
        brand: filteredData.brand,
        brand_id: filteredData.brand_id,
        category_id: filteredData.category_id,
        name: filteredData.name,
        product_id: filteredData.product_id,
        // quantity: filteredData.type === "attachements" ? "" : Number(filteredData.quantity) || 0,
        quantity: filteredData.type === "attachments" ? "" : (Number(filteredData.quantity) || 0),
        quantity_type: filteredData.quantity_type,
        type: filteredData.type
      }));
    const rfq_params = {
      customer_mobile: Number(customer_mobile),
      customer_email: customer_email,
      rfq_type: "Sales Outbound Call",
      subTotal: Number(subTotal),
      source: "CRM",
      image_data: selectedFile,
      attachments: JSON.stringify(uploadedFiles),
      created_by: loginedUserDetails?.user_id,
      campaign,
      utmSource: "sales",
      utmMedium: "outbound",
      isCustomerEmail: customer_does_not_email,
      shippingCharge,
      total,
      assigned_to: agent_type_id
    };

    const body_params = {
      customer_id: customer_id,
      customer_name: customer_name,
      // products: [...productsWithoutPrice, ...uploadedFiles],
      products: productsData,
      location,
      location_id: location_id.toString(),
      pincode,
      role: customer_type,
      lead_id: 0,
    };

    try {
      const res = await axios.post(
        apiUrl + "/create-rfq",
        { ...body_params, ...rfq_params },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const customerIdRes = res.data.data.customer_id;
      const RfqId = res.data.data.rfqId;

      const sr_params = {
        mobile: customer_mobile,
        email: customer_email,
        utm_source: "sales",
        utm_medium: "outbound",
        utm_campaign: campaign,
        utm_content: "",
        sales_remarks: "",
        account_name: searchAccount ? searchAccount : accountCompany,
        customer_id: customerIdRes,
        rfq_id: RfqId,
      };

      if (submitType === "createRfqSr") {
        body_params.rfq_id = RfqId;

        await axios.post(
          apiUrl + "/create-source-request",
          { ...body_params, ...sr_params },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setSuccessMessage("RFQ & SR Created Successfully!");
        setButtonDisabled(true);
        setIsError(false);
        setSubmitLoading(false);
        setRfqIdResponse(RfqId);
        setCustomerId(customerIdRes);
      } else {
        setSuccessMessage("RFQ Created Successfully!");
        setButtonDisabled(true);
        setIsError(false);
        setSubmitLoading(false);
        setRfqIdResponse(RfqId);
        setCustomerId(customerIdRes);
      }

      setTimeout(() => {
        setSuccessMessage("");
        setIsError(false);
        setIsRedirect(true);
        setButtonDisabled(true);
      }, 500);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred.");
      setIsError(true);
      setSubmitLoading(false);
      setButtonDisabled(false);

      setTimeout(() => {
        setErrorMessage("");
        setIsError(false);
      }, 4000);
    }
  };

  const gettingPrimaryAccountDetails = async (e) => {
    try {
      const response = await axios.post(
        apiUrl + "/get-primary-account",
        {
          phone: e.target.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },
        }
      );

      if (response.data.data.length > 0) {
        setAccountCompany(response.data.data[0].accountName);
        setAccountCompanyAgentName(response.data.data[0].accountAgentName);
      }
    } catch (error) {
      console.error(error);
      setAccountCompany("");
      setAccountCompanyAgentName("");
    }
  };

  const getPhoneValue = async (e) => {
    // e.persist();
    const phoneValue = e.target.value;
    // Show error if the number exceeds 10 digits
    if (phoneValue.length > 10) {
      e.target.value = phoneValue.slice(0, 10); // Trim to first 10 digits
      setErrorMessage("Mobile number cannot exceed 10 digits");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }

    if (phoneValue.length === 10) {
      await gettingPrimaryAccountDetails(e);
      setPhone2(phoneValue);
      setCustomerMobile(phoneValue);
      setWrongPhone(false);

      try {
        const response = await axios.post(
          apiUrl + "/get-customer-detail-by-phone",
          {
            phone: phoneValue,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getUserDetails?.token}`,
            },
          }
        );

        const customerData = response.data.data[0] || {};

        setExistingName(!!customerData.customerName);
        setExistingEmail(!!customerData.email);
        setCustomerName(customerData.customerName || "");
        setCustomerEmail(customerData.email || "");
        setCustomerType(customerData.role || "");
        setAgentType(customerData.agentName);
        setAgentTypeId(customerData.agent_id);
        setCustomerId(customerData.customer_id || 0);
        setCustomerMobile(customerData.telephone || phoneValue);
        setCustomerCompany(customerData.customerCompany || "");
        setAgentName(customerData.agentName || "");
        setAgentID(customerData.agent_id || "");
        setSearchAccount(customerData.customerCompany || "");
        setShowAccountId(customerData.account_id);
        if(customerData.account_id) {
          fetchAccountPlansData(customerData.account_id)
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
        setCustomerCompany("");
        setAgentName("");
        setWrongPhone(true);
        setCustomerId(0);
        setCustomerName("");
        setCustomerEmail("");
        setAgentType("");
        setAgentTypeId("");
        setAccountCompanyAgentName("");
        setCustomerType("");
        setExistingName(false);
        setExistingEmail(false);
        setAgentID("");
        setSearchAccount("");
        setShowAccountId("");
        setAccountPlansData([]);
        // closeCreditDropdown();
        setShowCredditFinance(!showCreditFinance)
      }
    } else {
      setCustomerMobile(phoneValue);
      setCustomerCompany("");
      setAgentName("");
      setWrongPhone(true);
      setCustomerId(0);
      setCustomerName("");
      setCustomerEmail("");
      setAccountCompanyAgentName("");
      setCustomerType("");
      setExistingName(false);
      setExistingEmail(false);
      setAgentID("");
      setSearchAccount("");
      setShowAccountId("");
      setAgentType("");
      setAgentTypeId("");
      setAccountPlansData([]);
      // closeCreditDropdown();
      setShowCredditFinance(!showCreditFinance)
    }
  };

  const handleAccountSearch = async (e) => {
    const value = e.target.value;
    setSearchAccount(value);
    if (!value) {
      setSuggestions([]);
      setShowCustomerDropdown(false);
      setCustomerMobile("");
      setCustomerCompany("");
      setAgentName("");
      setWrongPhone(true);
      setCustomerId(0);
      setCustomerName("");
      setCustomerEmail("");
      setAccountCompanyAgentName("");
      setCustomerType("");
      setExistingName(false);
      setExistingEmail(false);
      setAgentID("");
      setSearchAccount("");
      setShowAccountId("");
      setAgentType("");
      setAgentTypeId("");
      setAccountPlansData([]);
      closeCreditDropdown();
      setShowCredditFinance(!showCreditFinance)
      return;
    }

    try {
      const { data } = await axios.post(
        apiUrl + "/get-suggested-Account",
        { keyword: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },
        }
      );
      setSuggestions(data.data || []);
      setShowCustomerDropdown(true);
    } catch (err) {
      console.error(err.response);
    }
  };

  const handleSelectAccount = (account) => {
    setSearchAccount(account.company_name);
    setShowCustomerDropdown(false);

    setCustomerMobile(account.telephone);
    setCustomerEmail(account.email);
    setCustomerId(account.customer_id);
    setCustomerType(account.role);
    setCustomerName(account.name);
    setShowAccountId(account.account_id);
    setAgentType(account.agent_name);
    setAgentTypeId(account.agent_id);
    if(account.account_id) {
      fetchAccountPlansData(account.account_id)
    }
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setLocation(value);

    if (value.length > 2) {
      try {
        const response = await axios.post(
          apiUrl + "/google-text",
          {
            query: value + " in India",
            key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
            components: "country:in",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getUserDetails?.token}`,
            },
          }
        );

        const data = response.data.data;
        if (data.status === "OK") {
          const results = data.results.map((result) => result.formatted_address);
          let storeLatLng = data.results[0].geometry.location;  
          setLocationList(results);
          setShowDropdown(true);
          await fetchPostalCode(storeLatLng.lat, storeLatLng.lng);
        } else {
          setLocationList([]);
          setShowDropdown(false);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        setLocationList([]);
        setShowDropdown(false);
      }
    } else {
      setLocationList([]);
      setShowDropdown(false);
    }
  };

  const fetchPostalCode = async (lat, lng) => {
    try {
      const response = await axios.post(
        apiUrl + "/google-lat-long",
        {
          latlng: `${lat},${lng}`,
          sensor: "true",
          key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
          regions: "locality",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },
        }
      );
  
      const geocodeData = response.data;
      let dataRes = [];
      const uniqueAddresses = new Set();
  
      for (let each of geocodeData.data.results) {
        let formattedAddress = each.formatted_address;
        let pincode =
          each.address_components.find((comp) => comp.types.includes("postal_code"))
            ?.long_name || "";
  
        if (!uniqueAddresses.has(formattedAddress)) {
          uniqueAddresses.add(formattedAddress);
          dataRes.push({ addr: formattedAddress, pincode });
        }
      }
      setGoogleData(dataRes);
    } catch (error) {
      console.error("Error fetching postal code:", error);
    }
  };

  const handleSelectLocation = (location) => {
    const selectedEntry = googleData.find((entry) => entry.addr === location);
    // Extract pincode or set empty if not found
    const selectedPincode = selectedEntry ? selectedEntry.pincode : "";
    setLocation(location);
    setPincode(selectedPincode);
    setShowDropdown(false);
  };

  // all previous fucntion ends here
  const storeChildData = (data) => {
    setProductArrData(data);
  }

  if (is_redirect && getRfqId === "createRfq") {
    return <Redirect to="/quote-requests" />;
  } else if (
    is_redirect &&
    getRfqId === "createRfqSr"
  ) {
    return <Redirect to="/quote-requests" />;
  }

  return (
    <>
    {errorMessage ? (
        <ErrorHandling message={errorMessage} type={"ErrorMessage"} />
      ) : null}
      {successMessage ? (
        <ErrorHandling
          message={successMessage}
          type={"SuccessMessage"}
        />
      ) : null}
      {!isMobile ? ( <>
      <CustomerInfo
        showDropdown={showDropdown}
        isOpen={isOpen}
        isOpenAgent={isOpenAgent}
        searchAccount={searchAccount}
        wrong_phone={wrong_phone}
        customer_id={customer_id}
        customer_name={customer_name}
        existingName={existingName}
        customer_email={customer_email}
        existingEmail={existingEmail}
        customerTypeArray={customerTypeArray}
        customer_type={customer_type}
        accountCompanyAgentName={accountCompanyAgentName}
        suggestions={suggestions}
        showCustomerDropdown={showCustomerDropdown}
        customer_mobile={customer_mobile}
        accountPlansData={accountPlansData}
        locationList={locationList}
        location={location}
        agentData={agentData}
        agent_type={agent_type}
        showCreditFinance={showCreditFinance}
        customer_does_not_email={customer_does_not_email}
        handleBarCustomerEmailCheckbox={handleBarCustomerEmailCheckbox}
        openCreditDropdown={openCreditDropdown}
        closeCreditDropdown={closeCreditDropdown}
        setShowCustomerDropdown={setShowCustomerDropdown}
        setIsOpen={setIsOpen}
        setOpenAgent={setOpenAgent}
        showDropDowns={showDropDowns}
        closeDropDown={closeDropDown}
        toggleDropdown={toggleDropdown}
        getPhoneValue={getPhoneValue}
        handleChange={handleChange}
        handleSelectCustomerRole={handleSelectCustomerRole}
        handleSelectAgent={handleSelectAgent}
        handleSelectAccount={handleSelectAccount}
        handleAccountSearch={handleAccountSearch}
        handleSelectLocation={handleSelectLocation}
        handleInputChange={handleInputChange}
        toggleAgentDropdown={toggleAgentDropdown}
      />
      <AddItem
        storeChildData={storeChildData}
        getRfqArray={displayAllProducts}
        getAllProductsWithDetailForList={
          getAllProductsWithDetailForList
        }
        // updateProducts={updateProducts}
        displayProductArray={displayProductArray}
        custome_id={customer_id}
        customer_name={customer_name}
        customer_email={customer_email}
        customer_mobile={phone2}
        customer_idB2={customerIdB2C}
        customerMobile={customer_mobile}
        showCategory={showCategory}
        categoryObject={categoryObject}
        categObj={categObj}
        wrong_phone={wrong_phone}
      />
      <RfqFooter
       submitLoading={submitLoading}
       buttonDisabled={buttonDisabled}
       handleSubmit={handleSubmit}
      />
    </>
  ) : <CustomerInfoMobile
        searchAccount={searchAccount}
        showCustomerDropdown={showCustomerDropdown}
        suggestions={suggestions}
        customer_mobile={customer_mobile}
        customer_name={customer_name}
        existingName={existingName}
        customer_email={customer_email}
        existingEmail={existingEmail}
        customer_does_not_email={customer_does_not_email}
        customer_type={customer_type}
        isOpen={isOpen}
        customerTypeArray={customerTypeArray}
        accountPlansData={accountPlansData}
        showCreditFinance={showCreditFinance}
        location={location}
        locationList={locationList}
        showDropdown={showDropdown}
        agent_type={agent_type}
        isOpenAgent={isOpenAgent}
        agentData={agentData}
        accountCompany={accountCompany}
        agent_type_id={agent_type_id}
        customer_id={customer_id}
        handleSelectAgent={handleSelectAgent}
        toggleAgentDropdown={toggleAgentDropdown}
        setOpenAgent={setOpenAgent}
        handleSelectLocation={handleSelectLocation}
        handleInputChange={handleInputChange}
        closeCreditDropdown={closeCreditDropdown}
        openCreditDropdown={openCreditDropdown}
        handleSelectCustomerRole={handleSelectCustomerRole}
        toggleDropdown={toggleDropdown}
        setIsOpen={setIsOpen}
        handleBarCustomerEmailCheckbox={handleBarCustomerEmailCheckbox}
        handleChange={handleChange}
        getPhoneValue={getPhoneValue}
        handleSelectAccount={handleSelectAccount}
        setShowCustomerDropdown={setShowCustomerDropdown}
        handleAccountSearch={handleAccountSearch}
      /> 
  }
    </>
  );
};

export default CreateRfq;
