import React from "react";
import Modal from "react-bootstrap/Modal";
import "../SourcingUpload/sourcing_upload.css";
import uploadFile from "../../../asset/Images/uploadFile.png";
import excelIcon from "../../../asset/Images/excelIcon.svg";
import crossIcon from "../../../asset/Images/crossIcon.svg";
import retryIcon from "../../../asset/Images/retryIcon.svg";
import alertIcon from "../../../asset/Images/alertIcon.svg";
import { Spinner } from "react-bootstrap";

const UploadFileSourcing = (props) => {
  const {
    showUpload,
    handleCloseSourcingModal,
    handleOpenSourcingModal,
    handleDelete,
    selectedUploadFile,
    uploadFailed,
    submitUploadLoading,
    retryModal,
    handleFileUpload,
    handleFileSubmit,
    uploadErrorMessage,
  } = props;
  return (
    <>
      <Modal
        show={showUpload}
        onHide={handleCloseSourcingModal}
        centered
        className="sourcingUploadFileModal"
      >
        <Modal.Header closeButton>
          {uploadFailed && !retryModal ? (
            <Modal.Title>
              <img
                src={alertIcon}
                alt="alert icon"
                className="mr-1"
                width="24px"
                height="24px"
              />
              <span>Upload Failed!!</span>
            </Modal.Title>
          ) : (
            <Modal.Title>Upload File</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {uploadFailed && !retryModal ? (
            <p className="failedUploadMsg">
              {uploadErrorMessage ||
                "Failed to upload. Please check your internet connection and try again."}
            </p>
          ) : (
            <>
              {!selectedUploadFile && (
                <div className="uploadDataWrapper">
                  <label
                    htmlFor="formControlsFile"
                    className="sourcingUploadFileLabel"
                  >
                    <img
                      src={uploadFile}
                      alt="upload icon"
                      width="100%"
                      height="100%"
                    />
                  </label>
                  <input
                    type="file"
                    className="d-none"
                    id="formControlsFile"
                    accept=".xls,.xlsx"
                    onChange={handleFileUpload}
                    multiple={false}
                  />
                </div>
              )}
              {selectedUploadFile && (
                <div className="uploadedDataWrapper">
                  <div className="uploadedDataIconWrapper">
                    <img
                      src={excelIcon}
                      alt="excel icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="fileName">{selectedUploadFile.name}</span>
                  </div>
                  <button
                    type="button"
                    className="deleteUploadedData"
                    onClick={handleDelete}
                  >
                    <img
                      src={crossIcon}
                      alt="cross icon"
                      width="20px"
                      height="20px"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {uploadFailed && !retryModal ? (
            <button
              type="button"
              className="retryUploadBtn"
              onClick={handleOpenSourcingModal}
            >
              <img
                src={retryIcon}
                alt="drive icon"
                width="24px"
                height="24px"
              />
              <span>Retry upload</span>
            </button>
          ) : (
            <>
              <button
                type="button"
                className="UploadDataBtn"
                style={{ marginLeft: "auto" }}
                onClick={handleFileSubmit}
                disabled={!selectedUploadFile || submitUploadLoading}
              >
                {submitUploadLoading ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Upload Data"
                )}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadFileSourcing;
