import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import dropdown_Icon from "../../../asset/Images/dropdown-Icon.svg";
import classes from "../SourcingRequest/CreateSource.module.css";
import { DatePicker, Space } from "antd";

let date = new Date();
const maxYear = date.getFullYear();

const AdditionalMobile = (props) => {
  const {
    handleYear,
    disabledDate,
    dateString,
    handleProfile,
    turnover,
    annualRevenueLacs,
    employeeRange,
    credit_limit,
    credit_period,
    projects_done,
    employee_count,
    handleSubmit,
    handleBackStep,
  } = props;
  const [showAdditionalCard, setAdditionalCard] = useState(0);

  const handlePreviousPage = () => {
    setAdditionalCard(2);
    handleBackStep();
  };
  return (
    <>
      {showAdditionalCard === 0 ? (
        <>
          <div className="customCard addAddressCardMobile">
            <div className="formstepCnt">
              <span className="steps">7 of 7</span>
            </div>
            <h3 className="customCardheading">Additional Details</h3>
            <Form.Row>
              <Col md="4">
                <Form.Group className="customGroup">
                  <Form.Label className="customLabel">
                    Year Of business
                  </Form.Label>
                  <DatePicker
                    size="medium"
                    className="form-control customInput datePickerInput"
                    onChange={handleYear}
                    picker="year"
                    disabledDate={disabledDate}
                    inputReadOnly={true}
                    type="button"
                  />
                  <Form.Label className="totalYearOfBusiness">
                    {dateString
                      ? `${maxYear - Number(dateString)} Years in Business`
                      : null}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="customGroup">
                  <Form.Label className="customLabel">
                    Annual Revenue
                  </Form.Label>
                  <div className="customSelectCnt">
                    <Form.Control
                      as="select"
                      custom
                      name="turnover"
                      onChange={handleProfile}
                      value={turnover}
                      className="customSelect"
                    >
                      <option value="">
                        {turnover.length > 1 ? 0 : "Select Turn Over"}
                      </option>
                      {annualRevenueLacs &&
                        annualRevenueLacs.map((range) => {
                          return (
                            <option key={range.id} value={range.meta_value}>
                              {range.meta_title}
                            </option>
                          );
                        })}
                    </Form.Control>
                    <img
                      src={dropdown_Icon}
                      class="dropdownIcon"
                      width="16px"
                      height="16px"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="customGroup">
                  <Form.Label className="customLabel">
                    Employee Count Range
                  </Form.Label>
                  <div className="customSelectCnt">
                    <Form.Control
                      as="select"
                      custom
                      name="employee_count"
                      onChange={handleProfile}
                      value={employee_count}
                      className="customSelect"
                    >
                      <option value="">
                        {employee_count.length > 1 ? 0 : "Select Range"}
                      </option>
                      {employeeRange &&
                        employeeRange.map((range) => {
                          return (
                            <option key={range.id} value={range.meta_value}>
                              {range.meta_title}
                            </option>
                          );
                        })}
                    </Form.Control>
                    <img
                      src={dropdown_Icon}
                      class="dropdownIcon"
                      width="16px"
                      height="16px"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md="4">
                <Form.Group className="customGroup">
                  <Form.Label className="customLabel">Credit Limit</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Credit Limit."
                    name="credit_limit"
                    onChange={handleProfile}
                    value={credit_limit}
                    className="customInput"
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="customGroup">
                  <Form.Label className="customLabel">
                    Credit Period (In days)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Credit Period"
                    name="credit_period"
                    onChange={handleProfile}
                    value={credit_period}
                    className="customInput"
                  />
                </Form.Group>
              </Col>

              <Col md="4">
                <Form.Group className="customGroup">
                  <Form.Label className="customLabel">Projects Done</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Projects Done"
                    name="projects_done"
                    onChange={handleProfile}
                    value={projects_done}
                    className="customInput"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </div>
          <div className="addAccountFooterCntMobile">
            <button
              type="button"
              className="steeperPrevBtn"
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <button
              id="mobileSubmit"
              type="submit"
              className="steeperNextBtn"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AdditionalMobile;
