import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import SelectSearch from "react-select-search";
import AddProducts from "../Quotes/AddProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../../Config";
import axios from "axios";
import AddItemInRfq from "./AddItemInRfq";
import classes from "../SourcingRequest/CreateSource.module.css";
import ProductAdd from "../Leads/ProductAdd";
import ProductAdd2 from "../Leads/ProductAdd2";
// import Test from "./Test";

const apiUrl = Constants.API_URL;

class AddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_idB2c: "",
      show: false,
      productdetailArray: [
        {
          productName: "",
          productQuantity: 0,
        },
      ],
      itemArray: [
        {
          itemName: "",
          itemQuantity: 0,
        },
      ],
      itemDisplayArray: [
        {
          type: "product",
          name: "",
          quantity: "",
          brand: "",
          name: "",
          quantity_type: "",
          // itemQuantity: "",
          price: "",
          url:""
        }
      ],
      id: 0,
      id2: 0,
      displayProductArray: [],
      quantity_error: false,
      category_name: "",
      categoryList: [],
      categoryArray: [],
      category_id: "",
      categSelect: false,

      name: "",
      type: "",
      selectedValue: "product",
      new_add: false,
      newAdd: false,

      onBlurNotClicked: true,
      onlyFirstAdd: false,
    };
    this.props.storeChildData([
      {
        type: "product",
        name: "",
        quantity: "",
        brand: "",
        name: "",
        quantity_type: "",
        price: "",
        url:""
      }
    ]);
  }

  componentDidMount() {
    this.setState({
      displayProductArray: this.props.rfq_products,
    });
  }

  checkedCondition = () => {
    this.openModal();
    if (this.props.custome_id > 0) {
    }
    else {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails?.token}`,
        },
        url: apiUrl + "/get-customer-detail-by-phone",
        data: {
          phone: this.props.customer_mobile,
          customer_name: this.props.customer_name,
          customer_email: this.props.customer_email,
        },
      })
        .then((data) => {
          this.props.customer_idB2(data.data.data[0].customer_id);
          this.props.customerMobile(data.data.data[0].telephone);
          this.openModal();
          this.setState({
            customer_idB2c: data.data.data[0].customer_id,
          });
        })
        .catch((error) => {
          console.log(error);
          // alert("First Add Customer Mobile and Customer Name");
        });
    }
  };

  openModal = () => {
    this.setState({
      show: true,

      itemDisplayArray: [{ name: "", type: "product", quantity: "" }],
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  addDisplayArray = (i, e) => {
    let itemDisplayArray = [...this.state.itemDisplayArray];
    let updatedArray = itemDisplayArray.map((item,index)=>{
      if(index === i){
        return { ...item, ...e };
      }
      return item;
    })
    // this.props.updateProducts(
    //   "getAllProductsWithDetailForList",
    //   updatedArray
    // );
    // console.log(updatedArray,"updated array");
    this.setState({ itemDisplayArray: updatedArray });
    this.props.storeChildData(updatedArray);
  };

  updateProductData = (e,i) => {
    console.log("final update")
    let itemDisplayArray = [...this.state.itemDisplayArray];
    let updatedArray = itemDisplayArray.map((item,index)=>{
      if(index === i){
        return { ...item, ...e };
      }
      return item;
    })
    this.setState({ itemDisplayArray: updatedArray });
    this.props.storeChildData(updatedArray);
  };
  addNew = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;

    this.setState({
      productdetailArray: this.state.productdetailArray.concat({
        productName: newId,
        productQuantity: newId,
      }),
      // itemArray: this.state.itemArray.concat({
      //   itemName: newId,
      //   itemQuantity: newId,
      // }),
      id: newId,

      new_add: true,
    });
    this.props.displayProductArray.push(e);
    // this.props.displayProductArray.push(e);
  };

  addNewProduct = (e) => {
    let displayArray = [];
    displayArray.push(e);
    this.setState({
      displayProductArray: this.props.displayProductArray.concat(displayArray),
      // itemArray: this.state.itemArray.concat(e),
    });
  };
  addNewItem = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;
    this.props.storeChildData([
      ...this.state.itemDisplayArray,
      {
        name: "",
        type: "product",
        quantity: "",
        brand: "",
        name: "",
        quantity_type: "",
        price: "",
        url:""
      },
    ]);
    this.setState({
      itemDisplayArray: [
        ...this.state.itemDisplayArray,
        {
          name: "",
          type: "product",
          quantity: "",
          brand: "",
          name: "",
          quantity_type: "",
          // itemQuantity: "",
          price: "",
          url:""
        },
      ],
      id: newId,
    });
    
  };

  removeItem = (e) => {
    let id = e;
    let productdetailArray = this.state.productdetailArray;
    productdetailArray.splice(id, 1);
    // let itemArray = this.state.itemArray;
    // itemArray.splice(id, 1);
    let displayProductArray = this.state.displayProductArray;

    // For Add Rfq <productAdd>
    let itemArray = this.state.itemArray;

    itemArray.splice(id, 1);

    let itemDisplayArray = this.state.itemDisplayArray;

    itemDisplayArray.splice(e, 1);
    this.props.storeChildData(itemDisplayArray);
    this.setState({
      itemArray,
      itemDisplayArray,
      productdetailArray,
      displayProductArray,
    });

  };

  // Below is the function to get the name of the category using Search space
  getItemDetails = (e) => {
    let allItems = null;
    allItems = this.state.itemDisplayArray;
    const getLastElement = (name, allItems) => {
      const objUniq = allItems.reduce(
        (res, item) => ({ ...res, [item[name]]: item }),
        {}
      );
      return Object.keys(objUniq).map((item) => objUniq[item]);
    };
    const uniqAddress = getLastElement("name", allItems);
    const finalArray = uniqAddress.filter((item) => item.name != "");
    // e.preventDefault();
    console.log("final",finalArray,this.state.itemDisplayArray);
    this.setState(
      {
        itemArray: [
          {
            itemName: "",
            itemQuantity: 0,
          },
        ],
        itemDisplayArray: [
          {
            type: "product",
            brand: "",
            name: "",
            quantity: "",
            itemQuantity: "",
            price: "",
            url:"",
            showProducts: false,
            
          },
        ],
      },
      () => {
        this.props.getRfqArray(finalArray);
      }
    );
    this.closeModal();
    this.addNewItem();
  };

  getProductDetails = (e) => {
    // const customer_idB2c = this.state.customer_idB2c
    let allProducts = null;

    if (this.props.rfq_products) {
      allProducts = this.state.displayProductArray;
    } else {
      allProducts = this.props.displayProductArray.concat(
        this.state.displayProductArray
      );
    }
    e.preventDefault();
    this.setState(
      {
        show: false,
      },
      () => {
        this.props.getProductArray(allProducts);
        // this.props.customer_idB2(customer_idB2c)
      }
    );
    this.setState({
      productdetailArray: [
        {
          productName: "",
          productQuantity: 0,
        },
      ],
      itemArray: [
        {
          itemName: "",
          itemQuantity: 0,
        },
      ],
    });
  };
  quantityDisable = (e) => {
    this.setState({ quantity_error: e });
  };
  handleSelect = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });
  };

  render() {
    return (
      <ProductAdd2
        itemArray={this.state.itemArray}
        removeItem={this.removeItem}
        addNewItem={this.addNewItem}
        itemDisplayArray={this.state.itemDisplayArray}
        addDisplayArray={this.addDisplayArray}
        getItemDetails={this.getItemDetails}
        updateProductData={this.updateProductData}
      />
    );
  }
}

export default AddItem;
