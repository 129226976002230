// import React, { useState, useEffect } from "react";
import React, { Component } from "react";
// import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
// import Daterangepicker from "../../Dashboard/Common/Daterangepicker";
import { Form } from "react-bootstrap";
import constants from "../../../Config";
import  "./lead-table.css";

// import Daterangepicker from "../../Dashboard/Common/Daterangepicker";
import DateRangeNew from "./DateRangeNew";
const apiUrl = constants.API_URL;
export class Propricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: "",
      date_to: "",
      leadStatusArray: [],
      leadTypeArray: [],
      agentsArray: [],
      count: 0,
      source: "",
      //   leadStatus: "",
      agent: "",
      rowValue: "agent",
      columnValue: "",
      rowArray: [],
      pro_pricing_type: [],
      apiArray: [],
      resArray: [],
    };
  }
  componentDidMount() {
    this.getAllAgents();
    this.getProPricing();
    this.getProPricingType();
  }
  getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
        data: {
          agent_list: "true",
          id: [54],
          showAll: "true",
          slug: "",
        },

      // data: { logined_user_id: getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentsArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getProPricingType = (e) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },

      data: { meta_id: 24 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState({
          pro_pricing_type: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  filter = (e) => {
    this.setState(
      {
        rowValue: e.target.value,
      },
      () => {
        this.getProPricing();
      }
    );
  };
  getProPricing = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },

      data: {
        type: [this.state.rowValue],
        date_from: this.state.date_from,
        date_to: this.state.date_to,
      },
    };
    return axios(apiUrl + "/widget-pro-pricing", options)
      .then((data) => {
        let apiArray = data.data.data[0];
        Object.values(apiArray).map((item) => {
          delete item[0].agent_id;
        });

        let rowTotalArray = [];

        let holder = {};

        Object.values(apiArray).map((item, i) => {
          
          Object.keys(item[0]).map((d) => {
          
            if (holder.hasOwnProperty(d)) {
              holder[d] = holder[d] + item[0][d];
            } else {
              holder[d] = item[0][d];
            }
          });
        });

        holder.agent ? (holder.agent = "Total") : (holder.source = "Total"); //"assigning total key for row"
        rowTotalArray.push(holder);
        apiArray["Total"] = rowTotalArray;

        this.setState({
          apiArray,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
        },
        () => {
          this.getProPricing();
        }
      );
    };

    return (
      <>
        <div className="p-5 my-5 rounded border border-5 border-dark" id="table-padding-mobile">
          {/* filter section  */}
          <div className="row mb-3 justify-content-between">
            <div className="col-6 col-md-3">
              <div className="mb-2 quote_title_heading"
                style={{
                  fontWeight: "700",
                  fontSize: "17px",
                  backgroundColor: "#dc3545",
                  padding: "0.5rem",
                  borderRadius: "1rem",
                  textAlign: "center",
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)'
                }}
              >
                Pro Pricing Dashboard
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="margin-left-mobile"
                style={{
                  width: "100%",
                  background: "#fff",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  padding: "6px 10px 5px 17px",
                  marginLeft: "90px",
                 
                }}
              >
            
                <DateRangeNew
                label ={"ALL DATA"}
                  // defaultDays="29"
                  // dateAdded = {true}
                  // allData={true}
                  setDateRange={setDateRange}
                ></DateRangeNew>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <Form.Control
                as="select"
                // defaultValue={this.state.lead_type}
                custom
                onChange={this.filter}
              >
                {/* <option value="">Select Row</option> */}
                <option value="agent">Support Agent</option>
                <option value="source">Request Source</option>
                {/* <option value="agent">Agent</option> */}
              </Form.Control>
            </div>
          </div>

          {/* table section starts from below */}
          <div className="mobile-table-scroll">
            <table className="rfqtable striped bordered hover test3">
              <thead>
                <tr className="">
                  <th className="sticky-col first-col">
                    {this.state.rowValue == "source"
                      ? "Request Source"
                      : "Support Agents"}
                  </th>
                  <th className="bodyColor">Total Requests</th>
                  <th className="bodyColor">Pending (P+D+F)</th>
                  <th className="bodyColor">Pending NR</th>
                  <th className="bodyColor2">B2C</th>
                  <th className="bodyColor2">Professional</th>
                  <th className="bodyColor2">Building Product Re-seller</th>
                  <th className="bodyColor2">Corporate Buyer</th>
                  <th className="bodyColor2">Other B2B</th>
                </tr>
                {this.state.rowValue == "source"
                  ? Object.keys(this.state.apiArray).map((item, i) => {
                      return (
                        <tr>
                          {Object.values(this.state.apiArray[item][0]).map(
                            (data, i) => {
                              return <td className="tabledef">{data}</td>;
                            }
                          )}
                        </tr>
                      );
                    })
                  : Object.keys(this.state.apiArray).map((item, index) => {
                      return (
                        <tr key={index}>
                          {Object.values(this.state.apiArray[item][0]).map(
                            (data, i) => {
                              if (i == 0) {
                                return (
                                  <td key={i} className="tabledef sticky-col first-col">
                                    {data}
                                  </td>
                                );
                              } else {
                                return <td key={i} className="tabledef">{data}</td>;
                              }
                            
                            }
                          )}
                        </tr>
                      );
                    })}
              </thead>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default Propricing;
