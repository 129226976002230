import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Row, Col, Form, Button, Alert, Select } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from "react-select-search";
import axios from "axios";
import Constants from "../../../Config";
const apiUrl = Constants.API_URL;

class AddProducts extends React.Component {
  state = {
    product_name: "",
    product_quantity: "",
    productArray: [],
    productList: [],
    product_id: "",
    availabe_stock: 0,
    moq: "",
    qty_type: "",
    pack_quantity: "",
    count: 0,
    isDisable: false,
    quantity_error: false,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeQuantity = (e) => {
    if (e.target.value % this.state.pack_quantity != 0) {
      this.setState({
        quantity_error: true,
        quantity_error_message: ` Please Enter value in multiple of ${this.state.pack_quantity} `,
      });
    } else {
      this.setState({ quantity_error: false });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
    // this.props.quantityDisable(this.state.quantity_error);
    this.props.addNewProduct({
      product_id: this.state.product_id,
      quantity: this.state.product_quantity,
    });
  };
  // Below is the condition to check wheather the stock_available >= quantity we are updating
  // if(this.state.availabe_stock >= e.target.value) {
  //   this.setState({
  //     [e.target.name] : e.target.value
  //   })
  // } else {
  //   this.setState({quantity_error: true
  //     ,quantity_error_message: 'Quantity should less than available stock'})
  //   console.log('Quantity should less than available stock');
  //       }

  handleChangeQuantity2 = (e) => {
    let quantity = e.target.value;
    this.setState({
      product_quantity: quantity,
    });
    this.props.addNewProduct({
      product_id: this.state.product_id,
      quantity: quantity ? quantity : this.state.product_quantity,
    });
  };

  getProductName = (e) => {
    let index = this.state.productList.filter((el) => el.name == e)[0];
    let productId = index.product_id;
    // this.props.getNew(productId)
    this.setState({
      product_id: productId,
      product_name: e,
      availabe_stock: index.quantity,
      moq: index.moq,
      // product_quantity: index.moq,
      qty_type: index.qty_type,
      pack_quantity: index.pack_quantity,
      count: index.moq,
      product_quantity: index.pack_quantity,
    });
    this.props.addNewProduct({
      product_id: productId,
      quantity: index.pack_quantity,
      name: index.name,
      type: "product",
    });
  };

  removeMOQ = () => {
    if (this.state.product_quantity <= this.state.count) {
      this.setState(
        {
          message: `minimum order quantity is ${this.state.pack_quantity}`,
        },
        () => {
          setTimeout(() => {
            this.setState({
              message: "",
            });
          }, 2000);
        }
      );
    } else {
      // this.setState({product_quantity: this.state.product_quantity - this.state.pack_quantity})
      let quantity =
        Number(this.state.product_quantity) - Number(this.state.pack_quantity);
      this.setState({ product_quantity: quantity });
      this.props.addNewProduct({
        product_id: this.state.product_id,
        quantity: quantity ? quantity : this.state.product_quantity,
        name: this.state.product_name,
      });
    }
  };
  addMoq = () => {
    let quantity =
      Number(this.state.product_quantity) + Number(this.state.pack_quantity);
    this.setState({ product_quantity: quantity });
    this.props.addNewProduct({
      product_id: this.state.product_id,
      quantity: quantity ? quantity : this.state.product_quantity,
      name: this.state.product_name,
    });
  };

  getValue = (e) => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { keyword: e },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <Row key={this.props.index}>
        {/* {this.state.message ? (
          <Alert
            className="displayMessageArea"
            variant={this.state.message ? "success" : "danger"}
          >
            {this.state.message}
          </Alert>
        ) : null}
        {this.state.quantity_error ? (
          <Alert
            className="displayMessageArea"
            variant={this.state.quantity_error ? "danger" : "success"}
          >
            {this.state.quantity_error_message}
          </Alert>
        ) : null} */}

        <Col lg={3} className="customeAutoComplete">
          <Form.Group controlId="formBasicProductName" key={this.props.index}>
            <SelectSearch
              options={this.state.productArray}
              getOptions={(query) => this.getValue(query)}
              search
              placeholder="Product name"
              onChange={this.getProductName}
              value={this.state.product_name}
            />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group controlId="formBasicProductStock" key={this.props.index}>
            <Form.Control
              type="text"
              placeholder="Stock"
              value={this.state.availabe_stock}
              name="availabe_stock"
              readOnly="readOnly"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group
            controlId="formBasicProductQuantity"
            key={this.props.index}
            className="d-flex align-items-center choose_products_quantity"
            // style={{ display: "flex" }}
          >
            <Button
              className="h-50 d-inline-block quantity_add"
              disabled={this.state.quantity_error}
              onClick={() => this.removeMOQ(this, this.state.product_quantity)}
            >
              <FontAwesomeIcon
                icon="minus"
                className=""

                // disabled={this.state.isDisable}
              />
            </Button>
            <Form.Control
              className={this.state.quantity_error ? "error" : ""}
              type="text"
              placeholder="Quantity"
              onBlur={() =>
                this.props.addNewProduct({
                  product_id: this.state.product_id,
                  quantity: this.state.product_quantity,
                })
              }
              name="product_quantity"
              value={this.state.product_quantity}
              onChange={this.handleChangeQuantity}
            />

            {/* <input
                style={{
                  width: "35%",
                  minHeight: "35px",
                  border: "1px solid #ced4da",
                  borderRadius: "0.25rem",
                  textAlign: "center",
                }}
                placeholder="Quantity"
                defaultValue={this.state.product_quantity}
                // onBlur={()=>this.props.addNewProduct({product_id : this.state.product_id, quantity:this.state.product_quantity})}
                onChange={this.handleChangeQuantity2}
              /> */}
            <Button
              className="h-50 d-inline-block quantity_add"
              disabled={this.state.quantity_error}
              onClick={() => this.addMoq(this, this.state.product_quantity)}
            >
              <FontAwesomeIcon icon="plus" className="" />
            </Button>
          </Form.Group>
        </Col>
        <Col lg={3}>
          {this.state.product_quantity ? (
            <div className="moq_wrapper">
              <b>Box of: </b>
              {this.state.count + " " + this.state.qty_type} <br />{" "}
              <b> MOQ: </b>
              {this.state.moq + " "}Pcs
            </div>
          ) : null}
        </Col>
        <Col lg={1}>
          {this.props.productDetailArray.length != 1 ? (
            <>
              <FontAwesomeIcon
                icon="minus"
                id={this.props.index}
                onClick={() => this.props.removeItem(this.props.index)}
                className="removeIcon"
              />
              {this.props.productDetailArray.length == this.props.index + 1 ? (
                <FontAwesomeIcon
                  icon="plus"
                  onClick={() =>
                    this.props.addNew({
                      product_id: this.state.product_id,
                      quantity: this.state.product_quantity,
                      // name: this.state.product_name
                    })
                  }
                  className="addIcon"
                />
              ) : null}
            </>
          ) : (
            <FontAwesomeIcon
              icon="plus"
              onClick={() =>
                this.props.addNew({
                  product_id: this.state.product_id,
                  quantity: this.state.product_quantity,
                  // name: this.state.product_name
                })
              }
              className="addIcon"
            />
          )}
        </Col>
        {this.state.message ? (
          <Alert
            className="displayMessageArea"
            variant={this.state.message ? "success" : "danger"}
          >
            {this.state.message}
          </Alert>
        ) : null}
        {this.state.quantity_error ? (
          <Alert
            className="displayMessageArea"
            variant={this.state.quantity_error ? "danger" : "success"}
          >
            {this.state.quantity_error_message}
          </Alert>
        ) : null}
      </Row>
    );
  }
}

export default AddProducts;
