import React from "react";
import Modal from "react-bootstrap/Modal";
import crossBlack from "../../../asset/Images/crossBlack.svg";
import downloadIcon from "../../../asset/Images/downloadIcon.svg";
import { Spinner } from "react-bootstrap";
import ErrorHandling from "./ErrorHandling";

import SearchResultRow from "./SearchResultRow";

const NoProductFoundModal = (props) => {
  const {
    showNoProductFoundModal,
    handleCloseNoProductFoundModal,
    handleOpenCancelSRModal,
    unselectedProd,
    setUnselectedProd,
    stateData,
    setStateData,
    searchData,
    setSearchData,
    setCurrentIndex,
    handleAddToSr,
    downloadSelectedProducts,
    downloadProductsLoading,
    notProductFoundErrorMessage,
    handleOpenAdvanceSearchResultModal,
    handleSelectedProducts,
    setCheckSkipToSrData,
  } = props;

  const handleBackSearchResult = () => {
    let current = {};
    if (stateData && stateData.length > 1) {
      current = stateData[stateData.length - 1];
    }
    const updatedIndex = searchData.length - 1;
    if (updatedIndex < 0) {
      return;
    }
    if (current && Object.keys(current).length > 0 && current.type === "multiple") {
      const updatedSearchData = searchData.map((prod, index) => {
        if (index === updatedIndex) {
          return {
            ...prod,
            active: true,
          };
        } else {
          return {
            ...prod,
            active: false,
          };
        }
      });
      const filteredData = stateData.filter((_, index) => index !== stateData.length - 1);
      setCurrentIndex(updatedIndex);
      setStateData(filteredData);
      setSearchData(updatedSearchData);
    }
    setCheckSkipToSrData(false);
    handleCloseNoProductFoundModal();
    handleOpenAdvanceSearchResultModal();
  };
  

  return (
    <>
      <Modal
        show={showNoProductFoundModal}
        className="advanceSearchModal noProductFoundModal"
      >
        {notProductFoundErrorMessage ? (
          <ErrorHandling
            message={notProductFoundErrorMessage}
            type={"ErrorMessage"}
          />
        ) : null}
        <Modal.Header className="advanceSearchHeader multipleSearchHeaderCnt">
          <div className="multipleSearchHeader">
            <Modal.Title>
              <span>
                {unselectedProd.length} of {searchData.length} Products not
                found
              </span>
            </Modal.Title>
            <button
              className="searchModalCloseBtn"
              onClick={handleOpenCancelSRModal}
            >
              <img
                src={crossBlack}
                alt="cross icon"
                width="16px"
                height="16px"
              />
            </button>
          </div>
          <p className="multipleSearchSubContent">
            Choose the non existing products you want to add to the database.
          </p>
          <hr className="header-seprator" />
        </Modal.Header>
        <Modal.Body>
          <div className="multipleResultStatusCnt">
            <table className="searchResulttable ">
              <thead>
                <th></th>
                <th>Product</th>
                <th>Category</th>
                <th>Brand</th>
              </thead>
              <tbody>
                <SearchResultRow
                  type={3}
                  unselectedProd={unselectedProd}
                  handleSelectedProducts={handleSelectedProducts}
                />
              </tbody>
            </table>
            {unselectedProd.filter((product) => product.selected).length ? (
            <div className="downloadExlCnt">
              <p className="excelItems">
                {unselectedProd.filter((product) => product.selected).length}/
                {unselectedProd.length} Products Added to Excel
              </p>
              <button
                className="downloadExlBtb"
                onClick={downloadSelectedProducts}
              >
                <img
                  src={downloadIcon}
                  alt="download icon"
                  width="16px"
                  height="16px"
                />
                {downloadProductsLoading ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <span>Download .xls</span>
                )}
              </button>
            </div>
            ):null}
          </div>
        </Modal.Body>
        <Modal.Footer className="searchResultFooterModal">
          <div className="searchResultFooterBtnCnt">
            <button
              className="footerbackBtn"
              type="button"
              onClick={handleBackSearchResult}
            >
              Back to Search Results
            </button>
            <button
              className="footerSearchbtn"
              type="button"
              onClick={() => {
                handleCloseNoProductFoundModal();
                handleAddToSr();
              }}
            >
              Next
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoProductFoundModal;
