// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/HelveticaNowDisplay-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/HelveticaNowDisplay-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/HelveticaNowDisplay-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/HelveticaNowDisplay-ExtraBold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/HelveticaNowDisplay-Black.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Helvetica';\n    src: local('Helvetica Regular'), local('HelveticaNowDisplay-Regular'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n}\n\n\n@font-face {\n    font-family: 'Helvetica';\n    src: local('Helvetica Medium'), local('HelveticaNowDisplay-Medium'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n    font-weight: 500;\n    font-style: normal;\n    font-display: swap;\n}\n@font-face {\n    font-family: 'Helvetica';\n    src: local('Helvetica Bold'), local('HelveticaNowDisplay-Bold'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\n    font-weight: 700;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Helvetica';\n    src: local('Helvetica ExtBd'), local('HelveticaNowDisplay-ExtraBold'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\n    font-weight: 800;\n    font-style: normal;\n    font-display: swap;\n}\n@font-face {\n    font-family: 'Helvetica';\n    src: local('Helvetica Black'), local('HelveticaNowDisplay-Black'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2');\n    font-weight: 900;\n    font-style: normal;\n    font-display: swap;\n}\n\n\n\n", ""]);
// Exports
module.exports = exports;
