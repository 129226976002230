import React from "react";
import { Table, Modal, Button, Form, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import CallAttemptedHistory from "./CallAttemptedHistory";
import { DatePicker as AntDatePicker } from "antd";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import dayjs from "dayjs";


const apiUrl = Constants.API_URL;
const b2bApiUrl = Constants.B2B_API_URL;
const CallCount = 10;

class ApprovalProcess extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      businessData: [],
      businessGroup: [],
      checkedBusinessGroup: "",
      checkedBusinessType: [],
      checkedSubBusinessType: [],
      checkedSubBusinessTypeCategory: [],
      selected_sub_business_type_category: [],
      show_categories: [],
      combine_cats: [],
      selectedOption: null,
      businessType: [],
      selectedBusinessTypesRadio: [],
      subBusinessType: [],
      subBusinessTypeCategory: [],
      legalStatus: [],
      others: false,
      valid_gst: false,
      is_calling: false,
      call_attempt_count: "",
      call_attempt_history: [],
      sub_category: [],
      business_parent_category_array: [],
      business_category_array: [],
      business_category_array_org: [],
      selected_category_array: [],
      selected_city_serve_array: [],
      business_city_serve_array: [],
      business_city_serve_array_org: [],
      show_city_serve_category: false,
      is_multiselect_search: false,
      is_subcategory: false,
      show_business_category: false,
      keyword: "",
      isLoading: false,
      slug_exists: false,
      isSaveLoading: false,
      isApproveLoading: false,
      isRejectLoading: false,
      isShowSave: false,
      isShowReject: false,
      isShowApprove: false,
      isShowRejectForm: false,
      userFreeCredit: "",
      onboarding_credit: 0,
      showFreeLeadsCredits: false,
      userphone: "",
      user_id: "",
      username: "",
      useremail: "",
      company_name: "",
      legal_status: "",
      slug: "",
      gstin: "",
      gstin_applied: true,
      show_buttons: true,
      gstDisable: false,
      validGST: false,
      address: "",
      pin_code: 0,
      state: "",
      state_id: "",
      city: "",
      city_id: "",
      shop_state_id: "",
      agent: this.props.businessData.agent_id,
      agent_note: "",
      business_type_other: "",
      call_attempt: "",
      business_group: "",
      business_type: [],
      sub_business_type: [],
      current_status: "",
      product_interested: "",
      call_id: "",
      message: "",
      status: "",
      error: "",
      call_response: "",
      reject_reason: this.props.businessData.remarks,
      call_status_array: [],
      quote_type: "PRO_ACCOUNT",
      rfq_status: "",
      call_status: "",
      startDate: "",
      button_click_type: "save",
      remarks: this.props.businessData.remarks,
      address2: "",
      pro_pricing_type: "",
      businessID: "",
      approvedAgentName: "",
      approvedAgentID: "",
      validated: false,
      customerGroup: [
        { id: 1, name: "B2B" },
        { id: 2, name: "B2C" },
      ],
      showB2B: true,
      profile_type: this.props.businessData.profile_type,
      callStatus: "",
      hideAgent: false,
      proBusinessTypeArray: [],
      proSubBusinessTypeArray: [],
      minTime: this.calculateMinTime(new Date()),
      yrs_in_business: null,
      employee_count: this.props.businessData.employee_count,
      turnover: this.props.businessData.turnover,
      credit_limit: this.props.businessData.credit_limit,
      projects_done: this.props.businessData.projects_done,
      pan_card: this.props.businessData.panNo,
      credit_period: this.props.businessData.credit_period,
      proBusinessCategory: this.props.businessData.proBusinessCategory,

      addressArray: [],
      showGstAddressPopUp: false,
      selectedAddressIndex: null,
      selectedAddress: [],
      selectedGSTAddress: {},
      yrs_in_business_number: 0,
      pinLengthError: false,
      // selectedChildAccountsProID: this.props.businessData.is_merged,
      selectedChildAccountsProID: [],
      selectedChildAccountsArray: [],
      showChildAccounts: false,
      proChildAccounts: [],
      primaryId: {
        customer_id: this.props.businessData?.user_id,
        pro_id: this.props.businessData?.id,
      },
      mobileCallShowField: false,
      slug_edit: 0,
      call_successful_message: false,
      getProCitiesGroup: [],
      selectedCities: [],
      serachCategory:""
    };
  }

  componentDidMount() {
    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    let panNo = "";

    const matches = this.props.businessData.gstin.match(panRegex);
    if (matches && matches.length > 0) {
      panNo = matches[0];
    }
    this.setState(
      {
        businessID: this.props.businessData.id,
        slug: this.props.businessData.slug,
        current_status: this.props.businessData.current_status,
        category_array: this.props.businessData.category_array,
        city_serves: this.props.businessData.city_serves, // CITY_SERVE
        userFreeCredit: this.props.businessData.userFreeCredit,
        onboarding_credit: this.props.businessData.onboarding_credit,
        gstin_applied: this.props.businessData.gstin_applied ? "1" : false,
        userphone: this.props.businessData.userphone,
        gstin: this.props.businessData.gstin,
        state: this.props.businessData.state,
        state_id: this.props.businessData.state_id,
        pro_pricing_type: this.props.businessData.pro_pricing_type,
        checkedCustomerGroup: this.props.businessData.profile_type
          ? this.props.businessData.profile_type == "B2B"
            ? "1"
            : "2"
          : "",
        showB2B: this.props.businessData.profile_type == "B2B" ? true : false,
        hideAgent: this.props.businessData.profile_type == "B2B" ? false : true,
        // gstDisable: this.props.businessData.business_group == "100" ? true : false,
        // gstin_applied: this.props.businessData.business_group == "100" ? false : "1"
        pan_card:
          this.props.businessData.gstin && !this.props.businessData.panNo
            ? panNo
            : this.props.businessData.panNo,
      },
      () => {
        this.setPropsValueInState();
        this.getApprovedAgent();
        this.getLegalStatus();
        this.getBusinessGroup();
        this.getSubBusinessGroup();
        this.getCallAttemptHistory();
        this.getBusinessCategory();
        this.getSearchCityCategory(); // search city categoty api
        this.getBusinessParentCategory();
        // this.checkCompanyAlreadyExists();
        this.checkStatus();
        this.selectCategoryArray();
        this.selectCityCategoryArray(); // CITY_CATEGORY_ARRAY
        this.checkGSTValidation();
        this.getEmployeeRange();
        this.getAnnualRevenue();
        this.getDuplicateProAccounts();
        this.getProCitiesGroup();
      }
    );
  }
  getEmployeeRange = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { meta_id: 30 },
    };
    return await axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let employeeRange = data.data.data;
        this.setState({
          employeeRange,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // add new api start here
  getAnnualRevenue = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { meta_id: 10 },
    };
    return await axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let annualRevenueLacs = data.data.data;
        this.setState({
          annualRevenueLacs,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  disabledDate = (current) => {
    // Disable dates beyond the maximum year
    return current.year() > new Date().getFullYear();
  };
  checkGSTValidation = () => {
    if (!this.state.gstin_applied) {
      this.setState({
        validGST: true,
      });
      if (this.state.gstin.length) {
        this.validateGSTNumber(this.state.gstin);
      }
    } else if (this.state.gstin_applied == 1) {
      this.setState({
        validGST: true,
      });
    }
  };

  validateGSTNumber = (gstin) => {
    if (gstin) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },
        data: { gstin: gstin, state: this.state.state },
      };
      return axios(apiUrl + "/verifyGSTNumber", options)
        .then((data) => {
          if (data.data.message == "success") {
            this.setState({
              validGST: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            validGST: false,
          });
        });
    }
  };
  gstValidate = async (gstNumber) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const token = loginedUserDetails.token;
    const requestoption = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        gst: gstNumber,
      },
    };
    return await axios(apiUrl + "/gst-validate", requestoption)
      .then((data) => {
        let gstData = data.data.data[0];
        this.setState(
          {
            pan_card: gstData.panNo,
            addressArray: gstData.address_arr,
            validGST: true,
            // state: gstData?.state,
          },
          () => {
            this.getDuplicateProAccounts();
          }
        );
      })
      .catch((err) =>
        this.setState({
          validGST: false,
        })
      );
  };
  getApprovedAgent = async  () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return await axios(
      apiUrl + `/mq-assigned-agent/${this.state.businessID}`,
      options
    )
      .then((data) => {
        let approveAgent = data.data.data[0];
        this.setState({
          approvedAgentName: approveAgent.agent,
          approvedAgentID: approveAgent.agent_id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProCitiesGroup = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return await axios(
      apiUrl + `/get-pro-cities-group`,
      options
    )
      .then((data) => {
        let getProCitiesGroupData = data.data.data;
        this.setState({
          getProCitiesGroup: getProCitiesGroupData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCitySelection = (item) => {
    const cityNames = item.cityids.map((city) => city.name);
    const cityIds = item.cityids.map((city) => city.city_id);
  
    this.setState((prevState) => {
      // Check if all cities in the group are already selected
      const isCheckboxSelected = cityNames.every((name) => prevState.selectedCities.includes(name));
  
      // If checkbox selected, remove these cities, otherwise add the new cities
      const updatedSelectedCities = isCheckboxSelected
        ? prevState.selectedCities.filter((name) => !cityNames.includes(name))
        : [...prevState.selectedCities, ...cityNames.filter((name) => !prevState.selectedCities.includes(name))];
  
        const updatedSelectedCityServeArray = isCheckboxSelected
        ? prevState.selected_city_serve_array.filter((city) => !cityIds.includes(city.value))
        : [
            ...prevState.selected_city_serve_array,
            ...item.cityids
              .filter((city) => !prevState.selected_city_serve_array.some((selectedCity) => selectedCity.value == city.city_id))
              .map((city) => ({ label: city.name, value: city.city_id })),
          ];
  
      return {
        selectedCities: updatedSelectedCities,
        selected_city_serve_array: updatedSelectedCityServeArray,
      };
    });
  };
  
  setPropsValueInState = () => {
    this.setState({
      user_id: this.props.businessData.user_id,
      username: this.props.businessData.username,
      useremail: this.props.businessData.useremail,
      business_group_id: this.props.business_group_id,
      company_name: this.props.businessData.company_name,
      legal_status: this.props.businessData.legal_status,
      address: this.props.businessData.address,
      address2: this.props.businessData.address2,
      pin_code: this.props.businessData.pin_code,
      city: this.props.businessData.city,
      city_id: this.props.businessData.city_id,
      product_interested: this.props.businessData.product_interested,
    });
  };

  checkStatus = () => {
    if (
      this.state.current_status == "Verified Other B2B" ||
      this.state.current_status == "Merged" ||
      this.state.current_status == "Verified PRO" ||
      this.state.current_status == "Rejected"
    ) {
      this.setState({
        show_buttons: false,
      });
    }
  };
  selectCategoryArray = () => {
    let selectedCategory = [];
    let category_array = this.state.category_array;
    if (category_array.length > 0) {
      for (let i = 0; i < category_array.length; i++) {
        selectedCategory.push({
          label: category_array[i].name,
          value: category_array[i].id,
        });
      }
    }
    this.setState({
      selected_category_array: selectedCategory,
    });
  };
  // SELECT CITY SERVE CATEGOEY ARRAY
  selectCityCategoryArray = () => {
    let selectedCity = [];
    let city_serves = this.state.city_serves;
    if (city_serves.length > 0) {
      for (let i = 0; i < city_serves.length; i++) {
        selectedCity.push({
          label: city_serves[i].name,
          value: city_serves[i].id,
        });
      }
    }
    this.setState({
      selected_city_serve_array: selectedCity,
    });
  };
  checkCompanyAlreadyExists = async () => {
    const options = {
      method: "POST",
      data: { slug: this.state.slug },
    };
    return await axios(apiUrl + "/check-company-exits", options)
      .then((data) => {
        const result = data.data.data;
        const exists = result > 0 ? false : true;
        this.setState({
          slug_exists: exists,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkSlugExists = (e) => {
    const value = e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '');
    this.setState(
      {
        slug: value,
      }
    );
  };

  // call status api code
  getCallStatus = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        quote_type: this.state.quote_type,
        call_attempt_number: this.state.call_attempt_count - 1,
      },
    };
    return await axios(apiUrl + "/get-call-status", options)
      .then((data) => {
        this.setState({
          call_status_array: data.data.data,
          call_successful_message: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              call_successful_message: false,
            });
          }, 2000);
        });
      })
      .catch((error) => console.log(error));
  };

  handleCallType = (e) => {
    this.setState(
      {
        call_type: "Knowlarity",
      },
      () => {
        this.getCall();
      }
    );
  };

  getCall = () => {
    if (!this.state.customer_mobile) {
      this.setState({ wrong_phone: "Please Enter Correct Phone Number" });
    }
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    this.setState(
      {
        is_calling: true,
      },
      () => {
        this.getCallAttemptCount()
          .then((data) => {
            if (data <= CallCount) {
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.props.getUserDetails?.token}`,
                },
                data: {
                  phone: this.state.userphone,
                  agentID: loginedUserDetails.user_id,
                  type: ""
                },
              };
              return axios(apiUrl + "/make-call", options)
                .then((data) => {
                  let callData = data.data.data;
                  setTimeout(() => {
                    this.setState({
                      is_calling: false,
                      call_id: callData?.success?.call_id,
                      message: callData?.success?.message,
                      status: callData?.success?.status,
                      call_response: JSON.stringify(callData),
                    });
                  }, 1500);
                })
                .catch((error) => {
                  this.setState({
                    is_calling: false,
                    wrong_phone: error.response?.data?.message,
                  });
                });
            } else {
              this.setState({
                is_calling: false,
                error: "You have crossed your limit to call!",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              is_calling: false
            })
          });
      }
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCompany = (e) => {
    this.setState({
      company_name: e.target.value,
    });
    this.createSlug(e.target.value);
  };

  //create slug function start here
  createSlug = (val) => {
    var str = val.toLowerCase().replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '-');
    var replaced = str;
    this.setState({
      slug: replaced,
    });
  };
  // create slug function ends here

  handleChangeAddress2 = (e) => {
    this.setState({
      address2: e.target.value,
    });
  };

  // call status method code
  handleChangeForCallStatus = (e) => {
    if (e.target.value) {
      let call_status_array = this.state.call_status_array.filter((item) => {
        return item.id == e.target.value;
      });
      let rfqStatus = call_status_array[0].rfq_status;
      let callAttempt = call_status_array[0].status;
      if (rfqStatus != "Followup") {
        this.setState({
          startDate: "",
        });
      }
      if (
        [
          "Called and Verified - Other B2B",
          "Called and Verified -PRO",
        ].includes(callAttempt)
      ) {
        this.setState({
          showChildAccounts: true,
        });
      } else {
        this.setState({
          showChildAccounts: false,
          selectedChildAccountsArray: [],
          selectedChildAccountsProID: [],
        });
      }
      if (
        [
          "Call and Verified B2C",
          "Already Existing/ Duplicate account",
          "Not Considered/Invalid account",
        ].includes(callAttempt)
      ) {
        this.setState({
          checkedCustomerGroup: 0,
          callStatus: callAttempt,
          showB2B: false,
          checkedBusinessGroup: "",
          checkedBusinessType: [],
          hideAgent: true,
          // profile_type: "",q
        });
        if (this.state.profile_type == "B2B") {
          this.setState({
            profile_type: "",
          });
        }
      } else {
        this.setState({ callStatus: "", showB2B: true, hideAgent: false });
        if (this.state.profile_type == "B2C") {
          this.setState({
            profile_type: "",
          });
        }
      }
      this.setState(
        {
          current_call_status: e.target.value,
          call_status: e.target.value,
          rfq_status: rfqStatus,
          button_click_type: ["Approved", "Verified PRO"].includes(rfqStatus)
            ? "approve"
            : rfqStatus === "Rejected"
            ? "reject"
            : "save",
          call_attempt: callAttempt,
        },
        () => {
          if (
            this.state.rfq_status == "Approved" &&
            this.state.checkedBusinessGroup != "2"
          ) {
            this.setState({
              checkedBusinessType: [],
              checkedBusinessGroup: "",
            });
          } else if (
            "Called and Verified - Other B2B" == callAttempt &&
            this.state.checkedBusinessGroup == 2
          ) {
            this.setState({
              checkedBusinessType: [],
              checkedBusinessGroup: "",
            });
          }
        }
      );
    }
  };

  // Date code start
  handleDateChange(date) {
    if (date) {
      this.setState({
        minTime: this.calculateMinTime(date),
      });
    }
    this.setState(
      {
        startDate: date,
      },
      () => {
        if (this.state.startDate == null) {
          this.setState({
            startDate: "",
          });
        } else {
          this.setState({
            startDate: date,
          });
        }
      }
    );
  }
  // Date code end

  handleChangePincode = (e) => {
    this.setState(
      {
        pin_code: e.target.value,
      },
      () => {
        if (this.state.pin_code.length == 6) {
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.pin_code,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                city: data.data.data[0].city,
                city_id: data.data.data[0].city_id,
                state: data.data.data[0].state,
                state_id: data.data.data[0].state_id,
                pinLengthError: false,
                // shop_state_id: data.data.data[0].shop_state_id
              });
              // this.validateGSTNumber(this.state.gstin);
            })
            .catch((error) => console.log(error));
        } else {
          this.setState({
            city: "",
            state: "",
            pinLengthError: true,
          });
        }
      }
    );
  };
  handleGSTChange = (e) => {
    if (e.target.value.length == 0) {
      this.setState({
        pan_card: "",
        proChildAccounts: [],
      });
    }
    if (e.target.value.length == 15) {
      this.gstValidate(e.target.value);
    } else {
      this.setState({
        validGST: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      gstin_applied: false,
    });
  };
  handleRadioChange = (e) => {
    if (e.target.checked) {
      this.setState({
        gstin: "",
        validGST: true,
        pan_card: "",
        proChildAccounts: [],
      });
    }
    this.setState({
      gstin_applied: e.target.checked,
    });
  };
  getLegalStatus = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { meta_id: 16 },
    };
    return await axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState({
          legalStatus: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getBusinessCategory = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { busines_type: 2, keyword: "" },
    };
    return await axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        this.setState({
          business_category_array: data.data.data,
          business_category_array_org: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  // get search city function api
  getSearchCityCategory = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { keyword: "" },
    };
    return await axios(apiUrl + "/get-suggested-cities", options)
      .then((data) => {
        this.setState({
          business_city_serve_array: data.data.data,
          business_city_serve_array_org: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  // ends here serach city api

  getBusinessCategoryBySearch = (e) => {
    let keyword = e.target.value;
    const busines_typeArray = [2].concat(this.state.checkedBusinessType);

    this.setState({
      serachCategory: keyword,
    });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { busines_type: busines_typeArray, keyword: keyword },
    };
    axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        let result = data.data.data;
        let selectedCategory = this.state.selected_category_array;
        let selectArray = selectedCategory.map((selectedArray) => {
          return selectedArray.value;
        });
        let arrayAfterDeselect = [];
        result.map((item) => {
          if (selectArray.includes(item.id.toString()) === false) {
            arrayAfterDeselect.push({
              id: item.id,
              name: item.name,
            });
          }
        });
        this.setState({
          business_category_array: arrayAfterDeselect,
        });
      })
      .catch((error) => console.log(error));
  };

  // PASTE FUNCTION START HERE
  getBusinessCategoryByPasteSearch = (e) => {
    let keyword = e.clipboardData.getData("text");
    const busines_typeArray = [2].concat(this.state.checkedBusinessType);

    this.setState({
      keyword: keyword,
    });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { busines_type: busines_typeArray, keyword: keyword },
    };
    axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        let result = data.data.data;
        let selectedCategory = this.state.selected_category_array;
        let selectArray = selectedCategory.map((selectedArray) => {
          return selectedArray.value;
        });
        let arrayAfterDeselect = [];
        result.map((item) => {
          if (selectArray.includes(item.id.toString()) === false) {
            arrayAfterDeselect.push({
              id: item.id,
              name: item.name,
            });
          }
        });
        this.setState({
          business_category_array: arrayAfterDeselect,
        });
      })
      .catch((error) => console.log(error));
  };
  // PASTE FUNCTION ENDS HERE

  // search city function start here
  getSearchCityBySearch = (e) => {
    let keyword = e.target.value;
    
    this.setState({
      keyword: keyword,
      show_city_serve_category: keyword.length > 0,
    });

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { keyword: keyword },
    };
    axios(apiUrl + "/get-suggested-cities", options)
      .then((data) => {
        let result = data.data.data;
        let selectedCity = this.state.selected_city_serve_array;
        let selectArray = selectedCity.map((selectedArray) => {
          return selectedArray.value;
        });
        let arrayAfterDeselect = [];
        result.map((item) => {
          if (selectArray.includes(item.city_id.toString()) === false) {
            arrayAfterDeselect.push({
              id: item.city_id,
              name: item.name,
            });
          }
        });
        this.setState({
          business_city_serve_array: arrayAfterDeselect,
        });
      })
      .catch((error) => console.log(error));
  };
  // search city function ends here

  handleFocusOut = () => {
    // Programmatically blur the input element
    this.inputRef.current.blur();
  };

  // open mobile details on call button function start here
  handleOpenMobileData = () => {
    this.setState((prevState) => ({
      mobileCallShowField: !prevState.mobileCallShowField,
    }));
    this.getCallAttemptCount();
  };
  // open mobile details on call button function ends here

  getBusinessParentCategory = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { busines_type: 2 },
    };
    return await axios(apiUrl + "/get-parentcategory-by-businesstype", options)
      .then((data) => {
        this.setState({
          business_parent_category_array: data.data.data,
          is_multiselect_search: true,
        });
      })
      .catch((error) => console.log(error));
  };

  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          data: { id: this.props.businessData.id },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState(
              {
                businessGroup: data.data.data,
                isLoading: false,
              },
              () => {
                let selectedArray = this.state.businessGroup.filter((item) => {
                  return item.selected === true;
                });
                if (selectedArray.length > 0) {
                  if (selectedArray[0].business_type.length > 0) {
                    let selectedBusinessType =
                      selectedArray[0].business_type.filter((item) => {
                        return item.selected === true;
                      });
                    let selectedBusinessTypeID = selectedBusinessType.map(
                      (item) => item.id
                    );
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      businessType: selectedArray[0].business_type,
                      checkedBusinessType: selectedBusinessTypeID,
                      checkedCustomerGroup: "1",
                      profile_type: "B2B",
                      showB2B: true,
                    });
                  } else {
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      others: true,
                      business_type_other: selectedArray[0].value,
                    });
                  }
                } else {
                  this.setState({
                    is_multiselect_search: false,
                  });
                }
                //code for filtering ids of Pro Business Types for disabledmethod type function
                let proBusinessType = [];
                let proBusinessTypeArray = [];
                proBusinessType = this.state.businessGroup[0].business_type;
                proBusinessTypeArray = proBusinessType.map((item) => {
                  return item.id;
                });
                this.setState({
                  proBusinessTypeArray,
                });
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };

  getSubBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          data: {
            sub_busines_type: [this.props.businessData.business_type_id],
          },
        };
        return axios(apiUrl + "/get-business-sub-category", options)
          .then((data) => {
            this.setState(
              {
                subBusinessType: data.data.data,
                isLoading: false,
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };

  disabledMethodB2B = (businessID, rfq_status, call_status) => {
    if (
      ([
        "Call and Verified B2C",
        "Already Existing/ Duplicate account",
        "Not Considered/Invalid account",
      ].includes(this.state.callStatus) &&
        businessID == 1) ||
      (["Approved", "Rejected"].includes(rfq_status) &&
        businessID == 2 &&
        ![
          "Call and Verified B2C",
          "Already Existing/ Duplicate account",
          "Not Considered/Invalid account",
        ].includes(this.state.callStatus))
    ) {
      // window.alert("only other option will show")
      return true;
    } else {
      return false;
    }
  };

  // disabled code start
  disabledMethod = (business, rfq_status) => {
    if (rfq_status == "Verified PRO" && business != 2) {
      // window.alert("only other option will show")
      return true;
    } else if (
      ["Rejected", "Verified Other B2B"].includes(rfq_status) &&
      business == 2
    ) {
      return true;
    } else {
      return false;
    }
  };

  disabledMethodType = (business, rfq_status) => {
    if (
      ["Rejected", "Verified Other B2B"].includes(rfq_status) &&
      this.state.proBusinessTypeArray.includes(business)
    ) {
      return true;
    } else if (
      rfq_status === "Verified PRO" &&
      !this.state.proBusinessTypeArray.includes(business)
    ) {
      return true;
    }
  };
  // end

  disabledMethodSubType = (business, rfq_status) => {
    if (
      rfq_status == "Rejected" &&
      this.state.proSubBusinessTypeArray.includes(business)
    ) {
      return true;
    } else if (
      rfq_status === "Approved" &&
      !this.state.proSubBusinessTypeArray.includes(business)
    ) {
      return true;
    }
  };

  handleCustomerGroup = (e) => {
    let id = e.target.id;
    this.setState({
      checkedCustomerGroup: id,
    });

    if (id == 1) {
      //b2B SELECTION CASE
      this.setState({
        showB2B: true,
        profile_type: "B2B",
        hideAgent: false,

        agent:
          this.state.agent == ""
            ? this.props.businessData.agent_id
            : this.state.agent,
        showFreeLeadsCredits: false, // empty state in case of B2B selection
      });
    } else {
      //b2c SELECTION CASE
      this.setState({
        showB2B: false,
        checkedBusinessGroup: "",
        checkedBusinessType: [],
        checkedSubBusinessType: [], // add new
        profile_type: "B2C",
        hideAgent: true,
        agent: "",
        showFreeLeadsCredits: false, // empty state in case of B2C selection
      });
    }
  };

  handleBusinessGroup = (e) => {
    let id = e.target.id;
    if (id == 100) {
      this.setState({
        others: true,
        checkedBusinessGroup: id,
        checkedBusinessType: [],
        checkedSubBusinessType: [],
        is_multiselect_search: false,
        gstDisable: true,
        gstin_applied: false,
      });
    } else {
      if (id != 2) {
        this.setState({
          gstDisable: false,
          is_multiselect_search: false,
        });
      } else {
        this.setState({
          gstDisable: false,
          is_multiselect_search: true,
        });
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          checkedSubBusinessType: [], // empty
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
            }
          );
        }
      );
    }
    // SUB-BUSINESS-TYPE EMPTY FOR SPECIFIC ID CONDITION
    if (id == 18 || id == 22) {
      this.props.businessData.sub_business_types_id = "";
      this.setState({
        checkedBusinessGroup: id,
      });
    }
  };

  handleBusinessType = (e) => {
    if (e.target.type == "radio") {
      let selectedBusinessType = [];
      selectedBusinessType.push(parseInt(e.target.id));
      this.props.businessData.sub_business_types_id = "";
      this.setState(
        {
          selectedBusinessTypesRadio: selectedBusinessType,
          checkedBusinessType: [parseInt(e.target.id)],
        },
        () => {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails?.token}`,
            },

            data: {
              sub_busines_type: this.state.selectedBusinessTypesRadio,
            },
          };
          return (
            axios(apiUrl + "/get-business-sub-category", requestoption)
              .then((data) => {
                this.setState(
                  {
                    subBusinessType: data.data.data,
                    isLoading: false,
                  },
                  () => {
                    let selectedArray = this.state.businessType.filter(
                      (item) => {
                        return item.selected === true;
                      }
                    );
                  }
                );
              })
              .catch((error) => {
                // this.setState({
                //   wrong_pin: false,
                // });
              })
          );
        }
      );
      return null;
    } else {
      let selectedArray = this.state.checkedBusinessType;
      if (e.target.checked) {
        selectedArray.push(parseInt(e.target.id));
        this.setState({
          checkedBusinessType: selectedArray,
        });
      } else {
        let filteredArray = selectedArray.filter((item) => {
          return item != e.target.id;
        });
        this.setState({
          checkedBusinessType: filteredArray,
        });
        // this.props.business_type2(filteredArray);
      }
    }
  };

  handleSubBusinessType = (e) => {
    let selectedArray = [];
    let selectedCategoryArray = [];
    if (e.target.checked) {
      let extractSubCategories = this.state.subBusinessType.filter(
        (data) => data.id == e.target.id
      )[0].categories;
      extractSubCategories.map((data) => {
        this.state.combine_cats.push({
          id: data.id,
          subBusinessTypeId: e.target.id,
          name: data.name,
        });
      });
      let newSelectedValueString =
        this.props?.businessData?.sub_business_types_id + "," + e.target.id;
      const newSelectedValueNumber = ["", null, undefined].includes(
        newSelectedValueString
      )
        ? []
        : newSelectedValueString.split(",").map((d) => parseInt(d));
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        // checkedBusinessType: selectedArray,
        checkedSubBusinessType: newSelectedValueNumber,
        show_categories: extractSubCategories,
      });
      this.props.businessData.sub_business_types_id = newSelectedValueString;
    } else {
      let propsToNumber = this.props?.businessData?.sub_business_types_id
        .split(",")
        .map((d) => parseInt(d));
      let filteredArrays = propsToNumber.filter((item) => item != e.target.id);
      let removeUncheckCategories = this.state.combine_cats.filter(
        (data) => data.subBusinessTypeId !== e.target.id
      );
      this.state.combine_cats = removeUncheckCategories;
      this.setState({
        checkedSubBusinessType: filteredArrays,
      });
      this.props.businessData.sub_business_types_id = filteredArrays.join(",");
    }
    let mapping_categories = [];
    mapping_categories =
      this.state.subBusinessTypeCategory &&
      this.state.subBusinessTypeCategory.filter((item) => {
        return this.state.checkedSubBusinessType.includes(item.id)
          ? item.categories
          : "";
      });

    this.setState({
      selected_sub_business_type_category: mapping_categories,
    });
  };

  getCallAttemptCount = () => {
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },
        data: {
                businessID: this.props.businessData.id,
                logined_agent_id: this.props.getUserDetails.user_id,
              },
      };
      return axios(apiUrl + "/get-call-attempted-count", options)
        .then((data) => {
          this.setState(
            {
              call_attempt_count: data.data.data.count,
              mobileCallShowField: true,
              call_type:
                this.state.call_type == "Knowlarity" ? "Knowlarity" : "Manual",

              called: true,
            },
            () => {
              this.getCallStatus();
              
              resolve(this.state.call_attempt_count);
              if(this.state.call_attempt_count > CallCount){
                this.setState({
                  is_calling : false,
                  error: "You have crossed your limit to call"

                })
              }
            }
          );
        })
        .catch((error) => reject(error));
    });
  };
  getCallAttemptHistory = async () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        businessID: this.props.businessData.id,
        logined_agent_id: loginedUserDetails.user_id,
      },
    };

    return await axios(apiUrl + "/get-call-attempted-history", options)
      .then((data) => {
        this.setState({
          call_attempt_history: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getSubCategory = async (catID) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { id: catID },
    };
    return await axios(apiUrl + "/get-subcategory-by-parentid", options)
      .then((data) => {
        this.setState({
          sub_category: data.data.data,
          is_subcategory: true,
        });
      })
      .catch((error) => console.log(error));
  };

  handleInputChange = (value, e) => {
    if (e.action === "input-change") {
      this.setState({ value });
    }
  };

  selectOption = (e) => {
    let mainCategoryArray = this.state.business_category_array;
    let filteredCategory = mainCategoryArray.filter((item) => {
      return item.id != e.target.id;
    });
    let selectedCategory = this.state.selected_category_array;
    selectedCategory.push({
      label: e.target.title,
      value: e.target.id,
    });
    this.handleCategoryVisibility();
    this.setState({
      selected_category_array: selectedCategory,
      business_category_array: filteredCategory,
      serachCategory:""
    });
  };

  removeCategory = (e) => {
    let name = e.currentTarget.getAttribute("name");
    let mainCategoryArray = this.state.business_category_array;
    mainCategoryArray.push({
      name: name,
      id: e.currentTarget.id,
    });
    mainCategoryArray.sort((a, b) => a.name.localeCompare(b.name));
    let selectedCategoryArray = this.state.selected_category_array;
    let filteredCategory = selectedCategoryArray.filter((item) => {
      return item.value != e.currentTarget.id;
    });
    this.setState({
      selected_category_array: filteredCategory,
      business_category_array: mainCategoryArray,
    });
  };

  selectCityOption = (e) => {
    let mainCityArray = this.state.business_city_serve_array;
    let filteredCategory = mainCityArray.filter((item) => {
      return item.id != e.target.id;
    });
    let selectedCities = [
      {
        label: e.target.title,
        value: e.target.id,
      },
      ...this.state.selected_city_serve_array,
    ];
    this.handleCityServeVisibility();
    this.setState({
      selected_city_serve_array: selectedCities,
      business_city_serve_array: filteredCategory,
      keyword:""
    });
  };

  removeCities = (e) => {
    let name = e.currentTarget.getAttribute("name");
    let mainCityArray = this.state.business_city_serve_array;
    mainCityArray.push({
      name: name,
      id: e.currentTarget.id,
    });
    mainCityArray.sort((a, b) => a.name.localeCompare(b.name));
    let selectedCityArray = this.state.selected_city_serve_array;
    let filteredCity = selectedCityArray.filter((item) => {
      return item.value != e.currentTarget.id;
    });
    this.setState({
      selected_city_serve_array: filteredCity,
      business_city_serve_array: mainCityArray,
    });
  };

  // remove sub business type category function start here
  removeSubBusinessTypeCategory = (e) => {
    let name = e.currentTarget.getAttribute("name");
    let mainCategoryArray = this.state.combine_cats;
    let removeCats = mainCategoryArray.filter((data) => data.name !== name);
    this.state.combine_cats = removeCats;
    mainCategoryArray.push({
      name: name,
      id: e.currentTarget.id,
    });
    mainCategoryArray.sort((a, b) => a.name.localeCompare(b.name));
    let selectedCategoryArray = this.state.selected_category_array;
    let filteredCategory = selectedCategoryArray.filter((item) => {
      return item.id != e.currentTarget.id;
    });
    this.setState({
      selected_category_array: filteredCategory,
    });
  };
  // remove sub business type category function ends here

  // add sub business type category push function start here
  addSubCategories = () => {
    let valuesToAdd = [];
    this.state.combine_cats.map((data) => {
      valuesToAdd.push({
        label: data.name,
        value: data.id,
      });
    });
    if (this.state.combine_cats.length > 0) {
      this.state.combine_cats.map((data) => {
        this.state.selected_category_array.push({
          value: data.id,
          label: data.name,
        });
        const filteredArr = this.state.selected_category_array.reduce(
          (acc, current) => {
            const x = acc.find((item) => item.value === current.value);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
        this.state.selected_category_array = filteredArr;
        return (this.state.combine_cats = []);
      });
      return this.state.selected_category_array;
    } else {
      return this.state.selected_category_array;
    }
  };
  // remove sub business type category push function ends here

  handleCategoryVisibility = () => {
    this.setState((prevState) => ({
      show_business_category: !prevState.show_business_category,
    }));
  };

  hideCategoryVisibility = (e) => {
    if (
      e.target.className === "options" ||
      e.target.className === "form-control"
    ) {
    } else {
      this.setState({
        show_business_category: false,
        show_city_serve_category: false,
      });
    }
  };

  handleCityServeVisibility = () => {
    this.setState((prevState) => ({
      show_city_serve_category: !prevState.show_city_serve_category,
    }));
  };

  selectCategory = (e) => {
    let parent_id = e.target.getAttribute("data-item");
    let selectedCategoryArray = this.state.selected_category_array;
    let checkedCondition = e.target.checked;
    if (checkedCondition === true) {
      if (parent_id) {
        let categoryIndex = this.state.business_category_array_org.filter(
          (el) => el.id == parent_id
        );
        let categoryName = categoryIndex[0].name;
        var indexOfStevie = selectedCategoryArray.findIndex(
          (i) => i.value === parent_id
        );
        if (indexOfStevie == -1) {
          selectedCategoryArray.push({
            label: categoryName,
            value: parent_id,
          });
        }
      }
      selectedCategoryArray.push({
        label: e.target.id,
        value: e.target.value,
      });

      this.setState({
        selected_category_array: selectedCategoryArray,
      });
    } else {
      let filteredCategory = selectedCategoryArray.filter((item) => {
        return item.value != e.target.value;
      });
      this.setState({
        selected_category_array: filteredCategory,
      });
    }
  };
  showRejectForm = (e) => {
    let buttonClickType = e.target.id;
    if (buttonClickType === "reject") {
      this.setState({
        isShowRejectForm: true,
        show_buttons: false,
      });
    }
  };

  handlebarFreeLeadCredit = () => {
    this.setState((prevState) => ({
      showFreeLeadsCredits: !prevState.showFreeLeadsCredits,
    }));
  };

  submitForm = (e) => {
    let buttonClickType = e.target.id;
    if (buttonClickType === "save") {
      this.setState({
        isSaveLoading: true,
        validated: true,
      });
    } else if (buttonClickType === "approve") {
      this.setState({
        isSaveLoading: true,
        isApproveLoading: true,
        validated: true,
      });
    } else if (buttonClickType === "reject") {
      this.setState({
        isSaveLoading: true,
        isRejectLoading: true,
        validated: true,
      });
    }
    let params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        user_id: this.state.user_id,
        business_id: this.props.businessData.id,
        logined_agent_id: this.props.getUserDetails.user_id,
        // userFreeCredit: this.state.userFreeCredit, // add new free_credit key
        userphone: this.state.userphone,
        username: this.state.username,
        useremail: this.state.useremail,
        company_name: this.state.company_name ? this.state.company_name : "",
        slug: this.state.slug ? this.state.slug : "",
        legal_status:
          this.state.legal_status === null ? "" : this.state.legal_status,
        gstin: this.state.gstin,
        gstin_applied: this.state.gstin_applied,
        address: this.state.address === null ? "" : this.state.address,
        address2: this.state.address2 === null ? "" : this.state.address2,
        pin_code:
          this.state.pin_code === "" || this.state.pin_code === null
            ? 0
            : Number(this.state.pin_code),
        city: this.state.city === null ? "" : this.state.city,
        city_id: this.state.city_id === null ? "" : this.state.city_id,
        state: this.state.state === null ? "" : this.state.state,
        state_id: this.state.state_id === null ? "" : this.state.state_id,
        // shop_state_id: this.shop_state_id,
        agent: this.state.approvedAgentID
          ? this.state.approvedAgentID
          : this.state.agent,
        business_group: this.state.checkedBusinessGroup,
        business_type: this.state.checkedBusinessType,
        sub_business_type: this.state.checkedSubBusinessType,
        business_type_other: this.state.business_type_other,
        selected_category_array: this.state.selected_category_array,
        // selected_city_serve_array: this.state.selected_city_serve_array,
        city_ids: this.state.selected_city_serve_array.map((city) => {
          return {
            city_id: city.value,
            name: city.label,
          };
        }),
        is_free_credits: this.state.showFreeLeadsCredits,
        call_attempt: this.state.call_attempt,
        current_status: this.state.rfq_status,
        agent_note: this.state.agent_note,
        product_interested:
          this.state.product_interested === null
            ? ""
            : this.state.product_interested,
        call_id: this.state.call_id,
        message: this.state.message,
        status: this.state.status,
        call_response: this.state.call_response,
        reject_reason: this.state.reject_reason,
        click_type: buttonClickType,
        pro_pricing_type: this.state.pro_pricing_type,
        followup_date: this.state.startDate
          ? moment(this.state.startDate.toString()).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : "",
        profile_type: this.state.profile_type,
        yrs_in_business: this.state.yrs_in_business_number
          ? this.state.yrs_in_business_number
          : Number(this.props.businessData.yrs_in_business),
        employee_count: this.state.employee_count,
        turnover: this.state.turnover,
        credit_limit: Number(this.state.credit_limit),
        credit_period: Number(this.state.credit_period),
        projects_done: Number(this.state.projects_done),
        panNo: this.state.pan_card,
        proChildAccounts: this.state.selectedChildAccountsArray,
        is_primary:
          this.state.selectedChildAccountsProID.length > 0
            ? this.state.primaryId
            : {
                customer_id: this.props.businessData?.user_id,
                pro_id: this.props.businessData?.id,
              },
      },
    };
    if (this.state.validGST) {
      return axios(apiUrl + "/business-account-submit", params)
        .then((data) => {
          if (data.data.statusCode == 200) {
            if (data.data.data == "save") {
              this.setState({
                isSaveLoading: false,
                isShowSave: true,
              });
            } else if (data.data.data == "reject") {
              this.setState({
                isRejectLoading: false,
                isShowReject: true,
                isShowRejectForm: false,
              });
            } else if (data.data.data == "approve") {
              this.setState({
                isApproveLoading: false,
                isShowApprove: true,
                show_buttons: false,
              });
            }
            setTimeout(() => {
              this.setState({
                isShowSave: false,
                isShowReject: false,
                isShowApprove: false,
              });
              this.props.closeModal();
              this.props.getAllBusinessAccount();
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState(
            {
              isSaveLoading: false,
              isApproveLoading: false,
              isRejectLoading: false,
              error: error.response.data.message,
              slug_edit:
                error.response.data.message ==
                "Slug already exist,please make new one"
                  ? 1
                  : 0,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  error: "",
                });
              }, 5000);
            }
          );
        });
    } else {
      this.setState({
        error: "Please enter valid GST Number",
      });
      this.setState({
        isSaveLoading: false,
        isApproveLoading: false,
        isRejectLoading: false,
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 5000);
    }
  };

  calculateMinTime(date) {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).add({ minutes: 1 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate();
  }
  handleYear = (date, dateString) => {
    this.setState({
      yrs_in_business: date,
      yrs_in_business_number: date?.$y,
    });
  };
  closeModal = () => {
    this.setState({
      showGstAddressPopUp: false,
    });
  };
  selectGstAddress = (e) => {
    const addressArray = this.state.addressArray;
    const selectedIndex = parseInt(e.target.value);
    this.setState((prevState) => ({
      selectedAddressIndex:
        selectedIndex === prevState.selectedAddressIndex ? null : selectedIndex,
    }));

    const selectedAddress = addressArray.filter(
      (item, index) => parseInt(e.target.value) == index
    );
    if (!e.target.checked) {
      this.setState({
        selectedAddress: [],
      });
    } else {
      this.setState({
        selectedAddress,
      });
    }
  };
  selectAddressSubmit = (e) => {
    // this.setState({
    //   address: this.state.selectedAddress[0]?.address1,
    //   address2: this.state.selectedAddress[0]?.address2,
    //   city: this.state.selectedAddress[0]?.city,
    //   state: this.state.selectedAddress[0]?.state,
    //   pin_code: this.state.selectedAddress[0]?.pncd,
    // });
    this.setState({
      selectedGSTAddress: this.state.selectedAddress[0],
    });
    this.closeModal();
  };
  handleAccountChecked = (accountObject, accountProId) => {
    let selectedChildAccountsArray = this.state.selectedChildAccountsArray;
    let selectedChildAccountsProID = this.state.selectedChildAccountsProID;
    let primaryIdObject = this.state.primaryId;

    //Condition to Check if a Account has been added already ?

    if (selectedChildAccountsProID.indexOf(accountProId) !== -1) {
      selectedChildAccountsProID.splice(
        // removing account which have already been added
        selectedChildAccountsProID.indexOf(accountProId),
        1
      );
      selectedChildAccountsArray = selectedChildAccountsArray.filter(
        (item) => item.pro_id !== accountProId
      );
      if (accountProId == this.state.primaryId?.pro_id) {
        primaryIdObject = {
          customer_id: this.props.businessData?.user_id,
          pro_id: this.props.businessData?.id,
        };
      }
    } else {
      selectedChildAccountsProID.push(accountProId);
      selectedChildAccountsArray.push(accountObject);
    }
    this.setState({
      selectedChildAccountsArray,
      selectedChildAccountsProID,
      primaryId: primaryIdObject,
    });
  };
  getDuplicateProAccounts = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        pro_id: this.props.businessData.id,
        panNo: this.state.pan_card,
      },
    };
    return await axios(apiUrl + `/duplicate-pro-accounts-by-pan`, options)
      .then((data) => {
        this.setState(
          {
            proChildAccounts: data.data?.data?.duplicateProAccounts,
            selectedChildAccountsProID: data.data?.data?.is_merged,
            primaryId: data.data?.data?.is_primary,
            //Here will come the value for Primary ID from the api
          },
          () => {
            const filteredArray = this.state.proChildAccounts.filter((data) =>
              this.state.selectedChildAccountsProID.includes(data.pro_id)
            );
            this.setState({
              selectedChildAccountsArray: filteredArray,
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  markPrimary = (markedId, accountObject, accountProId) => {
    let selectedChildAccountsArray = this.state.selectedChildAccountsArray;
    let selectedChildAccountsProID = this.state.selectedChildAccountsProID;

    //Condition to Check if a Account has been added already ?

    if (selectedChildAccountsProID.indexOf(accountProId) == -1) {
      selectedChildAccountsProID.push(accountProId);
      selectedChildAccountsArray.push(accountObject);
    }

    this.setState({
      selectedChildAccountsArray,
      selectedChildAccountsProID,
    });
    if (this.state.primaryId?.customer_id != markedId) {
      this.setState({
        primaryId: {
          customer_id: markedId,
          pro_id: accountProId,
        },
      });
    } else {
      // setting the primary id object to the parent Id values
      this.setState({
        primaryId: {
          customer_id: this.props.businessData?.user_id,
          pro_id: this.props.businessData?.id,
        },
      });
    }
  };
  render() {
    return (
      <>
      <Form
        noValidate
        validated={this.state.validated}
        onClick={(e) => this.hideCategoryVisibility(e)}
        encType="multipart/form-data"
      >
        {this.state.isLoading ? (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            {this.state.error.length > 0 ? (
              <div
                className={
                  this.state.error
                    ? "alert alert-danger"
                    : "alert alert-success"
                }
              >
                {this.state.error}
              </div>
            ) : null}
            {this.state.isShowSave ? (
              <div className="alert alert-success">
                You have successfully Save this company
              </div>
            ) : null}
            {this.state.isShowReject ? (
              <div className="alert alert-danger">
                You have successfully Reject this company
              </div>
            ) : null}
            {this.state.isShowApprove ? (
              <div className="alert alert-success">
                You have successfully Approved this company
              </div>
            ) : null}
            <Form.Row>
              <Modal
                className="business_process_wrapper"
                center
                show={this.state.showGstAddressPopUp}
                onHide={this.closeModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Select An Address from Following </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.addressArray &&
                    this.state.addressArray.map((address, index) => {
                      return (
                        <div className="mt-3">
                          <input
                            type="checkbox"
                            onChange={this.selectGstAddress}
                            checked={this.state.selectedAddressIndex === index}
                            value={index}
                            id={index}
                          />
                          {"\t" +
                            address.address1 +
                            "\t" +
                            address.address2 +
                            "\t" +
                            address.city +
                            "\t" +
                            address.state}
                        </div>
                      );
                    })}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
              </Modal>
              <Col sm={8}>
                <Form.Row>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Mobile</Form.Label>
                      <br />
                      <Form.Control
                        className="mobileNumber"
                        placeholder="Mobile"
                        disabled
                        name="userphone"
                        value={this.state.userphone}
                        onChange={this.handleChange}
                      />
                      <Button
                        variant="success"
                        className="callButton"
                        disabled={this.state.is_calling}
                        // onClick={this.getCall}
                        // onClick={this.handleOpenMobileData}
                        onClick={this.handleCallType}
                      >
                        {this.state.is_calling ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ width: "1.3rem", height: "1.3rem" }}
                          />
                        ) : (
                          "Call"
                        )}
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        placeholder="Name"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                        required
                      />{" "}
                      <Form.Control.Feedback type="invalid">
                        Please enter billing first name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder="Email"
                        name="useremail"
                        value={this.state.useremail}
                        onChange={this.handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Company</Form.Label>
                      <Form.Control
                        placeholder="Company Name"
                        name="company_name"
                        type="text"
                        value={this.state.company_name}
                        // onChange={this.handleChange}
                        onChange={this.handleCompany}
                        required={
                          this.state.rfq_status == "Approved" ||
                          this.state.profile_type == "B2B"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label style={{ width: "100%" }}>Slug</Form.Label>
                      {this.state.slug_edit ? (
                        <Form.Control
                          className="slugLeft"
                          placeholder="Slug"
                          name="slug"
                          value={this.state.slug}
                          // disabled={this.props.businessData.slug}
                          onChange={this.checkSlugExists}
                          required={
                            this.state.rfq_status == "Approved" ||
                            this.state.profile_type == "B2B"
                          }
                        />
                      ) : (
                        <Form.Control
                          className="slugLeft"
                          placeholder="Slug"
                          name="slug"
                          value={this.state.slug}
                          disabled={this.props.businessData.slug}
                          onChange={this.checkSlugExists}
                          required={
                            this.state.rfq_status == "Approved" ||
                            this.state.profile_type == "B2B"
                          }
                        />
                      )}
                    </Form.Group>
                  </Col>

                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>GST Number</Form.Label>
                      <Form.Control
                        className={!this.state.validGST ? "error" : ""}
                        placeholder="GST Number"
                        name="gstin"
                        value={this.state.gstin}
                        onChange={this.handleGSTChange}
                        required={
                          this.state.profile_type == "B2B" &&
                          !this.state.gstin_applied
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter Correct GST.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={2}>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="radio"
                        label="GST Applied"
                        name="gstin_applied"
                        className="mt-30"
                        checked={this.state.gstin_applied}
                        onChange={this.handleRadioChange}
                        value={this.state.gstin_applied}
                        disabled={this.state.gstDisable}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Address-1</Form.Label>
                      <Form.Control
                        placeholder="address-1"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange}
                        required={
                          this.state.rfq_status == "Approved" ||
                          this.state.profile_type == "B2B"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Address-2</Form.Label>
                      <Form.Control
                        placeholder="address-2"
                        name="address"
                        value={this.state.address2}
                        onChange={this.handleChangeAddress2}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Pincode</Form.Label>
                      <Form.Control
                        className={this.state.pinLengthError ? "error" : ""}
                        placeholder="Pincode"
                        name="pin_code"
                        value={this.state.pin_code}
                        onChange={this.handleChangePincode}
                        required={
                          this.state.rfq_status == "Approved" ||
                          this.state.profile_type == "B2B"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        placeholder="City"
                        name="city"
                        value={this.state.city}
                        disabled
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        placeholder="State"
                        name="state"
                        value={this.state.state}
                        disabled
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {/* Agent Input Field Start here */}
                  {!this.state.hideAgent ? (
                    <Col sm={3}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Agent..</Form.Label>
                        {this.state.approvedAgentID ? (
                          <Form.Control
                            as="select"
                            custom
                            // value={this.state.approvedAgent.agent_id}
                          >
                            <option value={this.state.approvedAgentID}>
                              {this.state.approvedAgentName}
                            </option>
                          </Form.Control>
                        ) : (
                          <Form.Control
                            as="select"
                            custom
                            name="agent"
                            onChange={this.handleChange}
                            required={
                              this.state.rfq_status == "Approved" ||
                              this.state.profile_type == "B2B"
                            }
                          >
                            <option value="">Select Agent</option>
                            {this.props.businessData.agent.map(
                              (item, index) => {
                                return (
                                  <option
                                    key={index}
                                    selected={
                                      this.props.businessData.agent_id ==
                                      item.user_id
                                    }
                                    value={item.user_id}
                                  >
                                    {item.firstname}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        )}
                      </Form.Group>
                    </Col>
                  ) : null}
                  {/* Ageent Input Field Ends here */}

                  <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="businessGroupLabel">
                        Customer Group..
                      </Form.Label>
                      {this.state.customerGroup.map((item, index) => {
                        const disabled = this.disabledMethodB2B(
                          item.id,
                          this.state.rfq_status
                        );
                        return (
                          <Form.Check
                            type="radio"
                            inline
                            checked={
                              disabled
                                ? false
                                : item.id == this.state.checkedCustomerGroup
                            }
                            label={item.name}
                            key={index}
                            id={item.id}
                            onChange={this.handleCustomerGroup}
                            disabled={disabled}
                            required
                          />
                        );
                      })}
                    </Form.Group>
                  </Col>
                  {this.state.showB2B ? ( //Condition to show the businessGroup in case of choosing B2B
                    <>
                      <Col sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="businessGroupLabel">
                            Business Group
                          </Form.Label>
                          {this.state.businessGroup.map((item, index) => {
                            const disabled = this.disabledMethod(
                              item.id,
                              this.state.rfq_status
                            );
                            return (
                              <Form.Check
                                type="radio"
                                inline
                                key={item.id + index}
                                checked={
                                  disabled
                                    ? false
                                    : item.id == this.state.checkedBusinessGroup
                                }
                                id={item.id}
                                name="business_group"
                                label={item.name}
                                onChange={this.handleBusinessGroup}
                                disabled={disabled}
                              />
                            );
                          })}
                        </Form.Group>
                      </Col>

                      <Col sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="businessGroupLabel">
                            Business Type
                          </Form.Label>
                          {this.state.others == false ? (
                            this.state.businessType.map((item, index) => {
                              const disabled = this.disabledMethodType(
                                item.id,
                                this.state.rfq_status
                              );
                              let checked =
                                this.state.checkedBusinessType.indexOf(
                                  item.id
                                ) != -1;
                              return (
                                <Form.Check
                                  // type="checkbox"
                                  type={
                                    this.state.checkedBusinessGroup == 2
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  inline
                                  key={item.id + index}
                                  // checked={checked}
                                  checked={this.state.checkedBusinessType.includes(
                                    item.id
                                  )}
                                  id={item.id}
                                  name="business_type"
                                  onChange={this.handleBusinessType}
                                  label={item.name}
                                  disabled={disabled}
                                />
                              );
                            })
                          ) : (
                            <Form.Control
                              placeholder="Others"
                              name="business_type_other"
                              value={this.state.business_type_other}
                              onChange={this.handleChange}
                              required
                            />
                          )}
                        </Form.Group>
                      </Col>
                    </>
                  ) : null}

                  {this.state.checkedBusinessGroup == 2 ? (
                    <Col sm={12}>
                      <Form.Group controlId="formGridCustomerName">
                        <Form.Label style={{ marginRight: "75px" }}>
                          Sub Business Type
                        </Form.Label>
                        {this.state.selectedBusinessTypesRadio
                          ? this.state.subBusinessType?.map((item, index) => {
                              const disabled = this.disabledMethodSubType(
                                item.id,
                                this.state.rfq_status
                              );
                              const filterChecked = [
                                "",
                                null,
                                undefined,
                              ].includes(
                                this.props?.businessData?.sub_business_types_id
                              )
                                ? false
                                : this.props?.businessData?.sub_business_types_id
                                    .split(",")
                                    .map((d) => parseInt(d));

                              {
                                this.state.checkedSubBusinessType =
                                  filterChecked.length > 0 ? filterChecked : [];
                              }
                              let checked = Array.isArray(filterChecked)
                                ? filterChecked.includes(item.id)
                                : false;
                              return (
                                <>
                                  <Form.Check
                                    type="checkbox"
                                    inline
                                    onChange={this.handleSubBusinessType}
                                    key={item.id + index}
                                    // checked={
                                    //   this.state.checkedBusinessGroup==2 ?
                                    //   this.state.selectedOption :
                                    //   checked
                                    // }
                                    checked={checked}
                                    id={item.id}
                                    name="sub_business_type"
                                    label={item.name}
                                  />
                                </>
                              );
                            })
                          : ""}
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}

                  {this.state.combine_cats.length > 0 && (
                    <p className="col-sm-12">
                      <b>Service Category</b>
                    </p>
                  )}
                  <div className="col-sm-12 d-flex align-items-center">
                    {this.state.combine_cats.length > 0 ? (
                      <div className="selectedCategoryArea">
                        {this.state.combine_cats.map((item, index) => {
                          return (
                            <span key={index} id={item.id}>
                              {item.name}{" "}
                              <FontAwesomeIcon
                                name={item.name}
                                id={item.id}
                                onClick={this.removeSubBusinessTypeCategory}
                                icon="times-circle"
                              />
                            </span>
                          );
                        })}
                      </div>
                    ) : null}

                    {this.state.combine_cats.length > 0 && (
                      <div>
                        <Button
                          variant="primary"
                          onClick={this.addSubCategories}
                        >
                          Add
                        </Button>
                      </div>
                    )}
                  </div>

              {/* proBusinessCategory component start here selectedCategoryArea*/}
                {this.state.proBusinessCategory.length > 0 ?
                  <> 
                  <h6 className="col-sm-12 mt-2">
                    <b>Services Requested By Customer:</b>
                  </h6>
                  <div className="col-sm-12">
                    {this.state.proBusinessCategory
                      .split(",")
                      .map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <span>
                              { (index ? ', ' : '') + item }
                            </span>
                          </React.Fragment>
                        );
                      })}
                  </div>
                  </>
                 :null}
              {/* proBusinessCategory component ends here */}

                  {this.state.is_multiselect_search && !this.state.hideAgent ? (
                    <Col sm={11}>
                      <div className="categoryArea">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            placeholder="Search category..."
                            type="search"
                            name="serachCategory"
                            value={this.state.serachCategory}
                            onChange={(e) =>
                              this.getBusinessCategoryBySearch(e)
                            }
                            onPaste={(e) =>
                              this.getBusinessCategoryByPasteSearch(e)
                            }
                            onFocus={this.handleCategoryVisibility}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </div>

                      {this.state.show_business_category ? (
                        <div className="selectOptions">
                          {this.state.business_category_array.map(
                            (item, index) => {
                              return (
                                <p
                                  className="options"
                                  onClick={this.selectOption}
                                  key={index}
                                  id={item.id}
                                  title={item.name}
                                >
                                  {item.name}
                                </p>
                              );
                            }
                          )}
                        </div>
                      ) : null}


                      {/* Selected Category Input Box Start here */}
                      {this.state.selected_category_array.length > 0 ? (
                        <div className="selectedCategoryArea">
                          {this.state.selected_category_array.map(
                            (item, index) => {
                              return (
                                <span key={index} id={item.value}>
                                  {item.label}{" "}
                                  <FontAwesomeIcon
                                    name={item.label}
                                    id={item.value}
                                    onClick={this.removeCategory}
                                    icon="times-circle"
                                  />
                                </span>
                              );
                            }
                          )}
                        </div>
                      ) : null}
                      {/* Selected Category Input Box Ends here */}
                    </Col>
                  ) : null}

                <Col sm={12}>
                  <div className="d-flex align-items-center my-2">
                    <strong className="mr-2">Cities Served</strong>
                    {this.state.getProCitiesGroup.map((item) => (
                      <Form.Check
                        type="checkbox"
                        inline
                        key={item.id}
                        id={item.id}
                        label={`${item.name} (${item.cityids.length ?? 0})`}
                        checked={
                          item.cityids.every(city =>
                            this.state.selectedCities.includes(city.name)
                          )
                        }
                        onChange={() => this.handleCitySelection(item)}
                      />
                    ))}
                  </div>
                </Col>
                
                {/* Search City Component Start here  */}
                  <Col sm={11}>
                    <div className="categoryArea">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          placeholder="Search city..."
                          type="search"
                          name="keyword"
                          value={this.state.keyword}
                          onChange={(e) => this.getSearchCityBySearch(e)}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </div>

                    {this.state.show_city_serve_category ? (
                      <div className="selectOptions">
                        {this.state.business_city_serve_array.map((item) => {
                          return (
                            <p
                              className="options"
                              onClick={this.selectCityOption}
                              key={item.id}
                              id={item.id}
                              title={item.name}
                            >
                              {item.name}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}

                    {/* selected_city_serve_array */}
                    {this.state.selected_city_serve_array.length > 0 ? (
                      <div className="selectedCategoryArea">
                        {this.state.selected_city_serve_array.map(
                          (item, index) => {
                            return (
                              <span key={index} id={item.value}>
                                {item.label}{" "}
                                <FontAwesomeIcon
                                  name={item.label}
                                  id={item.value}
                                  onClick={this.removeCities}
                                  icon="times-circle"
                                />
                              </span>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </Col>
                  {/* Search City Component Ends here */}
                </Form.Row>

                <p style={{ marginTop: "0.5rem" }}>
                  <b>Company Detail</b>
                </p>
                <Form.Row className="mt-1">
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label>Employee Count Range</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="employee_count"
                        onChange={this.handleChange}
                        value={this.state.employee_count}
                      >
                        <option value="">
                          {this.state.employee_count.length > 1
                            ? 0
                            : "Select Range"}
                        </option>
                        {this.state.employeeRange &&
                          this.state.employeeRange.map((range) => {
                            return (
                              <option key={range.id} value={range.meta_value}>
                                {range.meta_title}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col sm={4}>
                    <Form.Group style={{ marginBottom: 0 }}>
                      <Form.Label>Year of business</Form.Label>
                    </Form.Group>

                    <AntDatePicker
                      size="medium"
                      className="form-control"
                      onChange={this.handleYear}
                      picker="year"
                      disabledDate={this.disabledDate}
                      value={
                        this.state.yrs_in_business
                          ? this.state.yrs_in_business
                          : this.props.businessData.yrs_in_business
                          ? dayjs(
                              this.props.businessData.yrs_in_business.toString(),
                              "YYYY"
                            )
                          : null
                      }
                    />
                  </Col>

                  <Col sm={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Legal Status</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="legal_status"
                        onChange={this.handleChange}
                        value={this.state.legal_status}
                      >
                        <option value="">Select Legal Status</option>
                        {this.state.legalStatus.map((item, index) => {
                          return (
                            <option
                              key={index}
                              selected={
                                this.props.businessData.legal_status == item.id
                              }
                              value={item.id}
                            >
                              {item.meta_value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label>Annual Revenue</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="turnover"
                        onChange={this.handleChange}
                        value={this.state.turnover}
                      >
                        <option value="">
                          {this.state.turnover > 1 ? 0 : "Select Turn Over"}
                        </option>
                        {this.state.annualRevenueLacs &&
                          this.state.annualRevenueLacs.map((range) => {
                            return (
                              <option key={range.id} value={range.meta_value}>
                                {range.meta_title}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label>Projects Done</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Projects Done"
                        name="projects_done"
                        onChange={this.handleChange}
                        value={
                          this.state.projects_done == 0
                            ? ""
                            : this.state.projects_done
                        }
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Col>

              <Col sm={4} style={{ paddingLeft: 15 }}>
                <h5>Call Disposal - Post Call</h5>
                <CallAttemptedHistory
                  call_attempt_history={this.state.call_attempt_history}
                />

                {this.state.mobileCallShowField && (
                  <>
                    <div>
                      <div
                        className={`Call_Attempt_${this.state.call_attempt_count}`}
                      >
                        <Form.Group controlId="call_attempt_count">
                          <Form.Label>
                            Call Attempt {this.state.call_attempt_count}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name=""
                            onChange={this.handleChangeForCallStatus}
                            required
                          >
                            <option value="">Select Call Attempt Type</option>
                            {this.state.call_status_array.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.id}
                                  name={item.status}
                                >
                                  {item.status}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      {this.state.rfq_status ? (
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Call Status</Form.Label>
                          <Form.Control
                            placeholder="Call Status"
                            disabled
                            name="rfq_status"
                            value={this.state.rfq_status}
                            required
                          />
                        </Form.Group>
                      ) : null}
                      {this.state.rfq_status == "Followup" ? (
                        <Form.Group controlId="formBasicEmail">
                          <DatePicker
                            className="d-block form-control"
                            selected={this.state.startDate}
                            onChange={(date) => this.handleDateChange(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={20}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            minTime={this.state.minTime}
                            maxTime={moment().endOf("day").toDate()}
                            minDate={new Date()}
                            maxDate={addDays(new Date(), 60)}
                            placeholderText="Please Select Date"
                            required
                          />
                        </Form.Group>
                      ) : null}

                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Agent Note</Form.Label>
                        <Form.Control
                          placeholder="Agent Note"
                          name="agent_note"
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                    </div>
                  </>
                )}

                {this.state.button_click_type === "reject" ||
                this.state.remarks ? (
                  <Form.Group controlId="formBasicEmail" className="pt-20">
                    <Form.Label>Reject Reason</Form.Label>
                    <Form.Control
                      placeholder="Reject Reason"
                      name="reject_reason"
                      defaultValue={this.state.remarks}
                      // disabled={this.state.remarks}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                ) : null}

                <Form.Group controlId="formBasicEmail" className="pt-20">
                  <Form.Label>Product Interested</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="product_interested"
                    value={this.state.product_interested}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                {this.state.rfq_status == "Verified PRO" ||
                this.state.current_status == "Verified PRO" ? (
                  <>
                    <Form.Check
                      className="mr-2"
                      inline
                      name="group1"
                      type="checkbox"
                      label={`Give ${this.state.userFreeCredit} free lead credits to professional`}
                      onChange={this.handlebarFreeLeadCredit}
                      checked={
                        this.state.onboarding_credit > 0
                          ? true
                          : this.state.showFreeLeadsCredits
                      }
                    />
                  </>
                ) : null}
              </Col>
            </Form.Row>

            {/* Child Account Table ROW starts */}
            {this.props.isFromProPricing &&
            this.state.proChildAccounts.length > 0 ? (
              <Form.Row>
                <div
                  className="mt-3 w-100"
                  style={{
                    background: "rgb(116 203 251 / 29%)",
                    border: "1px solid black",
                  }}
                >
                  <div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Account ID</th>
                          <th>GST No.</th>
                          <th>Company Name</th>
                          <th>Contact Name</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Pro Pricing Status</th>
                          {this.state.showChildAccounts ? <th>Merge</th> : null}
                          {this.state.showChildAccounts ? (
                            <th>Mark Primary</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.proChildAccounts &&
                          this.state.proChildAccounts.map((account) => (
                            <tr
                              key={account.pro_id}
                              style={{ borderTop: "2px solid black" }}
                            >
                              <td>
                                <b>{account.pro_id}</b>
                              </td>
                              <td>
                                <b>{account.gstNo}</b>
                              </td>
                              <td>
                                <b>{account.company_name}</b>
                              </td>
                              <td>
                                <b>{account.name}</b>
                              </td>
                              <td>
                                <b>{account.phone}</b>
                              </td>
                              <td>
                                <b>
                                  {account.address_1 +
                                    " , " +
                                    account.address_2 +
                                    ", " +
                                    account?.city +
                                    ", " +
                                    account?.state +
                                    ", " +
                                    account?.pin_code}
                                </b>
                              </td>
                              <td>
                                <b>{account.current_status}</b>
                              </td>
                              {this.state.showChildAccounts ? (
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={this.state.selectedChildAccountsProID.includes(
                                      account.pro_id
                                    )}
                                    onChange={() =>
                                      this.handleAccountChecked(
                                        account,
                                        account.pro_id
                                      )
                                    }
                                  />{" "}
                                  {"  "}
                                </td>
                              ) : null}
                              {this.state.showChildAccounts &&
                              ["Approved", "Rejected"].includes(
                                account.current_status
                              ) ? (
                                <td>
                                  <Button
                                    onClick={(e) =>
                                      this.markPrimary(
                                        account.customer_id,
                                        account,
                                        account.pro_id
                                      )
                                    }
                                    variant={
                                      this.state.primaryId?.pro_id ==
                                      account.pro_id
                                        ? "success"
                                        : "primary"
                                    }
                                    // disabled={!this.state.selectedChildAccountsProID.includes(
                                    //   account.pro_id
                                    // )}
                                    size="sm"
                                  >
                                    {this.state.primaryId?.pro_id ==
                                    account.pro_id
                                      ? "Primary"
                                      : "Mark"}
                                  </Button>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Form.Row>
            ) : null}

            {/* Form Submit BUtton Below  */}

            <Form.Row>
              {this.state.show_buttons ? (
                <div>
                  <Button
                    variant="primary"
                    disabled={this.state.isSaveLoading}
                    id={this.state.button_click_type}
                    onClick={!this.state.isSaveLoading ? this.submitForm : null}
                  >
                    {this.state.isSaveLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              ) : null}
            </Form.Row>
          </div>
        )}
      </Form>
      </>
    );
  }
}

export default ApprovalProcess;
