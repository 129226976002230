import Axios from "axios";
import React, { Component } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import Constants from "../../../Config";

const apiUrl = Constants.API_URL;

export class DuplicateLead extends Component {
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
    this.detailRef = React.createRef();
  }
  state = {};
  componentDidMount(){
    this.viewDuplicate();
  }
  viewDuplicate =(e) => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: {
        mobile: this.props.mobile,
        // mobile: "1234567890",
        leadId:[]
      }
      // rfq_status: this.state.options,
      // priority: "Medium",
      // rfq_id: this.state.RFQ_id,
    };
    return Axios(apiUrl + "/view-duplicates-lead", requestoption)
    .then((data)=>{

    })
    .catch((err)=>{
      // console.log(err.response)
    })
  }
  render() {
    return (
      <>
        <Modal
          show={this.props.duplicateShow}
          onHide={this.props.closeModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
         <Modal.Header closeButton></Modal.Header>  
          <Modal.Body>
            <div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Lead Id</th>
                    <th>Source</th>
                    <th>Product</th>
                    <th>Product</th>
                    <th>Mobile</th>
                    <th>Date</th>
                    <th>Quantity</th>
                    <th>GLV</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {this.state.duplicateItems.map({

                    })} */}
                  {/* <td></td> */}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default DuplicateLead;
