import React from "react";
import { getUserDetails } from "../Login/getLoginnedUserDetails";
import Constants from "../../Config";
import axios from "axios";
import { Button, Form, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const apiUrl = Constants.API_URL;

class Profile extends React.Component {
  state = {
    firstname: null,
    lastname: null,
    email: null,
    mobile: null,
    showMobileForm: false,
    showEmailForm: false,
  };
  componentDidMount() {
    this.getProfileDetail();
  }
  getProfileDetail = () => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    let params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: { user_id: getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-user-detail-by-id", params)
      .then((data) => {
        this.setState({
          firstname: data.data.data.firstname,
          lastname: data.data.data.lastname,
          email: data.data.data.email,
          mobile: data.data.data.mobile,
        });
      })
      .catch((error) => console.log(error));
  };
  showMobileForm = () => {
    this.setState({
      showMobileForm: true,
    });
  };
  showEmailForm = () => {
    this.setState({
      showEmailForm: true,
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-4 col-md-4" style={{ margin: "0px auto" }}>
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <img
                  src="https://mccoymart.com/buy/image/catalog/Top-Selling%20Pr%20oducts/UPVC-Windows.jpg"
                  className="rounded-circle"
                  width="150"
                />
                <h4 className="card-title mt-10">
                  {this.state.firstname} {this.state.lastname}
                </h4>
              </div>
            </div>
            <hr className="mb-0 mt-0" />
            <div className="card-body">
              <small className="text-muted d-block">Email address </small>
              {this.state.showEmailForm ? (
                <Form.Row className="FormEmailUpdate pt-10">
                  <Col>
                    <Form.Control
                      placeholder="Email"
                      name="email"
                      style={{ maxHeight: "32px", minHeight: "32px" }}
                      value={this.state.email}
                    />
                  </Col>
                  <Col>
                    <Button variant="success" size="sm">
                      Update
                    </Button>
                  </Col>
                </Form.Row>
              ) : (
                <h6>
                  {this.state.email}
                  <FontAwesomeIcon
                    className="editIcon"
                    icon="edit"
                    onClick={this.showEmailForm}
                  />
                </h6>
              )}

              <small className="text-muted d-block pt-10">Phone</small>
              {this.state.showMobileForm ? (
                <Form.Row className="FormMobileUpdate pt-10">
                  <Col>
                    <Form.Control
                      placeholder="Mobile"
                      name="mobile"
                      style={{ maxHeight: "32px", minHeight: "32px" }}
                      value={this.state.mobile}
                    />
                  </Col>
                  <Col>
                    <Button variant="success" size="sm">
                      Update
                    </Button>
                  </Col>
                </Form.Row>
              ) : (
                <h6>
                  {this.state.mobile}
                  <FontAwesomeIcon
                    className="editIcon"
                    icon="edit"
                    onClick={this.showMobileForm}
                  />
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
