import React from "react";
import { Row, Col, Form, Button, Alert, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Config";
import ProductLists from "../Quotes/ProductLists";
import "./createQuote.css";
import "../../Dashboard/BusinessAccount/addAccount.css";
import { Link, Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApproveQuote from "./ApproveQuote";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddItemModal from "./AddItemModal";
import tooltipArrowIcon from '../../../asset/Images/tooltipArrowIcon.svg'
const apiUrl = Constants.API_URL;
const b2bApiUrl = Constants.B2B_API_URL;

const dataUser = [{ name: "lalit", email: "lait@mccoymart.com" }];

const modules = {
  toolbar: [[{ list: "bullet" }]],
};

const defaultpaymentTermsArray = [
  "Grand total is including taxes"
];

class CreateQuote extends React.Component {
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
    this.state = {
      startDate: new Date(),
      nameData: [],
      displayProductArray: [],
      nameDataArray: [],
      stateArray: [],
      getAllProductsWithDetailForList: [],
      billing_first_name: "",
      billing_last_name: "",
      billing_mobile: "",
      billing_name: "",
      companyName: "",
      billing_company_name: "",
      billing_address_1: "",
      billing_address_2: "",
      billing_city: "",
      billing_zone_id: "",
      billing_postcode: "",
      billing_country: "",
      billing_country_name: "",
      billing_gst: "",
      gst: "",
      gstError: true,
      gstBusinessDetailsError: false,
      shipping_first_name: "",
      shipping_last_name: "",
      shipping_mobile: "",
      shipping_company_name: "",
      shipping_address_1: "",
      shipping_address_2: "",
      shipping_city: "",
      shipping_state_id: "",
      shipping_zone_id: 0,
      shipping_postcode: "",
      shipping_country: "",
      shipping_country_name: "",
      shipping_gst: "",
      selectedCompany: "",
      agentArray: [],
      customer_name: "",
      customer_email: "",
      customer_phone: "",
      agent_id: "",
      addressArray: [],
      selectedAddress: "",
      selectedShippingAddress: "",
      subTotal: "",
      subTotalInterest:"",
      list_price: "",
      list_price_org: "",
      taxTotal: "",
      grandTotal: "",
      company_id: "",
      available_stock: 0,
      customer_id: "",
      user_id: "",
      is_loading: false,
      validated: false,
      newValidated: false,
      is_error: false,
      is_redirect: false,
      quote_subject: "",
      quote_status: "",
      quoteID: "",
      rfqID: 0,
      message: "",
      shippingCharge: 0,
      rfq_id: "",
      business_account_id: "",
      rfq_products: [],
      cutomerName: "lalittestacc",
      details: [dataUser],
      product_id: "",
      productQuantity: "",
      listedNewProductsArray: "",
      main_rfq_id: "",
      agent_name: "",
      customer_email2: "",
      customer_phone2: "",
      customer_name2: "",
      disabled: true,
      companyName2: "",
      phone: "",
      phone2: "",
      selectedOption: "",
      special_tp: "0",
      ticket_id: "",
      vendor_id: "",
      new_address: "",
      new_name: "",
      new_mobile: "",
      new_companyName: "",
      new_address1: "",
      new_address2: "",
      address_array: ["Work", "Home"],
      new_address_type: "Work",
      new_postcode: "",
      new_city: "",
      new_zone_id: "",
      new_state: "",
      new_country: "",
      new_Gstno: "",
      new_country_id: "",
      new_state_id: "",
      isDisable: true,
      gstDisable: false,
      gstnewDisable: false,
      new_address_error_message: "",
      gstErrorMessage: "",
      new_address_success_message: "",
      categoryDisable: true,
      rfq_status: "",
      call_response: "",
      call_id: "",
      call_status: "",
      remarks: "",
      call_attempt_count: "",
      rfq_quote_type: "",
      rfq_agent_id: "",
      rfq_message: "",
      status: "",
      wrong_pin: false,
      new_wrong_pin: false,
      wrong_phone: false,
      productsArr: [],
      quote_type: "",
      productTotal: "",
      magicProductsArray: [],
      showRfq: false,
      quoteDetail: "",
      rfqDisable: true,
      showGstBox: false,
      gstLoading: false,
      showGstAddress: false,
      gst_address: "",
      billing_zone_name: "",
      address_type: "Work",
      shipping_address_type: "Work",
      gstNumber: "",
      gstAddressArray: [],
      gstAddressCount: 0,
      selectedGstAddress: "",
      showModal: false,
      gstAddressArr: [],
      selectedOneGstAddress: "",
      first_name: "",
      mobile_number: "",
      company_name: "",
      gstDefault: "",
      tpMessage: [],
      allowDuplicate: false,
      gstNotInDB: false,
      defaultpaymentTermsArray: [],
      paymentTerms1: "",
      rfqItems: [],
      isEmptyTerm: [],
      showGSTDetailsCheckbox: false,
      showDisabledFields: false,
      showModalVerifyGST: false,
      gstBusinessAddressArr: [],
      selectedOneBusinessGstAddress: "",
      addShippingCharge: "",
      rfqShippingCharges: "",
      storeInputValue: 0,
      customer_does_not_email: false,
      allowedSlugs: [],
      agent_remarks: "",
      manager_remarks: "",
      quoteGrossMargin: 0,
      quoteMinimumGrossMargin: 0,
      showSubmitForApproval: false,
      showSubmitForShippingCharges: false,
      attachment: [],
      discountOnMrp: 0,
      totalDiscountOnMrp: 0,
      totalMrp: 0,
      totalNetAmount: 0,
      sourcingStatus: "",
      existingProducts: new Map(),
      totalTaxAmount: 0,
      totalPayableAmount: 0,
      url: "https://mccoymart.s3.ap-south-1.amazonaws.com/rfqAttachment",
      totalSystemDeliverRate: 0,
      touched:false,
      shippingCharges: 0,
      triggerRender:0,
      paymentTerms: "",
      showCreditPlans:[],
      showMarkupCredit: 0,
      isChecked: false,
      currentInterest: 0,
      totalNetAmountInterest:0,
      totalTaxAmountInterest:0,
      grandTotalInterest: 0,
      selectedPlanId:0,
      selectedPartnerId:0,
      creditCharges:0,
      isCheckboxChecked:false,
      getPartnerId: "",
      getPlanId: "",
      creditPrice: 0,
    };
  }

  handleQuillChange = (content) => {
    this.setState({ paymentTerms: content });
  };

  handleDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  componentDidMount(props) {
    const sourcingStatus = localStorage.getItem("sourcingStatus");
    if (sourcingStatus) {
      this.setState({
        sourcingStatus: sourcingStatus,
      });
    }
    this.setState({
      allowedSlugs: this.props.childSlugProp["listQuote"],
    });
    const quoteState = this.props.quoteState;
    var val = JSON.parse(localStorage.getItem("dataQuote")); // from rfq popup detail link earlier one from BUsiness Account
    var val2 = JSON.parse(localStorage.getItem("dataQuote2")); //from rfq popup detail link new one and from edit in Listquote
    let parseData = JSON.parse(localStorage.getItem("dataKeyEdit")); // for edit from rfq New one
    if (val2 && !quoteState) {
      this.setState(
        {
          quoteDetail: val2.quoteID,
        },
        () => {
          if (val2.quoteID > 0) {
            this.getQuoteDetailByID(val2.quoteID);
          }
        }
      );
    }
    if (val && !quoteState) {
      this.setState(
        {
          quoteDetail: val.quoteID,
        },
        () => {
          if (val.quoteID > 0) {
            this.getQuoteDetailByID(val.quoteID);
          }
        }
      );
    }
    this.setState({
      paymentTerms:
        "<ul><li>" + defaultpaymentTermsArray.join("</li><li>") + "</li></ul>",
    });
    if (
      quoteState === 0 ||
      (quoteState.products && quoteState.products.length)
    ) {
      this.setState({
        quoteID: 0,
        phone: quoteState.phone,
      });
    } else if (quoteState === undefined) {
      if (parseData) {
        this.setState({
          quoteID: parseData.quoteID,
          rfqID: parseData.rfqID,
        });
      } else {
        this.setState({
          quoteID: "undefined",
        });
      }
    } else {
      const quoteID = quoteState.quoteID;
      const rfqID = quoteState.rfqID;
      const phone = quoteState.phone;
      const rfq_status = quoteState.rfq_status;
      const call_response = quoteState.call_response;
      const call_id = quoteState.call_id;
      // const message = quoteState.message;
      const call_status = quoteState.call_status;
      const remarks = quoteState.remarks;
      // agent_id: this.state.agent_id,
      const call_attempt_count = quoteState.call_attempt_count;
      const rfq_quote_type = quoteState.rfq_quote_type;
      const rfq_agent_id = quoteState.agent_id;
      const status = quoteState.status;
      const message = quoteState.message;
      this.setState({
        quoteID: quoteID,
        rfqID: rfqID,
        phone: phone,
        rfq_status: rfq_status,
        call_response: call_response,
        call_id: call_id,
        call_status: call_status,
        remarks: remarks,
        call_attempt_count: call_attempt_count,
        rfq_quote_type: rfq_quote_type,
        rfq_agent_id: rfq_agent_id,
        status: status,
        rfq_message: message,
        quote_type: quoteState.quote_type,
      });
      if (quoteID > 0) {
        this.getQuoteDetailByID(quoteID);
      }
    }
    this.getName();
    this.getAgent();
    this.getState();
    if (
      quoteState &&
      quoteState.rfqID &&
      quoteState.products &&
      quoteState.products.length
    ) {
      this.getDetailByRFQId(quoteState.rfqID, quoteState.products);
    } else if (quoteState && quoteState.rfqID) {
      this.getDetailByRFQId(this.props.quoteState.rfqID);
      // this.getDetailByRFQId(quoteState.rfqID);   //for rfq to quote new tab
    }
    if (
      quoteState &&
      quoteState.rfqID &&
      quoteState.products &&
      quoteState.products.length
    ) {
      this.fetchCreditPlansData(quoteState.rfqID);
    } else if (quoteState && quoteState.rfqID) {
      this.fetchCreditPlansData(this.props.quoteState.rfqID);
    }  
    if (parseData && !quoteState) {
      this.getQuoteDetailByID(parseData.quoteID);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.getAllProductsWithDetailForList.length &&
      prevState.getAllProductsWithDetailForList !=
        this.state.getAllProductsWithDetailForList
    ) {
      let val = false;
      for (let item of this.state.getAllProductsWithDetailForList) {
        if (Number(item.gross_margin) < Number(item.minimum_gross_margin)) {
          val = true;
        }
      }
      if (!this.showSubmitForApproval)
        this.setState({ showSubmitForApproval: val });
    }          
  }
  getQuoteDetailByID = async (quoteID) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    //
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: quoteID,
      },
    };
    return await axios(apiUrl + "/get-quotes", params)
      .then((data) => {
        let dataObject = data.data.data[0];
        let paymentTerms = dataObject.paymentTerms;
        if (paymentTerms.length > 0) {
          paymentTerms = paymentTerms.replace(
            /<ul style=margin-left: -11px>|<ul style=margin-left:-11px>|<ul style=\"margin-left:-11px;>|<ul style=\"margin-left: -11px;>/gi,
            "<ul>"
          );
          this.setState({
            isPaymentTermsChecked: true,
          });
        } else {
          paymentTerms = " ";
        }
        this.setState(
          {
            paymentTerms: paymentTerms,
            customer_email2: dataObject.companyData.email,
            customer_name2: dataObject.companyData.name,
            customer_phone2: dataObject.companyData.telephone,
            companyName2: dataObject.company_id,
            customer_id: dataObject.customer_id,
            selectedOption: dataObject.label_name,
            new_mobile: dataObject.companyData.telephone,
            new_name: dataObject.companyData.name,
            rfqID: dataObject.rfq_id,
            productsArr: dataObject.products,
            isDisable: false,
            gstDisable: true,
            quote_status: dataObject.quote_status,
            gstDefault: dataObject.gst,
            allowDuplicate: dataObject.allowDuplicate,
            showGstBox: dataObject.gst ? true : false,
            showSubmitForApproval:
              dataObject.quote_status == "Pending For Approval" ? false : true,
            showSubmitForShippingCharges:
              dataObject.quote_status == "Pending For Approval" ? false : true,  
          },
          () => {}
        );

        //
        let products = dataObject.products;
        let currentInterest = 0;
        let productTotal = dataObject.productTotal[0];
        let productsArray = [];
        let magicProductsArray = [];
        let productsArray1 = [];
        let subTotal = productTotal ? productTotal.sub_total : 0;

        let grandTotal = productTotal ? productTotal.grand_total : 0;
        let shipping = productTotal ? productTotal.shipping : 0;
        let partnerId = dataObject.partner_id;
        let planId = dataObject.plan_id;
        let creditDetails = dataObject.credit_plans;
        let checkCheckboxValue = false;

        if(partnerId && planId){
         for(let credit of creditDetails){
          if(partnerId == credit.partner_id && planId == credit.plan_id){
            currentInterest = credit.plan_interest
            checkCheckboxValue = true;
          }
         } 
        }
        //let taxTotal = productTotal.tax_total;
        let quoteGrossAmount = 0;
        let quoteMinGrossAmount = 0;
        let discountOnMrpAmount = 0;
        let totalDiscountOnMrpAmount = 0;
        let netTotalAmount = 0;
        let totalMrpAmount = 0;
        let subTotalAmount = 0;
        let totalTaxAmt = 0;
        let totalTaxAmtInterest = 0;
        let totalDeliveryRate = 0;
        let showButton = false;
        let showButtonShippingInput = false;
        let totalNetAmount1 = 0;
        let creditChargesInt = 0;
        let showCreditCharges = 0;
        let totalDiscountOnMrpAmountInterest = 0;
        let netAmountTotal = 0;
        let netTotalAmountInterest = 0;
        let subTotalAmountInterest = 0;

        for (let i = 0; i < products.length; i++) {
          if (products[i].product_type == "1") {
            let price =
              products[i].special_tp == 0
                ? products[i].transfer_price
                : products[i].special_tp;
            
            discountOnMrpAmount = ((Number(products[i].mrp) - Number(products[i].list_price)) / Number(products[i].mrp)) * 100;
            let netTotalSum = netTotalAmount + Number(products[i].net_amount_new);
            let totalDiscount = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
            totalDiscountOnMrpAmount += totalDiscount;
            let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
            totalMrpAmount += totalMrp;
            let netSubTotal = Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
            subTotalAmount = -netSubTotal;
            let totalTax = Number(products[i].tax_in_amount);
            totalTaxAmt += totalTax;

            let listPrice = (Number(products[i].markup) * Number(price)) / 100 + Number(price);
            let listPriceFinal = Number(listPrice);
            let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(currentInterest)/100);
            let listPriceFinalInterest = Number(listPriceInterest);
            let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
            let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
            let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
            let totalTaxInterest = Number(tax_in_amountInterest);
            totalTaxAmtInterest += totalTaxInterest
            let netAmount = listPriceFinal * Number(products[i].quantity);
            netAmountTotal += netAmount;
            let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
            creditChargesInt += Number(creditChargesInterest); 
            let creditCharge = Number(netAmountIntrest) - Number(netAmount);

            showCreditCharges += creditCharge;
            
            let netAmountFinalInterest = Number(netAmountIntrest);
           let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);
           let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest;
           let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest; 
           totalDiscountOnMrpAmountInterest += totalDiscountInterest;
           
           let netSubTotalInterest = Number(totalDiscountOnMrpAmountInterest) - Number(totalMrpAmount);
           subTotalAmountInterest = -netSubTotalInterest;
           let systemDeliveryRate = (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
           totalDeliveryRate += systemDeliveryRate * Number(118 / 100);
           quoteGrossAmount += (Number(products[i].gross_margin) / 100) * Number(netAmount);
           quoteMinGrossAmount += (Number(products[i].minimum_gross_margin) / 100) * Number(netAmount);

            if (
              !showButton &&
              Number(products[i].gross_margin) <
                Number(products[i].minimum_gross_margin)
            ) {
              showButton = true;
            }
            if (
              !showButtonShippingInput &&
              Number(this.state.storeInputValue) <
                Number(this.state.totalSystemDeliverRate)
            ) {
              showButtonShippingInput = true;
            }

            productsArray.push({
              product_id: products[i].product_id,
              name: products[i].name,
              brand: products[i].brand,
              quantity_type: products[i].quantity_type,
              tax: products[i].tax,
              mrp: products[i].mrp,
              model: products[i].model,
              transfer_price: products[i].transfer_price,
              special_tp: Number(products[i].special_tp),
              vendor: products[i].vendor,
              ticket_id: products[i].ticket_id,
              vendor_id: products[i].vendor_id,
              markup: Number(products[i].markup),
              markupInterest: Number(products[i].markup) + Number(currentInterest),
              quantity: products[i].quantity,
              list_price: Number(listPrice),
              list_price_interest: Number(listPriceInterest),
              // list_price_org: Number(products[i].list_price_org),
              list_price_org: Number(listPriceInterest),
              discount_on_mrp: Number(products[i].discount_on_mrp),
              net_amount: Number(products[i].net_amount_new),
              net_amount_interest: Number(netAmountIntrest),
              // net_amount_org: Number(products[i].net_amount),
              net_amount_org: Number(netAmountIntrest),
              tax_in_percentage: Number(products[i].tax_in_percentage),
              tax_in_amount: Number(products[i].tax_in_amount),
              tax_in_amount_interest: Number(totalTaxInterest),
              total: Number(products[i].total),
              totalInterest: Number(totalInterest),
              gross_margin: Number(products[i].gross_margin),
              minimum_gross_margin: Number(products[i].minimum_gross_margin),
              deliveryCharges: Number(products[i].deliveryCharges),
            });
          } else {
            magicProductsArray.push({
              product_id: products[i].product_id,
              name: products[i].name,
              brand: products[i].brand,
              quantity_type: products[i].quantity_type,
              tax: products[i].tax,
              mrp: products[i].mrp,
              model: products[i].model,
              transfer_price: products[i].transfer_price,
              special_tp: Number(products[i].special_tp),
              vendor: products[i].vendor,
              ticket_id: products[i].ticket_id,
              vendor_id: products[i].vendor_id,
              markup: products[i].markup,
              markupInterest: Number(products[i].markup) + Number(currentInterest),
              quantity: products[i].quantity,
              list_price: Number(products[i].list_price),
              list_price_interest: Number(products[i].list_price),
              list_price_org: Number(products[i].list_price_org),
              discount_on_mrp: Number(products[i].discount_on_mrp),
              net_amount: Number(products[i].net_amount_new),
              net_amount_interest: Number(products[i].net_amount_new),
              net_amount_org: Number(products[i].net_amount),
              tax_in_percentage: Number(products[i].tax_in_percentage),
              tax_in_amount: Number(products[i].tax_in_amount),
              tax_in_amount_interest: Number(products[i].tax_in_amount),
              total: Number(products[i].total),
              totalInterest: Number(products[i].total),
              gross_margin: Number(products[i].gross_margin),
              minimum_gross_margin: Number(products[i].minimum_gross_margin),
              deliveryCharges: Number(products[i].deliveryCharges),
            });
          }
          totalNetAmount1 += Number(products[i].net_amount_new);
          productsArray1.push({
            product_id: products[i].product_id,
            quantity: JSON.stringify(products[i].quantity),
          });
        }
        this.setState({
          currentInterest: currentInterest,
          checkCheckboxValue: checkCheckboxValue,
          creditCharges: Number(showCreditCharges),
          quoteGrossMargin: quoteGrossAmount,
          quoteMinimumGrossMargin: quoteMinGrossAmount,
          discountOnMrp: discountOnMrpAmount,
          totalDiscountOnMrp: totalDiscountOnMrpAmount,
          totalMrp: totalMrpAmount,
          totalNetAmount: totalNetAmount1,
          totalNetAmountInterest: subTotalAmountInterest,
          totalTaxAmount: totalTaxAmt,
          totalTaxAmountInterest: totalTaxAmtInterest,
          totalSystemDeliverRate: this.state.totalSystemDeliverRate + totalDeliveryRate,
          shippingCharges: Number(shipping),
          storeInputValue: Number(shipping),
          showCreditPlans: creditDetails, 
          getPlanId: planId.toString(),
          getPartnerId: partnerId.toString(),
        });
        this.setState({
          showSubmitForApproval: showButton,
          showSubmitForShippingCharges: showButtonShippingInput,
        });
        var d = new Date(dataObject.valid_upto);

        this.setState(
          {
            agent_id: dataObject.agent_id,
            agent_remarks: dataObject.agent_remarks
              ? dataObject.agent_remarks
              : "",
            manager_remarks: dataObject.manager_remarks
              ? dataObject.manager_remarks
              : "",
            quote_subject: dataObject.quote_subject,
            valid_upto: dataObject.valid_upto,
            startDate: d,
            companyName: dataObject.companyData.company_name,
            customer_name:
              dataObject.companyData.firstname +
              " " +
              dataObject.companyData.lastname,
            customer_email: dataObject.companyData.email,
            customer_phone: dataObject.companyData.telephone,
            addressArray: dataObject.companyData.address,
            customer_id: dataObject.customer_id,
            company_id: dataObject.company_id,
            user_id: dataObject.companyData.user_id,
            billing_first_name: dataObject.firstname,
            billing_last_name: dataObject.lastname,
            billing_name: dataObject.name,
            billing_company_name: dataObject.company_name
              ? dataObject.company_name
              : "",
            billing_mobile: dataObject.mobile,
            billing_address_1: dataObject.address_1 ? dataObject.address_1 : "",
            billing_address_2: dataObject.address_2 ? dataObject.address_2 : "",
            // selectedAddress: dataObject.address_1,
            billing_city: dataObject.city ? dataObject.city : "",
            billing_zone_id: dataObject.zone_id ? dataObject.zone_id : "",
            billing_postcode: dataObject.postcode ? dataObject.postcode : "",
            billing_country: dataObject.country_id,
            billing_country_name: dataObject.country_name,
            billing_gst: dataObject.gst ? dataObject.gst : "",
            shipping_first_name: dataObject.shipping_firstname
              ? dataObject.shipping_firstname
              : dataObject.companyData.name,
            shipping_last_name: dataObject.shipping_lastname,
            shipping_mobile: dataObject.shipping_mobile,
            shipping_company_name: dataObject.shipping_company_name,
            shipping_address_1: dataObject.shipping_address_1,
            shipping_address_2: dataObject.shipping_address_2,
            shipping_city: dataObject.shipping_city,
            shipping_zone_id: dataObject.shipping_zone_id,
            shipping_postcode: dataObject.shipping_postcode,
            shipping_country_name: dataObject.shipping_country_name,
            shipping_country: dataObject.shipping_country_id,
            shipping_gst: dataObject.shipping_gst,
            shipping_address_type: dataObject.shipping_address_type,
            getAllProductsWithDetailForList: productsArray,
            displayProductArray: productsArray1,
            subTotal: Number(subTotal),
            shippingCharges: Number(shipping),
            grandTotalInterest: Number(Number(subTotalAmountInterest)+Number(totalTaxAmtInterest)+Number(shipping)) ,
            first_name: dataObject.firstname ? dataObject.firstname : "",
            company_name: dataObject.company_name
              ? dataObject.company_name
              : "",
            mobile_number: dataObject.mobile ? dataObject.mobile : "",
            //for magic quote item
            magicProductsArray: magicProductsArray,
            productTotal: dataObject.productTotal[0].shipping,
          },
          () => {
            const gstAddressArray = this.state.addressArray.filter(
              (item) =>
                item.gst_address == 1 || (item.gstin && item.gstin.length == 15)
            );
            this.setState({
              gstAddressArray,
              gstAddressCount: gstAddressArray ? gstAddressArray.length : 0,
            });
          }
        );
      })
      .catch((err) => console.log(err));
  };
  getQuoteDetail = () => {
    this.setState(
      {
        // is_loading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          data: {
            request_quote_id: this.props.quoteState.rfqdata
              ? this.props.quoteState.rfqdata
              : this.props.quoteState.rfqID,
          },
        };
        return axios(apiUrl + "/get-quote-request", options)
          .then((data) => {
            let requestQuoteData = data.data.data[0];
            this.setState({
              rfqShippingCharges: requestQuoteData.shippingCharges,
            });
          })
          .catch((err) => console.log(err));
      }
    );
  };
  getName = async () => {
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return await axios(apiUrl + "/get-business-account", requestoption)
      .then((data) => {
        this.setState({
          nameDataArray: data.data.data,
          nameData: data.data.data.map((item) => {
            return {
              name: item.company_name,
              value: item.company_name,
            };
          }),
        });
      })
      .catch((err) => console.log(err));
  };
  getState = async () => {
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return await axios(apiUrl + "/get-all-states", requestoption)
      .then((data) => {
        this.setState({
          stateArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getAgent = async () => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        agent_list: "",
        id: [56, 54],
        showAll: "",
        slug: "createQuote",
      },
    };

    return await axios(apiUrl + "/get-pre-sales-agents", requestoption)
      .then((data) => {
        this.setState({
          agentArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  customer_idB2 = (value) => {
    this.setState({
      customer_id: value,
    });
    //   this.displayAllProducts(e)
  };
  customerMobile = (value) => {
    this.setState({
      customer_mobile: value,
    });
  };

  newHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeAddressTypeRadio = (e) => {
    this.setState({
      new_address_type: e.target.value,
    });
  };
  handleChangeShippingAddressTypeRadio = (e) => {
    this.setState({
      shipping_address_type: e.target.value,
    });
  };
  handleChangeBillingAddressTypeRadio = (e) => {
    this.setState({
      address_type: e.target.value,
    });
  };
  newHandleChangePincode = (e) => {
    this.setState(
      {
        new_postcode: e.target.value,
        gstNewDisable: true,
      },
      () => {
        if (this.state.new_postcode.length == 6) {
          this.setState({
            new_wrong_pin: false,
          });
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.new_postcode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                new_city: data.data.data[0].city,
                new_state: data.data.data[0].state,
                new_zone_id: data.data.data[0].shop_state_id,
                new_state_id: data.data.data[0].state_id,
                new_country: "India",
                new_country_id: 99,
                new_wrong_pin: false,
              });
              this.validateGSTNumber(
                this.state.new_Gstno,
                this.state.new_zone_id
              );
            })
            .catch((error) => {
              this.setState({
                new_city: "",
                new_zone_id: "",
              });
            });
        } else {
          this.setState({
            new_wrong_pin: true,
            new_city: "",
            new_zone_id: "",
          });
        }
      }
    );
  };
  getNewZoneID = (e) => {
    this.setState({
      new_zone_id: e.target.value,
    });
    this.validateGSTNumber(this.state.new_Gstno, e.target.value);
  };

  getPhoneValue = (e, type = "details") => {
    let inputTarget;
    if (type === "details") {
      inputTarget = e.target;
    } else {
      inputTarget = e;
    }
    if (inputTarget.value.length === 10) {
      this.setState({
        phone2: inputTarget.value,
      });
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: inputTarget.value },
      })
        .then((data) => {
          if (data.data.data[0]) {
            this.setState({
              addressArray: data.data.data[0].address,
              companyName: data.data.data[0].customerCompany,
              customer_id: data.data.data[0].customer_id,
              customer_email2: data.data.data[0].email,
              customer_name2: data.data.data[0].customerName,
              customer_phone2: data.data.data[0].telephone,
              new_name: data.data.data[0].customerName,
              new_mobile: data.data.data[0].telephone,
              isDisable: false,
              categoryDisable: true,
              wrong_phone: false,
            });
          } else {
            this.setState({
              customer_phone2: inputTarget.value,
              companyName: "",
              customer_name2: "",
              customer_email2: "",
              wrong_phone: true,
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({
        wrong_phone: true,
        companyName: "",
        customer_name2: "",
        customer_email2: "",
      });
    }
  };

  getPhoneValue2 = () => {
    if (this.state.phone) {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: this.state.phone },
      })
        .then((data) => {
          if (data.data.data[0]) {
            this.setState({
              addressArray: data.data.data[0].address,
              companyName:
                data.data.data[0].address.length > 0
                  ? data.data.data[0].address[0].company
                  : "",
              customer_id: data.data.data[0].customer_id,
              customer_email2: data.data.data[0].email,
              customer_name2: data.data.data[0].customerName,
              customer_phone2: data.data.data[0].telephone,
              isDisable: false,
            });
          } else {
            this.setState({
              customer_phone: this.state.phone,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  openModal = () => {
    this.setState({
      show: true,
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
      showRfq: false,
    });
  };

  handleChangePincode = (e) => {
    this.setState(
      {
        billing_postcode: e.target.value,
      },
      () => {
        if (this.state.billing_postcode.length == 6) {
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.billing_postcode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                billing_city: data.data.data[0].city,
                billing_zone: data.data.data[0].state,
                billing_zone_id: data.data.data[0].shop_state_id,
                billing_country_name: "India",
                billing_country: 99,
                gstDisable: true,
                wrong_pin: false,
              });
              this.validateGSTNumber(
                this.state.billing_gst,
                this.state.billing_zone_id
              );
            })
            .catch((err) => console.log(err));
        } else {
          this.setState({
            billing_city: "",
            billing_zone: "",
            billing_zone_id: "",
            billing_country_name: "",
            billing_country: "",
            gstDisable: false,
            wrong_pin: true,
          });
        }
      }
    );
  };
  validateGSTNumber = (gstin, state_id) => {
    let state_name = this.state.stateArray.filter((item) => {
      return state_id == item.id;
    });
    if (gstin.length > 0) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },
        data: {
          gstin: gstin,
          state: state_name.length ? state_name[0].name : "",
        },
      };
      return axios(apiUrl + "/verifyGSTNumber", options)
        .then((data) => {
          if (data.data.message == "success") {
            this.setState({
              gstError: true,
            });
          } else {
            this.setState(
              {
                gstError: false,
                gstErrorMessage: "Enter Valid GST number",
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    gstErrorMessage: "",
                  });
                }, 3000);
              }
            );
          }
        })
        .catch((error) => {
          this.setState(
            {
              gstError: false,
              gstErrorMessage: "Enter Valid GST number",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  gstErrorMessage: "",
                });
              }, 3000);
            }
          );
          this.setState();
        });
    }
  };

  handleGstChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const regex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
    if (e.target.value.match(regex)) {
      this.setState({
        gstError: true,
        wrongFormat: "",
      });
    } else {
      this.setState({
        wrongFormat: "Wrong Gst Format",
        gstError: false,
      });
    }
  };

  handleBusinessGstChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const regex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
    if (e.target.value.match(regex)) {
      this.setState({
        gstBusinessDetailsError: false,
      });
    } else {
      this.setState({
        gstBusinessDetailsError: true,
      });
    }
  };

  handleNewGstChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length == 0) {
      this.setState({ gstError: true });
    }
    this.validateGSTNumber(e.target.value, this.state.new_zone_id);
  };

  addNewAddress = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ newValidated: true });
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        customer_id: this.state.customer_id.toString(),
        customer_name: this.state.new_name
          ? this.state.new_name
          : this.state.customer_name2,
        company_name: this.state.new_companyName,
        address_1: this.state.new_address1,
        address_2: this.state.new_address2,
        city: this.state.new_city,
        pin_code: this.state.new_postcode,
        gstin: this.state.gstNumber,
        customer_mobile: this.state.new_mobile
          ? this.state.new_mobile
          : this.state.customer_phone2,
        country_id: this.state.new_country_id.toString(),
        zone_id: this.state.new_zone_id.toString(),
        address_type: this.state.new_address_type,
        gstBilling: this.state.showGSTDetailsCheckbox,
      },
    };

    axios(apiUrl + "/add-address", requestOptions)
      .then((data) => {
        const phoneInput = document.getElementById("customerPhone");
        this.getPhoneValue(phoneInput, "new_address");
        if (data.status == 200) {
          this.setState(
            {
              new_name: "",
              new_companyName: "",
              new_address1: "",
              new_address2: "",
              new_city: "",
              new_postcode: "",
              gstNumber: "",
              new_mobile: "",
              new_country_id: "",
              new_zone_id: "",
              new_address_type: "",
              showGSTDetailsCheckbox: false,
              showDisabledFields: false,
              newValidated: false,
              new_address_error_message: "",
              new_address_success_message: "Address added successfully",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  new_address_success_message: "",
                });
              }, 5000);
            }
          );
          this.closeModal();
        }
      })
      .catch((err) => {
        this.setState({
          new_address_error_message: err.response.data.message,
        });
        this.setState(() => {
          setTimeout(() => {
            this.setState({
              new_address_error_message: "",
            });
          }, 5000);
        });
      });
  };
  handleChangeShippingPincode = (e) => {
    this.setState(
      {
        shipping_postcode: e.target.value,
      },
      () => {
        if (this.state.shipping_postcode.length == 6) {
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.shipping_postcode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                shipping_city: data.data.data[0].city,
                shipping_zone: data.data.data[0].state,
                shipping_zone_id: data.data.data[0].shop_state_id,
                shipping_country_name: "India",
                shipping_country: 99,
              });
            })
            .catch((err) => console.log(err));
        } else {
          this.setState({
            shipping_city: "",
            shipping_zone: "",
            shipping_zone_id: "",
            shipping_country_name: "",
            shipping_country: "",
          });
        }
      }
    );
  };

  getDetailsbyAccountName = (e) => {
    const getSelectedAccountName = e;
    let index = {};
    if (this.state.business_account_id > 0) {
      index = { id: this.state.business_account_id };
    } else {
      index = this.state.nameDataArray.filter((el) => el.company_name == e)[0];
    }
    const requestOptions = {
      method: "POST",
      data: {
        company_id: index.id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };

    return axios(apiUrl + "/get-details-by-accountname", requestOptions)
      .then((response) => {
        this.setState(
          {
            addressArray: response.data.data.address,
            company_id: index.id,
            companyName: response.data.data.company_name,
            customer_id: response.data.data.customer_id,
            user_id: response.data.data.user_id,
            agent_id: response.data.data.agent_id,
            customer_name2:
              response.data.data.firstname + " " + response.data.data.lastname,
            customer_email2: response.data.data.email,
            customer_phone2: response.data.data.telephone,
            isDisable: false,
          },
          () => {
            const gstAddressArray = this.state.addressArray.filter(
              (item) =>
                item.gst_address == 1 || (item.gstin && item.gstin.length == 15)
            );
            this.setState({
              gstAddressArray,
              gstAddressCount: gstAddressArray ? gstAddressArray.length : 0,
            });
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getDetailByRFQId = async (e, products) => {
    let productMap = new Map();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: {
        rfq_id: e,
      },
    };
    if (products) {
      for (let product of products) {
        productMap.set(product.product_id, product);
      }
      this.setState({ existingProducts: productMap });
      products = products.filter(
        (product) => product.availabilityType !== "Not Avl"
      );
    }

    return await axios(apiUrl + "/business-account-details", requestOptions)
      .then((response) => {
        const rfq_products = [];
        let rfqItems = response.data.data.product;
        let getAllAttachment = response.data.data.attachments;

        response.data.data.product &&
          response.data.data.product.map((el) => {
            return rfq_products.push({
              product_id:
                el.product_id == null ? (el.product_id = 0) : el.product_id,
              quantity: el.productQuantity,
            });
          });
        this.setState(
          {
            main_rfq_id: response.data.data.id,
            rfq_id: response.data.data.id,
            business_account_id: response.data.data.company_id,
            rfq_products: rfq_products,
            rfqItems,
            attachment: getAllAttachment,
            customer_id: response.data.data.customer_id,
          },
          () => {
            if (products && products.length && response.data.data.company_id) {
              this.getDetailsbyAccountName(this.state.business_account_id);
              this.displayAllProducts(products, productMap);
            } else if (
              products &&
              products.length &&
              response.data.data.company_id === 0
            ) {
              this.getPhoneValue2();
              this.displayAllProducts(products, productMap);
            } else if (response.data.data.company_id) {
              this.getDetailsbyAccountName(this.state.business_account_id);
              this.displayAllProducts();
            } else {
              this.getPhoneValue2();
              this.displayAllProducts(this.state.rfq_products);
            }
          }
        );
      })
      .catch((error) => {});
  };

  fetchCreditPlansData = async (rfqID) => {
    this.setState({ is_loading: true, error: null });
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        },
        data: { rfq_id: Number(rfqID) },
      };
      const response = await axios(apiUrl + "/get-plans", options);
      const apiData = response.data.data;
      this.setState(
        {
          showCreditPlans: apiData,
          is_loading: false
        }
      );
    } catch (error) {
      console.error(error,"Error fetching",error.response?.data?.message);
      this.setState({
        is_loading: false,
      });
    }
  };

  handleCheckboxSelection = (checkboxData) => {
     const updatedCreditPlans = this.state.showCreditPlans.map((plan) => ({
      ...plan,
      isChecked: plan.plan_interest === checkboxData.plan_interest && checkboxData.isChecked,
     }));
     let checkCheckboxValue = checkboxData.isChecked ? checkboxData.isChecked : false; 
     if(checkboxData.isChecked){
      this.setState({
        getPartnerId: checkboxData.partner_id,
        getPlanId: checkboxData.plan_id
      })
     }else{
        this.setState({
          getPartnerId: "",
          getPlanId: ""
        })
     }
     let interest = checkboxData.isChecked ? checkboxData.plan_interest : 0;
     let plan_id = checkboxData.isChecked ? checkboxData.plan_id : 0;
     let partner_id = checkboxData.isChecked ? checkboxData.partner_id : 0;
     let plan_days = checkboxData.isChecked ? checkboxData.plan_days : "";
     let partner_name = checkboxData.isChecked ? checkboxData.partner_name : "";
     let selectedPaymentTerms = (plan_days && partner_name) ? `Payment Terms : ${plan_days} days via ${partner_name}` : "Payment terms - 100% advance";
     let products = this.state.getAllProductsWithDetailForList;
     let productsArray = [];
     let subTotal = 0;
     let subTotalInterest = 0;
     let shippingRate = 0;
     let updatedMinQuoteGross = 0;
     let updatedQuoteGross = 0;
     let discountOnMrpAmount = 0;
     let totalDiscountOnMrpAmount = 0;
     let totalDiscountOnMrpAmountInterest = 0;
     let discountOnMrpAmountInterest = 0;
     let netTotalAmount = 0;
     let netTotalAmountInterest = 0;
     let totalMrpAmount = 0;
     let subTotalAmount = 0;
     let subTotalAmountInterest = 0;
     let totalTaxAmt = 0;
     let totalTaxAmtInterest = 0;
     let totalDeliveryRate = 0;
     let creditChargesInterests = 0;
 
     for (let i = 0; i < products.length; i++) {
       let transfer_price =
         products[i].special_tp == 0
           ? products[i].transfer_price
           : products[i].special_tp;
       let ticket_id =
         products[i].ticket_id.length == 0
           ? this.state.ticket_id
           : products[i].ticket_id;
       let vendor_id =
         products[i].vendor_id.length == 0
           ? this.state.vendor_id
           : products[i].vendor_id;
 
       let listPrice = (Number(products[i].markup) * Number(transfer_price)) / 100 + Number(transfer_price);
       let listPriceFinal = Number(listPrice);
       let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(interest)/100);     
       let listPriceFinalInterest = Number(listPriceInterest);
       let netAmount = listPriceFinal * Number(products[i].quantity);
       let netAmountOrg = Number(listPriceFinal) * Number(products[i].quantity);
       let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
       let netAmountOrgIntrest = Number(listPriceFinalInterest) * Number(products[i].quantity);
       let netAmountFinal = Number(netAmount);
       let netAmountOrgFinal = Number(netAmountOrg);
       let netAmountFinalInterest = Number(netAmountIntrest);
       let netAmountOrgFinalInterest = Number(netAmountOrgIntrest);
       let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
       let tax_in_amount_final = Number(tax_in_amount);
       let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
       let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
       let total = Number(tax_in_amount_final) + Number(netAmountFinal);
       let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);
       let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
       creditChargesInterests += checkboxData.isChecked ? creditChargesInterest : 0; 
       let tpInToQuantity =Number(transfer_price) * Number(products[i].quantity);
       let tpInToQuantityR;
       if (netAmountFinal == 0) {
         tpInToQuantityR = 0;
       } else {
         tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;
       } 
       let grossMargin = Number(tpInToQuantityR * 100);
       subTotal = subTotal + total;
       subTotalInterest = subTotalInterest + totalInterest;
       updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
       updatedMinQuoteGross += (Number(products[i].minimum_gross_margin) / 100) * transfer_price * products[i].quantity;
       discountOnMrpAmount = ((Number(products[i].mrp) - Number(listPriceInterest)) / Number(products[i].mrp)) * 100;
       discountOnMrpAmountInterest =  ((Number(products[i].mrp) - Number(listPriceFinalInterest)) / Number(products[i].mrp)) * 100;
       let netTotalSum = netTotalAmount + netAmountFinal;
       let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest
       let totalDiscount = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
       let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest;
       totalDiscountOnMrpAmount += totalDiscount;
       totalDiscountOnMrpAmountInterest += totalDiscountInterest;
       let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
       totalMrpAmount += totalMrp;
       let netSubTotal = Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
       subTotalAmount = -netSubTotal;
       let netSubTotalInterest = Number(totalDiscountOnMrpAmountInterest) - Number(totalMrpAmount);
       subTotalAmountInterest =- netSubTotalInterest;
       let totalTax = Number(tax_in_amount);
       totalTaxAmt += totalTax;
      let totalTaxInterest = Number(tax_in_amountInterest);
      totalTaxAmtInterest += totalTaxInterest
      let systemDeliveryRate =  (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
       productsArray.push({
         product_id: products[i].product_id,
         name: products[i].name,
         quantity_type: products[i].quantity_type,
         tax: products[i].tax,
         mrp: Number(products[i].mrp),
         model: products[i].model,
         transfer_price: products[i].transfer_price,
         special_tp: products[i].special_tp,
         ticket_id: ticket_id,
         vendor_id: vendor_id,
         available_stock: products[i].available_stock,
         markup: products[i].markup,
         markupInterest: Number(products[i].markup)+Number(interest),
         quantity: products[i].quantity,
         list_price: listPriceFinal,
         list_price_org: listPriceFinal,
         list_price_interest:listPriceFinalInterest,
         list_price_org_interest:listPriceFinalInterest,
         net_amount: Number(netAmountIntrest),
         net_amount_org: netAmountOrgIntrest,
         net_amount_interest: Number(netAmountIntrest),
         net_amount_org_interest: netAmountOrgIntrest,
         tax_in_percentage: products[i].tax_in_percentage,
         tax_in_amount: tax_in_amount_final,
         tax_in_amount_interest: tax_in_amount_finalInterest,
         total: Number(total),
         totalInterest: Number(totalInterest),
         gross_margin: grossMargin ? grossMargin : 0,
         vendor: products[i].vendor,
         minimum_gross_margin: Number(products[i].minimum_gross_margin),
         discount_on_mrp: discountOnMrpAmount,
         deliveryCharges: Number(products[i].deliveryCharges)
       });
     }
    let grandTotalPriceInterest = Number(subTotalAmountInterest) + Number(totalTaxAmtInterest) + Number(this.state.shippingCharges);
    let newTermsArray = [...defaultpaymentTermsArray,`${selectedPaymentTerms}`]
    const newStyledTerm = "<ul><li>" + newTermsArray.join("</li><li>") + "</li></ul>";
    this.setState((prevState) => ({
      currentInterest: interest,
      creditCharges: creditChargesInterests,
      checkCheckboxValue: checkCheckboxValue,
      selectedPlanId: plan_id,
      selectedPartnerId: partner_id,
      paymentTerms: newStyledTerm, 
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      getAllProductsWithDetailForList: productsArray,
      showCreditPlans: updatedCreditPlans,
      subTotal: Number(subTotal),
      totalNetAmount: subTotalAmount,
      totalNetAmountInterest: subTotalAmountInterest,
      // totalTaxAmount: totalTaxAmt,
      totalTaxAmountInterest: Number(totalTaxAmtInterest),
      grandTotalInterest: Number(grandTotalPriceInterest)
    }));
  };

  getSelectAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedAddress: e.target.value,
        billing_zone_name: "",
      });
      let index = this.state.addressArray.filter(
        (el) => el.address_id == e.target.value
      )[0];
      if (index) {
        this.setState({
          billing_first_name: index.firstname ? index.firstname : index.name,
          address_type: index.address_type,
          billing_name: index.name || "",
          billing_company_name: index.company,
          billing_mobile: index.mobile || "",
          billing_address_1: index.address_1 || "",
          billing_address_2: index.address_2 || "",
          billing_city: index.city || "",
          billing_zone_id: index.zone_id || "",
          billing_postcode: index.postcode || "",
          billing_country: 99,
          billing_country_name: "India",
          billing_gst: index.gstin ? index.gstin : "",
          gstDefault: index.gstin ? index.gstin : "",
          address_type: index.address_type ? index.address_type : "",
          gstDisable: true,
        });
      }
    }
  };

  getSelectShippingAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedShippingAddress: e.target.value,
      });
      let index = this.state.addressArray.filter(
        (el) => el.address_id == e.target.value
      )[0];
      this.setState({
        shipping_first_name: index.firstname ? index.firstname : index.name,
        shipping_name: index.name,
        shipping_address_type: index.address_type,
        shipping_company_name: index.company || "",
        shipping_mobile: index.mobile,
        shipping_address_1: index.address_1,
        shipping_address_2: index.address_2,
        shipping_city: index.city,
        shipping_zone_id: index.zone_id,
        shipping_postcode: index.postcode,
        shipping_country: 99,
        shipping_country_name: "India",
        shipping_gst: index.gstin,
      });
    }
  };

  updateQuantityWithPrices = (data) => {
    let products = this.state.getAllProductsWithDetailForList;
    products[data.index].quantity = data.quantity;
    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedQuoteGross = 0;
    let updatedMinQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let totalDiscountOnMrpAmountInterest = 0;
    let netTotalAmount = 0;
    let netTotalAmountInterest = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let totalTaxAmt = 0;
    let totalTaxAmtInterest = 0;
    let totalDeliveryRate = 0;
    let subTotalAmountInterest = 0;
    let creditChargesInterests = 0;

    for (let i = 0; i < products.length; i++) {
      let vendor = data.vendordata;
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);
      let listPriceFinal = Number(listPrice);
      let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(this.state.currentInterest)/100);      
      let listPriceFinalInterest = Number(listPriceInterest);
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg =
        Number(products[i].list_price_org) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
      let netAmountOrgIntrest = Number(listPriceFinalInterest) * Number(products[i].quantity);
      let netAmountFinalInterest = Number(netAmountIntrest);
      let netAmountOrgFinalInterest = Number(netAmountOrgIntrest);
      let tax_in_amount = (netAmountFinal * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
      let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);
      let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);

      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;

      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross += Number((Number(products[i].minimum_gross_margin) / 100) * Number(netAmountFinal));
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinalInterest)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =
        Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
        subTotalAmount = -netSubTotal;
      let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest;
      let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest; 
      totalDiscountOnMrpAmountInterest += totalDiscountInterest;
      let netSubTotalInterest = Number(totalDiscountOnMrpAmountInterest) - Number(totalMrpAmount);
      subTotalAmountInterest =- netSubTotalInterest;
      let totalTax = Number(tax_in_amount);
      totalTaxAmt += totalTax;
      let totalTaxInterest = Number(tax_in_amountInterest);
      totalTaxAmtInterest += totalTaxInterest
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
      creditChargesInterests += creditChargesInterest; 
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: products[i].mrp,
        model: products[i].model,
        transfer_price: Number(products[i].transfer_price),
        available_stock: products[i].available_stock,
        special_tp: products[i].special_tp,
        ticket_id: products[i].ticket_id,
        vendor_id: products[i].vendor_id,
        // vendor: data.selectVendor,
        markup: products[i].markup,
        markupInterest: Number(products[i].markup) + Number(this.state.currentInterest),
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_org: Number(products[i].list_price_org),
        list_price_interest: Number(listPriceFinalInterest),
        list_price_org_interest: Number(listPriceFinalInterest),
        net_amount: netAmountFinal,
        net_amount_org: netAmountOrgFinal,
        net_amount_interest: Number(netAmountIntrest),
        net_amount_org_interest: netAmountOrgIntrest,
        tax_in_percentage: Number(products[i].tax_in_percentage),
        tax_in_amount: tax_in_amount_final,
        tax_in_amount_interest: tax_in_amount_finalInterest,
        total: Number(total),
        totalInterest: Number(totalInterest),
        gross_margin: grossMargin,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      creditCharges: creditChargesInterests,
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmount: subTotalAmount,
      totalNetAmountInterest: subTotalAmountInterest,
      totalTaxAmountInterest: Number(totalTaxAmtInterest),
      totalSystemDeliverRate: totalDeliveryRate,  
    });
    let grandTotalPriceInterest = Number(this.state.storeInputValue) === 0 ?
    Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmountInterest) +
    Number(totalTaxAmtInterest) +
    Number(totalDeliveryRate) 
    :
     Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmountInterest) +
    Number(totalTaxAmtInterest) +
    Number(this.state.storeInputValue);
    let grandTotalPriceInterest1 = Number(subTotalAmountInterest) + Number(totalTaxAmtInterest) + Number(this.state.shippingCharges);
    if(this.state.storeInputValue === 0){
      this.setState({shippingCharges:  totalDeliveryRate})
    }  
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotalInterest: Number(grandTotalPriceInterest)
    });
  }else {
    this.setState({
      grandTotalInterest: Number(grandTotalPriceInterest1)
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  });
  };

  updateMarkupWithPrices = (data) => {
    let products = this.state.getAllProductsWithDetailForList;
    products[data.index].markup = data.markup

    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let totalDiscountOnMrpAmountInterest = 0;
    let discountOnMrpAmountInterest = 0;
    let netTotalAmount = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let subTotalAmountInterest = 0;
    let totalTaxAmt = 0;
    let totalTaxAmtInterest = 0;
    let totalDeliveryRate = 0;
    let netTotalAmountInterest = 0;
    let creditChargesInterests = 0;

    for (let i = 0; i < products.length; i++) {
      let vendor = data.vendordata;
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      let listPrice = (Number(products[i].markup) * Number(transfer_price)) / 100 +Number(transfer_price);
      let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(this.state.currentInterest)/100);   

      let listPriceFinal = Number(listPrice);
      let listPriceFinalInterest = Number(listPriceInterest);
     
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      
      let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
      let netAmountFinalInterest = Number(netAmountIntrest);
      
      
      let netAmountOrg = Number(products[i].list_price_org) * Number(products[i].quantity);
      let netAmountOrgIntrest = Number(listPriceFinalInterest) * Number(products[i].quantity);

      let netAmountOrgFinal = Number(netAmountOrg);
      let netAmountOrgFinalInterest = Number(netAmountOrgIntrest);

      let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
      let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);
      let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);

      let tpInToQuantity = Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;
      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross +=(Number(products[i].minimum_gross_margin) / 100) * Number(netAmountFinal);
      discountOnMrpAmount =((Number(products[i].mrp) - Number(listPriceFinalInterest)) / Number(products[i].mrp)) * 100;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      
      totalMrpAmount += totalMrp;
      
      let netTotalSum = netTotalAmount + netAmountFinal;
      let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest;
      
      let totalDiscount = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest; 
      
      totalDiscountOnMrpAmount += totalDiscount;
      totalDiscountOnMrpAmountInterest += totalDiscountInterest;
      
     
      let netSubTotal = Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      let netSubTotalInterest = Number(totalDiscountOnMrpAmountInterest) - Number(totalMrpAmount);
      
      
      subTotalAmount = -netSubTotal;
      subTotalAmountInterest = -netSubTotalInterest;

      let totalTax = Number(tax_in_amount);
      let totalTaxInterest = Number(tax_in_amountInterest);
      
      totalTaxAmt += totalTax;
      totalTaxAmtInterest += totalTaxInterest
      
      let systemDeliveryRate = (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
      creditChargesInterests += creditChargesInterest; 
      
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        available_stock: products[i].available_stock,
        markup: products[i].markup,
        markupInterest: Number(products[i].markup) + Number(this.state.currentInterest),
        special_tp: products[i].special_tp,
        ticket_id: products[i].ticket_id,
        vendor_id: products[i].vendor_id,
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_org: Number(products[i].list_price_org),
        list_price_interest: Number(listPriceFinalInterest),
        list_price_org_interest: Number(listPriceFinalInterest),
        net_amount: netAmountFinal,
        net_amount_org: netAmountOrgFinal,
        net_amount_interest: Number(netAmountIntrest),
        net_amount_org_interest: netAmountOrgIntrest,
        tax_in_percentage: products[i].tax_in_percentage,
        tax_in_amount: tax_in_amount_final,
        tax_in_amount_interest: tax_in_amount_finalInterest,
        total: Number(total),
        totalInterest: Number(totalInterest),
        gross_margin: grossMargin,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      creditCharges: creditChargesInterests,
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmountInterest: Number(subTotalAmountInterest),
      totalTaxAmountInterest: Number(totalTaxAmtInterest),
      totalSystemDeliverRate: totalDeliveryRate,  
    });
    let grandTotalPriceInterest = Number(this.state.storeInputValue) === 0 ?
    Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmountInterest) +
    Number(totalTaxAmtInterest) +
    Number(totalDeliveryRate) 
    :
     Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmountInterest) +
    Number(totalTaxAmtInterest) +
    Number(this.state.storeInputValue);
    let grandTotalPriceInterest1 = Number(subTotalAmountInterest) + Number(totalTaxAmtInterest) + Number(this.state.shippingCharges);
    if(this.state.storeInputValue === 0){
      this.setState({shippingCharges:  totalDeliveryRate})
    }    
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1,
      grandTotalInterest: Number(grandTotalPriceInterest1),
    });
  }else {
    this.setState({
      grandTotalInterest: Number(grandTotalPriceInterest),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  })
  };

  specialTP = (data, type) => {
    let products = this.state.getAllProductsWithDetailForList;
    if (type === "sourcing") {
      for (let product of products) {
        product.vendor_id = data.get(product.product_id).vendor_id;
        product.ticket_id = this.state.rfq_id.toString();
        product.special_tp = data.get(product.product_id).sales_transfer_price;
      }
    } else {
      products[data.index].special_tp = data.TP;
      products[data.index].ticket_id = data.TicketID;
      products[data.index].vendor_id = data.selectVendor;
    }
    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let netTotalAmount = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let subTotalAmountInterest = 0;
    let totalTaxAmt = 0;
    let totalTaxAmtInterest = 0;
    let totalDeliveryRate = 0;
    let creditChargesInterests = 0;
    let totalDiscountOnMrpAmountInterest = 0;
    let netTotalAmountInterest = 0;

    for (let i = 0; i < products.length; i++) {
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      let ticket_id =
        products[i].ticket_id.length == 0
          ? this.state.ticket_id
          : products[i].ticket_id;
      let vendor_id =
        products[i].vendor_id.length == 0
          ? this.state.vendor_id
          : products[i].vendor_id;
      let vendor = data.vendordata;

      // CALCULATIONS FOR DIFFERENT SECTIONS OF PRODUCT DETAIL MODAL

      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);
      let listPriceFinal = Number(listPrice);
      let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(this.state.currentInterest)/100);      
      let listPriceFinalInterest = Number(listPriceInterest); 
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg = Number(listPriceFinal) * Number(products[i].quantity);
      let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
      let netAmountOrgIntrest = Number(listPriceFinalInterest) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let netAmountFinalInterest = Number(netAmountIntrest);
      let netAmountOrgFinalInterest = Number(netAmountOrgIntrest);
      let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
      let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);
      let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);
      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR;
      if (netAmountFinal == 0) {
        tpInToQuantityR = 0;
      } else {
        tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;
      }

      let grossMargin = Number(tpInToQuantityR * 100); //CHECK FOR GROSS MARGIN
      subTotal = subTotal + total;
      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross += (Number(products[i].minimum_gross_margin) / 100) * netAmountFinal;
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinalInterest)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =
        Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      let netSubTotalInterest =
        Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount = -netSubTotal;
      subTotalAmountInterest = -netSubTotalInterest;
      // let totalTax = Number(products[i].tax_in_amount);
      let totalTax = Number(tax_in_amount);
      totalTaxAmt += totalTax;
      let totalTaxInterest = Number(tax_in_amountInterest);
      totalTaxAmtInterest += totalTaxInterest;
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
      creditChargesInterests += creditChargesInterest; 
      let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest;
      let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest; 
      totalDiscountOnMrpAmountInterest += totalDiscountInterest;
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        special_tp: products[i].special_tp,
        ticket_id: ticket_id,
        vendor_id: vendor_id,
        // ticket_id: products[i].ticket_id,
        // vendor_id: products[i].vendor_id,
        available_stock: products[i].available_stock,
        markup: products[i].markup,
        markupInterest: Number(products[i].markup)+Number(this.state.currentInterest),
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_org: listPriceFinalInterest,
        list_price_interest: listPriceFinalInterest,
        list_price_org_interest: listPriceFinal,
        net_amount: Number(netAmountFinal),
        net_amount_org: Number(netAmountOrgFinal),
        net_amount_interest: Number(netAmountIntrest),
        net_amount_org_interest: netAmountOrgIntrest,
        tax_in_percentage: products[i].tax_in_percentage,
        tax_in_amount: tax_in_amount_final,
        tax_in_amount_interest: tax_in_amount_finalInterest,
        total: Number(total),
        totalInterest: Number(totalInterest),
        gross_margin: grossMargin ? grossMargin : 0,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges)
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      creditCharges: creditChargesInterests,
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmountInterest : subTotalAmountInterest,
      totalTaxAmount: totalTaxAmt,
      totalTaxAmountInterest : totalTaxAmtInterest,
      totalSystemDeliverRate: totalDeliveryRate,  
    });
      let grandTotalPriceInterest = Number(this.state.storeInputValue) === 0 ?
      Number(totalMrpAmount) -
      Number(totalDiscountOnMrpAmountInterest) +
      Number(totalTaxAmtInterest) +
      Number(totalDeliveryRate) 
      :
       Number(totalMrpAmount) -
      Number(totalDiscountOnMrpAmountInterest) +
      Number(totalTaxAmtInterest) +
      Number(this.state.storeInputValue);
    let grandTotalPriceInterest1 =
      Number(subTotalAmountInterest) +
      Number(totalTaxAmtInterest) +
      Number(this.state.shippingCharges);
      if(this.state.storeInputValue === 0){
        this.setState({shippingCharges:  totalDeliveryRate})
      }    
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotalInterest: grandTotalPriceInterest
    });
    }else {
    this.setState({
      grandTotalInterest: grandTotalPriceInterest1
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  })
  };
  updateTotal = (data) => {
    let products = this.state.getAllProductsWithDetailForList;
    products[data.index].markup = data.markup;
    products[data.index].list_price = data.listPrice;
    // products[data.index].list_price = data.netAmount;

    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let discountOnMrpAmountInterest = 0;
    let totalDiscountOnMrpAmount = 0;
    let totalDiscountOnMrpAmountInterest = 0;
    let netTotalAmount = 0;
    let netTotalAmountInterest = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let subTotalAmountInterest = 0;
    let totalTaxAmt = 0;
    let totalDeliveryRate = 0;
    let totalTaxAmtInterest = 0;
    let creditChargesInterests = 0;

    for (let i = 0; i < products.length; i++) {
      let vendor = data.vendordata;
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);
      if (products[i].special_tp !== 0) {
        listPrice = products[i].list_price;
      }
      if (data.index === i) {
        listPrice = data.listPrice;
      }
      let listPriceFinal = Number(listPrice);
      let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(this.state.currentInterest)/100);
      let listPriceFinalInterest = Number(listPriceInterest); 
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg =
        Number(products[i].list_price_org) * Number(products[i].quantity);
        let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
        let netAmountOrgIntrest = Number(listPriceFinalInterest) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let netAmountFinalInterest = Number(netAmountIntrest);
      let netAmountOrgFinalInterest = Number(netAmountOrgIntrest);
      let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
      let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);
      let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);

      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;
      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross += Number((Number(products[i].minimum_gross_margin) / 100) * Number(netAmountFinal));
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinalInterest)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =
        Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount = -netSubTotal;
      let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest;
      let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest; 
      totalDiscountOnMrpAmountInterest += totalDiscountInterest;
      let netSubTotalInterest = Number(totalDiscountOnMrpAmountInterest) - Number(totalMrpAmount);
      subTotalAmountInterest =- netSubTotalInterest;
      let totalTax = Number(tax_in_amount);
      totalTaxAmt += totalTax;
      let totalTaxInterest = Number(tax_in_amountInterest);
      totalTaxAmtInterest += totalTaxInterest
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
        totalDeliveryRate += systemDeliveryRate * (118 / 100);
      let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
      creditChargesInterests += creditChargesInterest; 
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        available_stock: products[i].available_stock,
        markup: products[i].markup,
        markupInterest: Number(products[i].markup)+Number(this.state.currentInterest),
        special_tp: products[i].special_tp,
        ticket_id: products[i].ticket_id,
        vendor_id: products[i].vendor_id,
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_interest: listPriceFinalInterest,
        list_price_org: Number(products[i].list_price_org),
        list_price_org_interest: listPriceFinal,
        net_amount: netAmountFinal,
        net_amount_org: netAmountOrgFinal,
        net_amount_interest: Number(netAmountIntrest),
        net_amount_org_interest: netAmountOrgIntrest,
        tax_in_percentage: products[i].tax_in_percentage,
        tax_in_amount: tax_in_amount_final,
        tax_in_amount_interest: tax_in_amount_finalInterest,
        total: Number(total),
        totalInterest: Number(totalInterest),
        gross_margin: grossMargin,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      creditCharges: creditChargesInterests,
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmountInterest: subTotalAmountInterest,
      totalTaxAmountInterest: Number(totalTaxAmtInterest),
      totalSystemDeliverRate: totalDeliveryRate,  
    });
      let grandTotalPriceInterest = Number(this.state.storeInputValue) === 0 ?
      Number(totalMrpAmount) -
      Number(totalDiscountOnMrpAmountInterest) +
      Number(totalTaxAmtInterest) +
      Number(totalDeliveryRate) 
      :
       Number(totalMrpAmount) -
      Number(totalDiscountOnMrpAmountInterest) +
      Number(totalTaxAmtInterest) +
      Number(this.state.storeInputValue);
      let grandTotalPriceInterest1 =
      Number(subTotalAmountInterest) + Number(totalTaxAmtInterest) + Number(this.state.shippingCharges);  
      if(this.state.storeInputValue === 0){
        this.setState({shippingCharges:  totalDeliveryRate})
      }    
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotalInterest: Number(grandTotalPriceInterest),
    });
  }else {
    this.setState({
      grandTotalInterest: Number(grandTotalPriceInterest1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  })
  };

  // add shipping charges function start here
  addshippingCharges = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue * 1.18;
    let grandTotalPrice = Math.abs(Number(this.state.totalNetAmount)) + Number(this.state.totalTaxAmount) + Number(inputValue);
    let grandTotalPriceInterest = Math.abs(Number(this.state.totalNetAmountInterest)) + Number(this.state.totalTaxAmountInterest) + Number(inputValue);
    this.setState({
      touched: this.state.totalSystemDeliverRate > this.state.shippingCharges,
      storeInputValue: inputValue,
      shippingCharge: inputValue,
      grandTotal: Number(grandTotalPrice),
      grandTotalInterest: Number(grandTotalPriceInterest)
    });
    if (inputValue !== this.state.shippingCharges) {
      this.setState({ shippingCharges: inputValue });
    }
  };
  // add shipping charges function ends here

  removeItemFromMainList = (e) => {
    let id = e;
    let products = this.state.getAllProductsWithDetailForList;
    products.splice(id, 1);
    if(products.length === 0){
      this.setState({
        getAllProductsWithDetailForList: [],
        displayAllProducts:[]
      })
    }
    else{
    let productsArray = [];
    let subTotal = 0;
    let taxTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let totalDiscountOnMrpAmountInterest = 0;
    let netTotalAmount = 0;
    let subTotalAmount = 0;
    let subTotalAmountInterest = 0;
    let totalMrpAmount = 0;
    let totalTaxAmt = 0;
    let totalTaxAmtInterest = 0;
    let totalDeliveryRate = 0;
    let creditChargesInterests = 0;
    let netAmountTotal = 0;
    let netTotalAmountInterest = 0;

    for (let i = 0; i < products.length; i++) {
      let price = products[i].special_tp == 0  ? products[i].transfer_price : products[i].special_tp;
      let subTotal1 = parseFloat(products[i].total);
      subTotal = subTotal + subTotal1;
      let taxTotal1 = parseFloat(products[i].tax_in_amount);
      taxTotal = taxTotal + taxTotal1;
      updatedQuoteGross += (products[i].gross_margin / 100) * products[i].net_amount;
      updatedMinQuoteGross += (Number(products[i].minimum_gross_margin) / 100) * products[i].net_amount;
     
      
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      
      let netSubTotal = Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount = -netSubTotal;
      
      let totalTax = Number(products[i].tax_in_amount);
      totalTaxAmt += totalTax;
      let totalTaxInterest = Number(products[i].tax_in_amount_interest);
      totalTaxAmtInterest += totalTaxInterest;
      
      let listPrice = (Number(products[i].markup) * Number(price)) / 100 +Number(price);
      let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(this.state.currentInterest)/100);      
      
      discountOnMrpAmount =
      ((Number(products[i].mrp) - Number(listPriceInterest)) /
        Number(products[i].mrp)) *
      100;
    let netTotalSum = netTotalAmount + products[i].net_amount;
    let totalDiscount =
      Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
    totalDiscountOnMrpAmount += totalDiscount;
      
      let listPriceFinalInterest =  Number(listPriceInterest)
      let netAmountIntrest = listPriceFinalInterest * Number(products[i].quantity);
      let netAmount = Number(listPrice) * Number(products[i].quantity);
      netAmountTotal += netAmount;
      
      let creditChargesInterest = Number(netAmountIntrest) - Number(netAmount);
      creditChargesInterests += creditChargesInterest;

      let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
      let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
	    let netAmountFinalInterest = Number(netAmountIntrest);
	   
     let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);
     let netTotalSumInterest = netTotalAmountInterest + netAmountFinalInterest;
     
     let totalDiscountInterest = Number(products[i].mrp) * Number(products[i].quantity) - netTotalSumInterest; 
     totalDiscountOnMrpAmountInterest += totalDiscountInterest;
     
     let netSubTotalInterest = Number(totalDiscountOnMrpAmountInterest) - Number(totalMrpAmount);
     subTotalAmountInterest = -netSubTotalInterest;
     let systemDeliveryRate =
      (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: products[i].mrp,
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        markup: products[i].markup,
        markupInterest: Number(Number(products[i].markup)+ Number(this.state.currentInterest)),
        quantity: products[i].quantity,
        special_tp: products[i].special_tp
          ? products[i].special_tp
          : this.state.special_tp,
        ticket_id: products[i].ticket_id
          ? products[i].ticket_id
          : this.state.ticket_id,
        vendor_id: products[i].vendor_id
          ? products[i].vendor_id
          : this.state.vendor_id,
        list_price: products[i].list_price,
        list_price_interest: Number(listPriceInterest),
        available_stock: products[i].available_stock,
        list_price_org: products[i].list_price_org,
        list_price_org_interest: products[i].list_price_org,
        net_amount: products[i].net_amount,
        net_amount_interest: Number(netAmountIntrest),
        net_amount_org: products[i].net_amount_org,
        net_amount_org_interest: products[i].net_amount_org,
        tax_in_percentage: products[i].tax,
        tax_in_amount: tax_in_amountInterest,
        tax_in_amount_interest: tax_in_amount_finalInterest,
        total: products[i].total,
        totalInterest: totalInterest,
        gross_margin: products[i].gross_margin,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmountInterest: subTotalAmountInterest,
      totalTaxAmountInterest: Number(totalTaxAmtInterest),
      creditCharges: creditChargesInterests,
      totalSystemDeliverRate: totalDeliveryRate,
    });
    let grandTotalPriceInterest = Number(this.state.storeInputValue) === 0 ?
    Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmountInterest) +
    Number(totalTaxAmtInterest) +
    Number(totalDeliveryRate) 
    :
     Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmountInterest) +
    Number(totalTaxAmtInterest) +
    Number(this.state.storeInputValue);
    let grandTotalPriceInterest1 = Number(subTotalAmountInterest) + Number(totalTaxAmtInterest) + Number(this.state.shippingCharges);  
    if(this.state.storeInputValue === 0){
      this.setState({shippingCharges:  totalDeliveryRate})
    }    
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotalInterest: Number(grandTotalPriceInterest),
    });
  }else {
    this.setState({
      grandTotalInterest: Number(grandTotalPriceInterest1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    displayProductArray: productsArray,
    subTotal: Number(subTotal),
  })
}
  };

  displayAllProducts = async (e, productMap) => {
    let productsMap = new Map();
    if (productMap) {
      productsMap = productMap;
    }
    let listedProductsArray = e == undefined ? this.state.rfq_products : e;
    let productID = [];
    listedProductsArray.map((item) => {
      return productID.push(item.product_id);
    });
    let productID2 = [];
    this.state.getAllProductsWithDetailForList.map((item) => {
      return productID2.push(item.product_id);
    });
    let onlyNewId = [];
    if (productID2.length == 0) {
      onlyNewId = productID;
    } else {
      onlyNewId = productID.filter((el) => !productID2.includes(el));
    }
    let uniqueChars = [...new Set(onlyNewId)];
    let data = localStorage.getItem("QuoteFromSourcing");
    let reqData = {
      product_id: uniqueChars,
      customer_id: this.state.customer_id,
    };
    if (data) {
      reqData = { ...reqData, status: "2,0" };
    }
    let requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: reqData,
    };
    return await axios(apiUrl + "/get-product-detail-by-id", requestoption)
      .then((data) => {
        let products = data.data.data;
        if(products && products.length){
        let productsArray = [];
        let subTotal = 0;
        let shippingRate = 0;
        let updatedMinQuoteGross = 0;
        let updatedQuoteGross = 0;
        let updatedDiscountOnMrp = 0;
        let totalDiscountOnMrpAmount = 0;
        let netTotalAmount = 0;
        let totalMrpAmount = 0;
        let totalTaxAmt = 0;
        let totalTaxAmtInterest = 0;
        let subTotalAmount = 0;
        let totalDeliveryRate = 0;
        let totalNetAmountFinal = 0;
        let totalNetAmountFinalInterest = 0;
        let creditChargesInterests =Number(this.state.creditCharges);

        for (let i = 0; i < products.length; i++) {
          let index = {};

          index = listedProductsArray.filter(
            (el) => el.product_id === products[i].product_id
          )[0];
          let price = products[i].sales_transfer_price ? products[i].sales_transfer_price : products[i].transfer_price ? products[i].transfer_price:0;          
          if(price){
            price = price + Number(price * Number(products[i].markup/100));
          }else{
            price = products[i].list_price;
          }
          let quantity = Number(products[i].quantity) ? Number(products[i].quantity) : index.quantity
          let transfer_price = Number(price)
          let listPrice = Number(price);
          let listPriceFinal = Number(listPrice);
          let listPriceInterest =  Number(listPrice) + Number(listPrice) * (Number(this.state.currentInterest)/100);
          let listPriceFinalInterest = Number(listPriceInterest);
          let netAmount = listPriceFinal * index.quantity;
          let netAmountOrg = listPriceFinal * index.quantity;
          let netAmountIntrest = listPriceFinalInterest * Number(quantity);
          let netAmountOrgIntrest = Number(listPriceFinalInterest) * Number(quantity);
          let netAmountFinal = Number(netAmount);
          let netAmountOrgFinal = Number(netAmountOrg);
          let netAmountFinalInterest = Number(netAmountIntrest);
          let netAmountOrgFinalInterest = Number(netAmountOrgIntrest);
          let tax_in_amount = (netAmount * products[i].tax) / 100;
          let tax_in_amount_final = Number(tax_in_amount);
          let tax_in_amountInterest = (netAmountIntrest * Number(products[i].tax)) / 100;
          let tax_in_amount_finalInterest = Number(tax_in_amountInterest);
          var total =
            parseFloat(tax_in_amount_final) + parseFloat(netAmountFinal);
          let totalInterest = Number(tax_in_amount_finalInterest) + Number(netAmountFinalInterest);
          let creditChargesInterest = Number(Number(netAmountIntrest).toFixed(2)) - Number(Number(netAmount).toFixed(2));
          creditChargesInterests += Number(creditChargesInterest); 
          let tpInToQuantity = products[i].transfer_price * index.quantity;
          let tpInToQuantityR =
            (netAmountFinal - tpInToQuantity) / netAmountFinal;
          let grossMargin =
            netAmountFinal == 0
              ? Number(tpInToQuantity * 100)
              : Number(tpInToQuantityR * 100);
          subTotal = subTotal + Number(total);

          updatedQuoteGross += (grossMargin / 100) * Number(netAmount);
          updatedMinQuoteGross += (Number(products[i].minGrossMargin) / 100) * Number(netAmount);
          updatedDiscountOnMrp = ((Number(products[i].price) - Number(products[i].list_price)) / Number(products[i].price)) * 100;
          let netTotalSum = netTotalAmount + netAmountFinal;
          const totalDiscount =
            Number(products[i].mrp ? products[i].mrp : products[i].price) *
              Number(
                products[i].quantity ? products[i].quantity : index.quantity
              ) -
            netTotalSum;
          totalDiscountOnMrpAmount += totalDiscount;
          let totalMrp = Number(products[i].price)
            ? Number(products[i].price)
            : Number(products[i].mrp) * Number(products[i].quantity);
          totalMrpAmount += totalMrp;
          let totalTax = tax_in_amount
            ? tax_in_amount
            : Number(products[i].tax_in_amount);
          totalTaxAmt += totalTax;
          let totalTaxInterest = tax_in_amountInterest
          ? tax_in_amountInterest
          : Number(products[i].tax_in_amount);
          totalTaxAmtInterest += totalTaxInterest
          subTotalAmount = total;
          let systemDeliveryRate = (Number(products[i].deliveryCharges) * netAmountIntrest) / 100;
          totalDeliveryRate += systemDeliveryRate * (118 / 100);
          totalNetAmountFinal += netAmountFinal;
          totalNetAmountFinalInterest += netAmountFinalInterest
          productsArray.push({
            product_id: products[i].product_id,
            name: products[i].name,
            brand: products[i].brand,
            quantity_type: products[i].quantity_type,
            tax: products[i].tax,
            mrp: products[i].mrp ? products[i].mrp : products[i].price,
            model: products[i].model,
            transfer_price: products[i].transfer_price,
            available_stock: products[i].available_stock,
            markup: products[i].markup,
            markupInterest: Number(products[i].markup) + Number(this.state.currentInterest),
            quantity: products[i].quantity
              ? products[i].quantity
              : index.quantity,
            special_tp: products[i].special_tp
              ? products[i].special_tp
              : this.state.special_tp,
            ticket_id: products[i].ticket_id
              ? products[i].ticket_id
              : this.state.ticket_id,
            vendor_id: products[i].vendor_id
              ? products[i].vendor_id
              : this.state.vendor_id,
            list_price: listPriceFinal,
            list_price_org:  listPriceFinalInterest,
            list_price_interest: listPriceFinalInterest,
            list_price_org_interest: listPriceFinalInterest,
            net_amount: netAmountFinal,
            net_amount_org: netAmountOrgFinal,
            net_amount_interest: Number(netAmountFinalInterest),
            net_amount_org_interest: netAmountOrgFinal,
            tax_in_percentage: products[i].tax,
            tax_in_amount: tax_in_amount_final,
            tax_in_amount_interest: tax_in_amount_finalInterest,
            total: Number(total),
            totalInterest: Number(totalInterest),
            gross_margin: Number(products[i].margin),
            vendor: products[i].vendor,
            minimum_gross_margin: Number(products[i].minGrossMargin),
            discount_on_mrp: updatedDiscountOnMrp,
            deliveryCharges: Number(products[i].deliveryCharges),
          });
        }
        productsArray = productsArray.filter((product) => {
          if (
            this.state.existingProducts.has(product.product_id) &&
            this.state.existingProducts.get(product.product_id)
              .availabilityType === "Not Avl"
          ) {
            return false;
          }
          return true;
        });
        // For Loop Ends
        let prevDeliveryRate = this.state.totalSystemDeliverRate
        if(this.state.storeInputValue === 0){
          this.setState({ shippingCharges : this.state.totalSystemDeliverRate + totalDeliveryRate,})
        }
        this.setState({
          quoteGrossMargin:
            parseFloat(this.state.quoteGrossMargin) +
            parseFloat(updatedQuoteGross),
          quoteMinimumGrossMargin:
            parseFloat(this.state.quoteMinimumGrossMargin) +
            parseFloat(updatedMinQuoteGross),
          totalDiscountOnMrp:
            this.state.totalDiscountOnMrp + totalDiscountOnMrpAmount,
          totalMrp: this.state.totalMrp + totalMrpAmount,
          creditCharges: creditChargesInterests,				 
          totalTaxAmountInterest: this.state.totalTaxAmountInterest + totalTaxAmtInterest,
          totalNetAmountInterest: this.state.totalNetAmountInterest + totalNetAmountFinalInterest,
          grandTotalInterest: Number(this.state.storeInputValue) === 0 ?
          Number(this.state.totalNetAmountInterest) + 
          Number(totalNetAmountFinalInterest) + 
          Number(this.state.totalSystemDeliverRate) + 
          Number(totalDeliveryRate) + 
          Number(this.state.totalTaxAmountInterest) + 
          Number(totalTaxAmtInterest) 
          : 
          Number(this.state.totalNetAmountInterest) + 
          Number(totalNetAmountFinalInterest) + 
          Number(this.state.storeInputValue) +
          Number(this.state.totalTaxAmountInterest) + 
          Number(totalTaxAmtInterest),
          totalSystemDeliverRate: this.state.totalSystemDeliverRate + totalDeliveryRate,
          touched:true,
          triggerRender: this.state.triggerRender>10 ? 0 :this.state.triggerRender + 1 ,
        });
        this.setState((prevState) => ({
          getAllProductsWithDetailForList:
            prevState.getAllProductsWithDetailForList.concat(productsArray),
          displayProductArray:
            prevState.getAllProductsWithDetailForList.concat(productsArray),
        }));
        this.setState(
          {
            subTotal: Number(this.state.subTotal) + subTotal,
          }
        );
        if (!productsMap.size) {
          for (let value of e) {
            if (this.state.existingProducts.has(value.product_id)) {
              if (
                this.state.existingProducts.get(value.product_id)
                  .availabilityType === "Not Avl"
              ) {
                this.setState(
                  {
                    message: "This product is not available",
                    is_error: true,
                    is_loading: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        message: "",
                        is_error: false,
                      });
                    }, 3000);
                  }
                );
              } else {
                productsMap.set(
                  value.product_id,
                  this.state.existingProducts.get(value.product_id)
                );
              }
            }
          }
        }
        if (productsMap && productsMap.size) {
          this.specialTP(productsMap, "sourcing");
        }
        }
      })
      .catch((err) => console.log(err));
  };

  handleFormSubmit = (e) => {
    this.validateGSTNumber(this.state.new_Gstno, this.state.new_zone_id);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ newValidated: true });
    }
  };

  handleSubmit = (e) => {
    let submitType = e.target.id;
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const result = this.state.getAllProductsWithDetailForList.map(
      ({ type, vendor, ...rest }) => ({ ...rest })
    );
    const token = loginedUserDetails.token;
    this.setState(
      {
        is_loading: true,
        validated: true,
      },
      () => {
        var d = new Date(this.state.startDate);
        var date = d.getDate();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        var dateValidUpto = year + "-" + month + "-" + date;
        let newGst = 0;
        if (
          this.state.selectedGstAddress.split(" ").length === 1 &&
          !isNaN(parseInt(this.state.selectedGstAddress))
        ) {
          newGst = parseInt(this.state.selectedGstAddress);
        }
        let productsData = result
          ? result
          : this.state.getAllProductsWithDetailForList;
        productsData = productsData.map((product) => {
          const {
            brand, // destructuring to exclude brand
            quantity_type, // destructuring to exclude quantity_type
            deliveryCharges,
            markupInterest,
            list_price_interest,
            list_price_org_interest,
            net_amount_interest,
            net_amount_org_interest,
            tax_in_amount_interest,
            totalInterest,
            ...rest
          } = product;
          return {
            ...rest,
            list_price: Number(product.list_price_interest).toFixed(4),
            net_amount: Number(product.net_amount_interest).toFixed(4),
            net_amount_org: Number(product.net_amount_interest).toFixed(4),
            discount_on_mrp: Number(product.discount_on_mrp).toFixed(4),
            tax_in_amount: Number(product.tax_in_amount_interest).toFixed(4),
            transfer_price: Number(product.transfer_price).toFixed(4),
            list_price_org: Number(product.list_price_interest).toFixed(4),
            gross_margin: Number(product.gross_margin).toFixed(4),
            total: Number(product.totalInterest).toFixed(4),
          };
        });
        const requestoption = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            gstBilling: this.state.showGstBox, // add new key name
            rfq_id: this.state.rfqID
              ? this.state.rfqID
              : this.props.quoteState.rfqID,
            customer_id: this.state.customer_id,
            customer_email: this.state.customer_email2,
            customer_name: this.state.customer_name2,
            company_id: this.state.company_id,
            agent_id: this.state.rfq_agent_id
              ? this.state.rfq_agent_id
              : this.state.agent_id,
            quote_subject: this.state.quote_subject,
            quote_type: submitType,
            rfq_quote_type: submitType,
            valid_upto: dateValidUpto,
            firstname: this.state.billing_first_name
              ? this.state.billing_first_name
              : this.state.customer_name2,
            company_name: this.state.billing_company_name
              ? this.state.billing_company_name
              : "",
            mobile:
              this.state.billing_mobile == "" ||
              this.state.billing_mobile == null
                ? this.state.customer_phone2
                : this.state.billing_mobile,
            email: this.state.customer_email2,
            address_1: this.state.billing_address_1,
            address_2: this.state.billing_address_2,
            city: this.state.billing_city,
            zone_id: this.state.billing_zone_id,
            postcode: this.state.billing_postcode,
            country_id: this.state.billing_country
              ? this.state.billing_country
              : 99,
            gst: this.state.billing_gst,
            shipping_firstname: this.state.shipping_first_name
              ? this.state.shipping_first_name
              : this.state.customer_name2,
            shipping_company_name: "",
            shipping_mobile:
              this.state.shipping_mobile == "" ||
              this.state.shipping_mobile == null
                ? this.state.customer_phone
                : this.state.shipping_mobile,
            shipping_email: this.state.customer_email2,
            shipping_address_1: this.state.shipping_address_1,
            shipping_address_2: this.state.shipping_address_2,
            shipping_city: this.state.shipping_city,
            shipping_zone_id: this.state.shipping_zone_id,
            shipping_postcode: this.state.shipping_postcode,
            shipping_country_id: this.state.shipping_country,
            shipping_gst: this.state.billing_gst,
            login_id: loginedUserDetails.user_id,
            sub_total: Number(this.state.subTotal).toFixed(4),
            shippingCharge: Number(this.state.storeInputValue) > 0 ? Number(this.state.storeInputValue).toFixed(4) : Number(this.state.shippingCharges).toFixed(4),  
            grand_total: Number(this.state.grandTotalInterest).toFixed(4),
            products: productsData,
            label_name: this.state.selectedOption, //Hot, Warm, Cold
            address_type: this.state.address_type, // delivery address radio button for work,home
            shipping_address_type: this.state.shipping_address_type, // billing address radio button for work,home
            agent_remarks: this.state.agent_remarks, // agent_remarks
            address_id: this.state.showGstBox ? newGst : 0,
            plan_id: this.state.selectedPlanId.toString(),
            partner_id: this.state.selectedPartnerId.toString(),
            ...(this.state.rfq_status == "Converted to Quote"
              ? {
                  call_response: this.state.call_response,
                  call_id: this.state.call_id,
                  call_status: this.state.call_status,
                  remarks: this.state.remarks,
                  call_attempt_count: this.state.call_attempt_count.toString(),
                  rfq_quote_type: this.state.rfq_quote_type,
                  status: this.state.status,
                  message: this.state.rfq_message,
                }
              : {}),
            paymentTerms: this.checkSetPaymentTermsValue(
              this.state.paymentTerms || this.state.isEmptyTerm.length
                ? this.state.paymentTerms
                : ""
            )
              ? this.state.paymentTerms
              : "",
          },
        };
        // begining code
        if (
          this.state.shipping_postcode > 0 &&
          this.state.shipping_zone_id > 0 &&
          this.state.shipping_city &&
          this.state.quote_subject
        ) {
          return axios(apiUrl + "/create-quote", requestoption)
            .then((data) => {
              localStorage.removeItem("QuoteFromSourcing");
              this.setState(
                {
                  message: "Quote Created Successfully!",
                  is_error: false,
                  is_loading: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      message: "",
                      is_error: false,
                      is_redirect: true,
                    });
                  }, 1000);
                }
              );
            })
            .catch((error) => {
              if (typeof error.response.data.message == "object") {
                this.setState(
                  {
                    tpMessage: error.response.data.message,
                    is_error: true,
                    is_loading: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        tpMessage: [],
                        is_error: false,
                      });
                    }, 1000);
                  }
                );
              } else {
                this.setState(
                  {
                    message: error.response.data.message,
                    is_error: true,
                    is_loading: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        message: "",
                        is_error: false,
                      });
                    }, 1000);
                  }
                );
              }
            });
        } else {
          this.setState({
            is_loading: false,
          });
        }
      }
    );
  };
  getCurrentAgent = (e) => {
    this.setState({
      agent_id: e.target.value,
      rfq_agent_id: e.target.value,
    });
  };
  getBillingZoneID = (e) => {
    this.setState({
      billing_zone_id: e.target.value,
      gstDisable: true,
    });
    this.validateGSTNumber(this.state.billing_gst, e.target.value);
  };
  getShippingZoneID = (e) => {
    this.setState({
      shipping_zone_id: e.target.value,
    });
  };

  onChangeValue = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleUpdate = (e) => {
    if (this.state.quoteID > 0) {
      let submitType = e.target.id;
      e.preventDefault();
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      const result = this.state.getAllProductsWithDetailForList.map(
        ({ type, vendor, ...rest }) => ({ ...rest })
      );
      const token = loginedUserDetails.token;
      this.setState(
        {
          is_loading: true,
          validated: true,
        },
        () => {
          var d = new Date(this.state.startDate);
          var date = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateValidUpto = year + "-" + month + "-" + date;
          let newGst = 0;
          if (
            this.state.selectedGstAddress.split(" ").length === 1 &&
            !isNaN(parseInt(this.state.selectedGstAddress))
          ) {
            newGst = parseInt(this.state.selectedGstAddress);
          }
          let productsData = result
            ? result
            : this.state.getAllProductsWithDetailForList;
          productsData = productsData.map((product) => {
            const {
              brand, // destructuring to exclude brand
              quantity_type, // destructuring to exclude quantity_type
              deliveryCharges,
              markupInterest,
              list_price_interest,
              list_price_org_interest,
              net_amount_interest,
              net_amount_org_interest,
              tax_in_amount_interest,
              totalInterest,
              ...rest
            } = product;
            return {
              ...rest,
              list_price: Number(product.list_price_interest).toFixed(4),
              net_amount: Number(product.net_amount_interest).toFixed(4),
              net_amount_org: Number(product.net_amount_interest).toFixed(4),
              discount_on_mrp: Number(product.discount_on_mrp).toFixed(4),
              tax_in_amount: Number(product.tax_in_amount_interest).toFixed(4),
              transfer_price: Number(product.transfer_price).toFixed(4),
              list_price_org: Number(product.list_price_interest).toFixed(4),
              gross_margin: Number(product.gross_margin).toFixed(4),
              total: Number(product.totalInterest).toFixed(4),
            };
          });
          const requestoption = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: {
              gstBilling: this.state.showGstBox,
              quoteID: this.state.quoteID,
              rfq_id: this.state.rfqID
                ? this.state.rfqID
                : this.props.quoteState.rfqID,
              customer_id: this.state.customer_id,
              customer_email: this.state.customer_email2,
              customer_name: this.state.customer_name2,
              company_id: this.state.company_id,
              company_name: this.state.billing_company_name
                ? this.state.billing_company_name
                : "",
              agent_id: this.state.rfq_agent_id
                ? this.state.rfq_agent_id
                : this.state.agent_id,
              quote_subject: this.state.quote_subject,
              quote_type: submitType,
              valid_upto: dateValidUpto,
              firstname: this.state.billing_first_name
                ? this.state.billing_first_name
                : this.state.customer_name2,
              lastname: this.state.billing_last_name,
              mobile:
                this.state.billing_mobile == "" ||
                this.state.billing_mobile == null
                  ? this.state.customer_phone
                  : this.state.billing_mobile,
              email: this.state.customer_email2,
              address_1: this.state.billing_address_1,
              address_2: this.state.billing_address_2,
              city: this.state.billing_city,
              zone_id: this.state.billing_zone_id,
              postcode: this.state.billing_postcode,
              country_id: this.state.billing_country
                ? this.state.billing_country
                : 99,
              gst: this.state.billing_gst,
              shipping_firstname: this.state.shipping_first_name
                ? this.state.shipping_first_name
                : this.state.customer_name2,
              shipping_lastname: this.state.shipping_last_name,
              shipping_mobile:
                this.state.shipping_mobile == "" ||
                this.state.shipping_mobile == null
                  ? this.state.customer_phone
                  : this.state.shipping_mobile,
              shipping_email: this.state.customer_email2,
              shipping_address_1: this.state.shipping_address_1,
              shipping_address_2: this.state.shipping_address_2,
              shipping_city: this.state.shipping_city,
              shipping_zone_id: this.state.shipping_zone_id,
              shipping_postcode: this.state.shipping_postcode,
              shipping_country_id: this.state.shipping_country,
              shipping_company_name: "",
              shipping_gst: this.state.shipping_gst,
              login_id: loginedUserDetails.user_id,
              sub_total: Number(this.state.subTotal).toFixed(4),
              shippingCharge: Number(this.state.storeInputValue) > 0 ? Number(this.state.storeInputValue).toFixed(4) : Number(this.state.shippingCharges).toFixed(4),
              isCustomerEmail: this.state.customer_email2
                ? true
                : this.state.customer_does_not_email,
              grand_total: Number(this.state.grandTotalInterest).toFixed(4),
              products: productsData,
              label_name: this.state.selectedOption,
              address_type: this.state.address_type, // delivery address choose radio button for Home,Work
              shipping_address_type: this.state.shipping_address_type, // billing address choose radio button for Home,Work
              manager_remarks: this.state.manager_remarks, // manager remarks key
              agent_remarks: this.state.agent_remarks, // manager remarks key,
              address_id: this.state.showGstBox ? newGst : 0,
              plan_id: this.state.getPlanId ? this.state.getPlanId.toString() : this.state.selectedPlanId.toString(),
              partner_id: this.state.getPartnerId ? this.state.getPartnerId.toString() : this.state.selectedPartnerId.toString(),
              paymentTerms: this.checkSetPaymentTermsValue(
                this.state.paymentTerms || this.state.isEmptyTerm.length
                  ? this.state.paymentTerms
                  : ""
              )
                ? this.state.paymentTerms
                : "",
            },
          };
          // beginning code
          if (
            this.state.shipping_postcode > 0 &&
            this.state.shipping_zone_id > 0 &&
            this.state.shipping_city &&
            this.state.quote_subject
          ) {
            return axios(apiUrl + "/edit-quote", requestoption)
              .then((data) => {
                this.setState(
                  {
                    message: data.data.message,
                    is_error: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        message: "",
                        is_error: false,
                        is_redirect: true,
                      });
                    }, 2000);
                  }
                );
              })
              .catch((error) => {
                if (typeof error.response.data.message == "object") {
                  this.setState(
                    {
                      tpMessage: error.response.data.message,
                      is_error: true,
                      is_loading: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          tpMessage: [],
                          is_error: false,
                        });
                      }, 1000);
                    }
                  );
                } else {
                  this.setState(
                    {
                      message: error.response.data.message,
                      is_error: true,
                      is_loading: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          message: "",
                          is_error: false,
                        });
                      }, 1000);
                    }
                  );
                }
              });
          } else {
            this.setState({
              is_loading: false,
            });
          }
        }
      );
    }
  };

  openDuplicate = () => {
    this.setState({
      redirectToDuplicate: true,
    });
  };
  setItem = () => {
    var data = {
      quoteID: this.state.quoteID,
      productsArr: this.state.productsArr,
      customer_id: this.state.customer_id,
    };
    localStorage.setItem("dataKey", JSON.stringify(data));
  };
  openRfq = () => {
    this.setState({
      requestQuoteID: this.state.rfqID,
      showRfq: true,
    });
  };
  onSelect = (e) => {
    if (!this.state.showGstBox) {
      this.setState({
        showGstBox: true,
      });
    }
    if (this.state.showGstBox) {
      this.setState({
        showGstBox: false,
        showGstAddress: false,
        selectedGstAddress: "",
        gstNumber: "",
        selectedOneGstAddress: 0,
        selectedOneBusinessGstAddress: "",
      });
    }
  };
  onSelectBillingAddress = (e) => {
    if (this.state.showGstBox) {
      this.setState({
        showGstBox: true,
      });
    }
    if (!this.state.showGstBox) {
      this.setState({
        showGstBox: false,
      });
    }
  };
  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showGSTDetailsCheckbox: !prevState.showGSTDetailsCheckbox,
      showDisabledFields: !prevState.showDisabledFields,
    }));
  };

  handleCloseVerifyGSTModal = () => {
    this.setState({
      showModalVerifyGST: false,
    });
  };
  handleOpenVerifyGSTModal = () => {
    this.setState({
      showModalVerifyGST: true,
    });
  };
  submitVerifyGSTRadioButtonHandler = () => {
    this.setState({
      showModalVerifyGST: false,
    });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  searchGst = (e) => {
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const token = loginedUserDetails.token;
    this.setState({
      gstLoading: true,
    });
    const requestoption = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        gst: this.state.gstNumber,
      },
    };
    return axios(apiUrl + "/gst-validate", requestoption)
      .then((data) => {
        let gstData = data.data.data[0];
        if (!gstData.zone_id) {
          this.setState({
            gstNotInDB: true,
          });
        } else {
          this.setState({
            gstNotInDB: false,
            showModal: true,
            gstAddressArr: data.data.data[0],
          });
        }
        let gstAddress =
          this.state.gstNumber +
          " - " +
          gstData.address1 +
          ", " +
          gstData.address2 +
          ", " +
          gstData.city +
          ", " +
          gstData.state +
          ", " +
          gstData.pincode;
        this.setState({
          gstLoading: false,
          showGstAddress: true,
          selectedGstAddress: gstAddress,
          selectedAddress: 0,
          billing_address_1: gstData.address1,
          billing_address_2: gstData.address2,
          billing_postcode: gstData.pincode,
          billing_city: gstData.city,
          billing_zone_name: gstData.state,
          address_type: "GST",
          billing_country_name: "India",
          billing_country: 99,
          billing_company_name: gstData.company_name,
          billing_first_name: this.state.first_name
            ? this.state.first_name
            : this.state.customer_name2,
          billing_mobile: this.state.mobile_number
            ? this.state.mobile_number
            : this.state.customer_phone2,
          billing_gst: this.state.gstNumber,
          billing_zone_id: gstData.zone_id,
        });
      })
      .catch((err) => {
        this.setState(
          {
            gstLoading: false,
            message: "No Address With This GST NO. is Found ",
            is_error: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 3000);
          }
        );
      });
  };
  searchBusinessGst = (e) => {
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    this.setState({
      gstLoading: true,
    });
    const requestoption = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        gst: this.state.gstNumber,
      },
    };
    return axios(apiUrl + "/gst-validate", requestoption)
      .then((data) => {
        let gstData = data.data.data[0];
        if (!gstData.zone_id) {
          this.setState({
            gstNotInDB: true,
          });
        } else {
          this.setState({
            gstNotInDB: false,
            showModalVerifyGST: true,
            gstBusinessAddressArr: data.data.data[0],
          });
        }
        this.setState({
          gstLoading: false,
        });
      })
      .catch((err) => {
        this.setState(
          {
            gstLoading: false,
            message: "No Address With This GST NO. is Found ",
            is_error: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 3000);
          }
        );
      });
  };
  getGstAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedGstAddress: e.target.value,
      });
      let index = this.state.addressArray.filter(
        (el) => el.address_id == e.target.value
      )[0];
      if (index) {
        this.setState({
          billing_first_name: index.firstname || this.state.first_name,
          billing_name: index.name || "",
          billing_company_name: index.addressCompany
            ? index.addressCompany
            : index.company,
          billing_mobile: index.mobile || this.state.mobile_number,
          billing_address_1: index.address_1 || "",
          billing_address_2: index.address_2 || "",
          billing_city: index.city || "",
          billing_zone_id: index.zone_id || "",
          billing_postcode: index.postcode || "",
          billing_country: 99,
          billing_country_name: "India",
          billing_gst: index.gstin ? index.gstin : "",
          address_type: index.address_type ? index.address_type : "",
          showGstAddress: true,
        });
      }
    }
  };
  // GET ALL G.S.T ADDRESS BUT SELECT ONLY ONE G.S.T ADDRESS FUNCTION
  getAllGstAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedGstAddress: e.target.value,
      });
      let index = this.state.gstAddressArr.address_arr.filter(
        (el) => el.address1 == e.target.value
      )[0];
      // Display Selected Company Name
      let selectedCompany_Name = this.state.gstAddressArr.company_name;
      let selectedGST_Number = this.state.gstNumber;
      if (index) {
        this.setState({
          billing_first_name: index.firstname || this.state.first_name,
          billing_name: index.name || "",
          billing_company_name: selectedCompany_Name || "",
          billing_mobile: index.mobile || this.state.mobile_number,
          billing_address_1: index.address1 || "",
          billing_address_2: index.address2 || "",
          billing_city: index.city || "",
          billing_zone_id: index.shop_state_id || "",
          billing_postcode: index.pncd || "",
          billing_country: 99,
          billing_country_name: "India",
          billing_gst: selectedGST_Number || "",
          address_type: index.address_type ? index.address_type : "GST",
          showGstAddress: true,
        });
      }
    }
  };

  // GET ALL BUSINESS G.S.T ADDRESS BUT SELECT ONLY ONE G.S.T ADDRESS FUNCTION
  getAllBusinessGstAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedOneBusinessGstAddress: e.target.value,
      });
      let selectedCompany_Name = this.state.gstBusinessAddressArr.company_name;
      let index = this.state.gstBusinessAddressArr.address_arr.filter(
        (el) => el.address1 == e.target.value
      )[0];
      if (index) {
        this.setState({
          new_postcode: index.pncd || "",
          new_city: index.city || "",
          new_zone_id: index.shop_state_id || "",
          new_country: "India",
          new_address1: index.address1 || "",
          new_address2: index.address2 || "",
          new_companyName: selectedCompany_Name || "",
        });
      }
    }
  };

  submitGSTRadioButtonHandler = () => {
    this.setState({
      showModal: false,
    });
  };

  checkSetPaymentTermsValue = (htmlString) => {
    const pattern = /<[^>]+>([^<]*)<\/[^>]+>/g;
    const matches = htmlString.match(pattern);
    if (matches) {
      for (const match of matches) {
        const content = match.replace(/<\/?[^>]+(>|$)/g, "").trim();
        if (content) {
          return true;
        }
      }
    }
    return false;
  };

  handleBarCustomerEmailCheckbox = () => {
    this.setState((prevState) => ({
      customer_does_not_email: !prevState.customer_does_not_email,
    }));
  };

  handleAttachmentClick(url) {
    window.open(url, "_blank"); // Open link in a new tab
  }

  render() {
    if (this.state.quoteID == "undefined") {
      return <Redirect to="/listQuote" />;
    }
    if (this.state.is_redirect == true) {
      return <Redirect to="/listQuote" />;
    }

    return (
      <>
      <Row>
        <Col md={12}>
          {this.state.message.length > 0 ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.is_error ? "danger" : "success"}
            >
              {this.state.message}
            </Alert>
          ) : null}
          {this.state.productmessage ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.productmessage ? "success" : "danger"}
            >
              {this.state.productmessage}
            </Alert>
          ) : null}
          {this.state.new_address_success_message ? (
            <Alert className="displayMessageArea" variant="success">
              {this.state.new_address_success_message}
            </Alert>
          ) : null}
          {this.state.tpMessage.length ? (
            <Alert className="displayMessageArea" variant="danger">
              {this.state.tpMessage &&
                this.state.tpMessage.map((data) => {
                  return <p className="my-3">{data.name}</p>;
                })}
            </Alert>
          ) : null}
          {this.state.gstNotInDB ? (
            <Alert className="displayMessageArea" variant="danger">
              <p className="my-1">Check PinCode ZoneID</p>
            </Alert>
          ) : null}

          {this.state.gstErrorMessage != "" ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.gstErrorMessage != "" ? "danger" : "success"}
            >
              {this.state.gstErrorMessage}
            </Alert>
          ) : (
            ""
          )}
          {this.state.showRfq ? (
            <ApproveQuote
              show={this.state.showRfq}
              requestQuoteID={this.state.rfqID}
              rfqDisable={this.state.rfqDisable}
              getUserDetails={this.props.getUserDetails}
              closeModal={this.closeModal}
              getAllQuote={this.getQuoteDetailByID}
            />
          ) : null}

          {/* Add New Address form below */}
          <Modal show={this.state.show} onHide={this.closeModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Address Below</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.new_address_error_message != "" ? (
                <Alert className="displayMessageArea" variant="danger">
                  {this.state.new_address_error_message}
                </Alert>
              ) : (
                ""
              )}

              {this.state.gstErrorMessage != "" ? (
                <Alert
                  className="displayMessageArea"
                  variant={
                    this.state.gstErrorMessage != "" ? "danger" : "success"
                  }
                >
                  {this.state.gstErrorMessage}
                </Alert>
              ) : (
                ""
              )}

              <Form noValidate validated={this.state.newValidated}>
                {/* ADD GST ADDRESS COMPONENT START HERE */}
                <div className="d-flex align-items-center mb-3">
                  <Form.Check
                    className="mr-2"
                    inline
                    name="group1"
                    type="checkbox"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.showGSTDetailsCheckbox}
                  />
                  <label className="mb-0">
                    <p className="mb-0">
                      <b>Use GSTIN for Business Purchase(Optional)..</b>
                    </p>
                    <p className="mb-0">
                      Get GST Invoice and claim input tax credit on your
                      purchase.
                    </p>
                  </label>
                </div>
                {this.state.showGSTDetailsCheckbox && (
                  <>
                    <h3 style={{ fontSize: "1rem" }}>
                      <b>Business Details</b>
                    </h3>
                    <Form.Row>
                      <div className="col-4">
                        <Form.Group>
                          <Form.Control
                            name="gstNumber"
                            onChange={this.handleBusinessGstChange}
                            value={this.state.gstNumber}
                            type="text"
                            placeholder="Enter Your GSTIN"
                          />
                        </Form.Group>
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={this.searchBusinessGst}
                        id="searchGst"
                        size="sm"
                        style={{
                          width: "6rem",
                          height: " 2.5rem",
                          marginLeft: "inherit",
                        }}
                        disabled={!this.state.gstError}
                      >
                        {this.state.gstLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading</span>
                          </Spinner>
                        ) : (
                          "Verify"
                        )}
                      </Button>

                      <div className="col-6">
                        <Form.Group
                          className="mb-3 ml-3"
                          controlId="formGridCompany"
                        >
                          <Form.Control
                            type="text"
                            name="new_companyName"
                            placeholder="Company"
                            autoFocus
                            onChange={this.newHandleChange}
                            value={this.state.new_companyName}
                            disabled
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter billing company name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Form.Row>
                  </>
                )}
                {/* ADD GST ADDRESS COMPONENT ENDS HERE */}

                {/* Verify Business Details Modal Pop UP Component Start here */}
                {this.state.gstBusinessAddressArr?.address_arr ? (
                  <Modal
                    scrollable={true}
                    show={this.state.showModalVerifyGST}
                    onHide={this.handleCloseVerifyGSTModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Select Your GST Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.state.gstBusinessAddressArr?.address_arr?.map(
                        (gstAddressDetails, index) => (
                          <React.Fragment key={index}>
                            <label className="d-flex justify-content-center align-items-center">
                              <Form.Check
                                type="radio"
                                name="gstBusinessAddressArr"
                                value={gstAddressDetails.address1}
                                checked={
                                  this.state.selectedOneBusinessGstAddress ===
                                  gstAddressDetails.address1
                                }
                                onChange={this.getAllBusinessGstAddress}
                              />
                              {`
                                ${gstAddressDetails.address1},
                                ${gstAddressDetails.address2},
                                ${gstAddressDetails.city},
                                ${gstAddressDetails.state},
                                ${gstAddressDetails.pncd}. 
                              `}
                            </label>
                          </React.Fragment>
                        )
                      )}
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-end">
                      <Button
                        variant="secondary"
                        onClick={this.handleCloseVerifyGSTModal}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={this.submitVerifyGSTRadioButtonHandler.bind(
                          this
                        )}
                      >
                        OK
                      </Button>
                    </Modal.Footer>
                  </Modal>
                ) : null}
                {/* Verfify Business Details Modal Pop Up Component Ends here */}

                <h3 className="con_detail_heading">Address</h3>
                <div className="row">
                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formPostCode">
                      <Form.Label>PostCode</Form.Label>
                      <Form.Control
                        className={this.state.new_wrong_pin ? "error" : ""}
                        type="number"
                        name="new_postcode"
                        placeholder="PostCode"
                        autoFocus
                        onChange={this.newHandleChangePincode}
                        value={this.state.new_postcode}
                        disabled={this.state.showDisabledFields}
                        required
                      />
                    </Form.Group>
                  </div>

                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_city"
                        placeholder="City"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_city}
                        required
                        disabled={this.state.showDisabledFields}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="new_zone_id"
                        onChange={this.getNewZoneID}
                        disabled={this.state.showDisabledFields}
                      >
                        <option value="">Please select state</option>
                        {this.state.stateArray.length > 0 &&
                          this.state.stateArray.map((item) => {
                            var selected =
                              this.state.new_zone_id == item.id ? true : false;
                            return (
                              <option
                                selected={selected}
                                key={item.id}
                                value={item.id}
                                defaultValue=""
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formCountry">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_country"
                        placeholder="Country"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_country}
                        required
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formAddress1">
                      <Form.Label>Address1</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_address1"
                        placeholder="Address1"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_address1}
                        required
                        disabled={this.state.showDisabledFields}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formAddress2">
                      <Form.Label>Address2</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_address2"
                        placeholder="Address2"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_address2}
                        required
                        disabled={this.state.showDisabledFields}
                      />
                    </Form.Group>
                  </div>
                </div>

                <Form.Group className="mb-3" controlId="formAddressType">
                  <div className="d-flex">
                    <Form.Label className="con_detail_heading mr-3">
                      Address Type
                    </Form.Label>
                    {this.state.address_array.map((item) => {
                      return (
                        <div className="d-flex mr-3">
                          <Form.Check
                            type="radio"
                            value={item}
                            name="home"
                            checked={this.state.new_address_type === item}
                            onChange={this.handleChangeAddressTypeRadio}
                            required
                          />
                          <label key={item}>{item}</label>
                        </div>
                      );
                    })}
                  </div>
                </Form.Group>

                <h3 className="con_detail_heading">Contact Details</h3>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_name"
                        placeholder="Name"
                        autoFocus
                        onChange={this.newHandleChange}
                        defaultValue={
                          this.state.new_name
                            ? this.state.new_name
                            : this.state.customer_name2
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the Name first.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formMobile">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="number"
                        name="new_mobile"
                        placeholder="Mobile"
                        autoFocus
                        onChange={this.newHandleChange}
                        defaultValue={
                          this.state.new_mobile
                            ? this.state.new_mobile
                            : this.state.customer_phone2
                        }
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="Submit"
                onClick={
                  this.state.gstError
                    ? this.addNewAddress
                    : this.handleFormSubmit
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Create Quote Main Page Form Below */}
          <Form noValidate validated={this.state.validated}>
            <div className="card">
              <div className="card-header justify-content-between">
                <h3>
                  <b>Quote Information</b>
                </h3>
                <div>
                  <Button
                    variant="secondary"
                    size="md"
                    onClick={this.openRfq}
                    className="mr-5"
                  >
                    View RFQ Detail
                  </Button>
                  {this.state.sourcingStatus === "reopen" ? (
                    ""
                  ) : (
                    <>
                      {this.state.allowDuplicate ? (
                        <Button
                          variant="primary"
                          size="md"
                          onClick={this.openDuplicate}
                          className="mr-3"
                        >
                          <Link
                            to="/duplicateQuote"
                            onClick={
                              this.setItem
                            }
                            style={{ textDecoration: "none", color: "white" }}
                            target="_blank"
                          >
                            Edit Quote to Mark as Duplicate
                          </Link>
                        </Button>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
              <div className="card-body create_quote_details">
                <Form.Row>
                  <Col md="3">
                    <Form.Group controlId="customerPhone">
                      <Form.Label>Customer Phone</Form.Label>
                      <Form.Control
                        type="number"
                        name="customer_phone2"
                        placeholder="Customer Phone"
                        defaultValue={this.state.customer_phone2}
                        disabled
                        onChange={this.getPhoneValue}
                        className={this.state.wrong_phone ? "error" : ""}
                        required
                      />
                      <Form.Control
                        name="customer_mobile"
                        type="hidden"
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group
                      controlId="exampleForm.SelectCustom"
                      value="b2blandingpage"
                    >
                      <Form.Label>
                        Account Name <span>(*)</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.companyName}
                        name="companyName"
                        placeholder="Company Name"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Quote Owner</Form.Label>
                      {this.state.rfq_agent_id ? (
                        <Form.Control
                          as="select"
                          custom
                          name="rfq_agent_id"
                          required
                          onChange={this.getCurrentAgent}
                          value={this.state.rfq_agent_id}
                          defaultValue={this.state.rfq_agent_id}
                        >
                          <option value="">Please select agent</option>
                          {this.state.agentArray.map((item) => {
                            var selected =
                              this.state.rfq_agent_id == item.id ? true : false;
                            return item.id ? (
                              <option
                                selected={selected}
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ) : null;
                          })}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          as="select"
                          custom
                          name="agent_id"
                          required
                          onChange={this.getCurrentAgent}
                          value={this.state.agent_id}
                        >
                          <option value="">Please select agent</option>
                          {this.state.agentArray.map((item) => {
                            var selected =
                              this.state.agent_id == item.id ? true : false;
                            return item.id ? (
                              <option
                                selected={selected}
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ) : null;
                          })}
                        </Form.Control>
                      )}

                      <Form.Control.Feedback type="invalid">
                        Please select quote owner.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="formGridSubject">
                      <Form.Label>Quote Subject</Form.Label>
                      <Form.Control
                        type="text"
                        name="quote_subject"
                        placeholder="Quote Subject"
                        value={this.state.quote_subject}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose quote subject.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="formGridValidUpto">
                      <Form.Label>Valid Until</Form.Label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={this.state.startDate}
                        onChange={this.handleDateChange}
                        className="form-control"
                        locale="es"
                        minDate={new Date()}
                        value={this.state.startDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="customerName">
                      <Form.Label>Customer Name </Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.customer_name2}
                        name="customer_name"
                        placeholder="Customer Name"
                        disabled
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="customerEmail">
                      <Form.Label>Customer Email </Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.customer_email2}
                        name="customer_email"
                        placeholder="Customer Email"
                        className={
                          this.state.customer_email2 === "" ? "error" : ""
                        }
                        disabled
                        required={
                          !this.state.customer_does_not_email ? false : true
                        }
                      />
                      {!this.state.customer_email2 ? (
                        <Form.Check
                          className="mr-2 font-weight-bold mt-3"
                          inline
                          name="group1"
                          type="checkbox"
                          label={`Customer does not have email`}
                          onChange={this.handleBarCustomerEmailCheckbox}
                        />
                      ) : null}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
            </div>

            <div className="card">
              <div className="card-body create_quote_details">
                {}
                {this.state.rfqItems.length ? (
                  <>
                    <h5>RFQ Products</h5>
                    <div className="p-3 my-3 rounded border border-5 border-dark">
                      {this.state.rfqItems.length > 0 &&
                        this.state.rfqItems.map((product, index) => {
                          return (
                            <Form.Row
                              style={{ justifyContent: "space-between" }}
                            >
                              <Col md="8" key={index}>
                                {product && product.productName != null ? (
                                  <>
                                    {product.productName.includes(
                                      this.state.url
                                    ) ? (
                                      <div>
                                        <b>
                                          <a
                                            style={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            href={product.productName}
                                          >
                                            Attachment {index + 0}
                                          </a>
                                        </b>
                                      </div>
                                    ) : (
                                      <div>
                                        <b>{product.productName}</b> (
                                        <b>
                                          {product.productQuantity} Quantity
                                        </b>
                                        )
                                      </div>
                                    )}
                                  </>
                                ) : null}
                              </Col>
                            </Form.Row>
                          );
                        })}
                      {/* Attachment Show Component start here */}
                      {this.state.attachment &&
                      this.state.attachment.length > 0 ? (
                        <>
                          <Col sm={12}>
                            <ul
                              className="row"
                              style={{ listStyleType: "none" }}
                            >
                              {this.state.attachment.map(
                                (attachments, index) => (
                                  <li className="p-1" key={index}>
                                    <a
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      href={attachments.url}
                                      target="_blank"
                                      onClick={() =>
                                        this.handleAttachmentClick(
                                          attachments.url
                                        )
                                      }
                                    >
                                      {`Attachment ${index + 1}`}
                                    </a>
                                  </li>
                                )
                              )}
                            </ul>
                          </Col>
                        </>
                      ) : null}
                      {/* Attachment Show Component ends here */}
                    </div>
                  </>
                ) : null}
              </div>
              {/* Credit Limit Fucntionality Start here */}
              {this.state.getAllProductsWithDetailForList.length > 0 ? (
                <div className="card-header">
                <div className="quotesHeadingCnt">
                  <h3 className="mainHeading">Product Detail</h3>
                  {this.state.showCreditPlans && this.state.showCreditPlans?.map((item) => {
                    return (                  
                        <div className="quotesTypeCnt" key={item.partner_name}>
                            <div className="quotesTypeWrapper customCheckbox quotesTooltipCnt">
                                <input 
                                 disabled={!item.plan_status}
                                  type="checkbox"
                                  checked={item.partner_id == this.state.getPartnerId && 
                                           item.plan_id == this.state.getPlanId && 
                                           this.state.getPlanId !== ''}
                                  onChange={(e) =>
                                    this.handleCheckboxSelection({
                                      isChecked: e.target.checked,
                                      plan_interest: item.plan_interest, // Send the interest rate
                                      plan_id: item.plan_id,
                                      partner_id: item.partner_id,
                                      plan_days: item.plan_days,
                                      partner_name: item.partner_name
                                    })
                                  }
                                />
                                <label className={item.plan_status ? null : "disabled"}>
                                  {item.partner_name === "Advance" ? 
                                   item.partner_name : 
                                   `${item.partner_name} (${item.plan_days} days) `}
                                </label>
                                {item.partner_name != "Advance" ? (  
                                  <div className="quotesTooltipWrapper">
                                    <img className="tooltipArrow" src={tooltipArrowIcon} alt="arrow icon" width="16px" height="10px"/>
                                    <p>{item.plan_interest}%</p>
                                    <p>{item.credit_limit}</p>
                                    <p>{item.plan_status ? null : "Not Active"}</p>
                                  </div>
                                ):null}
                            </div>
                        </div>
                    )
                    })}
                </div>
                </div>
              ) :null}
              {/* Credit Limit Functionality Ends here */}
              
              {this.state.getAllProductsWithDetailForList.length > 0 ? (
                <ProductLists
                 key={this.state.triggerRender}
                 trigger={this.state.triggerRender}
                  getAllProductsWithDetailForList={this.state.getAllProductsWithDetailForList}
                  touched={this.state.touched}
                  currentInterest={this.state.currentInterest}
                  creditCharges={this.state.creditCharges}
                  checkCheckboxValue={this.state.checkCheckboxValue}
                  totalTaxAmountInterest={this.state.totalTaxAmountInterest}
                  grandTotalInterest={this.state.grandTotalInterest}
                  updateParentState={this.updateParentState}
                  magicProductsArray={this.state.magicProductsArray}
                  quoteMinimumGrossMargin={this.state.quoteMinimumGrossMargin}
                  quoteGrossMargin={this.state.quoteGrossMargin}
                  totalSystemDeliverRate={this.state.totalSystemDeliverRate}
                  totalDiscountOnMrp={this.state.totalDiscountOnMrp}
                  totalMrp={this.state.totalMrp}
                  totalNetAmount={this.state.totalNetAmount}
                  totalNetAmountInterest={this.state.totalNetAmountInterest}
                  totalTaxAmount={this.state.totalTaxAmount}
                  subTotal={this.state.subTotal}
                  shippingCharge={this.state.shippingCharge}
                  rfqShippingCharges={this.state.rfqShippingCharges} 
                  grandTotal={this.state.grandTotal}
                  quote_type={this.state.quote_type}
                  productTotal={this.state.productTotal}
                  quote_id={this.state.quoteDetail}
                  shippingCharges={this.state.shippingCharges}
                  specialTP={this.specialTP}
                  updateTotal={this.updateTotal}
                  addshippingCharges={this.addshippingCharges}
                  updateQuantityWithPrices={this.updateQuantityWithPrices}
                  updateMarkupWithPrices={this.updateMarkupWithPrices}
                  removeItemFromMainList={this.removeItemFromMainList}
                />
              ) : null}

              <AddItemModal
                getAllProductsWithDetailForList={this.state.getAllProductsWithDetailForList}
                displayProductArray={this.state.displayProductArray}
                rfq_products={this.state.rfq_products}
                custome_id={this.state.customer_id}
                customer_name={this.state.customer_name2}
                customer_email={this.state.customer_email2}
                customer_mobile={this.state.phone2}
                categoryDisable={this.state.categoryDisable}
                getProductArray={this.displayAllProducts}
                customerMobile={this.customerMobile}
                customer_idB2={this.customer_idB2}
              />
            </div>

            {/* Billing & Shipping Address Component Start here */}
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col md="2">
                    <h3 className="billingAdressHeader">
                      <b>Delivery & Billing Address</b>
                    </h3>
                  </Col>
                  <Col md="2">
                    <Button
                      variant="outline-secondary"
                      onClick={this.openModal}
                      className="pull-right copyAddress w-100"
                      disabled={this.state.isDisable}
                    >
                      Add New Address
                    </Button>
                  </Col>
                </Row>
              </div>

              <div className="card-body create_quote_details">
                <Form.Row>
                  <>
                    <div>
                      <input
                        type="checkbox"
                        className="selection-input-4"
                        onChange={(e) => this.onSelect(e)}
                        checked={this.state.showGstBox ? true : false}
                      />
                    </div>
                    <div className="ml-3 mb-3">
                      <b>Use GSTIN for Business Purchase (Optional).</b>
                      <br />
                      Get GST Invoice and claim input tax credit on your
                      purchase
                    </div>
                  </>
                </Form.Row>
                <>
                  {/* MODAL OVERLAY START HERE FOR G.S.T POP UP */}
                  {this.state.gstAddressArr?.address_arr ? (
                    <Modal
                      scrollable={true}
                      show={this.state.showModal}
                      onHide={this.handleCloseModal}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Select Your GST Address</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {this.state.gstAddressArr?.address_arr?.map(
                          (gstAddressDetails, index) => (
                            <React.Fragment key={index}>
                              <label className="d-flex justify-content-center align-items-center">
                                <Form.Check
                                  type="radio"
                                  name="gstAddressArr"
                                  value={gstAddressDetails.address1}
                                  checked={
                                    this.state.selectedGstAddress ===
                                    gstAddressDetails.address1
                                  }
                                  onChange={this.getAllGstAddress}
                                />
                                {`
                                  ${gstAddressDetails.address1},
                                  ${gstAddressDetails.address2},
                                  ${gstAddressDetails.city},
                                  ${gstAddressDetails.state},
                                  ${gstAddressDetails.pncd}. 
                                `}
                              </label>
                            </React.Fragment>
                          )
                        )}
                      </Modal.Body>
                      <Modal.Footer className="d-flex justify-content-end">
                        <Button
                          variant="secondary"
                          onClick={this.handleCloseModal}
                        >
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={this.submitGSTRadioButtonHandler.bind(this)}
                        >
                          OK
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  ) : null}
                  {/* MODAL OVERLAY ENDS HERE FOR G.S.T POP UP */}
                </>
                {this.state.wrongFormat ? (
                  <Form.Row>
                    <Form.Label>{this.state.wrongFormat}</Form.Label>
                  </Form.Row>
                ) : null}
              </div>
              <div className="card-body create_quote_details">
                <Row>
                  {/* Billing address form Start */}
                  <Col md="6">
                    <h6 style={{ fontSize: "1.4rem" }}>
                      <b>Delivery Address</b>
                    </h6>
                    <h6>Saved Address</h6>
                    <Form.Group controlId="exampleFormAddress">
                      <Form.Control
                        as="select"
                        name="address_type"
                        custom
                        onChange={this.getSelectShippingAddress}
                        value={this.state.selectedShippingAddress}
                      >
                        {this.state.shipping_address_1 ? (
                          <option value={this.state.shipping_address_1}>
                            {this.state.shipping_address_1}
                          </option>
                        ) : (
                          <option value="">Select a saved address</option>
                        )}
                        {this.state.addressArray &&
                          this.state.addressArray.map((item) => {
                            return (
                              <option
                                key={item.address_id}
                                value={item.address_id}
                              >
                                {item.gstin} {item.name}, {item.address_1},
                                {item.city},{item.state} {item.postcode}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    <h6>
                      <b>Address Details</b>
                    </h6>
                    <Form.Row>
                      <Col md="4">
                        <Form.Group controlId="formGridShippingPostCode">
                          <Form.Label>Pincode</Form.Label>
                          <Form.Control
                            type="number"
                            name="shipping_postcode"
                            onChange={this.handleChangeShippingPincode}
                            value={this.state.shipping_postcode}
                            placeholder="Postcode"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping postcode.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group controlId="formGridShippingCity">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            name="shipping_city"
                            onChange={this.handleChange}
                            value={this.state.shipping_city}
                            type="text"
                            placeholder="City"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping city.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group controlId="formGridShippingZoneID">
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="shipping_zone_id"
                            value={this.state.shipping_zone_id}
                            required
                            onChange={this.getShippingZoneID}
                          >
                            <option value="">Please select state</option>
                            {this.state.stateArray.map((item) => {
                              var selected =
                                this.state.shipping_zone_id == item.id
                                  ? true
                                  : false;
                              return (
                                <option
                                  selected={selected}
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select state.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col md="6">
                        <Form.Group controlId="formGridShippingAddress1">
                          <Form.Label>Address 1</Form.Label>
                          <Form.Control
                            name="shipping_address_1"
                            onChange={this.handleChange}
                            value={this.state.shipping_address_1}
                            type="text"
                            placeholder="Address 1"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping address 1.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="formGridShippingAddress2">
                          <Form.Label>Address 2</Form.Label>
                          <Form.Control
                            name="shipping_address_2"
                            onChange={this.handleChange}
                            value={this.state.shipping_address_2}
                            type="text"
                            placeholder="Address 2"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping address 2.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Group controlId="formAddressType">
                      <Form.Label>
                        <b>Address Type</b>
                      </Form.Label>
                      <div className="d-flex">
                        {this.state.address_array.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Form.Check
                                type="radio"
                                value={item}
                                checked={
                                  this.state.shipping_address_type === item
                                }
                                onChange={
                                  this.handleChangeShippingAddressTypeRadio
                                }
                                required
                                label={item}
                                className="mr-3"
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Form.Group>

                    <h6>
                      <b>Contact Details</b>
                    </h6>
                    <Form.Row>
                      <Col md="6">
                        <Form.Group controlId="formGridShippingFirstName">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            onChange={this.handleChange}
                            name="shipping_first_name"
                            defaultValue={
                              this.state.shipping_first_name
                                ? this.state.shipping_first_name
                                : this.state.customer_name2
                            }
                            type="text"
                            placeholder="Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping first name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="formGridShippingMobile">
                          <Form.Label>Contact No</Form.Label>
                          <Form.Control
                            name="shipping_mobile"
                            onChange={this.handleChange}
                            defaultValue={
                              this.state.shipping_mobile
                                ? this.state.shipping_mobile
                                : this.state.customer_phone2
                            }
                            type="text"
                            placeholder="Mobile"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping mobile number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Group controlId="formGridShippingCountry">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        name="shipping_country_name"
                        onChange={this.handleChange}
                        value={this.state.shipping_country_name}
                        type="text"
                        placeholder="Country"
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter shipping country name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* Shipping Address Form Start */}

                  <Col md="6">
                    {this.state.showGstBox ? (
                      <>
                        <h6 style={{ fontSize: "1.4rem" }}>
                          <b>Billing Address</b>
                        </h6>
                        <Form.Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Saved GST Address
                              </Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                name="gst_address"
                                onChange={this.getGstAddress}
                                value={this.state.selectedGstAddress}
                              >
                                <option value="0">
                                  Select a saved GST address
                                </option>
                                {this.state.addressArray &&
                                  this.state.addressArray.map((item) => {
                                    return item.gst_address == 1 ||
                                      (item.gstin &&
                                        item.gstin.length == 15) ? (
                                      <>
                                        <option value={item.address_id}>
                                          {item.gstin}, {item.name}{" "}
                                          {item.address_1}, {item.address_2},{" "}
                                          {item.city},{item.state},
                                          {item.postcode}
                                        </option>
                                      </>
                                    ) : null;
                                  })}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Form.Row>
                          <Col className="col-6">
                            <Form.Group controlId="formGridCompany">
                              <Form.Label>Company</Form.Label>
                              <Form.Control
                                name="billing_company_name"
                                onChange={this.handleChange}
                                value={this.state.billing_company_name}
                                type="text"
                                placeholder="Company"
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing company name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <div className="col-6">
                            <Form.Group controlId="formGridGST">
                              <Form.Label>Gst No</Form.Label>
                              <Form.Control
                                className={!this.state.gstError ? "error" : ""}
                                name="billing_gst"
                                onChange={this.handleGstChange}
                                value={this.state.billing_gst}
                                type="text"
                                placeholder="Gst No"
                                disabled
                              />
                              {!this.state.gstError ? (
                                <Form.Control.Feedback type="invalid">
                                  Please select state.
                                </Form.Control.Feedback>
                              ) : null}
                            </Form.Group>
                          </div>
                        </Form.Row>
                        <h6>Saved Address</h6>

                        <Form.Group controlId="exampleFormAddress">
                          <Form.Control
                            as="select"
                            name="address_type"
                            custom
                            onClick={this.getNewAddress}
                            onChange={this.getSelectAddress}
                            value={this.state.selectedAddress}
                            disabled={this.state.showGstBox}
                          >
                            {this.state.showGstAddress ||
                            this.state.billing_address_1 ? (
                              <option value={0}>
                                {this.state.billing_address_1}
                              </option>
                            ) : (
                              <option value="">Select a saved address</option>
                            )}

                            {!this.state.showGstAddress &&
                              this.state.addressArray &&
                              this.state.addressArray.map((item) => {
                                return (
                                  <option
                                    key={item.address_id}
                                    value={item.address_id}
                                  >
                                    {item.name}, {item.address_1}, {item.city},{" "}
                                    {item.postcode}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Form.Group>

                        <h6>
                          <b>Address Details</b>
                        </h6>
                        <div className="row">
                          <div className="col-4">
                            <Form.Group controlId="formGridPostCode">
                              <Form.Label>Pincode</Form.Label>
                              <Form.Control
                                className={this.state.wrong_pin ? "error" : ""}
                                name="billing_postcode"
                                type="number"
                                onChange={this.handleChangePincode}
                                value={this.state.billing_postcode}
                                placeholder="Postcode"
                                required
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing postcode.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-4">
                            <Form.Group controlId="formGridCity">
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                name="billing_city"
                                onChange={this.handleChange}
                                value={this.state.billing_city}
                                type="text"
                                placeholder="City"
                                required
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing city.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-4">
                            <Form.Group controlId="formGridBillingZoneID">
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                name="billing_zone_id"
                                required
                                onChange={this.getBillingZoneID}
                                value={this.state.billing_zone_id}
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              >
                                {this.state.billing_zone_name ? (
                                  <option value={this.state.billing_zone_id}>
                                    {this.state.billing_zone_name}
                                  </option>
                                ) : (
                                  <>
                                    <option value="">
                                      Please select state
                                    </option>
                                    {this.state.stateArray.length > 0 &&
                                      this.state.stateArray.map((item) => {
                                        var selected =
                                          this.state.billing_zone_id == item.id
                                            ? true
                                            : false;
                                        return (
                                          <option
                                            selected={selected}
                                            key={item.id}
                                            value={item.id}
                                            defaultValue=""
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </>
                                )}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select state.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <Form.Group controlId="formGridAddress1">
                              <Form.Label>Address 1</Form.Label>
                              <Form.Control
                                name="billing_address_1"
                                onChange={this.handleChange}
                                value={this.state.billing_address_1}
                                type="text"
                                placeholder="Address 1"
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing address 1.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-6">
                            <Form.Group controlId="formGridAddress2">
                              <Form.Label>Address 2</Form.Label>
                              <Form.Control
                                name="billing_address_2"
                                onChange={this.handleChange}
                                value={this.state.billing_address_2}
                                type="text"
                                placeholder="Address 2"
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing address 2.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                        <Form.Group controlId="formAddressType">
                          <Form.Label>
                            <b>Address Type</b>
                          </Form.Label>
                          <div className="d-flex">
                            {this.state.address_array.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Form.Check
                                    type="radio"
                                    value={item}
                                    checked={this.state.address_type === item}
                                    onChange={
                                      this.handleChangeBillingAddressTypeRadio
                                    }
                                    label={item}
                                    className="mr-3"
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </Form.Group>

                        <h6>
                          <b>Contact Details</b>
                        </h6>
                        <div className="row">
                          <div className="col-6">
                            <Form.Group controlId="formGridFirstName">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                name="billing_first_name"
                                onChange={this.handleChange}
                                defaultValue={
                                  this.state.billing_first_name
                                    ? this.state.billing_first_name
                                    : this.state.customer_name2
                                }
                                type="text"
                                placeholder="Name"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing first name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-6">
                            <Form.Group controlId="formGridMobile">
                              <Form.Label>Conatct No</Form.Label>
                              <Form.Control
                                name="billing_mobile"
                                onChange={this.handleChange}
                                defaultValue={
                                  this.state.billing_mobile
                                    ? this.state.billing_mobile
                                    : this.state.customer_phone2
                                }
                                type="text"
                                placeholder="Mobile"
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing mobile number.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <Form.Group controlId="formGridCountry">
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                name="billing_country_name"
                                onChange={this.handleChange}
                                value={this.state.billing_country_name}
                                type="text"
                                placeholder="Country"
                                required
                                disabled
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing country name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </div>
              {/* Add Payment Terms div start here */}
              <div
                className="card-body"
                style={{
                  background: "rgb(122 ,  122 ,  122)",
                  color: "white",
                  marginTop: "2rem",
                }}
              >
                <b>Terms and Conditions:</b>
              </div>
               <ReactQuill
                modules={modules}
                value={this.state.paymentTerms}
                onChange={this.handleQuillChange}
                placeholder="Start Typing Here..."
                theme="snow"
              />
              {/* Add Payment Terms div ends here */}
            </div>
            <Row>
              <Col lg={5}>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#313131",
                    fontWeight: "600",
                  }}
                >
                  How confident you are of converting this Quote to Order?
                </span>
              </Col>
              <Col lg={3} style={{ display: "contents" }}>
                <Col lg={1} style={{ display: "flex", alignItems: "center" }}>
                  <Form.Check
                    type="radio"
                    value="Hot"
                    id="hot"
                    name="Hot"
                    checked={this.state.selectedOption === "Hot"}
                    onChange={this.onChangeValue}
                    required
                  />{" "}
                  <label className="mb-0" for="hot">
                    Hot
                  </label>
                </Col>
                <Col lg={1} style={{ display: "flex", alignItems: "center" }}>
                  <Form.Check
                    type="radio"
                    value="Warm"
                    name="Warm"
                    id="Warm"
                    checked={this.state.selectedOption === "Warm"}
                    onChange={this.onChangeValue}
                    required
                  />{" "}
                  <label className="mb-0" for="Warm">
                    Warm
                  </label>
                </Col>
                <Col lg={1} style={{ display: "flex", alignItems: "center" }}>
                  <Form.Check
                    type="radio"
                    value="Cold"
                    name="Hot"
                    id="Cold"
                    checked={this.state.selectedOption === "Cold"}
                    onChange={this.onChangeValue}
                    required
                  />{" "}
                  <label className="mb-0" for="Cold">
                    Cold
                  </label>
                </Col>
              </Col>
            </Row>

            {this.state.quote_status == "Pending For Approval" ? (
              <Row className="mt-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea2">
                    <Form.Label>Sales Agent Submission Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="agent_remarks"
                      defaultValue={this.state.agent_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Manager Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="manager_remarks"
                      defaultValue={this.state.manager_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled={!this.state.allowedSlugs.includes("approve")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <Row className="mt-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Manager Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="manager_remarks"
                      defaultValue={this.state.manager_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea2">
                    <Form.Label>Sales Agent Submission Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="agent_remarks"
                      defaultValue={this.state.agent_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled={
                        !this.state.showSubmitForApproval
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {!this.state.quoteDetail &&
            this.state.quote_status != "Expired" &&
            this.state.quote_status != "Pending For Approval" &&
            !this.state.showSubmitForApproval ? (
              <div className="my-3">
                {this.state.quoteID == 0 ? (
                  <div className="text-center">
                    <Button
                      id="saveOnly"
                      onClick={this.handleSubmit}
                      variant="primary"
                      type="submit"
                      disabled={this.state.is_loading ? true : false}
                      className="mr-3"
                    >
                      {this.state.is_loading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Save"
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      id="saveAndSent"
                      onClick={this.handleSubmit}
                    >
                      {this.state.is_loading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Save & Sent"
                      )}
                    </Button>
                  </div>
                ) : this.state.quote_type == 2 ? null : (
                  <div>
                    <Button
                      variant="primary"
                      type="submit"
                      id="updateOnly"
                      onClick={this.handleUpdate}
                      className="mr-3"
                    >
                      {this.state.is_loading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Update"
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      id="updateAndSent"
                      onClick={this.handleUpdate}
                    >
                      {this.state.is_loading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Update & Sent"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            ) : null}

            {/* submit for approval button component start here */}
            {this.state.showSubmitForApproval &&
            this.state.quote_status != "Pending For Approval" ? (<>
              <Button
                id="submitForApproval"
                onClick={
                  this.state.quoteID ? this.handleUpdate : this.handleSubmit
                }
                variant="primary"
                type="submit"
                disabled={this.state.is_loading ? true : false}
                className="mr-3"
              >
                {this.state.is_loading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Submit for Approval"
                )}
              </Button>
            {this.state.quoteID ? 
              <Button
                  variant="primary"
                  type="submit"
                  id="updateOnly"
                  onClick={this.handleUpdate}
                  className="mr-3"
                >
                  {this.state.is_loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save as Draft"
                  )}
              </Button>
              :
              <Button
                  variant="primary"
                  type="submit"
                  id="saveOnly"
                  onClick={this.handleSubmit}
                  className="mr-3"
                >
                  {this.state.is_loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save as Draft"
                  )}
              </Button>
            }
              </>
            ) : null}
            {/* submit for approval button component ends here */}

            {/* approve and reject button component start here */}
            {!this.state.quoteDetail &&
            this.state.quote_status != "Expired" &&
            this.state.quote_status == "Pending For Approval" &&
            this.state.allowedSlugs.includes("approve") ? (
              <div className="mt-2">
                <div>
                  <Button
                    variant="primary"
                    type="submit"
                    id="approve"
                    onClick={this.handleUpdate}
                    className="mr-3"
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Approve"
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    id="reject"
                    onClick={this.handleUpdate}
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Reject"
                    )}
                  </Button>
                </div>
              </div>
            ) : null}
            {/* approve and reject button component ends here */}
          </Form>
        </Col>
      </Row>
      </>
    );
  }
}

export default CreateQuote;
