import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Constants from "../../../Config";
import classes from "../SourcingRequest/CreateSource.module.css";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import deleteIcon from "../../../asset/Images/delete_new_icon.svg";
import downloadIcon from "../../../asset/Images/downloadIconNew.svg";
import alertMsg from "../../../asset/Images/danger_alert.svg";
import viewBtn from "../../../asset/Images/viewBtn.svg";
import ErrorHandling from "../../ErrorHandling/ShowMessage";

const apiUrl = Constants.API_URL;

export class ProductAdd2 extends Component {
  state = {
    productArray: [],
    productList: [],
    categoryArray: [],
    categoryList: [],
    product_name: "",
    product_id: "",
    pack_quantity: "",
    product_quantity: "",
    category_quantity: "",
    count: "",
    category_name: "",
    type: "",
    //
    other: "",
    attachments: "",
    other_quantity: 1,
    typeSwitch: false,

    // selectedValue: "product",
    itemQuantity: "",
    quantity_type: "",
    quantity: "",
    brand: "",

    isImageLoading: false,
    uploadedFiles: [],
    fileNames: [],
    selectedFile: null,
    attachmentStatus: false,
    selectedValue: "Catalouge",
    isOpen: false,
    textAreaValue: "",
    suggestions: [],
    categorySuggestions: [],
    imageIndex: null,
    prodName: "",
    categoryName: "",
    errorMessage: "",
    selectedIndex: null,
  };

  toggleDropdown = (index) => {
    this.setState((prevState) => ({
      isOpen: {
        ...prevState.isOpen,
        [index]: !prevState.isOpen[index],
      },
    }));
  };

  handleSelect = (i, e) => {
    this.setState(
      {
        selectedValue: e.target.value,
        typeSwitch: true,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: "",
          quantity: 0,
          type: this.state.selectedValue,
          category_id: 0,
          product_id: 0,
        });
      }
    );
  };
  handleItemClick = (index, value) => {
    this.setState(
      {
        selectedValue: value, // Set the selected value
        isOpen: false, // Close the dropdown
        product_id: "",
        product_name: "",
        textAreaValue: "",
        moq: "",
        product_price: "",
        quantity: "",
        quantity_type: "",
        brand: "",
        brand_id: "",
        count: "",
        product_quantity: "",
        category_name: "",
        category_id: "",
        type: "", // Reset category or type
        url: "",
        suggestions: [], // Clear suggestions after selection
        categorySuggestions: [],
      },
      () => {
        // Call the parent function with empty values after resetting state
        this.props.addDisplayArray(index, {
          name: "",
          quantity: "",
          type: "product",
          product_id: "",
          category_id: 0,
          // price: "",
          price: "",
          quantity_type: "",
          brand: "",
          brand_id: 0,
          url: "",
        });
        this.handleSelect(index, { target: { value } });
      }
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Fetch products dynamically from API
  getValue = (inputValue) => {
    if (!inputValue) {
      this.setState({ suggestions: [] }); // Hide suggestions if input is empty
      return;
    }
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: { keyword: inputValue, status: "2, 0" },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          suggestions: data.data.data.map((item) => ({
            label: item.name,
            value: item.name,
          })),
        });
      })
      .catch((error) => console.log(error));
  };

  handleUpdateTextAreaChange = (e, index) => {
    this.props.updateProductData({ [e.target.name]: e.target.value }, index);
    // Fetch suggestions as user types
    // this.getValue(inputValue);
  };

  // Handle user typing in textarea
  // handleTextAreaChange = (e, index) => {
  //   const inputValue = e.target.value;
  //   console.log(inputValue,"input value");
  //   let value = true;
  //   if (inputValue.length === 0) {
  //     value = false;
  //     this.props.updateProductData({ showProducts: value, name: inputValue, url: "" }, index);
  //   } else {
  //     this.props.updateProductData({ showProducts: value }, index);
  //   }
  //   // Update prodNames state for the specific index
  //   this.setState((prevState) => {
  //     const updatedProdNames = [...(prevState.prodName || [])]; // Ensure array exists
  //     updatedProdNames[index] = inputValue; // Update value for the specific index
  //     return { prodName: updatedProdNames };
  //   });
  //   this.setState({ selectedIndex: index }); 
  //   // Fetch suggestions as user types
  //   this.getValue(inputValue);
  // };

  handleTextAreaChange = (e, index) => {
    const inputValue = e.target.value;
  
    let showProducts = inputValue.length > 0; // If input is empty, hide suggestions
  
    // Update the product data
    this.props.updateProductData(
      {
        showProducts: showProducts,
        name: inputValue, 
        url: "", // Preserve existing URL if input is cleared
      },
      index
    );
  
    // Update prodName state for the specific index
    this.setState((prevState) => {
      const updatedProdNames = [...(prevState.prodName || [])]; // Ensure array exists
      updatedProdNames[index] = inputValue; // Update value for the specific index
      return { prodName: updatedProdNames };
    });
  
    // Set active dropdown index when typing
    this.setState({ selectedIndex: index });
  
    // Fetch suggestions as user types
    this.getValue(inputValue);
  };
  

  // Handle clicking on a suggestion
  handleSuggestionClick = (i, suggestion) => {
    let selectedProduct = this.state.productList.find(
      (el) => el.name === suggestion.value
    );
    if (selectedProduct) {
      this.setState(
        (prevState) => ({
          product_id: selectedProduct.product_id,
          name: selectedProduct.name, // 🔥 Clear the input after selection
          moq: selectedProduct.moq,
          // product_price: Number(selectedProduct.price).toFixed(2),
          product_price: `₹${Number(selectedProduct.price).toFixed(2)}`,
          quantity: selectedProduct.pack_quantity,
          quantity_type: selectedProduct.qty_type,
          brand: selectedProduct.brand,
          brand_id: selectedProduct.brand_id,
          count: selectedProduct.moq,
          product_quantity: selectedProduct.pack_quantity,
          url: selectedProduct.url,
          prodName: "",
        }),
        () => {
          this.props.addDisplayArray(i, {
            name: selectedProduct.name,
            quantity: 1,
            type: "product",
            product_id: selectedProduct.product_id,
            category_id: 0,
            // price: Number(selectedProduct.price).toFixed(2),
            price: `₹${Number(selectedProduct.price).toFixed(2)}`,
            quantity_type: selectedProduct.qty_type,
            brand: selectedProduct.brand,
            brand_id: selectedProduct.brand_id || 0,
            url: selectedProduct.url,
            showProducts: false,
            prodName: "",
          });
        }
      );
    }
    this.setState((prevState) => ({
      product_name: suggestion.label,
      suggestions: [],
    }));
  };

  getCategory = (e) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: {
        keyword: e,
      },
    })
      .then((data) => {
        this.setState({
          categoryList: data.data.data,
          categoryArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
        const suggestions = data.data.data.map((i) => ({
          label: `${i.name}`,
          value: `${i.name}`,
        }));

        this.setState({ categorySuggestions: suggestions }); // ✅ Update state with suggestions
      })
      .catch((error) => console.log(error));
  };

  handleCategoryClick = (index, suggestion) => {
    let categ = this.state.categoryList.find(
      (el) => el.name === suggestion.value
    );
    if (!categ) return;
    this.setState(
      {
        category_name: categ.name, // Show only selected value
        category_id: categ.category_id,
        type: "category",
        categorySuggestions: [], // Clear suggestions after selection
        categoryUrl: categ.url,
        categoryName: "",
      },
      () => {
        this.props.addDisplayArray(index, {
          name: categ.name,
          quantity: 1,
          type: "category",
          category_id: categ.category_id,
          product_id: 0,
          quantity_type: categ.quantity_type || "",
          brand: categ.brand || "",
          brand_id: 0,
          categoryUrl: categ.url,
          showProducts: false,
          categoryName: "",
        });
      }
    );
  };

  // handleTextAreaCategory = (e, index) => {
  //   const inputValue = e.target.value;
  
  //   // Update categoryNames array instead of a single value
  //   this.setState((prevState) => {
  //     const updatedCategoryNames = [...prevState.categoryName];
  //     updatedCategoryNames[index] = inputValue; 
  
  //     return { categoryName: updatedCategoryNames };
  //   });
  
  //   this.setState({ selectedIndex: index }); 

  //   let value = inputValue.length !== 0;
    
  //   this.props.updateProductData(
  //     { showProducts: value, name: value ? inputValue : "", categoryUrl: "" },
  //     index
  //   );
    
  //   // Fetch suggestions based on input
  //   this.getCategory(inputValue);
  // };
  handleTextAreaCategory = (e, index) => {
    const inputValue = e.target.value;
  
    // Ensure categoryName array exists before updating
    this.setState((prevState) => {
      const updatedCategoryNames = prevState.categoryName ? [...prevState.categoryName] : []; // Ensure it's an array
      updatedCategoryNames[index] = inputValue; // Update the specific index
  
      return { categoryName: updatedCategoryNames };
    });
  
    // Set active dropdown index when typing
    this.setState({ selectedIndex: index });
  
    let value = inputValue.length !== 0;
  
    this.props.updateProductData(
      { showProducts: value, name: value ? inputValue : "", categoryUrl: "" },
      index
    );
  
    // Fetch suggestions based on input
    this.getCategory(inputValue);
  };
  
  handleTextAreaOther = (e, index) => {
    const inputValue = e.target.value;
    this.props.updateProductData({ name: inputValue }, index);
    // Fetch suggestions as user types
    // this.getCategory(inputValue);
  };

  getOtherName = (i) => {
    this.props.addDisplayArray(i, {
      name: this.state.other,
      quantity: 0,
      type: "other",
      category_id: 0,
      product_id: 0,
      quantity_type: "",
      brand: 0,
      brand_id: 0,
    });
  };

  validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };

  getFileType = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return "pdf";
      case "jpeg":
      case "jpg":
      case "png":
        return "image";
      case "doc":
      case "docx":
        return "doc";
      case "xls":
        return "xls";
      default:
        return "unknown";
    }
  };

  onFileChanges = async (i, event) => {
    const file = event.target.files[0];
    const allowedImage = this.validateFile(file);
    if (!allowedImage) {
      this.setState({ isImageLoading: false, imageIndex: null });
      return;
    }
    const fileType = this.getFileType(file.name);
    let formData = new FormData();
    formData.append("file", file);
    this.setState({ isImageLoading: true, imageIndex: i });
    axios
      .post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails?.token}`,
        },
      })
      .then((res) => {
        const imageUrl = res.data.data.image_url;
        this.setState((prevState) => ({
          fileNames: [...prevState.fileNames, file.name],
          selectedFile: imageUrl,
          isImageLoading: false,
          imageIndex: null,
          attachmentStatus: true,
        }));
        this.props.addDisplayArray(i, {
          name: this.state.selectedFile,
          type: "attachments",
          category_id: 0,
          product_id: 0,
          quantity: "",
          quantity_type: "",
          brand: "",
          brand_id: 0,
        });
      });
  };

  render() {
    return (
      <>
        {this.state.errorMessage ? (
          <ErrorHandling
            message={this.state.errorMessage}
            type={"ErrorMessage"}
          />
        ) : null}
        {/* new code start here */}
        <section className="requirementDtlCnt">
          <div className="container-40">
            {this.props.itemDisplayArray ? (
              <h2 className="pageSubHeading">Requirement Details</h2>
            ) : null}
            <div className="requirementDtlTableCnt">
              {this.props.itemDisplayArray ? (
                <table className="requirementTable">
                  <thead className="tableHead">
                    <tr>
                      <th style={{ width: "20%" }}>Type</th>
                      <th style={{ width: "33%" }}>Name</th>
                      <th style={{ width: "10%" }}>Brand</th>
                      <th style={{ width: "10%" }}>Quantity</th>
                      <th style={{ width: "10%" }}>Unit</th>
                      <th style={{ width: "10%" }}>Price</th>
                      <th style={{ width: "7%" }}>&nbsp;</th>
                    </tr>
                  </thead>
                  {this.props.itemDisplayArray.map((item, index) => (
                    <tbody className="tableBody">
                      <tr key={index}>
                        <td className="enabled_filed_wrapper">
                          <div className="customeSelectCnt tableSelectOption">
                            <button
                              className="customSelectBtn"
                              onClick={() => this.toggleDropdown(index)}
                              onBlur={() => this.setState({ isOpen: false })}
                            >
                              <label htmlFor="name">
                                {item.type === "product"
                                  ? "Catalogue"
                                  : item.type === "category"
                                  ? "Category"
                                  : item.type === "other"
                                  ? "Other"
                                  : item.type === "attachments"
                                  ? "Attachment"
                                  : item.type}
                              </label>
                              <div className="customSelectBtnIcon downArrowIcon">
                                <img
                                  src={downArrowGrey}
                                  width="12px"
                                  height="12px"
                                />
                              </div>
                            </button>

                            {/* Dropdown List using <ul> and <li> */}
                            {this.state.isOpen[index] && ( // Check if the specific index is open
                              <div className="customDropDownCnt show">
                                <ul
                                  className="dropdownWrapper"
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {[
                                    { label: "Catalogue", value: "product" },
                                    { label: "Category", value: "category" },
                                    { label: "Other", value: "other" },
                                    {
                                      label: "Attachment",
                                      value: "attachments",
                                    },
                                  ].map((option, idx) => (
                                    <li
                                      key={option.value}
                                      className="dropdownItem"
                                      onClick={() =>
                                        this.handleItemClick(
                                          index,
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                        {item.type == "product" ? (
                          <td
                            className="enabled_filed_wrapper"
                            key={index}
                            style={{ width: "40%" }}
                          >
                            <div className="tableSelectOption">
                              <div className="textEditableInputCnt">
                                <textarea
                                  rows={1}
                                  className="textEditableInput"
                                  placeholder=""
                                  // value={item.name || this.state.prodName[index]}

                                  value={
                                     item.name
                                  }                                  
                                  onChange={(e) =>
                                    this.handleTextAreaChange(e, index)
                                  }
                                  // onFocus={() => setActiveDropdownIndex(index)}
                                  onFocus={() => this.setState({selectedIndex: index})}
                                />
                                {item.url ? (
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    className="view_Btn"
                                  >
                                    <img
                                      src={viewBtn}
                                      width="16px"
                                      height="16px"
                                    />
                                    <span className="viewCnt">View</span>
                                  </a>
                                ) : null}
                                {/* Show dynamic suggestions below textarea */}
                                {this.state.suggestions.length > 0 &&
                                  item.showProducts &&
                                  this.state.selectedIndex === index &&
                                  (
                                    <div
                                      className="customDropDownCnt show"
                                      key={index}
                                    >
                                      <ul className="dropdownWrapper">
                                        {this.state.suggestions.map(
                                          (suggestion, idx) => (
                                            <li
                                              key={idx}
                                              className="dropdownItem"
                                              onClick={() =>
                                                this.handleSuggestionClick(
                                                  index,
                                                  suggestion
                                                )
                                              }
                                            >
                                              {suggestion.label}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {item.type == "category" ? (
                          <td
                            className="enabled_filed_wrapper"
                            key={index}
                            style={{ width: "40%" }}
                          >
                            <div className="tableSelectOption">
                              <div className="textEditableInputCnt">
                                {/* Textarea to display the selected category */}
                                <textarea
                                  // rows={3}
                                  className="textEditableInput"
                                  placeholder=""
                                  // value={item.name || this.state.categoryName[index]  }
                                  value={item.name}
                                  onChange={(e) =>
                                    this.handleTextAreaCategory(e, index)
                                  }
                                  onFocus={() => this.setState({selectedIndex: index})}
                                  // readOnly // Prevent typing
                                />
                              </div>
                              {item.categoryUrl ? (
                                <a
                                  href={item.categoryUrl}
                                  target="_blank"
                                  className="view_Btn"
                                >
                                  <img
                                    src={viewBtn}
                                    width="16px"
                                    height="16px"
                                  />
                                  <span className="viewCnt">View</span>
                                </a>
                              ) : null}
                              {/* Suggestion dropdown */}
                              {this.state.categorySuggestions.length > 0 &&
                                item.showProducts && 
                                this.state.selectedIndex === index &&
                                (
                                  <div className="customDropDownCnt show">
                                    <ul className="dropdownWrapper">
                                      {this.state.categorySuggestions.map(
                                        (suggestion, idx) => (
                                          <li
                                            key={suggestion.value}
                                            className="dropdownItem"
                                            onClick={() =>
                                              this.handleCategoryClick(
                                                index,
                                                suggestion
                                              )
                                            }
                                          >
                                            {suggestion.label}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </td>
                        ) : null}

                        {item.type == "other" ? (
                          <>
                            <td
                              className="enabled_filed_wrapper"
                              style={{ width: "40%" }}
                            >
                              <div className="tableSelectOption">
                                <div className="textEditableInputCnt">
                                  <textarea
                                    // rows={2}
                                    className="textEditableInput"
                                    type="text"
                                    // name="other"
                                    placeholder="Search a product"
                                    value={item.name}
                                    onChange={(e) =>
                                      this.handleTextAreaOther(e, index)
                                    }
                                    // onBlur={() => this.getOtherName(index)}
                                  ></textarea>
                                </div>
                              </div>
                            </td>
                          </>
                        ) : null}
                        {item.type == "attachments" ? (
                          <>
                            {item.name ? (
                              <td>
                                <div className="tableSelectOption">
                                  <a
                                    href={item.name}
                                    target="_blank"
                                    className="attachmenttext"
                                  >
                                    Attachment
                                  </a>
                                </div>
                              </td>
                            ) : (
                              <>
                                {this.state.isImageLoading &&
                                this.state.imageIndex === index ? (
                                  <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                ) : (
                                  <td
                                    className="enabled_filed_wrapper"
                                    style={{ width: "40%" }}
                                  >
                                    <div className="tableSelectOption">
                                      <div className="uploadFileCnt">
                                        <label
                                          for="downloadIcon"
                                          className="upload_title"
                                        >
                                          <img
                                            src={downloadIcon}
                                            width="16px"
                                            height="16px"
                                          />
                                          <span>Upload a file</span>
                                        </label>
                                        <input
                                          id="downloadIcon"
                                          className="downloadFile"
                                          style={{ display: "none" }}
                                          type="file"
                                          name="attachments"
                                          accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                          onChange={(e) =>
                                            this.onFileChanges(index, e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                        <td
                          className={`${
                            item.type === "attachments"
                              ? "disbled_filed_wrapper"
                              : "enabled_filed_wrapper"
                          } ${
                            item.type === "product"
                              ? "disbled_filed_wrapper"
                              : ""
                          }`}
                        >
                          <div className="tableSelectOption">
                            {item.type === "attachments" ? null : (
                              <>
                                <label></label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="brand"
                                  value={item.brand}
                                  onChange={(e) =>
                                    this.handleUpdateTextAreaChange(
                                      e,
                                      index
                                      // item.type
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                  // readOnly="readonly"
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${
                            item.type === "attachments"
                              ? "disbled_filed_wrapper"
                              : "enabled_filed_wrapper"
                          }`}
                        >
                          <div className="tableSelectOption">
                            {item.type === "attachments" ? null : (
                              <>
                                <label></label>
                                <input
                                  type="number"
                                  className=""
                                  placeholder=""
                                  // min={1}
                                  name="quantity"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    this.handleUpdateTextAreaChange(
                                      e,
                                      index
                                      // item.type
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${
                            item.type === "attachments" ||
                            item.type == "product"
                              ? "disbled_filed_wrapper"
                              : "enabled_filed_wrapper"
                          }`}
                        >
                          <div className="tableSelectOption">
                            {item.type === "attachments" ? null : (
                              <>
                                <label></label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={item.quantity_type}
                                  onChange={(e) =>
                                    this.handleUpdateTextAreaChange(
                                      e,
                                      index
                                      // item.type
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${
                            item.type === "attachments" ||
                            item.type == "product"
                              ? "disbled_filed_wrapper"
                              : "enabled_filed_wrapper"
                          } ${
                            item.type === "product" ||
                            item.type === "category" ||
                            item.type === "other"
                              ? "disbled_filed_wrapper"
                              : "enabled_filed_wrapper"
                          }`}
                        >
                          <div className="tableSelectOption">
                            {item.type === "attachments" ? null : (
                              <>
                                <label></label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="price"
                                  value={item.price}
                                  onChange={this.handleChange}
                                  disabled={`${
                                    item.type == "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          {this.props.itemDisplayArray.length === 1 ? null : (
                            <div className="tableSelectOption deleteIconBtn">
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                                alt="deleteIcon"
                                id={index}
                                onClick={() => this.props.removeItem(index)}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : null}

              <button
                onClick={() => {
                  const { itemDisplayArray, getItemDetails } = this.props;
                  let errorMessage = ""; // Initialize error message
                  const isValid = itemDisplayArray.every((item) => {
                    switch (item.type) {
                      case "attachments":
                        if (!item.name?.trim()) {
                          errorMessage = "Attachment name is required.";
                          return false;
                        }
                        return true;
                      case "product":
                        if (!item.name?.trim()) {
                          errorMessage = "Name is required.";
                          return false;
                        }
                        if (!item.quantity) {
                          errorMessage = "Quantity is required.";
                          return false;
                        }
                        if ((Number(item.quantity)) <= 0) {
                          errorMessage = "Quantity must be greater than 0.";
                          return false;
                        }
                        return true;
                      case "category":
                      case "other":
                        if (!item.name?.trim()) {
                          errorMessage = "Name is required.";
                          return false;
                        }
                        if (!item.brand?.trim()) {
                          errorMessage = "Brand is required.";
                          return false;
                        }
                        if (!item.quantity) {
                          errorMessage = "Quantity is required.";
                          return false;
                        }
                        if ((Number(item.quantity)) <= 0) {
                          errorMessage = "Quantity must be greater than 0.";
                          return false;
                        }
                        if (!item.quantity_type) {
                          errorMessage = "Unit type is required.";
                          return false;
                        }
                        return true;
                      default:
                        errorMessage = "Invalid item type.";
                        return false;
                    }
                  });

                  if (isValid) {
                    getItemDetails();
                  } else {
                    this.setState({ errorMessage }, () => {
                      setTimeout(() => {
                        this.setState({ errorMessage: "" });
                      }, 3000);
                    });
                  }
                }}
                type="button"
                className="addItemsBtn"
              >
                Add item
              </button>
            </div>
          </div>
        </section>
        {/* new code ends here */}
      </>
    );
  }
}

export default ProductAdd2;
