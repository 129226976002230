import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Table,
  Modal,
  Button,
  Alert,
  Form,
  Spinner,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  InputGroup,
  // Pagination,
} from "react-bootstrap";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import axios from "axios";
import Constants from "../../../Config";
import Select, { components } from "react-select";
import "./listQuote.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
// import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Daterangepicker from "../Common/Daterangepicker";
// import Workbook from 'react-excel-workbook'
import { CSVLink, CSVDownload } from "react-csv";
import ClosedLostReason from "./ClosedLostReason";
import TicketRaiseListQuote from "./TicketRaiseListQuote";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DateRangeNew from "../../DashBoardWidgets/NewWidgets.js/DateRangeNew";
import { useState } from "react";
// import { env } from "yargs";

//import { touchRippleClasses } from "@mui/material";

const { SearchBar } = Search;
const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} /> {children}
    </components.Option>
  );
};

const ratingOptions = [
  { label: "Hot", value: "Hot" },
  { label: "Warm", value: "Warm" },
  { label: "Cold", value: "Cold" },
];

const columns = [
  {
    dataField: "quote_id",
    text: "Quote ID",
  },
  {
    dataField: "quoteDetail",
    text: "Quote Type",
  },
  {
    dataField: "company_name",
    text: "Account name",
    sort: true,
  },
  {
    dataField: "customer_detail",
    text: "Customer Details",
  },
  {
    dataField: "total",
    text: "Total",
  },
  {
    dataField: "agentName",
    text: "Agent Name",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "rating",
    text: "Rating",
  },
  {
    dataField: "created_at",
    text: "Date",
  },
  {
    dataField: "valid_upto",
    text: "Expired date",
  },
  {
    dataField: "action",
    text: "Action",
  },
];
const QuoteProductListheaders = [
  { label: "Quote ID", key: "quote_id" },
  { label: "Reference RFQ ID", key: "rfq_id" },
  { label: "LEAD REFERENCE ID", key: "lead_id" },
  { label: "Quote Date", key: "quote_date" },
  { label: "Valid Till Date", key: "valid_tilldate" },
  { label: "Quote Owner", key: "agentName" },
  { label: "Account Name", key: "account_name" },
  { label: "Customer Name", key: "customer_name" },
  { label: "mobile", key: "mobile" },
  { label: "Product Name", key: "product_name" },
  { label: "MRP", key: "mrp" },
  { label: "System TP", key: "tp" },
  { label: "Sourcing TP", key: "sourcing_tp" },
  { label: "Markup % ", key: "markup" },
  { label: "List Price per SKU", key: "list_price_per_sku" },
  { label: "List Price (ORG)", key: "list_price_org" },
  { label:"Net Amount (ORG)", key: "net_amount_org" },
  { label:"Quote Net Amount", key: "net_amount" },
  { label: "Qty", key: "qty" },
  { label: "Tax %", key: "tax" },
  { label: "Tax amount", key: "tax_amount" },
  { label: "Gross margin %", key: "total_gross_margin_per" },
  { label: "Product Category", key: "product_category" },
  { label: "Product Brand", key: "productbrand" },
  { label: "Status", key: "status" },
  { label: "Order Id", key: "order_id" },
  { label: "Reason (lost case)", key: "reasonlostcase" },
  { label: "Reason(Remarks)", key: "reason" },
];

const quoteListheaders = [
  { label: "Quote ID", key: "quote_id" },
  { label: "Reference RFQ ID", key: "rfq_id" },
  { label: "LEAD REFERENCE ID", key: "lead_id" },
  { label: "Quote Date", key: "quote_date" },
  { label: "Quote Owner", key: "agentName" },
  { label: "Quote Subject", key: "quote_subject" },
  { label: "Customer Group", key: "customer_group" },
  { label: "Customer Name", key: "customer_name" },
  { label: "Account Name", key: "account_name" },
  { label: "email", key: "email" },
  { label: "mobile", key: "mobile" },
  { label: "Grand Total", key: "grand_total" },
  { label: "Total Gross margin", key: "total_gross_margin" },
  { label: "Total Gross margin %", key: "total_gross_margin_per" },
  { label: "Billing Address", key: "billing_address" },
  { label: "Shipping Address", key: "shipping_address" },
  { label: "City", key: "city" },
  { label: "Rating Name", key: "label_name" },
  { label: "Rating Percentage", key: "label_perc" },
  { label: "Status", key: "status" },
  { label: "Order Id", key: "order_id" },
  { label: "Reason (lost case)", key: "reasonlostcase" },
  { label: "Reason(Remarks)", key: "reason" },
  { label: "Valid Till Date", key: "valid_tilldate" },
];

class ListQuote extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props, "data");
    this.csvLinkEl = React.createRef();

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  state = {
    QuoteList: [],
    show: false,
    showStatusPopup: false,
    message: "",
    QuoteData: [],
    CompanyData: "",
    quote_status: "",
    quote_id: 0,
    Products: [],
    isLoading: false,
    selectedStatus: [],
    isDownloading: false,
    date_from: "",
    date_to: "",
    QuoteListDownload: [],
    total_item: 25,
    current_page: 1,
    option: "",
    showModal: false,
    logined_user_id: [],
    all_quote_users: [],
    agent_id: "",
    showPopup: false,
    remarks: "",
    showTicket: false,
    total_record: 0,
    isPaginationLoading: 0,
    label_name: "",
    // QuoteData: [],
    openMagic: true,
    productsArr: [],
    dateType: "",
    quoteStatusArray: [],
    url: "",
    mobile: "",
    company_name: "",
    rating: [],
    quoteType: "",
    allowedSlugs: [],
    agentOptions: [],
    poStatusArray: [],
    poStatus: ""
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    this.getAllQuote();
    this.getAllQuoteUsers();
    this.getAllQuoteStatus();
    this.getAllPOStatus();
    this.setState({
      allowedSlugs: this.props.childSlugProp["listQuote"],
    });
  }
  getAllQuoteStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: { meta_id: 25 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let quotestatus = data.data.data;
        let quoteStatusArray = quotestatus.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ quoteStatusArray });
      })
      .catch((error) => console.log(error));
  };

  getTotalRecords = () => {
    this.setState(
      {
        isDownloading: true,
      },
      () => {
        // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails?.token}`,
          },

          data: {
            quote_type: this.props.quote_type,
            selectedStatus: this.state.selectedStatus.length
              ? this.state.selectedStatus
              : [],
            logined_user_id: this.state.logined_user_id.length
              ? this.state.logined_user_id
              : [],
            selectedType: this.state.selectedType,
            //selectedUserID:this.state.selectedUserID,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            key: "quote",
            type: this.state.dateType,
            phone: this.state.mobile,
            rating: this.state.rating,
            quoteType: this.state.quoteType,
            company: this.state.company_name,
            po_status: this.state.poStatus
          },
        };
        return axios(apiUrl + "/get-quote-count", requestoption)
          .then((data) => {
            this.setState({
              total_record: data.data.data,
              isDownloading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isDownloading: false,
            });
          });
      }
    );
  };

  updateQuoteStatus = (quote_status, quote_id, ratingStataus) => {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
      data: {
        quote_id: quote_id,
        status: quote_status,
        remarks: this.state.remarks,
        label_name: ratingStataus ? ratingStataus : this.state.label_name,
      },
    };
    return axios(apiUrl + "/update-quotes-status", params)
      .then((data) => {
        this.getAllQuote();
      })
      .catch((error) => console.log(error));
  };
  openViewProductsPopup = (quoteID) => {
    this.getQuoteDetailsByID(quoteID);
    this.setState({
      show: true,
    });
  };
  sendQuoteLink = (quoteID) => {
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: quoteID,
      },
    };
    return axios(apiUrl + "/send-quotes-link", params)
      .then((data) => {
        this.setState({
          message: data.data.message,
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
        }, 5000);
      })
      .catch((error) => console.log(error));
  };
  getQuoteDetailsByID = (quoteID) => {
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: quoteID,
      },
    };
    return axios(apiUrl + "/get-quotes", params)
      .then((data) => {
        this.setState({
          QuoteData: data.data.data[0],
          CompanyData: data.data.data[0].companyData,
          Products: data.data.data[0].products,
        });
      })
      .catch((error) => console.log(error));
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };
  handleChange = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        option: e.target.value,
      },
      () => {
        this.downloadReport(this.state.option);
      }
    );
  };

  getAllQuoteUsers = () => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },

      data: {
        agent_list: "",
        id: [56],
        showAll: "",
        slug: "listQuote",

        listType: "list",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState(
          {
            all_quote_users: data.data.data,
          },
          () => {
            let agentOptions1 = data.data.data;
            let agentOptions = agentOptions1.map((item) => {
              if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
                item["label"] = item["name"];
                item["value"] = item["id"];
                delete item["name"];
                delete item["id"];
                return item;
              }
            });

            this.setState({ agentOptions });
          }
        );
      })
      .catch((error) => console.log(error));
  };

  filterByAssignTo = (e) => {
    this.setState(
      {
        // agent_id: e.target.value,
        logined_user_id: e,
        current_page: 1,
        QuoteListDownload: [],
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  filterByRating = (e) => {
    this.setState(
      {
        rating: e,
        current_page: 1,
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  companyFilter = (e) => {
    this.setState(
      {
        company_name: e.target.value,
        current_page: 1,
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
        // this.businessAccountsListForDownload()
      }
    );
  };

  getPaginationValue = (currentPage, displayItems) => {
    this.setState(
      {
        current_page: currentPage,
        total_item: displayItems,
      },
      () => {
        this.getAllQuote();
      }
    );
  };
  setPOState = (POID, QUOTEID) => {
    const state = {
      poID: POID,
      quoteId: QUOTEID,
      timestamp: new Date().getTime(),
      // quote_type: item.quote_type,
    };

    localStorage.setItem("poState", JSON.stringify(state));
  };
  setItem = (QUOTEID, customerid, productsArr) => {
    var data = {
      quoteID: QUOTEID,
      productsArr: productsArr,
      customer_id: customerid,
    };
    localStorage.setItem("dataKey", JSON.stringify(data));
  };
  setItemPO = (QUOTEID, customerid, productsArr) => {
    var data = {
      quoteID: QUOTEID,
      productsArray: productsArr,
      customer_id: customerid,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem("PO_Details", JSON.stringify(data));
  };
  getAllQuote = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

        const token = loginedUserDetails.token;
        const requestoption = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            selectedStatus: this.state.selectedStatus.length
              ? this.state.selectedStatus
              : [],
            quoteID: 0,
            total_item: this.state.total_item,
            current_page: this.state.current_page,
            logined_user_id: this.state.logined_user_id.length
              ? this.state.logined_user_id
              : [],
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            type: this.state.dateType,
            phone: this.state.mobile,
            rating: this.state.rating,
            quoteType: this.state.quoteType,
            company: this.state.company_name,
            po_status: this.state.poStatus
          },
        };
        return axios(apiUrl + "/get-quotes", requestoption)
          .then((data) => {
            this.setState(
              {
                QuoteList: data.data.data,
                isLoading: false,
              },
              () => {
                const dataNew = this.state.QuoteList.map((item, index) => {
                  const validUpto = moment(item.valid_upto);
                  const d = validUpto._d;
                  const valid_upto = d.toString().slice(4, 15);

                  return {
                    // sno: index + 1,
                    quote_id: item.quote_id,
                    company_name: item.companyData.company_name,
                    quoteDetail: (
                      <div>
                        {item.quote_type == 2 ? "Magic" : "Normal"}
                        {item.parent_quote_id ? (
                          item.parent_quote_type == "Normal" ? (
                            <b>
                              <p style={{ color: "#007bff" }}>
                                Duplicate : {item.parent_quote_id}
                              </p>
                            </b>
                          ) : item.parent_quote_type == "Magic" ? (
                            <b>
                              <p style={{ color: "#ffc400" }}>
                                Duplicate MQ : {item.parent_quote_id}
                              </p>
                            </b>
                          ) : null
                        ) : null}
                      </div>
                    ),
                    agentName: item.agentName,
                    valid_upto: valid_upto,
                    allowDuplicate: item.allowDuplicate,
                    // customer_id: item.customer_id,
                    customer_detail: (
                      <p>
                        Name:
                        {item.companyData.name}
                        {/* {item.companyData.firstname} */}
                        {/* {" "} {item.companyData.lastname} */}
                        <br />
                        Email:
                        {item.companyData.email}
                        {/* {item.companyData.email} */}
                        <br /> Mobile:
                        {item.companyData.telephone}
                        {/* {item.companyData.telephone} */}
                      </p>
                    ),
                    total: (
                      <div>
                        <p>{this.getSubTotal(item)}</p>
                        <p>{this.getShippingRate(item)}</p>
                        <p>{this.getGrandTotal(item)}</p>
                      </div>
                    ),
                    status: (
                      <div>
                        <p
                          style={{
                            backgroundColor: `${this.displayStatus(
                              item.quote_status
                            )}`,
                            padding: "2px",
                            textAlign: "center",
                            borderRadius: "0.5rem",
                          }}
                        >
                          <b>{item.quote_status}</b>
                        </p>
                        {/* <Dropdown>
                          <Button
                            className="updateStatusButton"
                            variant={
                              "outline-" + this.displayStatus(item.quote_status)
                            }
                            id={"dropDown" + item.quote_id}
                            key={item.quote_id}
                          >
                            {item.quote_status}
                          </Button>
                          
                        </Dropdown> */}
                        <p>
                          {(() => {
                            if (item.order_id) {
                              if (item.missingOrderCounts) {
                                return (
                                  <>
                                    <DropdownButton
                                      title={`${item.missingOrderCounts} Missing Order`}
                                      // size="sm"
                                      variant="link"
                                    >
                                      {item.missingOrders &&
                                        item.missingOrders.map((i) => {
                                          return (
                                            <DropdownItem>{i}</DropdownItem>
                                          );
                                        })}
                                    </DropdownButton>
                                  </>
                                );
                              } else {
                                return (
                                  <span>
                                    Order ID: ({item.order_id}) <br />
                                    Status: {item.order_status} <br />
                                    {/* <Button onClick={this.openMissingOrderArray}></Button> */}
                                  </span>
                                );
                              }
                            }
                            if (item.missingOrderCounts) {
                              return (
                                <>
                                  <DropdownButton
                                    title={`${item.missingOrderCounts} Missing Order`}
                                    // size="sm"
                                    variant="link"
                                  >
                                    {item.missingOrders &&
                                      item.missingOrders.map((i) => {
                                        return <DropdownItem>{i}</DropdownItem>;
                                      })}
                                  </DropdownButton>
                                </>
                              );
                            }
                          })()}
                        </p>
                      </div>
                    ),
                    rating: (
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="updateStatusButton"
                            variant={
                              "outline-" +
                              this.displayStatusRating(item.label_name)
                            }
                            id={"dropDown" + item.quote_id}
                            key={item.quote_id}
                          >
                            {item.label_name}
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e) =>
                                  this.changeRating(e, item.quote_id)
                                }
                              >
                                Hot
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) =>
                                  this.changeRating(e, item.quote_id)
                                }
                              >
                                Warm
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) =>
                                  this.changeRating(e, item.quote_id)
                                }
                              >
                                Cold
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown.Toggle>
                        </Dropdown>
                        <p></p>
                      </div>
                    ),
                    created_at: (
                      <p>
                        {item.created_at} <br />
                        {item.created_at_time}
                      </p>
                    ),

                    action: (
                      <div className="d-flex flex-column">
                        <div>
                          {(() => {
                            if (
                              !["Closed Won", "Closed Lost", "Direct Order","PO created"].includes(
                                item.quote_status
                              )
                            ) {
                              return this.state.allowedSlugs.includes(
                                "edit"
                              ) ? (
                                <Link
                                  to={{
                                    pathname: "/editQuote",
                                    state: {
                                      quoteID: item.quote_id,
                                      quote_type: item.quote_type,
                                    },
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="editIcon"
                                    icon="edit"
                                  />
                                </Link>
                              ) : null;
                            }
                          })()}
                          {!["Closed Won", "Closed Lost", "Direct Order", "PO created"].includes(
                            item.quote_status
                          ) ? (
                            <FontAwesomeIcon
                              className="editIcon"
                              icon="envelope"
                              onClick={() => this.sendQuoteLink(item.quote_id)}
                            />
                          ) : null}
                          <FontAwesomeIcon
                            className="editIcon"
                            icon="eye"
                            onClick={() =>
                              this.openViewProductsPopup(item.quote_id)
                            }
                          />
                          {!["Closed Won", "Closed Lost", "Direct Order", "PO created"].includes(
                            item.quote_status
                          ) ? (
                            <>
                              {
                                [
                                  "Draft", "Pending For Approval", "Rejected"
                                ].includes(item.quote_status)
                                  ? null : item.quote_type == 1 ? (
                                    <a
                                      target="_blank"
                                      // href={ item.utm_medium ? `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}?utm_source=${item.utm_source}&utm_medium=${item.utm_medium}`: item.utm_source ? `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}?utm_source=${item.utm_source}`  : `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}` }
                                      // href={ process.env.NODE_ENV === 'development'? `https://uat.mccoymart.com/buy/view-quote/${item.quote_unique_id}` : `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}` }
                                      href={`https://mccoymart.com/buy/view-quote/${item.quote_unique_id}`}
                                    >
                                      <FontAwesomeIcon
                                        className="editIcon"
                                        icon="external-link-alt"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      // href={ item.utm_medium ? `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}?utm_source=${item.utm_source}&utm_medium=${item.utm_medium}`: item.utm_source ? `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}?utm_source=${item.utm_source}`  : `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}` }
                                      // href={process.env.NODE_ENV === 'development' ? `https://uat.mccoymart.com/buy/magic-quote/${item.quote_unique_id}` : `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}` }
                                      href={`https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}`}
                                    >
                                      <FontAwesomeIcon
                                        className="editIcon"
                                        icon="external-link-alt"
                                      />
                                    </a>
                                  )}
                            </>
                          ) : null}
                        </div>
                        {item.allowDuplicate ? (
                          <div>
                            <Button
                              className="mx-auto mt-3 btn-sm"
                              variant="primary"
                              type="submit"
                              id="updateAndSent"
                            // onClick={(e) => this.duplicateQuote(e, item.quote_id)}
                            >
                              <Link
                                to="/duplicateQuote"
                                onClick={() =>
                                  this.setItem(
                                    item.quote_id,
                                    item.customer_id,
                                    item.products
                                  )
                                }
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                                target="_blank"
                              >
                                Duplicate
                              </Link>
                            </Button>
                          </div>
                        ) : null}
                        {![
                          "Closed Won",
                          "Closed Lost",
                          "Expired",
                          "Draft", 
                          "Direct Order", 
                          "Pending For Approval", 
                          "Rejected",
                          "PO created"
                        ].includes(item.quote_status) && !["B2C", "b2c"].includes(item.profile_type) && item.quote_type == 1 ? (
                          <div>
                            <Button
                              variant="dark"
                              className="mx-auto mt-3 btn-sm"
                            >
                              <Link
                                to="/createPO"
                                onClick={() =>
                                  this.setItemPO(
                                    item.quote_id,
                                    item.customer_id,
                                    item.products
                                  )
                                }
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                                target="_blank"
                              >
                                Create PO
                              </Link>
                            </Button>
                          </div>
                        ) : null}
                        {item.po_id ? (
                          <a
                            href="/editPO"
                            target="_blank"
                            onClick={() =>
                              this.setPOState(item.po_id, item.quote_id)
                            }
                          ><b>{`View PO(${item.po_id})`}</b></a>
                        ) : null}
                      </div>
                    ),
                  };
                });
                this.setState(
                  {
                    QuoteList: dataNew,
                    isPaginationLoading: 0,
                  },
                  () => {
                    this.getTotalRecords();
                  }
                );
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  displayStatus = (status) => {
    this.setState({
      quote_status: status,
    });
    if (status == "Draft") {
      return "#DC4C64";
    } else if (status == "Shared") {
      return "#ffc107";
    } else if (status == "On Hold") {
      return "#DC4C64";
    } else if (status == "Negotiation") {
      return "#DC4C64";
    } else if (status == "Closed Won") {
      return "#28a745";
    } else if (status == "Direct Order") {
      return "#99f1ad";
    } else if (status == "Closed Lost") {
      return "ClosedLost";
    } else if (status == "Expired") {
      return "#9FA6B2";
    } else if (status == "Pending For Approval") {
      return "#FF8C00"
    } else if (status == "Rejected") {
      return "#FF0000"
    }else if (status == "PO created") {
      return "#ffc107"
    } else {
      return "";
    }
  };
  changeValue = (e, quote_id) => {
    let quote_status = e.currentTarget.textContent;
    // let ratingStataus= this.state.label
    if (quote_status === "Closed Lost") {
      this.ClosedLostPopup(quote_id, quote_status);
    } else {
      this.updateQuoteStatus(quote_status, quote_id, this.state.label);
    }
  };

  // Hot warm Cold status code
  displayStatusRating = (status) => {
    if (status == "Hot") {
      return "success";
    } else if (status == "Warm") {
      return "warning";
    } else {
      return "secondary";
    }
  };

  changeRating = (e, quote_id) => {
    let ratingStataus = e.currentTarget.textContent;
    let quote_status = this.state.quote_status;
    this.updateQuoteStatus(quote_status, quote_id, ratingStataus);
  };
  // code End

  ClosedLostPopup = (quote_id, quote_status) => {
    this.setState({
      requestQuoteID: quote_id,
      quote_status: quote_status,
      showPopup: true,
    });
  };

  closeModal3 = () => {
    this.setState({
      showPopup: false,
    });
  };

  message2 = (value) => {
    this.setState({
      message: value,
    });
  };

  TicketDetailPopup = (quote_id) => {
    this.setState({
      requestQuoteID: quote_id,
      showTicket: true,
    });
  };

  closeModal2 = () => {
    this.setState({
      showTicket: false,
    });
  };

  getSubTotal = (item) => {
    if (item.productTotal.length > 0) {
      return <span>Sub Total: {item.productTotal[0].sub_total}</span>;
    }
  };
  getShippingRate = (item) => {
    if (item.productTotal.length > 0) {
      if (item.productTotal[0].shipping > 0) {
        return <span>Flat Shipping Rate: {item.productTotal[0].shipping}</span>;
      }
    }
  };
  getGrandTotal = (item) => {
    if (item.productTotal.length > 0) {
      return <span>Grand Total: {item.productTotal[0].grand_total}</span>;
    }
  };
  handeChangeAccountName = (e) => {
    this.setState(
      {
        selectedStatus: e,
        current_page: 1,
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };

  exportsAllQuoteProductList = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails?.token}`,
            },

            data: {
              selectedStatus: this.state.selectedStatus.length
                ? this.state.selectedStatus
                : [],
              logined_user_id: this.state.logined_user_id.length
                ? this.state.logined_user_id
                : [],
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              type: this.state.dateType,
              phone: this.state.mobile,
              rating: this.state.rating,
              quoteType: this.state.quoteType,
              company: this.state.company_name,
              po_status: this.state.poStatus
            },
          };

          return axios(apiUrl + "/get-product-list-download", requestoption)
            .then((data) => {
              this.setState(
                {
                  QuoteListDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.QuoteListDownload.map(
                    (item, index) => {
                      const date = moment(item.created_at);
                      const validdate = moment(item.valid_upto);
                      return {
                        "S No.": index + 1,
                        "Quote ID": item.quote_id,
                        "Quote type": item.quote_type,
                        // city: item.city,
                        "Reference Rfq ID": item.rfq_id,
                        "Lead Reference ID": item.lead_id,
                        "Billing Address": item.billingAddress,
                        "Customer Name": item.customerName,
                        "Customer Group": item.customer_group,
                        "Quote Subject": item.quote_subject,

                        Email: item.email,
                        Mobile: item.mobile,
                        // total_gross_margin: item.grossMargin,
                        // total_gross_margin_per: item.grossMarginPercentage,
                        // shipping_address: item.shippingAddress,
                        "Quote Status": item.quote_status,
                        "Parent Quote Id": item.parent_quote_id,
                        "Parent Quote Type": item.parent_quote_type,
                        "Order Id": item.order_id,
                        "Valid till Date": validdate.format("DD-MM-YYYY"),
                        "Account Name": item.company_name,
                        "Grand Total": item.total,
                        "Agent Name": item.agentName,
                        "Credit Rate": item.credit_available,
                        // quote_date: item.created_at,
                        "Quote Date": date.format("DD-MM-YYYY"),
                        //
                        "Product Name": item.productName,
                        Mrp: item.mrp,
                        "System TP": item.tp,
                        "Sourcing TP": item.sourcing_tp,
                        "MarkUP %": item.markup_perc,
                        "List Price per SKU": item.list_price_per_sku,
                        "List Price (ORG)": item.list_price_org,
                        Qty: item.qty,
                        "Net Amount (ORG)": item.net_amount_org,
                        "Quote Net Amount": item.net_amount,
                        "Tax %": item.tax_perc,
                        "Tax Amount": item.tax,
                        "Product category": item.productCategory,
                        "Product Brand": item.productBrand,
                        "Gross margin %": item.grossMarginPercentage,
                        "Reason(Remarks)": item.remarks,
                        "Reason(Lost Case)": item.lost_reason,
                      };
                    }
                  );
                  resolve(dataNew);
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };

  exportsAllQuoteList = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails?.token}`,
            },

            data: {
              selectedStatus: this.state.selectedStatus.length
                ? this.state.selectedStatus
                : [],
              logined_user_id: this.state.logined_user_id.length
                ? this.state.logined_user_id
                : [],
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              type: this.state.dateType,
              phone: this.state.mobile,
              rating: this.state.rating,
              quoteType: this.state.quoteType,
              company: this.state.company_name,
              po_status: this.state.poStatus
            },
          };

          return axios(apiUrl + "/get-quote-list-download", requestoption)
            .then((data) => {
              this.setState(
                {
                  QuoteListDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.QuoteListDownload.map(
                    (item, index) => {
                      const validdate = moment(item.valid_upto);
                      const date = moment(item.created_at);
                      return {
                        "S No.": index + 1,
                        "Quote ID": item.quote_id,
                        "Quote Type": item.quote_type,
                        City: item.city,
                        "Reference RFQ ID": item.rfq_id,
                        "Lead Reference ID": item.lead_id,
                        "Billing Address":
                          item.billingAddress === null
                            ? item.billingAddress
                            : item.billingAddress.replace(/,/g, " "),
                        "Customer Name": item.customerName,
                        "Customer Group": item.customer_group,
                        "Quote Subject": item.quote_subject,
                        Email: item.email,
                        Mobile: item.mobile,
                        "Total Gross margin": item.grossMargin,
                        "Total Gross margin Percentage":
                          item.grossMarginPercentage,
                        "Shipping Address":
                          item.shippingAddress === null
                            ? item.shippingAddress
                            : item.shippingAddress.replace(/,/g, " "),
                        Status: item.quote_status,
                        "Parent Quote ID": item.parent_quote_id,
                        "Parent Quote Type": item.parent_quote_type,
                        "Order Id": `${item.missingOrders
                          ? `Missing Orders: ${item.missingOrders} || `
                          : ""
                          } ${item.order_id}`,
                        "Reason(remarks)": item.remarks,
                        "Reason(remark Lost Case)": item.lost_reason,
                        "Valid Till Date": validdate.format("DD-MM-YYYY"),
                        "Account Name": item.company_name,
                        "Grand Total": item.total,
                        "Agent Name": item.agentName,
                        "Credit Rate": item.credit_available,
                        "Quote Date": date.format("DD-MM-YYYY"),
                        "Rating Name": item.label_name,
                        "Rating Percentage": item.label_perc,
                        "UTM Content": item.utm_content,
                        "UTM Campaign": item.utm_campaign,
                        "UTM Source": item.utm_source,
                        "UTM Medium": item.utm_medium,
                        // product_category: item.
                      };
                    }
                  );
                  resolve(dataNew);
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };

  downloadReport = async (val) => {
    let result = null;
    if (this.state.option === "quote_list") {
      result = await this.exportsAllQuoteList();
    } else {
      result = await this.exportsAllQuoteProductList();
    }
    // let result = await this.exportsAllQuoteList();

    if (result.length >= 0) {
      this.setState({ QuoteListDownload: result }, () => {
        setTimeout(() => {
          this.getExcel();
          // this.csvLinkEl.current.click();
        });
      });
    }
  };
  getExcel = () => {
    // XLSX.utils.sheet_add_aoa(ws, [heading], { origin: "A1" });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      this.state.QuoteListDownload
      // { origin: "A2", skipHeader: true }
    );

    XLSX.utils.book_append_sheet(wb, ws, "myFile4");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(
      wb,
      this.state.option === "quote_list"
        ? "Quote-List.xls"
        : "Quote-Product-list.xls"
    );
  };

  paginationLoading = () => {
    this.setState({
      isPaginationLoading: 1,
    });
  };

  getPaginationValue = (currentPage, displayItems) => {
    this.setState(
      {
        current_page: currentPage,
        total_item: displayItems,
      },
      () => {
        this.getAllQuote();
      }
    );
  };
  handleDatetype = (e) => {
    this.setState(
      {
        dateType: e.target.value,
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  getUrl = (QUOTEID) => {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return axios(apiUrl + `/get-quote-url/${QUOTEID}`, config)
      .then((data) => {
        this.setState(
          {
            url: data.data.data[0].url,
          },
          () => {
            if (data.data.data) {
              window.open(this.state.url, "_blank");
            }
          }
        );
      })
      .catch((err) => console.log(err));
  };
  mobileFilter = (e) => {
    if (e.target.value.length == 0 || e.target.value.length > 4) {
      this.setState(
        {
          mobile: e.target.value,
          current_page: 1,
        },
        () => {
          this.paginationLoading();
          this.getAllQuote();
        }
      );
    }
  };
  filterByPOStatus = (e) => {
    this.setState(
      {
        poStatus: e,
        current_page: 1,
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
      }
    );

  }
  filterByQuoteType = (e) => {
    this.setState(
      {
        quoteType: e.target.value,
        current_page: 1,
      },
      () => {
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  getAllPOStatus = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "31",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails?.token}`,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        const poStatusArrayNew = data.data.data;
        let poStatusArray = poStatusArrayNew.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ poStatusArray });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
          current_page: 1,
        },
        () => {
          this.paginationLoading();
          this.getAllQuote();
        }
      );
    };
    return (
      <div>
        {this.state.message.length > 0 ? (
          <Alert className="displayMessageArea" variant="success">
            {this.state.message}
          </Alert>
        ) : null}

        <div className="card table-card">
          <div className="quoteListPage">
            <div className="headingPadding">
              <Form className="quoteListPageForm">
                <Row>
                  <Col md="3" className="mt-3">
                    <Form.Control
                      as="select"
                      className="listingFontSizeColor"
                      custom
                      onChange={this.handleDatetype}
                      name={this.state.dateType}
                    >
                      <option value="">Date Type</option>
                      <option value="created">Create Date</option>
                      <option value="updated">Update Date</option>
                    </Form.Control>
                  </Col>

                  {/* <Col  style={{ display: "flex" }}> */}
                  {/* <Row className="quoteListPageRow"> */}
                  <Col md="3" className="mt-3">
                    <div className="customFilterCnt">
                      <div className="customFilter">
                        {/* <Daterangepicker
                      setDateRange={setDateRange}
                    ></Daterangepicker> */}
                        <DateRangeNew
                          label={"ALL DATA"}
                          // allData={true}
                          setDateRange={setDateRange}
                        ></DateRangeNew>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="quoteStatus"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={this.state.quoteStatusArray}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"m
                      placeholder="Select Status"
                      onChange={(options) => {
                        if (Array.isArray(options)) {

                          this.setState(
                            {
                              selectedStatus: options?.map((opt) => opt.value),
                            },
                            () => {
                              this.handeChangeAccountName(
                                this.state.selectedStatus
                              );
                            }
                          );
                        } else {
                          this.handeChangeAccountName([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                    {/* <Form.Control
                      as="select"
                      size="md"
                      defaultValue={this.state.selectedStatus}
                      custom
                      onChange={this.handeChangeAccountName}
                    >
                      <option value="">Select status</option>
                      {this.state.quoteStatusArray &&
                        this.state.quoteStatusArray.map((item, ind) => {
                          return (
                            <option key={item.id} value={item.meta_value}>
                              {item.meta_title}
                            </option>
                          );
                        })}
                    </Form.Control> */}
                  </Col>
                  <Col md="3" className="mt-3">
                    <Form.Control
                      className="listingFontSizeColor"
                      placeholder="Filter By Mobile"
                      onChange={(data) => this.mobileFilter(data)}
                    />
                  </Col>
                  
                  {/* <Col xs={1}>
                    <Button
                      variant="light"
                      type="button"
                      onClick={this.TicketDetailPopup}
                      style={{ fontSize: "14px", border: "1px solid #cccccc", width: "100%" }}
                    >
                      Ticket
                    </Button>
                  </Col> */}
                  <Col md="3" className="mt-3">
                    <Form.Control
                      as="select"
                      className="listingFontSizeColor"
                      custom
                      onChange={this.filterByQuoteType}
                    >
                      <option value="">Quote Type</option>
                      <option value="2">Magic</option>
                      <option value="1">Normal</option>
                    </Form.Control>
                  </Col>
                  {this.state.agentOptions &&
                    this.state.agentOptions.length > 1 ? (
                    <Col md="3" className="mt-3">
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="orderType"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        options={this.state.agentOptions}
                        className="basic-multi-select listingFontSizeColor"
                        classNamePrefix="select"
                        placeholder="Filter-Agents"
                        onChange={(options) => {
                          if (Array.isArray(options)) {

                            this.setState(
                              {
                                logined_user_id: options?.map(
                                  (opt) => opt.value
                                ),
                              },
                              () => {
                                this.filterByAssignTo(
                                  this.state.logined_user_id
                                );
                              }
                            );
                          } else {
                            this.filterByAssignTo([]);
                          }
                        }}
                        components={{
                          Option: InputOption,
                        }}
                      />
                    </Col>
                  ) : null}

                  <Col md="3" className="mt-3">
                    <Form.Control
                      className="listingFontSizeColor"
                      placeholder="Filter By Company"
                      onChange={this.companyFilter}
                    />
                  </Col>
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="rating"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={ratingOptions}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Select Rating"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          // this.setState(options?.map((opt) => opt.value));

                          this.setState(
                            { rating: options?.map((opt) => opt.value) },
                            () => {
                              this.filterByRating(this.state.rating);
                            }
                          );
                        } else {
                          this.filterByRating([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </Col>
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="rating"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={this.state.poStatusArray}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="PO Status"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          // this.setState(options?.map((opt) => opt.value));

                          this.setState(
                            { poStatus: options?.map((opt) => opt.value) },
                            () => {
                              this.filterByPOStatus(this.state.poStatus);
                            }
                          );
                        } else {
                          this.filterByPOStatus([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </Col>
                  {this.state.allowedSlugs.includes("download") ? (
                    <Col md="3" className="mt-3">
                      <Link
                        // headers={headers}
                        headers={
                          this.state.option === "quote_list"
                            ? quoteListheaders
                            : QuoteProductListheaders
                        }
                        className="hidden"
                        // filename={"quote-request-list.csv"}
                        filename={
                          this.state.option === "quote_list"
                            ? "Quote-List.xls"
                            : "Quote-Product-list.xls"
                        }
                        data={this.state.QuoteListDownload}
                        ref={this.csvLinkEl}
                      >
                        Export Data
                      </Link>

                      {/* <Workbook filename={this.state.option==="quote_list"?"Quote-List.xslx": "Quote-Product-list.xslx"}
                         element={<button className="btn btn-lg btn-primary">Try me!</button>}>
                         <Workbook.Sheet data={this.state.QuoteListDownload} name="Sheet A">
                         <Workbook.Column>
                         headers={this.state.option==="quote_list"? quoteListheaders : QuoteProductListheaders}
                         </Workbook.Column>
                         </Workbook.Sheet>
                        </Workbook> */}

                      {this.state.isDownloading ? (
                        <span
                          variant="primary"
                          className="btn btn-primary downLoadButton"
                        >
                          <Spinner animation="border" />
                        </span>
                      ) : (
                        <>
                          <select
                            className="listingBtn"
                            // onChange={this.downloadReport}
                            onChange={this.handleChange}
                            disabled={this.state.isDownloading}
                            value={0}
                          >
                            <option value="0">Download</option>
                            <option value="quote_list">Quote List</option>
                            <option value="quote_product_list">
                              Quote Product List
                            </option>
                          </select>
                        </>
                      )}
                    </Col>
                  ) : null}
                </Row>
              </Form>
            </div>
          </div>
          <div className="card-block">
            {this.state.isLoading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="mobile-table-scroll">
                <BootstrapTable
                  keyField="sno"
                  data={this.state.QuoteList}
                  total={this.state.total_record}
                  columns={columns}
                  // pagination={paginationFactory(options)}
                  striped
                  hover
                  bootstrap4
                  noDataIndication="Data not found"
                />
              </div>
            )}
          </div>
        </div>

        <Pagination
          current_page={this.state.current_page}
          total_item={this.state.total_record}
          getPaginationValue={this.getPaginationValue}
          isPaginationLoading={this.state.isPaginationLoading}
        />

        <Modal className="business_process_wrapper" show={this.state.show} onHide={this.closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Account: {this.state.CompanyData.company_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Details</th>
                  <th>MRP</th>
                  <th>List Price(Per SKU)</th>
                  <th>Quantity</th>
                  <th>Net Amount</th>
                  <th>Tax Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.Products.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{product.name}</td>
                      <td>{product.mrp}</td>
                      <td>{product.list_price}</td>
                      <td>{product.quantity}</td>
                      <td>{product.net_amount_new}</td>
                      <td>{product.tax_in_amount}</td>
                      <td>{product.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Modal show={this.state.showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Closed Remarks
            </Modal.Title>
            <Modal.Body>
              <input />
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" >
              Update
            </Button>
          </Modal.Footer>
          </Modal.Header>
        </Modal> */}

        {this.state.showPopup ? (
          <ClosedLostReason
            requestQuoteID={this.state.requestQuoteID}
            status={this.state.quote_status}
            showModal={this.state.showPopup}
            closeModal3={this.closeModal3}
            // message2={this.message2}
            getAllQuote={this.getAllQuote}
          />
        ) : null}
        {this.state.showTicket ? (
          <TicketRaiseListQuote
            requestQuoteID={this.state.requestQuoteID}
            showModal={this.state.showTicket}
            closeModal2={this.closeModal2}
          // getAllQuote={this.getAllQuote}
          />
        ) : null}
      </div>
    );
  }
}

export default ListQuote;
