import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import CallAttemptedHistory from "./CallAttemptedHistory";
import Constants from "../../../Config";
import { Redirect } from "react-router-dom";

const CallCount = 10;
const apiUrl = Constants.API_URL;

export class RFQDetail extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    date_to: "",
    date_from: "",
    selectedStatus: "",
    // current_page: "",
    // total_item: "",
    quote_type: "",
    isLoading: false,
    product_data: [],
    call_status: "",
    rfq_status: "",
    call_attempt_history: [],
    show: true,
    showRfqList: false,
  };
  componentDidMount() {
    var val = JSON.parse(localStorage.getItem("dataQuote"));
if(val ){

  this.getRfqDetail(val.rfqID);
  this.setState({
    rfqID: val.rfqID,
  });
}
  }
  getRfqDetail = (rfqID) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },
          data: {
            request_quote_id: rfqID,
            logined_user_id: this.state.logined_user_id
              ? this.state.logined_user_id
              : getUserDetails.user_id,
            quote_type: this.props.quote_type,
            selectedStatus: this.state.selectedStatus,
            selectedType: this.state.selectedType,
            total_item: this.state.total_item,
            current_page: this.state.current_page,
            // buying_for: this.state.selectedBuyingFor,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
          },
        };
        return Axios(apiUrl + "/get-quote-request", options)
          .then((data) => {
            var options1 = { year: "numeric", month: "long", day: "numeric" };
            let requestQuoteData = data.data.data[0];

            this.setState(
              {
                isLoading: false,
                customer_name: requestQuoteData.customer_name,
                customer_mobile: requestQuoteData.customer_mobile,
                customer_email: requestQuoteData.customer_email,
                buying_for: requestQuoteData.buying_for,
                agent_id:
                  requestQuoteData.agent_id == null
                    ? ""
                    : requestQuoteData.agent_id,
                rfq_message: requestQuoteData.message,
                quote_type: requestQuoteData.quote_type,
                // assigned_to: requestQuoteData.assigned_to,
                // startDateShow: showCallBackDate,
                product_data: requestQuoteData.product,
                attachment: requestQuoteData.filePath,
                priority: requestQuoteData.priority,
                location: requestQuoteData.location,
                // location_id: requestQuoteData.location_id.toString(),
                campaign: requestQuoteData.campaign,
                call_status: requestQuoteData.call_status,
                rfq_status: requestQuoteData.status,
                agent_name: requestQuoteData.assigned_to,
                // business_other: requestQuoteData.business_type_other,
              },
              // () => {
              //   this.getCallAttemptHistory();
              //   this.getCallAttemptCount();
              // }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };


  closeModal = () => {
    this.setState({
      show: false,

      showRfqList: true,
    });
  };

  render() {
    if (this.state.showRfqList == true) {
      return <Redirect to={{ pathname: "/quote-requests" }} />;
    }

    return (
      <Modal
        show={this.state.show}
        onHide={this.closeModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>

        {this.state.isLoading ? (
          <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <Modal.Body className="business_approve_process test">
            <Form
              // noValidate
              // validated={this.state.validated}
              encType="multipart/form-data"
            >
              <Form.Row>
                <Col
                  sm={this.state.isShowRight ? "8" : "12"}
                  className={
                    this.state.isShowRight ? "quoteStatus8" : "quoteStatus12"
                  }
                >
                  <h5 className="on_call_title">On Call Information test2</h5>

                  <Form.Row>
                    <Col sm={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Mobile</Form.Label>
                        <br />
                        <Form.Control
                          className="mobileNumber"
                          placeholder="Mobile"
                          disabled
                          name="customer_mobile"
                          value={this.state.customer_mobile}
                          onChange={this.handleChange}
                        />
                        <Button
                          variant="success"
                          className="callButton"
                          disabled
                          onClick={this.getCall}
                        >
                          {this.state.is_calling ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ width: "1.3rem", height: "1.3rem" }}
                            />
                          ) : (
                            "Call"
                          )}
                        </Button>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          placeholder="Name"
                          disabled
                          name="customer_name"
                          value={this.state.customer_name}
                          onChange={this.handleChange}
                          required
                        />{" "}
                        <Form.Control.Feedback type="invalid">
                          Please enter billing first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          placeholder="Email"
                          disabled
                          name="customer_email"
                          value={this.state.customer_email}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>

                    {this.state.product_data.map((data, ind) => (
                      <>
                        <Col sm={6}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Product {ind + 1}</Form.Label>
                            <Form.Control
                              placeholder="Product"
                              name="product_name"
                              value={data.productName}
                              onChange={this.handleChange}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                              placeholder="Quantity"
                              name="product_quantity"
                              value={data.productQuantity}
                              onChange={this.handleChange}
                              disabled
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={3}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                              placeholder="Price"
                              name="price"
                              value={data.productPrice}
                              onChange={this.handleChange}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      </>
                    ))}

                    {this.state.attachment ? (
                      <Col sm={12}>
                        <Form.Label>Attachment</Form.Label>{" "}
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() =>
                            window.open(this.state.attachment, "_blank")
                          }
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col sm={3}>
                      <Form.Group controlId="formGridBuyingFor">
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                          as="select"
                          name="priority"
                          onChange={this.handleChange}
                          value={this.state.priority}
                          disabled
                        >
                          <option value="0">Select Priority</option>
                          <option value="Highest">Highest</option>
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {/* location form dropdown in below column */}
                    <Col sm={3} className="customeAutoComplete">
                      <Form.Group controlId="formGridCity">
                        <Form.Label>Location</Form.Label>

                        <Form.Control
                          name="location"
                          placeholder="Enter Location"
                          onChange={this.handleChange}
                          value={this.state.location}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="formEditcampaign">
                        <Form.Label>Campaign Details</Form.Label>
                        <Form.Control
                          name="campaign"
                          placeholder="Enter Campaign Details"
                          onChange={this.handleChange}
                          value={this.state.campaign}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={12}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          placeholder="Message"
                          name="rfq_message"
                          value={this.state.rfq_message}
                          as="textarea"
                          rows={2}
                          onChange={this.handleChange}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>

                {this.state.isShowRight ? (
                  <Col sm="4">
                    <h5>Call Disposal - Post Call Test2</h5>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        Callback
                        {/* {
                                          this.state.startDateShow
                                          ?
                                          <span> ({this.state.startDateShow})</span>
                                          :
                                          null
                                          } */}
                      </Form.Label>
                      <DateRangePicker
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm:ss aa"
                        selected={this.state.startDate}
                        onChange={this.handleDateChange}
                        className="form-control"
                        locale="es"
                        value={this.state.startDate}
                      />
                    </Form.Group>
                    {this.state.call_attempt_history.length > 0 ? (
                      <CallAttemptedHistory
                        call_attempt_history={this.state.call_attempt_history}
                      />
                    ) : null}
                    {this.state.call_attempt_count > 0 &&
                    this.state.call_attempt_count <= CallCount ? (
                      <div>
                        <div
                          className={`Call_Attempt_${this.state.call_attempt_count}`}
                        >
                          <Form.Group controlId="call_attempt_count">
                            <Form.Label>
                              Call Status {this.state.call_attempt_count}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              name="call_status"
                              value={this.state.call_status}
                              //   onChange={this.handleChangeForCallStatus}
                              required
                            ></Form.Control>
                          </Form.Group>
                        </div>
                        {this.state.rfq_status ? (
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>RFQ Status</Form.Label>
                            <Form.Control
                              placeholder="Name"
                              disabled
                              name="rfq_status"
                              value={this.state.rfq_status}
                              required
                            />
                          </Form.Group>
                        ) : null}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            placeholder="Remarks"
                            name="remarks"
                            value={this.state.remarks}
                            onChange={this.handleChange}
                            as="textarea"
                            rows={2}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Transfer Account</Form.Label>
                          <Form.Control
                            name="agent_name"
                            autoFocus
                            onChange={this.handleChange}
                            value={this.state.agent_name}
                          />
                        </Form.Group>
                      </div>
                    ) : null}
                  </Col>
                ) : null}
              </Form.Row>
            </Form>
          </Modal.Body>
        )}

        {/* <Modal.Footer>
            {this.state.rfq_status === "Converted to Quote" ? (
              <Button
                style={{ width: "200px" }}
                variant="primary"
                disabled={this.state.isSaveLoading}
                id="submit"
                onClick={
                  !this.state.isSaveLoading ? (val) => this.changeRFQ(val) : null
                }
              >
                {this.state.isSaveLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ width: "1.3rem", height: "1.3rem" }}
                  />
                ) : (
                  "Save & Create Quote"
                )}
              </Button>
            ) : (
              <Button
                style={{ width: "100px" }}
                variant="primary"
                disabled={this.state.isSaveLoading}
                id="submit"
                onClick={!this.state.isSaveLoading ? this.submitForm : null}
              >
                {this.state.isSaveLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ width: "1.3rem", height: "1.3rem" }}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            )}
          </Modal.Footer> */}
      </Modal>
    );
  }
}

export default RFQDetail;
