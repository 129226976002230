import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Constants from "../../../Config";
const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

class CallAttemptedHistory extends React.Component {
  render() {
    return (
      <div>
        {this.props.call_attempt_history?.map((item, index) => {
          let createdAT = new Date(item.created_at);
          var monthArray = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          let month = createdAT.getMonth();
          let ampm = createdAT.getHours() >= 12 ? "pm" : "am";
          let date =
            createdAT.getDate() +
            " " +
            monthArray[month] +
            " " +
            createdAT.getFullYear() +
            ", " +
            createdAT.getHours() +
            ":" +
            createdAT.getMinutes() +
            " " +
            ampm;
          let createdAt = moment.utc(createdAT).format("DD-MM-YYYY HH:mm");
          return (
            <p
              key={index}
              className={item.call_type == 1 ? "outGoing" : "inComing"}
            >
              <b>CA</b> - {item.call_attempt}, Status - {item.call_status}
              <br />
              <b>Remarks</b> - {item.agent_note}
              <br />
              <b>By</b> - {item.firstname} {item.lastname}, <b>On</b> -{" "}
              {createdAt.toString()} <br />
              <b>FollowUp Date </b>-{item.followup_date} <br />
              <b>Calling mode</b>-{item.calling_mode}
            </p>
          );
          // return <p key={index} className={item.call_type == 1 ? 'outGoing':'inComing'}><b>CA</b> - {item.call_attempt}, Status - {item.call_status}<br /><b>Remarks</b> - {item.agent_note}<br /><b>By</b> - {item.firstname} {item.lastname}, <b>On</b> - {item.created_at}, <br/> <b>Calling Mode </b>-{item.calling_mode}. <br/><b>FollowUp Date </b>-{item.followup_date}</p>
        })}
      </div>
    );
  }
}

export default CallAttemptedHistory;
