import React, { Component } from "react";
import { Form, Col, Row, Alert, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from "react-select-search";
import AsyncSelect from "react-select/async";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Constants from "../../../Config";

const apiUrl = Constants.API_URL;

export class ProductAdd3 extends Component {
  state = {
    productArray: [],
    productList: [],
    categoryArray: [],
    categoryList: [],
    product_name: "",
    product_id: "",
    pack_quantity: "",
    product_quantity: "",
    category_quantity: "",
    count: "",
    category_name: "",
    type: "",
    //
    other: "",
    other_quantity: 1,
    typeSwitch: false,

    selectedValue: "product",
    itemQuantity: "",
  };
  handleSelect = (i, e) => {
    this.setState(
      {
        selectedValue: e.target.value,
        typeSwitch: true,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: "",
          quantity: 0,
          type: this.state.selectedValue,
          category_id: 0,
          product_id: 0,
        });
      }
    );
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value, "Quantity  for Product");
  };
  handleChangeCategQuantity = (i, e) => {
    console.log(this.props.new_add, "New_add");
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: this.state.category_name,
          quantity: this.state.category_quantity,
          type: "category",
          category_id: this.state.category_id,
          product_id: 0,
        });
      }
    );
  };
  handleChangeProductQuantity = (e, i, itemtype) => {
    console.log(e, "New_add");
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.addDisplayArray(i, {
          name:
            itemtype == "product"
              ? this.state.product_name
              : itemtype == "category"
              ? this.state.category_name
              : this.state.other,
          quantity: this.state.itemQuantity,
          type: itemtype,
          price: itemtype == "product" ? this.state.product_price : 0,
          product_id: itemtype == "product" ? this.state.product_id : 0,
          category_id: itemtype == "category" ? this.state.category_id : 0,
        });

        // this.props.addDisplayArray(i, {
        //   name: this.state.product_name,
        //   quantity: this.state.product_quantity,
        //   type: this.state.selectedValue,
        //   price: this.state.product_price,
        //   product_id: this.state.product_id,
        //   category_id: 0,
        // });
      }
    );
  };
  getProductName = (i, e) => {
    let index = this.state.productList.filter((el) => el.name == e.value)[0];
    console.log("e", e);
    console.log("index", index);
    console.log("index", i);
    // let productId = index.product_id;

    this.setState(
      {
        product_id: index.product_id,
        product_name: index.name,
        //   availabe_stock: index.quantity,
        moq: index.moq,
        product_price: Number(index.price).toFixed(2),
        // product_quantity: index.moq,

        count: index.moq,
        product_quantity: index.pack_quantity,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: index.name,
          quantity: this.state.product_quantity.toString(),
          type: "product",
          product_id: index.product_id,
          category_id: 0,
          price: Number(index.price).toFixed(2),
        });
      }
    );
  };

  getValue = (e, cb) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      data: { keyword: e },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
        cb(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((error) => console.log(error));
  };
  getCategory = (e, cb) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: {
        keyword: e,
      },
    })
      .then((data) => {
        // console.log(data.data.data, "Categories");
        this.setState({
          categoryList: data.data.data,
          categoryArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
        cb(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((error) => console.log(error));
  };

  getCategoryName = (i, e) => {
    console.log("index", i);
    let categ = this.state.categoryList.filter((el) => el.name == e.value)[0];
    console.log(categ, "acte::::::::");
    categ["type"] = "category";
    // categ.push(type: "category")
    console.log(categ, "acte::::::::");
    this.setState(
      {
        category_name: e.value,
        category_id: categ.category_id,
        type: categ.type,
        categSelect: true,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: categ.name,
          quantity: this.state.category_quantity
            ? this.state.category_quantity
            : 1,
          type: "category",
          category_id: categ.category_id,
          product_id: 0,
        });
      }
    );
  };
  getOtherName = (i) => {
    console.log("index", i);
    this.props.addDisplayArray(i, {
      name: this.state.other,
      quantity: 1,
      type: "other",
      category_id: 0,
      product_id: 0,
    });
  };

  // addMoq = () => {
  //   let quantity =
  //     Number(this.state.product_quantity) + Number(this.state.pack_quantity);
  //   this.setState({ product_quantity: quantity });
  //   // this.props.addNewProduct({
  //   //   product_id: this.state.product_id,
  //   //   quantity: quantity ? quantity : this.state.product_quantity,
  //   //   name: this.state.product_name,
  //   // });
  // };

  render() {
    return (
      <div key={this.props.index}>
        {
          <div>
          <Row>
            <Col md={2}>
              <Form.Group controlId="formLeadTypeName">
                <Form.Label>Choose Type</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={(e) => this.handleSelect(this.props.index, e)}
                  value={this.props.item.type}
                  //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                >
                  <option value="product">Product</option>
                  <option value="category">Category</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={5}  className="customeAutoComplete">
              <Form.Label>Item type</Form.Label>
              <Form.Group
                controlId="formLeadProductName"
                key={this.props.index}
              >
                {this.props.item.type == "product" ? (
                  <AsyncSelect
                    value={
                      this.props.item.name || this.state.typeSwitch
                        ? {
                            value: this.props.item.name,
                            label: this.props.item.name,
                          }
                        : {
                            value: this.state.product_name,
                            label: this.state.product_name,
                          }
                    }
                    loadOptions={this.getValue}
                    placeholder="Product name"
                    onChange={(e) => this.getProductName(this.props.index, e)}
                  />
                ) : this.props.item.type == "category" ? (
                  <AsyncSelect
                    value={
                      this.props.item.name || this.state.typeSwitch
                        ? {
                            value: this.props.item.name,
                            label: this.props.item.name,
                          }
                        : {
                            value: this.state.category_name,
                            label: this.state.category_name,
                          }
                    }
                    loadOptions={this.getCategory}
                    placeholder="Product name"
                    onChange={(e) => this.getCategoryName(this.props.index, e)}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    name="other"
                    defaultValue={this.props.item.name}
                    value={
                      this.props.item.name
                        ? this.props.item.name
                        : this.state.other
                    }
                    onChange={this.handleChange}
                    onBlur={() => this.getOtherName(this.props.index)}
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={2} >
              <Form.Label>Quantity</Form.Label>
              <Form.Group
                controlId="formLeadProductQuantity"
                key={this.props.index}
                className="d-flex"
                // style={{ display: "flex" }}
              >
                <Form.Control
                  className={this.state.quantity_error ? "error" : ""}
                  type="number"
                  min={1}
                  placeholder="Qty"
                  name="itemQuantity"
                  value={
                    this.props.item.quantity || this.state.typeSwitch
                      ? this.props.item.quantity
                      : this.state.itemQuantity
                  }
                  onChange={(e) =>
                    this.handleChangeProductQuantity(
                      e,
                      this.props.index,
                      this.props.item.type
                    )
                  }
                />
              </Form.Group>
            </Col>
            <Col md={3} >
            <Row className="align-items-center">
                <Col> 
                <Form.Group controlId="formRfqPrice" key={this.props.index}>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                    placeholder="Price"
                    name="price"
                    value={this.props.item.price}
                    onChange={this.handleChange}
                    disabled
                    />
                </Form.Group>
                </Col>
                <Col>
                {this.props.index == this.props.itemDisplayArray.length-1  ? (
                    <>
                    <FontAwesomeIcon
                        icon="minus"
                        id={this.props.index}
                        onClick={() => this.props.removeItem(this.props.index)}
                        className="removeIcon"
                    />

                    <FontAwesomeIcon
                        icon="plus"
                        onClick={() =>
                        this.props.addNewItem({
                            product_id: this.state.product_id,
                            quantity: this.state.product_quantity,
                            // name: this.state.product_name
                        })
                        }
                        className="addIcon"
                    />
                    </>
                ) : (
                    <FontAwesomeIcon
                    icon="minus"
                    id={this.props.index}
                    onClick={() => this.props.removeItem(this.props.index)}
                    className="removeIcon"
                    />
                )}
                </Col>
            </Row>
            </Col>
          </Row>

      
          </div>
        }
      </div>
    );
  }
}

export default ProductAdd3;