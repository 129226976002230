import React, { useState } from "react";
import "../../asset/css/helvetica.css";
import "./static-page.css"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import user_icon from "../../asset/Images/user_icon.svg";
import downArrowGrey from "../../asset/Images/downArrowGrey.svg";
import searchIcon from "../../asset/Images/search-black.svg";
import infoIconGrey from "../../asset/Images/infoIconGrey.svg";
import deleteIcon from "../../asset/Images/delete_new_icon.svg";
import deleteIconWhite from "../../asset/Images/deleteIconWhite.svg";
import tooltipArrowIcon from "../../asset/Images/tooltipArrowIcon.svg";
import crossBlue from "../../asset/Images/crossBlue.svg";
import downloadIcon from "../../asset/Images/downloadIconNew.svg";
import alertMsg from "../../asset/Images/danger_alert.svg";
import viewBtn from "../../asset/Images/viewBtn.svg";
import callIcon from "../../asset/Images/callIcon.svg";
import callIconBlue from "../../asset/Images/callIconBlue.svg";
import backArrowNew from "../../asset/Images/backArrowNew.svg";
import backArrowInactive from "../../asset/Images/backArrowInactive.svg";
import edit from "../../asset/Images/edit.svg";
import CloseButton from "../../asset/Images/CloseButton.svg";
import editDot from "../../asset/Images/editDot.svg";
import editIconWhite from "../../asset/Images/editIconWhite.svg";
import plusIcon from "../../asset/Images/plusIcon.svg";
import RightArrowBlue from  "../../asset/Images/RightArrowBlue.svg"

const StaticPage = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow(false)
    setShow1(true)
  };
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setShow1(false)
    setShow2(true)
  };

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const showDropDown = () => {
    setShowDropdown(!showDropdown);
  };
  const closeDropDown = () => {
    setShowDropdown(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="DesktopHtml">
        <section className="customerInfoMainCnt">
          <div className="container-40">
            <div className="customerInfoEditable">
              <h1 className="pageSubHeading">Customer Info</h1>
              <div className="customerInfoCnt">
                <div className="customerInfoItem">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Customer Account</label>
                    <input type="number" id="name" placeholder="" />
                    <div className="traInputIconWrapper">
                      <img src={user_icon} width="24px" height="24px" />
                    </div>
                  </div>
                </div>
                <div className="customerInfoItem">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Customer Mobile</label>
                    <input type="number" id="name" placeholder="" />
                  </div>
                </div>
                <div className="customerInfoItem">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Customer Name</label>
                    <input type="text" id="name" placeholder="" />
                  </div>
                </div>
                <div className="customerInfoItem">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Customer Email</label>
                    <input type="email" id="name" placeholder="" />
                  </div>
                </div>
                <div className="customerInfoItem">
                  <div className="customeSelectCnt">
                    <button
                      className={`customSelectBtn`}
                      onClick={toggleDropdown}
                    >
                      <label htmlFor="name">Customer Role</label>
                      <span className="selectedValue">CEO</span>
                      <div className="customSelectBtnIcon downArrowIcon">
                        <img src={downArrowGrey} width="12px" height="12px" />
                      </div>
                    </button>
                    <div
                      className={`customDropDownCnt ${isOpen ? "show" : ""}`}
                    >
                      <ul className="dropdownWrapper">
                        <li className="dropdownItem">COE</li>
                        <li className="dropdownItem">Designer</li>
                        <li className="dropdownItem">Developer</li>
                        <li className="dropdownItem">COE</li>
                        <li className="dropdownItem">Designer</li>
                        <li className="dropdownItem">Developer</li>
                        <li className="dropdownItem">COE</li>
                        <li className="dropdownItem">Designer</li>
                        <li className="dropdownItem">Developer</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="customerInfoItem">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Delivery Location</label>
                    <input type="text" id="name" placeholder="" />
                    <div className="traInputIconWrapper searchIcon">
                      <img src={searchIcon} width="20px" height="20px" />
                    </div>
                  </div>
                </div>
                <div className="customerInfoItem">
                  <div className="traInputWrapper">
                    <label htmlFor="name">Agent</label>
                    <input type="text" id="name" placeholder="" />
                  </div>
                </div>
                <div className="customerInfoItem infoIconCnt">
                  <div className="customeSelectCnt">
                    <button
                      className="customSelectBtn disabled"
                      style={{ cursor: "default" }}
                    >
                      <label htmlFor="name">Credit Finance</label>
                      <span className="selectedValue">
                        2 Options Available
                      </span>
                      <div
                        className="customSelectBtnIcon downArrowIcon"
                        onClick={showDropDown}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={infoIconGrey} width="20px" height="20px" />
                      </div>
                    </button>
                    <div
                      className={`creditFinaceDtl ${showDropdown ? "" : "d-none"
                        }`}
                    >
                      <div className="closeDropDown" onClick={closeDropDown}>
                        <img
                          src={crossBlue}
                          width="16px"
                          height="16px"
                          alt="cross icon"
                        />
                      </div>
                      <ul className="creditFinaceItemWrapper">
                        <li>Name</li>
                        <li>Rate</li>
                        <li>Amount</li>
                      </ul>
                      <hr className="hr-1 my-2" />
                      <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                        <li>Udaan (15 Days)</li>
                        <li>2.25%</li>
                        <li>â‚¹5,00,00,000</li>
                      </ul>
                      <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                        <li>Udaan (15 Days)</li>
                        <li>2.25%</li>
                        <li>â‚¹5,00,00,000</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="hr-16" />

        {/* requirement detail section start */}
        <section className="requirementDtlCnt">
          <div className="container-40">
            <h2 className="pageSubHeading">Requirement Details</h2>
            <div className="requirementDtlTableCnt">
              <table className="requirementTable">
                <thead className="tableHead">
                  <tr>
                    <th style={{ width: "20%" }}>Type</th>
                    <th style={{ width: "33%" }}>Name</th>
                    <th style={{ width: "10%" }}>Brand</th>
                    <th style={{ width: "10%" }}>Quantity</th>
                    <th style={{ width: "10%" }}>Unit</th>
                    <th style={{ width: "10%" }}>Price</th>
                    <th style={{ width: "7%" }}>&nbsp;</th>
                  </tr>
                </thead>

                <tbody className="tableBody">
                  <tr>
                    <td className="enabled_filed_wrapper">
                      <div className="customeSelectCnt tableSelectOption">
                        <button className="customSelectBtn">
                          <label htmlFor="name">Catalogue</label>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div class="customDropDownCnt">
                          <ul class="dropdownWrapper">
                            <li class="dropdownItem">Ozone </li>
                            <li class="dropdownItem">Mortise Door</li>
                            <li class="dropdownItem">Mortise Door Handle</li>
                            <li class="dropdownItem">Somany Duragres</li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <textarea
                          rows={2}
                          className="textEditableInput"
                          type="text"
                          placeholder=" Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                        for Residences Library Offices Hospitals High Security
                                        Premises, Silver (2 Years Warranty)"
                        ></textarea>
                        <a href="javascript:void(0)" className="view_Btn">
                          <img src={viewBtn} width="16px" height="16px" />
                          <span className="viewCnt">View</span>
                        </a>
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="Ozone"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input type="number" className="" placeholder="10" />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="pcs"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="â‚¹250"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="tableSelectOption deleteIconBtn"
                      >
                        <img src={deleteIcon} width="24px" height="24px" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="enabled_filed_wrapper">
                      <div className="customeSelectCnt tableSelectOption">
                        <button className="customSelectBtn">
                          <label for="name">Catalogue</label>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <textarea
                          rows={2}
                          className="textEditableInput"
                          type="text"
                          placeholder=" Pedestal fans"
                        ></textarea>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="Godrej"
                        />
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input type="number" className="" placeholder="10" />
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="pcs"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="N/A"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="tableSelectOption deleteIconBtn"
                      >
                        <img src={deleteIcon} width="24px" height="24px" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="enabled_filed_wrapper">
                      <div className="customeSelectCnt tableSelectOption">
                        <button className="customSelectBtn">
                          <label for="name">Other</label>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <textarea
                          rows={2}
                          className="textEditableInput"
                          type="text"
                          placeholder="Blue Paint Bottles"
                        ></textarea>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="Asian Paint"
                        />
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input type="number" className="" placeholder="2" />
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className=""
                          placeholder="litres"
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="N/A"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="tableSelectOption deleteIconBtn"
                      >
                        <img src={deleteIcon} width="24px" height="24px" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="enabled_filed_wrapper">
                      <div className="customeSelectCnt tableSelectOption">
                        <button className="customSelectBtn">
                          <label for="name">Attachment</label>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label for="downloadIcon" className="upload_title">
                          <img
                            src={downloadIcon}
                            width="16px"
                            height="16px"
                          />
                          <span>Upload a file</span>
                        </label>
                        <input
                          id="downloadIcon"
                          type="file"
                          className="downloadFile"
                          style={{ display: "none" }}
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="NA"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="number"
                          className="disabled"
                          placeholder="NA"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="NA"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper">
                      <div className="tableSelectOption">
                        <label></label>
                        <input
                          type="text"
                          className="disabled"
                          placeholder="NA"
                          readOnly="readonly"
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="tableSelectOption deleteIconBtn"
                      >
                        <img src={deleteIcon} width="24px" height="24px" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="enabled_filed_wrapper">
                      <div className="customeSelectCnt tableSelectOption">
                        <button className="customSelectBtn">
                          <label for="name">Catalogue</label>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                      </div>
                    </td>
                    <td className="enabled_filed_wrapper searchProductWrapper">
                      <div className="searchProduct_list tableSelectOption">
                        <div>
                          <label for="searchProduct"></label>
                          <input
                            className="searchProducts"
                            placeholder="Search a product"
                          />
                        </div>
                        <div class="customDropDownCnt">
                          <ul class="dropdownWrapper">
                            <li class="dropdownItem">
                              Ozone ODWL-03-NS STD SSS Narrow Style Smart Door
                              Lock for Residences Library Offices Hospitals
                              High Security Premises, Silver (2 Years
                              Warranty)
                            </li>
                            <li class="dropdownItem">
                              Mortise Door Handle Set For Aluminium Casement
                              Door - Series - McCoy DHM
                            </li>
                            <li class="dropdownItem">
                              Mortise Door Handle Set For Aluminium Casement
                              Door - Series - McCoy DHM - White - McCoy 8M
                            </li>
                            <li class="dropdownItem">
                              Somany Duragres Breccia Grande Valor
                            </li>
                            <li class="dropdownItem">
                              Somany Duragres Breccia Grande Valor
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper alert-msg-area">
                      <div className="alertmsgInner tableSelectOption">
                        <span>&nbsp;</span>
                        <img src={alertMsg} width="16px" height="16px" />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper alert-msg-area">
                      <div className="alertmsgInner tableSelectOption">
                        <span>&nbsp;</span>
                        <img src={alertMsg} width="16px" height="16px" />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper alert-msg-area">
                      <div className="alertmsgInner tableSelectOption">
                        <span>&nbsp;</span>
                        <img src={alertMsg} width="16px" height="16px" />
                      </div>
                    </td>
                    <td className="disbled_filed_wrapper alert-msg-area">
                      <div className="alertmsgInner tableSelectOption">
                        <span>&nbsp;</span>
                        <img src={alertMsg} width="16px" height="16px" />
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="tableSelectOption deleteIconBtn"
                      >
                        <img src={deleteIcon} width="24px" height="24px" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="button" className="addItemsBtn">
                Add item
              </button>
            </div>
          </div>
        </section>
        {/* requirement detail section  end */}

        {/* second page start */}
        <section className="rfqDltMainCnt">
          <div className="container-40">
            <div className="rfqMainCnt">
              <div className="rfqLeftSection">
                <div className="customerInfoNonEditable">
                  <h1 className="pageSubHeading">Customer Info</h1>
                  <div className="customerInfoCnt mt-2">
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Account</label>
                        <input type="button" id="name" value="SuperDesigns" />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Email</label>
                        <input
                          type="button"
                          id="name"
                          value="iamnikhil@mccoymart.com"
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Delivery Location</label>
                        <input
                          type="button"
                          id="name"
                          value="Lajpat Nagar, Delhi 110007"
                        />
                        <button className="editButton">
                          <img
                            src={edit}
                            alt="edit icon"
                            width="12px"
                            height="12px"
                          />
                          <span>Edit</span>
                        </button>
                      </div>
                      <div className="traInputWrapper d-none">
                        <label htmlFor="name">Delivery Location</label>
                        <input type="text" id="name" placeholder="" />
                        <div className="traInputIconWrapper searchIcon">
                          <img src={searchIcon} width="20px" height="20px" />
                        </div>
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Name</label>
                        <input
                          type="button"
                          id="name"
                          value="Nikhil Sharma"
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Role</label>
                        <input
                          type="button"
                          id="name"
                          value="Other Construction PRO"
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Mobile</label>
                        <input
                          type="button"
                          id="name"
                          value="+91 9540495531"
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Agent</label>
                        <input type="button" id="name" value="Chetan" />
                      </div>
                    </div>
                    <div className="customerInfoItem w-100">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Customer Message</label>
                        <input
                          type="button"
                          id="name"
                          value="Please provide a detailed product description highlighting key features and benefits."
                        />
                      </div>
                    </div>
                    <div className="customerInfoItem">
                      <div className="nonEditableInputWrapper">
                        <label htmlFor="name">Support Remarks</label>
                        <input type="button" id="name" value="NA" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="requirementDtlCnt2">
                  <h2 className="pageSubHeading">Requirement Details</h2>
                  <div className="requirementDtlTableCnt">
                    <table className="requirementTable List">
                      <thead className="tableHead">
                        <tr>
                          <th style={{ width: "20%" }}>Type</th>
                          <th style={{ width: "33%" }}>Name</th>
                          <th style={{ width: "10%" }}>Brand</th>
                          <th style={{ width: "10%" }}>Quantity</th>
                          <th style={{ width: "10%" }}>Unit</th>
                          <th style={{ width: "10%" }}>Price</th>
                          <th style={{ width: "7%" }}>&nbsp;</th>
                        </tr>
                      </thead>

                      <tbody className="tableBody">
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label htmlFor="name">Catalogue</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>

                              <div class="customDropDownCnt">
                                <ul class="dropdownWrapper">
                                  <li class="dropdownItem">Ozone </li>
                                  <li class="dropdownItem">Mortise Door</li>
                                  <li class="dropdownItem">
                                    Mortise Door Handle
                                  </li>
                                  <li class="dropdownItem">
                                    Somany Duragres
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <textarea
                                rows={2}
                                className="textEditableInput"
                                type="text"
                                placeholder=" Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock
                                                    for Residences Library Offices Hospitals High Security
                                                    Premises, Silver (2 Years Warranty)"
                              ></textarea>
                              <a
                                href="javascript:void(0)"
                                className="view_Btn"
                              >
                                <img
                                  src={viewBtn}
                                  width="16px"
                                  height="16px"
                                />
                                <span className="viewCnt">View</span>
                              </a>
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="Ozone"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className=""
                                placeholder="10"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="pcs"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="â‚¹250"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Catalogue</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <textarea
                                rows={2}
                                className="textEditableInput"
                                type="text"
                                placeholder=" Pedestal fans"
                              ></textarea>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="Godrej"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className=""
                                placeholder="10"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="pcs"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="N/A"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Other</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <textarea
                                rows={2}
                                className="textEditableInput"
                                type="text"
                                placeholder="Blue Paint Bottles"
                              ></textarea>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="Asian Paint"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className=""
                                placeholder="2"
                              />
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className=""
                                placeholder="litres"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="N/A"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Attachment</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper">
                            <div className="tableSelectOption">
                              <a
                                href="javascript:void(0)"
                                type="button"
                                className="attachmentBtn"
                              >
                                Attachment.pdf
                              </a>
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="number"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper">
                            <div className="tableSelectOption">
                              <label></label>
                              <input
                                type="text"
                                className="disabled"
                                placeholder="NA"
                                readOnly="readonly"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="enabled_filed_wrapper">
                            <div className="customeSelectCnt tableSelectOption">
                              <button className="customSelectBtn">
                                <label for="name">Catalogue</label>
                                <div className="customSelectBtnIcon downArrowIcon">
                                  <img
                                    src={downArrowGrey}
                                    width="12px"
                                    height="12px"
                                  />
                                </div>
                              </button>
                            </div>
                          </td>
                          <td className="enabled_filed_wrapper searchProductWrapper">
                            <div className="searchProduct_list tableSelectOption">
                              <div>
                                <label for="searchProduct"></label>
                                <input
                                  className="searchProducts"
                                  placeholder="Search a product"
                                />
                              </div>
                              <div class="customDropDownCnt">
                                <ul class="dropdownWrapper">
                                  <li class="dropdownItem">
                                    Ozone ODWL-03-NS STD SSS Narrow Style
                                    Smart Door Lock for Residences Library
                                    Offices Hospitals High Security Premises,
                                    Silver (2 Years Warranty)
                                  </li>
                                  <li class="dropdownItem">
                                    Mortise Door Handle Set For Aluminium
                                    Casement Door - Series - McCoy DHM
                                  </li>
                                  <li class="dropdownItem">
                                    Mortise Door Handle Set For Aluminium
                                    Casement Door - Series - McCoy DHM - White
                                    - McCoy 8M
                                  </li>
                                  <li class="dropdownItem">
                                    Somany Duragres Breccia Grande Valor
                                  </li>
                                  <li class="dropdownItem">
                                    Somany Duragres Breccia Grande Valor
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td className="disbled_filed_wrapper alert-msg-area">
                            <div className="alertmsgInner tableSelectOption">
                              <span>&nbsp;</span>
                              <img
                                src={alertMsg}
                                width="16px"
                                height="16px"
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tableSelectOption deleteIconBtn"
                            >
                              <img
                                src={deleteIcon}
                                width="24px"
                                height="24px"
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="addItemsBtn">
                      Add item
                    </button>
                  </div>
                </div>
              </div>
              <div className="rfqRightSection">
                <div className="calLogBtnWrapper">
                  <button type="button" className="rfqCommonBtn bgBlue">
                    <img
                      src={callIcon}
                      alt="call icon"
                      width="11px"
                      height="11px"
                    />
                    <span>Call Customer</span>
                  </button>
                  <button type="button" className="rfqCommonBtn bgGrey" >
                    Log a Call
                  </button>
                </div>
                <div className="callRemarkCnt">
                  <h2 className="pageSubHeading">Remarks on Call Attempt</h2>
                  <div className="callReamrkWrapper">
                    <div className="callReamrkItem">
                      <div className="customeSelectCnt callReamrkDropCnt">
                        <button
                          className={`customSelectBtn`}
                          onClick={toggleDropdown}
                        >
                          <label htmlFor="name">Call Status</label>
                          <span className="selectedValue">Call Waiting</span>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div
                          className={`customDropDownCnt ${isOpen ? "show" : ""
                            }`}
                        >
                          <ul className="dropdownWrapper">
                            <li className="dropdownItem">Price Issue</li>
                            <li className="dropdownItem">
                              Needed Urgent Delivery
                            </li>
                            <li className="dropdownItem">
                              Non Serviceable Product
                            </li>
                            <li className="dropdownItem">
                              Non Serviceable Location
                            </li>
                            <li className="dropdownItem">Designer</li>
                            <li className="dropdownItem">Developer</li>
                            <li className="dropdownItem">Lead Time Issue</li>
                            <li className="dropdownItem">
                              Fulfilled by Other Quote
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="traInputWrapper">
                        <label htmlFor="name">Call Back</label>
                        <input type="text" id="name" placeholder="" disabled />
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="customeSelectCnt callReamrkDropCnt">
                        <button
                          className={`customSelectBtn`}
                          onClick={toggleDropdown}
                        >
                          <label htmlFor="name">RFQ Status</label>
                          <span className="selectedValue">Pending</span>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div
                          className={`customDropDownCnt ${isOpen ? "show" : ""
                            }`}
                        >
                          <ul className="dropdownWrapper">
                            <li className="dropdownItem">Pending</li>
                            <li className="dropdownItem">Sucsess</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="traInputWrapper">
                        <label htmlFor="name">Remarks</label>
                        <input type="text" id="name" placeholder="" />
                      </div>
                    </div>
                    <div className="callReamrkItem">
                      <div className="customeSelectCnt callReamrkDropCnt">
                        <button
                          className={`customSelectBtn`}
                          onClick={toggleDropdown}
                        >
                          <label htmlFor="name">Transfer Account</label>
                          <span className="selectedValue">Rahul</span>
                          <div className="customSelectBtnIcon downArrowIcon">
                            <img
                              src={downArrowGrey}
                              width="12px"
                              height="12px"
                            />
                          </div>
                        </button>
                        <div
                          className={`customDropDownCnt ${isOpen ? "show" : ""
                            }`}
                        >
                          <ul className="dropdownWrapper">
                            <li className="dropdownItem">Rahul</li>
                            <li className="dropdownItem">Nikhil</li>
                            <li className="dropdownItem">Nasir</li>
                            <li className="dropdownItem">Irshad</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="callHistoryCnt">
                  <h2 className="pageSubHeading">Call History</h2>
                  <div className="callHistoryAccordionCnt">
                    <div className="callHistoryAccordionItem show">
                      <button className="callAccordionBtn">
                        <span>Call 03</span>
                        <img
                          src={backArrowInactive}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="inactiveIcon"
                        />
                        <img
                          src={backArrowNew}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="activeIcon"
                        />
                      </button>
                      <div className="callAccordionBody">
                        <ul className="callHistoryWrapper">
                          <li>
                            <span>Call 01</span>
                            <p>No Remarks</p>
                          </li>
                          <li>
                            <span>Status</span>
                            <p>NA</p>
                          </li>
                          <li>
                            <span>By</span>
                            <p>Dev Kapoor</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>Calling Mode</span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>Follow up on</span>
                            <p>NA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="callHistoryAccordionItem">
                      <button className="callAccordionBtn">
                        <span>Call 03</span>
                        <img
                          src={backArrowInactive}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="inactiveIcon"
                        />
                        <img
                          src={backArrowNew}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="activeIcon"
                        />
                      </button>
                      <div className="callAccordionBody">
                        <ul className="callHistoryWrapper">
                          <li>
                            <span>Call 01</span>
                            <p>No Remarks</p>
                          </li>
                          <li>
                            <span>Status</span>
                            <p>NA</p>
                          </li>
                          <li>
                            <span>By</span>
                            <p>Dev Kapoor</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>Calling Mode</span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>Follow up on</span>
                            <p>NA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="callHistoryAccordionItem">
                      <button className="callAccordionBtn">
                        <span>Call 03</span>
                        <img
                          src={backArrowInactive}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="inactiveIcon"
                        />
                        <img
                          src={backArrowNew}
                          width="12px"
                          height="12px"
                          alt="arrow icon"
                          className="activeIcon"
                        />
                      </button>
                      <div className="callAccordionBody">
                        <ul className="callHistoryWrapper">
                          <li>
                            <span>Call 01</span>
                            <p>No Remarks</p>
                          </li>
                          <li>
                            <span>Status</span>
                            <p>NA</p>
                          </li>
                          <li>
                            <span>By</span>
                            <p>Dev Kapoor</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>on</span>
                            <p>13th Feb, 2018</p>
                          </li>
                          <li>
                            <span>Calling Mode</span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>Follow up on</span>
                            <p>NA</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* second page start */}

        {/* footer section star */}
        <section className="footerCnt">
          <div className="container-40">
            <div className="footerWrapper">
              <button type="button" className="rfqCommonBtn">
                Create with SR
              </button>
              <button type="button" className="rfqCommonBtn bgBlue">
                Create RFQ
              </button>
            </div>
          </div>
        </section>
      </div>



      <div className="MobileHtml">
        <section className="customerInfoMainCnt">
          <div className="customerInfoEditable">
            <h1 className="pageSubHeading">Customer Info</h1>
            <div className="customerInfoCnt">
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Account</label>
                  <input
                    type="number"
                    id="name"
                    placeholder=""
                  />
                  <div className="traInputIconWrapper">
                    <img src={user_icon} width="20px" height="20px" />
                  </div>
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Mobile</label>
                  <input type="number" id="name" placeholder="" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" placeholder="" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name"> Email</label>
                  <input type="email" id="name" placeholder="" />
                </div>
                <div className="customerEmailCheck customerInfoItem">
                  <input
                    className="customerCheckLabelInput"
                    id="customerCheck"
                    type="checkbox"
                  />
                  <label className="customerCheckLabel" for="customerCheck">
                    Customer doesn't have an email
                  </label>
                </div>
              </div>
              <hr className="customeSelectCnt customerInfoItem border-seprator-customer" />

              {/* start agant in select option   */}
              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">Select Agent</label>
                    <span className="selectedValue">CEO</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  <div
                    className={`customDropDownCnt ${isOpen ? "show" : ""}`}
                  >
                    <ul className="dropdownWrapper">
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* start agant in  select option  */}

              <div className="customerInfoItem infoIconCnt">
                <div className="customeSelectCnt">
                  <button
                    className="customSelectBtn disabled"
                    style={{ cursor: "default" }}
                  >
                    <label htmlFor="name">Credit Finance</label>
                    <span className="selectedValue">2 Options Available</span>
                    <div
                      className="customSelectBtnIcon downArrowIcon"
                      onClick={showDropDown}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={infoIconGrey} width="20px" height="20px" />
                    </div>
                  </button>
                  <div
                    className={`creditFinaceDtl ${showDropdown ? "" : "d-none"
                      }`}
                  >
                    <div className="closeDropDown" onClick={closeDropDown}>
                      <img
                        src={crossBlue}
                        width="16px"
                        height="16px"
                        alt="cross icon"
                      />
                    </div>
                    <ul className="creditFinaceItemWrapper">
                      <li>Name</li>
                      <li>Rate</li>
                      <li>Amount</li>
                    </ul>
                    <hr className="hr-1 my-2" />
                    <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                      <li>Udaan (15 Days)</li>
                      <li>2.25%</li>
                      <li>â‚¹5,00,00,000</li>
                    </ul>
                    <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                      <li>Udaan (15 Days)</li>
                      <li>2.25%</li>
                      <li>â‚¹5,00,00,000</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCntRole customeSelectCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">Select Customer Role</label>
                    <span className="selectedValue">CEO</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  <div
                    className={`customDropDownCnt ${isOpen ? "show" : ""}`}
                  >
                    <ul className="dropdownWrapper">
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="customerInfoItem">
                <div className="traInputWrapper" >
                  <label htmlFor="name">Delivery Location test</label>
                  <input type="text" id="name" placeholder=""  />
                  <div className="traInputIconWrapper searchIcon">
                    <img src={searchIcon} width="20px" height="20px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="requirementDtlMobileCnt">
          <h2 className="pageSubHeading mb-3">Requirement Details</h2>
          <div className="requirementDtlCard">
            <div className="catalogueEditCnt">
              <p className="catalogueWrapper">Catalogue</p>
              <div className="editRemoveBtnCnt">
                <button className="editRemoveBtn">
                  <img src={editDot} alt="dot icon" width="24px" height="24px" />
                </button>
                <div className="editRemoveDropdown show">
                  <img src={tooltipArrowIcon} width="16px" height="10px" alt="arrow icon" className="arrowIconTooltip" />
                  <ul className="editRemoveWrapper">
                    <li>
                      <img src={editIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Edit</span>
                    </li>
                    <li>
                      <img src={deleteIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Remove</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="prodDisc">Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock for Residences Libra Offices Hospitals High Security Premises, Silver (2 Years Warranty)</p>
            <a href="javascript:void(0)" className="view_Btn">
              <img src={viewBtn} width="16px" height="16px" alt="redirect icon" />
              <span>View</span>
            </a>
            <div className="customerInfoNonEditable_mobile">
              <div className="customerInfoItem w-100">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Brand</label>
                  <input type="button" id="name" value="Ozone" />
                </div>
              </div>
            </div>
            <div className="customerInfoNonEditable_mobile">
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Quantity</label>
                  <input type="button" id="name" value="120" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Unit</label>
                  <input type="button" id="name" value="pcs" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Price</label>
                  <input type="button" id="name" value="₹222" />
                </div>
              </div>
            </div>
          </div>
          <div className="requirementDtlCard">
            <div className="catalogueEditCnt">
              <p className="catalogueWrapper">Attachment</p>
              <div className="editRemoveBtnCnt">
                <button className="editRemoveBtn">
                  <img src={editDot} alt="dot icon" width="24px" height="24px" />
                </button>
                <div className="editRemoveDropdown">
                  <img src={tooltipArrowIcon} width="16px" height="10px" alt="arrow icon" className="arrowIconTooltip" />
                  <ul className="editRemoveWrapper">
                    <li>
                      <img src={editIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Edit</span>
                    </li>
                    <li>
                      <img src={deleteIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Remove</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <a href="javascript:void(0)" className="attachmentBtn">attachment.pdf</a>
            </div>
          </div>
          <div className="addItemBtnCnt">
            <button className="addItemBtn">
              <img src={plusIcon} alt="add icon" width="24px" height="24px" />
              <span>Add Item</span>
            </button>
          </div>
        </section>
        {/* Edit Rfq Section start here For Mobile Only */}
        <section className="editRfqMobileCnt">
          <h2 className="pageSubHeading mb-3">SuperDesigns</h2>
          <div className="customerInfoCnt">
            <div className="customerInfoItem">
              <div className="traInputWrapper">
                <label htmlFor="name">Customer Mobile</label>
                <input type="number" id="name" placeholder="" value="9540495531" disabled className="disabled" />
                <div className="callLognBtnCntMobile">
                  <div className="calLogBtnWrapper">
                    <a href="javascript:void(0)" type="button" className="item">
                      <img
                        src={callIconBlue}
                        alt="call icon"
                        width="11px"
                        height="11px"
                      />
                      <span>Call</span>
                    </a>
                    <button type="button" className="item" onClick={handleShow3} >
                      Log a Call
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="customerInfoItem">
              <div className="traInputWrapper" >
                <label htmlFor="name">Delivery Location</label>
                <input type="text" id="name" placeholder="" value="Lajpat Nagar, Delhi 110007"/>
                <div className="traInputIconWrapper searchIcon">
                  <img src={searchIcon} width="20px" height="20px" />
                </div>
              </div>
            </div>
            <button onClick={handleShow6} type="button" className="moreInfoBtn">More Info <img width="12px" height="12px" src={RightArrowBlue} alt="rightBlu" /></button>
          </div>
        </section>
        <section className="requirementDtlMobileCnt">
          <h2 className="pageSubHeading mb-3">Requirement Details</h2>
          <div className="requirementDtlCard">
            <div className="catalogueEditCnt">
              <p className="catalogueWrapper">Catalogue</p>
              <div className="editRemoveBtnCnt">
                <button className="editRemoveBtn">
                  <img src={editDot} alt="dot icon" width="24px" height="24px" />
                </button>
                <div className="editRemoveDropdown">
                  <img src={tooltipArrowIcon} width="16px" height="10px" alt="arrow icon" className="arrowIconTooltip" />
                  <ul className="editRemoveWrapper">
                    <li>
                      <img src={editIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Edit</span>
                    </li>
                    <li>
                      <img src={deleteIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Remove</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="prodDisc">Ozone ODWL-03-NS STD SSS Narrow Style Smart Door Lock for Residences Libra Offices Hospitals High Security Premises, Silver (2 Years Warranty)</p>
            <a href="javascript:void(0)" className="view_Btn">
              <img src={viewBtn} width="16px" height="16px" alt="redirect icon" />
              <span>View</span>
            </a>
            <div className="customerInfoNonEditable_mobile">
              <div className="customerInfoItem w-100">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Brand</label>
                  <input type="button" id="name" value="Ozone" />
                </div>
              </div>
            </div>
            <div className="customerInfoNonEditable_mobile">
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Quantity</label>
                  <input type="button" id="name" value="120" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Unit</label>
                  <input type="button" id="name" value="pcs" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Price</label>
                  <input type="button" id="name" value="₹222" />
                </div>
              </div>
            </div>
          </div>
          <div className="requirementDtlCard">
            <div className="catalogueEditCnt">
              <p className="catalogueWrapper">Category</p>
              <div className="editRemoveBtnCnt">
                <button className="editRemoveBtn">
                  <img src={editDot} alt="dot icon" width="24px" height="24px" />
                </button>
                <div className="editRemoveDropdown">
                  <img src={tooltipArrowIcon} width="16px" height="10px" alt="arrow icon" className="arrowIconTooltip" />
                  <ul className="editRemoveWrapper">
                    <li>
                      <img src={editIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Edit</span>
                    </li>
                    <li>
                      <img src={deleteIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Remove</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="customerInfoNonEditable_mobile">
              <div className="customerInfoItem w-100">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Brand</label>
                  <input type="button" id="name" value="Ozone" />
                </div>
              </div>
            </div>
            <div className="customerInfoNonEditable_mobile">
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Quantity</label>
                  <input type="button" id="name" value="120" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Unit</label>
                  <input type="button" id="name" value="pcs" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="nonEditableInputWrapper">
                  <label htmlFor="name">Price</label>
                  <input type="button" id="name" value="₹222" />
                </div>
              </div>
            </div>
          </div>
          <div className="requirementDtlCard">
            <div className="catalogueEditCnt">
              <p className="catalogueWrapper">Attachment</p>
              <div className="editRemoveBtnCnt">
                <button className="editRemoveBtn">
                  <img src={editDot} alt="dot icon" width="24px" height="24px" />
                </button>
                <div className="editRemoveDropdown">
                  <img src={tooltipArrowIcon} width="16px" height="10px" alt="arrow icon" className="arrowIconTooltip" />
                  <ul className="editRemoveWrapper">
                    <li>
                      <img src={editIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Edit</span>
                    </li>
                    <li>
                      <img src={deleteIconWhite} width="16px" height="16ox" alt="edit icon" />
                      <span>Remove</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <a href="javascript:void(0)" className="attachmentBtn">attachment.pdf</a>
            </div>
          </div>
        </section>
        {/* Edit Rfq Section Ends here For Mobile only */}
        {/* footer section start */}
        <div className="rfqButtonCnt">
          <div className="rfqButtonWrapper">
            <button
              type="button"
              className="rfqCommonBtn"
              onClick={handleShow5}
            >
              Submit with SR
            </button>
            <button
              type="button"
              onClick={handleShow}
              className="rfqCommonBtn bgBlue"
            >
              Next
            </button>
          </div>
        </div>

        {/* {add item modal html start} */}
        <Modal
          className="modal-p-bottom addRfqModal"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="pageSubHeading">Add Item</Modal.Title>
            <button className="modalCloseBtn" onClick={handleClose}>
              <img
                src={CloseButton}
                width="24px"
                height="24px"
                alt="cross icon"
              />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAddItemsList">
              <div className="customerInfoItem">
                <div className="customeSelectCntRole customeSelectCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">Item Type</label>
                    <span className="selectedValue">Catalogue</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  <div
                    className={`customDropDownCnt ${isOpen ? "show" : ""}`}
                  >
                    <ul className="dropdownWrapper">
                      <li className="dropdownItem">COE</li>
                      <li className="dropdownItem">Designer</li>
                      <li className="dropdownItem">Developer</li>
                      <li className="dropdownItem">COE</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" value="Ozone ODWL-03-NS STD SSS Narrow Styl..." placeholder="" />
                </div>
              </div>

              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Brand</label>
                  <input
                    type="text"
                    id="name"
                    placeholder=""
                    disabled
                    className="disabled"
                    value="Ozone"
                  />
                </div>
              </div>

              <div className="customerInfoItem QuantityUnitPrice">
                <div className="traInputWrapper">
                  <label htmlFor="name">Quantity</label>
                  <input type="number" id="name" placeholder="" value="120"/>
                </div>

                <div className="traInputWrapper">
                  <label htmlFor="name">Unit</label>
                  <input
                    type="text"
                    id="name"
                    placeholder=""
                    disabled
                    className="disabled"
                    value="pcs"
                  />
                </div>

                <div className="traInputWrapper">
                  <label htmlFor="name">Price</label>
                  <input
                    type="text"
                    id="name"
                    placeholder=""
                    disabled
                    className="disabled"
                    value="₹222"
                  />
                </div>
              </div>
            </div>
            <div className="rfqButtonWrapper">
              <button
                type="button"
                onClick={handleShow1}
                className="rfqCommonBtn bgBlue"
              >
                Add Item
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* {add item html  end} */}

        {/* {Customer Info html start} */}
        <Modal
          className="modal-p-bottom addRfqModal"
          show={show5}
          onHide={handleClose5}
        >
          <Modal.Header>
            <Modal.Title className="pageSubHeading">Customer Info</Modal.Title>
            <button className="modalCloseBtn" onClick={handleClose5}>
              <img
                src={CloseButton}
                width="24px"
                height="24px"
                alt="cross icon"
              />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAddItemsList">
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Account</label>
                  <input
                    type="number"
                    id="name"
                    placeholder=""
                    disabled
                    value="SuperDesigns"
                    className="disabled"
                  />
                  <div className="traInputIconWrapper">
                    <img src={user_icon} width="20px" height="20px" />
                  </div>
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Name</label>
                  <input
                    type="number"
                    id="name"
                    placeholder=""
                    disabled
                    value="Nikhil Sharma"
                    className="disabled"
                  />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Email</label>
                  <input
                    type="email"
                    id="name"
                    placeholder=""
                    disabled
                    value="iamnikhil@mccoymart.com"
                    className="disabled"
                  />
                </div>
              </div>
              <div className="customerEmailCheck customerInfoItem">
                <input
                  className="customerCheckLabelInput"
                  id="customerCheck"
                  type="checkbox"
                />
                <label className="customerCheckLabel" for="customerCheck">
                  Customer doesn't have an email
                </label>
              </div>
              <div className="customerInfoItem infoIconCnt">
                <div className="customeSelectCnt">
                  <button
                    className="customSelectBtn disabled"
                    style={{ cursor: "default" }}
                  >
                    <label htmlFor="name">Credit Finance</label>
                    <span className="selectedValue">2 Options Available</span>
                    <div
                      className="customSelectBtnIcon downArrowIcon"
                      onClick={showDropDown}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={infoIconGrey} width="20px" height="20px" />
                    </div>
                  </button>
                  <div
                    className={`creditFinaceDtl ${showDropdown ? "" : "d-none"
                      }`}
                  >
                    <div className="closeDropDown" onClick={closeDropDown}>
                      <img
                        src={crossBlue}
                        width="16px"
                        height="16px"
                        alt="cross icon"
                      />
                    </div>
                    <ul className="creditFinaceItemWrapper">
                      <li>Name</li>
                      <li>Rate</li>
                      <li>Amount</li>
                    </ul>
                    <hr className="hr-1 my-2" />
                    <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                      <li>Udaan (15 Days)</li>
                      <li>2.25%</li>
                      <li>â‚¹5,00,00,000</li>
                    </ul>
                    <ul className="creditFinaceItemWrapper creditFinaceValueWrapper">
                      <li>Udaan (15 Days)</li>
                      <li>2.25%</li>
                      <li>â‚¹5,00,00,000</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Agent</label>
                  <input type="text" id="name" placeholder="" disabled value="Chetan" className="disabled" />
                </div>
              </div>
              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCnt">
                  <button
                    className={`customSelectBtn disabled`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">Customer Role</label>
                    <span className="selectedValue">Other Construction PRO</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Customer Message</label>
                  <input type="text" id="name" placeholder="" disabled value="" className="disabled" />
                </div>
              </div>
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Support Remarks</label>
                  <input type="text" id="name" placeholder="" disabled value="" className="disabled" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* {Customer Info html  end} */}

        {/* {Upload modal start} */}
        <Modal
          className="modal-p-bottom addRfqModal"
          show={show1}
          onHide={handleClose1}
        >
          <Modal.Header>
            <Modal.Title className="pageSubHeading">Add Item</Modal.Title>
            <button className="modalCloseBtn" onClick={handleShow2}>
              <img
                src={CloseButton}
                width="24px"
                height="24px"
                alt="cross icon"
              />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="modalAddItemsList">
              <div className="customeSelectCntRole customerInfoItem customeSelectCnt">
                <button
                  className={`customSelectBtn`}
                  onClick={toggleDropdown}
                >
                  <label htmlFor="name">Item Type</label>
                  <span className="selectedValue">Attachment</span>
                  <div className="customSelectBtnIcon downArrowIcon">
                    <img src={downArrowGrey} width="12px" height="12px" />
                  </div>
                </button>
                <div className={`uploadFileChoosenCnt`} >
                  <div className="uploadFileChoosenWrapper">
                    <div className="uploadFileChoosen">
                      <label for="downloadIcon" className="upload_title">
                        <div className="uploadFilebg">
                          <img
                            src={downloadIcon}
                            width="16px"
                            height="16px"
                          />
                          <span className="uploadFileTitle">Upload a file</span>
                        </div>
                        <p className="noFileChoosen">No file choosen</p>
                      </label>
                      <input
                        id="downloadIcon"
                        type="file"
                        className="downloadFile"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className="uploadFileChoosen">
                      <label for="downloadIcon" className="upload_title">
                        <div className="uploadFilebg">
                          <img
                            src={downloadIcon}
                            width="16px"
                            height="16px"
                          />
                          <span className="uploadFileTitle">Upload a file</span>
                        </div>
                        <p className="noFileChoosen attchmentFile">attachment.pdf</p>
                      </label>
                      <input
                        id="downloadIcon"
                        type="file"
                        className="downloadFile"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rfqButtonWrapper pt-3">
              <button type="button" className="rfqCommonBtn bgBlue">Add Item</button>
            </div>
          </Modal.Body>
        </Modal>
        {/* {Upload modal  end} */}

        {/*-===== Exit form modal for warning start ====*/}
        <Modal
          className="modal-p-bottom addRfqModal"
          show={show2}
          onHide={handleClose2}
        >
          <Modal.Body>
            <div className="warningMsgWrapper">
              <div className="warningMsgWrapperInner">
                <div>
                  <h2>Are your sure you want to exit?</h2>
                  <p>Your changes are not saved if you exit all the changes will be lost.</p>
                </div>
                <button className="modalCloseBtn" onClick={handleClose2}>
                  <img
                    src={CloseButton}
                    width="24px"
                    height="24px"
                    alt="cross icon"
                  />
                </button>
              </div>
              <div class="footerWrapper exitAnywayBtn">
                <button type="button" class="rfqCommonBtn" onClick={handleClose2}>Exit Anyways</button>
                <button type="button" class="rfqCommonBtn bgOrange">Continue</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/*====== Exit form for warning end   =====*/}


        {/*======== call Remark modal customer info  start ======*/}
        <Modal
          className="modal-p-bottom addRfqModal callReamrkModal"
          show={show3}
          onHide={handleClose3}
        >
          <Modal.Header>
            <Modal.Title className="pageSubHeading">Remarks on Call Attempt</Modal.Title>
            <button className="modalCloseBtn" onClick={handleClose3}>
              <img
                src={CloseButton}
                width="24px"
                height="24px"
                alt="cross icon"

              />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="modalAddItemsList">
              <div className="callReamrkItem">
                <div className="customeSelectCntRole customeSelectCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">Call Status</label>
                    <span className="selectedValue">Call Waiting</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  <div
                    className={`customDropDownCnt ${isOpen ? "show" : ""}`}
                  >
                    <ul className="dropdownWrapper">
                      <li className="dropdownItem">Call Droped</li>
                      <li className="dropdownItem">Call Recived</li>
                      <li className="dropdownItem">Call Picked</li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="callReamrkItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Call Back</label>
                  <input type="text" id="name" placeholder="" disabled />
                </div>
              </div>
              <div className="callReamrkItem">
                <div className="customeSelectCnt callReamrkDropCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">RFQ Status</label>
                    <span className="selectedValue">Pending</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img
                        src={downArrowGrey}
                        width="12px"
                        height="12px"
                      />
                    </div>
                  </button>
                  <div
                    className={`customDropDownCnt ${isOpen ? "show" : ""
                      }`}
                  >
                    <ul className="dropdownWrapper">
                      <li className="dropdownItem">Pending</li>
                      <li className="dropdownItem">Sucsess</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="callReamrkItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Remarks</label>
                  <input type="text" id="name" placeholder="" />
                </div>
              </div>
              <div className="callReamrkItem">
                <div className="customeSelectCnt callReamrkDropCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="name">Transfer Account</label>
                    <span className="selectedValue">Rahul</span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img
                        src={downArrowGrey}
                        width="12px"
                        height="12px"
                      />
                    </div>
                  </button>
                  <div
                    className={`customDropDownCnt ${isOpen ? "show" : ""
                      }`}
                  >
                    <ul className="dropdownWrapper">
                      <li className="dropdownItem">Rahul</li>
                      <li className="dropdownItem">Nikhil</li>
                      <li className="dropdownItem">Nasir</li>
                      <li className="dropdownItem">Irshad</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="callHistoryCnt">
                <h2 className="pageSubHeading">Call History</h2>
                <div className="callHistoryAccordionCnt">
                  <div className="callHistoryAccordionItem show">
                    <button className="callAccordionBtn">
                      <span>Call 03</span>
                      <img
                        src={backArrowInactive}
                        width="12px"
                        height="12px"
                        alt="arrow icon"
                        className="inactiveIcon"
                      />
                      <img
                        src={backArrowNew}
                        width="12px"
                        height="12px"
                        alt="arrow icon"
                        className="activeIcon"
                      />
                    </button>
                    <div className="callAccordionBody">
                      <ul className="callHistoryWrapper">
                        <li>
                          <span>Call 01</span>
                          <p>No Remarks</p>
                        </li>
                        <li>
                          <span>Status</span>
                          <p>NA</p>
                        </li>
                        <li>
                          <span>By</span>
                          <p>Dev Kapoor</p>
                        </li>
                        <li>
                          <span>on</span>
                          <p>13th Feb, 2018</p>
                        </li>
                        <li>
                          <span>on</span>
                          <p>13th Feb, 2018</p>
                        </li>
                        <li>
                          <span>Calling Mode</span>
                          <p>Manual</p>
                        </li>
                        <li>
                          <span>Follow up on</span>
                          <p>NA</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="callHistoryAccordionItem">
                    <button className="callAccordionBtn">
                      <span>Call 03</span>
                      <img
                        src={backArrowInactive}
                        width="12px"
                        height="12px"
                        alt="arrow icon"
                        className="inactiveIcon"
                      />
                      <img
                        src={backArrowNew}
                        width="12px"
                        height="12px"
                        alt="arrow icon"
                        className="activeIcon"
                      />
                    </button>
                    <div className="callAccordionBody">
                      <ul className="callHistoryWrapper">
                        <li>
                          <span>Call 01</span>
                          <p>No Remarks</p>
                        </li>
                        <li>
                          <span>Status</span>
                          <p>NA</p>
                        </li>
                        <li>
                          <span>By</span>
                          <p>Dev Kapoor</p>
                        </li>
                        <li>
                          <span>on</span>
                          <p>13th Feb, 2018</p>
                        </li>
                        <li>
                          <span>on</span>
                          <p>13th Feb, 2018</p>
                        </li>
                        <li>
                          <span>Calling Mode</span>
                          <p>Manual</p>
                        </li>
                        <li>
                          <span>Follow up on</span>
                          <p>NA</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="callHistoryAccordionItem">
                    <button className="callAccordionBtn">
                      <span>Call 03</span>
                      <img
                        src={backArrowInactive}
                        width="12px"
                        height="12px"
                        alt="arrow icon"
                        className="inactiveIcon"
                      />
                      <img
                        src={backArrowNew}
                        width="12px"
                        height="12px"
                        alt="arrow icon"
                        className="activeIcon"
                      />
                    </button>
                    <div className="callAccordionBody">
                      <ul className="callHistoryWrapper">
                        <li>
                          <span>Call 01</span>
                          <p>No Remarks</p>
                        </li>
                        <li>
                          <span>Status</span>
                          <p>NA</p>
                        </li>
                        <li>
                          <span>By</span>
                          <p>Dev Kapoor</p>
                        </li>
                        <li>
                          <span>on</span>
                          <p>13th Feb, 2018</p>
                        </li>
                        <li>
                          <span>on</span>
                          <p>13th Feb, 2018</p>
                        </li>
                        <li>
                          <span>Calling Mode</span>
                          <p>Manual</p>
                        </li>
                        <li>
                          <span>Follow up on</span>
                          <p>NA</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rfqButtonWrapper">
              <button
                type="button"
                className="rfqCommonBtn bgBlue"
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/*======== call Remark customer info  end   ======*/}

        {/*======== Delivery Location customer info  start ======*/}
        <Modal
          className="modal-p-bottom addRfqModal callReamrkModal deliveryLocationModal"
          show={show6}
          onHide={handleClose6}
        >
          <Modal.Header>
            <Modal.Title className="pageSubHeading">Delivery Location</Modal.Title>
            <button className="modalCloseBtn" onClick={handleClose6}>
              <img
                src={CloseButton}
                width="24px"
                height="24px"
                alt="cross icon"

              />
            </button>
          </Modal.Header>

          <Modal.Body>
          <div className="traInputWrapper">
                <label htmlFor="name">Lajpat Nagar</label>
                <input className="forDeliveryInput" type="text" id="name" placeholder="" />
                <div className="traInputIconWrapper searchIcon">
                  <img src={searchIcon} width="20px" height="20px" />
                </div>
              </div>
               <hr className="locationSeprator"/>
            <div className="modalAddItemsList">
              
               <div className="deliveryLocationList">
                  <ul>
                     <li>Lajpat Nagar Metro Station</li>
                     <li>Lajpat Nagar 1</li>
                     <li>Lajpat Nagar 2</li>
                     <li>Lajpat Nagar 3</li>
                     <li>Lajpat Nagar 4</li>
                  </ul>
               </div>
            </div>
          </Modal.Body>
        </Modal>
        {/*======== Delivery Location customer info  end   ======*/}
      </div>
    </>
  );
};

export default StaticPage;