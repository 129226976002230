import React, { useState, useEffect } from "react";
import "../../../asset/css/helvetica.css";
import "../../Static-page/static-page.css";
import user_icon from "../../../asset/Images/user_icon.svg";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import searchIcon from "../../../asset/Images/search-black.svg";
import infoIconGrey from "../../../asset/Images/infoIconGrey.svg";
import deleteIconWhite from "../../../asset/Images/deleteIconWhite.svg";
import tooltipArrowIcon from "../../../asset/Images/tooltipArrowIcon.svg";
import crossBlue from "../../../asset/Images/crossBlue.svg";
import viewBtn from "../../../asset/Images/viewBtn.svg";
import editDot from "../../../asset/Images/editDot.svg";
import editIconWhite from "../../../asset/Images/editIconWhite.svg";
import plusIcon from "../../../asset/Images/plusIcon.svg";
import downloadIcon from "../../../asset/Images/downloadIconNew.svg";
import CloseButton from "../../../asset/Images/CloseButton.svg";
import ErrorHandling from "../../ErrorHandling/ShowMessage";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Config";
import Modal from "react-bootstrap/Modal";
import { Redirect } from "react-router-dom";

const apiUrl = Constants.API_URL;

const CustomerInfoMobile = (props) => {
  const {
    searchAccount,
    showCustomerDropdown,
    suggestions,
    handleSelectAccount,
    setShowCustomerDropdown,
    handleAccountSearch,
    customer_mobile,
    getPhoneValue,
    customer_name,
    existingName,
    customer_email,
    existingEmail,
    customer_does_not_email,
    handleBarCustomerEmailCheckbox,
    customer_type,
    toggleDropdown,
    setIsOpen,
    isOpen,
    customerTypeArray,
    handleSelectCustomerRole,
    accountPlansData,
    showCreditFinance,
    closeCreditDropdown,
    openCreditDropdown,
    location,
    handleInputChange,
    locationList,
    handleSelectLocation,
    handleChange,
    //
    agent_type,
    isOpenAgent,
    agentData,
    handleSelectAgent,
    toggleAgentDropdown,
    setOpenAgent,
    showDropdown,
    agent_type_id,
    accountCompany,
    pincode,
    customer_id,
  } = props;

  const [products, setProducts] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [editableProduct, setEditableProduct] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [isOpenTypeDropdown, setIsOpenTypeDropdown] = useState(false);
  const [isImageLoading, setImageLoading] = useState("");
  const [imageIndex, setImageIndex] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [is_error, setIsError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [getRfqId, setGetRfqId] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [customer_ids, setCustomerIds] = useState("");
  const [is_redirect, setIsRedirect] = useState(false);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const [editProductData, setEditProductData] = useState(null);
  const [formStepper, setFormStepper] = useState(0);
  const [showDropdownList, setShowDropdownList] = useState(false);

  const handleFormStepper = (index) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation regex
    if (customer_mobile === "") {
      setErrorMessage("Please enter mobile number");
    } else if (!/^\d{10}$/.test(customer_mobile)) {
      setErrorMessage("Mobile number must be exactly 10 digits");
    } else if (customer_name === "") {
      setErrorMessage("Please enter customer Name");
    } else if (customer_email === "" && !customer_does_not_email) {
      setErrorMessage("Please enter customer email or check the box");
    } else if (!customer_email && customer_does_not_email === "") {
      setErrorMessage("Please check the box or enter customer email");
    } else if (customer_email && !emailRegex.test(customer_email)) {
      setErrorMessage("Please enter a valid email address");
    } else if (agent_type === "") {
      setErrorMessage("Please select Agent");
    } else if (customer_type === "") {
      setErrorMessage("Please select Customer Role");
    } else {
      setFormStepper(formStepper + 1);
      setOpenProductModal(true);
      handleAddItem();
      setOpenModalIndex((prevIndex) => (prevIndex === index ? null : index));
      return;
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  const handleOpenDotIcon = (index) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index ? { ...product, showProducts: true } : product
      )
    );
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // const handleOpenProductModal = (index) => {
  //   setOpenProductModal(true);
  //   // handleAddItem();
  //   handleAddRow();
  //   setOpenModalIndex((prevIndex) => (prevIndex === index ? null : index));
  // };

  const handleOpenProductModal = () => {
    setOpenProductModal(true);

    setProducts((prevProducts) => [
      ...prevProducts,
      {
        type: "product",
        name: "",
        quantity: "",
        brand: "",
        quantity_type: "",
        price: "",
        url: "",
        showProducts: false, // Ensure it's hidden until confirmed
        showCards: false,
      },
    ]);
    setOpenDropdownIndex(false);
    setTimeout(() => {
      setOpenModalIndex(products.length); // Open the modal for the newly added item
    }, 0);
  };

  const handleCloseProductModal = () => {
    setOpenProductModal(false);
  };

  const handleHideProducts = (index) => {
    setOpenProductModal(false);
    setEditableProduct(false);
    // setOpenModalIndex(null); // Reset index value

    setProducts((prevProducts) => {
      const product = prevProducts[index];

      if (product.type === "attachments") {
        return prevProducts; // Return all products as is, without any removal
      }

      // Check if any required field is empty
      if (
        !product?.name ||
        !product?.brand ||
        !product?.quantity ||
        !product?.quantity_type ||
        !product.showCards
      ) {
        return prevProducts.filter((_, i) => i !== index); // Remove item if any field is empty
      }

      return prevProducts; // Otherwise, return the same list
    });
    // setProducts([]);
  };

  // const handleAddItemClose = () => {
  //   handleCloseProductModal();
  //   setEditableProduct(true);
  // };

  const handleAddItemClose = (index) => {
    const enteredValue = products[index]?.name?.trim();
    const dataValue = products[index]?.type;
    if (dataValue === "product") {
      // Determine which suggestions list to use based on type
      const suggestions = productSuggestions;
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = suggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()
      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""), 3000);
        return;
      }
    }
    if (dataValue === "category") {
      // Determine which suggestions list to use based on type
      const suggestions = categorySuggestions;
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = suggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()
      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""), 3000);
        return;
      }
    }

    if (!validateProducts()) {
      return; // Stop execution if validation fails
    }
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index ? { ...product, showCards: true } : product
      )
    );
    setEditProductData({});
    handleCloseProductModal(); // Close the modal if validation passes
    setEditableProduct(false);
  };

  const handleAddItemClose1 = (index) => {
    const enteredValue = editProductData.name?.trim();
    const dataValue = editProductData.type;
    if (dataValue === "product") {
      // Determine which suggestions list to use based on type
      const suggestions = productSuggestions;
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = suggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()
      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""), 3000);
        return;
      }
    }
    if (dataValue === "category") {
      // Determine which suggestions list to use based on type
      const suggestions = categorySuggestions;
      // Check if the entered value exists in the selected suggestions list
      const isValidSelection = suggestions.some(
        (suggestion) => suggestion.label.trim() === enteredValue.trim()
      );
      if (!isValidSelection) {
        setErrorMessage(`Please select a name.`);
        setTimeout(() => setErrorMessage(""), 3000);
        return;
      }
    }

    if (!validateProduct1()) {
      return; // Stop execution if validation fails
    }
    let prods = { ...editProductData, showCards: true }
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? { 
            ...prods              
            }
          : product
      )
    );
    handleCloseProductModal(); // Close the modal if validation passes
    setEditableProduct(false);
    setEditProductData({});
  };

  // const handleAddItemClose = (index) => {
  //   // Ensure required fields are filled
  //  if (!validateProducts()) {
  //     return; // Stop execution if validation fails
  //   }

  const closeDropdown = (index) => {
    setIsOpenTypeDropdown((prev) => ({ ...prev, [index]: false }));
  };

  const handleProductChange = (index, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              type: "product",
              name: "",
              quantity: "",
              brand: "",
              name: "",
              quantity_type: "",
              price: "",
              url: "",
              showProducts: false,
              [field]: value, // Set only the selected field
            }
          : product
      )
    );
    closeDropdown(index);
    setIsOpenTypeDropdown(false);
  };

  const handleProductChange1 = (field, value) => {
    setEditProductData( {
              type: "product",
              name: "",
              quantity: "",
              brand: "",
              name: "",
              quantity_type: "",
              price: "",
              url: "",
              showProducts: false,
              [field]: value, // Set only the selected field
            }
    );
    // closeDropdown(index);
    setIsOpenTypeDropdown(false);
  };

  const handleProductAreaChange = (e, index) => {
    const inputValue = e.target.value;
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              name: inputValue,
              showProducts: true,
              link: "",
            }
          : product
      )
    );
    setActiveDropdownIndex(index); // Set active dropdown index when typing
    getValue(inputValue);
  };

  const handleProductAreaChange1 = (e) => {
    const inputValue = e.target.value;
    setEditProductData({
      ...editProductData,
      name: inputValue,
      showProducts: true,
      link: "",
    });
    getValue(inputValue);
  };

  const getValue = (inputValue) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    if (!inputValue) {
      setProductSuggestions([]); // Hide suggestions if input is empty
      return;
    }
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      data: { keyword: inputValue, status: "2" },
    })
      .then((data) => {
        setProductList(data.data.data);
        setProductSuggestions(
          data.data.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      })
      .catch((error) => console.log(error));
  };

  const handleProductSuggestionClick = (index, suggestion) => {
    let selectedProduct = productList.find(
      (el) => el.name === suggestion.value
    );
    if (selectedProduct) {
      setProducts((prevProducts) =>
        prevProducts.map((product, i) =>
          i === index
            ? {
                ...product,
                product_id: selectedProduct.product_id,
                category_id: selectedProduct.category_id
                  ? selectedProduct.category_id
                  : 0,
                name: selectedProduct.name, // Set the selected name
                price: `₹${Number(selectedProduct.price).toFixed(2)}`,
                quantity: product.productQuantity ? product.productQuantity : 1,
                quantity_type: selectedProduct.qty_type,
                brand: selectedProduct.brand,
                brand_id: selectedProduct.brand_id,
                url: selectedProduct.url,
                showProducts: false,
              }
            : product
        )
      );
      setActiveDropdownIndex(null); // Hide dropdown after selection
    }
    // Clear suggestions after selection
    setCategorySuggestions([]);
  };

  const handleProductSuggestionClick1 = (suggestion) => {
    let selectedProduct = productList.find(
      (el) => el.name === suggestion.value
    );
    if (selectedProduct) {
      setEditProductData({
                ...editProductData,
                product_id: selectedProduct.product_id,
                category_id: selectedProduct.category_id
                  ? selectedProduct.category_id
                  : 0,
                name: selectedProduct.name, // Set the selected name
                price: `₹${Number(selectedProduct.price).toFixed(2)}`,
                quantity: editProductData.productQuantity ? editProductData.productQuantity : 1,
                quantity_type: selectedProduct.qty_type,
                brand: selectedProduct.brand,
                brand_id: selectedProduct.brand_id,
                url: selectedProduct.url,
                showProducts: false,
              }
      );
      setActiveDropdownIndex(null); // Hide dropdown after selection
    }
    // Clear suggestions after selection
    setCategorySuggestions([]);
  };

  const handleTextAreaCategory = (e, index) => {
    const inputValue = e.target.value;
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              name: inputValue,
              showProducts: true,
              link: "",
            }
          : product
      )
    );
    setActiveDropdownIndex(index);
    getCategory(inputValue);
  };

  const handleTextAreaCategory1 = (e) => {
    const inputValue = e.target.value;
    setEditProductData({
              ...editProductData,
              name: inputValue,
              showProducts: true,
              link: "",
            }
    );
    // setActiveDropdownIndex(index);
    getCategory(inputValue);
  };

  const getCategory = (e) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails?.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: { keyword: e },
    })
      .then((data) => {
        setCategoryList(data.data.data);
        setCategorySuggestions(
          data.data.data.map((i) => ({ label: i.name, value: i.name }))
        );
      })
      .catch((error) => console.log(error));
  };

  const handleCategoryClick = (index, suggestion) => {
    let selectedProduct = categoryList.find(
      (el) => el.name === suggestion.value
    );
    if (!selectedProduct) return;

    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              name: selectedProduct.name,
              product_id: selectedProduct.product_id
                ? selectedProduct.product_id
                : 0,
              brand: product.brand,
              brand_id: selectedProduct.brand_id ? selectedProduct.brand_id : 0,
              category_id: selectedProduct.category_id
                ? selectedProduct.category_id
                : 0,
              quantity_type: product.quantity_type,
              quantity: product.productQuantity ? product.productQuantity : 1,
              price: `${
                isNaN(Number(selectedProduct.product_price))
                  ? ""
                  : Number(selectedProduct.product_price).toFixed(2)
              }`,
              url: selectedProduct.url,
              showProducts: false,
            }
          : product
      )
    );

    // setCategorySuggestions([]); // Clear dropdown after selection
    setActiveDropdownIndex(null); // Hide dropdown after selection
  };

  const handleCategoryClick1 = (suggestion) => {
    let selectedProduct = categoryList.find(
      (el) => el.name === suggestion.value
    );
    if (!selectedProduct) return;

    setEditProductData({
              ...editProductData,
              name: selectedProduct.name,
              product_id: selectedProduct.product_id
                ? selectedProduct.product_id
                : 0,
              brand: editProductData.brand,
              brand_id: selectedProduct.brand_id ? selectedProduct.brand_id : 0,
              category_id: selectedProduct.category_id
                ? selectedProduct.category_id
                : 0,
              quantity_type: editProductData.quantity_type,
              quantity: editProductData.productQuantity ? editProductData.productQuantity : 1,
              price: `${
                isNaN(Number(selectedProduct.product_price))
                  ? ""
                  : Number(selectedProduct.product_price).toFixed(2)
              }`,
              url: selectedProduct.url,
              showProducts: false,
            }
    );

    // setCategorySuggestions([]); // Clear dropdown after selection
    // setActiveDropdownIndex(null); // Hide dropdown after selection
  };

  const handleTextChange = (index, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index ? { ...product, [field]: value } : product
      )
    );
  };

  const handleTextChange1 = (field, value) => {
    setEditProductData({ ...editProductData, [field]: value });
  };

  const validateProducts = () => {
    let errorMessage = ""; // Initialize error message

    const isValid = products.every((item) => {
      switch (item.type) {
        case "attachments":
          if (!item.name?.trim()) {
            errorMessage = "Please upload Attachment.";
            return false;
          }
          return true;

        case "product":
          if (!item.name?.trim()) {
            errorMessage = "Name is required.";
            return false;
          }
          if (!item.quantity) {
            errorMessage = "Quantity is required.";
            return false;
          }
          if (item.quantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
            return false;
          }
          return true;

        case "category":
        case "other":
          if (!item.name?.trim()) {
            errorMessage = "Name is required.";
            return false;
          }
          if (!item.brand?.trim()) {
            errorMessage = "Brand is required.";
            return false;
          }
          if (!item.quantity) {
            errorMessage = "Quantity is required.";
            return false;
          }
          if (item.quantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
            return false;
          }
          if (!item.quantity_type) {
            errorMessage = "Unit type is required.";
            return false;
          }
          return true;

        default:
          errorMessage = "Invalid item type.";
          return false;
      }
    });

    if (!isValid) {
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(""), 3000);
    }

    return isValid;
  };

  const validateProduct1 = () => {
    let errorMessage = ""; // Initialize error message
  
    if (!editProductData || typeof editProductData !== "object") {
      errorMessage = "Invalid product data.";
    } else {
      switch (editProductData.type) {
        case "attachments":
          if (!editProductData.name?.trim()) {
            errorMessage = "Please upload Attachment.";
          }
          break;
  
        case "product":
          if (!editProductData.name?.trim()) {
            errorMessage = "Name is required.";
          } else if (!editProductData.quantity) {
            errorMessage = "Quantity is required.";
          } else if (editProductData.quantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
          }
          break;
  
        case "category":
        case "other":
          if (!editProductData.name?.trim()) {
            errorMessage = "Name is required.";
          } else if (!editProductData.brand?.trim()) {
            errorMessage = "Brand is required.";
          } else if (!editProductData.quantity) {
            errorMessage = "Quantity is required.";
          } else if (editProductData.quantity <= 0) {
            errorMessage = "Quantity must be greater than 0.";
          } else if (!editProductData.quantity_type) {
            errorMessage = "Unit type is required.";
          }
          break;
  
        default:
          errorMessage = "Invalid product type.";
      }
    }
  
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(""), 3000);
      return false;
    }
  
    return true;
  };
  
  
  const handleAddRow = () => {
    const newProduct = {
      type: "product",
      name: "",
      brand: "",
      quantity: "",
      quantity_type: "",
      price: "",
      showProducts: false,
    };

    setProducts((prevProducts) => [...prevProducts, newProduct]);
  };

  // Button Click Handler
  // const handleAddItem = () => {
  //   setOpenProductModal(true);
  //   // handleAddRow();
  //   if (validateProducts()) {
  //     handleAddRow();
  //   } else {
  //     handleAddItemClose()
  //   }
  // };
  const handleAddItem = () => {
    setOpenProductModal(true);
    if (!validateProducts()) {
      return; // Stop execution if validation fails
    }
    handleAddRow();
    setOpenDropdownIndex(false);
    // setOpenProductModal(false);
  };

  const validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };

  const onFileChange = async (index, event) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const file = event.target.files[0];
    if (!file) return;

    const allowedImage = validateFile(file);
    if (!allowedImage) {
      setImageLoading(false);
      setImageIndex(null);
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    setImageLoading(true);
    setImageIndex(index);
    try {
      const response = await axios.post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginedUserDetails?.token}`,
        },
      });

      const imageUrl = response.data.data.image_url;

      // setFileNames((prevFileNames) => [...prevFileNames, file.name]);

      setProducts((prevProducts) =>
        prevProducts.map((product, i) =>
          i === index
            ? {
                ...product,
                name: imageUrl,
                product_id: 0,
                category_id: 0,
                price: product.price,
                quantity: product.productQuantity,
                quantity_type: product.quantity_type,
                brand: product.brand,
                brand_id: 0,
                link: "",
              }
            : product
        )
      );

      setImageLoading(false);
      setImageIndex(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setImageLoading(false);
      setImageIndex(null);
    }
  };

  const onFileChange1 = async (event) => {
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const file = event.target.files[0];
    if (!file) return;

    const allowedImage = validateFile(file);
    if (!allowedImage) {
      setImageLoading(false);
      setImageIndex(null);
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    setImageLoading(true);
    // setImageIndex(index);
    try {
      const response = await axios.post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginedUserDetails?.token}`,
        },
      });

      const imageUrl = response.data.data.image_url;

      // setFileNames((prevFileNames) => [...prevFileNames, file.name]);

      // setProducts((prevProducts) =>
      //   prevProducts.map((product, i) =>
      //     i === index
      //       ? {
      //           ...product,
      //           name: imageUrl,
      //           product_id: 0,
      //           category_id: 0,
      //           price: product.price,
      //           quantity: product.productQuantity,
      //           quantity_type: product.quantity_type,
      //           brand: product.brand,
      //           brand_id: 0,
      //           link: "",
      //         }
      //       : product
      //   )
      // );
      setEditProductData({
        ...editProductData,
        name: imageUrl,
        product_id: editProductData.product_id
          ? editProductData.product_id
          : 0,
        brand: editProductData.brand,
        brand_id: editProductData.brand_id ? editProductData.brand_id : 0,
        category_id: editProductData.category_id
          ? editProductData.category_id
          : 0,
        quantity_type: editProductData.quantity_type,
        quantity: editProductData.productQuantity ? editProductData.productQuantity : 1,
        price: `${
          isNaN(Number(editProductData.product_price))
            ? ""
            : Number(editProductData.product_price).toFixed(2)
        }`,
        url: editProductData.url,
        showProducts: false,
      }
);

      setImageLoading(false);
      setImageIndex(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setImageLoading(false);
      setImageIndex(null);
    }
  };

  const handleTextAreaOther = (e, index) => {
    const inputValue = e.target.value;
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index
          ? {
              ...product,
              name: inputValue,
              product_id: 0,
              category_id: 0,
              productPrice: "",
              quantity: product.quantity ? product.quantity : 1,
              quantity_type: product.quantity_type,
              brand: product.brand,
              brand_id: 0,
              url: "",
            }
          : product
      )
    );
  };

  const handleTextAreaOther1 = (e) => {
    const inputValue = e.target.value;
    setEditProductData( {
              ...editProductData,
              name: inputValue,
              product_id: 0,
              category_id: 0,
              productPrice: "",
              quantity: editProductData.quantity ? editProductData.quantity : 1,
              quantity_type: editProductData.quantity_type,
              brand: editProductData.brand,
              brand_id: 0,
              url: "",
            }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitType = e.target.id;

    setButtonDisabled(true);
    setGetRfqId(submitType);
    setSubmitLoading(true);
    // setValidated(true);

    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails?.token;

    const nameSet = new Set();
    let duplicateName = null;

    for (const item of products) {
      if (nameSet.has(item.name)) {
        duplicateName = item.name;
        break;
      }
      nameSet.add(item.name);
    }

    // Show error message if duplicate product name found
    if (duplicateName) {
      setErrorMessage(`Duplicate product not allowed.`);
      setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
      setButtonDisabled(false);
      setSubmitLoading(false);
      return;
    }

    // Extract state values
    const productsData = products.map(
      ({
        showProducts,
        itemQuantity,
        price,
        url,
        categoryUrl,
        ...filteredData
      }) => ({
        brand: filteredData.brand,
        brand_id: filteredData.brand_id,
        category_id: filteredData.category_id,
        name: filteredData.name,
        product_id: filteredData.product_id,
        quantity:
          filteredData.type === "attachments"
            ? ""
            : Number(filteredData.quantity) || 0,
        quantity_type: filteredData.quantity_type,
        type: filteredData.type,
      })
    );
    const rfq_params = {
      customer_mobile: Number(customer_mobile),
      customer_email: customer_email,
      rfq_type: "Sales Outbound Call",
      subTotal: 0,
      source: "CRM",
      image_data: "",
      attachments: "",
      created_by: loginedUserDetails?.user_id,
      campaign: "",
      utmSource: "sales",
      utmMedium: "outbound",
      isCustomerEmail: customer_does_not_email,
      shippingCharge: 0,
      total: 0,
      assigned_to: agent_type_id,
    };

    const body_params = {
      customer_id: customer_id,
      customer_name: customer_name,
      products: productsData,
      location,
      location_id: "",
      pincode: pincode,
      role: customer_type,
      lead_id: 0,
    };

    try {
      const res = await axios.post(
        apiUrl + "/create-rfq",
        { ...body_params, ...rfq_params },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const customerIdRes = res.data.data.customer_id;
      const RfqId = res.data.data.rfqId;

      const sr_params = {
        mobile: customer_mobile,
        email: customer_email,
        utm_source: "sales",
        utm_medium: "outbound",
        utm_campaign: "",
        utm_content: "",
        sales_remarks: "",
        account_name: searchAccount ? searchAccount : accountCompany,
        customer_id: customerIdRes,
        rfq_id: RfqId,
      };

      if (submitType === "createRfqSr") {
        body_params.rfq_id = RfqId;

        await axios.post(
          apiUrl + "/create-source-request",
          { ...body_params, ...sr_params },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setSuccessMessage("RFQ & SR Created Successfully!");
        setButtonDisabled(true);
        setIsError(false);
        setSubmitLoading(false);
        setCustomerIds(customerIdRes);
      } else {
        setSuccessMessage("RFQ Created Successfully!");
        setButtonDisabled(true);
        setIsError(false);
        setSubmitLoading(false);
        setCustomerIds(customerIdRes);
      }

      setTimeout(() => {
        setSuccessMessage("");
        setIsError(false);
        setIsRedirect(true);
        setButtonDisabled(true);
      }, 500);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred.");
      setIsError(true);
      setSubmitLoading(false);
      setButtonDisabled(false);

      setTimeout(() => {
        setErrorMessage("");
        setIsError(false);
      }, 4000);
    }
  };

  const editProducts = (index) => {
    setOpenProductModal(true);
    setOpenModalIndex(index);
    setEditProductData(products[index]);
    setOpenDropdownIndex(false);
    setEditableProduct(true);
  };

  const removeItem = (index) => {
    setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const toggleTypeDropdown = (index) => {
    setIsOpenTypeDropdown((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleBlurDropdown = () => {
    setIsOpenTypeDropdown(false);
  };

  if (is_redirect && getRfqId === "createRfq") {
    return <Redirect to="/quote-requests" />;
  } else if (is_redirect && getRfqId === "createRfqSr") {
    return <Redirect to="/quote-requests" />;
  }

  return (
    <>
      {errorMessage ? (
        <ErrorHandling message={errorMessage} type={"ErrorMessage"} />
      ) : null}
      {successMessage ? (
        <ErrorHandling message={successMessage} type={"SuccessMessage"} />
      ) : null}
      {formStepper === 0 ? (
        <section className="customerInfoMainCnt">
          <div className="customerInfoEditable">
            <h1 className="pageSubHeading">Customer Info</h1>
            <div className="customerInfoCnt">
              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Account</label>
                  {/* <input type="number" id="name" placeholder="" /> */}
                  <input
                    type="text"
                    name="serachAccount"
                    value={searchAccount}
                    onChange={handleAccountSearch}
                    placeholder=""
                    style={{ paddingRight: "40px" }}
                    onFocus={() => setShowCustomerDropdown(true)}
                    onBlur={() => setShowCustomerDropdown(false)}
                    autoComplete="off"
                    required
                  />
                  <div className="traInputIconWrapper">
                    <img src={user_icon} width="20px" height="20px" />
                  </div>
                  {/* Dropdown Suggestions */}
                  {showCustomerDropdown &&
                    suggestions.length > 0 &&
                    searchAccount.length > 0 && (
                      <div className={`customDropDownCnt ${searchAccount.length > 0 && "show"}`}>
                        <ul
                          className="dropdownWrapper"
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {suggestions.map((account) => (
                            <li
                              key={account.account_id}
                              onMouseDown={() => handleSelectAccount(account)}
                              className="dropdownItem"
                            >
                              <strong>{account.company_name}</strong>,{" "}
                              {account.city_name}, {account.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </div>
              </div>

              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">
                    Mobile
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="number"
                    name="customer_mobile"
                    value={customer_mobile}
                    onChange={getPhoneValue}
                    disabled={searchAccount}
                    placeholder=""
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">
                    Name
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="customer_name"
                    value={customer_name}
                    onChange={handleChange}
                    disabled={existingName || searchAccount}
                    required
                    placeholder=""
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name"> Email</label>
                  <input
                    type="email"
                    name="customer_email"
                    onChange={handleChange}
                    value={customer_email}
                    disabled={existingEmail || searchAccount}
                    placeholder=""
                    required={!customer_does_not_email ? false : true}
                    autoComplete="off"
                  />
                </div>
                <div className="customerEmailCheck customerInfoItem">
                  <input
                    type="checkbox"
                    className="customerCheckLabelInput"
                    onChange={handleBarCustomerEmailCheckbox}
                  />
                  <label className="customerCheckLabel" for="customerCheck">
                    Customer doesn't have an email
                  </label>
                </div>
              </div>
              <hr className="customeSelectCnt customerInfoItem border-seprator-customer" />

              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCnt">
                  <button
                    className={`${
                      agent_type &&
                      agent_type !== "Select" &&
                      agentData.filter(({ name }) => name === agent_type)
                        .length === 0
                        ? "customSelectBtn disabled"
                        : "customSelectBtn"
                    }`}
                    onClick={toggleAgentDropdown}
                    onBlur={() => setOpenAgent(false)}
                    name="agent_type"
                    disabled={
                      agent_type &&
                      agent_type !== "Select" &&
                      agentData.filter(({ name }) => name === agent_type)
                        .length === 0
                    }
                  >
                    <label htmlFor="name">
                      Agent
                      <span class="mandatory">*</span>
                    </label>
                    <span className="selectedValue">
                      {agent_type ? agent_type : "Select"}
                    </span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>

                  <div
                    className={`customDropDownCnt ${isOpenAgent ? "show" : ""}`}
                  >
                    <ul
                      className="dropdownWrapper"
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {agentData.map((item) => {
                        return (
                          <li
                            className="dropdownItem"
                            key={item.id}
                            onClick={() =>
                              handleSelectAgent(item.id, item.name)
                            }
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="customerInfoItem infoIconCnt">
                <div className="customeSelectCnt">
                  <button
                    className="customSelectBtn disabled"
                    style={{ cursor: "default" }}
                  >
                    <label htmlFor="name">Credit Finance</label>
                    <span className="selectedValue">
                      {Array.isArray(accountPlansData) &&
                      accountPlansData.length > 0
                        ? `${accountPlansData.length}`
                        : "No"}{" "}
                      Options Available
                    </span>
                    <div
                      className="customSelectBtnIcon downArrowIcon"
                      onClick={openCreditDropdown}
                      style={{ cursor: "pointer" }}
                    >
                      {Array.isArray(accountPlansData) &&
                      accountPlansData.length > 0 ? (
                        <img src={infoIconGrey} width="20px" height="20px" />
                      ) : null}
                    </div>
                  </button>
                  {accountPlansData && accountPlansData.length ? (
                    <div
                      className={`creditFinaceDtl ${
                        showCreditFinance ? "" : "d-none"
                      }`}
                    >
                      <div
                        className="closeDropDown"
                        onClick={closeCreditDropdown}
                      >
                        <img
                          src={crossBlue}
                          width="16px"
                          height="16px"
                          alt="cross icon"
                        />
                      </div>
                      <ul className="creditFinaceItemWrapper">
                        <li>Name</li>
                        <li>Rate</li>
                        <li>Amount</li>
                      </ul>
                      <hr className="hr-1 my-2" />
                      {accountPlansData.map((accountPlan) => (
                        <ul
                          key={accountPlan.plan_id}
                          className="creditFinaceItemWrapper creditFinaceValueWrapper"
                        >
                          <li>
                            {accountPlan.partner_name} ({accountPlan.plan_days}{" "}
                            Days)
                          </li>
                          <li>{accountPlan.plan_interest} %</li>
                          <li>
                            ₹
                            {Number(accountPlan.credit_limit).toLocaleString(
                              "en-IN"
                            )}
                          </li>
                        </ul>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="customerInfoItem customeSelectCnt">
                <div className="customeSelectCntRole customeSelectCnt">
                  <button
                    className={`customSelectBtn`}
                    onClick={toggleDropdown}
                    onBlur={() => setIsOpen(false)}
                    name="customer_type"
                  >
                    <label htmlFor="name">
                      Select Customer Role
                      <span class="mandatory">*</span>
                    </label>
                    <span className="selectedValue">
                      {customer_type ? customer_type : "Select"}
                    </span>
                    <div className="customSelectBtnIcon downArrowIcon">
                      <img src={downArrowGrey} width="12px" height="12px" />
                    </div>
                  </button>
                  <div className={`customDropDownCnt ${isOpen ? "show" : ""}`}>
                    <ul
                      className="dropdownWrapper"
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {customerTypeArray.map((item) => {
                        return (
                          <li
                            className="dropdownItem"
                            key={item.id}
                            value={item.meta_value}
                            onClick={() =>
                              handleSelectCustomerRole(item.meta_value)
                            }
                          >
                            {item.meta_title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="customerInfoItem">
                <div className="traInputWrapper">
                  <label htmlFor="name">Delivery Location</label>
                  <input
                    type="text"
                    id="location"
                    placeholder=""
                    value={location}
                    onChange={handleInputChange}
                    autoComplete="off"
                    style={{ paddingRight: "44px" }}
                    required
                  />
                  <div className="traInputIconWrapper searchIcon">
                    <img src={searchIcon} width="20px" height="20px" />
                  </div>
                  {showDropdown && location.length > 0 && (
                    <div className="customDropDownCnt show">
                      <ul className="dropdownWrapper">
                        {locationList.map((location, index) => (
                          <li
                            className="dropdownItem"
                            key={index}
                            onClick={() => handleSelectLocation(location)}
                          >
                            {location}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rfqButtonCnt">
            <div className="rfqButtonWrapper">
              <button
                type="button"
                onClick={() => handleFormStepper(products.length)}
                className="rfqCommonBtn bgBlue"
              >
                Next
              </button>
            </div>
          </div>
        </section>
      ) : null}

      {formStepper === 1 ? (
        <>
          <section className="requirementDtlMobileCnt">
            <h2 className="pageSubHeading mb-3">Requirement Details</h2>
            {products.map((item, index) => (
              <React.Fragment key={index}>
                {item.showCards ? (
                  <>
                    {item.type != "attachments" ? (
                      <div className="requirementDtlCard">
                        <div className="catalogueEditCnt">
                          <p className="catalogueWrapper">
                            {item.type === "product"
                              ? "Catalogue"
                              : item.type === "category"
                              ? "Category"
                              : item.type === "other"
                              ? "Other"
                              : item.type === "attachments"
                              ? "Attachment"
                              : item.type}
                          </p>
                          <div className="editRemoveBtnCnt">
                            <button className="editRemoveBtn">
                              <img
                                src={editDot}
                                alt="dot icon"
                                width="24px"
                                height="24px"
                                onClick={() => handleOpenDotIcon(index)}
                              />
                            </button>

                            {openDropdownIndex === index &&
                            item.showProducts ? (
                              <div className="editRemoveDropdown show">
                                <img
                                  src={tooltipArrowIcon}
                                  width="16px"
                                  height="10px"
                                  alt="arrow icon"
                                  className="arrowIconTooltip"
                                />
                                <ul className="editRemoveWrapper">
                                  <li onClick={() => editProducts(index)}>
                                    <img
                                      src={editIconWhite}
                                      width="16px"
                                      height="16ox"
                                      alt="edit icon"
                                    />
                                    <span>Edit</span>
                                  </li>
                                  <li onClick={() => removeItem(index)}>
                                    <img
                                      src={deleteIconWhite}
                                      width="16px"
                                      height="16ox"
                                      alt="edit icon"
                                    />
                                    <span>Remove</span>
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <p className="prodDisc">{item.name}</p>
                        {(item.type === "product" ||
                          item.type === "category") &&
                        item.url ? (
                          <a
                            href={item.url}
                            target="_blank"
                            className="view_Btn"
                          >
                            <img
                              src={viewBtn}
                              width="16px"
                              height="16px"
                              alt="redirect icon"
                            />
                            <span>View</span>
                          </a>
                        ) : null}
                        <div className="customerInfoNonEditable_mobile">
                          <div className="customerInfoItem w-100">
                            <div className="nonEditableInputWrapper">
                              <label htmlFor="name">Brand</label>
                              <input
                                type="button"
                                id="name"
                                value={item.brand}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="customerInfoNonEditable_mobile">
                          <div className="customerInfoItem">
                            <div className="nonEditableInputWrapper">
                              <label htmlFor="name">Quantity</label>
                              <input
                                type="button"
                                id="name"
                                value={item.quantity}
                              />
                            </div>
                          </div>
                          <div className="customerInfoItem">
                            <div className="nonEditableInputWrapper">
                              <label htmlFor="name">Unit</label>
                              <input
                                type="button"
                                id="name"
                                value={item.quantity_type}
                              />
                            </div>
                          </div>
                          <div className="customerInfoItem">
                            <div className="nonEditableInputWrapper">
                              <label htmlFor="name">Price</label>
                              <input
                                type="button"
                                id="name"
                                value={item.price}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {item.showCards ? (
                  <>
                    {item.type === "attachments" ? (
                      <div className="requirementDtlCard">
                        <div className="catalogueEditCnt">
                          <p className="catalogueWrapper">Attachment</p>
                          <div className="editRemoveBtnCnt">
                            <button className="editRemoveBtn">
                              <img
                                src={editDot}
                                alt="dot icon"
                                width="24px"
                                height="24px"
                                onClick={() => handleOpenDotIcon(index)}
                              />
                            </button>
                            {openDropdownIndex === index &&
                            item.showProducts ? (
                              <div className="editRemoveDropdown show">
                                <img
                                  src={tooltipArrowIcon}
                                  width="16px"
                                  height="10px"
                                  alt="arrow icon"
                                  className="arrowIconTooltip"
                                />
                                <ul className="editRemoveWrapper">
                                  <li onClick={() => editProducts(index)}>
                                    <img
                                      src={editIconWhite}
                                      width="16px"
                                      height="16ox"
                                      alt="edit icon"
                                    />
                                    <span>Edit</span>
                                  </li>
                                  <li onClick={() => removeItem(index)}>
                                    <img
                                      src={deleteIconWhite}
                                      width="16px"
                                      height="16ox"
                                      alt="edit icon"
                                    />
                                    <span>Remove</span>
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <a
                            href={item.name}
                            target="_blank"
                            className="attachmentBtn"
                          >
                            attachment.pdf
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                <div>
                  <Modal
                    className="modal-p-bottom addRfqModal"
                    show={openProductModal && openModalIndex === index}
                    onHide={handleCloseProductModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title className="pageSubHeading">
                        {editableProduct ? "Edit Item" : "Add Item"}
                      </Modal.Title>
                      <button
                        className="modalCloseBtn"
                        // onClick={handleCloseProductModal}
                      >
                        <img
                          src={CloseButton}
                          width="24px"
                          height="24px"
                          alt="cross icon"
                          onClick={() => handleHideProducts(index)}
                        />
                      </button>
                    </Modal.Header>
                    {editableProduct ? (
                      <Modal.Body>
                        {editProductData.type === "product" ? (
                          <div className="modalAddItemsList">
                            <div className="customerInfoItem">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {editProductData.type === "product"
                                      ? "Catalogue"
                                      : editProductData.type === "category"
                                      ? "Category"
                                      : editProductData.type === "other"
                                      ? "Other"
                                      : editProductData.type === "attachments"
                                      ? "Attachment"
                                      : editProductData.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange1(
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Name</label>
                                <input
                                  placeholder=""
                                  type="text"
                                  value={editProductData.name}
                                  onChange={(e) =>
                                    handleProductAreaChange1(e)
                                  }
                                  // onFocus={() => setActiveDropdownIndex(index)}
                                  onFocus={() => {
                                    setActiveDropdownIndex(index);
                                    setShowDropdownList(true);
                                  }}
                                  onBlur={() => setShowDropdownList(false)}
                                />
                                {productSuggestions.length > 0 &&
                                  editProductData.showProducts &&
                                  showDropdownList &&
                                  activeDropdownIndex === index && (
                                    <div
                                      className="customDropDownCnt show"
                                      key={index}
                                      style={{position:"static",marginTop:"8px"}}
                                    >
                                      <ul
                                        className="dropdownWrapper"
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {productSuggestions.map(
                                          (suggestion, idx) => (
                                            <li
                                              key={idx}
                                              className="dropdownItem"
                                              onClick={() =>
                                                handleProductSuggestionClick1(
                                                  suggestion
                                                )
                                              }
                                            >
                                              {suggestion.label}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Brand</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="brand"
                                  value={editProductData.brand}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "brand",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem QuantityUnitPrice">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Quantity</label>
                                <input
                                  placeholder=""
                                  type="number"
                                  name="quantity"
                                  value={editProductData.quantity}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Unit</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={editProductData.quantity_type}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "quantity_type",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    editProductData.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Price</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product" ||
                                    editProductData.type == "category" ||
                                    editProductData.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="productPrice"
                                  value={editProductData.price}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "productPrice",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    editProductData.type == "product" ||
                                    editProductData.type == "category" ||
                                    editProductData.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {editProductData.type === "category" ? (
                          <div className="modalAddItemsList">
                            <div className="customerInfoItem">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {editProductData.type === "product"
                                      ? "Catalogue"
                                      : editProductData.type === "category"
                                      ? "Category"
                                      : editProductData.type === "other"
                                      ? "Other"
                                      : editProductData.type === "attachments"
                                      ? "Attachment"
                                      : editProductData.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange1(
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  value={editProductData.name}
                                  onChange={(e) =>
                                    handleTextAreaCategory1(e)
                                  }
                                  // onFocus={() => setActiveDropdownIndex(index)}
                                  onFocus={() => {
                                    setActiveDropdownIndex(index);
                                    setShowDropdownList(true);
                                  }}
                                  // onBlur={() => setActiveDropdownIndex(false)}
                                  onBlur={() => setShowDropdownList(false)}
                                />
                                {categorySuggestions.length > 0 &&
                                  editProductData.showProducts &&
                                  showDropdownList &&
                                  activeDropdownIndex === index && (
                                    <div className="customDropDownCnt show"
                                      style={{position:"static",marginTop:"8px"}}
                                    >
                                      <ul
                                        className="dropdownWrapper"
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {categorySuggestions.map(
                                          (suggestion, idx) => (
                                            <li
                                              key={suggestion.value}
                                              className="dropdownItem"
                                              onClick={() =>
                                                handleCategoryClick1(
                                                  suggestion
                                                )
                                              }
                                            >
                                              {suggestion.label}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Brand</label>
                                <input
                                  type="text"
                                  placeholder=""
                                  name="brand"
                                  value={editProductData.brand}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "brand",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem QuantityUnitPrice">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Quantity</label>
                                <input
                                  placeholder=""
                                  type="number"
                                  name="quantity"
                                  value={editProductData.quantity}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Unit</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={editProductData.quantity_type}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "quantity_type",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    editProductData.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Price</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product" ||
                                    editProductData.type == "category" ||
                                    editProductData.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="productPrice"
                                  value={editProductData.price}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "productPrice",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    editProductData.type == "product" ||
                                    editProductData.type == "category" ||
                                    editProductData.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {editProductData.type === "other" ? (
                          <div className="modalAddItemsList">
                            <div className="customerInfoItem">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {editProductData.type === "product"
                                      ? "Catalogue"
                                      : editProductData.type === "category"
                                      ? "Category"
                                      : editProductData.type === "other"
                                      ? "Other"
                                      : editProductData.type === "attachments"
                                      ? "Attachment"
                                      : editProductData.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange1(
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Name</label>
                                <input
                                  rows={1}
                                  className="textEditableInput"
                                  type="text"
                                  placeholder=""
                                  value={editProductData.name}
                                  onChange={(e) =>
                                    handleTextAreaOther1(e, index)
                                  }
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Brand</label>
                                <input
                                  type="text"
                                  placeholder=""
                                  name="brand"
                                  value={editProductData.brand}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "brand",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem QuantityUnitPrice">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Quantity</label>
                                <input
                                  placeholder=""
                                  type="number"
                                  name="quantity"
                                  value={editProductData.quantity}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Unit</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={editProductData.quantity_type}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "quantity_type",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    editProductData.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Price</label>
                                <input
                                  type="text"
                                  className={`${
                                    editProductData.type === "product" ||
                                    editProductData.type == "category" ||
                                    editProductData.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="productPrice"
                                  value={editProductData.price}
                                  onChange={(e) =>
                                    handleTextChange1(
                                      "productPrice",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    editProductData.type == "product" ||
                                    editProductData.type == "category" ||
                                    editProductData.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {editProductData.type == "attachments" ? (
                          <div className="modalAddItemsList">
                            <div className="customeSelectCntRole customerInfoItem customeSelectCnt">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {editProductData.type === "product"
                                      ? "Catalogue"
                                      : editProductData.type === "category"
                                      ? "Category"
                                      : editProductData.type === "other"
                                      ? "Other"
                                      : editProductData.type === "attachments"
                                      ? "Attachment"
                                      : editProductData.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange1(
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                              <div className={`uploadFileChoosenCnt`}>
                                <div className="uploadFileChoosenWrapper">
                                  {editProductData.name ? (
                                    <div className="uploadFileChoosen">
                                      <a
                                        className="noFileChoosen attchmentFile"
                                        href={editProductData.name}
                                        target="_blank"
                                      >
                                        Attachment
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="uploadFileChoosen">
                                      {isImageLoading &&
                                      imageIndex === index ? (
                                        <Spinner
                                          animation="border"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </Spinner>
                                      ) : (
                                        <>
                                          <label
                                            for="downloadIcon"
                                            className="upload_title"
                                          >
                                            <div className="uploadFilebg">
                                              <img
                                                src={downloadIcon}
                                                width="16px"
                                                height="16px"
                                              />
                                              <span className="uploadFileTitle">
                                                Upload a file
                                              </span>
                                            </div>
                                            <p className="noFileChoosen">
                                              No file choosen
                                            </p>
                                          </label>
                                          <input
                                            id="downloadIcon"
                                            className="downloadFile"
                                            style={{ display: "none" }}
                                            type="file"
                                            name="attachments"
                                            accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                            onChange={(e) =>
                                              onFileChange1(e)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="rfqButtonWrapper">
                          <button
                            type="button"
                            // onClick={() => handleAddItemClose(index)}
                            onClick={() => handleAddItemClose1(index)}
                            // onClick={handleAddItem}
                            className="rfqCommonBtn bgBlue"
                          >
                            {editableProduct ? "Edit Item" : "Add Item"}
                          </button>
                        </div>
                      </Modal.Body>
                    ) : (
                      <Modal.Body>
                        {item.type === "product" && openModalIndex === index ? (
                          <div className="modalAddItemsList">
                            <div className="customerInfoItem">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {item.type === "product"
                                      ? "Catalogue"
                                      : item.type === "category"
                                      ? "Category"
                                      : item.type === "other"
                                      ? "Other"
                                      : item.type === "attachments"
                                      ? "Attachment"
                                      : item.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange(
                                              index,
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Name</label>
                                <input
                                  placeholder=""
                                  type="text"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleProductAreaChange(e, index)
                                  }
                                  // onFocus={() => setActiveDropdownIndex(index)}
                                  onFocus={() => {
                                    setActiveDropdownIndex(index);
                                    setShowDropdownList(true);
                                  }}
                                  onBlur={() => setShowDropdownList(false)}
                                />
                                {productSuggestions.length > 0 &&
                                  item.showProducts &&
                                  showDropdownList &&
                                  activeDropdownIndex === index && (
                                    <div
                                      className="customDropDownCnt show"
                                      key={index}
                                      style={{position:"static",marginTop:"8px"}}
                                    >
                                      <ul
                                        className="dropdownWrapper"
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {productSuggestions.map(
                                          (suggestion, idx) => (
                                            <li
                                              key={idx}
                                              className="dropdownItem"
                                              onClick={() =>
                                                handleProductSuggestionClick(
                                                  index,
                                                  suggestion
                                                )
                                              }
                                            >
                                              {suggestion.label}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Brand</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="brand"
                                  value={item.brand}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "brand",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem QuantityUnitPrice">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Quantity</label>
                                <input
                                  placeholder=""
                                  type="number"
                                  name="quantity"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Unit</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={item.quantity_type}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "quantity_type",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Price</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="productPrice"
                                  value={item.price}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "productPrice",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {item.type === "category" &&
                        openModalIndex === index ? (
                          <div className="modalAddItemsList">
                            <div className="customerInfoItem">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {item.type === "product"
                                      ? "Catalogue"
                                      : item.type === "category"
                                      ? "Category"
                                      : item.type === "other"
                                      ? "Other"
                                      : item.type === "attachments"
                                      ? "Attachment"
                                      : item.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange(
                                              index,
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleTextAreaCategory(e, index)
                                  }
                                  // onFocus={() => setActiveDropdownIndex(index)}
                                  onFocus={() => {
                                    setActiveDropdownIndex(index);
                                    setShowDropdownList(true);
                                  }}
                                  // onBlur={() => setActiveDropdownIndex(false)}
                                  onBlur={() => setShowDropdownList(false)}
                                />
                                {categorySuggestions.length > 0 &&
                                  item.showProducts &&
                                  showDropdownList &&
                                  activeDropdownIndex === index && (
                                    <div className="customDropDownCnt show"
                                    style={{position:"static",marginTop:"8px"}}
                                    >
                                      <ul
                                        className="dropdownWrapper"
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {categorySuggestions.map(
                                          (suggestion, idx) => (
                                            <li
                                              key={suggestion.value}
                                              className="dropdownItem"
                                              onClick={() =>
                                                handleCategoryClick(
                                                  index,
                                                  suggestion
                                                )
                                              }
                                            >
                                              {suggestion.label}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Brand</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="brand"
                                  value={item.brand}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "brand",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem QuantityUnitPrice">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Quantity</label>
                                <input
                                  placeholder=""
                                  type="number"
                                  name="quantity"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Unit</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={item.quantity_type}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "quantity_type",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Price</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="productPrice"
                                  value={item.price}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "productPrice",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {item.type === "other" && openModalIndex === index ? (
                          <div className="modalAddItemsList">
                            <div className="customerInfoItem">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {item.type === "product"
                                      ? "Catalogue"
                                      : item.type === "category"
                                      ? "Category"
                                      : item.type === "other"
                                      ? "Other"
                                      : item.type === "attachments"
                                      ? "Attachment"
                                      : item.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange(
                                              index,
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Name</label>
                                <input
                                  rows={1}
                                  className="textEditableInput"
                                  type="text"
                                  placeholder=""
                                  value={item.name}
                                  onChange={(e) =>
                                    handleTextAreaOther(e, index)
                                  }
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Brand</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="brand"
                                  value={item.brand}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "brand",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>
                            </div>

                            <div className="customerInfoItem QuantityUnitPrice">
                              <div className="traInputWrapper">
                                <label htmlFor="name">Quantity</label>
                                <input
                                  placeholder=""
                                  type="number"
                                  name="quantity"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Unit</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="quantity_type"
                                  value={item.quantity_type}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "quantity_type",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="traInputWrapper">
                                <label htmlFor="name">Price</label>
                                <input
                                  type="text"
                                  className={`${
                                    item.type === "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : "enabled_filed_wrapper"
                                  }`}
                                  placeholder=""
                                  name="productPrice"
                                  value={item.price}
                                  onChange={(e) =>
                                    handleTextChange(
                                      index,
                                      "productPrice",
                                      e.target.value
                                    )
                                  }
                                  disabled={`${
                                    item.type == "product" ||
                                    item.type == "category" ||
                                    item.type == "other"
                                      ? "disbled_filed_wrapper"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {item.type == "attachments" &&
                        openModalIndex === index ? (
                          <div className="modalAddItemsList">
                            <div className="customeSelectCntRole customerInfoItem customeSelectCnt">
                              <div className="customeSelectCntRole customeSelectCnt">
                                <button
                                  className={`customSelectBtn`}
                                  onClick={() => toggleTypeDropdown(index)}
                                  onBlur={toggleBlurDropdown}
                                >
                                  <label htmlFor="name">Item Type</label>
                                  <span className="selectedValue">
                                    {item.type === "product"
                                      ? "Catalogue"
                                      : item.type === "category"
                                      ? "Category"
                                      : item.type === "other"
                                      ? "Other"
                                      : item.type === "attachments"
                                      ? "Attachment"
                                      : item.type}
                                  </span>
                                  <div className="customSelectBtnIcon downArrowIcon">
                                    <img
                                      src={downArrowGrey}
                                      width="12px"
                                      height="12px"
                                    />
                                  </div>
                                </button>
                                <div
                                  className={`customDropDownCnt ${
                                    isOpenTypeDropdown ? "show" : ""
                                  }`}
                                  style={{position:"static",marginTop:"8px"}}
                                >
                                  {isOpenTypeDropdown[index] && (
                                    <ul
                                      className="dropdownWrapper"
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {[
                                        {
                                          label: "Catalogue",
                                          value: "product",
                                        },
                                        {
                                          label: "Category",
                                          value: "category",
                                        },
                                        { label: "Other", value: "other" },
                                        {
                                          label: "Attachment",
                                          value: "attachments",
                                        },
                                      ].map((option, idx) => (
                                        <li
                                          key={option.value}
                                          className="dropdownItem"
                                          onClick={() =>
                                            handleProductChange(
                                              index,
                                              "type",
                                              option.value
                                            )
                                          }
                                        >
                                          {option.label}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                              <div className={`uploadFileChoosenCnt`}>
                                <div className="uploadFileChoosenWrapper">
                                  {item.name ? (
                                    <div className="uploadFileChoosen">
                                      <a
                                        className="noFileChoosen attchmentFile"
                                        href={item.name}
                                        target="_blank"
                                      >
                                        Attachment
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="uploadFileChoosen">
                                      {isImageLoading &&
                                      imageIndex === index ? (
                                        <Spinner
                                          animation="border"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </Spinner>
                                      ) : (
                                        <>
                                          <label
                                            for="downloadIcon"
                                            className="upload_title"
                                          >
                                            <div className="uploadFilebg">
                                              <img
                                                src={downloadIcon}
                                                width="16px"
                                                height="16px"
                                              />
                                              <span className="uploadFileTitle">
                                                Upload a file
                                              </span>
                                            </div>
                                            <p className="noFileChoosen">
                                              No file choosen
                                            </p>
                                          </label>
                                          <input
                                            id="downloadIcon"
                                            className="downloadFile"
                                            style={{ display: "none" }}
                                            type="file"
                                            name="attachments"
                                            accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                            onChange={(e) =>
                                              onFileChange(index, e)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="rfqButtonWrapper">
                          <button
                            type="button"
                            onClick={() => handleAddItemClose(index)}
                            // onClick={handleAddItem}
                            className="rfqCommonBtn bgBlue"
                          >
                            {editableProduct ? "Edit Item" : "Add Item"}
                          </button>
                        </div>
                      </Modal.Body>
                    )}
                    {errorMessage ? (
                      <ErrorHandling
                        message={errorMessage}
                        type={"ErrorMessage"}
                      />
                    ) : null}
                  </Modal>
                </div>
              </React.Fragment>
            ))}
            <div className="addItemBtnCnt">
              <button className="addItemBtn" onClick={handleOpenProductModal}>
                <img src={plusIcon} alt="add icon" width="24px" height="24px" />
                <span>Add Item</span>
              </button>
            </div>
          </section>
          <section className="rfqButtonCnt">
            <div className="rfqButtonWrapper">
              <button
                className="rfqCommonBtn"
                type="button"
                id="createRfqSr"
                disabled={buttonDisabled}
                onClick={handleSubmit}
              >
                {submitLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Create with SR"
                )}
              </button>
              <button
                className="rfqCommonBtn bgBlue"
                type="button"
                id="createRfq"
                disabled={buttonDisabled}
                onClick={handleSubmit}
              >
                {submitLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Create RFQ"
                )}
              </button>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default CustomerInfoMobile;
